import React, { useState, useEffect, useRef } from 'react'
import { Grid, Button, Stack, Box, IconButton,Card, CardContent, Typography  } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import bgpremium from '../../assets/images/DiaryImages/bgpremium.png'
import videaoPopup from '../../assets/images/DiaryImages/videao-popup.png'
import audioDefaultWave from '../../assets/images/DiaryImages/audio-default-wave.png'
import CloseIcon from "@mui/icons-material/Close"
// import close from '../../../../images/DiaryImages/close.png'
// import startRecord from '../../../../images/DiaryImages/start-record.png'
// import news from '../../../../images/DiaryImages/new.png'
import DiaryStyles from './DiaryStyles'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import './DiaryCustomStyles.css'
import { ReactMediaRecorder, useReactMediaRecorder } from "react-media-recorder";
import CircleIcon from '@mui/icons-material/Circle';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';
import SaveIcon from '@mui/icons-material/Save';
import DiaryPremiumBannerStyles from './DiaryPremiumBannerStyles';
// import Player from "react-wavy-audio";
import { base64UploadService, fileConvertToBase64 } from "../../helpers";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { firebase, db, storage, auth } from '../../config/fbConfig'
var premiumbox = {
  width: '100%',
  maxHeight: '430px',
  minHeight: '430px',
  backgroundImage: `url(${bgpremium})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  paddingTop: '30px',
  borderRadius: '30px',
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor:"#fff",
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius:'20px',
  pt: 3,
  px: 3,
  pb: 3,
};
const VideoPreview = ({stream, mediaBlobUrl}) => {
  const videoRef = useRef(null)
  const diaryPremiumBannerClasses = DiaryPremiumBannerStyles();
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return (
    <>
    {
      stream?.active?
      <video ref={videoRef} width={"100%"} autoPlay controls className={diaryPremiumBannerClasses.videoPreviewPlayer}/>
      :
      <video src={mediaBlobUrl} width={"100%"} autoPlay controls className={diaryPremiumBannerClasses.videoPreviewPlayer}/>
    }
    </>
  )
};
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{  alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, marginTop:1 }}>
        <Typography variant="body2" align='center' color="text.secondary">{`${Math.round(
          (props.value*60)/100,
        )} sec left`}</Typography>
      </Box>
    </Box>
  );
}
const VideoRecordModal = ({
    videoModalVisible,
    onClose,
    onSave
}) => {
  const { 
    status, 
    startRecording, 
    pauseRecording, 
    resumeRecording, 
    stopRecording, 
    muteAudio, 
    unmuteAudio, 
    mediaBlobUrl,
    clearBlobUrl,
    isMuted,
    previewStream,
   } =
    useReactMediaRecorder({ 
      audio: true,
      video: true,
      blobPropertyBag: {
        type: "video/mp4"
      },
     });
  const [visible, setVisible] = React.useState(0);
  const classes = DiaryStyles();
  const diaryPremiumBannerClasses = DiaryPremiumBannerStyles();
  const [open, setOpen] = useState(false);
  const [videoRecordFinished, setVideoRecordFinished] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [counter, setCounter] = React.useState(60);
  const onRequestClose = (val) => {
    setVideoRecordFinished(false)
    stopRecording()
    clearBlobUrl()
    onClose()
  }
  React.useEffect(() => {
    const timer = ((counter > 0) && status == "recording") && setInterval(() => setCounter(counter - 1), 1000);
    if ((counter == 0) && status == "recording") {
      handleFinishVideoRecorder()
    }
    return () => clearInterval(timer);
  }, [counter, status]);
  const handleStartVideoRecorder = () => {

    startRecording();
    setCounter(60)
  }
  const handlePauseVideoRecorder = () => {
    pauseRecording()
  }
  const handleResumeVideoRecorder = () => {
    resumeRecording()
  }
  const handleStopAudioRecorder = () => {
    stopRecording()
  }
  const handleFinishVideoRecorder = async() => {
    stopRecording()
    const blobb = await new Blob(mediaBlobUrl, {type: "video/mp4", }); // Important Line
    
    setVideoUrl(blobb)
    setVideoRecordFinished(true)
  }
  const handleSaveVideoFile = async() => {
    const fileSource = await fileConvertToBase64(mediaBlobUrl);
    const filePath = "diary/video/"+ auth.currentUser.uid+"/"+new Date().getTime()+".mp4"
    const fileUrl = await base64UploadService(fileSource, filePath)
    setVideoRecordFinished(false)
    stopRecording()
    clearBlobUrl()
    onSave(fileUrl)

  }
  const handleRestartAudioRecorder = () => {
    stopRecording()
    setVideoRecordFinished(false)
    setCounter(60)
  }
  useEffect(() => {
    if(status === "stopped" && !videoRecordFinished) {
      clearBlobUrl()
    }
  }, [status,videoRecordFinished])
  return (
    <>
      <div>
        <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={videoModalVisible}
      >
        <Stack>
          <Modal
            open={videoModalVisible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <div className={diaryPremiumBannerClasses.closebtn}>
              <CloseIcon onClick={() => onRequestClose(false)} />
            </div>
              <div>
                <h4  className={diaryPremiumBannerClasses.headTex}>Record Video</h4>
              </div>

              <Card 
              className={diaryPremiumBannerClasses.audioWaveCard}
              variant="outlined">
                <CardContent>
                {
                  previewStream?
                    <VideoPreview 
                    stream={previewStream} 
                    mediaBlobUrl={videoUrl}
                    />
                  : 
                  <div>
                    <img src={videaoPopup} style={{ }}></img>
                  </div>
                }
                </CardContent>
              </Card>
              <LinearProgressWithLabel value={(counter*100)/60} />
              <div
                style={{
                  backgroundColor: '#EEEEEE',
                  padding: '10px',
                  marginTop: '10px',
                }}
              >
              <Typography variant="body1" component="p" align='center'>
                All set? Let’s start!
              </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} justifyContent='space-evenly' style={{display:'flex'}}>
                    <Button 
                    variant="contained" 
                    startIcon={
                      <CloseIcon 
                        style={{ color:"#242424"}}
                        fontSize="small"
                      />
                    }
                    className={diaryPremiumBannerClasses.finishButton}
                    onClick={() => onRequestClose(false)} 
                    >
                      Cancel
                    </Button>
                  
                  
                  {
                    status == "idle"?
                        <Button 
                        variant="contained" 
                        startIcon={
                          <CircleIcon 
                            style={{ color:"#F10019"}}
                            fontSize="small"
                          />
                        }
                        className={diaryPremiumBannerClasses.finishButton}
                        onClick={() => handleStartVideoRecorder()}
                        >
                          Start Recording
                        </Button>
                  :
                  <>
                    <IconButton
                      onClick={() => handleRestartAudioRecorder()}
                      className={diaryPremiumBannerClasses.pauseButton}
                     >
                    <RefreshIcon
                     fontSize ="small"
                    style={{ color:"#242424"}}
                     />
                     </IconButton>
                    {
                     ( ["recording","acquiring_media", "stopping", "stopped", "paused"].includes(status) && !videoRecordFinished)?
                      <>
                        
                          {
                            ["recording"].includes(status)?
                          
                            <IconButton
                              onClick={() => handlePauseVideoRecorder()}
                              className={diaryPremiumBannerClasses.pauseButton}
                              // style={{backgroundColor: 'white'}}
                            >
                            <PauseIcon 
                              style={{ color:"#242424"}}
                              fontSize="small"
                            />
                            </IconButton>
                          
                          :
                          
                            <IconButton
                              onClick={() => handleResumeVideoRecorder()}
                              className={diaryPremiumBannerClasses.pauseButton}
                            >
                            <CircleIcon 
                              style={{ color:"#F10019"}}
                              fontSize="small"
                            />
                            </IconButton>
                          
                          }
                          <Button 
                          variant="contained" 
                          startIcon={<CheckIcon />}
                          onClick={() => handleFinishVideoRecorder()}
                          className={diaryPremiumBannerClasses.finishButton}
                          >
                            Finish
                          </Button>
                        
                      </>
                      :
                        <LoadingButton 
                        variant="contained" 
                        startIcon={<SaveIcon />}
                        onClick={() => handleSaveVideoFile()}
                        className={diaryPremiumBannerClasses.saveButton}
                        loadingPosition="end"
                        >
                          Save
                        </LoadingButton>
                    }
                  
                  </>
                  }
                  
                  
                </Grid>
                </Grid>
              </div>
            </Box>
          </Modal>
        </Stack>
      </Backdrop>
      </div>
    </>
  )
}
export default VideoRecordModal