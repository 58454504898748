import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	institutePermissions: [],
	defaultPermissions: [],
	userPermissions: {
		complaint_portal: false,
		assign_roles: false,
		override_permissions: false,
		reviewer_access: false,
		user_leaderboard: false,
		help_center_user: false,
		help_center_admin: false,
		reviewer_insights: false,
		user_insights: false,
		announcements: false,
		post_activity_insights: false,
		user_actions: false,
	}
};

export const permissions = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.GET_INSTITUTE_PERMISSIONS:
			return {
				...state,
				institutePermissions: payload
			}

		case actionTypes.SET_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: payload
			}

		default:
			return state;
	}
};