import { actionTypes, triggerReducer } from "./index";
import store from "../store";
import { firebase, db } from '../../config/fbConfig'


export const changeBlogStatus = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;
   let id = payload.id
   delete payload.id

   await firestore
      .collection('posts')
      .doc(id)
      .update(payload)
      .then(res => {
         console.log("Res ---")
      }).catch((error) => {
         console.log("Error : ", error)
      });
};

export const changePoemStatus = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;
   let id = payload.id
   delete payload.id

   await firestore
      .collection('poems')
      .doc(id)
      .update(payload)
      .then(res => {
         console.log("Res ---")
      }).catch((error) => {
         console.log("Error : ", error)
      });
};

export const changeEssayStatus = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;
   let id = payload.id
   delete payload.id

   await firestore
      .collection('essay')
      .doc(id)
      .update(payload)
      .then(res => {
         console.log("Res ---", res)
      }).catch((error) => {
         console.log("Error : ", error)
      });
};

export const getEssaysForReview = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;

   await firestore
      .collection('essay')
      .where('reviewerEmail', '==', auth.email)
      .get()
      .then(async (essays) => {
         let essaysData = []
         essays.docs.forEach(async (item) => {
            let essay = item.data()
            essay = {
               ...essay,
               id: item.id
            }
            essaysData.push(essay)
         })
         for (let i = 0; i < essaysData.length; i++) {
            let essay = essaysData[i]
            let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', essay.userId).get()
            if (user && user.docs && user.docs.length > 0) {
               let userData = user.docs[0].data()
               userData = {
                  ...userData,
                  cPassword: null,
                  newPassword: null,
                  password: null
               }
               essay = {
                  ...essay,
                  user: userData
               }
               essaysData[i] = essay
            } else {
               essaysData.splice(i, 1)
               i--
            }
         }

         dispatch(
            triggerReducer(actionTypes.GET_ESSAYS_FOR_REVIEW, {
               payload: essaysData
            })
         );
      })
      .catch((err) => {
         console.log("Error ---- ", err)
      })
};

export const getPoemsForReview = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;

   await firestore
      .collection('poems')
      .where('reviewerEmail', '==', auth.email)
      .get()
      .then(async (poems) => {
         let poemsData = []
         poems.docs.forEach(async (item) => {
            let poem = item.data()
            poem = {
               ...poem,
               id: item.id
            }
            poemsData.push(poem)
         })
         for (let i = 0; i < poemsData.length; i++) {
            let poem = poemsData[i]
            let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', poem.userId).get()
            if (user && user.docs && user.docs.length > 0) {
               let userData = user.docs[0].data()
               userData = {
                  ...userData,
                  cPassword: null,
                  newPassword: null,
                  password: null
               }
               poem = {
                  ...poem,
                  user: userData
               }
               poemsData[i] = poem
            }
         }
         dispatch(
            triggerReducer(actionTypes.GET_POEMS_FOR_REVIEW, {
               payload: poemsData
            })
         );
      })
      .catch((err) => {
         console.log("error -- ", err)
      })
};


export const saveReviewerDetails = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { profile } = getState().firebase;
   const { displayName, avatarUrl } = profile.providerData
   console.log("profile.providerData ---", profile.providerData)
   payload = {
      ...payload,
      displayName,
      avatarUrl
   }
   try {
      firestore.add({ collection: "reviewer" }, payload)
         .then(() => {
            dispatch(triggerReducer(actionTypes.SET_REVIEWER, { payload }));
         }).catch((error) => {
            console.log("Error reviwer details store === ", error)
         });

   } catch (error) {
      console.log("Error reviwer details store === ", error)
   }
};

export const getUserReviewRequest = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   const { auth } = getState().firebase;

   await firestore
      .collection('reviewer')
      .where('reviewerEmail', '==', auth.email)
      .get()
      .then(async (reviews) => {
         let reviewsData = []
         reviews.docs.forEach(async (item) => {
            let review = item.data()
            review = {
               ...review,
               id: item.id
            }
            reviewsData.push(review)
         })
         // for (let i = 0; i < reviewsData.length; i++) {
         //    let review = reviewsData[i]
         //    let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', review.userId).get()
         //    if (user && user.docs && user.docs.length > 0) {
         //       let userData = user.docs[0].data()
         //       userData = {
         //          ...userData,
         //          cPassword: null,
         //          newPassword: null,
         //          password: null
         //       }
         //       review = {
         //          ...review,
         //          user: userData
         //       }
         //       reviewsData[i] = review
         //    }
         // }
         dispatch(
            triggerReducer(actionTypes.GET_REVIEW_REQUEST, {
               payload: reviewsData
            })
         );
      })
      .catch((err) => {
         console.log("error -- ", err)
      })
};

// export const getPostsForReview = (payload) => async (dispatch, getState) => {
//    const { firestore } = store;
//    const { auth } = getState().firebase;

//    await firestore
//       .collection('poems')
//       .where('reviewerEmail', '==', auth.email)
//       .get()
//       .then(async (essays) => {
//          let essaysData = []
//          essays.docs.forEach(async (item) => {
//             let essay = item.data()
//             essay = {
//                ...essay,
//                id: item.id
//             }
//             essaysData.push(essay)
//          })
//          for (let i = 0; i < essaysData.length; i++) {
//             let essay = essaysData[i]
//             let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', essay.userId).get()
//             if (user && user.docs && user.docs.length > 0) {
//                let userData = user.docs[0].data()
//                userData = {
//                   ...userData,
//                   cPassword: null,
//                   newPassword: null,
//                   password: null
//                }
//                essay = {
//                   ...essay,
//                   user: userData
//                }
//                essaysData[i] = essay
//             } else {
//                essaysData.splice(i, 1)
//                i--
//             }
//          }

//          dispatch(
//             triggerReducer(actionTypes.GET_BOOKS_FOR_REVIEW, {
//                payload: essaysData
//             })
//          );
//       })
//       .catch((err) => {
//          console.log("Error ---- ", err)
//       })
// };