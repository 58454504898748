import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { clearSnackbar } from "../../store/actions/snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarMain() {
    const dispatch = useDispatch();

    const { snackBarMessage, snackBarOpen, snackBarType } = useSelector(state => state.snackbar);

    const handleClose = () => {
        dispatch(clearSnackbar());
    }

    return (
        <div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={snackBarType}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
