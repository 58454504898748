import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import "./Blog.css";
import { useHistory, useParams } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import BlogViewer from "./BlogViewer";
import lodash from "lodash-es";
import { Box, Button, Container, Grid, Icon } from "@mui/material";
import { setSearchQuery } from "../../../store/actions/library";
import { Helmet } from "react-helmet";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Main from "../../Main";
import Navbar from "../../Navbar";

const styles = {
    backgroundColor: '#fff',
    backgroundImage: 'none',
    paddingBottom: 50
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: `flex`,
        padding: theme.spacing(0)
    },
    box: {
        margin: 10,
        padding: 10
    }
}));

const BlogView = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { postId, name } = useParams();
    const { posts } = useSelector((state) => state.firestore.ordered);
    const history = useHistory();
    const auth = useSelector((state) => state.firebase.auth);
    const isAuthenticated = isLoaded(auth) && !isEmpty(auth);

    const _ = lodash;
    useFirestoreConnect([
        { collection: 'posts', doc: postId }
    ]);

    useEffect(() => {
        dispatch(setSearchQuery())
    }, [])

    const post = posts && _.find(posts, { 'id': postId })
    if (!post) {
        return ``;
    }

    const redirectToBack = (id = false) => {
        history.goBack();
    };

    return (
        <Main styles={styles}>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    isAuthenticated &&
                    <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                        <Navbar />
                    </Grid>
                }

                <Container maxWidth="xl" style={{ marginTop: isAuthenticated ? '0px' : '85px' }}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Quill At Will - Blog - {name}</title>
                        <meta
                            name="title"
                            content={name}
                        />
                        <meta
                            name="description"
                            content={name}
                        />
                        <meta
                            name="keywords"
                            content={name.replaceAll('-', ', ')}
                        />
                    </Helmet>
                    {/* <Box className={classes.box}>
                        <Button
                            variant="outlined"
                            onClick={() => redirectToBack()}
                        ><Icon>arrow_back</Icon>&nbsp;Back</Button>
                    </Box> */}
                    <BlogViewer post={post} />
                </Container>
            </Grid>
        </Main>
    )
};

export default BlogView;
