import React, { useState } from "react";
import "../../App.css";
import Footer from "../Footer";
import ContactInformation from "./ContactInformation";

function Contact() {
  const [show, setShow] = useState(false);
  return (
    <>
      <ContactInformation />
      <Footer />
    </>
  );
}

export default Contact;
