import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	roles: []
};

export const roles = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.GET_ROLES:
			return {
				...state,
				roles: payload
			}

		default:
			return state;
	}
};