import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar'
import store from '../store'

export const setOpenedPostCard = (payload, status) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_POSTCARD, { payload }));
    dispatch(
        triggerReducer(actionTypes.SET_DRAFT_EDIT_FLAG, { payload: status })
    );
};

export const getPostCards = (user) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let postcards = [];
        await db
            .collection('postcard')
            .where('userId', '==', user.uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    postcards.push(doc.data());
                })
            }).catch((error) => {
                dispatch(showErrorSnackbar('Error in getting the Sent Post Cards'))

            });
        dispatch(
            triggerReducer(actionTypes.GET_SENT_POSTCARDS, {
                payload: postcards
            })
        );
        resolve(postcards)
    });
};


// export const getReceivedPostCards = (user) => (dispatch, getState) => {
export const getReceivedPostCards = (user) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        var name = user.email.substring(0, user.email.lastIndexOf("@"))
        let postcards = [];
        await db
            .collection('postcard')
            // .whereField("to", 'arrayContains', payload.uid)
            .where("to", "array-contains", { email: user.email, userName: name })
            // .where("to", "array-contains", { email: 'rajasri@badisoch.com', userName: 'rajasri' })
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    postcards.push(doc.data());
                })
            }).catch((error) => {

                reject([])
            });
        dispatch(
            triggerReducer(actionTypes.GET_RECEIVED_POSTCARDS, {
                payload: postcards
            })
        );

        dispatch(
            triggerReducer(actionTypes.GET_RECEIVED_POSTCARDS_COUNT, {
                payload: postcards.length ? postcards.length : 0
            })
        );
        resolve(postcards)
    });
};

export const getSentPostCards = (user) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let postcards = [];
        await db
            .collection('postcard')
            .where('userId', '==', user.uid)
            // .where('userId', '==', 'q3upqIMIk0QvNPbB0YWAPoksP1l2')
            .get()
            .then(async querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    postcards.push(doc.data());
                })
                try {
                    for (let i = 0; i < postcards.length; i++) {
                        let post = postcards[i]
                        let { to } = post
                        for (let j = 0; j < to.length; j++) {
                            let tempUser = to[j]
                            let user = await db.collection("users").where('email', '==', tempUser.email).get()
                            if (user && user.docs && user.docs.length > 0) {
                                let userData = user.docs[0].data()
                                let { displayName, email, avatarUrl } = userData
                                let userDetails = {
                                    displayName, email, avatarUrl
                                }

                                to[j] = userDetails
                            }
                        }
                        post = {
                            ...post,
                            to
                        }
                        postcards[i] = post
                    }
                } catch (error) {
                    console.log("Err0r ----", error)
                }

            }).catch((error) => {
                console.log("Err0r ----", error)
            });
        dispatch(
            triggerReducer(actionTypes.GET_SENT_POSTCARDS, {
                payload: postcards
            })
        );
        dispatch(
            triggerReducer(actionTypes.GET_SENT_POSTCARDS_COUNT, {
                payload: postcards.length ? postcards.length : 0
            })
        );
        resolve(postcards)
    });
};

export const getPostCard = (user) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState().firebase;
        const { openedPostCard } = getState().postcards;
        await db
            .collection('postcard')
            .doc(user.id)
            .get()
            .then(
                querySnapshot => {
                    querySnapshot.docs.forEach(doc => {
                        openedPostCard.id = doc.id;
                        openedPostCard.content = doc.data().content;
                        user.id = doc.id;
                        user.content = doc.data().content;
                    })
                }).catch((error) => {

                });
        openedPostCard.userid = auth.uid;
        dispatch(
            triggerReducer(actionTypes.SET_OPENED_POSTCARD, {
                payload: user
            })
        );
        resolve(user)
    });
};

export const savePostCard = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { openedPostCard, savedPostCard } = getState().postcards;
    const { firestore } = store;
    const { tenantId } = getState().user;

    await firestore.add({ collection: "postcard" }, {
        content: payload.content || "",
        userId: auth.uid,
        tenantId
    }).then((resp) => {
        openedPostCard.id = resp.id;
        openedPostCard.userid = auth.uid;
        savedPostCard.id = resp.id;
        savedPostCard.userid = auth.uid;
        payload.id = resp.id;
        dispatch(triggerReducer(actionTypes.SET_SAVED_POSTCARD), { payload });
        dispatch(showSuccessSnackbar("Post Card Saved Successfully for Sending!"));
    })
};

export const setReceivedPost = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.RECEIVED_POSTCARD, {
            payload
        })
    );
}

export const setPostCardDetails = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_POSTCARD_DETAILS, {
            payload
        })
    );
    // dispatch(showSuccessSnackbar("Post Card Saved Successfully for Sending!"));
}

export const setPostCardImage = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_POSTCARD_IMAGE, {
            payload
        })
    );
    dispatch(showSuccessSnackbar("Post Card Image Saved!!!"));
}


export const saveDraftPostCard = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { openedPostCard, savedPostCard } = getState().postcards;
    const { firestore } = store;

    if (payload.id) {
        const docId = payload.id;
        await db
            .collection("draftPostcard")
            .doc(docId)
            .update(payload)
            .then(() => {
                dispatch(showSuccessSnackbar("Draft Post Card updated Successfully!"));
                dispatch(
                    triggerReducer(actionTypes.SAVE_DRAFT_POSTCARDS, { payload })
                );
            })
            .catch((error) => {

                dispatch(showErrorSnackbar("Error in updating Blog"));
            });
    } else {

        // let payloadData = {
        //     content: payload.content || "",
        //     userId: payload.userId,
        //     image: payload.image,
        //     date: payload.date,
        // }
        // db.collection('users').doc(auth.uid).collection('draftPostcard').add(payloadData)
        //     .then((resp) => {
        //         console.log("resp working -- ", resp)
        //     })
        //     .catch((err) => {
        //         console.log("Error ---- ", err)
        //     })

        await firestore.add({ collection: "draftPostcard" }, {
            content: payload.content || "",
            userId: payload.userId,
            image: payload.image,
            date: payload.date,
        }).then((resp) => {
            openedPostCard.id = resp.id;
            openedPostCard.userid = auth.uid;
            savedPostCard.id = resp.id;
            savedPostCard.userid = auth.uid;
            payload.id = resp.id;
            dispatch(triggerReducer(actionTypes.SAVE_DRAFT_POSTCARDS), { payload });
            dispatch(showSuccessSnackbar("Draft Post Card Saved Successfully!"));
        })
    }

};

export const getDraftPostCards = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState().firebase;
        let postcards = [];
        await db
            .collection('draftPostcard')
            .orderBy('date', 'desc')
            .where('userId', '==', auth.uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    let post = doc.data()
                    post = {
                        ...post,
                        id: doc.id
                    }
                    postcards.push(post);

                })
            }).catch((error) => {
                console.log('Error === ', error)
            });
        dispatch(
            triggerReducer(actionTypes.GET_DRAFT_POSTCARDS, {
                payload: postcards
            })
        );
        dispatch(triggerReducer(actionTypes.GET_DRAFT_POSTCARDS_COUNT, {
            payload: postcards.length ? postcards.length : 0
        }))
        resolve(postcards)
    });
};


export const deleteDraftCard = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    await firestore.collection('draftPostcard')
        .doc(payload).delete().then(() => {
            // dispatch(showSuccessSnackbar("Draft "));
        }).catch((error) => {
            // dispatch(showErrorSnackbar("Todo delete failed!!!"));
        });
}

export const getPostcardById = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { openedPostCard } = getState().postcards;
    const { firestore } = store;
    await firestore.collection('postcard')
        .doc(payload).get().then(
            querySnapshot => {
                let postcard = querySnapshot.data()
                // if (querySnapshot) {
                //     openedPostCard.id = querySnapshot.id;
                //     openedPostCard.content = querySnapshot.data().content;
                //     payload.id = querySnapshot.id;
                //     payload.content = querySnapshot.data().content;
                // }
                dispatch(triggerReducer(actionTypes.SET_OPENED_POSTCARD, { payload: postcard }))
            })
        .catch((error) => {
            console.log("Error --- ", error)
            // dispatch(showErrorSnackbar("Todo delete failed!!!"));
        });
}

//add extra field test
export const updateDraftPostcard = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    let id = payload.id
    delete payload.id
    await firestore.collection('draftPostcard')
        .doc(id)
        .update(payload).then((res) => {
            console.log("res ----- ", res)
            // dispatch(showSuccessSnackbar("Draft "));
        }).catch((error) => {
            // dispatch(showErrorSnackbar("Todo delete failed!!!"));
            console.log("error postcard update --- ", error)
        });
}

export const getUserPostcardCount = () => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    var name = auth.email.substring(0, auth.email.lastIndexOf("@"))

    let sentPostcardCount = await db
        .collection('postcard')
        .where('userId', '==', auth.uid)
        .get()

    let receivedPostcardCount = await db
        .collection('postcard')
        .where("to", "array-contains", { email: auth.email, userName: name })
        .get()

    let draftPostcardCount = await db
        .collection('draftPostcard')
        .orderBy('date', 'desc')
        .where('userId', '==', auth.uid)
        .get()

    dispatch(
        triggerReducer(actionTypes.GET_SENT_POSTCARDS_COUNT, {
            payload: sentPostcardCount.size ? sentPostcardCount.size : 0
        })
    );

    dispatch(
        triggerReducer(actionTypes.GET_RECEIVED_POSTCARDS_COUNT, {
            payload: receivedPostcardCount.size ? receivedPostcardCount.size : 0
        })
    );

    dispatch(triggerReducer(actionTypes.GET_DRAFT_POSTCARDS_COUNT, {
        payload: draftPostcardCount.size ? draftPostcardCount.size : 0
    }))
}