import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Box, Grid, Paper, Button } from "@mui/material";
import { getRiddle } from "../../../store/actions/riddles";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'

export default function RiddleComponent() {
  const dispatch = useDispatch();
  const { riddles } = useSelector((state) => state);
  const [flag, setFlag] = useState(false);
  const [riddle, setRiddle] = useState();
  const [loading, setLoading] = useState(true)
  const [noRiddleContent, setNoRiddleContent] = useState(true)
  useEffect(() => {
    dispatch(getRiddle());
  }, []);

  useEffect(() => {
    if (riddles && riddles.OpenedRiddle && riddles.OpenedRiddle.length > 1) {
      const riddles1 = riddles.OpenedRiddle
      let sec = new Date().getTime() / 1000;
      let day = moment().format('d')
      let index = day % riddles1.length
      if (index > riddles1.length - 1) {
        index %= riddles1.length
      }
      setRiddle(riddles1[index])
      setNoRiddleContent(false)
      setLoading(false)
    }
  }, [riddles])

  const onClickfunction = () => {
    setFlag((prev) => !prev);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        borderRadius: "1rem",
        backgroundColor: "#EDF0F2",
        padding: "1rem",
        marginTop: "1rem",
      }}
    >
      <h3 style={{ color: "#001f58" }}>Riddle</h3>
      {
        loading ?
          <>
            <br />
            <h4 style={{ fontWeight: 'bold' }}>Loading...</h4>
            <br /><br /><br />
          </>

          :
          noRiddleContent ?
            <>
              <br />
              <h4 style={{ fontWeight: 'bold' }}>No Riddles</h4>
              <br /><br /><br />
            </>
            :
            <>

              <p style={{ textAlign: "center", margin: "0.5rem" }}>
                {riddle?.riddle}
              </p>

              {flag === true ? (
                <Grid item xs="auto">
                  <p style={{ color: "blue", overflow: "auto", textAlign: "center" }}>
                    {riddle?.answer}
                  </p>
                </Grid>
              ) : null}
              <Box>
                <Button
                  style={{
                    borderRadius: "5rem",
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    background: "white",
                    color: "#001f58",
                    border: "none",
                    boxShadow: "1px 2px 2px 1px #001f58",
                  }}
                  onClick={onClickfunction}
                >
                  {flag === false ? "Show Answer" : "Hide Answer"}
                </Button>
              </Box>
            </>

      }

    </Stack>
  );
}
