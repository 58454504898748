import { Typography, Grid, Box } from "@mui/material";
import React, { } from "react";
import TermsAndConditionsStyles from "./TermsAndConditionsStyles.js";

const TermsAndConditions = props => {
    const classes = TermsAndConditionsStyles();


    return (
        <div className={classes.bg1} style={{ backgroundColor: '#fff' }}>
            <div className={classes.root} >
                <Grid container spacing={2}>
                    <Box>
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            Terms and Conditions
                        </Typography>
                        <br />
                        <Typography component="p">
                            We at <a href="https://quillatwill.com/"> quillatwill.com </a> would like to thank you for your interest in using our website. We want to make sure that you feel settled in at <a href="https://quillatwill.com/"> quillatwill.com </a> and that you understand the rules before joining our humble website. In order to be a member of <a href="https://quillatwill.com/"> quillatwill.com </a>, you must accept the following terms and conditions:
                        </Typography>
                        <br></br>
                        <Typography>
                            The following is an agreement between Quillatwill.com ("us", "our" and "we" ), and any person or other legal entity ("you", "your" or "account holder") who wishes to use the <a href="https://quillatwill.com/"> quillatwill.com </a> website. Whether as the holder of an account on the Site ("Member") or merely to browse the website (Members and other users of the Site are collectively described in this agreement as "Users").

                        </Typography>
                        <br /><br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            About <a href="https://quillatwill.com/"> Quillatwill.com </a>
                        </Typography>

                        <Typography component="p">
                        <a href="https://quillatwill.com/"> Quillatwill.com </a> is a website owned by Badi Soch Innovations Pvt. Ltd. The website allows members to write stories, blogs as well as postcards online and share them with other users. It also has a number of other resources and activities to support aspiring writers.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Acceptance of Terms
                        </Typography>

                        <Typography component="p">
                            These terms and conditions ("Terms") apply to all and any use of <a href="https://quillatwill.com/"> Quillatwill.com .</a> This includes any postings, emails, and other communications made to or using it. If you use <a href="https://quillatwill.com/"> Quillatwill.com </a>, you agree to comply with these terms. If you do not accept these terms, then you should leave this website and discontinue use of the services immediately. Please read this agreement carefully and save it.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        <a href="https://quillatwill.com/"> Quillatwill.com </a> content
                        </Typography>

                        <Typography component="p">
                            This website is for personal and non-commercial use only, unless otherwise specified on this site, and may not be used for any purpose not expressly permitted by this agreement. In addition, you may not reproduce, copy, duplicate, sell or resell any part of the Service.
                            <br />
                            <br />
                            <a href="https://quillatwill.com/"> Quillatwill.com </a> owns all materials that appear on this website, including any slogans, logos, designs, graphics, pictures, images, text, content, images, or other materials. All such rights of Quillatwill.com are hereby asserted and reserved, and you may not make any unauthorised use of the content, including using content in a way that is likely to cause confusion among consumers, using content in a commercial manner, or otherwise infringing on <a href="https://quillatwill.com/"> Quillatwill.com's </a> rights.

                        </Typography>
                        <br />
                        <Typography>
                            You are, however, allowed to print the stories or blogs that you, as a member of <a href="https://quillatwill.com/"> Quillatwill.com </a> have created on the website. Since <a href="https://quillatwill.com/"> Quillatwill.com </a> is a community site, we also use a range of emojis and pictures throughout the site, such as in the comments and status updates.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            User conduct <br></br>
                            General Rules

                        </Typography>

                        <Typography component="p">
                            These rules apply to all website visitors, including public visitors and members of <a href="https://quillatwill.com/"> Quillatwill.com </a> (Please ask a grown-up to read these for you, if you do not understand):
                        </Typography>
                        <br />

                        <Typography component="p">
                            You must submit the requested information to <a href="https://quillatwill.com/"> Quillatwill.com </a> during the registration process, including a correct email address. When such registration information is requested by the service, the information you provide must be true, accurate, current, and complete.
                            <br />
                            You are responsible for any activity that occurs under your username and account. You may not transfer your account.
                            <br />
                            You are responsible for keeping your member password secure and for notifying <a href="https://quillatwill.com/"> Quillatwill.com </a> immediately of any breach of security or unauthorised use of your account.
                            <br />
                            You are responsible for maintaining the confidentiality of any personally identifiable data that you upload and store on the Site, as well as for complying with all applicable laws governing the collection, storage, processing, use, and transfer of such information.
                            <br />
                            You are solely responsible for your conduct on the Site and use of the Service, and for any content, including but not limited to designs, text, graphics, pictures, video, information, applications, software, music, sound, and other files (collectively, “Content”), that you contribute to the Site.
                            <br />
                            You must not abuse, harass, threaten, impersonate or intimidate other users of Quillatwill.com
                            <br />
                            You may not use the Service for any illegal or unauthorized purpose. International Users agree to comply with all local laws regarding online conduct and acceptable content.

                        </Typography>
                        <br />
                        <Typography>
                            You must not create or submit unwanted emails or other messages to any users of the Site.
                            <br />
                            You are not permitted to collect or harvest any personally identifiable information from the Site, including account names.
                            <br />
                            You may not use the Service to upload, transmit, or link to prohibited content. Generally, Prohibited Content includes content or other material that <a href="https://quillatwill.com/"> Quillatwill.com </a> believes is:
                            abusive, deceptive, pornographic, obscene, defamatory, slanderous, offensive, encourages conduct that would violate or violates any law, harassing, hateful, racially or ethnically offensive, or otherwise inappropriate;
                            <br />
                            comprising material that is copyrighted, or protected by trade secrets, or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless express written permission of the owner is obtained to use the material and to grant all of the licence rights granted herein;
                            violates or infringes on the rights of others;
                            contains viruses, worms, corrupt files, Trojan horses or other forms of corruptive code, or any other content that could compromise the Service;
                            advocates illegal activity;
                            harms anyone, including minors; or
                            It provides a link to any of the above.
                            <br />

                            <a href="https://quillatwill.com/"> Quillatwill.com </a> has the sole discretion to determine whether content is Prohibited Content, and any content submitted to the service may be subject to examination from time to time. <a href="https://quillatwill.com/"> Quillatwill.com </a> may delete, move, or edit content for any reason, at any time, without notice. <a href="https://quillatwill.com/"> Quillatwill.com </a> in no way guarantees the accuracy, non-infringement, quality, or appropriateness of content available through the service.


                        </Typography>
                        <br></br>
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Third Parties
                        </Typography>

                        <Typography component="p">
                            From time to time, we may provide links to third party websites, especially on our blog. Those links are provided as a service to our users. We do not sponsor or endorse those third parties, nor do we control or direct their activities. You should review any website terms and conditions, privacy policies, and other disclosures that those sites provide before interacting with those third parties, or providing them with information about yourself. Please be aware that the terms of our Privacy Policy do not apply to these outside websites.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            The <a href="https://quillatwill.com/"> Quillatwill.com </a> Blog
                        </Typography>

                        <Typography component="p">
                            These terms also apply to the use of our blog. You are responsible for any comments you post here, <a href="https://quillatwill.com/"> Quillatwill.com </a> will not be held liable for any offensive or illegal content (as described above). Any competitions run on the blog, will include their own terms and conditions within the blog post, along with a description of the prize.
                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Personal Information

                        </Typography>

                        <Typography component="p">
                            Sometimes we ask you to submit some personal information about yourself. Read our Privacy policy for more details on how this information is used and what we collect.
                        </Typography>

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Content Contributed to the Site by Members

                        </Typography>

                        <Typography component="p">
                            The views expressed in blogs, stories, or postcards are those of the individual creators or writers of those stories and not those of <a href="https://quillatwill.com/"> Quillatwill.com </a>, unless specified otherwise by Quillatwill.com. In particular, but without limitation to the above, <a href="https://quillatwill.com/"> Quillatwill.com </a> is not responsible for and disclaims all liability in respect of any comments, views or remarks expressed in stories or blogs created by users of the website.
                            <a href="https://quillatwill.com/"> Quillatwill.com </a> gives no warranty and makes no representation as to the accuracy or validity of the information and material contained within the user created stories and shall not be liable for any loss or damage resulting from the use of such material. It is also important to note that <a href="https://quillatwill.com/"> Quillatwill.com </a> reserves the right to remove content from the Site at any time, without notice, for any reason, or for no reason, in its sole discretion.

                        </Typography>
                        <br />
                        <Typography component="p">
                            We will not be liable for your use of any information or materials on this website, entirely at your own risk. It will be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            What is Copyright?

                        </Typography>
                        {/* <br /> */}
                        <Typography component="p">
                            Copyright protects writers or creators of content from having their work copied and shared without permission. Copyright is automatically given to the creator of content, the moment the content is created or published. In the case of <a href="https://quillatwill.com/"> Quillatwill.com </a>, a writer automatically gains copyright over their book the moment they hit the “Publish” button.
                            Please remember that ideas can NOT be copyrighted. When an author writes a blog or book on <a href="https://quillatwill.com/"> Quillatwill.com </a>, it is the text or sentences that they use that are copyrighted ONLY. Also, images used in books cannot be copyrighted, as these are public domain, so anyone can use these.

                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Copyright and Plagiarism

                        </Typography>
                        <br />
                        <Typography component="p">
                            Copyright is very important to <a href="https://quillatwill.com/"> Quillatwill.com </a>. All content on this website including blog posts, printables, and stories written by our users, is copyrighted. Below are our general rules in regards to copyright.

                            You can not share content, such as PDFs, printables and stories by our members on third-party (external) websites without written permission from Quillatwill.com.
                            If you receive permission from <a href="https://quillatwill.com/"> Quillatwill.com </a> to share content on your site, then you MUST attribute our site as the original source of the content AND provide a do-follow link back to our website.
                            You CAN NOT copy and paste any content from our site onto your external site. This includes everything from website copy to stories written by our users. If you are caught copying our content, you will be asked to remove it straight away. Failure to remove this content could result in legal action.
                            Copyright to all content, including books by our users ultimately belongs to the user.

                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Copyright


                        </Typography>

                        <Typography component="p">
                            Apart from general copyright guidelines, we also have specific guidelines for stories and books written by our users/members. Below are our copyright guidelines relating to books published on <a href="https://quillatwill.com/"> Quillatwill.com </a>:
                            If another user on <a href="https://quillatwill.com/"> Quillatwill.com </a> copies your blog or book, you can report that book and it will be removed from the public library.
                            Alternatively, if your book on <a href="https://quillatwill.com/"> Quillatwill.com </a> gets copied by a third-party (external) website, please let us know at support@quillatwill.com. Our team will then contact that website owner, asking them to remove your content.
                            The download option for books should only ever be used for PERSONAL USE ONLY (commercial use is NOT allowed). For example, a user can print out our books to read at home before bedtime.
                            It is illegal to copy or plagiarise content on another website without written permission from the owner.


                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Liability



                        </Typography>

                        <Typography component="p">
                            The <a href="https://quillatwill.com/"> Quillatwill.com </a> website will be maintained on an "as is" and "as available" basis. <a href="https://quillatwill.com/"> Quillatwill.com </a> gives no guarantee that the website will always be available, or that it will work perfectly. To the maximum extent permitted by law, <a href="https://quillatwill.com/"> Quillatwill.com </a> excludes liability for any loss or damage of any kind arising out of your use of Quillatwill.com. We will not be liable to you in respect of any loss or corruption of any data on the site or any stories you created.
                        </Typography>

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Termination


                        </Typography>

                        <Typography component="p">
                        <a href="https://quillatwill.com/"> Quillatwill.com </a> reserves the right to terminate this Agreement and immediately remove, cancel, or suspend your access to and use of <a href="https://quillatwill.com/"> Quillatwill.com </a> for any reason, including if you violate the rules or these terms, or if Quillatwill.com is purchased by another company, or for any other reason.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Linking to <a href="https://quillatwill.com/"> Quillatwill.com </a>


                        </Typography>

                        <Typography component="p">
                            Anyone who links to <a href="https://quillatwill.com/"> Quillatwill.com </a> must not imply or suggest affiliations with <a href="https://quillatwill.com/"> Quillatwill.com </a> that do not exist, nor may the link refer to or portray <a href="https://quillatwill.com/"> Quillatwill.com </a> in a false, misleading, derogatory, or otherwise offensive manner.

                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Refund and Cancellation Policy


                        </Typography>

                        <Typography component="p">
                        You can cancel your Quill At Will membership at any time, and you will continue to have access to the Quill At Will 
                        service through the end of your billing period.<strong> As per our company Policies, payments are non-refundable.</strong> 
                        To cancel, go to the "Account" page and follow the instructions for cancellation. If you cancel your membership, 
                        your account will automatically close at the end of your current billing period. To see when your account will close,
                         click "Billing details" on the "Account" page. If you signed up for Quill At Will using your account with a third 
                         party as a Payment Method and wish to cancel your Quill At Will Premium, you may need to do so through such a 
                         third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the Quill At Will service through that third party.


                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Quill At Will offers a free and paid Service.

                        </Typography>

                        <Typography component="p">
                        If you are subscribing to Quill At Will Premium. You can sign up for three months subscription. 
                        Your subscription will automatically renew. You can cancel your subscription at any time. If you cancel 
                        your subscription, you will not receive a refund or credit for any amounts that have already been billed. 
                        either you will continue to have access to the Quill At Will Premium plan through the end of your billing period.


                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Miscellaneous


                        </Typography>

                        <Typography component="p">
                            Your use of this website and any dispute arising out of such use of the website is subject to the laws of Hyderabad, India.
                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Changes to Terms
                        </Typography>

                        <Typography component="p">
                        <a href="https://quillatwill.com/"> Quillatwill.com </a> may make alterations to these Terms from time to time. These changes shall become effective immediately as soon as they are posted on this site.

                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Questions?

                        </Typography>

                        <Typography component="p">
                            All questions, comments or enquiries should be sent by email to: support@Quillatwill.com
                        </Typography>
                        <br />


                        <hr />
                        <Typography component="p" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Date: 24th Dec 2021
                        </Typography>

                        <br />
                        <br />
                        <br />
                    </Box>
                </Grid>

            </div>
        </div>
    );
};

export default TermsAndConditions;
