import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
    goals :[],
    openedGoal: {
        id: null,
        goal: null,
        userId: null,
        date: null,
        status: null
    }
};

export const goals = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.GET_GOALS:
            return {
                ...state,
                goals: payload,
            };
        case actionTypes.SET_OPENED_GOALS:
            return {
                ...state,
                openedGoal: payload
            }
        default:
            return state;
    }
};
