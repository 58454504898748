import React, { useState } from "react";
import Main from "../Main";
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import PersonalInfo from './PersonalInfo'
import InstituteInfo from './InstituteInfo'
import AccountURL from "./AccountURL";
import StartSetup from './StartSetup'
import Onboarding from "./Onboarding";
import CreatAccount from "./CreateAccount";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    backgroundColor: '#fff',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1E3A71' : '#308fe8',
    },
}));

const Register = (props) => {
    const [pageCount, setPageCount] = useState(1)
    const [subPageCount, setSubPageCount] = useState(0)

    const renderComponent = () => {
        switch (pageCount) {
            case 1: return <PersonalInfo setPageCount={setPageCount} />
            case 2: return subPageCount ?
                <CreatAccount setPageCount={setPageCount} /> :
                <InstituteInfo setPageCount={setPageCount} setSubPageCount={setSubPageCount} />
            case 3: return <AccountURL setPageCount={setPageCount} setSubPageCount={setSubPageCount} />
            case 4: return <StartSetup setPageCount={setPageCount} />
            case 5: return <Onboarding setPageCount={setPageCount} />
            default: return <PersonalInfo setPageCount={setPageCount} />
        }
    }
    const styles = {
        backgroundColor: "#1d396f",
        backgroundImage: 'linear-gradient(darkblue, aqua)'
    }
    return (
        <Main styles={styles}>
            <Grid container style={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Grid item sm={12} md={4} lg={4} style={{ justifyContent: 'center', textAlign: 'center', height: 'auto' }} >
                    <br /><br />
                    <Typography variant="h5" style={{ color: '#fff', maxWidth: '100%', fontFamily: 'Museo700' }}>
                        {
                            pageCount === 2 && subPageCount === 1 ? <></> :
                                <>{'Page 0' + pageCount}<span style={{ fontSize: '17px', fontFamily: 'Museo300' }}>/03</span></>
                        }
                    </Typography>
                    <br />
                </Grid>
                {
                    renderComponent()
                }
                {
                    pageCount !== 3 &&
                    <Grid container item sm={12} md={2} style={{ marginTop: 30, marginBottom: 40 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <BorderLinearProgress variant="determinate" value={pageCount * 20} />
                        </Box>
                    </Grid>
                }

            </Grid>
        </Main>
    );
}

export default Register;
