import React, { useDebugValue, useEffect, useRef, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import DiaryStyles from "./DiaryStyles";
import Footer from "../Footer";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";

import {
  getGoals,
  saveGoals,
  updateGoals,
  openedGoal,
  deleteGoal,
} from "../../store/actions/goals";
import AppCreate from "../Blog/blogSections/AppCreate";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import moment from "moment";
import "./Styles.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import {
  addDiaryPage,
  getDiaryPage,
  getDiaryDates,
  clearDiaryDates,
} from "../../store/actions/diary";

import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import DiaryDate from "./DiaryDate";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewAppToDo from "../Dashboard/sections/NewAppToDo";
import NewAppGoalComp from "../Dashboard/sections/NewAppGoalComp";
import Happy from "../../assets/images/Happy.png";
import Neutral from "../../assets/images/Neutral.png";
import Sad from "../../assets/images/Sad.png";
import VeryHappy from "../../assets/images/VeryHappy.png";
import VerySad from "../../assets/images/VerySad.png";
import CreateFabButton from "../Dashboard/CreateFabButton";
import DiaryHabit from "./DiaryHabit";
import bgpremium from "../../assets/images/DiaryImages/bgpremium.png";
import a4audio from "../../assets/images/DiaryImages/A4 - 4.png";
import a5vedio from "../../assets/images/DiaryImages/A4 - 5.png";
import Area_Train from "../../assets/images/DiaryImages/Area_Train.png";
import image from "../../assets/images/dashboard/diamond.png";
import vector from "../../assets/images/dashboard/Vector.jpeg";
import camera from "../../assets/images/dashboard/camera.png";
import mic from "../../assets/images/dashboard/mic.png";
import NewAppEvents from "../../components/Dashboard/sections/NewAppEvents";
import CreatefabButton from "../../components/Dashboard/CreateFabButton";
import Navbar from "../Navbar";
import Main from "../Main";
import PremiumButton from "../../components/PremiumButton";
import { useHistory } from "react-router-dom";
import AppPremiumBadge from "../Dashboard/sections/AppPremiumBadge";

const premiumbox = {
  width: "100%",
  maxHeight: "467px",
  minHeight: "430px",
  paddingTop: "30px",
  borderRadius: "30px",
  position: "relative",
  backgroundImage: `url(${bgpremium})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};
let add;

// const styles = {
//   backgroundColor: "#fff",
//   backgroundImage: "none",
//   paddingBottom: 50,
//   // border:'3px solid red'
// };

const TASKS = [];

TaskItem.propTypes = {
  task: PropTypes.string,
  checked: PropTypes.bool,
  formik: PropTypes.object,
};

function TaskItem({
  goalData,
  task,
  checked,
  formik,
  goalId,
  date,
  setDeleteIds,
  deleteIds,
  ...other
}) {
  const { getFieldProps } = formik;
  const [text, setText] = useState(task);
  const [goalID, setGoalID] = useState(goalId);
  const auth = useSelector((state) => state.firebase.auth);
  const openedGoalState = useSelector((state) => state.goals.openedGoal);

  const [value, setValue] = useState(new Date());
  const classes = DiaryStyles();
  const dispatch = useDispatch();

  const handleGoalChange = (e) => {
    let goal = {
      goal: e.target.value,
    };
    if (!goalID) {
      goal = {
        ...goal,
        date: new Date(),
        status: 0,
        userId: auth.uid,
      };
    }
    dispatch(openedGoal(goal));
    setText(e.target.value);
  };

  useEffect(() => {
    let goal = {
      status: checked ? 1 : 0,
    };
    if (checked && goalId) {
      if (deleteIds && deleteIds.length > 0) {
        let goals = [...deleteIds];
        goals.push(goalId);
        setDeleteIds(goals);
      } else {
        let goals = [];
        goals.push(goalId);
        setDeleteIds(goals);
      }
    }
  }, [checked]);

  const handleOnBlur = (e) => {
    if (goalID) {
      let goal = {
        goal: e.target.value,
      };
      dispatch(updateGoals(goal, goalId));
    } else {
      let goal = {
        goal: e.target.value,
        date: new Date(),
        status: 0,
        userId: auth.uid,
      };
      dispatch(saveGoals(goal));
    }
  };

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      sx={{ py: 0.75 }}
    >
      <FormControlLabel
        control={
          <Checkbox
            icon={<CircleIcon />}
            checkedIcon={<CheckCircleIcon />}
            style={{ borderRadius: "50%", color: "white" }}
            {...getFieldProps("checked")}
            value={task}
            checked={checked}
            {...other}
          />
        }
        label={
          <input
            type="text"
            value={text}
            name="text"
            onChange={handleGoalChange}
            onBlur={handleOnBlur}
            className={classes.goalsStyling}
          />
        }
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          minDate={new Date()}
          className={classes.datePickerStyle}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} className={classes.datePickerStyle} />
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  [theme.breakpoints.down("sm")]: {},
}));

const imageArray = [VerySad, Sad, Neutral, Happy, VeryHappy];
const feelingsArray = ["very_sad", "sad", "neutral", "happy", "very_happy"];
const moodColors = ["#E88492", "#FA8072", "#FFA07A", "#50C878", "#69B39F"];

function Diary(props) {

  const classes = DiaryStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const [interests, setPreferences] = useState([]);
  const [status, setStatus] = useState(new Array(30));
  const [statusRead, setStatusRead] = useState(new Array(30));
  const [statusDrink, setStatusDrink] = useState(new Array(30));
  const [deleteIds, setDeleteIds] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [calendarColor, setCalendarColor] = useState("#E88492");
  const [updateEmojiImg, setUpdateEmojiImg] = useState(3);
  const [moodValue, setMoodValue] = useState(2);
  const [feelings, setFeelings] = useState("happy");
  const [loading, setLoading] = useState(false);
  // B 2 B update
  const history = useHistory();
  const [toggl, setTogle] = useState("Goals");
  const [HabitTracker, setHabitTracker] = useState(true);
  const [GoalsTracker, setGoalsTracker] = useState(false);
  const [TodoTracker, setTodoTracker] = useState(false);
  // B 2 B update
  const dispatch = useDispatch();
  const { goals } = useSelector((state) => state.goals);
  const openedDiary = useSelector((state) => state.diary.openedDiary);
  const { diaryDates } = useSelector((state) => state.diary);

  useEffect(() => {
    dispatch(getGoals());
    let payload = {
      userid: auth.uid,
      on: moment(new Date()).format("YYYY/MM/DD"),
    };
    dispatch(getDiaryPage(payload));
    // dispatch(getJournalPage(payload))
  }, []);

  useEffect(() => {
    setGoalsList(goals);
  }, [goals, goals.length]);

  useEffect(() => {
    setUpdateEmojiImg(moodValue);
    setCalendarColor(moodColors[moodValue] || "#B22222");
    setFeelings(feelingsArray[moodValue]);

    if (loading) {
      let date = new Date();
      let on = moment(date).format("YYYY/MM/DD");
      let userid = auth.uid;
      let { feelings } = openedDiary;

      const diary = {
        ...openedDiary,
        userid,
        on,
        feelings: feelingsArray[moodValue],
        color: calendarColor,
        feelingChange: true,
      };
      if (feelings !== feelingsArray[moodValue]) dispatch(addDiaryPage(diary));
    }
    setLoading(true);
  }, [moodValue]);

  useEffect(() => {
    const { feelings } = openedDiary;
    if (feelings) {
      let index = feelingsArray.findIndex((f) => f === feelings);
      if (index > -1) {
        setUpdateEmojiImg(index);
        setCalendarColor(moodColors[index] || "#B22222");
        setFeelings(feelings);
        setMoodValue(index);
      }
    }
  }, [openedDiary]);

  useEffect(() => {
    let newArrayData = new Array(30);
    newArrayData.fill(false);
    setStatus(newArrayData);
    setStatusRead(newArrayData);
    setStatusDrink(newArrayData);
  }, []);

  const changecolor = (index) => {
    let newData = [...status];
    newData[index] = !newData[index];
    setStatus(newData);
  };

  const changecolorRead = (index) => {
    let newData = [...statusRead];
    newData[index] = !newData[index];
    setStatusRead(newData);
    // setStatus(newData);
  };
  const changecolorBook = (index) => {
    let newData = [...statusDrink];
    newData[index] = !newData[index];
    setStatusDrink(newData);
    // setStatus(newData);
  };

  const formik = useFormik({
    initialValues: {
      checked: [TASKS[2]],
    },
    onSubmit: (values) => {
      //
    },
  });

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    dispatch(clearDiaryDates());
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let payload = {
      startDate,
      endDate,
    };
    dispatch(getDiaryDates(payload));
  }, [currentWeek]);

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  ///////////////////////Calender Dates
  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM";
    const currentDateFormat = "dd";

    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let day = startDate;
    let dayEnd = endDate;

    return (
      <div>
        <Typography
          className={classes.dateOfmonthStyle}
          // gutterBottom
          // variant="h6"
          // textAlign="center"
          component="div"
          fontFamily="Museo700"
          color="#0B2F64"
        >
          {format(currentMonth, dateFormat)} {format(day, currentDateFormat)}-
          {format(dayEnd, currentDateFormat)}
          <FaChevronLeft onClick={() => changeWeekHandle("prev")} />
          <FaChevronRight onClick={() => changeWeekHandle("next")} />
        </Typography>
      </div>
    );
  };

  //////////////////////render day
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      let day = addDays(startDate, i);

      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const getIndex = (date) => {
    let index = diaryDates.findIndex(
      (dDate) =>
        moment.unix(dDate.on.seconds).format("YYYYMMDD") ==
        moment(date).format("YYYYMMDD")
    );
    if (index > -1) {
      let { feelings } = diaryDates[index];
      if (feelings) {
        let index = feelingsArray.findIndex((f) => f === feelings);
        if (index > -1) {
          return index;
        }
      }
    }
    return -1;
  };
  ///////////////////calender dates
  const renderCells = () => {
    const dateFormat = "EEE";
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat1 = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let todayDate = moment().format("YYYYMMDD");
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        let dateCurrent = moment(day).format("YYYYMMDD");
        const cloneDay = day;

        add = todayDate === dateCurrent;
        let imageIndex = getIndex(day);
        days.push(
          <div
            className={`col cell ${isSameDay(day, new Date())
              ? "today"
              : isSameDay(day, selectedDate)
                ? "selected"
                : ""
              }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            &nbsp; &nbsp;
            <span
              className={classes.radioCustomBtn}
              style={{
                backgroundColor: add ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)",
                // borderRadius: add ? "16px" : "16px",
                fontWeight: 400,
                color: "#1E3A71",
              }}
            >
              <div
                className="col col-center"
                key={i}
                style={{
                  paddingBottom: "10px",
                  fontWeight: 700,
                }}
              >
                {format(addDays(startDate, i), dateFormat1)}
              </div>
              {" " + formattedDate}
              <br />
              {add ? (
                <img
                  src={imageArray[updateEmojiImg]}
                  alt="img"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : imageIndex > -1 ? (
                <img
                  src={imageArray[imageIndex]}
                  alt="img"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <div style={{ width: "30px", height: "30px" }}></div>
              )}
            </span>
            {/* <span className="bg">{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  const [DaysInMonth, setDaysInMonth] = useState([]);
  useEffect(() => {
    showDate();
  }, []);

  const showDate = () => {
    const currentDate = moment();
    const daysIsmonths = currentDate.daysInMonth();
    const arr = [];
    for (let i = 0; i < daysIsmonths; i++) {
      arr.push(i);
    }
    setDaysInMonth(arr);
  };

  const handleDeleteGoal = () => {
    if (deleteIds && deleteIds.length > 0) {
      //
      dispatch(deleteGoal(deleteIds));
      setDeleteIds([]);
    }
  };
  return (
    <Main >
      <Grid container style={{ display: "flex", flexDirection: "column" }}>
        <Grid item xs={12} md={12} style={{ minHeight: "80px" }}>
          <Navbar />
        </Grid>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            className={classes.mainContainerDiaryStyle}
          >
            {/* Left side Bar Progress and audio dairy Sections*/}

            <Grid xs={12} md={4} lg={2}>
              <Grid
                container
                direction={"row"}
                spacing={2}
                className={classes.progStyle}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <img
                  src={add1}
                  alt="Train"
                  className={classes.DiaryAddImg}
                  
                  width="100%"
                /> */}
                  <AppPremiumBadge />
                  {/* <DiaryProgress /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {true ? (
                    // {isPremiumUser ? (
                    <Grid container style={premiumbox} mb={4}>
                      <Grid item xs={6}>
                        <div>
                          <img
                            src={a5vedio}
                            className={classes.premiumVideoCard}
                            style={{}}
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <img
                            className={classes.premiumAudioCard}
                            src={a4audio}
                            style={{}}
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "0px 20px",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "26px",
                            marginTop: {
                              md: "240px",
                              xs: "300px",
                            },
                          }}
                        >
                          Go Premium and enjoy 1 minute Video diary and Audio
                          Diary feature
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <PremiumButton
                          buttonText="Go Premium"
                          customStyles={{
                            borderRadius: "30px",
                            fontSize: "20px",
                            backgroundColor: "#9747FF",
                            color: "white",
                            padding: "2px 27px",
                            marginTop: "6px",
                          }}
                        // handleClick={() => {
                        //   formRef.current?.click();
                        // }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Box>
                      <img
                        src={add2}
                        alt="Train"
                        width="100%"
                        className={classes.DiaryAddImg}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Left side Bar Progress and audio dairy Sections */}

            <Grid xs={12} md={8} lg={8}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  margin="auto"
                  sx={{ backgroundColor: "white", height: "15%" }}
                >
                  <AppCreate />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.main}
                  sx={{
                    display: "flex",
                    marginTop: "1.5rem",
                  }}
                >
                  {/* date,audio,video,present date main container*/}
                  <Grid
                    container
                    className={classes.mainParticlesLeft}
                  // border="1px solid blue"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    // border="1px solid black"
                    >
                      <Grid
                        container
                        spacing={2}
                      // direction={{ xs: "column", sm: "column", md: "row" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={7}
                        // border="1px solid black"
                        >
                          <DiaryDate
                            setCalendarColor={setCalendarColor}
                            setUpdateEmojiImg={setUpdateEmojiImg}
                            setMoodValue={setMoodValue}
                            moodValue={moodValue}
                            setFeelings={setFeelings}
                          />
                          {/* <DiaryMoodSection /> */}
                        </Grid>
                        {/* Video Diary and Audio Diary Sections */}
                        <Grid item xs={12} sm={12} md={5}>
                          <Stack
                            direction={{
                              xs: "column",
                              sm: "row",
                              md: "column",
                            }}
                            spacing={{ xs: 1, sm: 2, md: 2 }}
                          >
                            {/* First Box VideosSections Parent Container */}
                            <Box
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "364px",
                                  md: "364px",
                                  lg: "100%",
                                  cursor: "pointer",
                                },
                                // mx: 'auto',
                                height: "109px",
                                backgroundColor: "#CAE4FF",
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                              onClick={() => history.push("/diary-editor")}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  gap: "8rem",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      width: "131px",
                                      height: "28px",
                                      fontSize: "24px",
                                      fontFamily: "Raleway",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "28px",
                                      textAlign: "center",
                                      color: "#1E3A71",
                                      marginTop: "12px",
                                    }}
                                  >
                                    Video Diary
                                  </Typography>
                                </Box>
                                <Box marginTop="12px">
                                  <Box
                                    sx={{
                                      width: "24px",
                                      height: "24px",
                                      backgroundColor: "#FEB231",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <img
                                      src={image}
                                      style={{
                                        color: "white",
                                        width: "13.88px",
                                        height: "12.39px",
                                      }}
                                      alt="Diamond"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    width: "146px",
                                    height: "18px",
                                    fontSize: "14px",
                                    fontFamily: "Quicksand",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                    textAlign: "center",
                                    color: "#1E3A71",
                                    marginTop: "12px",
                                  }}
                                >
                                  1 minute video
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  gap: "12rem",
                                }}
                              >
                                <Box>
                                  <img
                                    src={camera}
                                    style={{
                                      color: "white",
                                      width: "48px",
                                      height: "38px",
                                      overflow: "hidden",
                                    }}
                                    alt="camera"
                                  />
                                </Box>
                                <Box>
                                  <img
                                    src={vector}
                                    style={{ width: "20px", height: "14px" }}
                                    alt="vector"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            {/* Second Audio flex Container Sections */}
                            <Box
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "364px",
                                  md: "364px",
                                  lg: "100%",
                                  cursor: "pointer",
                                },
                                height: "109px",
                                backgroundColor: "#CAE4FF",
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                              onClick={() => history.push("/diary-editor")}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  gap: "8rem",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      width: "131px",
                                      height: "28px",
                                      fontSize: "24px",
                                      fontFamily: "Raleway",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "28px",
                                      textAlign: "center",
                                      color: "#1E3A71",
                                      marginTop: "12px",
                                    }}
                                  >
                                    Audio Diary
                                  </Typography>
                                </Box>
                                <Box marginTop="12px">
                                  <Box
                                    sx={{
                                      width: "24px",
                                      height: "24px",
                                      backgroundColor: "#FEB231",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <img
                                      src={image}
                                      style={{
                                        color: "white",
                                        width: "13.88px",
                                        height: "12.39px",
                                      }}
                                      alt="Diamond"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    width: "146px",
                                    height: "18px",
                                    fontSize: "14px",
                                    fontFamily: "Quicksand",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                    textAlign: "center",
                                    color: "#1E3A71",
                                    marginTop: "12px",
                                  }}
                                >
                                  1 minute audio
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  gap: "12rem",
                                }}
                              >
                                <Box>
                                  <img
                                    src={mic}
                                    style={{
                                      width: "48px",
                                      height: "38px",
                                      overflow: "hidden",
                                    }}
                                    alt="mic"
                                  />
                                </Box>
                                <Box>
                                  <img
                                    src={vector}
                                    style={{ width: "20px", height: "14px" }}
                                    alt="vector"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>

                      {/* <Grid item xs={12} sm={12} md={12} lg={8}> */}

                      <Grid item xs={12} sm={12} md={12}>
                        <div
                          className={classes.mainCardDown}
                          style={{ backgroundColor: "#EDF0F2" }}
                        >
                          {/* {renderHeader()} */}
                          <Box
                            sx={{
                              display: "inline-block",
                            }}
                          >
                            <ListItemButton
                              sx={{
                                padding: "0",
                                margin: "0",
                                "&:hover": {
                                  backgroundColor: "none",
                                },
                                // borderRadius: "2rem",
                              }}
                              onClick={handleClick}
                            >
                              <ListItemText
                                primary="Weekly"
                                style={{
                                  fontFamily: "Quicksand",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  lineHeight: "15px",
                                  textAlign: "center",
                                  color: "#000000",
                                  fontStyle: "normal",
                                  marginRight: "10px",
                                  // opacity: "0.5",
                                }}
                              />
                              {open ? (
                                <ExpandLess
                                  sx={{
                                    color: "#000000",
                                    opacity: "0.5",
                                  }}
                                />
                              ) : (
                                <ExpandMore
                                  sx={{
                                    color: "#000000",
                                    opacity: "0.5",
                                  }}
                                />
                              )}
                            </ListItemButton>
                          </Box>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems={"center"}
                            p={0}
                            margin={0}
                          >
                            <Grid
                              justifyContent="center"
                              alignItems={"center"}
                              container
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                            >
                              <FaChevronLeft
                                onClick={() => changeWeekHandle("prev")}
                              />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                              {renderCells()}
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems={"center"}
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                            >
                              <FaChevronRight
                                onClick={() => changeWeekHandle("next")}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Event , Habit & Goals sections */}

                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "flex" },
                      justifyContent: "center",
                      width: "100%",
                      gap: "3rem",
                      backgroundColor: "#FEB231",
                      widht: "692px",
                      height: "49px",
                    }}
                  >
                    <Box
                      sx={{
                        mt: "0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4rem",
                        // border:'3px solid red',
                      }}
                    >
                      <button
                        style={{
                          font: "20px Raleway",
                          fontWeight: "600",
                          lineHeight: "29px",
                          border: "none",
                          marginTop: "3px",
                          backgroundColor:
                            toggl === "Events" ? "#FFFFFF" : "#FEB231",
                          opacity: toggl === "Events" ? "0.6" : "none",
                          width: toggl === "Events" ? "126px" : "63px",
                          height: toggl === "Events" ? "26px" : "30px",
                          borderRadius: toggl === "Events" ? "30px" : "none",
                        }}
                        onClick={() => {
                          setGoalsTracker(false);
                          setHabitTracker(false);
                          setTodoTracker(true);
                          setTogle("Events");
                        }}
                      >
                        Events
                      </button>
                      <button
                        style={{
                          font: "20px Raleway",
                          fontWeight: "600",
                          lineHeight: "29px",
                          border: "none",
                          marginTop: "3px",
                          backgroundColor:
                            toggl === "Habits" ? "#FFFFFF" : "#FEB231",
                          opacity: toggl === "Habits" ? "0.6" : "none",
                          width: toggl === "Habits" ? "126px" : "63px",
                          height: toggl === "Habits" ? "26px" : "30px",
                          borderRadius: toggl === "Habits" ? "30px" : "none",
                        }}
                        onClick={() => {
                          setGoalsTracker(false);
                          setTodoTracker(false);
                          // setHabitTracker(HabitTracker);
                          setHabitTracker(true);
                          setTogle("Habits");
                        }}
                      >
                        Habits
                      </button>
                      <button
                        style={{
                          font: "20px Raleway",
                          fontWeight: "600",
                          lineHeight: "29px",
                          border: "none",
                          marginTop: "3px",
                          backgroundColor:
                            toggl === "Goals" ? "#FFFFFF" : "#FEB231",
                          opacity: toggl === "Goals" ? "0.6" : "none",
                          width: toggl === "Goals" ? "126px" : "63px",
                          height: toggl === "Goals" ? "26px" : "30px",
                          borderRadius: toggl === "Goals" ? "30px" : "none",
                        }}
                        onClick={() => {
                          setHabitTracker(false);
                          setTodoTracker(false);
                          setGoalsTracker(!GoalsTracker);
                          //  setHabitTracker(!HabitTracker)
                          setTogle("Goals");
                        }}
                      >
                        Goals
                      </button>
                    </Box>
                  </Box>
                  {/* Habits Input Section */}
                  <div className={classes.mainContentDownLeft}>
                    {HabitTracker && (
                      <>
                        {" "}
                        <Box
                          style={{
                            // display: "flex",
                            textAlign: "center",
                            marginTop: "3rem !important",
                            justifyContent: "center",
                          }}
                        >
                          <DiaryHabit />
                        </Box>
                      </>
                    )}
                    {/* Goals Input Section */}
                    {GoalsTracker && (
                      <>
                        {" "}
                        <Box
                          style={{
                            textAlign: "center",
                            marginTop: "3rem !important",
                            justifyContent: "center",
                          }}
                        >
                          <NewAppGoalComp />
                        </Box>
                      </>
                    )}
                    {/* Event Input Section */}
                    {TodoTracker && (
                      <>
                        {" "}
                        <Box
                          style={{
                            textAlign: "center",
                            marginTop: "3rem !important",
                            justifyContent: "center",
                          }}
                        >
                          <NewAppEvents />
                        </Box>{" "}
                      </>
                    )}
                  </div>
                </Box>
                {/* Event , Habit & Goals sections */}
              </Grid>
            </Grid>

            {/* Right Side Bar Gorw your event */}
            <Grid xs={12} md={4} lg={2} xl={2}>
              <Grid
                container
                direction={"row"}
                spacing={2}
                className={classes.progStyle}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img
                    src={add1}
                    alt="Train"
                    className={classes.DiaryAddImg}
                    width="100%"
                  />
                  {/* <DiaryProgress /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img
                      src={Area_Train}
                      alt="Train"
                      width="100%"
                      className={classes.DiaryAddImg}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* Right Side Bar Gorw your event */}
          </Grid>
          <Box className={classes.createFabStyles3}>
            <CreatefabButton />
          </Box>
          {/* <Footer /> */}
        </Container >
      </Grid >
    </Main >
  );
}

export default Diary;