import { combineReducers } from 'redux';

import { firebaseReducer } from "react-redux-firebase";
import { clubs } from "./clubs";
import { firestoreReducer } from "redux-firestore";

import { actionTypes } from '../actions';

import { user } from './user'
import { blogs } from './blogs'
import { posts, userPosts } from './posts'
import { postcards } from './postcards'
import { comments } from './comments'
import { diary } from './diary'
import { snackbar } from './snackbar';
import { riddles } from './riddles'
import { images } from './images'
import { journal } from './journal';
import { todos } from './todos';
import { goals } from './goals';
import { library } from './library';
import { aspirations } from './aspirations'
import { rewards } from './rewards';
import { habit } from './habitTracker';
import { subscriptions } from './subscriptions'
import { poems } from './poems';
import { essays } from './essay';
import { reviewer } from './reviewer'
import { roles } from './roles'
import { siteInsights } from './siteInsights'
import { complaints } from './complaints';
import { message_announcement } from './message_announcement'
import { permissions } from './permissions';
import { notifications } from './notifications'

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
	const { type, error } = action;

	if (type === actionTypes.RESET_ERROR_MESSAGE) {
		return null;
	} else if (error) {
		return error;
	}
	return state;
};

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	errorMessage,
	user,
	blogs,
	posts,
	postcards,
	comments,
	diary,
	userPosts,
	snackbar,
	riddles,
	images,
	journal,
	todos,
	goals,
	library,
	aspirations,
	rewards,
	habit,
	subscriptions,
	poems,
	essays,
	reviewer,
	notifications,
	roles,
	siteInsights,
	complaints,
	message_announcement,
	permissions,
	clubs,
});

export default rootReducer;