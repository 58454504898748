const Data={
    time:[
        {
            status:false,
            name:'Last 5 days',
            value:5
        },
        {
            status:false,
            name:'Last Month',
            value:30
        },
        {
            status:false,
            name:'Last Year',
            value:365
        },
        {
            status:false,
            name:'All Time',
            value:99999999
        },
    ],
    status:[
        {
            status:false,
            name:'All',
            value:'All'
        },
        {
            status:true,
            name:"Open",
            value:"Open"
        },
        {
            status:false,
            name:"Closed",
            value:"Closed"
        },
        {
            status:false,
            name:"Archived",
            value:'Archived'
        }
    ],
    category:[
        {
            status:false,
            name:'Technical Error',
            value:'Technical Error'
        },
        {
            status:false,
            name:'Complaints',
            value:'Complaint'
        },
        {
            status:false,
            name:'Suggestions',
            value:'Suggestion'
        },
        {
            status:false,
            name:'Post Reports',
            value:'Post Report'
        },
    ],
    sortby:[
        {
            status:false,
            name:'Newest First',
            value:'asc'
        },
        {
            status:false,
            name:'Oldest First',
            value:'asc'
        },
        {
            status:false,
            name:'Post Report First',
            value:'Post Report'
        },
        {
            status:false,
            name:'Complaints First',
            value:'Complaint'
        },
        {
            status:false,
            name:'Suggestions First',
            value:'Suggestion'
        },
    ],
    search:[
        {
            status:false,
            name:"Search",
            value:""
        },
    ]
}

export default Data