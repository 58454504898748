import { makeStyles } from "@mui/styles";

const SecretDiaryStyles = makeStyles((theme) => ({
  // MainDiv {
  //     padding: 4rem;
  //     background: #f8f8f8;
  //   }
  postImg: {
    width: "100%",
  },

  headingTypo: {
    fontFamily: "Museo 700",
    textalign: "center",
    fontSize: "40px",
    letterSpacing: "0.1rem",
    color: "#453f3f",
    justifyContent: "center",
    textAlign: "left",
    padding: "10px",
  },

  headingTypo1: {
    color: "#000",
    fontSize: "22px",
    justifyContent: "center",
    textAlign: "left",
    padding: "10px",
    fontFamily: "sans-serif",
  },

  imgbox: {
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    boxShadow: "0 2px 2px #ccc",
    marginTop: "-10px ",
    // padding: '10px'
  },

  spantext: {
    fontSize: "1.5rem",
    color: "#453f3f",
    paddingLeft: "4px",
    width: "20px",
  },
  container: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    width: "100%",
    margin: "0 0",
  },
  visionImage: {
    position: "absolute",
    // left: "150px",
    top: "150px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "0px",
      width: "100%",
    }
  },
  visionBox: {
    paddingTop: "7rem",
    paddingBottom: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    }
  },
  leadershipCard: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      maxWidth: "100%",
      flexFlow: "column",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    }
  },
  leadershipText: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      maxWidth: "100%",
      flexFlow: "column",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    }
  },
  leadershipHead: {
    fontSize: "20px",
    color: "#434343",
    paddingTop: "1rem",
    paddingBottom: "2rem",
    paddingLeft: "25rem",
    paddingRight: '25rem',
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingLeft: "0px",
      fontSize: "20px",
      color: "#434343",
    }
  },
  '& .table': {
    fontFamily: 'arial, sans-serif',
    borderCollapse: 'collapse',
    maxWidth: '60% !important',
    margin: 'auto',
    background: 'yellow'
  },

  '& th': {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px'
  },
  '& td': {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px'
  }


  /* .Row-div {
    position: relative;
    margin: 50px 0 45px;
  } */

  // .Row-div {
  //   margin-bottom: 24px;
  // }

  // .cards__item {
  //   display: flex;
  //   flex: 1;
  //   margin: 0 1rem;
  //   border-radius: 10px;
  // }

  // .cards__item__link {
  //   display: flex;
  //   flex-flow: column;
  //   width: 100%;
  //   box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  //   -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  //   filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  //   border-radius: 10px;
  //   overflow: hidden;
  //   text-decoration: none;
  // }

  // .cards__item__pic-wrap {
  //   position: relative;
  //   width: 100%;
  //   padding-top: 67%;
  //   overflow: hidden;
  // }

  // .fade-img {
  //   animation-name: fade-img;
  //   animation-duration: 2s;
  // }

  // .cards__item__pic-wrap::after {
  //   content: attr(data-category);
  //   position: absolute;
  //   bottom: 0;
  //   margin-left: 10px;
  //   padding: 6px 8px;
  //   max-width: calc((100%) - 60px);
  //   font-size: 12px;
  //   font-weight: 700;
  //   color: #fff;
  //   background-color: #1f98f4;
  //   box-sizing: border-box;
  // }

  // .cards__item__img {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   display: block;
  //   width: 100%;
  //   max-width: 100%;
  //   height: 100%;
  //   max-height: 100%;
  //   object-fit: cover;
  //   transition: all 0.2s linear;
  // }

  // .cards__item__img:hover {
  //   transform: scale(1.1);
  // }

  // .cards__item__info {
  //   padding: 20px 30px 30px;
  // }

  // .cards__item__text {
  //   color: #252e48;
  //   font-size: 18px;
  //   line-height: 24px;
  // }

  // @media only screen and (min-width: 1200px) {
  //   .content__blog__container {
  //     width: 84%;
  //   }
  // }

  // @media only screen and (min-width: 1024px) {
  //   .cards__items {
  //     display: flex;
  //   }
  // }

  // @media only screen and (max-width: 1024px) {
  //   .cards__item {
  //     margin-bottom: 2rem;
  //   }
  // }
}));

export default SecretDiaryStyles;
