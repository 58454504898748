import { createStore, applyMiddleware, compose } from "redux";
import { reduxFirestore, getFirestore } from 'redux-firestore';
import rootReducer from "./reducers";
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';
import firebaseApp from '../config/fbConfig';
import logger from 'redux-logger';

/*
const firebaseConfig = {
  apiKey: "AIzaSyCrgWDippc5Voo09hPkpCFWlFHy-eVJX3A",
  authDomain: "quillatwill-b2b.firebaseapp.com",
  projectId: "quillatwill-b2b",
  storageBucket: "quillatwill-b2b.appspot.com",
  messagingSenderId: "240463715190",
  appId: "1:240463715190:web:5d3af906aba51329d33857",
  measurementId: "G-R4XCW5EQ18"
};
*/
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const middlewares = [
  thunk.withExtraArgument(getFirebase),
  thunk.withExtraArgument(getFirestore),
  // logger
]
const createStoreWithFirebase = compose(
  // firebase instance as first argument, rfConfig as optional second
  compose(
    reduxFirestore(firebaseApp, firebaseConfig),
    applyMiddleware(...middlewares)
  ),
)(createStore);

//const store = createStore(rootReducer, applyMiddleware(thunk));
const initialState = {};
const store = createStoreWithFirebase(rootReducer, initialState);
export default store;
