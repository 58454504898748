import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IoMdLock } from "react-icons/io";
import { useHistory } from "react-router-dom";

const pages = ["Save", "Share", "Preview"];

const Diaryheader = (props) => {
  const history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="static"
        style={{ marginTop: "0rem !important", backgroundColor: "#fff",marginTop:'-81px' }}>
        <Container maxWidth="false">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    color: "#777777",
                    fontSize: "17px",
                    fontFamily: "Museo700",
                  }}
                  onClick={() => history.push("/diary")}
                >
                  <ArrowBackIosNewIcon
                    style={{ color: "#777777", width: "28%", margin: "8px" }}
                  />
                  Back
                </Button>
              </Stack>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              style={{ fontFamily: "Museo700",color: "#777777"  }}
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              onClick={() => history.push("/diary")}
            >
              Back
            </Typography>
            <Box
              style={{
                borderLeft: "1px solid #FFFFFF",
                marginLeft: "18px",
                // color: "red",
                width: "2%",
                height: "27px",
              }}
            ></Box>
            {/* <Typography variant="h6" fontFamily="Museo700">
              My Diary Name Here
            </Typography> */}
            {/* <HiOutlinePencil style={{margin:'20px'}}/> */}

            <Box
              sx={{
                flexGrow: 2,
                display: { xs: "flex", md: "flex", justifyContent: "end" },
              }}
            >
              {/* <Box>
                <IoMdLock fontSize="22px" color="white" />
              </Box> */}

              <Button
                // className={classes.root1}
                onClick={() => props.onSave()}
                sx={{
                  marginLeft: "20px",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#FFDB00",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                  "&: hover": {
                    backgroundColor: "yellow",
                  },
                }}
                variant="contained"
              >
                Save
              </Button>
              {/* <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                save
              </Button> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Diaryheader;