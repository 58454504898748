import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import PoemTypeImg from "../../../assets/images/DiaryImages/PoemTypeImg.jpg";
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "./SecretDiaryStyles";
import Footer from "../../Footer";

function TypesOfPoem() {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Box
                style={{
                    background: "#fff",
                }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Online Diary | Online Diary Writing | Quill at Will</title>
                    <meta
                        name="title"
                        content="Online Diary | Online Diary Writing | Quill at Will"
                    />
                    <meta
                        name="description"
                        content="Online Diary - Quill at Will is the secure place to record your day-to-day experience, feelings & thoughts. Let's Create your own free e-diary or journal and get started now!"
                    />
                    <meta
                        name="keywords"
                        content="Blog Writing Platform"
                    />
                </Helmet>

                <Grid container style={{
                    display: 'flex', justifyContent: "center", marginTop: '6rem', marginBottom: "3rem"
                }}>
                    <Grid item xs={12} sm={12} md={10} lg={10} style={{
                        borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                        padding: '20px'
                    }}>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            Explore the World of Poems - Carve Your Niche
                        </Typography>
                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            Types of poems with examples by famous poets
                        </Typography>
                        <Grid container spacing={3} stype={{ marginTop: '1rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <img src={PoemTypeImg} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                                <Grid container >
                                    <Box >
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "18px" }}
                                        >
                                            Online poem writing platform - Quill at Will is a vividly designed website to catch your attention with its colourful designs and fun features. It offers you templates to write haiku, couplets, sonnets, and free verse where you can write in the format of your choice. This article describes the six common types of poetry and their unique features which will help you in online poem writing, learn the art of writing poems along with some noteworthy examples by world renowned poets.
                                            <br />
                                            <br />
                                            Quick Note- Please refer to our <a href="/hub/guide/poem" > Product Guide on poems </a>  under Hub, for a glossary of commonly used terms in poetry and learn how to write a poem in step-by step method.
                                            <br />
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Haiku
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "18px" }}>
                                            A haiku is traditionally a Japanese form of poetry with three short lines that do not rhyme. The art of haiku lies in its ability to convey vivid images in just a few words. The origins of haiku poems can be traced back to as far as the 9th century. Many modern western poets do not subscribe to the 5-7-5 pattern. The modern haiku continues to focus on one brief moment in time, employ provocative, colourful imagery, and provide a moment of illumination.
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                            Traditional Haiku
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px" }}>
                                            An example-
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "25px" }}
                                    >
                                        “The Old Pond” by Matsuo Bashō

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        An old silent pond
                                        <br />
                                        <br />

                                        A frog jumps into the pond—
                                        <br />
                                        <br />

                                        Splash! Silence again.
                                        <br />

                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px", }}>
                                        <br />
                                        This traditional example comes from Matsuo Bashō, one of the four great masters of Haiku.
                                    </Typography>
                                    <br />
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                        Modern 20th century haiku
                                        <br />
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        An example-
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        Nightfall,
                                        <br />
                                        <br />

                                        Too dark to read the page
                                        <br />
                                        <br />

                                        Too cold.
                                        <br />
                                        <br />

                                        - Jack Kerouac

                                        <br />

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "25px" }}
                                    >
                                        Couplets
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "18px" }}>
                                        Couplets can be defined as two lines of poetic verse, usually but not always in the same meter and of about the same length, which are often (but not always) connected by rhyme and form a unit (i.e., that go together and/or stand alone).
                                        <br />
                                        A couplet can form the entirety of a poem, or it can be used within a more complex form such as a sonnet or villanelle. One of the greatest wordsmiths of all time, William Shakespeare, who's actually credited with creating English words, also liked to add a couplet or two to his writing.

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                        Traditional couplets
                                        <br />
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        Some excerpts of couplets from famous works-

                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        Tyger! Tyger! burning bright
                                        <br />
                                        <br />

                                        In the forests of the night ...
                                        <br />
                                        <br />

                                        <b>―William Blake, "The Tyger"</b>
                                        <br />
                                        <br />
                                        <br />

                                        Double, double, toil and trouble;
                                        <br />
                                        <br />

                                        Fire burn and cauldron bubble.
                                        <br />
                                        <br />
                                        <b> ―William Shakespeare, "Macbeth"</b>
                                        <br />

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                        Modern Couplets
                                        <br />
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        Not just poets and writers of the past, some modern writers employ couplets as well. For instance, children's author <b> Shel Silverstein </b> included some great examples of couplets in his poems. Let’s see this example from his poem <b>“Sick”</b>


                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        "I have the measles and the mumps,

                                        <br />
                                        <br />

                                        a gash, a rash and purple bumps. "

                                        <br />
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        While many couplets rhyme, they don't have to. You can find several examples of couplets without rhyme.
                                        The beginning of <b>Robert Creeley's poem, "The Innocence," </b> starts with a couplet.
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        "Looking to the sea, it is a line
                                        <br />
                                        <br />
                                        of unbroken mountains."
                                        <br />
                                        <br />

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "25px" }}
                                    >
                                        Sonnet
                                    </Typography>


                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "18px" }}>
                                        The word ‘sonnet’ is derived from the Italian word ‘sonnetto’ which means ‘little song’. Sonnets usually deal with love.
                                        <br />
                                        The traditional English sonnet typically ends with a rhymed couplet that "wraps things up." The most common English forms of the sonnet are the Spenserian sonnet (rhyme scheme abab bcbc cdcd ee), the Shakespearean sonnet (rhyme scheme abab cdcd efef gg) and the Petrarchan sonnet (rhyme scheme abba abba cdecde), although there are other variations too. Each type contains 14 lines but comes with its own set of rules. Sonnets offer an amazing insight into the historical perspectives as they have been around for centuries across cultures.
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        Example- Sonnet 104 by Shakespeare
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        To me, fair friend, you never can be old,
                                        <br />
                                        <br />

                                        For as you were when first your eye I eyed,
                                        <br />
                                        <br />

                                        Such seems your beauty still. Three winters cold
                                        <br />
                                        <br />

                                        Have from the forests shook three summers’ pride,
                                        <br />
                                        <br />

                                        Three beauteous springs to yellow autumn turned
                                        <br />
                                        <br />

                                        In process of the seasons have I seen,
                                        <br />
                                        <br />

                                        Three April perfumes in three hot Junes burned,
                                        <br />
                                        <br />

                                        Since first I saw you fresh, which yet are green.
                                        <br />
                                        <br />

                                        Ah, yet doth beauty, like a dial hand,
                                        <br />
                                        <br />

                                        Steal from his figure and no pace perceived;
                                        <br />
                                        <br />

                                        So your sweet hue, which methinks still doth stand,
                                        <br />
                                        <br />

                                        Hath motion and mine eye may be deceived;
                                        <br />
                                        <br />

                                        For fear of which, hear this, thou age unbred:
                                        <br />
                                        <br />

                                        ‘Ere you were born was beauty’s summer dead.

                                        <br />
                                        <br />
                                    </Typography>


                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "25px" }}
                                    >
                                        Limerick
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "18px" }}>
                                        Limericks are humorous poems that have a more distinct rhythm. Due to their short and simple structure, limericks are a popular form among amateur poets. Here is a famous poem by Edward Lear, a British poet, widely regarded as Father of Limericks.
                                        <br />
                                        These are generally funny and sometimes rude in nature.

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                        Example of a traditional limerick
                                        <br />
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        There was an Old Man with a beard
                                        <br />
                                        <br />
                                        Who said, "It is just as I feared!
                                        <br />
                                        <br />
                                        Two Owls and a Hen,
                                        <br />
                                        <br />
                                        Four Larks and a Wren,
                                        <br />
                                        <br />
                                        Have all built their nests in my beard!"

                                        <br />
                                        <br />
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "20px" }}>
                                        Limericks often appear as nursery rhymes. Perhaps the most widely recited of these is <b>“Hickory Dickory Dock”
                                        </b>
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                        Hickory, dickory, dock.
                                        <br />
                                        <br />
                                        The mouse ran up the clock.
                                        <br />
                                        <br />
                                        The clock struck one,
                                        <br />
                                        <br />
                                        The mouse ran down,
                                        <br />
                                        <br />
                                        Hickory, dickory, dock.

                                        <br />
                                        <br />

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "18px" }}>
                                            Limericks are usually written in five lines with an aabba rhyme scheme, clearly showing their intended audiences as well as the bawdiness, nonsense, humour, and delightful storytelling simplicity of the form.
                                            <br />
                                            The first couplet sets location and subject, second couplet describes an action, and the final line is a punchline, often shocking and/or humorous.


                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                            Example of a modern limerick
                                            <br />
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                            Our language is running amuck
                                            <br />
                                            <br />
                                            Slapped around like an old hockey puck
                                            <br />
                                            <br />
                                            Verbs like "to be"
                                            <br />
                                            <br />
                                            Are trashed endlessly
                                            <br />
                                            <br />
                                            And syntax's a matter of luck!
                                            <br />
                                            <br />
                                            <b>-Doug Jenkins</b>


                                            <br />
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Ode
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "18px" }}>
                                            The ode type of poetry was originated by Sappho and defined by Pindar and Horace. The lengthy, lyrical ode features elaborate stanza structures and stateliness in tone and style. These were created by the Greeks to provide choral accompaniment. For nearly three millennia, the ode has been one of the most elaborate and dignified poetic expressions ever developed. Originally created to provide choral accompaniment to musical instruments, the ode evolved into a lyric poem that praised and glorified individuals, accomplishments, and victories, while also painting observant portraits of nature.
                                            <br />
                                            <br />
                                            Ode was originally structured in three acts:    <br />
                                            strophe (one side of story),    <br />
                                            antistrophe (other side of story), and     <br />
                                            epode (review)

                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                            Example of Ode
                                            <br />

                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px" }}>
                                            <b> “Intimations of Immortality from Recollections of Early Childhood”  by William Wordsworth  </b>is a good example of a more modern poem in the Pindaric style.

                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                            "There was a time when meadow, grove, and stream,

                                            <br />
                                            <br />
                                            The earth, and every common sight

                                            <br />
                                            <br />
                                            To me did seem

                                            <br />
                                            <br />
                                            Apparelled in celestial light,

                                            <br />
                                            <br />
                                            The glory and the freshness of a dream.

                                            <br />
                                            <br />
                                            It is not now as it hath been of yore;

                                            <br />
                                            <br />
                                            Turn wheresoe'er I may,

                                            <br />
                                            <br />
                                            By night or day,

                                            <br />
                                            <br />
                                            The things which I have seen I now can see no more."

                                            <br />
                                            <br />
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Free Verse
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "18px" }}>
                                            Free verse is typically a poem without defined rhyme or rhythm limitations. Free verse poetry may be the best entry into this exploration of the types of poetry because it is less structured and likely easier for people to write themselves.
                                            <br />
                                            <br />
                                            Free verse makes liberal use of line breaks, lack of rhyming, word choices, and lengths to create poems that reflect the writer’s deepest feelings and natural speech rhythms. Poet Harvey Stanbrough (Beyond the Masks) likes to say of free verse, "There’s nothing truly free about it. All well-written free verse is metrically sound."  Common themes in a free verse poem are love, tragedy, nature, politics, liberation, revelation etc. In a free verse, single stanzas can exceed 100 lines, or begin and end in two lines. Poem writing in English is the easiest in free verse format for a beginner, to express their thoughts in the form of creative poems and start their journey to become a poet.


                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                            Example of free verse
                                            <br />

                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px" }}>
                                            Known as the father of free verse, poet <b>Walt Whitman</b> created several free verse poems, including <b> "After the Sea-Ship."</b>


                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                            "After the Sea-Ship--after the whistling winds;
                                            <br />
                                            <br />
                                            After the white-gray sails, taut to their spars and ropes,
                                            <br />
                                            <br />
                                            Below, a myriad, myriad waves, hastening, lifting up their necks,
                                            <br />
                                            <br />
                                            Tending in ceaseless flow toward the track of the ship:
                                            <br />
                                            <br />
                                            Waves of the ocean, bubbling and gurgling, blithely prying,
                                            <br />
                                            <br />
                                            Waves, undulating waves--liquid, uneven, emulous waves,
                                            <br />
                                            <br />
                                            Toward that whirling current, laughing and buoyant, with curves,
                                            <br />
                                            <br />
                                            Where the great Vessel, sailing and tacking, displaced the surface ..."
                                            <br />
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "20px" }}>
                                            Famous free verse wordsmith <b> Robert Graves</b>  uses sporadic rhyme within this work; however, there is no specific rhyme scheme.

                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'center', fontFamily: 'Museo300' }}>
                                            "I now delight
                                            <br />
                                            <br />
                                            In spite
                                            <br />
                                            <br />
                                            Of the might
                                            <br />
                                            <br />
                                            And the right
                                            <br />
                                            <br />
                                            Of classic tradition,
                                            <br />
                                            <br />
                                            In writing
                                            <br />
                                            <br />
                                            And reciting
                                            <br />
                                            <br />
                                            Straight ahead,
                                            <br />
                                            <br />
                                            Without let or omission,
                                            <br />
                                            <br />
                                            <br />

                                            In any little time
                                            <br />
                                            <br />
                                            That runs in my head;
                                            <br />
                                            <br />
                                            Because, I’ve said,
                                            <br />
                                            <br />
                                            My rhymes no longer shall stand arrayed
                                            <br />
                                            <br />
                                            Like Prussian soldiers on parade
                                            <br />
                                            <br />
                                            That march,
                                            <br />
                                            <br />
                                            Stiff as starch,
                                            <br />
                                            <br />
                                            Foot to foot,
                                            <br />
                                            <br />
                                            Boot to boot,
                                            <br />
                                            <br />
                                            Blade to blade"

                                            <br />
                                            <br />

                                            <Typography
                                                className={classes.headingTypo1}
                                                style={{ fontSize: "20px", textDecoration: 'underline' }}>
                                                Example of modern instapoetry
                                                <br />

                                            </Typography>
                                            <Typography
                                                className={classes.headingTypo1}
                                                style={{ fontSize: "25px", }}
                                            >
                                                <b>  Legacy by Rupi Kaur</b>
                                            </Typography>

                                            <Typography
                                                className={classes.headingTypo}
                                                style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300', display: 'flex', justifyContent: 'center' }}>
                                                I stand
                                                <br />
                                                on the sacrifices
                                                <br />
                                                of a million women before me
                                                <br />
                                                thinking
                                                <br />
                                                what can I do
                                                <br />
                                                to make this mountain taller
                                                <br />
                                                so the women after me
                                                <br />
                                                can see farther
                                                <br />
                                            </Typography>
                                            <Typography
                                                className={classes.headingTypo1}
                                                style={{ fontSize: "20px" }}>
                                                If you suspect you have a knack for poetic storytelling, then the gift is already inside you. Study each of these six types and explore with the tips for poem writing. Start by seeing which one feels closest to your heart, then begin. Let the verses pour out of your soul! Start on your path to becoming a poet by reading and writing poems online on www.quillatwill.com.

                                            </Typography>
                                        </Typography>


                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default TypesOfPoem;