import firebase from "firebase/compat/app";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { db } from "../../config/fbConfig";
import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";

const CLUBS = [
  {
    bannerUrl: "",
    categories: [],
    content:
      "Get closer to the magic every day - join the Harry Potter Fan Club for free",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "harry-potter",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Fharry-potter%2FHarry-Potter.jpg?alt=media&token=c0387781-cc46-410c-bc38-be5b18387b0d",
    title: "Harry Potter",
    updatedAt: Date.now(),
  },
  {
    bannerUrl: "",
    categories: [],
    content:
      "Welcome to Short Stories Club. It's like a book club, but for short stories!",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "art-and-music",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Fart-and-music%2FArt-music.jpg?alt=media&token=2d69478f-c608-4143-8936-da875bd765c0",
    title: "Art & Music",
    updatedAt: Date.now(),
  },
  {
    bannerUrl: "",
    categories: [],
    content:
      "A traveler without observation is a bird without wings - join the Travel Club for free",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "travel",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Ftravel%2FTravel.png?alt=media&token=4faf7884-03d6-449f-a072-f91b1cb53663",
    title: "Travel",
    updatedAt: Date.now(),
  },
  {
    bannerUrl: "",
    categories: [],
    content:
      "How to find new books? Join this online Book Club to meet new people across the country. Start reading a new book with a new group.",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "books",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Fbooks%2FBooks.avif?alt=media&token=92c319ed-05ef-4261-95dc-f4c2f67c1ef9",
    title: "Books",
    updatedAt: Date.now(),
  },
  {
    bannerUrl: "",
    categories: [],
    content:
      "Chase the vision, not the money; the money will end up following you - join the Startup Club for free",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "entrepreneurship",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Fentrepreneurship%2FEnterpreneurship.png?alt=media&token=d386a87c-ba2e-4c34-b960-a63563ed697d",
    title: "Entrepreneurship",
    updatedAt: Date.now(),
  },
  {
    bannerUrl: "",
    categories: [],
    content:
      "Join this online Bookish Club to meet new people across the country.",
    createdAt: Date.now(),
    followers: [],
    lastUpdatedAt: Date.now(),
    metaTitle: "",
    publishedAt: Date.now(),
    slug: "sports",
    status: true,
    tags: [],
    thumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/clubs%2Fsports%2FSports_club.jpg?alt=media&token=d5fe938e-e347-475f-8ce7-0d04e33a50ae",
    title: "Sports",
    updatedAt: Date.now(),
  },
];

const addNewClub = (club) => {
  db.collection("clubs")
    .add(club)
    .then(() => console.log("Added club"))
    .catch(() => console.log("Could not add club"));
};

export const addAllClubs = (payload) => (dispatch, getState) => {
  CLUBS.map((club) => {
    addNewClub(club);
  });
  db.collection("clubs")
    .get()
    .then((docSnapshot) => {
      docSnapshot.docs.map((doc) => {
        if (!doc.data().slug.includes("/"))
          db.collection("clubs")
            .doc(doc.id)
            .set({ slug: doc.data().slug + "/" + doc.id }, { merge: true })
            .then(() => console.log("Added slug"))
            .catch((err) => console.log("AddSlug error:", err));
      });
    })
    .catch((err) => console.log("AddSlug error:", err));
};

export const getAllClubs = (payload) => (dispatch, getState) => {
  db.collection("clubs").onSnapshot(
    (docSnapshot) => {
      const clubs = docSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        followers: doc.data().followers?.map((follower) => follower.id),
      }));
      // console.log("Clubs:", clubs);
      dispatch(triggerReducer("GET_ALL_CLUBS", { payload: clubs }));
    },
    (err) => console.log("Error getAllClubs:", err)
  );
};

export const followClub =
  ({ clubID, follower }) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      db.collection("clubs")
        .doc(clubID)
        .update({
          followers: firebase.firestore.FieldValue.arrayUnion(follower),
        })
        .then(() => {
          console.log("Followed!");
          resolve();
        })
        .catch((err) => {
          console.log("followClub err:", err);
          reject();
        });
    });
  };

export const getAuthor =
  ({ authorId }) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      db.collection("users")
        .doc(authorId)
        .get()
        .then((doc) => {
          console.log("Author found!");
          resolve({ ...doc.data(), id: doc.id });
        })
        .catch((err) => {
          console.log("getAuthor error err:", err);
          reject(err);
        });
    });
  };

export const addNewPost =
  ({ clubId, post }) =>
  (dispatch, getState) => {
    db.collection("clubs")
      .doc(clubId)
      .collection("posts")
      .add(post)
      .then(() => console.log("Added post"))
      .catch(() => console.log("Could not add post"));
  };

export const getAllPosts =
  ({ clubId }) =>
  (dispatch, getState) => {
    console.log("getAllPosts", "Action",clubId);
    db.collection("clubs")
      .doc(clubId)
      .collection("posts")
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (docSnapshot) => {
          const posts = docSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          console.log("Posts:", posts,"shakir");
          dispatch(triggerReducer("GET_ALL_POSTS", { payload: posts }));
        },
        (err) => console.log("Error getAllPosts:", err)
      );
  };

export const likePost =
  ({ clubId, postId, userId }) =>
  (dispatch, getState) => {
    db.collection("clubs")
      .doc(clubId)
      .collection("posts")
      .doc(postId)
      .update({ likedBy: arrayUnion(userId) })
      .then(() => console.log("Liked post"))
      .catch(() => console.log("Could not like post"));
  };
export const dislikePost =
  ({ clubId, postId, userId }) =>
  (dispatch, getState) => {
    db.collection("clubs")
      .doc(clubId)
      .collection("posts")
      .doc(postId)
      .update({ likedBy: arrayRemove(userId) })
      .then(() => console.log("Disliked post"))
      .catch(() => console.log("Could not dislike post"));
  };
export const answerPoll =
  ({ clubId, postId, response, options }) =>
  (dispatch, getState) => {
    db.collection("clubs")
      .doc(clubId)
      .collection("posts")
      .doc(postId)
      .set(
        { form: { responses: arrayUnion(response), options: options } },
        { merge: true }
      )
      .then(() => console.log("Answered Poll"))
      .catch(() => console.log("Could not answer poll"));
  };
