import React, { useEffect, useState } from "react";
import Main from "../Main";
import { Button, Grid, Paper, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import GridViewIcon from '@mui/icons-material/GridView';
import Navbar from "../Navbar";
import TopBar from "../TopBar";
import { makeStyles, styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getCounts } from "../../store/actions/siteInsights";
import SiteActivity from "./SiteActivity";
import UserActivity from "./UserActivity";

const TopBarStyles = makeStyles((theme) => ({
    mainGrid: {
        '& .css-1cndkqf-MuiToolbar-root': {
            minHeight: '40px',
            height: '70px'
        }
    },
}));

const SiteInsights = (props) => {
    const classes = TopBarStyles()
    const [index, setIndex] = useState(0)

    const styles = {
        backgroundColor: '#fff',
        backgroundImage: 'none',
    }

    return (
        <Main styles={styles}>
            <Grid container style={{ display: 'flex', flexDirection: 'column', }}>
                <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                    <Navbar />
                </Grid>
                <Grid container item xs={12} md={12} style={{ margin: "auto" }} >
                    <Grid item xs={12} md={12} style={{
                        backgroundColor: "#1d396f",
                        backgroundImage: 'linear-gradient(to right, darkblue, aqua)'
                    }}
                        className={classes.mainGrid}
                    >
                        <TopBar page={2} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', paddingTop: "2.5rem" }}>
                    <Grid container item xs={1} md={1} lg={1}
                        style={{ minHeight: '85vh', backgroundColor: '#607d8b2e', alignItems: 'start' }}
                        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
                    >
                        {/* <Grid container item xs={1} md={1} lg={1} style={{ minHeight: '85vh', backgroundColor: '#607d8b2e', alignItems: 'start' }}> */}
                        <Grid item md={12} style={{ display: 'grid', justifyContent: 'center', padding: '10px' }}>
                            <GridViewIcon style={{
                                fontSize: "40px",
                                padding: "10px",
                                background: index === 0 ? "#fff" : "#13135e",
                                borderRadius: "10px",
                                color: index === 0 ? "#13135e" : "#fff",
                                cursor: 'pointer'
                            }}
                                onClick={() => setIndex(0)}
                            />
                            <PeopleAltIcon style={{
                                marginTop: "0.9rem",
                                fontSize: "40px",
                                padding: "10px",
                                background: index === 1 ? "#fff" : "#13135e",
                                borderRadius: "10px",
                                color: index === 1 ? "#13135e" : "#fff",
                                cursor: 'pointer'
                            }}
                                onClick={() => setIndex(1)}
                            />
                            {/* <PieChartIcon style={{
                                marginTop: "0.6rem",
                                fontSize: "30px",
                                padding: "10px",
                                background: index === 2 ? "#fff" : "#13135e",
                                borderRadius: "10px",
                                color: index === 2 ? "#13135e" : "#fff",
                                cursor:'pointer'
                            }}
                                onClick={() => setIndex(2)}
                            /> */}
                        </Grid>

                    </Grid>
                    {
                        index === 0 && <SiteActivity />
                    }


                    {
                        index === 1 && <UserActivity />


                    }
                </Grid>
            </Grid>
        </Main>
    );
}

export default SiteInsights;
