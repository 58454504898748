import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
    notifications: [],
};

export const notifications = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload,
            };
        case actionTypes.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload
            }
        default:
            return state;
    }
};
