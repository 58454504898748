import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import BookImageSrc from '../../../assets/images/dashboard/book-1.png';
import { useHistory } from "react-router-dom";
import { getBlogOfTheWeek } from "../../../store/actions/posts";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: '1.4rem',
  color: theme.palette.info.darker,
  backgroundColor: '#FFB5C9',
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: "25px",
  height: '46vh',
  [theme.breakpoints.down("sm")]: {
    marginTop: '0rem'
  }
}));

export default function AppBlogOfTheWeek() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { blogOfTheWeek } = useSelector(state => state.posts);
  const { tenantId } = useSelector(state => state.user)

  useEffect(() => {
    if (tenantId)
      dispatch(getBlogOfTheWeek())
  }, [tenantId])

  const getUsername = () => {
    let name = ''
    let { user } = blogOfTheWeek
    if (user) {
      if (user.hasOwnProperty('displayName')) {
        name = user.displayName
      }
      if (user.hasOwnProperty('name')) {
        let temp = user.name
        user = temp.length > name.length ? temp : name
      }
      if(user.hasOwnProperty('firstName')){
        let temp = user.firstName + ' ' + user?.lastName
        user = temp.length > name.length ? temp : name
      }
    }
    return name;
  }
  return (
    <RootStyle onClick={() => history.push('/blog/' + blogOfTheWeek.id + '/' + blogOfTheWeek.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())} style={{ cursor: 'pointer' }}>
      <img
        style={{
          width: '7rem',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        src={BookImageSrc}
        alt="Book_Icon"
      />
      <Typography style={{
        lineHeight: 'normal', fontSize: "26px", fontFamily: "Museo 700", color: 'rgb(0, 31, 88)'
      }}>
        Blog of the Week
      </Typography>

      {
        blogOfTheWeek ?
          <>
            <Typography variant="h6" style={{ fontWeight: 100, fontSize: '0.9rem !important' }}>
              {
                blogOfTheWeek?.blogId.label.slice(0, 20) + '...' ?? ''
              }
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 100, fontSize: '0.9rem !important' }}>
              {
                blogOfTheWeek?.title.slice(0, 20) + '...' ?? ''
              }
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72, fontSize: '0.9rem' }}>
              {
                getUsername()
              }
            </Typography>
          </>
          : null
      }

    </RootStyle>
  );
}
