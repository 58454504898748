import { actionTypes } from '../actions';

const INITIAL_STATE = {
    snackBarOpen: false,
    snackBarType: null,
    snackBarMessage: null,
    isPageLoading: false
};

export const snackbar = (state = INITIAL_STATE, action = undefined) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SNACKBAR_SUCCESS:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "success",
                snackBarMessage: payload
            };
        case actionTypes.SNACKBAR_ERROR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: payload
            };
        case actionTypes.SNACKBAR_INFO:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "info",
                snackBarMessage: payload
            };
        case actionTypes.SNACKBAR_CLEAR:
            return {
                ...state,
                snackBarOpen: false,
            };
        case actionTypes.IS_PAGE_LOADING:
            return {
                ...state,
                isPageLoading: payload
            };
        default:
            return state;
    }
};
