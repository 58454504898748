import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Stack,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";
import Footer from "../Footer";
import PostCardStyles from "./PostCardStyles";

//blog code
import AppCreate from "../Blog/blogSections/AppCreate";
import PosctacrdAccordian from "./PostcardSections/PostcardAccordian";
import PostcardSendData from "./PostcardSections/PostcardSendData";
import PostcardDetails from "./PostcardSections/PostcardDetails";
import PostcardMyRoom from "./PostcardSections/PostcardMyRoom";
import CreatefabButton from "../Dashboard/CreateFabButton";

import Navbar from "../Navbar";
import Main from "../Main";


const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

function PostCard(props) {
  const classes = PostCardStyles();
  const [value, setValue] = useState("1");
  //blog code

  const [category, setCategory] = React.useState("1");

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Container maxWidth="xl">
          <Grid container spacing={2} className={classes.ContainerStyle} >
            <Grid item xs={12} md={6} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={12}>
                  <AppCreate></AppCreate>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Stack>
                    <div>

                      <TabContext
                        value={value}
                        style={{ display: "flex", justifyContent: "space-evenly" }}
                      >
                        <Box
                          style={{ display: "flex", justifyContent: "space-evenly" }}
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            textAlign: "center",
                          }}
                        >
                          <TabList
                            style={{ display: "flex", justifyContent: "space-around" }}
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Postcard" value="1" style={{ marginLeft: '2rem', color: "rgb(30, 58, 113)", fontFamily: "Museo700", }} />
                            {/* <Tab label="Greeting Card" value="2" /> */}
                            {/* <Tab label="Invite" value="3" /> */}
                          </TabList>
                        </Box>
                        {/* library feed section */}
                        <TabPanel
                          value="1"
                          spacing={2}
                          sx={{ display: "flex", justifyContent: "space-between" }}
                        >

                        </TabPanel>
                      </TabContext>

                      {/* <Button
                      onClick={handleOpen}
                      style={{
                        width: "100%",
                        padding: "1rem",
                        borderRadius: "1rem",
                        backgroundColor: "#ffc107",
                        border: "none",
                        color: "white",
                      }}
                    >
                      Create New <span> </span>
                      <AddIcon/>
                      <img
                        src={plusicon}
                        alt=""
                        style={{ marginLeft: "5px" }}
                      />
                    </Button> */}

                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>

                  <PosctacrdAccordian></PosctacrdAccordian>
                </Grid>

                <Grid item xs={12} md={6} lg={12}>
                  <PostcardDetails></PostcardDetails>
                </Grid>

                <Grid item xs={12} md={6} lg={12}>
                  <PostcardSendData></PostcardSendData>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PostcardMyRoom></PostcardMyRoom>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box className={classes.hidenImgStyle}>
                    <img src={add2} alt="hi"
                      width="100%"
                      className={classes.hidenImgStyle}
                    />
                    {/* <img
                      src={feather}
                      alt="Feather"
                      style={{ width: "-webkit-fill-available" }}
                    /> */}
                    <img
                      // src={plus}
                      alt=""
                      style={{
                        display: "none",
                        position: "absolute",
                        top: "50%",
                        left: "150px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img src={add1} alt="hi"
                      width="100%"
                      className={classes.hidenImgStyle}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.createFabStyles1} >
            <CreatefabButton />
          </Box>
        </Container>
      </Grid>
    </Main>



  );
}

export default PostCard;
