
// const name = "Virendra"
const PostCardId = "jsdlkfjsklfj"
exports.FollowerTemplate = (name, postcardId) => {
    let html =
        `
    <!DOCTYPE html>
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">

<head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <style>
        * {
            box-sizing: border-box
        }

        body {
            margin: 0;
            padding: 0
        }

        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: inherit !important
        }

        #MessageViewBody a {
            color: inherit;
            text-decoration: none
        }

        p {
            line-height: inherit
        }

        @media (max-width:660px) {
            .icons-inner {
                text-align: center
            }

            .icons-inner td {
                margin: 0 auto
            }

            .row-content {
                width: 100% !important
            }

            .image_block img.big {
                width: auto !important
            }

            .column .border,
            .mobile_hide {
                display: none
            }

            table {
                table-layout: fixed !important
            }

            .stack .column {
                width: 100%;
                display: block
            }

            .mobile_hide {
                min-height: 0;
                max-height: 0;
                max-width: 0;
                overflow: hidden;
                font-size: 0
            }

            .row-11 .column-1,
            .row-13 .column-1,
            .row-7 .column-1,
            .row-9 .column-1 {
                border-top: 16px solid #fff;
                border-right: 30px solid #fff;
                border-bottom: 16px solid #fff;
                border-left: 16px solid #fff
            }

            .row-17 .column-1,
            .row-17 .column-2 {
                border-top: 10px solid #fff;
                border-right: 10px solid #fff;
                border-bottom: 10px solid #fff;
                border-left: 10px solid #fff
            }
        }
    </style>
</head>

<body style="background-color:#f6f8f8;margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none">
    <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
        style="mso-table-lspace:0;mso-table-rspace:0;background-color:#f6f8f8">
        <tbody>
            <tr>
                <td>
                    <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-color:#2b3940">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation"
                                        style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:640px"
                                        width="640">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:20px;padding-left:20px;padding-top:20px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;font-family:Verdana, Geneva, Tahoma, sans-serif;mso-line-height-alt:14.399999999999999px;color:#555;line-height:1.2">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:left">
                                                                            <strong
                                                                                style="font-size:13px;color:#ffffff;">
                                                                                EXPLORE</strong>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="column column-2"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:20px;padding-left:20px;padding-top:20px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;font-family:Verdana, Geneva, Tahoma, sans-serif;mso-line-height-alt:14.399999999999999px;color:#555;line-height:1.2">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:left">
                                                                            <strong
                                                                                style="font-size:13px;color:#ffffff;">
                                                                                CREATE</strong>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="column column-3"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:20px;padding-right:20px;padding-top:20px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;font-family:Verdana, Geneva, Tahoma, sans-serif;mso-line-height-alt:14.399999999999999px;color:#555;line-height:1.2">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:right">
                                                                            <strong
                                                                                style="font-size:13px;color:#ffffff;">SHARE</strong>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                        cellspacing="0" role="presentation"
                                        style="mso-table-lspace:0;mso-table-rspace:0;background-color:#fff;color:#000;width:640px"
                                        width="640">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-top:0;padding-bottom:0;border-top:0;border-right:0;border-bottom:0;border-left:0">

                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-left:40px;padding-right:40px;padding-top:35px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;mso-line-height-alt:18px;color:#555;line-height:1.5;font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                        <p
                                                                            style="margin:0;font-size:16px;text-align:center;mso-line-height-alt:51px">
                                                                            <span style="font-size:15px;color:#2b3940;">
                                                                                New User Followed You
                                                                                <!-- <span style="text-transform:capitalize"> <strong>${name}</strong> </strong>has sent a Postcard to you</span> -->
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table class="image_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0">
                                                        <tr>
                                                            <td
                                                                style="padding-left:16px;padding-right:16px;padding-top:16px;width:100%">
                                                                <div align="center"
                                                                    style="line-height:10px ; background-color: #fff;background-image: repeating-linear-gradient(-50deg, #e7e4e4, #e7e4e4 20px, #df5646 20px, #df5646 40px, #e7e4e4 40px, #e7e4e4 60px, #1c78a4 60px, #1c78a4 80px); animation: barberpole 4s linear infinite; padding: 10px; border-radius: 10px;margin: 0 auto;">

                                                                    <div class="postcardPad"
                                                                        style="padding:40px; background:white ;width:100% ;">
                                                                        <h2>${name} has followed you.</h2><br />
                                                                        <p>Click</p> <button
                                                                            style="background-color: #6200EE;color: white;padding: 10px 20px;border-radius: 8px;margin: 15px 0;font-weight: 500;cursor: pointer; font-size: 18px;"><a
                                                                                href="https://walchand.quillatwill.com"
                                                                                target="_blank"
                                                                                style="text-decoration:none;color:white">View
                                                                                Profile</a></button>
                                                                        <!-- <p>to view the message</p> -->
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:15px;padding-left:40px;padding-right:40px;padding-top:13px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:14px;mso-line-height-alt:25.2px;color:#555;line-height:1.8;font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:center">
                                                                            <span style="color:#900305;">Step into the
                                                                                creative realm with the fabulous
                                                                                QuillAtWill platform!</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:15px;padding-left:40px;padding-right:40px;padding-top:13px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:14px;mso-line-height-alt:25.2px;color:#555;line-height:1.8;font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:center">
                                                                            <span style="color:black;">
                                                                                <span
                                                                                    style="text-decoration: underline;"><a
                                                                                        href="https://quillatwill.com/"
                                                                                        target="_blank"
                                                                                        style="color: black;">Quillatwill.com</a></span>
                                                                                is your space to explore, hone, create
                                                                                and express yourself. Let your
                                                                                inspiration take shape with our
                                                                                innovative tools! We bring readers,
                                                                                writers and editors together on our
                                                                                platform, revolutionizing the creative
                                                                                landscape.</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:15px;padding-left:40px;padding-right:40px;padding-top:13px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:14px;mso-line-height-alt:25.2px;color:#555;line-height:1.8;font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:center">
                                                                            <span style="color:black;">Why wait? Spread
                                                                                your wings of imagination today and take
                                                                                flight with
                                                                                <span
                                                                                    style="text-decoration: underline;"><a
                                                                                        href="https://quillatwill.com/"
                                                                                        target="_blank"
                                                                                        style="color: black;">quillatwill.com!</a></span></span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table class="button_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:50px;padding-left:40px;padding-right:40px;padding-top:10px;text-align:center">
                                                                <div align="center">
                                                                    <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:52px;width:178px;v-text-anchor:middle;" arcsize="6%" stroke="false" fillcolor="#f75c03"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Tahoma, sans-serif; font-size:16px"><![endif]-->
                                                                    <button
                                                                        style="text-decoration:none;display:inline-block;color:#fff;background-color:#f75c03;border-radius:3px;width:auto;border-top:1px solid #f75c03;font-weight:undefined;border-right:1px solid #f75c03;border-bottom:1px solid #f75c03;border-left:1px solid #f75c03;padding-top:10px;padding-bottom:10px;font-family:Verdana, Geneva, Tahoma, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all">
                                                                        <a href="https://quillatwill.com/"
                                                                            target="_blank">
                                                                            <span
                                                                                style="padding-left:28px;padding-right:28px;font-size:16px;display:inline-block;letter-spacing:normal;"><span
                                                                                    style="font-size: 16px; margin: 0; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;color: #e7e4e4;">
                                                                                    <strong>Get
                                                                                        Started
                                                                                        Now</strong></span></span></a>
                                                                    </button>
                                                                    <!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>




                    <table class="row row-15" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                        cellspacing="0" role="presentation"
                                        style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:640px"
                                        width="640">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-top:0;padding-bottom:0;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <div class="spacer_block"
                                                        style="height:70px;line-height:70px;font-size:1px">&#8202;</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>





                    <table class="row row-19" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-color:#000">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation"
                                        style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:640px"
                                        width="640">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="50%"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:20px;padding-left:20px;padding-top:20px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;font-family:Verdana, Geneva, Tahoma, sans-serif;mso-line-height-alt:14.399999999999999px;color:#555;line-height:1.2">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:left">
                                                                            <span
                                                                                style="font-size:13px;color:white;">Join
                                                                                Us Now !
                                                                            </span>
                                                                            <br />
                                                                            <span style="font-size:13px;color:white;">
                                                                                <a href="https://quillatwill.com/"
                                                                                    target="_blank"
                                                                                    style="color: white;text-decoration: none;">www.quillatwill.com</a>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="column column-2" width="50%"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="text_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                                                        <tr>
                                                            <td
                                                                style="padding-bottom:20px;padding-right:20px;padding-top:20px">
                                                                <div
                                                                    style="font-family:Verdana, Geneva, Tahoma, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size:12px;font-family:Verdana, Geneva, Tahoma, sans-serif;mso-line-height-alt:14.399999999999999px;color:#555;line-height:1.2">
                                                                        <p
                                                                            style="margin:0;font-size:14px;text-align:right">
                                                                            <span style="font-size:13px;color:white;">
                                                                                Have a question ? </span>
                                                                            <br />
                                                                            <a href="mailto:support@quillatwill.com"
                                                                                target="_blank"
                                                                                style=" text-decoration: none;color:white">
                                                                                <span
                                                                                    style="font-size:13px;color:white;">
                                                                                    support@quillatwill.com </span></a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-22" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                        cellspacing="0" role="presentation"
                                        style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:640px"
                                        width="640">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%"
                                                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0">
                                                    <table class="icons_block" width="100%" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace:0;mso-table-rspace:0">
                                                        <tr>
                                                            <td
                                                                style="vertical-align:middle;color:#9d9d9d;font-family:Verdana, Geneva, Tahoma, sans-serif;font-size:15px;padding-bottom:5px;padding-top:5px;text-align:center">
                                                                <table width="100%" cellpadding="0" cellspacing="0"
                                                                    role="presentation"
                                                                    style="mso-table-lspace:0;mso-table-rspace:0">
                                                                    <tr>
                                                                        <td
                                                                            style="vertical-align:middle;text-align:center">
                                                                            <!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                                                            <!--[if !vml]><!-->
                                                                            <table class="icons-inner"
                                                                                style="mso-table-lspace:0;mso-table-rspace:0;display:inline-block;margin-right:-4px;padding-left:0;padding-right:0"
                                                                                cellpadding="0" cellspacing="0"
                                                                                role="presentation">
                                                                                <!--<![endif]-->
                                                                                <tr>
                                                                                    <td
                                                                                        style="vertical-align:middle;text-align:center;padding-top:5px;padding-bottom:1px;padding-left:5px;padding-right:6px">
                                                                                        <a href="https://quillatwill.com/"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none;display:block;height:auto;border:0;width:126px;max-width:100%"><img
                                                                                                class="icon"
                                                                                                alt="Designed with BEE"
                                                                                                src="https://firebasestorage.googleapis.com/v0/b/jappiz-uat.appspot.com/o/images%2FQuillatwillnewlogo.png?alt=media&token=e3dd65d0-3075-4f57-ae4a-99906a88a312"
                                                                                                width="126" height="34"
                                                                                                align="center"
                                                                                                style="display:block;height:auto;margin:0 auto;border:0"></a>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table><!-- End -->
</body>

</html>`
    return html;
}