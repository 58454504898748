import React from "react";

// component from library
import Stack from '@mui/material/Stack';
// material
import { Grid } from '@mui/material';
// custom component
import CustomButton from '../CustomButton';
// bg-image import
import bgImageSrc from '../../../assets/images/dashboard/bg-image.png';

export default function ShortStoryMasterClass() {
  return (
    <Stack
      style={{
        backgroundColor: '#FFDB00',
        borderRadius: '25px',
        // marginTop: "1rem",
        height: '100%',
      }}
    >
      <img src={bgImageSrc} alt="bg" style={{ height: '230px', }} />
      <Stack

        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{
          padding: '1rem',

        }}
      >
        <h2 style={{ fontSize: '30px', color: '#001f58', borderBottom: '1px solid green', textAlign: 'center' }}>
          Short Story
          <br />
          Masterclass
        </h2>

        <h4 style={{ color: '#001f58', textAlign: 'center', margin: '0' }}>
          {/* 20 Feb 2022 */}
          <br />
          {/* 10Am - 12.30 Pm */}
        </h4>

        <CustomButton
          buttonText="Coming Soon"
          customStyles={{
            borderRadius: '5rem',
            fontSize: '1.2rem'
          }}
        />
      </Stack>
    </Stack>
  );
}
