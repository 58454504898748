import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	gallery: [],
	usersImages: {
		images: [],
		userid: null,
		thumbnail: [],
		count: 0
	},
	usersLatestImages: {
		images: [],
		userid: null,
		thumbnail: [],
		count: 0
	},
	profileImages: [],
	premiumImages: [],
	stickers: [],
};

export const images = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.SET_OPENED_GALLERY:
			return {
				...state,
				gallery: payload,
			};
		case actionTypes.SET_OPENED_USERS_IMAGES:
			return {
				...state,
				usersImages: payload,
			};
		case actionTypes.SET_LATEST_USERS_IMAGES:
			return {
				...state,
				usersLatestImages: payload,
			};

		case actionTypes.SET_PROFILE_IMAGES:
			return {
				...state,
				profileImages: payload,
			};
		case actionTypes.GET_PREMIUM_IMAGES:
			return {
				...state,
				premiumImages: payload,
			};
		case actionTypes.GET_STICKERS:
			return {
				...state,
				stickers: payload,
			};
		default:
			return state;
	}
};