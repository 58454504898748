import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import eye from "../../../assets/images/Blog/BD/a;hi.png";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import share from "../../../assets/images/Blog/BD/agr.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";
import logoImages from "../../../assets/images/logo1.png";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { deletePost, getUserPosts, setOpenedPost, setUserDraftPostEdit } from "../../../store/actions/posts";
import PoemEditorStyles from "../PoemEditorStyles";

// import { styled } from '@mui/material/styles';
// import { CgLogOut } from 'react-icons/cg';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TransitionsModal from 'TransitionsModal';
import './Poem.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AppReviewCompletedData(props) {
  const classes = PoemEditorStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [draftPosts, setDraftPosts] = useState([])
  const { userDraftPosts } = useSelector((state) => state.posts)
  const auth = useSelector(state => state.firebase.auth);
  const [id, setId] = useState([]);

  useEffect(() => {
    try {
      dispatch(getUserPosts())
    } catch (error) {

    }
  }, []);

  const onClickDraft = (post) => {

    dispatch(setUserDraftPostEdit(true))
    dispatch(setOpenedPost(post))
    history.push('/blog-editor')
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (post) => {
    setId(post.id);
    setOpen(true);
  };
  const handleClose = (id) => {
    dispatch(deletePost(id));
    dispatch(getUserPosts())
    setOpen(false);
  }
  const notDelete = () => {
    setOpen(false);
  }
  return (
    <Grid container spacing={2}>
      <Dialog
        open={open}
        keepMounted
        onClose={notDelete}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Are you sure you want to Delete ?'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            lorem ipsum dolor sit amet, consectetur
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button style={{ background: 'rgb(0, 31, 88)', color: '#fff' }} onClick={() => handleClose(id)}>Yes</Button>
          <Button style={{ background: 'rgb(0, 31, 88)', color: "#fff" }} onClick={() => notDelete(id)}>No</Button>
        </DialogActions>
      </Dialog>

      {userDraftPosts && userDraftPosts.length > 0
        ? userDraftPosts.map((post, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4} style={{ cursor: 'pointer' }}>
            <Stack
              className={classes.responsiveCard}
            >
              <Grid container spacing={1} direction="column">
                <Grid item>

                  <Box style={{ position: "relative" }}>
                    <Box>
                      <DeleteIcon onClick={() => handleClickOpen(post)}
                        className={classes.deltDraftStyles}
                      />
                    </Box>
                    {/* <div style={{ minHeight: '200px', backgroundColor: 'skyblue' }}>
                      <img onClick={() => onClickDraft(post)}
                        // src={bgImageSrc1}
                        src={post.images && post.images.length > 0 ? post.images : blankimg}
                        alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover', cursor: "pointer", }} />
                    </div> */}

                    <div style={{
                      minHeight: '200px', backgroundColor: 'skyblue', display: 'flex',
                      justifyContent: 'center', alignItems: 'center'
                    }}>
                      {
                        post.images && post.images.length > 0 ?
                          <img
                            src={post.images[0]}
                            //  src={bgImageSrc2}
                            alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover' }} />
                          :
                          <img
                            src={logoImages}
                            //  src={bgImageSrc2}
                            alt="bg" style={{ width: "50%", height: 'auto', margin: 'auto' }} />

                      }
                    </div>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0",
                        // margin: "10px",
                        padding: '10px',
                        width: '78vw',
                        // background: 'linear-gradient(rgba(255,0,0,0), #878383)',
                        background: 'linear-gradient(rgba(255,0,0,0), #000000)',


                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{ color: "#fff" }}
                      >
                        <Grid item>
                          <img src={eye} alt="" />
                          <span>
                            {post?.views || 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <img src={hands} alt="" />
                          <span>
                            {post && post.likedby
                              ? post?.likedby?.length
                              : 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <img src={comment} alt="" />
                          <span>
                            {post?.comments || 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <img src={share} alt="" />
                          <span>
                            {post?.shared || 0}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    spacing={2}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <div style={{ height: '100px', overflow: 'hidden' }}>
                      <h3 style={{
                        color: "grey", marginTop: "0px", cursor: "pointer",
                      }} >
                        {/* Lorem ipsum dolor */}
                        {post?.title}
                      </h3>

                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace: 'nowrap !important',
                          overflow: 'hidden !important',
                          textOverflow: 'ellipsis !important',
                          width: '100%'
                        }}
                        className='paragraphHtml'
                      >
                        {
                          post && post.body ? ReactHtmlParser(post?.body) : null
                        }

                        {/* {
                          post.body ?
                            post.body.length < 100 ?
                              ReactHtmlParser(post?.body) :
                              ReactHtmlParser(post?.body.substring(0, 50)) + '....' : ''
                        } */}
                      </p>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      marginBottom="0"
                    >
                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <img
                          // src={blog?.image} 
                          style={{
                            width: "34px",
                            height: "34px",
                            borderRadius: "50%",
                          }}
                          src={auth?.photoURL || auth?.avatarURL}
                          alt="pi" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={7}>
                        <Stack direction="row" spacing={2}>
                          <b
                            style={{
                              fontWeight: "500",
                              color: "rgb(0, 31, 88)",
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {auth?.displayName} |{" "}
                          </b>
                          {/* <span style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', }}>{post?.blogId?.label}</span> */}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <b
                          style={{
                            fontWeight: "500",
                            color: "rgb(0, 31, 88)",
                            textAlign: "right",
                          }}
                        >
                          {post.lastUpdated
                            ? moment
                              .unix(post?.lastUpdated?.seconds)
                              .format("DD MMM YY")
                            : null}
                        </b>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>

            </Stack>
          </Grid>
        ))
        : null}
    </Grid>
  );
}
