import React from 'react';
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebaseApp, { auth, googleAuthProvider, db } from '../config/fbConfig';
import { sha512 } from 'js-sha512';
import moment from 'moment';
function PaymentForm(props) {
    const user = useSelector((state) => state.user.user);
    const [hash, setHash] = useState(null)
    const [txnid, setTxnid] = useState(null);
    const [userName, setUserName] = useState("");
    const getUsername = () => {
      let name = ''
      if (user) {
        if (user.hasOwnProperty('displayName')) {
          name = user.displayName
        }
        if (user.hasOwnProperty('name')) {
          let temp = user.name
          name = temp.length > name.length ? temp : name
        }
        if (user.hasOwnProperty('firstName')) {
          let temp = user.firstName + ' ' + user?.lastName
          name = temp.length > name.length ? temp : name
        }
      }
      return name;
    }
    useEffect(() => {
        const name = getUsername();
        setUserName(name)
        var now = moment().unix();
        let randomNumber = parseInt(Math.random() * 1000)
        let transactionId = 'QaW-' + now + randomNumber
        let udf1 = auth?.currentUser?.uid
        let udf5 = "PayUBiz_NODE_JS_KIT"
        let key = process.env.REACT_APP_PAYMENT_KEY;
        let salt = process.env.REACT_APP_PAYMENT_SALT;
        let amount = '199'
        let productinfo = "Premium"
        let firstname = name
        let email = auth?.currentUser.email
        let text = key + '|' + transactionId + '|' + amount + '|' + productinfo + '|' + firstname + '|' + email + '|' + udf1 + '||||' + udf5 + '||||||' + salt;
        let createHash = sha512.create();
        let newhash = createHash.update(text)
        let hashHex = createHash.hex(newhash);
        setHash(hashHex);
        setTxnid(transactionId)
    }, [user])
  return (
    <div>
        <form action={process.env.REACT_APP_PAYMENT_ENDPOINT} id="payment_form" method="Post" style={{ display: 'none' }}>
        <input type="hidden" id="key" name="key" value={process.env.REACT_APP_PAYMENT_KEY} />
        <input type="text" id="txnid" name="txnid" placeholder="Transaction ID" value={txnid} />
        <input type="text" id="productinfo" name="productinfo" placeholder="Product Info" value="Premium" />
        <input type="text" id="amount" name="amount" placeholder="Amount" value="199" />
        <input type="text" id="email" name="email" placeholder="Email ID" value={auth?.currentUser.email}/>
        <input type="text" id="firstname" name="firstname" placeholder="First Name" value={userName} />
        {/* <input type="text" id="Lastname" name="Lastname" placeholder="Last Name" value={user?.lastName} /> */}
        <input type="hidden" id="surl" name="surl" value={process.env.REACT_APP_PAYMENT_SUCCESS_URL} />
        <input type="hidden" id="furl" name="furl" value={process.env.REACT_APP_PAYMENT_FAILED_URL} />
        <input type="hidden" id="curl" name="curl" value={process.env.REACT_APP_PAYMENT_CLOSE_URL} />
        <input type="text" id="phone" name="phone" placeholder="Mobile/Cell Number" value={'012345678'} />
        <input type="text" id="hash" name="hash" placeholder="Hash" value={hash} />
        <input type="hidden" id="udf1" name="udf1" value={auth?.currentUser?.uid} />
        <input type="hidden" id="udf5" name="udf5" value="PayUBiz_NODE_JS_KIT" />
        <input type="hidden" id="userId" name="userId" value={auth?.currentUser?.uid} />
        <input type="text" id="address1" name="address1" placeholder="Address1" value="Latur" />
        <input type="text" id="address2" name="address2" placeholder="Address2" value="Latur" />
        <input type="text" id="city" name="city" placeholder="City" value="Latur" />
        <input type="text" id="state" name="state" placeholder="State" value="Maharashtra" />
        <input type="text" id="country" name="country" placeholder="Country" value="India" />
        <input type="text" id="Zipcode" name="Zipcode" placeholder="Zip Code" value="413512" />
        <input type="text" id="custom_note" name="custom_note" placeholder="Custom note" value="Get Premium ₹199/- For 3 Month" />
        {/* <input type="text" id="Pg" name="Pg" placeholder="PG" value="CC" /> */}
        <input 
         ref={props.setRef}
        type="submit" id="submit" value="Pay" />
      </form>
    </div>
  )
}

export default React.memo(PaymentForm)