import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import { INITIAL_STATE as postInitialState } from "../reducers/posts";
import moment from 'moment'
import { addUserLogs } from './rewards';
import _ from 'lodash'

export const getPosts = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await db.collection("posts").limit(5).get()
            .then(async (posts) => {
                let postsData = []
                posts.docs.forEach(async (item) => {
                    let post = item.data()
                    post = {
                        ...post,
                        id: item.id
                    }
                    postsData.push(post)
                })
                for (let i = 0; i < postsData.length; i++) {
                    let post = postsData[i]
                    let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                    if (user && user.docs && user.docs.length > 0) {
                        let userData = user.docs[0].data()
                        userData = {
                            ...userData,
                            cPassword: null,
                            newPassword: null,
                            password: null
                        }
                        post = {
                            ...post,
                            user: userData
                        }
                        postsData[i] = post
                    } else {
                        postsData.splice(i, 1)
                        i--
                    }

                }

                dispatch(
                    triggerReducer(actionTypes.GET_POSTS, {
                        payload: postsData
                    })
                );
                resolve(postsData)
            })
            .catch((err) => {
                reject(err)
            })
    });
};

export const getPostsByUser = (user_id) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await db.collection("posts").where('userid', '==', user_id).get()
            .then(async (posts) => {
                let postsData = []
                posts.docs.forEach(async (item) => {
                    let post = item.data()
                    post = {
                        ...post,
                        id: item.id
                    }
                    postsData.push(post)
                })
                dispatch(
                    triggerReducer(actionTypes.GET_USER_POSTS, {
                        userPosts: postsData
                    })
                );
                resolve(postsData)
            })
            .catch((err) => {
                reject(err)
            })
    });
};


export const setOpenedPost = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_POST, { payload }));
};

export const setUserDraftPostEdit = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER_DRAFT_POST_EDIT, { payload }));
}

export const clearPost = () => (dispatch) => {
    const payload = postInitialState.openedPost;
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_POST, { payload }));
};

export const setSavedPost = (data) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { openedPost } = getState().posts;
    const { tenantId } = getState().user;
    const { firestore } = store;
    const payload = {
        userId: auth.uid,
        updatedBy: auth.uid,
        layout: '',
        lastUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        tenantId,
        ...openedPost,
    };

    if (payload.images.length > 0) {
        let imagesArray = []
        for (let i = 0; i < payload.images.length; i++) {
            if (payload.content.includes(payload.images[i])) {
                imagesArray.push(payload.images[i])
            }
        }
        payload.images = imagesArray
    }
    if (payload.blogId && payload.blogId.label) {
        payload.blogId = payload.blogId;
    } else {
        payload.blogId = ''
    }

    if (payload.category && payload.category.label) {
        payload.category = payload.category;
    } else {
        payload.category = ''
    }
    if (openedPost && openedPost.id) {
        payload.id = openedPost.id
    }

    payload.isPublished = data.isPublished;

    if (openedPost?.blogId?.value !== null) {
        dispatch(savePost(payload));
        if (data.isPublished) {

            firestore.collection("blogs")
                .doc(openedPost?.blogId?.value)
                .update({ 'isPublished': data.isPublished })

            var blogRef = db.collection('blogs').doc(openedPost?.blogId?.value);
            blogRef.update({
                publishedpostcount: firebase.firestore.FieldValue.increment(1)
            });
        }
    } else if (payload.blogId && payload.blogId.label) {
        await firestore.add({ collection: "blogs" }, {
            title: openedPost.blogId.label,
            userId: auth.uid,
            category: payload.category,
            isPublished: data.isPublished,
            tenantId
        }).then((resp) => {
            payload.blogId = {
                value: resp.id,
                label: openedPost.blogId.label,
            };
            dispatch(savePost(payload, data));
        })
            .catch((error) => {
                console.log("save post error ---- ", error)
            })
    }
};

export const savePost = (payload, data) => async (dispatch, getState) => {
    const { firestore } = store;
    const { openedPost } = getState().posts;
    try {
        const docRefId = openedPost.id;

        delete payload.id;
        if (!docRefId) {
            dispatch(togglePageLoading(true));
            firestore.add({ collection: "posts" }, payload).then((docRef) => {
                payload.id = docRef.id;

                dispatch(triggerReducer(actionTypes.SET_SAVED_POST, { payload }));
                dispatch(triggerReducer(actionTypes.SET_OPENED_POST, { payload }));

                if (payload.isPublished) {
                    let rewardPayload = {
                        postId: docRef.id,
                        blogid: payload.blogId.value,
                        type: 'blog',
                        operation: 'create',
                        quinns: 1000,
                        milestone: 1,
                        date: firebase.firestore.Timestamp.fromDate(new Date())
                    }
                    dispatch(addUserLogs(rewardPayload))
                }

                if (data?.showAlert)
                    dispatch(showSuccessSnackbar("Post Saved Successfully"));
                dispatch(togglePageLoading(false));
            }).catch((error) => {
                console.log("save post error ---- ", error)
                if (data?.showAlert)
                    dispatch(showErrorSnackbar("Error in saving Post"));
                dispatch(togglePageLoading(false));
            });
        } else {
            dispatch(togglePageLoading(true));
            firestore.collection("posts").doc(docRefId).update(payload)
                .then(() => {
                    payload.id = docRefId

                    dispatch(triggerReducer(actionTypes.SET_SAVED_POST, { payload }));
                    dispatch(triggerReducer(actionTypes.SET_OPENED_POST, { payload }));
                    if (data?.showAlert)
                        dispatch(showSuccessSnackbar("Post Updated Successfully"));
                    dispatch(togglePageLoading(false));

                }).catch((error) => {
                    console.log("save post error ---- ", error)
                    if (data?.showAlert)
                        dispatch(showErrorSnackbar("Error in updating Post"));
                    dispatch(togglePageLoading(false));
                });
        }
    } catch (error) {
        console.log("save post catch error ---- ", error)
        dispatch(showErrorSnackbar("Error in saving Post"));
        dispatch(togglePageLoading(false));
    }
};


export const getLikedPost = () => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { tenantId } = getState().user;

    await db.collection("posts")
        .where("likedby", "array-contains", auth.uid)
        // .where('tenantId', '==', tenantId)
        .get()
        .then(async (posts) => {
            let postsData = []
            posts.docs.forEach(async (item) => {
                let post = item.data()
                post = {
                    ...post,
                    id: item.id
                }
                postsData.push(post)
            })
            for (let i = 0; i < postsData.length; i++) {
                let post = postsData[i]
                let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                if (user && user.docs && user.docs.length > 0) {
                    let userData = user.docs[0].data()
                    userData = {
                        ...userData,
                        cPassword: null,
                        newPassword: null,
                        password: null
                    }
                    post = {
                        ...post,
                        user: userData
                    }
                    postsData[i] = post
                } else {
                    postsData.splice(i, 1)
                    i--
                }
            }

            dispatch(
                triggerReducer(actionTypes.GET_USER_LIKED_POSTS, {
                    payload: postsData
                })
            );
        })
        .catch((err) => {

        })
}

export const getTrendingPosts = () => async (dispatch, getState) => {
    const { tenantId } = getState().user;
    await db.collection("posts")
        .where('tenantId', '==', tenantId)
        .orderBy('views', 'desc')
        .limit(5).get()
        .then(async (posts) => {
            let postsData = []
            posts.docs.forEach(async (item) => {
                let post = item.data()
                post = {
                    ...post,
                    id: item.id
                }
                postsData.push(post)
            })
            for (let i = 0; i < postsData.length; i++) {
                let post = postsData[i]
                let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                if (user && user.docs && user.docs.length > 0) {
                    let userData = user.docs[0].data()
                    userData = {
                        ...userData,
                        cPassword: null,
                        newPassword: null,
                        password: null
                    }
                    post = {
                        ...post,
                        user: userData
                    }
                    postsData[i] = post
                }
            }
            dispatch(
                triggerReducer(actionTypes.GET_TRENDING_POSTS, {
                    payload: postsData
                })
            );
        })
        .catch((err) => {
            console.log("error === ", err)
        })
};

export const getWallOfFamePosts = () => async (dispatch, getState) => {
    const { tenantId } = getState().user;
    if (tenantId) {
        await db.collection("posts")
            .where('isPublished', '==', true)
            .where('tenantId', '==', tenantId)
            .orderBy('lastUpdate', 'desc')
            .get()
            .then(async (posts) => {
                let postsData = []
                posts.docs.forEach(async (item) => {
                    let post = item.data()
                    post = {
                        ...post,
                        id: item.id,
                        type: 'blog'
                    }
                    postsData.push(post)
                })
                let usersArrayData = []
                for (let i = 0; i < postsData.length; i++) {
                    let post = postsData[i]
                    let userData = {}
                    let index = usersArrayData.findIndex((u) => u.id === post.userId)
                    if (index > -1) {
                        userData = usersArrayData[index]
                    } else {
                        let user = await db.collection("users")
                            .where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                        if (user && user.docs && user.docs.length > 0) {
                            userData = user.docs[0].data()
                            userData = {
                                ...userData,
                                id: user.docs[0].id,
                                cPassword: null,
                                newPassword: null,
                                password: null
                            }
                            usersArrayData.push(userData)
                        }
                    }
                    post = {
                        ...post,
                        user: userData
                    }
                    postsData[i] = post
                }

                dispatch(
                    triggerReducer(actionTypes.GET_WALL_OF_FAME_POSTS, {
                        payload: postsData
                    })
                );
            })
            .catch((err) => {
                console.log("Errr ---- ", err)
            })
    }

};


export const getUserPosts = (isPublished = false) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    await db.collection("posts")
        .orderBy('lastUpdate', 'desc')
        .where('userId', '==', auth.uid)
        .where('isPublished', '==', isPublished)
        .get()
        .then(async (posts) => {
            let postsData = []
            let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', auth.uid).get()
            let userData = ''
            if (user && user.docs && user.docs.length > 0) {
                userData = user.docs[0].data()
                userData = {
                    ...userData,
                    id: user.docs[0].id,
                    cPassword: null,
                    newPassword: null,
                    password: null
                }
            }
            posts.docs.forEach(async (item) => {
                let post = item.data()
                post = {
                    ...post,
                    id: item.id,
                    type: 'blog',
                    user: userData
                }
                postsData.push(post)
            })
            if (isPublished) {
                let likedByCount = 0;
                let lovedByCount = 0;
                let sharedByCount = 0;
                let viewsCount = 0;

                for (let i = 0; i < postsData.length; i++) {
                    if (postsData[i].likedby) {
                        likedByCount += postsData[i].likedby.length;
                    }
                    else {
                        likedByCount += 0;
                    }
                    if (postsData[i].lovedby) {
                        lovedByCount += postsData[i].lovedby.length;
                    }
                    else {
                        lovedByCount += 0;
                    }

                    if (postsData[i].shared) {
                        sharedByCount += postsData[i].shared;
                    }
                    else {
                        sharedByCount += 0;
                    }

                    if (postsData[i].views) {
                        viewsCount += postsData[i].views;
                    }
                    else {
                        viewsCount += 0;
                    }

                }
                dispatch(
                    triggerReducer(actionTypes.COUNTS, {
                        payload: { likedByCount, lovedByCount, sharedByCount, viewsCount },
                    })
                );

                dispatch(
                    triggerReducer(actionTypes.GET_USER_PUBLISHED_POSTS, {
                        payload: postsData,
                    })
                );
            } else {
                dispatch(
                    triggerReducer(actionTypes.GET_USER_DRAFT_POSTS, {
                        payload: postsData
                    })
                );
            }

        })
        .catch((err) => {

        })
};

export const deletePost = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    await firestore.collection('posts')
        .doc(payload).delete().then(() => {
            firestore.collection("comments")
                .where('postid', '==', payload)
                .get()
                .then((comments) => {
                    if (comments && comments.docs.length > 0) {
                        comments.docs.forEach(async (item) => {
                            item.ref.delete()
                        })
                    }
                })
                .catch((error) => {
                    console.log("Errorr deleting comment", error)
                })
            dispatch(showSuccessSnackbar("post deleted Successfully!"));
        }).catch((error) => {
            console.log("Eroorrrrrrr === ", error)
            dispatch(showErrorSnackbar("post delete failed!!!"));
        });
}

function getPost(startDate, endDate, tenantId) {
    return new Promise(async (resolve, reject) => {
        await db.collection("posts")
            .where('isPublished', '==', true)
            .where('lastUpdate', '>=', startDate)
            .where('lastUpdate', '<=', endDate)
            .where('tenantId', '==', tenantId)
            .orderBy('lastUpdate', 'asc')
            .orderBy('views', 'desc')
            .get()
            .then(async (posts) => {
                let postsArray = []
                if (posts && posts.docs.length > 0) {
                    for (let i = 0; i < posts.docs.length; i++) {

                        let postData = posts.docs[i].data()
                        postsArray.push({
                            ...postData,
                            id: posts.docs[i].id
                        })
                    }
                    if (postsArray.length > 1) {
                        postsArray = postsArray.sort((a, b) => b.views - a.views)

                        let payload = postsArray[0]

                        let user = await db.collection("users")
                            .where(firebase.firestore.FieldPath.documentId(), '==', payload.userId)
                            .get()
                        if (user && user.docs && user.docs.length > 0) {
                            let userData = user.docs[0].data()
                            userData = {
                                ...userData,
                                cPassword: null,
                                newPassword: null,
                                password: null
                            }
                            payload = {
                                ...payload,
                                user: userData
                            }
                        }
                        resolve(payload)
                    } else {
                        resolve(false)
                    }
                }
                resolve(false)
            })
            .catch((err) => {
                console.log("Error getBlogOfTheWeek", err)
                resolve(false)
            })
    })
}

export const getBlogOfTheWeek = () => async (dispatch, getState) => {
    const today = moment()
    const { tenantId } = getState().user;

    if (tenantId) {
        let startDate = firebase.firestore.Timestamp.fromDate(new Date(moment().startOf('week').format('YYYY-MM-DD')))
        let endDate = firebase.firestore.Timestamp.fromDate(new Date(today.format('YYYY-MM-DD')))

        let post = await getPost(startDate, endDate, tenantId)
        if (!post) {
            startDate = firebase.firestore.Timestamp.fromDate(new Date(moment().startOf('week').subtract(1, 'weeks').format('YYYY-MM-DD')))
            endDate = firebase.firestore.Timestamp.fromDate(new Date(moment().startOf('week').subtract(1, 'days').format('YYYY-MM-DD')))
            post = await getPost(startDate, endDate, tenantId)
            if (!post) {
                startDate = firebase.firestore.Timestamp.fromDate(new Date(moment().startOf('week').subtract(2, 'weeks').format('YYYY-MM-DD')))
                endDate = firebase.firestore.Timestamp.fromDate(new Date(moment().startOf('week').subtract(1, 'weeks').subtract(1, 'days').format('YYYY-MM-DD')))
                post = await getPost(startDate, endDate, tenantId)
            }
        }

        if (post)
            dispatch(
                triggerReducer(actionTypes.GET_BLOG_OF_THE_WEEK, {
                    payload: post
                })
            );
    }

};

export const getFeaturedPosts = (payload) => async (dispatch, getState) => {
    await db.collection("posts")
        .where(firebase.firestore.FieldPath.documentId(), 'in', payload)
        .get()
        .then(async (posts) => {
            console.log("posts --- ", posts)
            let postsData = []
            const postsIdArray = ['ZwxewdY4oOMdDFqWj4dy', 'jL0xoTjcGeudElVgOSiW', '9icWwZo9lpWRHgexV3ic', 'OGfDi2WCF1cobJR9ilrR', 'ZtSLwcLmjv12D3AJnujb']
            for (let i = 0; i < postsIdArray.length; i++) {
                for (let j = 0; j < posts.docs.length; j++) {
                    console.log("Id --------- ", posts.docs[j].id)
                    if (postsIdArray[i] === posts.docs[j].id) {
                        let post = posts.docs[j].data()
                        post = {
                            ...post,
                            id: posts.docs[j].id
                        }
                        postsData.push(post)
                    }
                }
            }
            // posts.docs.forEach(async (item) => {
            //     let post = item.data()
            //     post = {
            //         ...post,
            //         id: item.id
            //     }
            //     postsData.push(post)
            // })
            for (let i = 0; i < postsData.length; i++) {
                let post = postsData[i]
                let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                if (user && user.docs && user.docs.length > 0) {
                    let userData = user.docs[0].data()
                    userData = {
                        ...userData,
                        cPassword: null,
                        newPassword: null,
                        password: null
                    }
                    post = {
                        ...post,
                        user: userData
                    }
                    postsData[i] = post
                }
            }
            dispatch(
                triggerReducer(actionTypes.GET_FEATURED_POSTS, {
                    payload: postsData
                })
            );
        })
        .catch((err) => {
            console.log("Errr ---- ", err)
        })
};


export const saveCategories = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    var categories = ['Art & Craft', 'Science', 'Money', 'Business', 'Technology', 'Programming', 'Future', 'Philosophy', 'Gaming', 'Education & Exams', 'Style', 'Politics/ Social Justice', 'Women Power', 'Equality', 'Psychology', 'Privacy', 'Space', 'Fiction', 'Cyberworld', 'Photography', 'Harry Potter', 'BTS / Korea', 'Transportation/ Cars & Bikes', 'Beauty/ Makeup', 'History', 'Health & Fitness', 'Digital Life', 'Sports', 'Pets/ Animals', 'Music & Dance', 'Accessibility', 'Culture', 'Future', 'Writing', 'Creativity', 'Economy', 'Religion / Spirituality', 'Gadgets', 'Coronavirus', 'Social media', 'Artificial Intelligence', 'Family', 'Relationships', 'Books & Novels', 'Film / TV series', 'Poetry', 'Climate change/ Environment', 'Language', 'Humour', 'Comics'];
    try {
        categories.forEach((cat) => {
            firestore.add({ collection: "categories" }, { title: cat, tags: [] }).then((docRef) => {
                console.log("Added --- ", cat)
            }).catch((error) => {

                dispatch(showErrorSnackbar("Error in saving Post"));
                dispatch(togglePageLoading(false));
            });
        })

    } catch (error) {

        dispatch(showErrorSnackbar("Error in saving Post"));
        dispatch(togglePageLoading(false));
    }
};


export const getGlobalPosts = () => async (dispatch, getState) => {
    const { tenantId } = getState().user;
    await db.collection("posts")
        .where('isPublished', '==', true)
        .where('isGlobalView', '==', true)
        .where('tenantId', '!=', tenantId)
        .orderBy('tenantId')
        .orderBy('lastUpdate', 'desc')
        .get()
        .then(async (posts) => {
            
            let postsData = []
            posts.docs.forEach(async (item) => {
                let post = item.data()
                post = {
                    ...post,
                    id: item.id,
                    type: 'blog'
                }
                postsData.push(post)
            })
            console.log("isGlobalView ----- ", postsData)
            var usersArrayData = []
            for (let i = 0; i < postsData.length; i++) {
                let post = postsData[i]
                let userData = {}
                let index = usersArrayData.findIndex((u) => u.id === post.userId)
                if (index > -1) {
                    userData = usersArrayData[index]
                } else {
                    let user = await db.collection("users")
                        .where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                    if (user && user.docs && user.docs.length > 0) {
                        userData = user.docs[0].data()
                        userData = {
                            ...userData,
                            id: user.docs[0].id,
                            cPassword: null,
                            newPassword: null,
                            password: null
                        }
                        usersArrayData.push(userData)
                    }
                }
                post = {
                    ...post,
                    user: userData
                }
                postsData[i] = post
            }
            console.log("getGlobalPosts --- ", postsData)
            dispatch(
                triggerReducer(actionTypes.GET_GLOBAL_POSTS, {
                    payload: postsData
                })
            );
        })
        .catch((err) => {
            console.log("Errr ---- ", err)
        })
};

export const getPostByPostId = (payload) => async (dispatch, getState) => {
    await db.collection("posts")
        .where(firebase.firestore.FieldPath.documentId(), 'in', payload)
        .get()
        .then(async (complaints) => {
            let complaintsData = []
            complaints.docs.forEach(async (item) => {
                let complaint = item.data()
                complaint = {
                    ...complaint,
                    id: item.id
                }
                complaintsData.push(complaint)
            })
            console.log(complaintsData)
        })
        .catch((err) => {
            console.log("Err get complaints --- ", err)
        })
};

//testing area start


export const getUserBlogTitles = () => (dispatch, getState) => {
    const { auth } = getState().firebase;
    db.collection("blogs").where('userId', '==', auth.uid).get()
        .then(async (blog_tiles) => {
            let blogTitlesData = []
            blog_tiles.docs.forEach(async (item) => {
                let blog_title = item.data()
                blog_title = {
                    ...blog_title,
                    id: item.id
                }
                blogTitlesData.push(blog_title)
            })
            dispatch(
                triggerReducer(actionTypes.GET_USER_BLOG_TITLES, {
                    payload: blogTitlesData
                })
            );
        })
        .catch((err) => {
            console.log("gettting blog title Errror --- ", err)
        })
};


// export const getAllPosts = () => (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//         await db.collection("posts").get()
//             .then(async (posts) => {
//                 let postsData = []
//                 posts.docs.forEach(async (item) => {
//                     let post = item.data()
//                     post = {
//                         ...post,
//                         id: item.id
//                     }
//                     postsData.push(post)
//                 })

//                 let data = _.groupBy(postsData, 'blogId.value')
//                 let objectKeys = Object.keys(data)

//                 for (let i = 0; i < objectKeys.length; i++) {
//                     let dataArray = data[objectKeys[i]]
//                     let publishedpostcount = 0;
//                     let isPubslished = false
//                     for (let j = 0; j < dataArray.length; j++) {
//                         let post = dataArray[j]
//                         if (post.isPublished == true) {
//                             publishedpostcount = publishedpostcount + 1;
//                             isPubslished = true
//                         }
//                     }
//                     console.log(publishedpostcount, isPubslished, objectKeys[i])
//                     if (objectKeys[i]) {
//                         await db
//                             .collection('blogs')
//                             .doc(objectKeys[i])
//                             .update({ publishedpostcount, isPubslished })
//                             .then(res => {
//                                 console.log("updated successfully---", res)
//                             }).catch((error) => {
//                                 console.log("Error : ", error)
//                             });
//                     }
//                 }

//                 resolve(postsData)
//             })
//             .catch((err) => {
//                 reject(err)
//             })
//     });
// };


// export const addTenantIdManuallyToPost = (tenantId) => (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//         if (tenantId) {
//             await db.collection("posts").get()
//                 .then(async (posts) => {
//                     let postsData = []
//                     posts.docs.forEach(async (item) => {
//                         let post = item.data()
//                         post = {
//                             ...post,
//                             id: item.id
//                         }
//                         postsData.push(post)
//                     })

//                     for (let i = 0; i < postsData.length; i++) {
//                         let post = postsData[i]
//                         if (post.hasOwnProperty('tenantId') && post.tenantId === tenantId) {
//                             resolve(true)
//                         }
//                     }

//                     let count = 0;
//                     for (let i = 0; i < postsData.length; i++) {
//                         let post = postsData[i]
//                         if (count == 10)
//                             break;
//                         if ((!post.hasOwnProperty('tenantId') || post?.tenantId.includes('@')) && count < 10) {
//                             await db
//                                 .collection('posts')
//                                 .doc(post.id)
//                                 .update({ tenantId })
//                                 .then(res => {
//                                     console.log("updated successfully---", res)
//                                 }).catch((error) => {
//                                     console.log("Error : ", error)
//                                 });
//                             count++;
//                         }

//                     }
//                     resolve(postsData)
//                 })
//                 .catch((err) => {
//                     reject(err)
//                 })
//         }
//         resolve(false)
//     });
// };


// export const addGlobalViewToPost = () => (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//         const { tenantId } = getState().user;

//         await db.collection("posts")
//             .where('tenantId', '!=', tenantId)
//             .get()
//             .then(async (posts) => {
//                 let postsData = []
//                 posts.docs.forEach(async (item) => {
//                     let post = item.data()
//                     post = {
//                         ...post,
//                         id: item.id
//                     }
//                     postsData.push(post)
//                 })

//                 for (let i = 0; i < 10; i++) {
//                     let post = postsData[i]
//                     await db
//                         .collection('posts')
//                         .doc(post.id)
//                         .update({ isGlobalView: true })
//                         .then(res => {
//                             console.log("Global added successfully---", res)
//                         }).catch((error) => {
//                             console.log("Error : ", error)
//                         });
//                 }
//                 resolve(postsData)
//             })
//             .catch((err) => {
//                 reject(err)
//             })
//     });
// };


//testing are end