import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import diary1 from '../../../assets/images/article/diary1.png';
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function DiaryPersonalBlog() {
    const classes = DiaryblogsStyles();

    //     <Helmet>
    //     <meta charSet="utf-8" />
    //     <title>Online Personal Diary | Benefits Of Online Diary | Quill At Will</title>
    //     <meta
    //         name="title"
    //         content="Online Personal Diary | Benefits Of Online Diary | Quill At Will"
    //     />
    //     <meta
    //         name="description"
    //         content="Know all about the diary and the reasons to opt for an online personal diary to maintain a record of your day-to-day experiences, feelings & thoughts."
    //     />
    //     <meta
    //         name="keywords"
    //         content="Blog Writing Platform"
    //     />
    // </Helmet>

    return (
        <>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px',
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        Diary - Why opt for an <a href="/diary">Online Personal Diary</a> , Benefits of an online diary
                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", }}
                    >
                        What is a Diary?
                        <br />
                    </Typography>

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={diary1} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        A diary is a place where an individual's daily routine, thoughts, feelings, or experiences are written down. It is often organised in a chronological order.
                                        <br />
                                        There are many different ways to keep a diary, or some people call it a secret diary, as many would like to put down certain thoughts, feelings or incidents that are private in their secret diary.
                                        People sometimes publish their diaries too! The most famous one being ‘The Diary of Anne Frank’.
                                        Have you read it?
                                        <br />
                                        <br />
                                        The ability to publish journals online as blogs came about with the introduction of the internet.
                                        Journals are increasingly taking the shape of a vlog these days- a sort of video diary.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    The following are some uses of diaries:
                                </Typography>

                                <ul >
                                    <li style={{ color: '#000' }} >
                                        A place to store private thoughts.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A chronology of events
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A just telling an intriguing tale.
                                    </li>
                                </ul>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    You can concentrate on your work while keeping your audience and other people's opinions out of your diary. Additionally, practising it frequently will help you think more creatively and improve your thought processes. This may be significant for many reasons.
                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Benefits of writing online diaries
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The online diary is even more interesting and engaging!
                                    <br />
                                    It offers great features to help you write your diary more creatively and efficiently. Also, the best online diaries provide premium features to even publish your diary and help you become a published author.

                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Why is an online personal diary better?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Simply put, an online personal diary is a place on the internet where you are allowed to log personal experiences and events freely.
                                    Therefore, using online journal software allows you to keep one with you at all times,
                                    on any day, and anywhere, in place of choosing a traditional diary or notebook where you may write down your thoughts, express your feelings, and use your creativity.
                                    A digital gadget is all that is required to get access to an online diary.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The online diary is great whether you're looking for a tool to log your daily thoughts and activities in a reflective journal, or diet diary, or even record your dreams in a dream journal.
                                    <br />
                                    Here are some of the benefits of using an online diary, which make it a better option for people to choose from.
                                    <br />
                                    These are:
                                </Typography>
                                <ol style={{ color: '#000' }}>
                                    <li style={{ color: '#000' }}>
                                        Privacy Concerns
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Write Anywhere
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Track your goals and habits.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Become a Published Author
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Earn Rewards
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Multimedia usage
                                    </li>
                                </ol>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }} >
                                    Let’s go over them in detail-
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                1. Privacy Concerns
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Anyone can access paper journals, even if they are meticulously maintained. With double password security and military-grade encryption, online diaries keep your journals safe, so you can rest easy knowing your entries are safe in the ‘Diary Vault’.
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                2. Write Anywhere
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                With the mobile apps for iPhone, iPad, android phones and tablets, you can take your journals everywhere you go without carrying any extra paper or pen.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                3. Track your goals and habits.
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Some of the best online dairies offer you the chance to keep track of your habits and goals. You can mark and maintain your goals. You can check your performance and work accordingly.
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                4. Become a Published Author
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                There are many online diary writing platforms that allow you to write a diary, and you can become a published author after you complete some essential stages for that. You can share your work on social media platforms and gain a wider audience.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                5. Earn Rewards
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                To make writing more interesting and exciting, online diary writing platforms reward their writers.
                                They keep some terms and conditions so that people can earn badges and other rewards offered by diary writing platforms.
                                <br />
                                This ultimately helps to improve writing and be consistent, complete your tasks and maintain your online diary more significantly.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                6. Multimedia usage
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Use technology to your advantage by integrating audio, video, or pictures in your journal. Nowadays, the majority of computers, phones come equipped with cameras, enabling you to include images or videos in your diary.
                                <br />
                                By integrating digital images, screen captures, video, and links to your favourite online sites, you may accomplish much more with your electronic journal.You can have fun by utilising all the multimedia features offered by electronic journaling.
                                <br />
                                <br />
                                A wonderful method to ensure your memories and experiences are secured is by keeping a regular journal. It enables you to stay organised, reflect on your past, and absorb lessons from your mistakes. Additionally, it has great therapeutic potential. Not only to document exciting and enjoyable experiences, but also difficult and unsettling ones. Recording changes in your life in an online journal can be beneficial in the long run.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DiaryPersonalBlog;