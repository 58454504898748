import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import aboutl from "../../../assets/images/about/Group-775.png";
import diaryImg1 from '../../../assets/images/diaryImg.png';
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function DiaryTypesBlog() {
    const classes = DiaryblogsStyles();

    return (
        <>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px',
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        Types of Diary, Journals and why it is important
                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", }}
                    >
                        “Your Journal is like your best friend, You don't have to pretend with it, you can be honest and write exactly how you feel”
                        <br />
                    </Typography>
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontSize: "17px", fontFamily: "Museo 700", textAlign: "end" }} >
                        ― Bukola Ogunwale.
                    </Typography>

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={diaryImg1} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        Writing a secret diary allows you to focus on your writing without worrying about your audience and getting judged. If you love journaling enough, it can truly become your best friend. And adopting the habit of diary writing consistently will give you a creative bent of mind as well as sharpen your thought processes.
                                        <br />
                                        This may have a significant positive impact in your daily life.

                                        <br />
                                        There are many types of journals you can write to channel your thoughts. Although different types of journals serve various purposes, it is imperative that you view journaling as a relaxing and gratifying activity since that's what it should be.
                                        The types of diaries you can keep to develop your thought process and make intelligent changes in your life are listed below.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    The following are some uses of diaries:
                                </Typography>

                                <ul >
                                    <li style={{ color: '#000' }} >
                                        A place to store private thoughts.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A chronology of events
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A just telling an intriguing tale.
                                    </li>
                                </ul>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    You can concentrate on your work while keeping your audience and other people's opinions out of your diary. Additionally, practising it frequently will help you think more creatively and improve your thought processes. This may be significant for many reasons.
                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Benefits of writing online diaries
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The online diary is even more interesting and engaging!
                                    <br />
                                    It offers great features to help you write your diary more creatively and efficiently. Also, the best online diaries provide premium features to even publish your diary and help you become a published author.

                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Why is an online personal diary better?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Simply put, an online personal diary is a place on the internet where you are allowed to log personal experiences and events freely.
                                    Therefore, using online journal software allows you to keep one with you at all times,
                                    on any day, and anywhere, in place of choosing a traditional diary or notebook where you may write down your thoughts, express your feelings, and use your creativity.
                                    A digital gadget is all that is required to get access to an online diary.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    1. Travel Journal
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Travelogues, usually referred to as travel diaries, are memoirs of the trip experiences. In your travel journal, you can write about your recent trip, paste the entry tickets of parks, monuments you visited, add photos of your travel group, and write something new you learnt from your visit to a new place.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    2. Secret Diaries
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Each person has some things they like to keep private. That is why so many people value having a secret diary. Secret diaries are essentially a place for you to express and record your most private thoughts of life.
                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                3. Gratitude Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                A gratitude journal is meant for writing down a list of things you are grateful for in a day. It can be big or small. Maintaining a gratitude journal helps one in becoming thankful for what they have, inculcating a positive attitude.
                                <br />
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                4. Dream Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                One of the most fascinating kinds of diary is the dream diary, in which you can record the dream you have had.Your dreams are regularly recorded in a dream journal, where you may observe the themes and key trends over time.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                7. Health Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                A health diary is more than just a log of medical testing results and calorie intake. It is a technique to keep track of emotions, feelings, goals, efforts, context-specific incidents, and results for any specific area of health.
                                <br />
                                Your comprehensive medical history is documented in a health journal. Some examples include, noting down your weight, blood sugar levels, and blood pressure readings over a period of time checked at regular intervals. It will help one understand the progress they have made with respect to their overall wellness.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                8. School Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Unlike printed school diaries, which we usually agree are pretty humdrum, electronic school diaries are substantially more customisable, and some of them come with many categories already set up for you.
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                9. Reflective Diary

                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                It is a space for you to express your feelings while keeping track of your events in a reflective journal.
                                <br />
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                10. Event Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Planning for an event has so many aspects involved that it's simple to lose sleep. The good part ? There is a way to organise your most awaited events! Be it a birthday party, housewarming, family get-togethers or any other celebration, plan by jotting down the details of shopping list, vendors, etc in your event planner/diary.
                                <br />
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                11. Work Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                In order to keep track of your daily work, examine career options, set milestones, and accomplish your goals, write about your professional experiences. Your observations on your professional life are written down in a work journal.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                12. Electronic Diary or Digital Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Whatever your motivations for journaling are, you will undoubtedly value the convenience and flexibility of an electronic diary. Digital diaries are online diaries that users keep and maintain.
                                <br />
                                There are numerous websites where you can maintain a digital journal and store your thoughts. Quillatwill.com offers you a digital diary and journal along with many exciting features like habit trackers, goal setting, and mood board.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                13. Creative Writing Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                A creative writing diary chronicles any poetry, stories, songs, or other artistic ideas that spring to mind.
                                <br />
                                Oftentimes, people design and decorate creative writing diaries where you can list your interests and hobbies. This kind of diary can also be used to compose creative inspirational pieces and write stories.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                14. Motivation Diaries
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Nowadays, most people enjoy keeping a motivational journal or diary. To inspire yourself, you can write about your strengths and make a list of life goals to achieve.
                                <br />
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                15. Audio Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Keeping an audio diary is convenient because all you need to do is upload your voice recordings to the platform you want to use for maintaining your audio diary.
                                <br />
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                16. Fitness Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                One of the most popular diaries among fitness aficionados is a fitness diary. A fitness diary, also known as a workout or exercise diary, tracks your accomplishments and also your daily dietary intake.
                                <br />
                                These diaries are used by people to record their favourite sports and their playing experiences.
                                <br />
                                Typically, athletes use it to store their individual records, accomplishments, trophies, and photos.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DiaryTypesBlog;