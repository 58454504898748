import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import moment from 'moment'
import _ from 'lodash'

/*
GET_USER_COMPLAINTS:'GET_USER_COMPLAINTS',
GET_COMPLAINTS:'GET_COMPLAINTS',
*/

export const getComplaintsByInstitute = () => async (dispatch, getState) => {
   const { tenantId } = getState().user;
   console.log(tenantId)
   await db.collection("complaints")
      .where('tenantId', '==', tenantId)
      .get()
      .then(async (complaints) => {
         let complaintsData = []
         complaints.docs.forEach(async (item) => {
            let complaint = item.data()
            complaint = {
               ...complaint,
               id: item.id
            }
            complaintsData.push(complaint)
         })
         dispatch(
            triggerReducer(actionTypes.GET_COMPLAINTS, {
               payload: complaintsData
            })
         );
      })
      .catch((err) => {
         console.log("Err get complaints --- ", err)
      })
};

export const getComplaintsByUser = () => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   await db.collection("complaints")
      .where('userId', '==', auth.uid)
      .get()
      .then(async (complaints) => {
         let complaintsData = []
         complaints.docs.forEach(async (item) => {
            let complaint = item.data()
            complaint = {
               ...complaint,
               id: item.id
            }
            complaintsData.push(complaint)
         })

         dispatch(
            triggerReducer(actionTypes.GET_USER_COMPLAINTS, {
               payload: complaintsData
            })
         );
      })
      .catch((err) => {
         console.log("Err get complaints --- ", err)
      })
};

export const raiseComplaint = (payload) => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { tenantId } = getState().user;
   const { firestore } = store;
   const date = firebase.firestore.Timestamp.fromDate(new Date())
   await firestore.add({ collection: "complaints" }, { ...payload,tenantId:tenantId, userId: auth.uid,status:"Open",createdAt:date })
      .then((resp) => {
         dispatch(getComplaintsByInstitute())
         dispatch(showSuccessSnackbar("Complaint raised successfully!"));
      })
      .catch((err) => {
         console.log("Error --- ", err)
      })
};

export const updateComplaintStatus = (payload) => async (dispatch,getState) =>{
   let date = firebase.firestore.Timestamp.fromDate(new Date())
   await db.collection('complaints').
      doc(payload).update({status:'Closed',closedAt:date})
      .then((res)=>{
         dispatch(getComplaintsByInstitute())
         dispatch(showSuccessSnackbar("Complaint Status Changed Successfully!"))
      })
      .catch((err)=>{
         console.log("Error -- ",err)
      })
}

export const addUpdateComment = (payload) => async(dispatch)=>{
   const commentData={
      date:firebase.firestore.Timestamp.fromDate(new Date()),
      comment:payload.comment
   }
   await db.collection('complaints')
      .doc(payload.id).update({comment:firebase.firestore.FieldValue.arrayUnion(commentData)})
      .then(res=>{
         dispatch(getComplaintsByInstitute())
      }).catch(err=>{
         console.log("err -- ",err)
         dispatch(showErrorSnackbar("Sorry, comment can't be added at this moment!"))
      })
}

//testing complaints

export const addComplaints = () => (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { firestore } = store;
   const { tenantId } = getState().user
   const arrayList = [
      'Website is crashing',
      'Postcard Received section has a bug',
      'Unparliamentary words in Blog',
      'This User voilates the community gauidelines.',
      'Review dont but stars not updated.',
      'Unparliamentary words in Essay'
   ]
   const tp = ['Technical Error','Complaint','Suggestion','Post Report']
   for (let i = 0; i < arrayList.length; i++) {
      let testDate = new Date('2022-10-20')
      testDate.setHours(13 + i)
      let date = firebase.firestore.Timestamp.fromDate(testDate)
      let status ='Open'

      firestore.add({ collection: "complaints" }, {
         title: arrayList[i],
         status,
         userId: auth.uid,
         tenantId: tenantId,
         createdAt: date,
         complaintCategory:tp[i%4]
      })
         .then((resp) => {
            console.log("annocuenfvnvn -- ", resp)
            dispatch(showSuccessSnackbar("compalints added successfully!"));
         })
         .catch((err) => {
            console.log("Error --- ", err)
         })
   }

}

//testing ends