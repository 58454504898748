import React, { useEffect, useState } from "react";
import {
    Grid, Paper, Typography, InputBase,
    InputLabel, FormControl, Button, Radio,
    FormControlLabel, RadioGroup, OutlinedInput, FormHelperText
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { setInstituteInfo, instituteSignup } from "../../store/actions/user";
import { useHistory } from "react-router-dom";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        color: '#000'
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '15px 12px',
        color: '#000',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
    formField: {
        width: '100%'
    }
}));

const AccountURL = (props) => {
    const dispatch = useDispatch()
    const [accountUrl, setAccountUrl] = useState('')
    const [showError, setShowError] = useState({
        status: false,
        message: ''
    })
    const auth = useSelector(state => state.firebase.auth);
    const history = useHistory()

    useEffect(() => {
        if (auth && auth.uid) {
            history.push('/user-organization')
        }
    }, [auth])

    const onChangeAccountUrl = (value) => {
        setShowError({
            status: false,
            message: ''
        })
        let data = value
        if (data.includes(' ')) {
            setShowError({
                status: true,
                message: 'Space is not allowed'
            })
            data = data.trim()
        } else if (!isNaN(data[0])) {
            setShowError({
                status: true,
                message: 'Cannot start with number'
            })
            data = ''
        }
        setAccountUrl(data)
    }
    
    const handleOnClickNext = () => {
        let data = {
            accountUrl
        }
        dispatch(setInstituteInfo(data))
        dispatch(instituteSignup())

        // props.setPageCount(4)
    }

    return (
        <Grid container style={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'center', maxHeight: '70%' }}>
            <Grid item sm={12} md={6} lg={4}>
                <Grid item sm={12} md={12} component={Paper} style={{ borderRadius: '10%', padding: 100, paddingTop: 150, paddingBottom: 80, textAlign: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Choose your account url
                    </Typography>
                    <br />
                    <Typography
                        variant="p"
                        style={{ fontFamily: 'Museo300', color: '#1E3A71' }}
                    >
                        This url will take you directly to your account
                    </Typography>
                    <br /><br /> <br /><br /><br /><br />
                    <FormControl variant="standard" style={{ width: '100%', textAlign: 'left', justifyContent: 'left', alignItems: 'left' }}>
                        <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold', marginTop: '-20px' }}>
                            School/Institute Name*
                        </InputLabel>
                        <br />
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type='text'
                            // value={values.password}
                            value={accountUrl}
                            onChange={(e) => onChangeAccountUrl(e.target.value)}
                            style={{ paddingRight: 0, backgroundColor: '#fcfcfb' }}
                            endAdornment={
                                <BootstrapInput
                                    id="bootstrap-input"
                                    value="quillatwill.com"
                                    type="text"
                                    style={{
                                        backgroundColor: '#89CFF0',
                                        color: '#89CFF0',
                                        witdh: 500
                                    }}
                                />
                            }
                        />
                        {
                            showError.status ?
                                <span style={{ color: '#f00', float: 'left' }}>
                                    &nbsp;&nbsp;{showError.message}
                                </span>
                                :
                                <span style={{ color: '#f00', float: 'left' }}>
                                    &nbsp;&nbsp;
                                </span>
                        }

                    </FormControl>

                    <br /><br />
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                        >
                            <FormControlLabel value="end" control={<Radio />}
                                label={
                                    <p>I agree to the&nbsp;
                                        <a
                                            style={{
                                                textDecoration: 'none',
                                                color: '#1E3A71',
                                                fontFamily: 'Museo700'
                                            }}
                                            href="#terms"
                                        >
                                            terms of use
                                        </a>
                                        &nbsp;and&nbsp;
                                        <a
                                            style={{
                                                textDecoration: 'none',
                                                color: '#1E3A71',
                                                fontFamily: 'Museo700'
                                            }}
                                            href="#privacy"
                                        >
                                            Privacy Policy
                                        </a>.
                                    </p>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    <br /><br /><br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#1E3A71',
                                fontFamily: 'Museo700',
                                color: '#fff',
                                width: '60%',
                                padding: 15
                            }}
                                size="large"
                                onClick={handleOnClickNext}
                            >
                                NEXT
                            </Button>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', margin: 'auto' }}>
                            <Button style={{
                                backgroundColor: '#fff',
                                fontFamily: 'Museo700',
                                color: 'gray',
                                width: '60%',
                                padding: 15,
                                textDecoration: 'underline'
                            }}
                                size="large"
                                onClick={() => { props.setSubPageCount(0); props.setPageCount(2); }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default AccountURL;
