import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
const pages = ["Home", "AboutUs", "ContactUs", "Hub", "Login", "Sign-Up"];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState("0");

  const changePage = (value) => {
    setValue(value);
    setOpenDrawer(false)
    switch (parseInt(value)) {
      case 0:
        history.push("/");
        break;
      case 1:
        history.push("/about");
        break;
      case 2:
        history.push("/contact");
        break;
      case 3:
        history.push("/hub/articles");
        break;
      case 4:
        history.push("/login");
        break;
      case 5:
        history.push("/sign-up");
        break;
      default:
        history.push("/");
        break;
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        <List>
          {pages.map((page, index) => (
            <ListItemButton key={index}>
              <ListItemIcon onClick={() => changePage(index)}>
                <ListItemText>{page}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
