import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import DiaryblogsStyles from './DiaryblogsStyles';
import PoemProductImg from '../../../assets/images/article/PoemProductImg.jpeg';

function GuidPoem() {
    const classes = DiaryblogsStyles();
    return (
        <>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "center" }}
                    >
                        Product Guide - Poem
                    </Typography>
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Some questions might arise in your mind while writing a poem or before starting one like-
                        <br />
                        <br />
                    </Typography>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        What are the different types of poems ?
                    </Typography>
                    <Typography
                        className={classes.headingTypo1}
                        style={{ fontSize: "18px", fontFamily: "Museo300", }}
                    >
                        The world of poetry is vast and diverse. Read about the most popular forms of poetry in our article, Poems and their types here (insert link from footer)
                        <br />
                        <br />
                    </Typography>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Does a poem have more than one type?

                    </Typography>
                    <Typography
                        className={classes.headingTypo1}
                        style={{ fontSize: "18px", fontFamily: "Museo300", }}
                    >
                        Yes, there are sonnets, which are rhyming verses, as well as lyric poems.

                        <br />
                        <br />
                    </Typography>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        The majority of poems rhyme, but do all poems have to rhyme?
                    </Typography>
                    <Typography
                        className={classes.headingTypo1}
                        style={{ fontSize: "18px", fontFamily: "Museo300", }}
                    >
                        No, a free verse and haiku need not rhyme, which is an example of non-rhyming poetry.
                        <br />
                    </Typography>

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>


                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} sm={12} style={{ textAlign: "left" }}>
                                    <img src={PoemProductImg} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}
                                >
                                    A glossary of commonly used terms in poetry.
                                </Typography>
                                <ul style={{ color: '#000', fontSize: "18px", fontFamily: "Museo300", }} className={classes.headingTypo1}
                                >
                                    <li style={{ color: '#000' }}>
                                        Stanza - a set amount of lines in poetry grouped together by their length, meter or rhyme scheme.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Couplet - a two-line stanza.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Tercet -  a three-line stanza.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Quatrain - a four-line stanza.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Cinquain - a five-line stanza.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Sestet - a six-line stanza.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Meter - the pattern of stressed syllables (long-sounding) and unstressed syllables (short-sounding) in poetry.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Rhyme scheme - the pattern of rhyme that comes at the end of each line or verse.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Syllable - the single, unbroken sound of a spoken or written word.

                                    </li>
                                </ul>

                                <br />
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}
                                >
                                    A step by step guide for writing poems on Quill at Will.

                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "18px", fontFamily: "Museo300", }}
                                >
                                    Step 1  Head on to the <b>Poem</b> section from your dashboard or the Create (+) button.<br />
                                    Step 2  To start a new poem, Click on the <b>Create New +</b> button.<br />
                                    Step-3 To edit an existing draft poem, click on the <b>Draft</b> icon.<br />
                                    Step-4 Click on the pencil icon beside <b>“Poem Title here”</b>  to give a title to your poem.<br />
                                    Step-5 On the left hand side, select a <b>category</b> for your poem from the range of Haiku, Sonnet, Ode, Ballad, Elegy, Villanelle, Limerick or simply choose Free verse to have your own customised format for the poem.<br />
                                    Step-6 <b>Write</b> your poem in the textbox in the centre.<br />
                                    Step-7 You can add images and background from the <b>Media</b> section to make it fun.<br />
                                    Step-8 You may opt to click on <b>Preview</b> to view a review of how your poem will look upon publishing or<br />
                                    Step-9 Click on <b>Publish</b> to post your poem for the viewers to read.

                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GuidPoem;