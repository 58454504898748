import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import OnlineJournalImg from "../../../assets/images/DiaryImages/OnlineJournal.jpeg";
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "./SecretDiaryStyles";
import Footer from "../../Footer";

function OnlineJournal() {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Online Diary | Online DiaryWriting App | Quill At Will</title>
                <meta
                    name="title"
                    content="Online Diary | Online DiaryWriting App | Quill At Will"
                />
                <meta
                    name="description"
                    content="Keep your daily record of news and events with our online journal platform - Quill at Will. Enjoy additional features like a mood tracker, habit tracker, goal tracker and more."
                />
                <meta
                    name="keywords"
                    content="Blog Writing Platform"
                />
            </Helmet>

            <Grid container style={{
                display: 'flex', justifyContent: "center", marginTop: '6rem', marginBottom: "3rem"
            }}>
                <Grid item xs={12} sm={12} md={10} lg={10} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        Quill At Will online journal- A vault for your thoughts
                    </Typography>
                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={OnlineJournalImg} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        When we talk to someone, we always calculate what we say, how much we say and how we say it. Sometimes, we find ourselves shy or bottled up and needing to express ourselves. A journal works well as a personal place which helps you store your feelings or ideas freely.
                                        <br />
                                        Quill At Will is a writing platform that helps you jot down your thoughts, experiences and observations with ease. It has tools such as media and stickers to make your journaling experience enjoyable. Our online journal is specially meant to provide you with a platform where you can pen your deepest thoughts without being judged or without hurting anyone’s feelings. A place where you can be honest with yourself.
                                        <br />
                                        And don’t worry, unlike physical journals, this online journal is protected by a password that only you know.
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "30px" }}
                                    >
                                        A safe space to store all your memories

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        It is said that noting down things helps in keeping your memory sharp. So why not note down all your happy memories, experiences, views, ideas, and keep them close to you in your Quillatwill online personal journal that is designed to be available for you round the clock.
                                        <br />
                                        While making your memory sharp as a part of the journaling process, it also helps you process your thoughts and see things clearly.
                                        <br />
                                        So start online journal writing today, express your thoughts and retrieve them on demand everywhere you go without having the need to carry a book and pen.
                                        <br />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}>
                                    Feature
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    <b style={{ color: "gray" }}>Password protection : </b> We know how important personal space is which is why your account is protected by a password like your social media accounts.
                                    <br />
                                    <b style={{ color: "gray" }}>Add media : </b> You can add media to your online personal journal or upload images to keep your memories safe.
                                    <br />
                                    <b style={{ color: "gray" }}>Customisable: </b>  In your online writing journal, you can add links, embed links, change headline styles, fonts, highlights and so much more to give your personal touch to your journal.
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default OnlineJournal;