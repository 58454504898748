import React, { Component } from 'react';
import CanvasJSReact from './canvas.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart

export default function PieChartPage(props) {

    CanvasJS.addColorSet("colorShades", ["#ADD8E6", "#FAD5C9", "#FFDD76", "#B8DA90"])
    const options = {
        theme: "light2",
        animationEnabled: true,
        exportFileName: props?.title,
        exportEnabled: true,
        colorSet: "colorShades",
        title: {
            text: props?.title
        },
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}</strong>",
            indexLabel: "{y}",
            indexLabelPlacement: "inside",
            dataPoints: props.dataPoints
        }]
    }
    return (
        <div style={{ border: '2px solid gray', borderRadius: '15px', padding: '5px 10px 0px 10px' }}>
            <CanvasJSChart options={options} />
        </div>
    );
}         