import React from "react";
import { useRef, useState, useEffect } from "react";
// component from library
import Stack from "@mui/material/Stack";
import InfoIcon from '@mui/icons-material/Info';

// material
import { Grid, Box, Button, Tooltip, IconButton, InputAdornment } from "@mui/material";

import { useFirestoreConnect } from "react-redux-firebase";
import TopImg from "../../../assets/images/DiaryImages/Path 512.png";
import view from "../../../assets/images/view.png";
import clap from "../../../assets/images/Path 425.png";
import share from "../../../assets/images/tele.png";
import premium from "../../../assets/images/Group.png";
import { useDispatch, useSelector } from "react-redux";
import DashboardStyles from "../DashboardStyles";
import { getUserPosts } from "../../../store/actions/posts";
import ProfilePic from '../../../assets/images/Profile/2.jpg'
import { setInstitute } from "../../../store/actions/user";
const userInit = {
  email: "",
  phoneNumber: "",
  avatarUrl: "",
  displayName: "",
};
export default function AppPremiumBadge() {
  const classes = DashboardStyles();
  const { firestore } = useSelector((state) => state);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const { counts } = useSelector((state) => state.posts);
  const { tenantId, institute, user } = useSelector((state) => state.user);
  const { posts } = useSelector((state) => state);
  // const [user, setUser] = useState(userInit);
  const [avatarUrl, setAvatarUrl] = useState('')
  // useFirestoreConnect([{ collection: "users", doc: auth.uid }]);
  // useFirestoreConnect([{ collection: "institutes", doc: auth.uid }]);

  useEffect(() => {
    if (user) {
      setAvatarUrl(user?.avatarUrl)
      // setUser({ ...user });
    }
  }, [user])

  useEffect(() => {
    if (tenantId)
      dispatch(getUserPosts(true));
  }, [tenantId]);

  const [tooltipOpen, setTooltipOpen] = useState(false)


  useEffect(() => {
    if (tooltipOpen) {
      setTimeout(() => setTooltipOpen(false), 3000)
    }
  }, [tooltipOpen])

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };



  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className={classes.appPreStyle1}
    // style={{
    //     borderRadius: '1rem',
    //     backgroundColor: '#EDF0F2',

    // }}
    >
      <img src={TopImg} style={{ width: '100%' }} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ position: "relative", padding: "10px" }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50px",
            backgroundImage: `url(${avatarUrl})`,
            position: "absolute",
            top: "-50px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>

        <h3
          style={{
            color: "#001f58",
            marginTop: "50px",
            marginBottom: "20px",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {user?.name}
        </h3>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
            <img src={view} />
            <p style={{ color: '#001f58' }}>{counts?.viewsCount}</p>
          </Grid>
          <Grid item xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
            <img src={clap} />
            <p style={{ color: '#001f58' }}>{counts?.likedByCount}</p>
          </Grid>
          <Grid item xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
            <img src={share} />
            <p style={{ color: '#001f58' }}>{counts?.sharedByCount}</p>
          </Grid>
        </Grid>
      </Stack>
      <Box
        style={{
          backgroundColor: "#001f58",
          textAlign: "center",
          width: "100%",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          borderRadius: "0 0 1rem 1rem",
        }}
      >
        <img src={premium} />
        <div style={{ marginTop: "0.5rem" }}>

          {/* <IconButton style={{ color: '#23348f', marginTop: "-0.5rem" }}>
            <InfoIcon />
          </IconButton> */}
          {
            window.innerWidth < 680 ?
              <Tooltip title="You are not a premium member. Subscribe to premium membership to enjoy premium benefits
                            "
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button variant="outlined" startIcon={<InfoIcon
                  onClick={handleTooltipOpen}
                />} style={{
                  fontSize: "12px",
                  color: "darkblue",
                  fontFamily: 'Museo700',
                  background: "#fff",
                  border: "none",
                  fontWeight: 'bold',
                  padding: "8px 8px 8px 10px",
                  borderRadius: "15px"
                }}>
                  Access my Premium
                </Button>
              </Tooltip> :
              <Tooltip title="You are not a premium member. Subscribe to premium membership to enjoy premium benefits">
                <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
          }
          {/* <CustomButton buttonText="Access my Premium" startIcon={<InfoIcon />}
          >
          </CustomButton> */}
        </div>
      </Box>
    </Stack>
  );
}
