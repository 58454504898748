import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { firebase, db } from '../../config/fbConfig'

export const getUserQuinns = (payload) => (dispatch, getState) => {
   dispatch(
      triggerReducer(actionTypes.GET_USER_QUINNS, {
         payload
      })
   );
};

export const getUserLogs = () => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   await db
      .collection('rewards')
      .where('userId', '==', auth.uid)
      .get()
      .then(querySnapshot => {
         querySnapshot.docs.forEach(doc => {
            if (doc) {
               let payload = {
                  ...doc.data(),
                  docId: doc.id
               }
               dispatch(triggerReducer(actionTypes.SET_USER_LOGS, { payload }));
               dispatch(triggerReducer(actionTypes.SET_USER_QUINNS, { payload: payload.totalQuinns }));

            }
         })
      }).catch((error) => {
         console.log("Error : ", error)
      });
};

export const addUserLogs = (payload) => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { userLogs } = getState().rewards;
   const { firestore } = store;
   let { totalQuinns, logs, totalBlogQuinns, totalPoemQuinns,
      totalEssayQuinns, totalPostcardQuinns, docId
   } = userLogs

   let { type, quinns } = payload
   let userPreviousLogs = logs
   userPreviousLogs.push(payload)
   let calculatedQuinns = totalQuinns ? totalQuinns + quinns : quinns
   let payloadData = {
      totalQuinns: calculatedQuinns,
      totalBlogQuinns: type === 'blog' ? totalBlogQuinns + quinns : totalBlogQuinns,
      totalPoemQuinns: type === 'poem' ? totalPoemQuinns + quinns : totalPoemQuinns,
      totalEssayQuinns: type === 'essay' ? totalEssayQuinns + quinns : totalEssayQuinns,
      totalPostcardQuinns: type === 'postcard' ? totalPostcardQuinns + quinns : totalPostcardQuinns,
      logs: userPreviousLogs,
      lastUpdatedDate: firebase.firestore.Timestamp.fromDate(new Date())
   }
   if (docId) {
      await firestore
         .collection("rewards")
         .doc(docId)
         .update(payloadData)
      let userLogsData = {
         ...userLogs,
         ...payloadData
      }
      dispatch(triggerReducer(actionTypes.SET_USER_LOGS, { payload: userLogsData }));
      dispatch(triggerReducer(actionTypes.SET_USER_QUINNS, { payload: calculatedQuinns }));
   } else {
      await firestore.add({ collection: "rewards" }, { ...payloadData, userId: auth.uid }).then((resp) => {
         let userLogsData = {
            ...userLogs,
            ...payloadData,
            docId: resp.id
         }
         // dispatch(getUserLogs())

         dispatch(triggerReducer(actionTypes.SET_USER_LOGS, { payload: userLogsData }));
         // dispatch(showSuccessSnackbar("User Log added Successfully!"));
      })
         .catch((error) => {
            console.log("Error adding quins : ", error)
         })
   }
};

export const updateUserLogs = (payload, docId) => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { firestore } = store;
   await firestore
      .collection("rewards")
      .doc(docId)
      .update(payload)
      .then((resp) => {
         dispatch(showSuccessSnackbar("Goal updated Successfully!"));
      })
};

export const deleteUserLog = (payload) => async (dispatch, getState) => {
   const { firestore } = store;
   await firestore.collection('rewards')
      .doc(payload.id).delete().then(() => {
         dispatch(showSuccessSnackbar("Goal deleted Successfully!"));
      }).catch((error) => {
         dispatch(showErrorSnackbar("Goal delete failed!!!"));
      });
}