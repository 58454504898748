import React, { useEffect, useState } from "react";
import { Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, DialogContent, TextField, DialogActions, Dialog, Typography } from '@mui/material';

import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';

import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { addRole, deleteRole, getRoles, updateRole } from "../../store/actions/roles";
import _ from 'lodash'

const labelstyle = {
    fontSize: "1.5rem",
    color: "#1f108e",
};

const ManageRoles = (props) => {
    const dispatch = useDispatch()
    const { roles } = useSelector(state => state.roles)
    const { tenantId } = useSelector(state => state.user)
    const [instituteRoles, setInstitutreRoles] = useState([])
    const [open, setOpen] = useState(false)
    const [role_name, setRoleName] = useState('')
    const [role_description, setRoleDescription] = useState('')
    const [short_name, setShortName] = useState('')
    const [updateRoleFlag, setUpdateRoleFlag] = useState(false)
    const [updateRoleId, setUpdateRoleId] = useState(null)
    const [updateRoleDocId, setUpdateRoleDocId] = useState(null)
    useEffect(() => {
        if (tenantId)
            dispatch(getRoles())
    }, [tenantId])


    useEffect(() => {
        let sortedRoles = []
        if (roles && roles.length > 0) {
            sortedRoles = _.sortBy(roles, ['role_id'])
        }
        setInstitutreRoles(sortedRoles)
    }, [roles])

    useEffect(() => {
    }, [instituteRoles])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const editRole = (index) => {
        let roleData = instituteRoles[index]
        let { role_name, role_description, role_id, id, short_name } = roleData
        setUpdateRoleFlag(true)
        setUpdateRoleDocId(id)
        setUpdateRoleId(role_id)
        setRoleName(role_name)
        setRoleDescription(role_description)
        setShortName(short_name)
        setOpen(true)
    }

    const renderActionIcons = (index, id) => {
        if (index === 0 || index === 1 || index === 4)
            return <BorderColorRoundedIcon style={{ float: 'left', fontSize: 30, textAlign: 'center', color: '#00008B' }} onClick={() => editRole(index)} />
        else {
            return <div style={{ display: 'flex', justifyContent: 'left' }}>
                <BorderColorRoundedIcon style={{ fontSize: 30, color: '#00008B', marginRight: 20, cursor: 'pointer' }} onClick={() => editRole(index)} />
                <DeleteOutlineIcon style={{ fontSize: 40, color: '#00008B', cursor: 'pointer' }} onClick={() => dispatch(deleteRole(id))} />
            </div>
        }
    }

    const renderHierarchyIcons = (index) => {
        if (index === 0)
            return <ArrowCircleUpTwoToneIcon style={{ float: 'left', fontSize: 40, color: '#fff', backgroundColor: '#00008B', borderRadius: 20 }} />
        else if (index === (instituteRoles.length - 1))
            return <ArrowCircleDownTwoToneIcon style={{ float: 'left', fontSize: 40, color: '#fff', backgroundColor: '#00008B', borderRadius: 20 }} />
        else {
            return <div style={{ display: 'flex', justifyContent: 'left', }}>
                <ArrowCircleUpTwoToneIcon style={{ fontSize: 40, color: '#fff', backgroundColor: '#00008B', marginRight: 20, borderRadius: 20 }} />
                <ArrowCircleDownTwoToneIcon style={{ fontSize: 40, color: '#fff', backgroundColor: '#00008B', borderRadius: 20 }} />
            </div>
        }
    }

    const resetValues = () => {
        setRoleName('')
        setRoleDescription('')
        setShortName('')
        setUpdateRoleFlag(false)
        setUpdateRoleId(null)
        setUpdateRoleDocId(null)
    }
    const handleOnClick = () => {
        let data = {
            role_name, role_description, short_name,
            role_id: instituteRoles[instituteRoles.length - 1].role_id + 1,
            role_code: short_name ? short_name.replaceAll(' ', '_').toLowerCase() : ''
        }
        if (updateRoleFlag) {
            data = {
                ...data,
                id: updateRoleDocId,
                role_id: updateRoleId
            }
            dispatch(updateRole(data))
            resetValues()
            setOpen(false);
        } else {
            dispatch(addRole(data))
            resetValues()
            setOpen(false);
        }
    }

    return (
        <Grid container>
            <Grid container item xs={12} md={12}>
                <Box
                    style={{
                        // height: '60vh',
                        width: '100%',
                        marginBottom: 20
                    }}
                >
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1.2rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Role
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1.2rem'
                                        }}
                                        align="center"
                                    >
                                        Description
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1.2rem'
                                        }}
                                        align="center"
                                    >
                                        Short Name
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1.2rem'
                                        }}
                                        align="center"
                                    >
                                        Hierarchy
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1.2rem'
                                        }}
                                        align="center"
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ border: '1px solid #747474', borderRadius: '25px' }}>
                                {instituteRoles.map((row, index) => (
                                    <TableRow
                                        key={row.role_name}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{
                                            borderRight: '1px solid gray', fontFamily: 'Museo700',
                                            fontWeight: 700, fontSize: '1rem', borderLeft: '1px solid gray'
                                        }}
                                        >
                                            {row.role_name}
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid gray', fontSize: '1rem', width: '30%' }}>{row.role_description}</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid gray', fontSize: '1rem' }}>{row.short_name}</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid gray', fontSize: '1rem', textAlign: 'center' }}>
                                            {renderHierarchyIcons(index)}
                                        </TableCell>
                                        <TableCell align="center" style={{ textAlign: 'center' }}>
                                            {renderActionIcons(index, row.id)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <br /><br />
            <br /><br />
            <Grid container item xs={12} md={12} style={{ display: 'flex', justifyContent: 'right' }}>
                <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" onClick={handleClickOpen} autoFocus>
                        Add Roles
                    </Button>
                </Grid>
            </Grid>
            <Dialog style={{
                // marginLeft: '0%',
                padding: '0',
                width: '40vw',
                align: 'right',
                left: '30%',
                top: '1%',
                paddingBottom: 20
            }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ padding: 0 }}>
                    <Grid container spacing={2} marginBottom="1rem" style={{ display: "flex", justifyContent: 'space-between' }}>
                        <Grid item xs={12} md={12} lg={12} style={{ height: '8vh', background: 'aqua', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <Typography variant="h6">
                                {updateRoleFlag ? 'Update Role' : 'Add Role'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ padding: '20px', marginTop: '-2rem' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                Role Name
                            </label>
                            <TextField
                                fullWidth
                                type="text"
                                id="blogtitle"
                                label="Enter Role Name"
                                style={{ width: "100%", cursor: "pointer !important" }}
                                size="small"
                                value={role_name}
                                onChange={(e) => setRoleName(e.target.value)}
                            />
                            <span style={{ color: "red", fontSize: "11px" }}>
                            </span>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container spacing={2} style={{ padding: '20px', marginTop: '-3rem' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                Role Short name
                            </label>
                            <TextField
                                fullWidth
                                type="text"
                                id="blogtitle"
                                label="Enter Short Name"
                                style={{ width: "100%", cursor: "pointer !important" }}
                                size="small"
                                value={short_name}
                                onChange={(e) => setShortName(e.target.value)}
                            />
                            <span style={{ color: "red", fontSize: "11px" }}>
                            </span>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container spacing={2} style={{ padding: '20px', marginTop: '-3rem' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                Role Description
                            </label>
                            <TextField
                                fullWidth
                                type="text"
                                id="blogtitle"
                                label="Enter Role Description"
                                style={{ width: "100%", cursor: "pointer !important" }}
                                size="small"
                                value={role_description}
                                onChange={(e) => setRoleDescription(e.target.value)}
                            />
                            <span style={{ color: "red", fontSize: "11px" }}>
                            </span>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: 20 }}>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleOnClick} autoFocus>
                        {updateRoleFlag ? 'Update' : 'Add'}
                    </Button>

                </DialogActions>
            </Dialog>
        </Grid>

    );
}

export default ManageRoles;
