
import React from "react";
import s from "./style.module.css"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const Loader = () => {
    return(
        <>
            <div className={s.root}>
                <div className={s.textcenter}>
                {/* <img src="/assets/home/loader_new.svg" alt="loader" className={s.image}/> */}
                <CircularProgress className={s.image} />
                <p data-text="loading" className={s.method}>Loading</p>
                </div>
            </div>
        </>
    
    )
}
  
export default Loader;