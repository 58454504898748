import React, { useEffect } from "react";
import { Grid, Stack, Box, CardContent, Typography } from "@mui/material";
import PostCardStyles from "../PostCardStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLatestUsersImages } from '../../../store/actions/images'
import { setOpenedPostCard } from "../../../store/actions/postCards";

import AddIcon from "@mui/icons-material/Add";

function PostCardReceivd(props) {
  const classes = PostCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector(state => state.firebase.auth);
  const { usersLatestImages } = useSelector(state => state.images);

  useEffect(() => {
    let uid = auth.uid ? auth.uid : ''
    dispatch(getLatestUsersImages({}, uid))
  }, [])

  const handleOnClickCreate = () => {
    let card = {
      from: null,
      to: [],
      content: null,
      image: null
    }
    dispatch(setOpenedPostCard(card, false))
    history.push('/postcard-editor')
  }
  return (
    <Grid container spacing={2}
    //  style={{overflow:'hidden',
    // height:'14vw', }}
    >
      <Grid item xs={12} sm={4} md={3} lg={3}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: 'hidden',
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box className={classes.createbtn}
                onClick={handleOnClickCreate}>
                <AddIcon
                  className={classes.addbtn}
                />
                <CardContent className={classes.cardTitle}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ cursor: 'pointer', textAlign: 'center', display: 'flex', justifyContent: 'center', color: "rgb(30, 58, 113)", fontFamily: "Museo700", }}
                  >
                    Create New
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      {usersLatestImages && usersLatestImages.length > 0 ?
        usersLatestImages.map((img, index) => {
          return index < 3 && (<Grid item xs={12} sm={12} md={3} lg={3}>
            <Box style={{ padding: 0, }}>
              <img
                component="img"
                // height="140"
                src={img}
                alt="green iguana"
                className={classes.cardImg1}
              />
            </Box>

            {/* <Stack
                      style={{
                        padding: "0",
                        marginTop: "1rem",
                        borderRadius: "2rem",
                        overflow: "hidden",
                        boxShadow: "white 0px 0px 1px 0px",
                      }}
                    >
                      <Grid container spacing={1} direction="column">
                        <Grid item>
            
                          <Box style={{ padding: 0, position: "relative" }}>
                            <img
                              component="img"
                              // height="140"
                              src={Post}
                              alt="green iguana"
                              style={{ width: "100%" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Stack> */}
            {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.cardText}
                      >
                        {card.imageName}
                      </Typography> */}
          </Grid>)
        }
        ) : null

      }

      {/* <Grid item xs={12} sm={4} md={3} lg={3}>

                                <Box style={{ padding: 0 }}>
                                    <img
                                        component="img"
                                        // height="140"
                                        src={Post3}
                                        alt="green iguana"
                                        className={classes.cardImg1}
                                    />
                                   </Box>
         <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.cardText}
          >
            Beach
          </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={3} lg={3}>
      <Box style={{ padding: 0 }}>
                                    <img
                                        component="img"
                                        // height="140"
                                        src={Post3}
                                        alt="green iguana"
                                        className={classes.cardImg1}
                                    />
                                   </Box>
              <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.cardText}
          >
            Summer
          </Typography>
      </Grid> */}
    </Grid>
  );
}

export default PostCardReceivd;
