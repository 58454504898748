import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box, TextField, IconButton, Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { Switch } from '@mui/material';
import store from "../../../store/store";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../store/actions/snackbar";
import { getProfileImages } from '../../../store/actions/images';
import { setUserAvatar } from '../../../store/actions/user';
import AppBottomGrid from './AppBottomGrid';

const useStyles = makeStyles({
  radiobutton: {
    height: 0,
    opacity: 0,
    width: 0,
    position: "absolute",
  },
  profileImgStyle: {
    height: "50px",
    width: "50px",
    borderRadius: "25px",
    position: 'relative',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    cursor: 'pointer'
  },
});
const userInit = {
  email: '',
  phoneNumber: '',
  tagLine: '',
  photoURL: '',
  dob: '',
  password: '',
  cPassword: '',
  newPassword: '',
};

const AppCentergrid = (props) => {

  const passwordauth = getAuth();
  const currentuserp = passwordauth.currentUser;
  const [dobdate, setDOBDate] = useState();
  const classes = useStyles();
  const { tenantId, user: userData } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const [user, setUser] = useState(userInit);
  const { profileImages } = useSelector(state => state.images)
  const [avtarUrl, setAvtarUrl] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)


  useEffect(() => {
    if (tooltipOpen) {
      setTimeout(() => setTooltipOpen(false), 3000)
    }
  }, [tooltipOpen])

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };


  useEffect(() => {
    if (userData && avtarUrl?.length < 1) {
      setUser({ ...user, ...userData });
      setAvtarUrl(userData?.avatarUrl)
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getProfileImages())
  }, [])

  const onChange = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
    if (name === "avatarUrl") {
      setAvtarUrl(value)
      dispatch(setUserAvatar(value))
    }

  };
  const onSubmit = event => {
    const userObject = {
      ...user
    };
    store.firestore.collection('users').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
      dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
    });
  };

  const checkOldPass = event => {
    const oldpass = event.target.value;
    try {
      const credential = EmailAuthProvider.credential(
        currentuserp.email,
        oldpass
      );
      reauthenticateWithCredential(currentuserp, credential).then(() => {

      });
    } catch (error) {

      dispatch(showErrorSnackbar(`Profile Updated Failed!!`));
    }
  }

  const setPass = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  }

  const updatePass = event => {
    if (user.newPassword == user.cPassword) {
      const newUserPassword = user.newPassword;
      updatePassword(currentuserp, newUserPassword).then(() => {
        dispatch(showSuccessSnackbar(`Password Updated !!`));
      }).catch((error) => {
        // An error ocurred

        dispatch(showErrorSnackbar(`Profile Updated Failed!!`));
        // ...
      });

    }
    else {
      dispatch(showErrorSnackbar(`Profile Updated Failed!!`));
    }
  }
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{
          // height: '95vh',
          border: "none",
          borderRadius: "1rem",
          backgroundColor: "#fff",
          padding: "2rem 2rem 0rem 2rem",
          paddingBottom: "8px",
          marginTop: "1rem",
          boxShadow: "black 1px 1px 4px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={10}
            md={10}
            lg={10}
            style={{ padding: "12px", margin: 'auto', marginTop: "0.5rem", display: "flex", justifyContent: 'center', textAlign: "center" }}
          >
            {
              window.innerWidth < 680 ?
                <Tooltip title="Choose from our range of cute profile pictures.
        "
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <IconButton style={{ color: '#23348f', marginTop: '-1rem' }}
                    onClick={handleTooltipOpen}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip> :
                <Tooltip title="It is the heading of the article being posted under a blog.">
                  <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
            }
            <h3 >Set Avatar As Profile Picture</h3>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          style={{
            marginTop: "0px",
            justifyContent: "space-between",
            minHeight: '54px'
          }}
        >
          {
            profileImages && profileImages.length > 0 ?
              profileImages.map((proPic) =>
                <Grid item sm={4} xs={4} md={1} ld={1}>
                  <label>
                    <input className={classes.radiobutton} type="radio" name="avatarUrl" value={proPic} onChange={onChange} onBlur={onSubmit} />
                    <div style={{
                      padding: 2, borderRadius: '28px',
                      border: proPic === avtarUrl ? '3px solid #1976D2' : 'none',
                      marginTop: proPic === avtarUrl ? '-2px' : 0
                    }}
                    >
                      <div className={classes.profileImgStyle}
                        style={{ backgroundImage: `url(${proPic})` }}
                      >
                      </div>

                    </div>
                  </label>
                </Grid>
              ) : null
          }

        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <h5 style={{ color: "#1f108e" }}>Email Id</h5>
            <span style={{ color: "grey" }}>{auth?.email || ''}</span>
          </Grid>
          <br /><br />
          {/* <Grid item xs={12} md={6} lg={4}>
          <h5 style={{ color: "#1f108e" }}>Mobile No</h5>
          <TextField type="number" id="standard-basic" label="" variant="standard" name="phoneNumber" value={user.phoneNumber.toString()} onChange={onChange} onBlur={onSubmit} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <h5 style={{ color: "#1f108e" }}>Birthday</h5>
          <DatePicker selected={dobdate} name="dob" onChange={(date) => setDOBDate(date)} />
        </Grid> */}
        </Grid>
        <Grid container>
          <h5 style={{ color: "#1f108e" }}>Change Password</h5><br />
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <p style={{ color: "grey", marginBottom: "0px" }}>Old Password</p>
              <TextField type="password" label="" variant="standard" name="password" onBlur={checkOldPass} />
              {/* <span style={{ color: "grey" }}>********</span> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <p style={{ color: "grey", marginBottom: "0px" }}>New Password</p>
              <TextField type="password" label="" variant="standard" name="newPassword" onChange={setPass} />
              {/* <span style={{ color: "grey" }}>********</span> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <p style={{ color: "grey", marginBottom: "0px" }}>
                Confirm New Password
              </p>
              <TextField type="password" label="" variant="standard" name="cPassword" onChange={setPass} onBlur={updatePass} />

              {/* <span style={{ color: "grey" }}>********</span> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: "0px" }}>
          <h4 style={{ color: "#1f108e" }}>Settings</h4>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} md={10} lg={10}>
                <p>When someone comments or claps on your work</p>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "end" }}>
                <Switch defaultChecked />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} md={10} lg={10}>
                <p>When someone sends you something</p>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "end" }}>
                <Switch defaultChecked />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} md={10} lg={10}>
                <p>When someone adds you as a collaborator</p>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "end" }}>
                <Switch defaultChecked />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} md={10} lg={10}>
                <p>Reminders about habit tracker, goals, to do tasks</p>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "end" }}>
                <Switch defaultChecked />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} md={10} lg={10}>
                <p>Email all notifications</p>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "end" }}>
                <Switch defaultChecked />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Grid item sm={12} xs={12} md={12} lg={12}>
        <br />
        <h3 style={{ color: "#1f108e" }}>Interest</h3>
        <AppBottomGrid />
      </Grid>
    </>
  );
}
export default AppCentergrid;