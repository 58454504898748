import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";
import handsblack from "../../../assets/images/Blog/BD/Group 72.png";
import profile from "../../../assets/images/Ellipse 19.png";
import profilePicSrc1 from "../../../assets/images/dashboard/Group 654.png";
import BlogEditorStyles from "../BlogEditorStyles";
import { getLatestComments } from "../../../store/actions/comments";
import { useDispatch, useSelector } from "react-redux";
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import { useHistory } from "react-router-dom";

export default function AppComments() {
  const classes = BlogEditorStyles();
  const dispatch = useDispatch();
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMoreComments, setShowMoreComments] = useState([])
  const latestComments = useSelector((state) => state.comments.latestComments)
  const auth = useSelector(state => state.firebase.auth);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getLatestComments())
  }, [])

  const onClickReadMore = (index) => {
    let arrayData = showMoreComments.length > 0 ? [...showMoreComments] : []
    arrayData.push(index)
    setShowMoreComments(arrayData)
  }

  const onClickViewLess = (index) => {
    let arrayData = [...showMoreComments]
    arrayData = arrayData.filter((item) => item !== index)
    setShowMoreComments(arrayData)
  }
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        marginTop: "2rem",
      }}
    >
      <h3 style={{ color: "#1f108e" }}>Latest Comments</h3>
      <Grid container direction="row" spacing={2}>
        {
          latestComments && latestComments.length > 0 ?
            latestComments.map((cmt, index) =>
              <Grid key={index} item xs={12} md={12} lg={12}>
                <Box className={classes.blogCardstyle} style={{ padding: '10px 20px' }}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={9} md={9} lg={9} style={{ marginBottom: 10 }}>
                      <span
                        className="spanTest"
                        style={{
                          color: "#1d396f",
                          fontSize: '20px', cursor: 'pointer',
                          fontFamily: 'Museo700',
                          '&:hover': {
                            textDecoration: 'underline',
                            border: '1px solid red'
                          }
                        }}
                        onClick={() => history.push('/blog/' + cmt?.postData?.postId + '/' + cmt?.postData?.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())}
                      >
                        {cmt?.postData?.blogId?.label} - {cmt?.postData?.title}
                      </span>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                      <Grid container justifyContent="end" style={{}}>
                        <Grid item>
                          <img src={handsblack} alt="hands" />
                          <span style={{ color: "grey", marginRight: 10 }}>{' ' + (cmt?.postData?.likedby?.length || 0) + ' '}</span>
                        </Grid>
                        <Grid item>
                          <MapsUgcOutlinedIcon style={{ fontSize: 20, color: 'gray' }} />
                          <span style={{ color: "grey" }}>{' ' + cmt?.postData?.commentscount || 0}</span>
                        </Grid>
                        <Grid item>
                          <span style={{ color: "grey", marginLeft: "10px" }}>
                            {" "}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={1} lg={1}>
                      <img src={cmt?.user?.avatarUrl} width="50" height="50" alt="" style={{ borderRadius: 25 }} />
                    </Grid>
                    <Grid item xs={12} md={10} lg={10}>
                      <span style={{ color: "grey", fontWeight: "bold" }}>
                        {
                          auth.uid === cmt?.user?.id ? "You" : cmt?.user?.displayName
                        }
                      </span>
                      <Typography>
                        {
                          cmt?.comment
                        }
                      </Typography>

                      <Typography style={{ color: "grey", marginTop: 20 }}>
                        {/* <img src={handsblack} alt="" /> */}
                        <span style={{ marginLeft: 20 }}>{cmt?.children?.length || 0}</span>
                        <span style={{ marginLeft: 5, marginRight: 20 }}>Replies</span>
                        {
                          cmt?.children?.length ?
                            showMoreComments.includes(index) ?
                              <span style={{ color: "#1d396f", cursor: 'pointer', fontWeight: 700 }} onClick={() => onClickViewLess(index)}>View less</span>
                              :
                              <span style={{ color: "#1d396f", cursor: 'pointer', fontWeight: 700 }} onClick={() => onClickReadMore(index)}>Read more</span>
                            : null
                        }
                      </Typography>
                      <Grid container style={{ marginTop: 20, display: showMoreComments.includes(index) ? 'inline' : 'none' }}>
                        {
                          cmt && cmt.children && cmt.children.length > 0 ?
                            cmt.children.map((subcmt, index) =>
                              <Grid key={index} container style={{ marginTop: 20 }}>
                                <Grid item xs={1} md={1} lg={1}>
                                  <img src={subcmt?.user?.avatarUrl} width="30" height="30" style={{ borderRadius: 15 }} alt="" />
                                </Grid>
                                <Grid item xs={10} md={10} lg={10}>
                                  <span style={{ color: "grey", fontWeight: "bold" }}>
                                    {
                                      auth.uid === subcmt?.user?.id ? "You" : subcmt?.user?.displayName
                                    }
                                  </span>
                                  <Typography>
                                    {
                                      subcmt?.comment
                                    }
                                  </Typography>
                                  <Typography style={{ color: "grey" }}>
                                    <img src={handsblack} alt="" />
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : null
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : null
        }

      </Grid >
    </Stack >
  );
}
