import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Stack,
  Box,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import bgpremium from "../../assets/images/DiaryImages/bgpremium.png";
import a5vedio from "../../assets/images/DiaryImages/A4 - 5.png";
import a4audio from "../../assets/images/DiaryImages/A4 - 4.png";
import vedioimg from "../../assets/images/DiaryImages/image-vedio.png";
import AudioBg from "../../assets/images/DiaryImages/audiobg.png";
// import vedioicon from "../../../../images/DiaryImages/vedio.png";
// import uploadicon from "../../../../images/DiaryImages/upload.png";
// import recordicon from "../../../../images/DiaryImages/record.png";
// import videaoPopup from "../../../../images/DiaryImages/videao-popup.png";
import { useSelector, useDispatch } from "react-redux";
import audioDefaultWave from "../../assets/images/DiaryImages/audio-default-wave.png";
import CloseIcon from "@mui/icons-material/Close";
// import close from "../../../../images/DiaryImages/close.png";
// import startRecord from "../../../../images/DiaryImages/start-record.png";
// import news from "../../../../images/DiaryImages/new.png";
import DiaryStyles from "./DiaryStyles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import "./DiaryCustomStyles.css";
import {
  ReactMediaRecorder,
  useReactMediaRecorder,
} from "react-media-recorder";
import DiaryPremiumBannerStyles from "./DiaryPremiumBannerStyles";
// import Player from "react-wavy-audio";
import AudioRecordModal from "./AudioRecordModal";
import VideoRecordModal from "./VideoRecordModal";
import { fileUploadService } from "../../helpers";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import UploadIcon from "@mui/icons-material/Upload";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import WaveSurfer from "wavesurfer.js";
import { firebase, db, storage, auth } from "../../config/fbConfig";
import PremiumButton from "../PremiumButton";
import PaymentForm from "../PaymentForm";
var premiumbox = {
  width: "100%",
  maxHeight: "430px",
  minHeight: "430px",
  backgroundImage: `url(${bgpremium})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  paddingTop: "30px",
  borderRadius: "30px",
};
const DiaryPremium = ({ onSaveAudio, onSaveVideo, diaryData,isPremiumUser
}) => {
  console.log(diaryData,'diaryData')
  const {
    status,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    muteAudio,
    unmuteAudio,
    mediaBlobUrl,
    clearBlobUrl,
    isMuted,
    previewStream,
  } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: {
      type: "audio/wav",
    },
  });

  const [visible, setVisible] = React.useState(0);
  const classes = DiaryStyles();
  const diaryPremiumBannerClasses = DiaryPremiumBannerStyles();
  const [open, setOpen] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  const [premimumVisiblity, setPremimumVisiblity] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const waveformRef = useRef();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const handleVideoModal = (val) => {
    setVideoModalVisible(val);
  };
  const handleAudioModal = (val) => {
    setAudioModalVisible(val);
  };
  const handleAudioSave = (file) => {
    console.log("handleAudioSave", file);
    onSaveAudio(file);
    setAudioModalVisible(false);
  };
  const handleVideoSave = (file) => {
    console.log("handleVideoSave", file);
    onSaveVideo(file);
    setVideoModalVisible(false);
  };
  const [openAudio, setOpenAudio] = useState(false);
  const handleOpenAudio = () => {
    setOpenAudio(true);
  };

  useEffect(() => {
    if (waveformRef.current) {
      const track = document.querySelector("#track");
      const waveSurfer = WaveSurfer.create({
        container: waveformRef.current,
        barWidth: 3,
        cursorWidth: 1,
        backend: "WebAudio",
        height: 80,
        progressColor: "#2D5BFF",
        responsive: true,
        waveColor: "#EFEFEF",
        cursorColor: "transparent",
      });
      if (waveSurfer) {
        waveSurfer.load(track);
      }
      

      return () => {
        if (waveSurfer) {
          waveSurfer.destroy();
        }
      };
    }
  }, [diaryData?.audio, waveformRef.current]);

  const handleAudioFileUploadService = async (file) => {
    if (file.size < 1024 * 1024 * 10) {
      const filePath =
        "diary/audio/" +
        auth.currentUser.uid +
        "/" +
        new Date().getTime() +
        ".mp3";
      const fileUrl = await fileUploadService(file, filePath);
      console.log("fileUrl", fileUrl);
      onSaveAudio(fileUrl);
    } else {
      console.log("Can not upload file as it is greater than 10 MB");
    }
  };

  const handleVideoFileUploadService = async (file) => {
    if (file.size < 1024 * 1024 * 10) {
      const filePath =
        "diary/video/" +
        auth.currentUser.uid +
        "/" +
        new Date().getTime() +
        ".mp4";
      const fileUrl = await fileUploadService(file, filePath);
      console.log("fileUrl", fileUrl);
      onSaveVideo(fileUrl);
    } else {
      console.log("Can not upload file as it is greater than 10 MB");
    }
  };
  return (
    <>
      <div>
        <PaymentForm setRef={formRef} />
        <div style={{ margin: "20px 20px 0px 20px" }}>
          <Grid>
            <div>
              {!isPremiumUser ? (
                <Grid container style={premiumbox}>
                  <Grid item xs={6}>
                    <div>
                      <img src={a5vedio} style={{ width: "100%" }} />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <img src={a4audio} style={{ width: "100%" }} />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <p style={{ padding: "0px 20px" }}>
                      Go Premium and enjoy 1 minute Video diary and Audio Diary
                      feature
                    </p>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <PremiumButton
                      buttonText="Go Premium"
                      customStyles={{
                        borderRadius: "30px",
                        fontSize: "20px",
                        backgroundColor: "#9747FF",
                        color: "white",
                        padding: "2px 27px",
                        marginTop: "6px",
                      }}
                      handleClick={() =>formRef.current?.click()}
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div>
                    {diaryData?.video ? (
                      <Grid
                        container
                        style={{
                          backgroundImage: `url(${vedioimg})`,
                          width: "100%",
                          height: "220px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          padding: "20px 10px",
                          borderRadius: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12}>
                          <div>
                            <h4>Video Diary</h4>
                          </div>
                          <div
                            style={{
                              paddingBottom: "10px",
                            }}
                          >
                            <video
                              src={diaryData?.video}
                              width={"100%"}
                              autoPlay={false}
                              controls
                              className={diaryPremiumBannerClasses.videoPlayer}
                            />
                          </div>
                          {/* <div style={{ marginBottom: '15px', marginRight: '20px' }}>
                       <span
                         style={{
                           backgroundColor: '#fff',
                           padding: '3px 20px',
                           borderRadius: '15px',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           width:"100px"
                         }}
                       >
                         <img src={news}></img>&nbsp;<span>New</span>
                       </span>
                     </div> */}
                        </Grid>

                        <Grid item xs={12}></Grid>
                      </Grid>
                    ) : (
                      <>
                        {isPremiumUser && (
                          <Box>
                            <Grid
                              container
                              style={{
                                backgroundImage: `url(${vedioimg})`,
                                width: "100%",
                                height: "210px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                padding: "20px 10px",
                                borderRadius: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <Grid item xs={12}>
                                <div>
                                  <h4>Video Diary</h4>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    textAlign: "center",
                                    padding: "20px 0px",
                                  }}
                                >
                                  <Button
                                    onClick={() => handleVideoModal(true)}
                                    className={
                                      diaryPremiumBannerClasses.actionBtn
                                    }
                                    variant="contained"
                                    startIcon={<VideoCallIcon />}
                                  >
                                    Record
                                  </Button>
                                  <Button
                                    component="label"
                                    className={
                                      diaryPremiumBannerClasses.actionBtn
                                    }
                                    variant="contained"
                                    startIcon={<UploadIcon />}
                                  >
                                    Upload
                                    <input
                                      hidden
                                      accept="video/*"
                                      multiple={false}
                                      type="file"
                                      onChange={(e) =>
                                        handleVideoFileUploadService(
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </>
                    )}
                    {diaryData?.audio ? (
                      <Grid
                        container
                        direction={"column"}
                        style={{
                          backgroundImage: `url(${AudioBg})`,
                          width: "100%",
                          height: "210px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          padding: "10px",
                          borderRadius: "10px",
                          backgroundColor: "white",
                          border: "1px solid #E0E0E0",
                        }}
                      >
                        <Grid item xs={12} sm container>
                          <div
                            style={{
                              width: "100%",
                              height: "30px",
                            }}
                            ref={waveformRef}
                          ></div>
                        </Grid>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item>
                            <div>
                              <h4>Audio Diary</h4>
                            </div>
                          </Grid>
                          <Grid item>
                            <audio
                              src={diaryData?.audio}
                              // src={url}
                              controls
                              id="track"
                              className={diaryPremiumBannerClasses.audioPlayer}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {isPremiumUser && (
                          <Box>
                            <Grid
                              container
                              style={{
                                backgroundImage: `url(${vedioimg})`,
                                width: "100%",
                                height: "210px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                padding: "10px",
                                borderRadius: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    textAlign: "center",
                                    padding: "20px 0px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Button
                                    onClick={() => handleAudioModal(true)}
                                    className={
                                      diaryPremiumBannerClasses.actionBtn
                                    }
                                    variant="contained"
                                    startIcon={<SpatialAudioOffIcon />}
                                  >
                                    Record
                                  </Button>
                                  <Button
                                    // onClick={() => handleAudioModal(true)}
                                    component="label"
                                    className={
                                      diaryPremiumBannerClasses.actionBtn
                                    }
                                    variant="contained"
                                    startIcon={<UploadIcon />}
                                  >
                                    Upload
                                    <input
                                      hidden
                                      accept="audio/*"
                                      multiple={false}
                                      type="file"
                                      onChange={(e) =>
                                        handleAudioFileUploadService(
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  </Button>
                                </div>
                                <div>
                                  <h4>Audio Diary</h4>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </>
                    )}
                  </div>

                  <div></div>
                </>
              )}
            </div>
          </Grid>
        </div>
        <VideoRecordModal
          videoModalVisible={videoModalVisible}
          onClose={() => handleVideoModal(false)}
          onSave={(fileSource) => handleVideoSave(fileSource)}
        />
        <AudioRecordModal
          audioModalVisible={audioModalVisible}
          onClose={() => handleAudioModal(false)}
          onSave={(fileSource) => handleAudioSave(fileSource)}
        />
        <div></div>
      </div>
    </>
  );
};
export default DiaryPremium;