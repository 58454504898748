import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import girlImg from '../../assets/images/girlImg.png';

function CreateAccount(props) {

    useEffect(() => {
        setTimeout(() => props.setPageCount(3), 100)
    }, [])

    return (
        <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', maxHeight: '70%' }}>
            <Grid style={{ borderRadius: '18vw', height: '35vw', width: '35vw', border: '19px solid #FFFFFF', padding: '4.5vw' }}>
                <img src={girlImg} alt='grdgrd' style={{ width: '24vw', height: '24vw', margin: 'auto', display: 'flex', justifyContent: 'center' }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center', position: 'relative' }}>
                <Typography style={{ fontSize: '3rem', color: '#fff', fontWeight: 'bold' }}>
                    Create Your Account
                </Typography>
                <Typography style={{ fontSize: '1rem', color: '#fff', fontWeight: '500' }}>
                    You will be taken to your new trail
                </Typography>
            </Grid>

        </Grid >
    )
}

export default CreateAccount