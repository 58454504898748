import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	message_announcement: []
};

export const message_announcement = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.GET_ANNOUNCEMENTS:
			return {
				...state,
				message_announcement: payload
			}
		default:
			return state;
	}
};


