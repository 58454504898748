import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebase } from "../../config/fbConfig";
import DiaryStyles from "../Diary/DiaryStyles";

import {
  deleteHabit,
  getHabits,
  saveHabits,
  updateHabits,
} from "../../store/actions/habitTracker";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
// import { CloudLightning } from "react-bootstrap-icons";
import { showErrorSnackbar } from "../../store/actions/snackbar";

function DiaryHabit() {
  const classes = DiaryStyles();
  const dispatch = useDispatch();
  const { goals, openedGoal } = useSelector((state) => state.goals);
  const auth = useSelector((state) => state.firebase.auth);
  const { savedAspirations } = useSelector((state) => state.aspirations);
  const [input, setInput] = useState("");
  const [aspirations, setAspirations] = useState([]);
  const [color, setColor] = useState("#a093c7");
  const [textColor, setTextColor] = useState("white");
  const state = useSelector((state) => state);
  const { habits } = useSelector((state) => state.habit);
  //   const [status, setStatus] = useState(false);
  const [datePayload, setDatePayload] = useState([]);
  const [status, setStatus] = useState(new Array(30));
  const [statusRead, setStatusRead] = useState(new Array(30));
  const [statusDrink, setStatusDrink] = useState(new Array(30));
  const [payload, setPayload] = useState([]);

  const [interestsListArray, setInterestsListArray] = useState([]);
  const [habitsData, setHabitsData] = useState([]);

  const [todayDate, setTodayDate] = useState({
    0: parseInt(new Date().toJSON().slice(0, 10).split("-")[2])
  });
  console.log("sss", parseInt(new Date().toJSON().slice(0, 10).split("-")[2]))
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const daysInThisMonth = new Date(currentYear, currentMonth, 0).getDate();

  useEffect(() => {
    dispatch(getHabits());
  }, []);

  useEffect(() => {
    // setHabits(habits)
    // console.log("habits:--", habits);
  }, [habits]);

  const datePayloadData = () => {
    let date = moment().format("YYYY-MM-01");
    let daysInMonth = moment().daysInMonth();
    setInterestsListArray(new Array(daysInMonth));
    for (let i = 0; i < daysInMonth; i++) {
      let startDate = moment(
        moment(date).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      ).add(i, "days");

      let array = {
        date: moment(startDate).format("DD-MM-YYYY"),
        status: false,
      };
      datePayload.push(array);
    }
  };

  const addhabit = () => {  
    if (input.length < 1) {
      alert("not able to save empty habit");
    } else {
      setDatePayload([]);
      datePayloadData();
      const payload_state = {
        habit: input,
        userId: auth.uid,
        date: datePayload,
        created_at: moment().format("DD-MM-YYYY"),
      };
      const todayDateClone = {...todayDate}
      if(habits.length>0) {
        todayDateClone[habits.length]=parseInt(new Date().toJSON().slice(0, 10).split("-")[2])
      }
      setTodayDate(todayDateClone)
      setInput("");
      dispatch(saveHabits(payload_state));
    }
  };

  const changecolor = (mainIndex, index) => {
    // let newData = [...status];
    // newData[index] = !newData[index];
    // setStatus(newData);
    let habitsDataArray = [...habits];
    let currentHabitData = habitsDataArray[mainIndex];
    let { date } = currentHabitData;
    date[index].status = !date[index].status;

    currentHabitData.date = date;
    dispatch(updateHabits(currentHabitData));
  };

  const changecolorRead = (index) => {
    let newData = [...statusRead];
    newData[index] = !newData[index];
    setStatusRead(newData);
    setStatus(newData);
  };

  const deleteItem = (id) => {
    dispatch(deleteHabit(id));
  };

  const handelInput = (event) => {
    const { name, value } = event.target;
    if (value.length > 25) {
      dispatch(showErrorSnackbar("Limit is 25 characters !!!"));
      return;
    } else {
      setInput(value);
    }
  };

  //  select weekly, monthly, yearly
  const [time, setTime] = useState("");
  // console.log(time);
  const handleChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{
        borderRadius: "1rem",
        // backgroundColor: '#EDF0F2',
        padding: "1rem",
        marginTop: "1rem",
        display: "block",
      }}
    >
      <Grid>
        <Box>
          <form
            style={{
              marginTop: "-0.5rem",
              background: "rgba(255, 255, 255, 0.7)",
              marginBottom: "15px",
              borderRadius: "5px",
            }}
          >
            <TextField
              type="text"
              label="Add New Habit"
              variant="outlined"
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
              size="big"
              value={input}
              onChange={(e) => handelInput(e)}
              // onChange={(e) => setInput(e.target.value)}
              // onBlur={addGoal}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" onClick={addhabit}>
                    <AddCircleIcon
                      style={{ cursor: "pointer", color: "#103d7e" }}
                    />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  addhabit();
                }
              }}
            />
          </form>

          {/* Map itmes here */}
          {habits
            ? habits.map((item, mainIndex) => {
                let currentDate = new Date()
                  .toJSON()
                  .slice(0, 10)
                  .split("-")[2];

                return (
                  <ListItem
                    key={mainIndex}
                    sx={{
                      background: "rgba(255, 255, 255, 0.7)",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      padding: "0",
                    }}
                  >
                    <Grid
                      container
                      // spacing={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // border: "1px solid red",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        sx={{
                          width: "100%",
                          height: "100%",
                          py: "7px",
                          px: "8px",
                        }}
                        style={{
                          background: "rgba(255, 255, 255, 0.7)",
                          // opacity: "0.6",
                          // border: "1px solid red",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={11} sm={10} md={10}>
                            <ListItemText
                              style={{
                                // fontSize: "18px",
                                // marginTop: "1.8rem",
                                // width: '20vw',
                                // paddingLeft:"10px",
                                // color:"#000000",
                                // textColor:"#000000",

                                // position: "absolute",
                                left: "15px",
                                top: "5px",
                                // border: "1px solid blue",
                                /* or 182% */
                                color: "#000000",
                              }}
                              id={mainIndex}
                              primaryTypographyProps={{
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "18px",
                                lineHeight: "29px",
                                width: "84px",
                                // height: "30px",
                              }}
                              primary={item.habit}
                            />
                          </Grid>
                          {/* <Grid item xs={1} sm={2} md={2}>
                            <Box>
                              <CreateIcon
                                fontSize="small"
                                sx={{
                                  opacity: 0.7,
                                  fontSize: "16px",
                                  // position: "absolute",
                                  left: "13.5%",
                                  // right: "12.49%",
                                  top: "20%",
                                  // bottom: "12.5%",
                                }}
                              />
                            </Box>
                          </Grid> */}
                          <Grid item xs={4} sm={4} md={9}>
                            <Box
                              sx={{
                                // position: "absolute",
                                left: "15px",
                                bottom: "7px",
                              }}
                            >
                              <FormControl fullWidth size="small">
                                <NativeSelect
                                  disableUnderline
                                  sx={{
                                    height: "20px",
                                  }}
                                  defaultValue="weekly"
                                  inputProps={{
                                    name: "age",
                                    id: "uncontrolled-native",
                                  }}
                                >
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Yearly">Yearly</option>
                                </NativeSelect>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={11}
                        sm={11}
                        md={9}
                        style={
                          {
                            // border: "1px solid black",
                            // margin: "auto",
                            // backgroundColor: 'black'
                          }
                        }
                      >
                        <Stack width="100%">
                          <div className={classes.DownLeftMini}>
                            <ChevronLeftIcon
                              fontSize="large"
                              sx={{
                                fontSize: {
                                  xs: "30px",
                                  sm: "30px",
                                  md: "48px",
                                },
                              }}
                              style={{
                                opacity: 0.7,
                                // fontSize: "48px",
                                // margin: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if(todayDate[mainIndex] >= 7) {
                                  const todayDateClone = {...todayDate}
                                  if(todayDateClone[mainIndex]) {
                                    todayDateClone[mainIndex]-=1
                                  }
                                  setTodayDate(todayDateClone);
                                // setTodayDate((pre) => {
                                //   // console.log(pre);

                                //   return pre - 1;
                                // });
                                }
                              }}
                            />
                            {item.date.map((interest, index) => {
                              if (
                                todayDate[mainIndex] >= index + 1 &&
                                index + 1 >= todayDate[mainIndex] - 5
                              ) {
                                return (
                                  <Button
                                    className={classes.btnStyles}
                                    style={{
                                      background:
                                        parseInt(moment().date()) - 1 < index
                                          ? "gray"
                                          : interest.status
                                          ? "green"
                                          : color,
                                      color: interest.status
                                        ? "#fff"
                                        : textColor,
                                      // margin: "2px",
                                    }}
                                    disabled={
                                      parseInt(moment().date()) - 1 < index
                                    }
                                    onClick={() => {
                                      let dateStatus =
                                        parseInt(moment().date()) - 1 < index;

                                      return dateStatus
                                        ? null
                                        : changecolor(mainIndex, index);
                                    }}
                                  >
                                    {" "}
                                    {index + 1}
                                  </Button>
                                );
                              }
                            })}
                            <ChevronRightIcon
                                fontSize="large"
                                sx={{
                                  fontSize: {
                                    xs: "30px",
                                    sm: "30px",
                                    md: "48px",
                                  },
                                }}
                                style={{
                                  opacity: 0.7,
                                  // fontSize: "48px",
                                  // margin: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if(todayDate[mainIndex] !=daysInThisMonth) {
                                    const todayDateClone = {...todayDate}
                                    if(todayDateClone[mainIndex]) {
                                      todayDateClone[mainIndex]+=1
                                    }
                                    setTodayDate(todayDateClone);
                                  // setTodayDate((pre) => {
                                  //   // console.log(pre);

                                  //   return pre - 1;
                                  // });
                                  }
                                  // if(todayDate !== daysInThisMonth) {
                                  //   setTodayDate((pre) => {
                                  //   console.log(typeof pre);
                                  //   return pre + 1;
                                  // });
                                  // }
                                }}
                              />
                          </div>
                        </Stack>
                      </Grid>
                      {/* ChevronLeftIcon */}

                      <Grid
                        item
                        sx={{
                          textAlign: {
                            xs: "left",
                            sm: "left",
                            md: "right",
                          },
                        }}
                        xs={1}
                        sm={1}
                        md={1}
                        style={
                          {
                            // background: "rgba(255, 255, 255, 0.7)",
                            // borderTopRightRadius: "10px",
                            // borderBottomRightRadius: "10px",
                          }
                        }
                      >
                        <DeleteIcon
                          fontSize="small"
                          sx={{
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "24px",
                            },
                            marginRight: {
                              xs: "20px",
                              sm: "20px",
                              md: "20px",
                            },
                          }}
                          style={{
                            opacity: 0.7,

                            // margin: "10px",
                            // marginRight: "5px",
                            // marginTop: "180%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            deleteItem(item.id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })
            : ""}
        </Box>
      </Grid>
    </Stack>
  );
}

export default DiaryHabit;