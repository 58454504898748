import { actionTypes, triggerReducer } from '.';
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { firebase, db, auth } from "../../config/fbConfig"
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from 'moment'

export const addEvent = (payload) => (dispatch, getState) => {
   dispatch({
      type: actionTypes.ADD_USER_EVENT,
      addUserEventLoading: true,
      addUserEventStatus: true,
      addUserEventMessage: "",
  })
  db
   .collection("userEvents")
   .add(payload)
   .then(() => {
      dispatch(showSuccessSnackbar("New Event Added successfully!"));
      dispatch({
         type: actionTypes.ADD_USER_EVENT,
         addUserEventLoading: false,
         addUserEventStatus: true,
         addUserEventMessage: "New Event Added successfully!",
      })
      dispatch(fetchEvent())
   })
   .catch((error) => {
      dispatch({
         type: actionTypes.ADD_USER_EVENT,
         addUserEventLoading: false,
         addUserEventStatus: false,
         addUserEventMessage: error,
     })
   })
};
export const updateEvent = (payload) => (dispatch, getState) => {
   const {id, ...restPayload} = payload;
   dispatch({
      type: actionTypes.UPDATE_USER_EVENT,
      updateUserEventLoading: true,
      updateUserEventStatus: true,
      updateUserEventMessage: "",
  })
  db
   .collection("userEvents")
   .doc(id)
   .update(restPayload)
   .then(() => {
      dispatch(showSuccessSnackbar("Event Updated successfully!"));
      dispatch({
         type: actionTypes.UPDATE_USER_EVENT,
         updateUserEventLoading: false,
         updateUserEventStatus: true,
         updateUserEventLoadingMessage: "Event Updated successfully!",
      })
      dispatch(fetchEvent())
   })
   .catch((error) => {
      dispatch({
         type: actionTypes.UPDATE_USER_EVENT,
         updateUserEventLoading: false,
         updateUserEventStatus: false,
         updateUserEventMessage: error,
     })
   })
};
export const deleteEvent = (payload) => (dispatch, getState) => {
   const {id, ...restPayload} = payload;
   dispatch({
      type: actionTypes.DELETE_USER_EVENT,
      deleteUserEventLoading: true,
      deleteUserEventStatus: true,
      deleteUserEventMessage: "",
  })
  db
   .collection("userEvents")
   .doc(id)
   .delete()
   .then(() => {
      dispatch(showSuccessSnackbar("Event Deleted successfully!"));
      dispatch({
         type: actionTypes.DELETE_USER_EVENT,
         deleteUserEventLoading: false,
         deleteUserEventStatus: true,
         deleteUserEventLoadingMessage: "Event Deleted successfully!",
      })
      dispatch(fetchEvent())
   })
   .catch((error) => {
      dispatch({
         type: actionTypes.DELETE_USER_EVENT,
         deleteUserEventLoading: false,
         deleteUserEventStatus: false,
         deleteUserEventMessage: error,
     })
   })
};
export const fetchEvent = (payload) => (dispatch, getState) => {
   dispatch({
      type: actionTypes.LOADING_USER_EVENT,
      getUserEventLoading: true,
      getUserEventStatus: true,
      getUserEventMessage: "",
  })
  db
   .collection("userEvents")
   .where('userId', '==', auth.currentUser.uid)
   .orderBy("eventAt", "asc")
   .get()
   .then((querySnapshot) => {
      console.log("querySnapshot.empty", querySnapshot.empty)
      if(!querySnapshot.empty) {
         const data = []
         querySnapshot.forEach((documentSnapshot) => {
            data.push({
               ...documentSnapshot.data(),
               id: documentSnapshot.id,
            })
            // dispatch(showSuccessSnackbar("Event fetched successfully!"));
            dispatch({
               type: actionTypes.GET_USER_EVENT,
               getUserEventData: data,
               getUserEventPageInfo: {},
            })
            dispatch({
               type: actionTypes.LOADING_USER_EVENT,
               getUserEventLoading: false,
               getUserEventStatus: true,
               getUserEventLoadingMessage: "Event fetched successfully!",
            })
         })
      } else {
         // dispatch(showSuccessSnackbar("Event fetched successfully!"));
         dispatch({
            type: actionTypes.GET_USER_EVENT,
            getUserEventData: [],
            getUserEventPageInfo: {},
         })
         dispatch({
            type: actionTypes.LOADING_USER_EVENT,
            getUserEventLoading: false,
            getUserEventStatus: true,
            getUserEventLoadingMessage: "Event fetched successfully!",
         })
      }

   })
   .catch((error) => {
      console.log("fetchEvent.error", error)
      dispatch({
         type: actionTypes.LOADING_USER_EVENT,
         getUserEventLoading: false,
         getUserEventStatus: false,
         getUserEventMessage: error,
     })
   })
};
export const refreshEvent = (payload) => (dispatch, getState) => {
   dispatch({
      type: actionTypes.REFRESH_USER_EVENT,
      getUserEventRefreshing: true,
      getUserEventStatus: true,
      getUserEventMessage: "",
  })
  db
   .collection("userEvents")
   .where('userId', '==', auth.currentUser.uid)
   .get()
   .then((querySnapshot) => {
      if(!querySnapshot.empty) {
         const data = []
         querySnapshot.forEach((documentSnapshot) => {
            data.push({
               ...documentSnapshot.data(),
               id: documentSnapshot.id,
            })
            // dispatch(showSuccessSnackbar("Event fetched successfully!"));
            dispatch({
               type: actionTypes.GET_USER_EVENT,
               getUserEventData: data,
               getUserEventPageInfo: {},
            })
            dispatch({
               type: actionTypes.REFRESH_USER_EVENT,
               getUserEventRefreshing: false,
               getUserEventStatus: true,
               getUserEventLoadingMessage: "Event fetched successfully!",
            })
         })
      } else {
         dispatch(showSuccessSnackbar("Event fetched successfully!"));
         dispatch({
            type: actionTypes.REFRESH_USER_EVENT,
            getUserEventRefreshing: false,
            getUserEventStatus: true,
            getUserEventLoadingMessage: "Event fetched successfully!",
         })
      }

   })
   .catch((error) => {
      dispatch({
         type: actionTypes.LOADING_USER_EVENT,
         getUserEventLoading: false,
         getUserEventStatus: false,
         getUserEventMessage: error,
     })
   })
};