import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { makeStyles } from "@mui/styles";
import "./Blog.css";
import ReactHtmlParser from "react-html-parser";
import BlogViewAuthor from "./BlogViewAuthor";
import dayjs from "dayjs";
import { LayoutConfig } from "./Layout";
import CommentsMultiLevel from "../../Shared/CommentsMultiLevel";
import { showInfoSnackbar } from "../../../store/actions/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { convertKMBT } from "../../../helpers/Helpers";
import Post1 from "../../../assets/images/PostCardImages/Post1.png";
import eye from "../../../assets/images/Blog/BD/a;hi.png";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import share from "../../../assets/images/Blog/BD/agr.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";

//start
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";

import createImagePlugin from "@draft-js-plugins/image";
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

import { convertToHTML } from "draft-convert";
import draftToHtml from 'draftjs-to-html';

//end

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const plugins = [imagePlugin, resizeablePlugin, alignmentPlugin, blockDndPlugin, focusPlugin];

const useStyles = makeStyles((theme) => ({
  content: {
    display: `flex`,
    padding: theme.spacing(0),
  },
  mainContent: {
    flexGrow: 1,
    width: `100%`,
    overflowY: `scroll`,
    overflowX: `hidden`,
    paddingRight: theme.spacing(0),
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    marginLeft: theme.spacing(3),
  },
  adSection: {
    // width: '285px',
    // overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  blogAuthor: {
    width: `220px`,
    textAlign: `center`,
    marginTop: 100,
    paddingTop: theme.spacing(10),
    overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  imageGrid: {
    flexGrow: 1,
    overflow: `hidden`,
  },
  imageGridItem: {
    padding: 0,
  },
  image: {
    height: `auto`,
    objectFit: `cover`,
    maxWidth: `100%`,
  },
  tags: {
    display: `flex`,
    color: theme.palette.secondary.alt,
    justifyContent: `flex-end`,
    fontStyle: `italic`,
  },
  actionIcons: {
    display: `flex`,
    justifyContent: `flex-end`,
  },
  viewIcon: {
    color: `lightgreen`,
  },
  favIcon: {
    color: `red`,
  },
  shareIcon: {
    color: `green`,
  },
  commentIcon: {
    color: theme.palette.primary.main,
  },
  likeIcon: {
    color: theme.palette.primary.main,
  },
  blogIcon: {
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
    marginRight: 5,
  },
}));

const BlogViewer = ({ post, isPreview = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const classes = useStyles();
  const [isCommentOpen, setCommentOpen] = useState(false);
  const [likedButtonStatus, setLikedButtonStatus] = useState(false);
  const [shareButtonStatus, setShareButtonStatus] = useState(false);
  const [commentButtonStatus, setCommentButtonStatus] = useState(false);

  //
  useEffect(() => {
    if (!isPreview && auth && auth.uid) {
      let viewscount = post.views ? post.views + 1 : 1;
      firestore.collection("posts").doc(post.id).update({
        views: viewscount,
      });
    }
  }, []);

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  const handleCommentOpen = () => {
    setCommentOpen(true);
  };
  useEffect(() => {
    if (post?.likedby?.indexOf(auth.uid) > -1) setLikedButtonStatus(true);
    setShareButtonStatus(true);
    setCommentButtonStatus(true);
  }, [post]);
  const handleLikedClick = () => {
    let likedArray = post?.likedby.length > 0 ? [...post.likedby] : [];
    if (post?.likedby?.indexOf(auth.uid) === -1) {
      likedArray.push(auth.uid);
      firestore.collection("posts").doc(post.id).update({
        likedby: likedArray,
      });
      setLikedButtonStatus(true);
    } else {
      dispatch(showInfoSnackbar("You had already liked this post!"));
    }
  };

  const handleLovedClick = () => {
    let lovedArray = post?.lovedby.length > 0 ? [...post.lovedby] : [];
    if (post?.lovedby?.indexOf(auth.uid) === -1) {
      lovedArray.push(auth.uid);

      firestore.collection("posts").doc(post.id).update({
        lovedby: lovedArray,
      });
    } else {
      dispatch(showInfoSnackbar("You had already loved this post!"));
    }
  };

  const handleSharedClick = () => {
    let sharecount = post.shared ? post.shared + 1 : 1;
    const el = document.createElement("input");
    el.value = process.env.REACT_APP_DOMAIN_URL + window.location.pathname;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    firestore.collection("posts").doc(post.id).update({
      shared: sharecount,
    });
    dispatch(showInfoSnackbar("This post is copied to share!"));
  };

  if (!post) {
    return ``;
  }

  const layout =
    LayoutConfig.find((itm) => itm.id === post.layout) || LayoutConfig[0];

  const showComments = () => (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleCommentOpen}>
        Comment
      </Button>
      <Dialog open={isCommentOpen}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <TextField
            // autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommentClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCommentClose} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  const showActions = () => (
    <React.Fragment>
      <Box className={classes.blogIcon}>
        <IconButton style={{ background: "lightgreen" }}>
          <img src={eye} alt="" style={{ width: "16px", height: "16px" }} />
          {/* <VisibilityIcon style={{ color: 'lightgreen' }}>visibility</VisibilityIcon> */}
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.views ? post.views : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLikedClick}>
        <IconButton
          style={{ background: likedButtonStatus ? "blue" : "#b8b3b3" }}
        >
          <img
            src={hands}
            alt=""
            style={{ width: "16px", height: "16px", textAlign: "center" }}
          />
          {/* <ThumbUpOffAltIcon style={{ color: 'theme.palette.primary.main' }}>thumb_up_off_alt</ThumbUpOffAltIcon> */}
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.likedby ? post.likedby.length : 0)}
        </Typography>
      </Box>
      {/* <Box className={classes.blogIcon} onClick={handleLovedClick}>
        <IconButton className={classes.favIcon}>
          <FavoriteIcon style={{ color: 'red' }}>favorite</FavoriteIcon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.lovedby ? post.lovedby.length : "[]")}
        </Typography>
      </Box> */}
      <Box className={classes.blogIcon} onClick={handleSharedClick}>
        <IconButton
          style={{ background: shareButtonStatus ? "green" : "#b8b3b3" }}
        >
          <img src={share} alt="" style={{ width: "16px", height: "16px" }} />
          {/* <ShareIcon style={{ color: 'green' }}>share</ShareIcon> */}
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.shared ? post.shared : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon}>
        <IconButton
          style={{ background: commentButtonStatus ? "blue" : "#b8b3b3" }}
        >
          <img src={comment} alt="" style={{ width: "16px", height: "16px" }} />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.commentscount ? post.commentscount : 0)}
        </Typography>
      </Box>
    </React.Fragment>
  );

  const getPostHeader = () => {
    return (
      <React.Fragment>
        <Stack>
          <Typography variant="h4">{post.title}</Typography>
          {post.blogId && (
            <Typography variant="subtitle2">{post.blogId.label}</Typography>
          )}

          {post.lastUpdate && (
            <Typography variant="subtitle" component="i">
              {post.lastUpdate
                ? moment
                  .unix(post.lastUpdate.seconds)
                  .format("MMMM D YYYY, h:mm A")
                : null}
              {/* {dayjs(new Date(post.lastUpdate)).format("MMMM D YYYY, h:mm A")} */}
            </Typography>
          )}
          <Typography variant="subtitle" sx={{ marginRight: "auto" }}>
            Categories: {post.category ? post.category.label : 'Undefined'}
          </Typography>
        </Stack>
      </React.Fragment>
    );
  };

  const getPostTags = () => {
    return (
      <React.Fragment>
        {post.tags &&
          post.tags.map((tag) => (
            <React.Fragment style={{ marginRight: 5 }}>
              <Typography color="textSecondary">#</Typography>
              <Typography color="textSecondary">
                {tag && tag.label ? tag.label : tag}&nbsp;
              </Typography>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  const getPostImages = () => {
    let tempArr = [];
    const newArray = tempArr.push(post.images);
    //

    return (
      <React.Fragment>
        <Grid container className={classes.imageGrid} spacing={0}>
          {tempArr &&
            tempArr?.map((image) => (
              <Grid item xs className={classes.imageGridItem}>
                <img src={image} className={classes.image} />
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  const convertPostDataToHtml = () => {
    // const state = convertFromRaw(JSON.parse(post.content))
    // const state = JSON.parse(post.content)
    // let editorStateData = EditorState.createWithContent(state);
    // let dataStatus = editorStateData.getCurrentContent().getPlainText();
    // let htmlData = draftToHtml(state);
    // console.log("dataStatus : ", dataStatus)
    // return htmlData

    const state = convertFromRaw(JSON.parse(post.content))
    let editorStateData = EditorState.createWithContent(state);
    return <Editor
      editorState={editorStateData}
      plugins={plugins}
      readOnly={true}
    />
  }

  const postView = () => (
    <React.Fragment>
      <React.Fragment>
        <Box
          p={2}
          mt={0}
          bgcolor={layout.titleBg}
          color={layout.titleColor}
          textAlign="center"
        >
          {getPostHeader()}
        </Box>
        {
          post && post.content && post.content.length > 10 ?
            null
            :
            <Box mt={0}>{getPostImages()}</Box>
        }

      </React.Fragment>

      <Box mt={3} textAlign="right" className={classes.tags}>
        {getPostTags()}
      </Box>

      <Box mt={3} p={2}>
        <Typography variant="body2">
          {
            post && post.content ?
              convertPostDataToHtml()
              :
              post && post.body ? ReactHtmlParser(post.body) : ""
          }
        </Typography>
      </Box>

      {!isPreview && (
        <Box
          Box
          mt={2}
          mb={5}
          textAlign="right"
          className={classes.actionIcons}
        >
          {showActions()}
        </Box>
      )}

      {!isPreview && post?.commentable && (
        <Box mt={3} mb={3}>
          <CommentsMultiLevel post={post} />
        </Box>
      ) || null}
    </React.Fragment>
  );

  return (
    <Grid
      container
      className={classes.content}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Grid item container xs={12} md={1}>
        <Button
          style={{
            marginTop: "0.3rem",
            border: "1px solid gray",
            background: "blue",
            color: "#fff",
            height: "35px",
            width: "100px",
          }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Grid>
      <Grid item container xs={12} md={11} spacing={1}>
        {layout.author && (
          <Grid
            item
            xs={12}
            md={2}
            className={classes.blogAuthor}
            bgcolor={layout.authorBg}
            color={layout.authorColor}
          >
            <BlogViewAuthor post={post} isPreview={isPreview} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={8}
          className={classes.mainContent}
          pt={!isPreview ? 2 : 0}
        >
          {postView()}
        </Grid>
        {!isPreview && (
          <Grid item xs={12} md={2} className={classes.adSection}>
            <div style={{ width: "100%", overflow: "hidden" }}>
              <img alt="AD200x720" src={Post1} />
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BlogViewer;
