import React, { useState, useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  Grid,
  Box,
  Dialog,
  Paper,
  Button,
  DialogContent,
  DialogTitle,
  InputBase,
  IconButton,
  FormControl,
  NativeSelect,
  TextField,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Masonry } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import BlockStyleToolbar, { getBlockStyle } from "./BlockStyleToolbar";
import "./PoemEditorCSS.css";
import Typography from "@mui/material/Typography";
import { GoFileMedia } from "react-icons/go";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import PoemHeader from "./PoemHeader";
import { saveUserPoem, setOpenedPoem, getUserPoems } from "../../store/actions/poems";
import { showErrorSnackbar } from "../../store/actions/snackbar";
import { storage } from '../../config/fbConfig';
import { useFilePicker } from "use-file-picker";
import { ImCross } from "react-icons/im";
import SearchIcon from "@mui/icons-material/Search";
import PoemEditorStyles from "./PoemEditorStyles";
import {
  getOpenedUsersImages,
  getOpenedGallery, getPremiumImages, getStickers
} from "../../store/actions/images";

import '@draft-js-plugins/alignment/lib/plugin.css';
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import createImagePlugin from "@draft-js-plugins/image";
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import { convertToHTML } from "draft-convert";
import mockUpload from '../Blog/mockUpload';
import { getSubscription, getUserSubscription, addUserSubscription } from "../../store/actions/subscriptions";
import { CgMenuGridR } from "react-icons/cg";
import EditIcon from '@mui/icons-material/Edit';
import Navbar from "../Navbar";
import Main from "../Main";


const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: mockUpload,
  addImage: imagePlugin.addImage,
});

const plugins = [imagePlugin, resizeablePlugin, alignmentPlugin, blockDndPlugin, focusPlugin];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#fff',
    },
  },
}));

const PoemEditor = (props) => {
  const classes = PoemEditorStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const { openedPoem, draftPoemEdit } = useSelector((state) => state.poems);
  const { gallery, usersImages, premiumImages, stickers } = useSelector(state => state.images);
  const [images, SetImages] = useState([]);
  const [gotImages, updateGotImages] = useState(0);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorStateAsHTML, setEditorStateAsHTML] = useState("");
  const [hasTextStatus, setHasTextStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [openElements, setOpenElements] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const [category, setCategory] = useState(10);
  const [categoryId, setCategoryId] = useState("");
  const { isPremiumUser, userStatusUpdated } = useSelector((state) => state.subscriptions);
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState(false)
  const [postImages, setPostImages] = useState([])
  const [openAddImageDialog, setOpenAddImageDialog] = useState(false)
  const [change, setChange] = useState(false)

  const [openFileSelector, { filesContent, plainFiles, clear }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL",
      accept: "image/*",
    });

  useFirestoreConnect([{ collection: "poem_categories" }]);

  const poem_categories = useSelector((state) => state.firestore.ordered.poem_categories);

  useEffect(() => {
    dispatch(setOpenedPoem({ ...openedPoem, title }));
  }, [title])

  const handleChange = (event) => {
    let id = event.target.value
    setCategoryId(id)
    let category = poem_categories[event.target.selectedIndex - 1]?.title || ''
    setCategory(category)
    dispatch(setOpenedPoem({ ...openedPoem, category }));
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setOpenElements(false);
  };

  useEffect(() => {
    if (!userStatusUpdated)
      dispatch(getUserSubscription())

    try {
      let uid = auth?.uid;
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages));
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages));
      dispatch(getStickers())
      dispatch(getPremiumImages())
    } catch (e) {
      console.log("Error : ", e);
    }
  }, []);

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
    let dataStatus = editorState.getCurrentContent();
    let htmlData = convertToHTML(dataStatus);
    setEditorStateAsHTML(htmlData);
    let status = dataStatus.hasText();
    setHasTextStatus(status);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = JSON.stringify(rawContentState)
    dispatch(setOpenedPoem({ ...openedPoem, body: markup, content: htmlData }));
    clear()
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  useEffect(() => {
    var imagesArray = [];
    if (usersImages.length > 0) {
      imagesArray = imagesArray.concat(usersImages);
    }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery);
    }
    SetImages(imagesArray);
  }, [gotImages, usersImages, props.images]);

  const handleClose = () => {
    setOpen(false);
  };

  const searchContent = (e) => {
    if (e && e.target.value && e.target.value.length > 0) {
      let imagesArray = images.filter((img) =>
        img.toLowerCase().includes(e.target.value.toLowerCase())
      );
      SetImages(imagesArray);
    } else {
      let imagesArray = [];
      if (usersImages.length > 0) {
        imagesArray = imagesArray.concat(usersImages);
      }
      if (gallery.length > 0) {
        imagesArray = imagesArray.concat(gallery);
      }
      SetImages(imagesArray);
    }
  };

  const handleMediaClick = (url) => {
    setMediaUrl(url);
    setEditorState(insertImage(url));
    setOpen(false);
  };

  /////uploadImages model open
  const handleAddImages = () => {
    setOpenAddImageDialog(true);
  };

  /////Template model open
  const handleClickOpenTemplate = (scrollType) => () => {
    setOpenTemplate(true);
    setScroll(scrollType);
  };

  /////Elements model open
  const handleClickOpenElements = (scrollType) => () => {
    setOpenElements(true);
    setScroll(scrollType);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onUnderlineClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onBoldClick = (event) => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const savePoem = () => {
    if (hasTextStatus) dispatch(saveUserPoem());
    else {
      dispatch(showErrorSnackbar("Please, enter text for your poem"));
    }
  };

  const publishPoem = () => {
    if (hasTextStatus) {
      const { category } = openedPoem;
      if (
        category === "" ||
        category === null ||
        category === undefined
      ) {
        dispatch(showErrorSnackbar("Please, select category"));
        // handleOpenModal();
      } else if (
        title === "" ||
        title === null ||
        title === undefined
      ) {
        dispatch(showErrorSnackbar("Please, enter title"));
      }
      else {

        if (!openedPoem.hasOwnProperty('images'))
          dispatch(setOpenedPoem({ ...openedPoem, images: postImages }));

        dispatch(saveUserPoem(true));
        dispatch(getUserPoems());
        dispatch(getUserPoems(true));
        props.history.push("/poem");
      }
    } else {
      dispatch(showErrorSnackbar("Please, enter text for your blog"));
    }
  };

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      let image = plainFiles[0];
      let userId = auth.uid;
      storage
        .ref(`/users/${userId}/${image.name}`)
        .put(image)
        .on("state_changed", console.log, console.error, () => {
          storage
            .ref(`/users/${userId}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async (url) => {
              let imagesArray = openedPoem && Array.isArray(openedPoem.images) ? openedPoem.images : []
              imagesArray.push(url)
              setPostImages(imagesArray)
              dispatch(setOpenedPoem({ ...openedPoem, images }));
              setEditorState(insertImage(url));
            });
        });
    }

  }, [plainFiles, plainFiles.length]);

  useEffect(() => {
    if (mediaUrl) {
      let imagesArray = openedPoem && Array.isArray(openedPoem.images) ? openedPoem.images : []
      imagesArray.push(mediaUrl)
      dispatch(setOpenedPoem({ ...openedPoem, images: imagesArray }));
    }
  }, [mediaUrl])

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  useEffect(() => {
    if (poem_categories && poem_categories.length > 0 && draftPoemEdit && !change) {
      const { category } = openedPoem;
      if (category) {
        let index = poem_categories && poem_categories.findIndex((cat) => cat.title === category)
        if (index > -1) {
          let currentCategory = poem_categories[index]
          const { title, id } = currentCategory
          setCategoryId(id)
          setCategory(title)
        }
      }
    }
  }, [poem_categories])

  useEffect(() => {
    if (draftPoemEdit) {
      const { image, content, body, title, category } = openedPoem;
      let editorStateData = EditorState.createEmpty()
      setTitle(title)

      if (category) {
        let index = poem_categories && poem_categories.findIndex((cat) => cat.title === category)
        if (index > -1) {
          let currentCategory = poem_categories[index]
          const { title, id } = currentCategory
          setCategoryId(id)
          setCategory(title)
        }
      }

      if (body) {
        try {
          const state = convertFromRaw(JSON.parse(body))
          editorStateData = EditorState.createWithContent(state);
          onChange(editorStateData)
        } catch (e) {
          console.log("Error --- ", e)
        }
      } else {
        if (content) {
          const blocksFromHTML = htmlToDraft(content);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          editorStateData = EditorState.createWithContent(state);
          setEditorState(editorStateData);
        }

        if (image) {
          const contentState = editorStateData.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "IMMUTABLE",
            { src: image }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorStateData, {
            currentContent: contentStateWithEntity,
          });
          editorStateData = AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            " "
          );
          setEditorState(editorStateData);
        }
        let dataStatus = editorStateData.getCurrentContent();
        let htmlData = convertToHTML(dataStatus);
        setEditorStateAsHTML(htmlData);
        let status = dataStatus.hasText();
        setHasTextStatus(status);
      }

    }
  }, [draftPoemEdit]);

  const handleCloseElements = () => {
    setOpenElements(false);
    setOpenTemplate(false);
  };

  const renderBuyPremiumModal = () => {
    return (
      <Dialog style={{ padding: "10px" }}
        open={openElements || openTemplate}
        onClose={handleCloseElements}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"To unclock these images buy premium"}
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions style={{ paddingBottom: '20px' }}>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements}>Buy</Button>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const onChangeName = (e) => {
    setTitle(e.target.value)
    dispatch(setOpenedPoem({ ...openedPoem, title: e.target.value }));
  }

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid item xs={12} className="pageContainer" style={{ backgroundColor: "#f2f2f2" }}>
          <PoemHeader
            style={{ margin: "auto", display: "flex" }}
            savePoem={savePoem}
            publishPoem={publishPoem}
          />

          <Grid item xs={6} sm={6} display={{ xs: "block", sm: "block", md: "none", lg: "none" }} className={classes.textStyle} >
            <Button style={{ color: "#fff", background: '#1976d2', padding: '0.6rem', width: "60vw", borderRadius: '6px', marginTop: '5rem', marginBottom: "-8rem", marginLeft: '5rem' }}
              onClick={handleAddImages}
            >Add Images</Button>

            <Dialog style={{ margin: '0px' }}
              open={openAddImageDialog}
              onClose={() => setOpenAddImageDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', color: '#033463', fontSize: '30px' }}>
                {"Add Images"}
              </DialogTitle>
              <DialogContent>
                <Grid item sm={12} md={12} style={{ margin: "auto", display: "flex", }}>
                  <Grid item sm={12} md={12} xs={12} style={{ display: 'flex', width: "100vw", height: "15vh" }}>
                    <Grid item sm={3} md={12} xs={4} style={{ marginRight: '0.7rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <GoFileMedia
                          onClick={handleClickOpen("paper")}
                          style={{ color: "#fff" }}
                          className={classes.fileicons}
                        />
                      </Box>
                      <Typography className={classes.iconname}>Media</Typography>
                    </Grid>
                    <Grid item sm={3} md={12} xs={4}>
                      <Box className={classes.boxiconBlog}>
                        <FiUpload
                          className={classes.fileicons}
                          onClick={openFileSelector}
                        />
                      </Box>
                      <Typography className={classes.iconname}>Upload</Typography>
                    </Grid>
                    <Grid item sm={3} md={12} xs={4} style={{ marginLeft: '0.7rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <WorkspacePremiumIcon style={{ display: "flex", justifyContent: "center", height: "45px" }}
                          onClick={handleClickOpenElements("paper")}
                          className={classes.fileicons} />
                      </Box>
                      <Typography className={classes.iconname}>Premium <br />Images</Typography>
                    </Grid>
                    <Grid item sm={4} md={12} xs={4} style={{ marginLeft: '0.7rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <CgMenuGridR
                          onClick={handleClickOpenTemplate("paper")}
                          className={classes.fileicons} />
                      </Box>
                      <Typography className={classes.iconname}>Premium <br />Stickers</Typography>
                    </Grid>

                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button style={{ background: "#1976d2", color: "#fff", width: '44vw', margin: 'auto' }}
                  onClick={() => setOpenAddImageDialog(false)}
                  autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>

          <Grid container spacing={2} className="editorContainer">
            <Grid item xs={12} sm={12} md={1} className="toolbars">
              <Box className={classes.toolbarStyling}>
                <Box>
                  <BlockStyleToolbar
                    editorState={editorState}
                    onToggle={toggleBlockType}
                  />
                  <Box className={classes.underLineStyle}>
                    <button className="styleButton" onClick={onUnderlineClick}>
                      U
                    </button>
                    <button className="styleButton" onClick={onBoldClick}>
                      <b>B</b>
                    </button>
                    <button className="styleButton" onClick={onItalicClick}>
                      <em>I</em>
                    </button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={9} sm={12} xs={12} className={classes.poemBoxStyl}>
              <Grid item xs={12} style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '10px 0px' }}>
                <Grid item xs={6}>
                  {
                    status ?
                      <TextField id='title' variant="standard" value={title}
                        onChange={(e) => onChangeName(e)}
                        sx={{
                          borderColor: '#000',
                          margin: 'auto',
                          marginLeft: '10px',
                          fontSize: 40,
                          marginTop: '20px',
                          fontWeight: 'bold'
                        }}
                        style={{ fontSize: '40px', fontWeight: 'bold' }}
                        onBlur={() => setStatus(false)}
                        autoFocus={true}
                      />
                      :
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="h4"
                          style={{ fontFamily: "Museo700", padding: '10px 20px' }}
                        >
                          {title ? title : 'Poem Title Here'}
                        </Typography>
                        <EditIcon style={{ margin: 'auto', marginLeft: '5px' }} onClick={() => setStatus(true)} />
                      </div>
                  }



                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ m: 1 }} variant="standard" style={{ float: 'right', marginRight: '20px', width: '70%' }}>
                    {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={categoryId}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      placeholder="Select or Type category"
                      style={{ width: '100%' }}
                    >
                      <option aria-label="None" value="" />
                      {
                        poem_categories && poem_categories.length > 0 ?
                          poem_categories.map((cat) => <option value={cat.id}>{cat.title}</option>) : null
                      }

                      {/* <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option> */}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className="editors"
                style={{
                  marginTop: '1rem',
                  overflow: "auto",
                }}
              >
                <Editor
                  blockStyleFn={getBlockStyle}
                  editorState={editorState}
                  handleKeyCommand={handleKeyCommand}
                  onChange={onChange}
                  plugins={plugins}
                />
                <AlignmentTool />
              </Grid>
            </Grid>

            <Grid item xs={2} md={2} sm={12} className={classes.iconsBoxed}>
              <Grid
                item
                sm={12}
                md={2}
                xs={12}
                style={{
                  marginTop: '6rem',
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <Box className="boxicon">
                  <GoFileMedia
                    style={{ color: "#fff" }}
                    className="fileicons"
                    onClick={handleClickOpen("paper")}
                  />
                </Box>
                <Typography className="iconname">Media</Typography>
                <Box className="boxicon">
                  <FiUpload
                    className="fileicons"
                    onClick={openFileSelector}
                    modifier={imagePlugin.addImage}
                  />
                </Box>
                <Typography className="iconname">Upload</Typography>


                <Box className="boxicon">
                  <WorkspacePremiumIcon
                    style={{ display: "flex", justifyContent: "center", height: "45px" }}
                    className="fileicons"
                    onClick={handleClickOpenElements("paper")}
                  />
                </Box>
                <Typography className="iconname">Premium<br /> Images</Typography>


                <Box className="boxicon">
                  <CgMenuGridR
                    style={{ color: "#fff" }}
                    className="fileicons"
                    onClick={handleClickOpenTemplate("paper")}
                  />
                </Box>
                <Typography className="iconname">Premium<br /> Stickers</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ borderRadius: "2rem !important" }}
          >
            <Dialog
              className={classes.dailogstyle}
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    onChange={searchContent}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Photos
                  </Typography>
                  {/* <Typography>See all</Typography> */}
                </Box>
                <Box sx={{ width: '100%', minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                    {images && images.length > 0 ? images.map((img, index) =>
                      <div key={index}>
                        {/* <Label>{index + 1}</Label> */}
                        <img
                          src={img}
                          // src={`${img}?w=162&auto=format`}
                          // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                          alt='demo'
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={() => handleMediaClick(img)}
                        />

                      </div>
                    ) : null
                    }
                  </Masonry>
                </Box>

              </DialogContent>
            </Dialog>
          </Grid>
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {
              isPremiumUser ?
                <Dialog
                  className={classes.dailogstyle}
                  open={openTemplate}
                  onClose={handleCloseTemplate}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  borderRadius="2rem"
                  sx={{
                    // width: "70%",
                    display: "flex",
                    justifyContent: "right",
                    paddingRight: "6rem",
                    // p: "2px 4px",
                  }}
                >
                  <ImCross
                    onClick={handleCloseTemplate}
                    style={{
                      position: "absolute",
                      right: 0,
                      margin: "10px",
                      color: "gray",
                    }}
                  />

                  <DialogTitle id="scroll-dialog-title">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "2rem",
                      }}
                    >
                      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by template"
                        inputProps={{ "aria-label": "Search by type" }}
                        // onChange={(e) => }
                        onKeyPress={(ev) => {

                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                          }
                        }}
                      />

                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    </Paper>
                  </DialogTitle>

                  <DialogContent dividers={scroll === "div"}>
                    <Grid spacing={2} xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        // textAlign="start"
                        component="div"
                        fontFamily="Museo700"
                        color="#1E3A71"
                      >
                        Sticker Images
                      </Typography>
                      <Box sx={{ width: '100%', minHeight: 829 }}>
                        <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                          {stickers && stickers.length > 0 ? stickers.map((img, index) =>
                            <div key={index}>
                              {/* <Label>{index + 1}</Label> */}
                              <img
                                src={img}
                                // src={`${img}?w=162&auto=format`}
                                // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                                alt='demo'
                                loading="lazy"
                                style={{
                                  borderBottomLeftRadius: 4,
                                  borderBottomRightRadius: 4,
                                  display: 'block',
                                  width: '100%',
                                }}
                                onClick={() => handleMediaClick(img)}
                              />

                            </div>
                          ) : null
                          }
                        </Masonry>
                      </Box>
                      {/* <Grid container spacing={3}>

                    {
                      stickers && stickers.length > 0 ? stickers.map((img, index) =>
                        <Grid item sm={4} sx={12}>
                          <img style={{ width: "100%", height: '100%', cursor: "pointer" }}
                            key={index}
                            component="img"
                            src={img}
                            alt="green iguana"
                            onClick={() => handleMediaClick(img)}
                          />
                        </Grid>
                      ) : null
                    }
                  </Grid> */}
                    </Grid>
                  </DialogContent>
                </Dialog>
                :
                renderBuyPremiumModal()
            }

          </Grid>
          {/* Elements dialog */}
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {
              isPremiumUser ?
                <Dialog
                  className={classes.dailogstyle}
                  open={openElements}
                  onClose={handleCloseElements}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  borderRadius="2rem"
                  sx={{
                    // width: "70%",
                    display: "flex",
                    justifyContent: "right",
                    paddingRight: "6rem",
                    // p: "2px 4px",
                  }}
                >
                  <ImCross
                    onClick={handleCloseElements}
                    style={{
                      position: "absolute",
                      right: 0,
                      margin: "10px",
                      color: "gray",
                    }}
                  />

                  <DialogTitle id="scroll-dialog-title">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "2rem",
                      }}
                    >
                      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by type"
                        inputProps={{ "aria-label": "Search by type" }}

                        onKeyPress={(ev) => {

                          if (ev.key === 'Enter') {
                            // Do code here
                            ev.preventDefault();
                          }
                        }}
                      />

                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    </Paper>
                  </DialogTitle>

                  <DialogContent dividers={scroll === "div"}>
                    <Grid spacing={3} xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        // textAlign="start"
                        component="div"
                        fontFamily="Museo700"
                        color="#1E3A71"
                      >
                        Premium Images
                      </Typography>
                      <Box sx={{ width: '100%', minHeight: 829 }}>
                        <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                          {premiumImages && premiumImages.length > 0 ?
                            premiumImages.map((image, index) =>
                              <div key={index}>
                                {/* <Label>{index + 1}</Label> */}
                                <img
                                  src={image}
                                  // src={`${img}?w=162&auto=format`}
                                  // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                                  alt='demo'
                                  loading="lazy"
                                  style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                  }}
                                  onClick={() => handleMediaClick(image)}

                                />

                              </div>
                            ) : null
                          }
                        </Masonry>
                      </Box>
                    </Grid>
                  </DialogContent>
                </Dialog> :
                renderBuyPremiumModal()
            }

          </Grid>

        </Grid>
      </Grid>
    </Main>
  );
};

export default PoemEditor;
