import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
const interestsListArray = [
  "Science",
  "Technology",
  "Pets",
  "Art",
  "Craft",
  "Psychology",
  "Travel",
  "Books",
  "Pop",
  "Painting",
  "Sports",
  "Fiction",
  "IT",
  "Dance",
  "Poems",
  "Comics",
  "Scaling",
  "Animals",
];

function LibraryButton(props) {
  const [interests, setPreferences] = useState([]);
  const { searchQuery } = useSelector(state => state.library)

  const handleInterests = (interest) => {
    let interestsList = [...interests];
    let index = interestsList.findIndex((i) => i === interest);

    if (index > -1) {
      interestsList.splice(index, 1);
    } else {
      interestsList.push(interest);
    }
    props.setTopics(interestsList)
    setPreferences(interestsList);
    props.filterByTopics(interestsList)
  };

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      setPreferences([]);
    }
  }, [searchQuery])
  return (
    <>
      <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12}
        style={{
          margin: 'auto', width: '80vw', maxHeight: '40vh',
          paddingBottom: '0.8rem',
          overflowX: 'auto',
        }}
      >
        {props.categories.map((interest, index) => (
          <Button
            key={index}
            style={{
              // marginRight: "10px",
              // marginBottom: "10px",
              borderRadius: "50px",
              borderColor: "gray",
              color: interests.includes(interest.title) ? "#fff" : "#000",
              margin: 3,
              //   padding: 4,
              fontSize: "10px",
              // marginBottom: 10,
            }}
            variant={interests.includes(interest.title) ? "contained" : "outlined"}
            size="small"
            onClick={() => handleInterests(interest.title)}
          >
            {interest.title}
          </Button>
        ))}
      </Grid>
    </>
  );
}

export default LibraryButton;
