import { makeStyles } from "@mui/styles";

const PrivacyPolicyStyles = makeStyles((theme) => ({

  bg: {
    width: '100%',
    background: '#1171FF',
    marginTop: '4rem',
    padding: '7rem',
    minHeight: '100vh',
    paddingTop: '54px',
    paddingBottom: '50px',
    backgroundPosition: 'left top',
  },

  root: {
    textAlign: 'left',
    marginTop: "1px",
    width: '90vw'
  },

}));

export default PrivacyPolicyStyles;


