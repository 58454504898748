import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'


export const getCounts = () => async (dispatch, getState) => {
   const { auth } = getState().firebase;

   const { tenantId } = getState().user;
   console.log("tenantId -- ", tenantId)
   await db.collection("users")
      .where('tenantId', '==', tenantId)
      .get()
      .then(async (posts) => {
         console.log("posts --- ", posts, posts.size)
         let totalCount = posts.size
         let studentsCount = 0
         let employeesCount = 0
         if (totalCount > 0) {
            let postsData = []
            posts.docs.forEach(async (item) => {
               let post = item.data()
               post = {
                  ...post,
                  id: item.id
               }
               postsData.push(post)
            })
            for (let i = 0; i < postsData.length; i++) {
               let post = postsData[i]
               if (post.isStudent)
                  studentsCount++
               else
                  employeesCount++
            }
         }

         await db.collection("posts")
            .where('isPublished', '==', true)
            .where('tenantId', '==', tenantId)
            .get()
            .then(async (posts) => {
               console.log("Posts data size --- --", posts.size)
               if (posts.size)
                  dispatch(
                     triggerReducer(actionTypes.SET_BLOGS_COUNT, {
                        payload: posts.size
                     })
                  )
            })
            .catch((err) => {
               console.log("Error getBlogOfTheWeek", err)
            })

         dispatch(
            triggerReducer(actionTypes.SET_STUDENTS_COUNT, {
               payload: studentsCount
            })
         );
         dispatch(
            triggerReducer(actionTypes.SET_EMPLOYEES_COUNT, {
               payload: employeesCount
            })
         );
      })
      .catch((err) => {
         console.log("error --- ", err)
      })
};

