import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, InputBase, InputLabel, FormControl, Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { logout, setInstituteInfo } from "../../store/actions/user";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        color: '#000'
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '15px 12px',
        color: '#000',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
    formField: {
        width: '100%'
    }
}));

const PersonalLogin = (props) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [password, SetPassword] = useState('')


    // useEffect(() => {
    //     dispatch(logout())
    // }, [])

    const handleOnClickNext = () => {
        // let data = {
        //     email, password
        // }
        // dispatch(setInstituteInfo(data))
        // props.setPageCount(2)
    }

    return (
        <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', maxHeight: '70%' }}>
            <Grid item sm={12} md={6} lg={4}>
                <Grid item sm={12} md={12} component={Paper} style={{ borderRadius: '10%', padding: 100, paddingTop: 70, paddingBottom: 50, textAlign: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Welcome to Quillatwill
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Personal Login
                    </Typography>
                    <br /><br />
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                            Username*
                        </InputLabel>
                        <BootstrapInput value={email} id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>

                    <br /><br />
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                            Password*
                        </InputLabel>
                        <BootstrapInput id="password" value={password} type="password" onChange={(e) => SetPassword(e.target.value)} />
                    </FormControl>

                    <br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', margin: 'auto' }}>
                            <Typography
                                variant="p"
                                style={{ fontFamily: 'Museo300', color: 'gray' }}
                            >
                                Not Sure?
                                <span
                                    style={{
                                        fontFamily: 'Museo700',
                                        color: '#1E3A71',
                                        marginLeft: 10,
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => props.setInstituteLogin(true)}
                                >
                                    Institute Login
                                </span>
                            </Typography>

                        </Grid>

                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#1E3A71',
                                fontFamily: 'Museo700',
                                color: '#fff',
                                width: '60%',
                                padding: 15
                            }}
                                size="large"
                                onClick={handleOnClickNext}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default PersonalLogin;
