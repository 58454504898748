import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Ellipse from "../../assets/images/Ellipse 18.png";
import PostCardStyles from "./PostCardStyles";
import signupImg from "../../assets/images/Signupbg.png";
import ReactHtmlParser from "react-html-parser";
import { setReceivedPost } from "../../store/actions/postCards";
import moment from "moment";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

function PostCardReceived() {
  const classes = PostCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const receivedPostCard = useSelector((state) => state.postcards.received);
  const postcard = useSelector((state) => state.postcards.displayPostcard);
  // Start Received Preview code//
  const [image, setImage] = useState("");
  const [senderName, setSenderName] = useState("");
  const [receivedFrom, setReceivedFrom] = useState([]);
  const [cardContent, setCardContent] = useState("");
  const [checkLength, setCheckLength] = useState("");
  const [currentReceivedDate, setCurrentReceivedDate] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (postcard.files) {
      setImage(postcard.files);
    }
    else {
      setImage(postcard.image)
    }
    setCurrentReceivedDate(postcard.date)
    setReceivedFrom(postcard.from);
    setCardContent(postcard.content);
    setSenderName(postcard.name);

  }, [postcard]);

  // End Received Preview code//
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sentToUserRender = () => {
    if (receivedFrom && receivedFrom.length > 0) {
      if (receivedFrom.length > 1) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography className={classes.marginTypography}>
              {receivedFrom[0].email}
            </Typography>
            <div
              onClick={() => setOpen(true)}
              style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}
            >
              <Typography style={{ marginLeft: '10px', marginRight: '5px', color: '#318CE7' }}>
                more
              </Typography>
              <GroupAddIcon style={{ color: '#318CE7' }} />
            </div>
            <Dialog onClose={handleClose} open={open}>
              <DialogTitle>Users</DialogTitle>
              <List sx={{ pt: 0 }}>
                {receivedFrom.map((user, index) => (
                  <ListItem button key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={user.avatarUrl} alt="img" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={user.userName} style={{ marginRight: '5px' }} />
                    <ListItemText primary={user.email} />
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </div>
        )
      } else {
        return (
          <Typography className={classes.marginTypography}>
            {receivedFrom[0].email}
          </Typography>
        )
      }
    } else {
      return null
    }

  }

  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        className={classes.postcardReciev}
        style={{
          margin: 'auto',
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${signupImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            // marginTop: "3rem",
            // backgroundColor:"red",
            padding: "20px",
            // minHeight: "100vh",
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Box
              style={{
                maxHeight: '100%',
                width: "100%",
                padding: "33px",
                backgroundColor: "#fff",
                textAlign: "left",
                borderRadius: "20px",
                justifyContent: "left",
                margin: "auto",
                marginTop: '3rem',
                // marginLeft:"4rem",

              }}
            >
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={6} md={8}>
                  <Typography
                    style={{
                      color: "#1E3B72",
                      fontFamily: "Museo300",
                      fontSize: "25px",
                    }}
                  >
                    {image && image.content ? 'PostCard Preview' : postcard.sent === true ? 'Postcard Sent To' : 'Postcard Received From'}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <CloseIcon
                    style={{
                      justifyContent: "end",
                      textAlign: "end",
                      float: "right",
                      color: "#000",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/postcard")}
                  />
                </Grid>
              </Grid>

              {/* <Box style={{ display: 'flex' }}> */}
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                style={{ paddingTop: "1.5rem" }}
              >
                <Box style={{ display: "flex" }}>
                  {
                    postcard.sent === true ?
                      <img src={receivedFrom && receivedFrom[0]?.avatarUrl} alt="img" className={classes.profileImg} />
                      :
                      <img src={postcard.profileImage} alt="img" className={classes.profileImg} />

                  }
                  {/* <img  src={post?.user?.avatarUrl} alt="img" className={classes.profileImg} /> */}

                  {postcard.sent === true ?
                    receivedFrom.map((element, index) => {
                      return index < 1 &&
                        <Typography className={classes.marginTypography}>
                          {element.userName}
                        </Typography>
                    }) : <Typography className={classes.marginTypography}>
                      {senderName}
                    </Typography>
                  }



                  {postcard.sent === true ?
                    sentToUserRender() :
                    <Typography className={classes.marginTypography1}>{receivedFrom}</Typography>
                  }

                </Box>
              </Grid>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                style={{ marginLeft: '30px' }}
              >
                <Typography className={classes.marginTypography}>
                  {postcard.sent === true ? "Sent at : " : "Received at : "} {moment(postcard.date).format('LLLL')}
                </Typography>

              </Grid>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '1rem',
                  borderRadius: '10px',
                  padding: '10px',
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Grid item xs={12} md={6} lg={6} style={{
                  maxHeight: '50vh',
                  boxShadow: '0px 0px 1px 1px gray', borderRadius: '20px'

                }}>
                  <p
                    style={{
                      fontSize: "small",
                      color: "grey",
                      textAlign: "left",
                      padding: "15px",
                      marginTop: "5px",
                      fontSize: "small",
                      color: "grey",
                      whiteSpace: "nowrap !important",
                      overflow: "hidden !important",
                      textOverflow: "ellipsis !important",
                      width: '100%'


                    }}
                  >
                    {ReactHtmlParser(cardContent)}
                  </p>
                  {/* {cardContent} */}
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{
                  maxHeight: '50vh',
                  boxShadow: '0px 0px 1px 1px gray', borderRadius: '20px'
                }}>
                  {image.content ? (
                    <img
                      src={image.content}
                      alt=""
                      style={{
                        backgroundSize: "cover",
                        justifyContent: "center",
                        objectFit: "cover",
                        overflow: "hidden",
                        borderRadius: "21px",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                  ) : (
                    <img
                      src={image}
                      alt=""
                      style={{
                        backgroundSize: "cover",
                        justifyContent: "center",
                        objectFit: "cover",
                        overflow: "hidden",
                        borderRadius: "21px",
                        width: "100%",
                        height: "100%"
                      }}
                    >
                    </img>
                  )}
                </Grid>
              </Grid>

              {/* </Box> */}
            </Box>
          </Grid>
        </Container>
      </Box>
      {/* <Footer /> */}
    </>
  );
}

export default PostCardReceived;
