import React from "react";
import FooterStyles from "./FooterStyles";
import faceb from "../../assets/images/faceb.png";
import instagra from "../../assets/images/instagra.png";
import twitter from "../../assets/images/twitter.png";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo1.png";
import appstore from "../../assets/images/appstore.png";
import googleplay from "../../assets/images/googleplay.png";
import { Box, Grid, Container, Typography } from "@mui/material";
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
// import { HTML5_FMT } from "moment";

function Footer() {
  const classes = FooterStyles();
  return (

    <Box className={classes.footerContainer}>
      <Box className={classes.footerLinks}>
        <Grid container style={{ margin: 'auto' }} spacing={2} item lg={10}>
          <Grid item xs={12} md={3.5} sm={12} >
            <Box className={classes.footerLinkWrapper}>
              <Box className={classes.footerLinkItems}>
                <img src={logo} alt="First slide" />
                <Typography className={classes.footerPara}>
                  The world is a beautiful place when we nurture the spark within.
                  QuillatWill is here to give wings to your creative expression to craft your vision.
                </Typography>

                <Box className={classes.socialMediaWrap}>
                  <Box className={classes.socialIcons}>
                    <a href="https://www.facebook.com/QuillAtWill">
                      <img src={faceb} alt="First slide" />
                    </a>
                    <a href="https://www.instagram.com/quillatwillofficial/">
                      <img src={instagra} alt="First slide" />
                    </a>
                    <a href="https://twitter.com/quillatwill">
                      <img src={twitter} alt="First slide" />
                    </a>
                    <a href="https://in.pinterest.com/QuillAtWill/">
                      <PinterestIcon style={{ cursor: 'pointer', color: '#ffff' }} />
                    </a>
                    <a href="https://www.linkedin.com/company/82144441/" style={{ color: "#fff !important" }}>

                      <LinkedInIcon style={{ cursor: 'pointer', color: '#ffff' }} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCK7VquJmYZ4Wb0tN20g7wAA" style={{ color: "#fff !important" }}>

                      <YouTubeIcon style={{ cursor: 'pointer', color: '#ffff' }} />
                    </a>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} md={1.8}>
            <Box className={classes.quicklinks} >
              <Box className={classes.footerLinkItems}>
                <h2 className={classes.footerHeading}>Create</h2>
                <Link className={classes.linkStyle} to="/blog">
                  Blog
                </Link>
                <Link className={classes.linkStyle} to="/diary">
                  Diary
                </Link>
                <Link className={classes.linkStyle} to="/postcard">
                  Postcard
                </Link>
                <Link className={classes.linkStyle} to="/poem">
                  Poem
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} sm={2} md={2.2}>
            <Box className={classes.quicklinks} >
              <Box
                className={classes.footerLinkItems}

              >
                <h2 className={classes.footerHeading}>Explore</h2>

                <Link
                  className={classes.linkStyle}
                  to="/hub/articles"
                >
                  Hub
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/secret-diary"
                >
                  Secret Diary
                </Link>

                <Link
                  className={classes.linkStyle}
                  to="/online-journal"
                >
                  Online Journal
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/online-diary"
                >
                  Online Diary
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/types-of-poem"
                >
                  Types Of Poem
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <Box className={classes.quicklinks} >
              <Box
                className={classes.footerLinkItems}

              >
                <h2 className={classes.footerHeading}>About</h2>

                <Link className={classes.linkStyle} to="/privacypolicy">
                  Privacy Policy
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/hub/faqs"
                >
                  FAQs
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/terms-conditions"
                >
                  Terms of Service
                </Link>
                <Link
                  className={classes.linkStyle}
                  to="/CookiesPolicy"
                >
                  Cookies Policy
                </Link>

                <Link className={classes.linkStyle} to="/about">
                  About Us
                </Link>
                {/* <Link className={classes.linkStyle} to="/signupwithgoogle"> */}
                <Link className={classes.linkStyle} to="/contact">
                  Contact Us
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={1} md={1}>
            <Box className={classes.footerLinkWrapper}>


              <Box className={classes.footerLinkItems}>
                <h2 className={classes.footerHeading}>Download the App</h2>
                <a
                  className={classes.linkStyle}
                  href="https://play.google.com/store/apps/details?id=com.quillatwill" >
                  <img
                    style={{ marginBottom: "5px" }}
                    src={googleplay}
                    height="50"
                    width="150"
                  />
                </a>

                <a
                  className={classes.linkStyle}
                  href="https://apps.apple.com/in/app/quillatwill/id1614219945" >
                  <img src={appstore} height="50" width="150" />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        className={classes.socialMedia}
        style={{ borderBottom: "1px solid gray" }}
      ></Box>

      <Box style={{ width: "70%" }}>
        <Container>
          <Grid item md={6}>
            <Typography className={classes.footerPara}>
              {" "}
              All Rights Reserved. By © 2022 Quill@Will.
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              float: "right",
            }}
          >
            <Typography className={classes.footerPara}>
              Designed by @QQuench.com .
            </Typography>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
