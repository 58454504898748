import React, { useState } from "react";
import { Box, Grid, Container, Typography, Divider } from "@mui/material";
import contact from "../../assets/images/contact/contact2.png";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { showErrorSnackbar, showSuccessSnackbar } from '../../store/actions/snackbar';
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from "react-helmet";


export const ContactInformation = (props) => {
    const [usersEmail, setUsersEmail] = useState('');
    const [usersName, setUsersName] = useState('');
    const [usersSubject, setUsersSubject] = useState('');
    const [usersMessage, setUsersMessage] = useState('');
    const handleEmailChange = (event) => {
        setUsersEmail(event.target.value);

    }
    const handleNameChange = (event) => {
        setUsersName(event.target.value);

    }
    const handleSubjectChange = (event) => {
        setUsersSubject(event.target.value);

    }
    const handleMessageChnage = (event) => {
        setUsersMessage(event.target.value);

    }
    const dispatch = useDispatch();
    const firestore = useFirestore();

    const sendEmail = () => {
        let msgData = `Hello Team, \n User wants supports from team, the details of user's are as follows :\nName: ${usersName} \n Email: ${usersEmail} \n Message: ${usersMessage}`
        fetch(
            "https://asia-south1-quillatwill-int-uat.cloudfunctions.net/sendDynamicEmail",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },

                body: JSON.stringify({
                    email: 'connect@quillatwill.com',
                    name: 'User',
                    message: msgData,
                    subject: usersSubject
                }),
            }
        )
            .then((response) => {
                setUsersEmail('')
                setUsersSubject('')
                setUsersName('')
                setUsersMessage('')
            })
            .catch((error) => {
                console.error("Errorrr email  : ", error);
            });
    }
    const handleSendEmail = async () => {
        const uuid = uuidv4();
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(usersEmail)) {
            await firestore.collection("mails").doc(uuid).set({
                to: ["connect@quillatwill.com"],
                message: {
                    name: usersName,
                    subject: usersSubject,
                    html: usersMessage
                }
            }).then(async () => {
                sendEmail()
                dispatch(showSuccessSnackbar("Thank you for your interest, we will get back to you soon!"));

            });
        } else {
            dispatch(showErrorSnackbar("Please enter valid email address!"));
        }
    }
    return (
        <>
            <Grid container spacing={2} style={{
                maxWidth: "100%",
                margin: "0",
                background: "#F4F5F8",
            }}>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Us | Quill at Will - Platform For Blogging</title>
                    <meta
                        name="title"
                        content="Contact Us | Quill at Will - Platform For Blogging"
                    />
                    <meta
                        name="description"
                        content="Contact us for any of your queries or questions regarding Quill at Will platform. We would be more than happy to address your queries & questions."
                    />
                    <meta
                        name="keywords"
                        content=""
                    />
                </Helmet>
                <Container style={{
                    marginTop: "6rem",
                    marginBottom: "3rem",
                    borderRadius: "25px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    background: "linear-gradient(0deg, rgba(73,182,207,1) 0%, rgba(69,63,175,1) 60%, rgba(68,44,170,1) 100%)",
                }}>
                    <Grid container>
                        <Grid item xs={12} md={4} lg={3}>
                            <img src={contact} style={{
                                marginLeft: "-2rem",
                                paddingTop: "5rem",
                            }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} style={{
                            padding: "2rem",
                        }}>
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    color: "#fff",
                                    textAlign: "right",
                                }}
                            >
                                We can’t wait to hear from you!<br />
                                please write in to connect@quillatwill.com
                                <br />
                                <br />
                                <Typography style={{ fontFamily: "Museo700" }}> Address: H303, Casa Rouge, Jubilee Gardens, Kondapur, Hyderabad </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={5} style={{
                            background: "#fff",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                            padding: "2rem",


                        }}>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '80%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField id="standard-basic" label="Full Name" variant="standard" onChange={handleNameChange} value={usersName} />
                                <TextField id="standard-basic" label="Email" variant="standard" onChange={handleEmailChange} value={usersEmail} />
                                <TextField id="standard-basic" label="Subject" variant="standard" onChange={handleSubjectChange} value={usersSubject} />
                                <TextField
                                    id="standard-multiline-static"
                                    label="Enter Your Message Here"
                                    multiline
                                    rows={4}
                                    variant="standard"
                                    onChange={handleMessageChnage}
                                    value={usersMessage}
                                />
                                <Button variant="contained" onClick={handleSendEmail} style={{
                                    background: "linear-gradient(180deg, rgba(253,203,80,1) 0%, rgba(254,178,49,1) 100%)",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                }}>Submit</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
};

export default ContactInformation;
