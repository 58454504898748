import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import publish from '../../../assets/images/dashboard/published.png';
import draft from '../../../assets/images/dashboard/Group 516.png';
import PostCardStyles from "../PostCardStyles";

export default function PostcardMyRoom() {
  const classes = PostCardStyles();

  const { sentCount, receivedCount, draftCount } = useSelector(state => state.postcards);
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      // className={classes.myroomStyle}
      style={{
        borderRadius: '1rem',
        backgroundColor: '#dddddd',
        padding: '1rem',
      }}
    >
      <h3 style={{ color: '#001f58' }}>My Room</h3>
      <Grid container direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <h3 style={{ color: 'grey' }}>Blogs</h3> */}
          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={publish} alt="publish" />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              {/* <p style={{ color: '#f5819e', fontFamily: 'Museo700' }}>Sent</p> */}
              <p>Sent</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p style={{ fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691' }}>{sentCount ? sentCount : 0}</p>
            </Grid>
          </Grid>

          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt="publish" style={{ color: 'red' }} />
            </Grid>


            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Received</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700',
                  fontSize: '1rem',
                  color: '#4d3691',
                  marginBottom: '1rem'
                }}
              >
                {receivedCount ? receivedCount : 0}
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt="publish" />
            </Grid>


            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Drafts</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700',
                  fontSize: '1rem',
                  color: '#4d3691',
                  marginBottom: '1rem'
                }}
              >
                {draftCount ? draftCount : 0}

              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
