import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DiaryblogsStyles from './DiaryblogsStyles';
import guide from '../../../assets/images/PostCardImages/guide.jpg';
import { useHistory, useParams } from 'react-router-dom'
import NewDiary from './NewDiary';
import GuidPoem from './GuidPoem';
function GuideSubpages() {
    const classes = DiaryblogsStyles();
    const history = useHistory();
    const [showArticle, setShowArticle] = useState(false)
    const [value, setValue] = useState(null)
    const { type } = useParams()
    const handleShowArticle = () => {
        switch (value) {
            case 0: return (<NewDiary />);
            case 1: return <GuidPoem />;
            default: return
        }
    }

    useEffect(() => {
        if (type) {
            setShowArticle(true)
            if (type === 'poem')
                setValue(1)
            if (type === 'postcard')
                setValue(0)
        }
    }, [type])

    const onClickArticle = (value) => {
        setShowArticle(true)
        setValue(value)
    }
    return (
        showArticle ?
            <>
                <Button variant="contained"
                    style={{
                        marginBottom: "1rem",
                        color: '#fff',
                        cursor: 'pointer',
                        fontFamily: 'Museo700'
                    }}
                    onClick={() => setShowArticle(false)}
                >
                    {`  < Go Back`}
                </Button>
                {handleShowArticle()}

            </>
            :
            <>
                <Grid container style={{
                    display: 'flex', justifyContent: "center",
                }}>
                    <Grid conatiner item xs={12} sm={12} md={12} lg={12} style={{
                        borderRadius: '20px', border: '1px solid gray',
                        padding: '20px', height: "150vh"
                    }}>
                        <Grid container item xs={12} spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} md={4} lg={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center', paddingTop: '1rem' }}>
                                {/* <Typography
                                    className={classes.headingTypo}
                                    style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700" }}
                                >
                                    General
                                </Typography> */}

                            </Grid>

                        </Grid>
                        <Grid container item xs={12} spacing={3} style={{ display: 'flex', justifyContent: 'left', paddingLeft: "20px" }}>
                            <Grid item xs={12} md={4} lg={6} style={{}}>
                                <Box style={{ paddingTop: "1rem", }}>
                                    <Typography
                                        // className={classes.headingTypo}
                                        style={{ fontWeight: "500", color: "#101070", fontSize: "20px", fontFamily: "Museo 700", cursor: 'pointer' }}
                                        onClick={() => onClickArticle(0)}
                                    >
                                        PostCard
                                    </Typography>
                                    <Typography
                                        // className={classes.headingTypo}
                                        style={{ fontWeight: "500", color: "#101070", fontSize: "20px", fontFamily: "Museo 700", cursor: 'pointer' }}
                                        onClick={() => onClickArticle(1)}>
                                        Poem
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
    )
}

export default GuideSubpages;