import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import diaryImg1 from '../../../assets/images/DiaryImages/diaryImg1.jpg';
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function Guide() {
    const classes = DiaryblogsStyles();

    return (
        <>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "center" }}
                    >
                        FAQ/ Help Section
                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", textAlign: "center" }}
                    >
                        General FAQs
                        <br />
                    </Typography>
                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "25px" }}
                                    >
                                        1. How to create a free account
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        Ready to create a free QuillatWill account? Follow the steps below to get started:
                                        On the top right corner of the home page enter your name, email address, and a password.
                                        Click "Register."
                                        Follow the steps to complete the configuration for your account.
                                        Start writing!

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "25px" }}
                                    >
                                        2. Who should I contact if I have a technical problem or a support issue?
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        Please drop us an email at support@quillatwill.com and our team will get back to you.
                                        <br />
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "25px" }}
                                    >
                                        3. How do you change your display name?
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        Click on the profile picture icon at the top right.
                                        Go to the profile section,
                                        In the left panel, under the profile picture, click on the name and enter the user display name of your choice,
                                        Press enter to save the display name.

                                        <br />
                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "25px" }}
                                    >
                                        4. I have forgotten my account password
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        In the Login screen, click on the Forget Password link.
                                        Enter the email ID with which you have registered,and press the Reset password button. An email will be sent to your email inbox with a link to reset your password.

                                        <br />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    Blogs FAQs
                                </Typography>
                                <Typography className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}>
                                    5. What are Blogs ?
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "16px" }}
                                >
                                    Have something to say to the world? Quill it through a blog. Share what’s on your mind with a large audience on our platform. You can share your experiences, photos, and solutions to life problems through online blog writing. Go ahead and share food, travel, sports and experiential blogs and also connect with people on issues that matter.


                                    <br />
                                </Typography>
                                <Typography className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}>
                                    6. Alerts to friends: How do my friends know if I have written a blog? Can I let them know?

                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "16px" }}
                                >
                                    Yes, once you open your blog, click on the paper plane icon to get a sharing link, paste it in your message chat box to share it with your friends.
                                    <br />
                                </Typography>
                                <Typography className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}>
                                    7. What are post titles and blog titles ?

                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "16px" }}
                                >
                                    Post title is the heading of the article being posted under a blog.
                                    The Blog title indicates a collection of one or more posts under a single blog. For example:
                                    Blog title - My travels
                                    Post title - My trip to Kashmir

                                    <br />
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    Postcard FAQs
                                </Typography>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    8. What are Postcards?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    Heart-warming posts for your friends and family, specially designed by you.
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    9. Alert for postcard: How will my friend know I have sent them a postcard?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    They will receive an email with a link to open the postcard and view your message.
                                    <br />
                                </Typography>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    10. Sending Postcard: How do I send a postcard?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    Go to the Card section on your dashboard, click the Create New icon, and write your message. You can also upload an image or choose from existing media. Further, click on send, enter the email id of the receiver(s) and send the postcard. For more detailed instructions, please visit the product guide.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    Diary FAQs
                                </Typography>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    11. What is a Diary?
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    A diary is a place where you keep track of events, experiences, and other personal details that you find interesting.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "25px" }}
                                >
                                    12. Can my diary or journal entries be read by others?

                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    No.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "25px" }}
                            >
                                13. Can I add emoticons and stickers to my diary entry?
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "16px" }}
                            >
                                Yes. Tap on the Elements section and choose from QuillatWill’s creative gallery of emoticons and stickers. These can be added under the journal section.
                                <br />
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "25px" }}
                            >
                                14. How to add photos to my diary entries?
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "16px" }}
                            >
                                Once in the diary section, click on the upload icon that appears in the right hand side of the diary to add images from your device or choose from a range of images using the media icon.
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "16px" }}
                            >
                                Note- Headover to Product Guides under Hub for detailed step by step instructions on using our writing features like blogs, postcard, poems etc.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Guide;