import React, { useState } from "react";
import Main from "../Main";
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import PersonalLogin from './PersonalLogin'
import InstituteLogin from "./InstituteLogin";
import { useHistory } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    backgroundColor: '#fff',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1E3A71' : '#308fe8',
    },
}));
const styles = {
    backgroundColor: "#1d396f",
    backgroundImage: 'linear-gradient(darkblue, aqua)'
}
const Login = (props) => {
    const [instituteLogin, setInstituteLogin] = useState(true)
    const history = useHistory()
    return (
        <Main styles={styles}>
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '95vh' }}>
                {
                    instituteLogin ?
                        <InstituteLogin setInstituteLogin={setInstituteLogin} />
                        :
                        <PersonalLogin setInstituteLogin={setInstituteLogin} />
                }
                {/* <Typography
                    variant="p"
                    style={{ fontFamily: 'Museo300', color: 'gray' }}
                >
                    Don't Have Account?
                    <span
                        style={{
                            fontFamily: 'Museo700',
                            color: '#1E3A71',
                            marginLeft: 10,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={() => history.push('/register')}
                    >
                        Register Here
                    </span>
                </Typography> */}
            </Grid>
        </Main>
    );
}

export default Login;
