import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	subscriptions: [],
	userSubscription: {
		name: 'Freemium',
		type: 'freemium',
		subText: 'Free Use',
		price: 0,
		features: ['10 Blogs per month', 'Publish 5 Poems', 'Send 5 postcards', 'Up-to 5 Essays', 'Earn Badges & Currency', 'Digital Pets'],
		blogs: 10,
		poems: 5,
		postcard: 5,
		essays: 5
	},
	userAllowedContent: {
		blogs: 0,
		postcard: 0,
		poems: 0,
		essay: 0
	},
	isPremiumUser: true,
	userStatusUpdated: true
};

export const subscriptions = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_SUBSCRIPTION:
			return {
				...state,
				subscriptions: payload
			}

		case actionTypes.ADD_USER_SUBSCRIPTION:
			return {
				...state,
				userSubscription: payload
			}

		case actionTypes.GET_USER_SUBSCRIPTION:
			return {
				...state,
				userSubscription: payload
			}
		case actionTypes.SET_USER_ALLOWED_CONTENT:
			return {
				...state,
				userAllowedContent: payload
			}
		case actionTypes.SET_PREMIUM_USER_FLAG:
			return {
				...state,
				isPremiumUser: payload
			}
		case actionTypes.SET_PREMIUM_USER_STATUS_FLAG:
			return {
				...state,
				userStatusUpdated: payload
			}

		default:
			return state;
	}
};

