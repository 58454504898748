import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import eye from "../../../assets/images/Blog/BD/a;hi.png";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import share from "../../../assets/images/Blog/BD/agr.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";
import blankimg from "../../../assets/images/Blog/blankimg.png";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import DashboardStyles from "../DashboardStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getUserPosts, deletePost } from "../../../store/actions/posts";
import { getUserPoems, deletePoem, setShowPoems, setShowPoemIndex } from "../../../store/actions/poems";
import { useFirestoreConnect } from "react-redux-firebase";
import { isEmpty } from 'lodash'

export default function AppRecentalyAddedData(props) {
  const classes = DashboardStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { firestore } = useSelector((state) => state);
  const { userPublishedPosts } = useSelector((state) => state.posts)
  const { userPublishedPoems } = useSelector((state) => state.poems)
  const auth = useSelector(state => state.firebase.auth);
  const [id, setId] = useState(null);
  const [contentType, setContentType] = useState('')

  const { users } = firestore.ordered;
  const [user, setUser] = useState([]);
  const [userPosts, setUserPosts] = useState([])
  useFirestoreConnect([{ collection: "users", doc: auth.uid }]);

  useEffect(() => {
    if (users) {
      const currentUser = users[0];
      setUser({ ...user, ...currentUser });
    }
  }, [users]);

  useEffect(() => {
    dispatch(getUserPosts(true));
    dispatch(getUserPoems(true))
  }, []);

  useEffect(() => {
    let userPostsData = []
    let contents = []
    let userPoems = []
    for (let i = 0; i < userPublishedPoems.length; i++) {
      let poem = userPublishedPoems[i]
      poem = {
        ...poem,
        user
      }
      userPoems.push(poem)
      let content = {
        id: poem.id,
        title: poem.title,
        images: poem && poem.images && poem.images.length > 0 ? poem.images : [poem?.image],
        type: 'poem',
        views: poem?.views || 0,
        likedby: poem?.likedBy || [],
        comments: 0,
        shared: 0,
        body: poem?.content,
        content: poem?.body,
        lastUpdate: poem?.lastUpdated,
        user: poem?.user,
        commentscount: poem?.commentscount,
        shared: poem?.shared
      }
      contents.push(content)
    }
    if (isEmpty(userPosts))
      userPostsData = contents
    else {
      userPostsData = userPosts.filter((post) => post.type != 'poem')
      userPostsData = [...userPostsData, ...contents]
      userPostsData = userPostsData.sort(function (a, b) {
        return b.lastUpdate.seconds - a.lastUpdate.seconds;
      });
    }
    dispatch(setShowPoems(userPoems))
    setUserPosts(userPostsData)
  }, [userPublishedPoems])

  useEffect(() => {
    let userPostsData = []
    if (isEmpty(userPosts))
      userPostsData = userPublishedPosts
    else {
      userPostsData = userPosts.filter((post) => post.type != 'blog')
      userPostsData = [...userPostsData, ...userPublishedPosts]
      userPostsData = userPostsData.sort(function (a, b) {
        return b.lastUpdate.seconds - a.lastUpdate.seconds;
      });
    }
    setUserPosts(userPostsData)
  }, [userPublishedPosts])

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e, post) => {
    e.stopPropagation();
    setId(post.id)
    setContentType(post.type)
    setOpen(true);
  };
  const handleClose = () => {
    if (id) {
      if (contentType === 'blog') {
        dispatch(deletePost(id));
      }
      if (contentType === 'poem') {
        dispatch(deletePoem(id));
      }

      let userPostsData = userPosts
      let index = userPostsData.findIndex((post) => post.id === id)
      if (index > -1)
        userPostsData.splice(index, 1)

      setUserPosts(userPostsData)
      setId(null)
      setContentType(null)
    }
    setOpen(false);
  }
  const onClickNo = () => {
    setOpen(false);
  }

  const handlePoemClick = (poemId) => {
    let index = userPublishedPoems.findIndex((poem) => poem.id === poemId)
    dispatch(setShowPoemIndex(index > 0 ? index : 0))
    history.push('/poem-view')
  }

  // const getUsername = (post) => {
  //   let name = ''
  //   if (post && post.user && post.user.displayName) {
  //     name = post.user.displayName
  //   } else if (post && post.user && post.user.name) {
  //     name = post.user.name
  //   }
  //   return name;
  // }

  const getUsername = (post) => {
    let name = ''
    let { user } = post
    if (user) {
      if (user.hasOwnProperty('displayName')) {
        name = user.displayName
      } else if (user.hasOwnProperty('name')) {
        let temp = user.name
        user = temp.length > name.length ? temp : name
      }
    }
    return name;
  }

  return (
    <Grid container spacing={2}>


      <Dialog
        open={open}
        keepMounted
        onClose={onClickNo}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Are you sure you want to Delete ?'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            lorem ipsum dolor sit amet, consectetur
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button style={{ background: 'rgb(0, 31, 88)', color: '#fff' }} onClick={handleClose}>Yes</Button>
          <Button style={{ background: 'rgb(0, 31, 88)', color: "#fff" }} onClick={onClickNo}>No</Button>
        </DialogActions>
      </Dialog>

      {userPosts && userPosts.length > 0
        ? userPosts.map((post, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4}
            style={{ cursor: 'pointer' }}
            onClick={() => post?.type == 'poem' ?
              handlePoemClick(post.id)
              :
              history.push("/blog/" + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())
            }>
            <Stack className={classes.responsiveCard}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box style={{ position: "relative" }}>
                    <Box>
                      <DeleteIcon onClick={(e) => handleClickOpen(e, post)}
                        className={classes.deleteStyles}
                      />
                    </Box>
                    <div style={{ minHeight: '200px', backgroundColor: 'skyblue' }}>
                      <span style={{
                        color: '#000',
                        position: 'absolute',
                        Zindex: 1000,
                        fontSize: 16,
                        top: 10,
                        right: 10,
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        fontFamily: 'Museo700',
                        // fontWeight: 700,
                        borderRadius: 10
                      }}
                      >
                        {
                          post?.type === 'blog' ? 'Blog' : 'Poem'
                        }
                      </span>
                      <img
                        src={post.images && post.images.length > 0 ? post.images : blankimg}
                        //  src={bgImageSrc2}
                        alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover' }} />
                    </div>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0",
                        // margin: "10px",
                        padding: '1rem',
                        width: '78vw',
                        background: 'linear-gradient(rgba(255,0,0,0), #000000)',

                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{ color: "#fff" }}
                      >
                        <Grid item>
                          <img src={eye} alt="" />
                          <span> {post?.views || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={hands} alt="" />
                          <span> {post && post.likedby
                            ? post.likedby.length
                            : 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={comment} alt="" />
                          <span> {post?.commentscount || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={share} alt="" />
                          <span> {post?.shared || 0} </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    spacing={2}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <div style={{ height: '100px', width: '17vw', overflow: 'hidden', paddingRight: '10px' }}>
                      <h3 style={{ color: "grey", marginTop: "0px", }}>
                        {post?.title}
                      </h3>

                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                        }}
                      // dangerouslySetInnerHTML={{ __html: post?.body }}
                      >
                        {
                          post && post.body ? ReactHtmlParser(post?.body) : null
                        }
                        {/* {
                        post.body ?
                          post.body.length < 100 ?
                            ReactHtmlParser(post?.body) :
                            ReactHtmlParser(post?.body.substring(0, 50)) + '....' : ''
                      } */}
                      </p>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      marginBottom="0"
                    >
                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <img
                          style={{
                            width: "34px",
                            height: "34px",
                            borderRadius: "50%",
                          }}
                          src={user.avatarUrl || auth.photoURL}
                          alt="pc" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Stack direction="row" spacing={2}>
                          <b
                            style={{
                              fontWeight: "500",
                              color: "rgb(0, 31, 88)",
                              whiteSpace: 'nowrap !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important',
                            }}
                          >
                            {getUsername(post)}
                          </b>
                          {/* <span style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}> {post?.title}</span> */}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <b
                          style={{
                            fontWeight: "500",
                            color: "rgb(0, 31, 88)",
                            textAlign: "right",
                            overflow: 'hidden',
                          }}
                        >
                          {post.lastUpdate
                            ? moment
                              .unix(post.lastUpdate.seconds)
                              .format("DD MMM YY")
                            : null}
                        </b>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid >
        )) : null
      }
      {/* <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "black 0px 0px 5px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box style={{ position: "relative" }}>
                <img src={bgImageSrc2} alt="bg" style={{ width: "100%" }} />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "0",
                    margin: "10px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    spacing={4}
                    style={{ color: "#fff" }}
                  >
                    <Grid item>
                      <img src={eye} alt="" />
                      <span>7500</span>
                    </Grid>
                    <Grid item>
                      <img src={hands} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={comment} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={share} alt="" />
                      <span>100</span>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Stack
                direction="column"
                spacing={2}
                style={{
                  padding: "1rem",
                }}
              >
                <h3 style={{ color: "grey", marginTop: "0px" }}>
                  Lorem ipsum dolor
                </h3>

                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "small",
                    color: "grey",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit.
                </p>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  marginBottom="0"
                >
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <img src={profilePicSrc1} alt="pc" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Stack direction="row" spacing={2}>
                      <b
                        style={{
                          fontWeight: "500",
                          color: "rgb(0, 31, 88)",
                        }}
                      >
                        Mitali Singh
                      </b>
                      <span>Blog</span>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <b
                      style={{
                        fontWeight: "500",
                        color: "rgb(0, 31, 88)",
                        textAlign: "right",
                      }}
                    >
                      Feb 17
                    </b>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "black 0px 0px 5px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box style={{ position: "relative" }}>
                <img src={bgImageSrc2} alt="bg" style={{ width: "100%" }} />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "0",
                    margin: "10px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    spacing={4}
                    style={{ color: "#fff" }}
                  >
                    <Grid item>
                      <img src={eye} alt="" />
                      <span>7500</span>
                    </Grid>
                    <Grid item>
                      <img src={hands} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={comment} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={share} alt="" />
                      <span>100</span>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Stack
                direction="column"
                spacing={2}
                style={{
                  padding: "1rem",
                }}
              >
                <h3 style={{ color: "grey", marginTop: "0px" }}>
                  Lorem ipsum dolor
                </h3>

                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "small",
                    color: "grey",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit.
                </p>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  marginBottom="0"
                >
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <img src={profilePicSrc1} alt="pc" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Stack direction="row" spacing={2}>
                      <b
                        style={{
                          fontWeight: "500",
                          color: "rgb(0, 31, 88)",
                        }}
                      >
                        Mitali Singh
                      </b>
                      <span>Blog</span>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <b
                      style={{
                        fontWeight: "500",
                        color: "rgb(0, 31, 88)",
                        textAlign: "right",
                      }}
                    >
                      Feb 17
                    </b>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid> */}
    </Grid >
  );
}
