import { makeStyles } from "@mui/styles";

const DashboardStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    width: "100%",
    height: "60%",
    // background: "yellow",
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  LetStartStyle: {
    maxWidth: "40%",
    fontFamily: 'Museo300',
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    }
  },
  appPreStyle1: {
    borderRadius: '1rem',
    backgroundColor: '#EDF0F2',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      margin: 'auto',
      marginTop: "1rem",
    }
  },
  //create App style code//
  mainContainerStyle2: {
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "3rem !important",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "6rem !important",
      paddingLeft: "1rem !important",
      paddingRight: "1rem !important",
    },
  },
  //Create App blog Style end code//
  febBTN: {
    '&.MuiSpeedDialAction-fab': {
      padding: 'auto !important',
      width: '60px !important',
      height: '60px !important',
      backgroundColor: '#ffc107 !important',
    },
    '& img': {
      marginTop: '5.1px'
    }
  },

  // textStyle:{
  //   margin:"1px",
  //   width:'18ch',
  //   marginLeft:"-1.1px",
  // },


  createFabStyles2: {
    position: 'fixed', zIndex: '9999', top: '50%', right: '60px',
    '& .MuiFab-primary': {
      color: '#fff',
      backgroundColor: '#ffc107',
    }
  },
  hiddenOnMobileStyle: {
    // display: "flex",
    // marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none !important"
    },

  },
  hiddenOnwebStyle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      // display: "flex",
      // justifyContent: "center",
    },
  },

  letboxStyle: {
    color: "white",
    background: "linear-gradient(0deg, #6acfff, #2d00b7)",
    borderRadius: "1rem",
    padding: "1rem",
    marginBottom: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      color: "white",
      background: "linear-gradient(0deg, #6acfff, #2d00b7)",
      borderRadius: "1rem",
      padding: "1rem",
      // position:"absolute",
      // marginTop:"-94rem",
      width: "100%",
      marginTop: "-6rem",

    }
  },

  DashboardImgsStyle: {
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    },

    // [theme.breakpoints.up("sm")]: {
    //   display:'none',
    // },

  },

  mainCard: {
    [theme.breakpoints.down("sm")]: {
      // flexDirection: "column",
      background: "green",
    },
  },

  mainContent: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    // background: "red",
  },
  mainParticlesLeft: {
    width: "73%",
    height: "100%",
    position: "absolute",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  mainParticlesRight: {
    width: "24%",
    height: "100%",
    position: "absolute",
    right: 0,
    background: "#FFDB00",
    marginLeft: "2rem",
    borderRadius: "2rem",
    padding: "1rem",
  },
  mainCardUp: {
    width: "100%",
    height: "67%",
    display: "flex",
    justifyContent: "space-between",

    // background: "green",
    // marginButton: "1rem",
  },
  mainCardDown: {
    width: "100%",
    height: "30%",
    position: "absolute",
    bottom: 0,
    // marginTop: "2rem",
    background: "#FFB5C9",
    borderRadius: "1.5rem",
  },

  mainContentDownLeft: {
    width: "50%",
    height: "100%",
    background: "#CEBFFB",
    // marginTop: "1rem",
    borderRadius: "1.5rem",
    padding: "1rem",
    padding: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      flexDirection: "column",
    },
  },

  mainContentDownRight: {
    width: "50%",
    height: "100%",
    background: "#77D4E3",
    marginLeft: "2rem",
    borderRadius: "1.5rem",
    padding: "1rem",
  },
  DownLeftMini: {
    height: "4rem",
    width: "100%",
    backgroundColor: "#DBDBDB",
    marginBottom: "1rem",
    borderRadius: "10px",
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  DownRightMini: {
    height: "4rem",
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "10px",
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "red",
      // height: "100%",
    },
  },

  responsiveCard: {
    height: "auto",
    // width: '22vw',
    padding: "0",
    marginTop: "1rem",
    borderRadius: "2rem",
    overflow: "hidden",
    boxShadow: "black 0px 0px 5px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      height: "auto",
      width: "75vw",
      padding: "0",
      marginTop: "1rem",
      borderRadius: "2rem",
      overflow: "hidden",
      boxShadow: "black 0px 0px 5px 0px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      //  width: 23vw;
      // height: 53vw;
      padding: "0",
      marginTop: "1rem",
      borderRadius: "2rem",
      overflow: "hidden",
      boxShadow: "black 0px 0px 5px 0px",
    },
  },

  deleteStyles: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#183756",
    marginTop: "1rem",
    marginLeft: "0.5rem",
    background: "#f0f0f0",
    width: "3vw !important",
    padding: "6px",
    border: "2px solid rgb(0 0 0 / 26%)",
    height: "6vh !important",
    marginLeft: "19px",
    borderRadius: "24px",
    position: "absolute",
    zIndex: "1",

    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
      fontSize: "30px",
      color: "rgb(24, 55, 86)",
      marginTop: "1rem",
      marginLeft: "19px",
      background: "rgb(240, 240, 240)",
      width: "11vw !important",
      padding: "7px",
      border: "2px solid rgba(0, 0, 0, 0.26)",
      height: "6vh !important",
      borderRadius: "24px",
      position: "absolute",
      zIndex: "1",
    },
  },
}));

export default DashboardStyles;
