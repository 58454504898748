import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	institute: null,
	user: null,
	users: [],
	coOrdinators: [],
	tenantId: null,
	activePageIndex: 0,
	isInstitute: false
};

export const user = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.SET_INSTITUTE:
			return {
				...state,
				institute: payload
			}
		case actionTypes.SET_USER:
			return {
				...state,
				user: payload
			}

		case actionTypes.GET_USERS:
			return {
				...state,
				users: payload
			}

		case actionTypes.GET_CO_ORDINATORS:
			return {
				...state,
				coOrdinators: payload
			}
		case actionTypes.SET_IS_INSTITUTE:
			return {
				...state,
				isInstitute: payload
			}
		case actionTypes.SET_TENANT_ID:
			return {
				...state,
				tenantId: payload
			}
		case actionTypes.SET_ACTIVE_PAGE_INDEX:
			return {
				...state,
				activePageIndex: payload
			}

		default:
			return state;
	}
};



