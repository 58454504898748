import React, { useState, useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  Grid,
  Box,
  Dialog,
  Paper,
  Stack,
  Button,
  Modal,
  Backdrop,
  TextField,
  MenuItem,
  DialogContent,
  DialogTitle,
  InputBase,
  IconButton
} from "@mui/material";
import { Masonry } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DialogActions from "@mui/material/DialogActions";
import BlockStyleToolbar, { getBlockStyle } from "./BlockStyleToolbar";
import "./BlogEditorCSS.css";
import Typography from "@mui/material/Typography";
import { GoFileMedia } from "react-icons/go";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import BlogHeader from "./BlogHeader";
import { setOpenedPost, setSavedPost, getUserPosts } from "../../store/actions/posts";
import { showErrorSnackbar } from "../../store/actions/snackbar";
import { storage } from "../../config/fbConfig";
import { useFilePicker } from "use-file-picker";
import { ImCross } from "react-icons/im";
import SearchIcon from "@mui/icons-material/Search";
import BlogEditorStyles from "./BlogEditorStyles";
import {
  getOpenedUsersImages, getOpenedGallery, getPremiumImages,
  getStickers, addUserImageMetadata
} from "../../store/actions/images";
import BlogMultiSelect from "./blogSections/BlogMultiSelect";
import catimg from "../../assets/images/Blog/NBP/Cat.png";
import categoryImg from "../../assets/images/Blog/NBP/Icon1.png";
import tagImg from "../../assets/images/Blog/NBP/Icon feather-tag.png";
import calenderImg from "../../assets/images/Blog/NBP/Icon feather-calendar.png";
import commentImg from "../../assets/images/Blog/NBP/Icon.png";
import ladyImg from "../../assets/images/Blog/NBP/shutterstock_2029347044.png";
import cloudImg from "../../assets/images/Artwork1.png";

import '@draft-js-plugins/alignment/lib/plugin.css';
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import createImagePlugin from "@draft-js-plugins/image";
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import { convertToHTML } from "draft-convert";
import mockUpload from './mockUpload';
import { getSubscription, getUserSubscription, addUserSubscription } from "../../store/actions/subscriptions";
import { CgMenuGridR } from "react-icons/cg";
import BlogSelectBlogTitle from "./blogSections/BlogSelectBlogTitle";

import Navbar from "../Navbar";
import Main from "../Main";
const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: mockUpload,
  addImage: imagePlugin.addImage,
});

const plugins = [imagePlugin, resizeablePlugin, alignmentPlugin, blockDndPlugin, focusPlugin];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "70%",
};
const labelstyle = {
  fontSize: "1.5rem",
  color: "#1f108e",
};

const BlogEditor = (props) => {
  const classes = BlogEditorStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const { openedPost, draftPostEdit } = useSelector((state) => state.posts);
  const { gallery, usersImages, premiumImages, stickers } = useSelector(state => state.images);
  const [images, SetImages] = useState([]);
  const [gotImages, updateGotImages] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateAsHTML, setEditorStateAsHTML] = useState("");
  const [hasTextStatus, setHasTextStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [openUpload, setOpenUpload] = useState(false);
  const [openElements, setOpenElements] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [blogInfoOpen, setBlogInfoOpen] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogTitleId, setBlogTitleId] = useState(null);
  const [postTitle, setPostTitle] = useState("");
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(new Date());
  const [tag, setTag] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState([]);
  const [postImages, setPostImages] = useState([])
  const [autoSaveTimer, setAutoSaveTimer] = useState(null)
  const [autoSaveFlag, setAutoSaveFlag] = useState(false)
  const [openAddImageDialog, setOpenAddImageDialog] = useState(false)

  const { subscriptions, userSubscription, isPremiumUser, userStatusUpdated } = useSelector((state) => state.subscriptions);

  const [openFileSelector, { filesContent, plainFiles, clear }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL",
      accept: "image/*",
    });

  useFirestoreConnect([{ collection: "categories" }]);

  const categories = useSelector((state) => state.firestore.ordered.categories);

  const handleOpenModal = () => setBlogInfoOpen(true);
  const handleCloseModal = () => setBlogInfoOpen(false);

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setOpenElements(false);
  };

  const changeDate = (newValue) => {
    setDate(newValue);
  };

  const handleCategoryChange = (e) => {
    let tags = categories.filter((cat) => cat.title === e.target.value);
    if (tags && tags.length > 0) {
      setCategoryId(tags[0].id);
      tags = tags[0].tags;
      setTags(tags);
    } else {
      setTags([]);
    }
    setCategory(e.target.value);
  };

  useEffect(() => {
    if (!userStatusUpdated)
      dispatch(getUserSubscription())

    try {
      let uid = auth?.uid;
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages));
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages));
      dispatch(getStickers())
      dispatch(getPremiumImages())
    } catch (e) {
      console.log("Error : ", e);
    }
    setAutoSaveFlag(true)
    setAutoSaveTimer(10)
  }, []);

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
    let dataStatus = editorState.getCurrentContent();
    let htmlData = convertToHTML(dataStatus);
    setEditorStateAsHTML(htmlData);
    let status = dataStatus.hasText();
    setHasTextStatus(status);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = JSON.stringify(rawContentState)
    dispatch(setOpenedPost({ ...openedPost, body: htmlData, content: markup, images: postImages }));
    clear()
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  // useEffect(() => {
  //   dispatch(setOpenedPost({ ...openedPost, body: editorStateAsHTML }));
  // }, [editorStateAsHTML]);

  useEffect(() => {
    var imagesArray = [];
    // if (usersImages.length > 0) {
    //   imagesArray = imagesArray.concat(usersImages);
    // }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery);
    }
    SetImages(imagesArray);
  }, [gotImages, props.images]);

  const handleClose = () => {
    setOpen(false);
  };

  const searchContent = (e) => {
    if (e && e.target.value && e.target.value.length > 0) {
      let imagesArray = images.filter((img) =>
        img.toLowerCase().includes(e.target.value.toLowerCase())
      );
      SetImages(imagesArray);
    } else {
      let imagesArray = [];
      if (usersImages.length > 0) {
        imagesArray = imagesArray.concat(usersImages);
      }
      if (gallery.length > 0) {
        imagesArray = imagesArray.concat(gallery);
      }
      SetImages(imagesArray);
    }
  };

  const handleMediaClick = (url) => {
    setMediaUrl(url);
    setEditorState(insertImage(url));
    setOpen(false);
  };

  /////Template model open
  const handleClickOpenTemplate = (scrollType) => () => {
    setOpenTemplate(true);
    setScroll(scrollType);
  };

  /////Elements model open
  const handleClickOpenElements = (scrollType) => () => {
    setOpenElements(true);
    setScroll(scrollType);
  };

  const handleClickOpenUpload = (scrollType) => () => {
    setOpenUpload(true);
    setScroll(scrollType);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onUnderlineClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onBoldClick = (event) => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const saveBlog = (data) => {
    if (hasTextStatus) dispatch(setSavedPost(data));
    else {
      dispatch(showErrorSnackbar("Please, enter text for your blog"));
    }
  };

  useEffect(() => {
    async function save() {
      dispatch(setOpenedPost({ ...openedPost, images: postImages }));

      await saveBlog({ isPublished: false, showAlert: false })
      setAutoSaveTimer(10)
    }
    if (autoSaveFlag) {
      if (autoSaveTimer > 0) {
        setTimeout(() => setAutoSaveTimer(autoSaveTimer - 1), 1000);
      } else {
        save()
      }
    }
  }, [autoSaveTimer]);

  const publishBlog = () => {
    if (hasTextStatus) {
      const { title, category, blogId, tags, date, commentable } = openedPost;
      if (title === "" || title === null || title === undefined) {
        dispatch(showErrorSnackbar("Please, enter valid Post title"));
        handleOpenModal();
      } else if (
        blogId?.label === "" ||
        blogId?.label === null ||
        blogId?.label === undefined
      ) {
        dispatch(showErrorSnackbar("Please, enter valid Blog title"));
        handleOpenModal();
      } else if (
        category?.label === "" ||
        category?.label === null ||
        category?.label === undefined
      ) {
        dispatch(showErrorSnackbar("Please, enter valid category"));
        handleOpenModal();
      } else if (tags.length < 1) {
        dispatch(showErrorSnackbar("Please select valid tag"));
        handleOpenModal();
      } else if (date === "" || date === null || date === undefined) {
        dispatch(showErrorSnackbar("Please select valid date"));
        handleOpenModal();
      } else if (commentable === null || commentable === undefined) {
        dispatch(showErrorSnackbar("Please select yes and no comment"));
        handleOpenModal();
      } else {
        // if (!openedPost.hasOwnProperty('images'))
        dispatch(setOpenedPost({ ...openedPost, images: postImages }));

        dispatch(setSavedPost({ isPublished: true, showAlert: true }));
        dispatch(getUserPosts());
        dispatch(getUserPosts(true));
        props.history.push("/blog");
      }
    } else {
      dispatch(showErrorSnackbar("Please, enter text for your blog"));
    }
  };

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      let image = plainFiles[0];
      let userId = auth.uid;
      storage
        .ref(`/users/${userId}/${image.name}`)
        .put(image)
        .on("state_changed", console.log, console.error, () => {
          storage
            .ref(`/users/${userId}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async (url) => {
              let name = url.split('?')
              name = name[0].split('%2F')
              name = name[2]

              let payload = {
                url,
                category: 'Birthday',
                tags: ['Happy', 'Birthday', 'Wishes'],
                type: 'image',
                name,
                isPaid: 'free'
              }
              dispatch(addUserImageMetadata(payload))
              let imagesArray = postImages //openedPost && Array.isArray(openedPost.images) ? openedPost.images : []
              imagesArray.push(url)
              setPostImages(imagesArray)
              // dispatch(setOpenedPost({ ...openedPost, images: imagesArray }));
              setEditorState(insertImage(url));
            });
        });
    }

  }, [plainFiles, plainFiles.length]);

  useEffect(() => {
    if (mediaUrl) {
      let imagesArray = postImages //openedPost && Array.isArray(openedPost.images) ? openedPost.images : []
      imagesArray.push(mediaUrl)
      dispatch(setOpenedPost({ ...openedPost, images: imagesArray }));
    }
  }, [mediaUrl])

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  useEffect(() => {
    if (draftPostEdit) {
      const { title, category, blogId, tags, date, body, commentable, images, content } = openedPost;

      setBlogTitle(blogId?.label || "");
      setPostTitle(title ? title : "");
      setComment(commentable);
      setDate(date ? date : new Date());
      setTag(tags);
      let editorStateData = EditorState.createEmpty()

      if (content) {
        try {
          const state = convertFromRaw(JSON.parse(content))
          editorStateData = EditorState.createWithContent(state);
          onChange(editorStateData)
        } catch (e) {
          console.log("Error --- ", e)
        }
      } else {
        if (body) {
          const blocksFromHTML = htmlToDraft(body);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          editorStateData = EditorState.createWithContent(state);
          setEditorState(editorStateData);
        }

        if (images && images.length > 0) {
          for (let i = 0; i < images.length; i++) {
            const contentState = editorStateData.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
              "IMAGE",
              "IMMUTABLE",
              { src: images ? images : images[i] }
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorStateData, {
              currentContent: contentStateWithEntity,
            });
            editorStateData = AtomicBlockUtils.insertAtomicBlock(
              newEditorState,
              entityKey,
              " "
            );
            setEditorState(editorStateData);
          }
          setPostImages(images)
        }

        let dataStatus = editorStateData.getCurrentContent();
        let htmlData = convertToHTML(dataStatus);
        setEditorStateAsHTML(htmlData);
        let status = dataStatus.hasText();
        setHasTextStatus(status);
      }
      if (images && images.length > 0) {
        setPostImages(images)
      }
    }
  }, [draftPostEdit]);

  const handleOnClickNext = () => {
    let errors = new Array(6);
    let errorStatus = false;
    if (postTitle === "" || postTitle === null || postTitle === undefined) {
      errors[0] = "Please enter valid Post title";
      errorStatus = true;
    }
    if (blogTitle === "" || blogTitle === null || blogTitle === undefined) {
      errors[1] = "Please enter valid Blog title";
      errorStatus = true;
    }
    if (category === "" || category === null || category === undefined) {
      errors[2] = "Please select valid category";
      errorStatus = true;
    }
    if (tag.length < 1) {
      errors[3] = "Please select valid tag";
      errorStatus = true;
    }
    if (date === "" || date === null || date === undefined) {
      errors[4] = "Please enter valid date and time";
      errorStatus = true;
    }
    if (comment === null || comment === undefined) {
      errors[5] = "Please select yes and no comment";
      errorStatus = true;
    }
    setErrors(errors);
    if (!errorStatus) {
      let post = {
        ...openedPost,
        title: postTitle,
        category: {
          label: category,
          value: categoryId ? categoryId : "",
        },
        blogId: {
          label: blogTitle,
          value: blogTitleId,
        },
        tags: tag,
        date,
        commentable: comment,
        views: 0,
        likedby: [],
        lovedby: [],
        shared: 0,
        id: openedPost && openedPost.id ? openedPost.id : null
      };
      dispatch(setOpenedPost(post));
      handleCloseModal();
    }
  };

  const handleCloseElements = () => {
    setOpenElements(false);
    setOpenTemplate(false);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  /////uploadImages model open
  const handleAddImages = () => {
    setOpenAddImageDialog(true);
  };

  const renderBuyPremiumModal = () => {
    return (
      <Dialog style={{ padding: "10px" }}
        open={openElements || openTemplate}
        onClose={handleCloseElements}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"To unclock these images buy premium"}
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions style={{ paddingBottom: '20px' }}>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements}>Buy</Button>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid item xs={12} className="pageContainer" style={{ backgroundColor: "#f2f2f2" }}>
          <BlogHeader
            style={{ margin: "auto", display: "flex" }}
            saveBlog={saveBlog}
            publishBlog={publishBlog}
          />
          <Grid item xs={6} sm={6} display={{ xs: "block", sm: "block", md: "none", lg: "none" }} style={{ marginTop: "3rem" }} >
            <Button style={{ color: "#fff", background: '#1976d2', padding: '0.6rem', width: "60vw", borderRadius: '6px', marginTop: '5rem', marginBottom: "-5rem", marginLeft: '5rem' }}
              onClick={handleAddImages}
            >Add Images</Button>

            <Dialog style={{ margin: '0px' }}
              open={openAddImageDialog}
              onClose={() => setOpenAddImageDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', color: '#033463', fontSize: '30px' }}>
                {"Add Images"}
              </DialogTitle>
              <DialogContent>
                <Grid item sm={12} md={12} style={{ margin: "auto", display: "flex", }}>
                  <Grid item sm={12} md={12} xs={12} style={{ display: 'flex', width: "100vw", height: "15vh" }}>
                    <Grid item sm={3} md={12} xs={4} style={{ marginRight: '0.5rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <GoFileMedia
                          onClick={handleClickOpen("paper")}
                          style={{ color: "#fff" }}
                          className={classes.fileicons}
                        />
                      </Box>
                      <Typography className={classes.iconname}>Media</Typography>
                    </Grid>
                    <Grid item sm={3} md={12} xs={4}>
                      <Box className={classes.boxiconBlog}>
                        <FiUpload
                          className={classes.fileicons}
                          onClick={openFileSelector}
                        />
                      </Box>
                      <Typography className={classes.iconname}>Upload</Typography>
                    </Grid>
                    <Grid item sm={3} md={12} xs={4} style={{ marginLeft: '0.7rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <WorkspacePremiumIcon style={{ display: "flex", justifyContent: "center", height: "45px" }}
                          onClick={handleClickOpenElements("paper")}
                          className={classes.fileicons} />
                      </Box>
                      <Typography className={classes.iconname}>Premium <br />Images</Typography>
                    </Grid>
                    <Grid item sm={4} md={12} xs={4} style={{ marginLeft: '0.6rem' }}>
                      <Box className={classes.boxiconBlog}>
                        <CgMenuGridR
                          onClick={handleClickOpenTemplate("paper")}
                          className={classes.fileicons} />
                      </Box>
                      <Typography className={classes.iconname}>Premium <br />Stickers</Typography>
                    </Grid>

                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button style={{ background: "#1976d2", color: "#fff", width: '44vw', margin: 'auto' }}
                  onClick={() => setOpenAddImageDialog(false)}
                  autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
          <Grid container spacing={2} className="editorContainer">
            <Grid item xs={12} sm={12} md={1} className="toolbars">
              <Box className={classes.toolbarStyling}>
                <Box>
                  <BlockStyleToolbar
                    editorState={editorState}
                    onToggle={toggleBlockType}
                  />
                  <Box className={classes.underLineStyle}>
                    <button className="styleButton" onClick={onUnderlineClick}>
                      U
                    </button>
                    <button className="styleButton" onClick={onBoldClick}>
                      <b>B</b>
                    </button>
                    <button className="styleButton" onClick={onItalicClick}>
                      <em>I</em>
                    </button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sm={12}
              className="editors"
              style={{
                marginTop: "6rem",
                overflow: "auto",
              }}
            >
              <Editor
                blockStyleFn={getBlockStyle}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onChange={onChange}
                plugins={plugins}
              />
              <AlignmentTool />
            </Grid>
            <Grid item xs={2} md={1} sm={12} className={classes.iconsBoxed}
              style={{
                display: 'flex', justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Grid
                item
                sm={12}
                md={2}
                xs={12}
                style={{
                  marginTop: '6rem',
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <Box className="boxicon">
                  <GoFileMedia
                    style={{ color: "#fff" }}
                    className="fileicons"
                    onClick={handleClickOpen("paper")}
                  />
                </Box>
                <Typography className="iconname">Media</Typography>
                <Box className="boxicon">
                  <FiUpload
                    onClick={handleClickOpenUpload("paper")}
                    className="fileicons"
                  />
                </Box>
                <Typography className="iconname">Upload</Typography>


                <Box className="boxicon">
                  <WorkspacePremiumIcon
                    style={{ display: "flex", justifyContent: "center", height: "45px" }}
                    className="fileicons"
                    onClick={handleClickOpenElements("paper")}
                  />
                </Box>
                <Typography className="iconname">Premium<br /> Images</Typography>


                <Box className="boxicon">
                  <CgMenuGridR
                    style={{ color: "#fff" }}
                    className="fileicons"
                    onClick={handleClickOpenTemplate("paper")}
                  />
                </Box>
                <Typography className="iconname">Premium<br /> Stickers</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ borderRadius: "2rem !important" }}
          >
            <Dialog
              className={classes.dailogstyle}
              open={openUpload}
              onClose={handleCloseUpload}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleCloseUpload}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                  cursor: 'pointer'
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    onChange={searchContent}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
                <Button variant="contained" style={{ width: '35vw', padding: "10px", marginTop: '0.5rem', borderRadius: "20px" }}
                  onClick={openFileSelector}
                  modifier={imagePlugin.addImage}
                >Upload Media</Button>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Images
                  </Typography>
                  {/* <Typography>See all</Typography> */}
                </Box>
                <Box sx={{ width: '100%', minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                    {usersImages && usersImages.length > 0 ? usersImages.map((img, index) =>
                      <div key={index}>
                        {/* <Label>{index + 1}</Label> */}
                        <img
                          src={img}
                          alt='demo'
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={() => handleMediaClick(img)}
                        />

                      </div>
                    ) : null
                    }
                  </Masonry>
                </Box>

              </DialogContent>
            </Dialog>
          </Grid>
          <Grid
            container
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ borderRadius: "2rem !important" }}
          >
            <Dialog
              className={classes.dailogstyle}
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    onChange={searchContent}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Photos
                  </Typography>
                  {/* <Typography>See all</Typography> */}
                </Box>
                <Box sx={{ width: '100%', minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                    {gallery && gallery.length > 0 ? gallery.map((img, index) =>
                      <div key={index}>
                        {/* <Label>{index + 1}</Label> */}
                        <img
                          src={img}
                          // src={`${img}?w=162&auto=format`}
                          // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                          alt='demo'
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={() => handleMediaClick(img)}
                        />

                      </div>
                    ) : null
                    }
                  </Masonry>
                </Box>

              </DialogContent>
            </Dialog>

            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={blogInfoOpen}
            >
              <Stack>
                <Modal
                  open={blogInfoOpen}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} minwidth="md">
                    <Box style={{ position: "relative" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2} md={2} lg={2} style={{ width: '30vw', height: "30vh" }}>
                          <img
                            src={catimg}
                            alt="CatImg"
                            style={{
                              position: "absolute",
                              top: "-70px",
                              left: "-65px",
                              width: '30vw', height: "30vh"
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={8}
                          lg={8}
                          style={{ textAlign: "center" }}
                        >
                          <h2 style={{ color: "#1f108e" }}>Add Details</h2>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={2}
                          lg={2}
                          style={{ textAlign: "end" }}
                        >
                          <Button onClick={handleCloseModal}>Close</Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box component="form" noValidate autoComplete="off">
                      <Grid container spacing={2} marginBottom="1rem">
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                            Post Title
                          </label>
                          <TextField
                            className={classes.hkk}
                            fullWidth
                            type="text"
                            id="blogtitle"
                            label="Type here"
                            style={{ width: "100%", cursor: "pointer !important" }}
                            size="small"
                            marginBottom="2px"
                            onChange={(e) => setPostTitle(e.target.value)}
                          />
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[0] ? errors[0] : ""}
                          </span>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="posttitle" style={{ ...labelstyle }}>
                            Blog Title
                          </label>
                          <BlogSelectBlogTitle setBlogTitle={setBlogTitle} setBlogTitleId={setBlogTitleId} />
                          {/* <TextField
                        className={classes.hkk}
                        type="text"
                        fullWidth
                        size="small"
                        id="posttitle"
                        label="Type here"
                        onChange={(e) => setBlogTitle(e.target.value)}
                      /> */}
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[1] ? errors[1] : ""}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginBottom="1rem">
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="category" style={{ ...labelstyle }}>
                            <img src={categoryImg} alt="Category" /> Select Category
                          </label>
                          <TextField
                            fullWidth
                            id="category"
                            select
                            size="small"
                            label="Select"
                            onChange={handleCategoryChange}
                          >
                            {categories &&
                              categories.length > 0 &&
                              categories.map((option) => (
                                <MenuItem key={option.title} value={option.title}>
                                  {option.title}
                                </MenuItem>
                              ))}
                          </TextField>
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[2] ? errors[2] : ""}
                          </span>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="tags" style={{ ...labelstyle }}>
                            <img src={tagImg} alt="tags" />
                            Add Tags
                          </label>
                          <BlogMultiSelect tags={tags} setTag={setTag} />
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[3] ? errors[3] : ""}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginBottom="1rem">
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="category" style={{ ...labelstyle }}>
                            <img src={calenderImg} alt="Category" /> Set Date & Time
                          </label>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack spacing={3}>
                              <DateTimePicker
                                label="select date"
                                size="small"
                                value={date}
                                onChange={changeDate}
                                renderInput={(params) => (
                                  <TextField className={classes.hkk2} {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[4] ? errors[4] : ""}
                          </span>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <label htmlFor="comments" style={{ ...labelstyle }}>
                            <img src={commentImg} alt="comments" />
                            Comment (Yes/No)
                          </label>
                          <TextField
                            style={{ zIndex: "1" }}
                            fullWidth
                            id="comments"
                            select
                            size="small"
                            label="Select"
                            value={comment}
                            onChange={handleChange}
                          >
                            <MenuItem key={"1"} value={1}>
                              Yes
                            </MenuItem>
                            <MenuItem key={"0"} value={0}>
                              No
                            </MenuItem>
                          </TextField>
                          <span style={{ color: "red", fontSize: "11px" }}>
                            {errors[5] ? errors[5] : ""}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justifyContent="space-between">
                        <Grid item></Grid>
                        <Grid item style={{ cursor: "pointer" }}>
                          <Button
                            style={{
                              padding: "5px 20px",
                              borderRadius: "2rem",
                              backgroundColor: "#ffc107",
                              border: "none",
                              fontSize: "1.5rem",
                              color: "#1f108e",
                              cursor: "pointer !important",
                              zIndex: "1",
                            }}
                            onClick={handleOnClickNext}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Box style={{ position: "relative" }}>
                            <img
                              src={cloudImg}
                              alt=""
                              style={{
                                position: "absolute",
                                top: "-15px",
                                left: "-100px",
                                zIndex: "-1",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item textAlign="end">
                          <Box style={{ position: "relative" }}>
                            <img
                              src={ladyImg}
                              alt=""
                              style={{
                                position: "absolute",
                                bottom: "-170px",
                                right: "-175px",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Modal>
              </Stack>
            </Backdrop>
          </Grid>
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {
              isPremiumUser ?
                <Dialog
                  className={classes.dailogstyle}
                  open={openTemplate}
                  onClose={handleCloseTemplate}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  borderRadius="2rem"
                  sx={{
                    // width: "70%",
                    display: "flex",
                    justifyContent: "right",
                    paddingRight: "6rem",
                    // p: "2px 4px",
                  }}
                >
                  <ImCross
                    onClick={handleCloseTemplate}
                    style={{
                      position: "absolute",
                      right: 0,
                      margin: "10px",
                      color: "gray",
                    }}
                  />

                  <DialogTitle id="scroll-dialog-title">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "2rem",
                      }}
                    >
                      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by template"
                        inputProps={{ "aria-label": "Search by type" }}
                        // onChange={(e) => }
                        onKeyPress={(ev) => {

                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                          }
                        }}
                      />

                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    </Paper>
                  </DialogTitle>

                  <DialogContent dividers={scroll === "div"}>
                    <Grid spacing={2} xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        // textAlign="start"
                        component="div"
                        fontFamily="Museo700"
                        color="#1E3A71"
                      >
                        Sticker Images
                      </Typography>
                      <Box sx={{ width: '100%', minHeight: 829 }}>
                        <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                          {stickers && stickers.length > 0 ? stickers.map((img, index) =>
                            <div key={index}>
                              {/* <Label>{index + 1}</Label> */}
                              <img
                                src={img}
                                // src={`${img}?w=162&auto=format`}
                                // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                                alt='demo'
                                loading="lazy"
                                style={{
                                  borderBottomLeftRadius: 4,
                                  borderBottomRightRadius: 4,
                                  display: 'block',
                                  width: '100%',
                                }}
                                onClick={() => handleMediaClick(img)}
                              />

                            </div>
                          ) : null
                          }
                        </Masonry>
                      </Box>
                      {/* <Grid container spacing={3}>

                    {
                      stickers && stickers.length > 0 ? stickers.map((img, index) =>
                        <Grid item sm={4} sx={12}>
                          <img style={{ width: "100%", height: '100%', cursor: "pointer" }}
                            key={index}
                            component="img"
                            src={img}
                            alt="green iguana"
                            onClick={() => handleMediaClick(img)}
                          />
                        </Grid>
                      ) : null
                    }
                  </Grid> */}
                    </Grid>
                  </DialogContent>
                </Dialog>
                :
                renderBuyPremiumModal()
            }

          </Grid>
          {/* Elements dialog */}
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {
              isPremiumUser ?
                <Dialog
                  className={classes.dailogstyle}
                  open={openElements}
                  onClose={handleCloseElements}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  borderRadius="2rem"
                  sx={{
                    // width: "70%",
                    display: "flex",
                    justifyContent: "right",
                    paddingRight: "6rem",
                    // p: "2px 4px",
                  }}
                >
                  <ImCross
                    onClick={handleCloseElements}
                    style={{
                      position: "absolute",
                      right: 0,
                      margin: "10px",
                      color: "gray",
                    }}
                  />

                  <DialogTitle id="scroll-dialog-title">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "2rem",
                      }}
                    >
                      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by type"
                        inputProps={{ "aria-label": "Search by type" }}

                        onKeyPress={(ev) => {

                          if (ev.key === 'Enter') {
                            // Do code here
                            ev.preventDefault();
                          }
                        }}
                      />

                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    </Paper>
                  </DialogTitle>

                  <DialogContent dividers={scroll === "div"}>
                    <Grid spacing={3} xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        // textAlign="start"
                        component="div"
                        fontFamily="Museo700"
                        color="#1E3A71"
                      >
                        Premium Images
                      </Typography>
                      <Box sx={{ width: '100%', minHeight: 829 }}>
                        <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                          {premiumImages && premiumImages.length > 0 ?
                            premiumImages.map((image, index) =>
                              <div key={index}>
                                {/* <Label>{index + 1}</Label> */}
                                <img
                                  src={image}
                                  // src={`${img}?w=162&auto=format`}
                                  // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                                  alt='demo'
                                  loading="lazy"
                                  style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                  }}
                                  onClick={() => handleMediaClick(image)}

                                />

                              </div>
                            ) : null
                          }
                        </Masonry>
                      </Box>
                    </Grid>
                  </DialogContent>
                </Dialog> :
                renderBuyPremiumModal()
            }
          </Grid>
        </Grid>
      </Grid>
    </Main>

  );
};

export default BlogEditor;
