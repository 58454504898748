import { actionTypes } from "../actions";

export const INITIAL_STATE = {
   openedAspirations: {},
   savedAspirations: {}
};

export const aspirations = (state = INITIAL_STATE, action) => {
   const { type, payload } = action;
   switch (type) {
      case actionTypes.SET_GET_ASPIRATIONS:
         return {
            ...state,
            savedAspirations: payload,
         };
      case actionTypes.SET_ADD_ASPIRATIONS:
         return {
            ...state,
            openedAspirations: payload,
         };
      default:
         return state;
   }
};