import React from "react";
// material
import { Grid, Stack, Box } from "@mui/material";
// ------
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Postcardsend from "./Postcardsend";

export default function PostcardSendData() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion elevation={0}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 style={{ color: "#1f108e" }}>Drafts</h3>
      </AccordionSummary>
      <AccordionDetails>
        <Postcardsend></Postcardsend>
      </AccordionDetails>
    </Accordion>
  );
}
