import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Box } from "@mui/system";
import CampaignIcon from '@mui/icons-material/Campaign';
import AddIcon from '@mui/icons-material/Add';
import ActivityBar from "../ActivityBar";
import PieChartPage from "../PieChartPage";
import { useDispatch, useSelector } from "react-redux";
import { getCounts } from "../../store/actions/siteInsights";
import AnnouncementsTable from "./Table/AnnouncementsTable";
import ComplaintsTable from "./Table/ComplaintsTable";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const SiteActivity = (props) => {
   const dispatch = useDispatch()
   const { studentsCount, employeesCount, reviewReceived, reviewEvaluated } = useSelector((state) => state.siteInsights)
   const [postActivityDataPoints, setPostActivityDataPoints] = useState([])
   const [groupActivityDataPoints, setGroupActivityDataPoints] = useState([])

   useEffect(() => {
      let data = [
         { y: studentsCount, label: "Students" },
         { y: employeesCount, label: "Employees" },
         { y: reviewReceived, label: "Req Received" },
         { y: reviewEvaluated, label: "Req Evaluated" },
      ]
      setPostActivityDataPoints([...data])

      setGroupActivityDataPoints([
         { y: 50, label: "Students" },
         { y: 22, label: "Employees" },
         { y: 15, label: "Req Received" },
         { y: 5, label: "Req Evaluated" },
      ])
   }, [])
   return (
      <Grid container item xs={11} md={11} lg={11} style={{ margin: "auto", height: "100vh" }}>
         <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12} md={3} >
               <Grid container spacing={2}>
                  <Grid item xs={12} md={6}  >
                     <Box style={{ background: "lightblue", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {studentsCount}
                        </Typography>
                        <Typography>
                           Active
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Students
                        </Typography>
                     </Box>

                  </Grid>
                  <Grid item xs={12} md={6} >
                     <Box style={{ background: "#ec572840", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {employeesCount}
                        </Typography>
                        <Typography>
                           Active
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Employees
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={6} >
                     <Box style={{ background: "#ffc1078c", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {reviewReceived}
                        </Typography>
                        <Typography>
                           Review Requests
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Received
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <Box style={{ background: "#8bc34a9c", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {reviewEvaluated}
                        </Typography>
                        <Typography>
                           Review requests
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Evaluated
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12} md={7} lg={7}>
               <ActivityBar />
            </Grid>

            <Grid item xs={12} md={2} >
               <PieChartPage title="Post Activity" dataPoints={postActivityDataPoints} />
            </Grid>

            {/* <Grid item xs={12} md={2} >
               <PieChartPage title="Group Activity" dataPoints={groupActivityDataPoints} />
            </Grid> */}

         </Grid>

         <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12} md={12}>
               <Grid container spacing={2}>
                  <Grid item spacing={1} xs={12} md={6}  >
                     <Grid container style={{ background: "lightgray", borderRadius: '10px', textAlign: "center", padding: '20px' }} >
                        <Grid container style={{ padding: "5px", display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                           <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                              <CampaignIcon style={{ marginRight: "0.5rem", fontSize: "30px" }} />
                              <Typography style={{ fontSize: "20px", fontWeight: "600", color: "#1e3a71" }}>
                                 Announcements
                              </Typography>
                           </Grid>
                           <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "end" }}>
                              <Button variant="contained" startIcon={<AddIcon />} style={{ borderRadius: "20px", background: "#ffc107", color: "#1e3a71", fontWeight: "600" }}>
                                 Create New
                              </Button>
                           </Grid>
                        </Grid>

                        <Grid item md={12} lg={12}>
                           <AnnouncementsTable />
                        </Grid>

                     </Grid>

                  </Grid>
                  <Grid item spacing={1} xs={12} md={6}  >
                     <Grid container style={{ background: "lightgray", borderRadius: '10px', textAlign: "center", padding: '20px' }} >
                        <Grid container style={{ padding: "5px", display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                           <Grid item md={5} lg={5} style={{ display: "flex", justifyContent: "flex-start" }}>
                              {/* <CampaignIcon style={{ marginRight: "0.5rem" }} /> */}
                              <PrivacyTipIcon style={{ marginRight: "0.5rem" }} />

                              <Typography style={{ fontWeight: "600", color: "#1e3a71" }}>
                                 User Complaints
                              </Typography>
                           </Grid>
                           <Grid item md={7} lg={7} style={{ display: "flex", justifyContent: "space-around", }}>
                              <Typography style={{ fontWeight: "600", color: "#1e3a71", marginTop: "0.4rem" }}>
                                 Sort By
                              </Typography>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 Open
                              </Button>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 In-Progress
                              </Button>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 Closed
                              </Button>
                           </Grid>
                        </Grid>

                        <Grid item md={12} lg={12}>
                           <ComplaintsTable />
                        </Grid>

                     </Grid>

                  </Grid>

               </Grid>
            </Grid>
         </Grid>

      </Grid>


   );
}

export default SiteActivity;
