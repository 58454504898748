import React from "react"
// Button from MUI
import Button from '@mui/material/Button';

export default function CustomButton({ customStyles, buttonText }) {
  const defaultStyles = {
    borderRadius: '1rem',
    backgroundColor: 'white',
    fontWeight: 700,
    color: '#001f58'
  };

  return (
    <Button style={{ ...defaultStyles, ...customStyles }} variant="contained">
      {buttonText}
    </Button>
  );
}
