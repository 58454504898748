import React from "react"
import IconButton from '@mui/material/IconButton';
import HoverImage from "react-hover-image";
import Stack from '@mui/material/Stack';

export default function IconCircle({ IconCompSrc, HoverCompSrc, DispTitle }) {
  // IconComp is the Icon Component to be displayed over here...
  // DispTitle is the title which needs to be displayed over here...
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" minWidth="fit-content">
      <IconButton sx={{ backgroundColor: 'rgba(256, 256, 256, 0.2)', width:"40px", height:"40px"}}>
        <HoverImage src={IconCompSrc} hoverSrc={HoverCompSrc} />
        </IconButton>
      <div style={{ color: 'white', fontWeight: 700, marginTop: '0.2rem' }}>{DispTitle}</div>
    </Stack>
  );
}
