import React, { useState, useEffect } from "react";
import { Grid, Container, Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";

import AppCreate from "./essaySections/AppCreate";
import AppDrafts from "./essaySections/AppDrafts";
import AppPublished from "./essaySections/AppPublished";
import AppReviewInProgress from "./essaySections/AppReviewInProgress";
import Footer from "../Footer";
import plusicon from "../../assets/images/Blog/BD/Icon feather plus.png";

import AppPremiumBadge from "../Dashboard/sections/AppPremiumBadge";
import PoemEditorStyles from "./EssayEditorStyles";
import CreatefabButton from "../Dashboard/CreateFabButton";
import AppReviewCompleted from "./essaySections/AppReviewCompleted";

import Navbar from "../Navbar";
import Main from "../Main";


const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

function Poem(props) {
  const classes = PoemEditorStyles();

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>

        <Container maxWidth="xl">
          <Grid container spacing={2} className={classes.mainContainerStyle}>
            <Grid item xs={12} md={6} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={12}>
                  <AppCreate></AppCreate>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Stack>
                    <div>
                      <Button
                        onClick={() => props.history.push('/essay-editor')}
                        style={{
                          width: "100%",
                          padding: "1rem",
                          borderRadius: "1rem",
                          backgroundColor: "#ffc107",
                          border: "none",
                          color: "white",
                          fontSize: '18px',
                          fontFamily: "Museo700",
                        }}
                      >
                        Create New <span> </span>
                        <img
                          src={plusicon}
                          alt=""
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppDrafts></AppDrafts>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppReviewCompleted />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppReviewInProgress />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppPublished></AppPublished>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={12}>
                <AppComments></AppComments>
              </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={2}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AppPremiumBadge />
                  {/* <AppProgress></AppProgress> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.hiddensection}
                >
                  <Box className={classes.hidenImg}>
                    <img
                      src={add2}
                      alt="hi"
                      width="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img
                      src={add1}
                      alt="hi"
                      width="100%"
                      className={classes.hidenImg1}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Box className={classes.createFabStyles} >
              <CreatefabButton />
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Main>
  );
}

export default Poem;
