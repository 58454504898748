import { createTheme } from '@mui/material/styles';
import Museo300 from './assets/fonts/Museo300-Regular.otf';
const theme = createTheme({
  palette: {
    background: {
      // default: '#E5E5E5',
      default: '#000',
    },
  },
  typography: {
    fontFamily: 'Museo300',
  },
  overrides: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Museo';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Museo'), local('Museo-Regular'), url(${Museo300}) format('otf');
        }
      `,
      '@global': {
        body: {
          // backgroundImage: `url(${BackLogo})`,
          backgroundRepeat: 'no-repeat',
          // minHeight: '1200px',
        },
      },
    },
  },
});

export default theme;