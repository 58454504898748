import React, { useState, useEffect, useRef, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../config/fbConfig";
import {
  setPostCardDetails,
  getSentPostCards,
  getReceivedPostCards,
  setPostCardImage,
  saveDraftPostCard,
  setOpenedPostCard
} from "../../store/actions/postCards";
import {
  getOpenedUsersImages, getOpenedGallery, getPremiumImages, getStickers
} from "../../store/actions/images";
import InfoIcon from '@mui/icons-material/Info';

import { showErrorSnackbar } from "../../store/actions/snackbar";
import { Box, Grid, Paper, Card, Tooltip, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Postcardheader from "./PostCardHeader";
import { GoFileMedia } from "react-icons/go";
import PostCardStyles from "./PostCardStyles";
import { useFilePicker } from "use-file-picker";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { convertToHTML, convertFromHTML } from 'draft-convert';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';

import { FiUpload } from "react-icons/fi";
import { ImCross } from "react-icons/im";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import htmlToDraft from 'html-to-draftjs';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { CgMenuGridR } from "react-icons/cg";
import { getSubscription, getUserSubscription, addUserSubscription } from "../../store/actions/subscriptions";
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import EditIcon from '@mui/icons-material/Edit';
import draftToHtml from 'draftjs-to-html';

import Navbar from "../Navbar";
import Main from "../Main";


const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

class ColorPic extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  // componentDidMount() {
  //   const { onChange } = this.props;
  //   onChange('color', "#f47373");
  //   let color = {
  //     hex: '#f47373'
  //   }
  //   this.onChange(color)
  // }
  stopPropagation = (event) => {
    event.stopPropagation();
  };

  onChange = (color) => {
    const { onChange } = this.props;
    onChange('color', color.hex);
  }

  renderModal = () => {
    const { color } = this.props.currentState;
    return (
      <div
        onClick={this.stopPropagation}
        style={{ marginLeft: '-70px' }}
      >
        <BlockPicker color={color} onChangeComplete={this.onChange} />
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;
    return (
      <div style={{ position: 'absolute', width: '9%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div style={{ position: 'absolute' }}

          aria-haspopup="true"
          aria-expanded={expanded}
          aria-label="rdw-color-picker"
        >
          <div
            onClick={onExpandEvent}
          >
            <EditIcon sx={{ color: '#431ba5', fontSize: '2rem', }} />
            {/* <img
            src={EditIcon}
            alt=""
          /> */}
          </div>
          {expanded ? this.renderModal() : undefined}
        </div>
      </div>
    );
  }
}


const PostCardEditor = (props) => {
  const classes = PostCardStyles();
  const dispatch = useDispatch();
  const [postcardContentData, setpostcardContentData] = useState({});
  // const [postCardImageData, setPostCardImageData] = useState("");
  const [email, setEmail] = useState([]);
  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])
  const [details, setDetails] = useState({ content: "", name: "", });

  const previewPost = useSelector((state) => state.postcards.postCard);
  const draftEdit = useSelector((state) => state.postcards.draftEdit);
  const openedPostCard = useSelector((state) => state.postcards.openedPostCard);
  const { subscriptions, userSubscription, isPremiumUser, userStatusUpdated } = useSelector((state) => state.subscriptions);

  const auth = useSelector(state => state.firebase.auth);
  const { gallery, usersImages, premiumImages, stickers } = useSelector(state => state.images);
  const [images, SetImages] = useState([])
  const [gotImages, updateGotImages] = useState(0)
  const [isMediaSelected, setIsMediaSelected] = useState(false)
  const [mediaUrl, setMediaUrl] = useState('')

  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
    multiple: false, readAs: "DataURL", accept: 'image/*',
  });

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorStateArray, setEditorStateArray] = useState([editorState])
  const [editorStateAsHTML, setEditorStateAsHTML] = useState(null)
  const [hasTextStatus, setHasTextStatus] = useState(false)

  const descriptionElementRef = useRef(null);
  //dialog media

  const [open, setOpen] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openElements, setOpenElements] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [mosaicProps, setMosaicProps] = useState(null)

  useEffect(() => {
    if (!userStatusUpdated)
      dispatch(getUserSubscription())

    try {
      let uid = auth?.uid
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages))
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages))
      dispatch(getStickers())
      dispatch(getPremiumImages())
    } catch (e) {
      console.log("Error --- ", e)
    }
  }, []);

  useEffect(() => {
    var imagesArray = []
    if (usersImages.length > 0) {
      imagesArray = imagesArray.concat(usersImages)

    }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery)
    }
    setMosaicProps({
      images: imagesArray,
      // mainImagePath: './assets/img4.jpg',
      width: 800,
      height: 600,
      maxBlocksInRow: 5,
      spaceBetween: 5,
      mainImageIndex: 1,
      mainImageStyle: { opacity: 0 },
      secondaryImageStyle: { maxHeight: '400px' },
      onClick: (e, url) => window.open(url, '_blank')
    })
    SetImages(imagesArray);
  }, [gotImages, usersImages, props.images]);



  /////Media model open
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  //upload modal
  const handleClickOpenUpload = (scrollType) => () => {
    setOpenUpload(true);
    setScroll(scrollType);
  };

  /////Template model open
  const handleClickOpenTemplate = (scrollType) => () => {
    setOpenTemplate(true);
    setScroll(scrollType);
  };

  /////Elements model open
  const handleClickOpenElements = (scrollType) => () => {
    setOpenElements(true);
    setScroll(scrollType);
  };

  /////Media model close
  const handleClose = () => {
    setOpen(false);
  };

  //upload images modal close//
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  /////template model close
  const handleCloseTemplate = () => {
    setOpenTemplate(false);
  };

  /////Elements model close
  const handleCloseElements = () => {
    setOpenElements(false);
  };


  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //Start Editor


  const onEditorStateChange = (editorCurrentState) => {
    setEditorState(editorCurrentState);
    let dataStatus = editorCurrentState.getCurrentContent()
    const rawContentState = convertToRaw(editorCurrentState.getCurrentContent());

    const markup = draftToHtml(rawContentState);

    // let htmlData = convertToHTML(dataStatus)

    setEditorStateAsHTML(markup)
    let status = dataStatus.hasText()
    setHasTextStatus(status)
  };
  //end editor

  const undoAction = () => {
    if (editorState.length < 1)
      return
    let index = editorStateArray.findIndex((e) => JSON.stringify(e) == JSON.stringify(editorState))
    if (index > -1)
      setEditorState(editorStateArray[index - 1])
  }

  useEffect(() => {
    setIsMediaSelected(false)
    setMediaUrl('')
    setFiles(filesContent)
    setUploadFiles(plainFiles)
  }, [plainFiles])

  useEffect(() => {
    dispatch(getReceivedPostCards(auth));
    dispatch(getSentPostCards(auth));
    if (postcardContentData) {
      setpostcardContentData(postcardContentData);
    }
  }, [postcardContentData]);

  useEffect(() => {
    if (previewPost.length !== 0) {
      setDetails({ content: previewPost.content, name: previewPost.name });
      if (previewPost.to !== undefined) {
        previewPost.to.map((item) => {
          email.push(item.email);
        });
      }
    }
  }, [previewPost]);

  const handleDetails = (e) => {
    if (e.target.value.length <= 400) {
      const { name, value } = e.target;
      setDetails({ ...details, [name]: value });
    } else {
      dispatch(showErrorSnackbar("Please enter less than 400 characters"));
    }
  };

  const uploadFile = () => {
    if (mediaUrl && isMediaSelected) {
      onSave(mediaUrl)
    } else if (mediaUrl && draftEdit) {
      onSave(mediaUrl)
    } else {
      let image = plainFiles[0] //e.target.files[0];
      let userId = auth.uid;
      storage
        .ref(`/users/${userId}/${image.name}`)
        .put(image)
        .on("state_changed", () => {
          storage
            .ref(`/users/${userId}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async (url) => {
              // setPostCardImageData(url);
              dispatch(setPostCardImage(url));

              onSave(url)
            });
        });
    }
  };

  const onSave = async (url) => {
    var date = new Date()
    var sample = [];
    let email = auth.email ? auth.email : 'test@quillatwill.com'
    let userName = email.substring(0, email.lastIndexOf("@"))

    const postCard = {
      content: editorStateAsHTML,
      name: auth.displayName ? auth.displayName : userName,
      userId: auth.uid,
      from: auth.email,
      to: [],
      image: url,
      date: date.toString(),
      id: openedPostCard && openedPostCard.id ? openedPostCard.id : null,
      isPublished: false
    }

    dispatch(setPostCardDetails(postCard))
    dispatch(saveDraftPostCard(postCard))
  }

  const removeFile = () => {
    setIsMediaSelected(false)
    setMediaUrl('')
    setFiles([])
    setUploadFiles([])
    try {
      let openedPostCardData = {
        ...openedPostCard,
        image: ''
      }
      dispatch(setOpenedPostCard(openedPostCardData, true))
    } catch (error) {

    }

  }

  const clearFilePicker = () => {
    clear()
    setFiles([])
    setUploadFiles([])
    setMediaUrl('')
    setIsMediaSelected(false)
  }
  const clearEditor = () => {
    setEditorState(() => EditorState.createEmpty())
  }

  useEffect(() => {
    if (draftEdit) {
      if (openedPostCard && openedPostCard.content) {
        const blocksFromHTML = htmlToDraft(openedPostCard.content);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorStateData = EditorState.createWithContent(state)
        setEditorState(editorStateData)
        let dataStatus = editorStateData.getCurrentContent()
        let htmlData = convertToHTML(dataStatus)
        setEditorStateAsHTML(htmlData)
      }

      setMediaUrl(openedPostCard?.image)
      setIsMediaSelected(true)
      setHasTextStatus(true)
    }
  }, [draftEdit])

  //Search code start//
  const searchContent = (e) => {
    if (e && e.target.value && e.target.value.length > 0) {
      let imagesArray = images.filter((img) => img.toLowerCase().includes(e.target.value.toLowerCase()))
      SetImages(imagesArray)
    } else {
      let imagesArray = []
      if (usersImages.length > 0) {
        imagesArray = imagesArray.concat(usersImages)

      }
      if (gallery.length > 0) {
        imagesArray = imagesArray.concat(gallery)
      }
      SetImages(imagesArray);
    }
  }

  const handleMediaClick = (url) => {
    setFiles([])
    setUploadFiles([])
    setIsMediaSelected(true)
    setMediaUrl(url)
    setOpen(false)
  }

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid item xs={12}>
          <Postcardheader
            style={{ margin: "auto", display: "flex" }}
            onSave={uploadFile}
            setEmail={setEmail}
            email={email}
            clearFilePicker={clearFilePicker}
            clearEditor={clearEditor}
            editorStateAsHTML={editorStateAsHTML}
            plainFiles={uploadFiles}
            files={files}
            // setPostCardImageData={setPostCardImageData}
            hasTextStatus={hasTextStatus}
            mediaUrl={mediaUrl}
            isMediaSelected={isMediaSelected}
          />

          <Box sx={{ flexGrow: 1 }} style={{ display: "flex", paddingTop: "2rem", background: '#F6F6F9' }}>
            <Grid
              container
              style={{
                marginTop: '3rem',
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid container item xs={12} md={12} sm={12} style={{ height: '35px', maxWidth: '80%', marginLeft: '10%' }}>
                <Grid item xs={12} sm={12} md={12} style={{ display: 'none', flexDirection: 'row', justifyContent: 'left' }}>
                  <UndoIcon style={{ cursor: 'pointer', fontSize: 40 }} onClick={undoAction} />
                  <RedoIcon style={{ marginLeft: '2vw', cursor: 'pointer', fontSize: 40 }} />
                </Grid>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between', width: '70%', marginTop: '2rem' }}
                  className={classes.addTextStyle}
                >
                  <Grid item md={3} lg={3} style={{ textAlign: 'center', display: 'flex', justifyContent: 'end' }}>
                    <Typography>
                      Add Text
                    </Typography>
                  </Grid>
                  <Grid item md={3} lg={3} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <Typography>
                      Add Picture
                    </Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} md={5} sm={12}
                  style={{
                    display: "flex",
                    margin: "auto",
                    textAlign: "end",
                    justifyContent: "end",
                    paddingRight: '2vw', marginTop: '-3rem'
                  }}>
                  <Box className={classes.delteStyle} style={{ display: 'flex', justifyContent: 'end' }}
                  // style={{ cursor: 'pointer', paddingBottom: '30px',marginRight:'-14rem' }}
                  >
                    <DeleteIcon
                      style={{ cursor: 'pointer', fontSize: 30, marginTop: '1rem', textAlign: 'center', paddingTop: '-10px', position: 'relative' }}
                      onClick={removeFile}

                    />
                    <Grid item xs={12} md={1} >

                      <Tooltip title="Click on the Media to select a pic from QAW gallery or click on “Upload” to add a picture from your device for your postcard.
              ">
                        <IconButton style={{ color: '#23348f', marginTop: '0.8rem' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                  </Box>
                  {/* <PersonAddAltRoundedIcon style={{ marginLeft: '2vw', fontSize: 30, cursor: 'pointer', }} /> */}
                </Grid>
              </Grid>

              <Grid item sm={12} md={6} xs={12}
                className={classes.cardEditorStyle}
              // style={{
              //   display: 'flex',
              //   flexDirection: 'column', justifyContent: 'center', padding: 30,
              //   marginTop: '-30px'
              // }}

              >
                <Editor
                  editorState={editorState}
                  // toolbarClassName={draftEdit ? classes.toolbarHiddenClassName : classes.toolbarClassName}
                  toolbarClassName={classes.toolbarClassName}
                  wrapperClassName={classes.wrapperClassName}
                  editorClassName={classes.editorClassName}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    colorPicker: { component: ColorPic },
                  }}

                  handleBeforeInput={val => {
                    const textLength = editorState.getCurrentContent().getPlainText().length;
                    if (val && textLength >= 600) {
                      return (
                        alert('Alert, Characters limits only 600 ')
                      );
                    }
                    return 'not-handled';
                  }}
                  handlePastedText={val => {
                    const textLength = editorState.getCurrentContent().getPlainText().length;
                    return ((val.length + textLength) >= 600);
                  }}
                  onBlur={(event, editorState) => { }}
                />
              </Grid>

              <Grid item sm={12} md={4} xs={12} style={{ padding: '30px 0px', paddingRight: '20px', marginTop: '-30px' }}>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    textAlign: "center",

                  }}

                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: '35vw',
                      // height: '35vw',
                      // boxShadow: "0px 10px 20px #d3e0ff",


                    },
                  }}
                >
                  <Paper style={{ overflow: 'hidden', marginTop: '2rem', minHeight: '72vh', width: '100%' }}>
                    {files && files.length > 0 ?
                      files.map((file, index) =>
                        <>
                          <div
                            style={{
                              textAlign: "center",
                              width: "80%",
                            }}
                          >
                            {/* <button onClick={() => removeFile(index)}>Remove File</button> */}
                          </div>
                          <img
                            style={{ width: "100%", height: '100%', objectFit: 'fill', display: 'flex', justifyContent: 'center' }}
                            src={file.content}
                            alt={file.name}
                          />
                          <br /><br />
                          {/* <button onClick={() => uploadFile(index)}>Upload</button> */}
                        </>
                      )
                      :
                      isMediaSelected && mediaUrl ?
                        <img
                          style={{ width: "100%", height: '100%', objectFit: 'fill', display: 'flex', justifyContent: 'center' }}
                          src={mediaUrl}
                          alt="image"
                        />
                        :
                        draftEdit && openedPostCard && openedPostCard.image ?
                          <img
                            style={{ width: "100%", height: '100%', objectFit: 'fill', display: 'flex', justifyContent: 'center' }}
                            src={openedPostCard.image}
                            alt="image"
                          /> :
                          <Typography style={{ margin: "auto", marginTop: 50 }}>
                            Add Picture
                          </Typography>
                    }
                  </Paper>
                </Box>
              </Grid>

              <Grid item sm={12} xs={12} md={2}>
                {/* <Box className={classes.boxicon}>
              <CgFile
                onClick={handleClickOpenTemplate("paper")}
                className={classes.fileicons}

              />
            </Box>
            <Typography className={classes.iconname}>Template</Typography> */}

                {/* <Box className={classes.boxicon}>
              <RiText className={classes.fileicons} />
            </Box>
            <Typography className={classes.iconname}>Text</Typography> */}

                <Box className={classes.boxicon}>
                  <GoFileMedia
                    onClick={handleClickOpen("paper")}
                    style={{ color: "#fff" }}
                    className={classes.fileicons}
                  />
                </Box>
                <Typography className={classes.iconname}>Media</Typography>

                {/* <div className={classes.App}>
              <Box
                className={classes.mw400center}
                style={{ textAlign: "left" }}
              >
                <div>
                  <input type="file" onChange={onChange} />
                </div>
              </Box>
            </div> */}

                {/* //Element code// */}
                {/* 
            <Box className={classes.boxicon}>
              <CgMenuGridR
                onClick={handleClickOpenElements("paper")}
                className={classes.fileicons} />
            </Box>
            <Typography className={classes.iconname}>Elements</Typography> */}

                <Box className={classes.boxicon}>
                  <FiUpload
                    className={classes.fileicons}
                    onClick={handleClickOpenUpload("paper")}

                  />
                </Box>

                <Typography className={classes.iconname}>Upload</Typography>

                <Box className={classes.boxicon}>
                  <WorkspacePremiumIcon style={{ display: "flex", justifyContent: "center", height: "45px" }}
                    onClick={handleClickOpenElements("paper")}
                    className={classes.fileicons} />
                </Box>
                <Typography className={classes.iconname}>Premium <br />Images</Typography>
                <Box className={classes.boxicon}>
                  <CgMenuGridR
                    onClick={handleClickOpenTemplate("paper")}
                    className={classes.fileicons} />
                </Box>
                <Typography className={classes.iconname}> Premium<br /> Stickers</Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ borderRadius: "2rem !important" }}>
            <Dialog
              className={classes.dailogstyle}
              open={openUpload}
              onClose={handleCloseUpload}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleCloseUpload}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                  cursor: 'pointer'
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    onChange={searchContent}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
                <Button variant="contained" style={{ width: '35vw', padding: "10px", marginTop: '0.5rem', borderRadius: "20px" }}
                  onClick={openFileSelector}
                // modifier={imagePlugin.addImage}
                >Upload Media</Button>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Images
                  </Typography>
                  {/* <Typography>See all</Typography> */}
                </Box>
                <Box sx={{ width: '100%', minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                    {usersImages && usersImages.length > 0 ? usersImages.map((img, index) =>
                      <div key={index}>
                        {/* <Label>{index + 1}</Label> */}
                        <img
                          src={img}
                          alt='demo'
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={() => handleMediaClick(img)}
                        />

                      </div>
                    ) : <></>
                    }
                  </Masonry>
                </Box>

              </DialogContent>
            </Dialog>
          </Grid>

          {/* Media dialog */}
          <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ borderRadius: "2rem !important" }}>
            <Dialog
              className={classes.dailogstyle}
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: '1rem !important',
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",

                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    // p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    onChange={searchContent}
                    onKeyPress={(ev) => {

                      if (ev.key === 'Enter') {
                        // Do code here
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Photos
                  </Typography>
                </Box>

                <Box sx={{ width: '100%', minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                    {images && images.length > 0 ? images.map((img, index) =>
                      <div key={index}>
                        <img
                          src={img}
                          alt='demo'
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={() => handleMediaClick(img)}
                        />

                      </div>
                    ) : <></>
                    }
                  </Masonry>
                </Box>
              </DialogContent>
            </Dialog>
            {/* </Box> */}
          </Grid>

          {/* template dialog */}
          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12}>
            <Dialog
              className={classes.dailogstyle}
              open={openTemplate}
              onClose={handleCloseTemplate}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              borderRadius="2rem"
              sx={{
                // width: "70%",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
                // p: "2px 4px",
              }}
            >
              <ImCross
                onClick={handleCloseTemplate}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                  cursor: 'pointer'
                }}
              />

              <DialogTitle id="scroll-dialog-title">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by template"
                    inputProps={{ "aria-label": "Search by type" }}
                    // onChange={(e) => }
                    onKeyPress={(ev) => {

                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                      }
                    }}
                  />

                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                </Paper>
              </DialogTitle>

              <DialogContent dividers={scroll === "div"}>
                <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    // textAlign="start"
                    component="div"
                    fontFamily="Museo700"
                    color="#1E3A71"
                  >
                    Sticker Images
                  </Typography>
                  <Box
                    spacing={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={3}>

                      {
                        stickers && stickers.length > 0 ? stickers.map((img, index) =>
                          <Grid key={index} item sm={4} xs={12}>
                            <img style={{ width: "100%", height: '100%', cursor: "pointer" }}
                              key={index}
                              component="img"
                              src={img}
                              alt="green iguana"
                              onClick={() => handleMediaClick(img)}
                            />
                          </Grid>
                        ) : null
                      }
                    </Grid>
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12}>
            <Dialog

              className={classes.dailogstyle}

              open={openElements}
              onClose={handleCloseElements}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              borderRadius="2rem"
              sx={{
                // width: "70%",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
                // p: "2px 4px",
              }}
            >
              <ImCross
                onClick={handleCloseElements}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle id="scroll-dialog-title">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}

                    onKeyPress={(ev) => {

                      if (ev.key === 'Enter') {
                        // Do code here
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
              </DialogTitle>

              <DialogContent dividers={scroll === "div"}>
                <Grid container spacing={3} item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    fontFamily="Museo700"
                    color="#1E3A71"
                  >
                    Premium Images
                  </Typography>
                  <Box sx={{ width: '100%', minHeight: 829 }}>
                    <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                      {premiumImages && premiumImages.length > 0 ?
                        premiumImages.map((image, index) =>
                          <div key={index}>
                            <img
                              src={image}
                              alt='demo'
                              loading="lazy"
                              style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                display: 'block',
                                width: '100%',
                              }}
                              onClick={() => handleMediaClick(image)}

                            />

                          </div>
                        ) : <></>
                      }
                    </Masonry>
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </Main>
  );
};

export default PostCardEditor;
