import { actionTypes } from '../actions';

export const INITIAL_STATE = {
    OpenedRiddle: {
        riddle: null,
        answer: null,
        start: null,
        end: null
    }
};

export const riddles = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_OPENED_RIDDLE:
            return {
                ...state,
                OpenedRiddle: payload,
            };
        default:
            return state;
    }
};
