import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	studentsCount: 0,
	employeesCount: 0,
	reviewReceived: 0,
	reviewEvaluated: 0,
	totalBlogsCount: 0,
	totalPoemsCount: 0,
	totalStoryCount: 0,
	totalBooksCount: 0
};

export const siteInsights = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.SET_STUDENTS_COUNT:
			return {
				...state,
				studentsCount: payload
			}
		case actionTypes.SET_EMPLOYEES_COUNT:
			return {
				...state,
				employeesCount: payload
			}
		case actionTypes.SET_BLOGS_COUNT:
			return {
				...state,
				totalBlogsCount: payload
			}
		default:
			return state;
	}
};


