import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import add1 from "../../assets/images/dashboard/1.png";
import add2 from "../../assets/images/dashboard/2.png";
import Posts from './Posts';
import Pagination from '@mui/material/Pagination';
import { useFirestoreConnect } from "react-redux-firebase";
import Main from "../Main";

import {
  Container,
  Grid,
  Box,
  Tab,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FaRegComment } from "react-icons/fa";
import Stack from "@mui/material/Stack";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import Carousel from "react-elastic-carousel";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import LibraryStyles from "./LibraryStyles";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import hands from "../../assets/images/Blog/BD/Group 72.png";
import LibraryButton from "./LibraryButton";
import {
  getLikedPost,
  getTrendingPosts,
  getWallOfFamePosts,
  getGlobalPosts
} from "../../store/actions/posts";
import { setSearchQuery } from "../../store/actions/library";
import { getPoems, setShowPoems } from "../../store/actions/poems";
import Navbar from "../Navbar";
import { setActivePage } from "../../store/actions/user";

const theme = createTheme();

const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
}

const Library = () => {
  const classes = LibraryStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [topics, setTopics] = useState([]);
  const [value, setValue] = useState("0");
  const [postCount, setPostCount] = useState(0)
  const [showPostCount, setShowPostCount] = useState(12)
  const [page, setPage] = useState(1);
  const [globalPage, setGlobalPage] = useState(1)
  const [globalPostCount, setGlobalPageCount] = useState(0)

  const [allContents, setAllContents] = useState([])
  const [showData, setShowData] = useState(false)

  const { tenantId } = useSelector((state) => state.user);

  const { likedPosts, trendingPosts, wallOfFamePosts, globalPosts } = useSelector((state) => state.posts);
  const { poems } = useSelector((state) => state.poems);

  const { searchQuery } = useSelector((state) => state.library);
  const [trendingPostsState, setTrendingPostsState] = useState(trendingPosts);

  const categories = useSelector((state) => state.firestore.ordered.categories);

  useFirestoreConnect([{ collection: "categories" }]);

  useEffect(() => {
    dispatch(setActivePage(1))
  }, [])

  useEffect(() => {
    if (tenantId) {
      dispatch(getTrendingPosts());
      dispatch(getWallOfFamePosts());
      // dispatch(getLikedPost());
      dispatch(getPoems())
    }
  }, [tenantId]);


  useEffect(() => {
    let pageCount = 0;
    if (globalPosts && globalPosts.length > 0) {
      pageCount = parseInt(globalPosts.length / showPostCount)
      pageCount += (globalPosts.length % showPostCount) > 0 ? 1 : 0
    }
    setGlobalPageCount(pageCount)
  }, [globalPosts])

  useEffect(() => {
    let contents = []
    if (allContents && allContents.length > 0)
      contents = [...allContents]
    if (poems && poems.length > 0) {
      contents = contents.filter((post) => post.type !== 'poem')
      for (let i = 0; i < poems.length; i++) {
        let poem = poems[i]
        let content = {
          id: poem.id,
          title: poem.title,
          images: poem && poem.images && poem.images.length > 0 ? poem.images : [poem?.image],
          type: 'poem',
          views: poem?.views || 0,
          likedby: poem?.likedBy || [],
          comments: 0,
          shared: 0,
          body: poem?.content,
          content: poem?.body,
          lastUpdate: poem?.lastUpdated,
          user: poem?.user,
          commentscount: poem?.commentscount,
          shared: poem?.shared
        }
        contents.push(content)
      }
    }
    dispatch(setShowPoems(poems))
    contents = contents.sort(function (a, b) {
      return b.lastUpdate.seconds - a.lastUpdate.seconds;
    });
    setAllContents(contents)
    let postCount = contents?.length
    let totalColumn = parseInt(postCount / showPostCount)
    if (postCount % showPostCount > 0) {
      totalColumn += 1
    }
    setPostCount(totalColumn)
    //to reset page index
    // setPage(1) 
  }, [poems])

  useEffect(() => {
    setTrendingPostsState(trendingPosts);
  }, [trendingPosts]);

  useEffect(() => {
    let contents = []
    if (allContents && allContents.length > 0)
      contents = [...allContents]
    if (wallOfFamePosts && wallOfFamePosts.length > 0) {
      contents = contents.filter((post) => post.type !== 'blog')
      contents = [...contents, ...wallOfFamePosts]
      contents = contents.sort(function (a, b) {
        return b.lastUpdate.seconds - a.lastUpdate.seconds;
      });
      setAllContents(contents)
      let postCount = contents?.length
      let totalColumn = parseInt(postCount / showPostCount)
      if (postCount % showPostCount > 0) {
        totalColumn += 1
      }
      setPostCount(totalColumn)
      setPage(1)
      setShowData(true)
    }
  }, [wallOfFamePosts]);

  const handleChange = (event, newValue) => {
    setValue('' + newValue + '');

    if (newValue == 1 && tenantId) {
      if (globalPosts && globalPosts.length > 0)
        return;
      else
        dispatch(getGlobalPosts())
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlelobalFeedPageChange = (event, value) => {
    setGlobalPage(value);
  };

  const filterPost = (post, list) => {
    let { type } = post
    for (let i = 0; i < list.length; i++) {
      try {
        if (type === 'blog') {
          let { category } = post

          if (category?.label.toLowerCase() === list[i].toLowerCase()) {
            return true;
          }
        } else if (type === 'poem') {
          let { category } = post
          if (category?.toLowerCase() === list[i].toLowerCase()) {
            return true;
          }
        }
      } catch (error) { }
    }
    return false;
  };

  const setAllPosts = () => {
    let wallOfFamePostsState = wallOfFamePosts;
    setAllContents(wallOfFamePosts)
    if (wallOfFamePostsState) {
      let postCount = wallOfFamePostsState?.length
      let totalColumn = parseInt(postCount / showPostCount)
      if (postCount % showPostCount > 0) {
        totalColumn += 1
      }
      setPostCount(totalColumn)
      setPage(1)
      setShowData(true)
    } else {
      setPostCount(0)
      setPage(0)
    }
  }

  const filterByTopics = (list) => {
    let contentsData = []
    if (wallOfFamePosts && wallOfFamePosts.length > 0)
      contentsData = [...wallOfFamePosts];

    if (poems && poems.length > 0) {
      for (let i = 0; i < poems.length; i++) {
        let poem = poems[i]
        let content = {
          id: poem.id,
          title: poem.title,
          images: poem && poem.images && poem.images.length > 0 ? poem.images : [poem?.image],
          type: 'poem',
          views: poem?.views || 0,
          likedby: poem?.likedBy || [],
          comments: 0,
          shared: 0,
          body: poem?.content,
          content: poem?.body,
          lastUpdate: poem?.lastUpdated,
          user: poem?.user,
          commentscount: poem?.commentscount,
          shared: poem?.shared
        }
        contentsData.push(content)
      }
    }
    contentsData = contentsData.sort(function (a, b) {
      return b.lastUpdate.seconds - a.lastUpdate.seconds;
    });

    if (list && list.length > 0) {
      dispatch(setSearchQuery(""));

      let filtredContentsData = contentsData.filter((post) =>
        filterPost(post, list)
      );

      // setWallOfFamePostsState(filtredContentsData);
      setAllContents(filtredContentsData)
      let postCount = filtredContentsData?.length
      let totalColumn = postCount ? parseInt(postCount / showPostCount) : 0

      if (postCount % showPostCount > 0) {
        totalColumn += 1
      }
      setPostCount(totalColumn)
      setPage(postCount ? 1 : 0)

    } else if (searchQuery === '') {
      setAllPosts()
    }
  };

  const filterBySearchQuery = (post, searchQuery) => {
    let { type, category, body, user, blogId, title, content, tags } = post

    try {
      if (type === 'blog') {
        if (category?.label?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          body?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (tags && tags.length > 0 && JSON.stringify(tags).toLowerCase().includes(searchQuery.toLowerCase())) ||
          blogId?.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
      } else if (type === 'poem') {
        if (category?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          content?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
      }
    } catch (error) {
      console.log("Error ---- ", error)
      return false
    }
    return false
  }

  const filterByQuery = () => {
    let contentsData = []
    setTopics([])

    if (wallOfFamePosts && wallOfFamePosts.length > 0)
      contentsData = [...wallOfFamePosts];

    if (poems && poems.length > 0) {
      for (let i = 0; i < poems.length; i++) {
        let poem = poems[i]
        let content = {
          id: poem.id,
          title: poem.title,
          images: poem && poem.images && poem.images.length > 0 ? poem.images : [poem?.image],
          type: 'poem',
          views: poem?.views || 0,
          likedby: poem?.likedBy || [],
          comments: 0,
          shared: 0,
          body: poem?.content,
          content: poem?.body,
          lastUpdate: poem?.lastUpdated,
          user: poem?.user,
          commentscount: poem?.commentscount,
          shared: poem?.shared
        }
        contentsData.push(content)
      }
    }
    contentsData = contentsData.sort(function (a, b) {
      return b.lastUpdate.seconds - a.lastUpdate.seconds;
    });
    let filtredContentsData = contentsData.filter((post) =>
      filterBySearchQuery(post, searchQuery)
    );

    // setWallOfFamePostsState(filtredContentsData);
    setAllContents(filtredContentsData)
    let postCount = filtredContentsData?.length
    let totalColumn = postCount ? parseInt(postCount / showPostCount) : 0

    if (postCount % showPostCount > 0) {
      totalColumn += 1
    }
    setPostCount(totalColumn)
    setPage(postCount ? 1 : 0)

  }

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  useEffect(() => {
    if (searchQuery && searchQuery.length > 2) {
      debounce(filterByQuery(), 1000)
    } else if (topics.length < 1) {
      setAllPosts()
    }
  }, [searchQuery]);

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid container item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Grid container item xs={12} md={10}>
            <Grid item xs={12} md={10} lg={10}>
              <TabContext value={value} width="100%">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <TabList
                    centered
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  // variant="fullWidth"
                  >
                    <Tab label="Institute Feed" value={"0"} />
                    <Tab label="Global Feed" value={"1"} />
                  </TabList>
                </Box>
                <TabPanel
                  value={"0"}
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid container item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      component="div"
                      variant="h5"
                      color="rgb(30, 58, 113)"
                      fontWeight="700"
                      marginBottom="1rem"
                    >
                      Wall of Fame
                    </Typography>

                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        style={{
                          margin: "auto",
                          textAlignment: "center",
                          justifyConent: "center",
                          width: "100%",

                        }}
                      >
                        {
                          trendingPostsState && trendingPostsState.length > 0 ?
                            <Carousel className={classes.cars} sx={{ p: 5 }}>
                              {trendingPostsState.map((blog, index) => (
                                <Card
                                  className={classes.cardResp}
                                  style={{
                                    borderRadius: ".8rem",
                                    display: "flex",
                                    height: "100%",
                                    width: "100vw",

                                  }}
                                  onClick={() =>
                                    history.push("/blog/" + blog.id + '/' + blog.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())
                                  }
                                  key={blog.id}
                                >
                                  <Grid
                                    item
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    style={{ maxHeight: "350px" }}
                                  >
                                    <CardContent style={{ textAlign: "left" }}>
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        style={{ overflow: "hidden" }}
                                      >
                                        <div
                                          style={{
                                            height: "50px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <Typography
                                            component="div"
                                            variant="h5"
                                            fontWeight="700"
                                            color="text.secondary"
                                          >
                                            {blog.title}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            maxHeight: 100,
                                            minHeight: 100,
                                            overflow: "hidden",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            fontSize="14px"
                                            color="text.secondary"
                                            component="div"
                                            style={{ width: '30vw', height: '38vh', overflow: 'hidden' }}
                                          >
                                            {ReactHtmlParser(
                                              blog.body.slice(0, 50) + '...'
                                            )}
                                          </Typography>
                                        </div>
                                      </Grid>

                                      <Box
                                        elevation={0}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          textAlign: "center",
                                          alignItem: "center",
                                          alignContent: "center",
                                          marginTop: "2rem",
                                        }}
                                      >
                                        <Grid container >
                                          <Grid item sm={12} xs={12} md={12} style={{
                                            display: "flex",
                                            textAlign: "center",
                                          }}>
                                            <Grid item sm={6} md={6} xs={6} style={{ display: 'flex' }}>
                                              <Box
                                                sx={{
                                                  width: "34px",
                                                  height: "34px",
                                                  borderRadius: "50%",
                                                  display: "flex",
                                                  overflow: "hidden",
                                                  flexDirection: 'row'
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    display: "block",
                                                    width: "34px",
                                                    height: "34px",
                                                    borderRadius: "50%",
                                                  }}
                                                  src={blog?.user?.avatarUrl}
                                                  alt="First slide"
                                                />

                                              </Box>
                                              <Typography
                                                variant="standard"
                                                sx={{
                                                  whiteSpace:
                                                    "nowrap !important",
                                                  overflow: "hidden !important",
                                                  textOverflow:
                                                    "ellipsis !important",
                                                  color: "rgb(30, 58, 113)",
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                {blog?.user?.displayName}

                                              </Typography>
                                            </Grid>
                                            <Grid item sm={6} md={6} xs={6}>
                                              <Typography
                                                variant="standard"
                                                sx={{
                                                  width: "10px !important",
                                                  whiteSpace:
                                                    "nowrap !important",
                                                  overflow: "hidden !important",
                                                  textOverflow:
                                                    "ellipsis !important",
                                                  color: "rgb(30, 58, 113)",
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                {blog.lastUpdate
                                                  ? moment
                                                    .unix(blog.lastUpdate.seconds)
                                                    .format("DD MMM YY")
                                                  : null}
                                              </Typography>
                                            </Grid>
                                          </Grid>

                                          <Grid item sm={12} xs={12} style={{
                                            width: "50%",
                                            display: "flex",
                                            textAlign: "center",
                                          }}>
                                            <Grid item xs={12}>
                                              <Grid container style={{
                                                marginTop: "1rem",
                                                width: window.innerWidth < 668 ? "90vw" : "auto",
                                              }}>
                                                <Grid item xs={2}>
                                                  <RemoveRedEyeOutlinedIcon color="rgb(162, 162, 162)" />
                                                  <span
                                                    style={{
                                                      color: "rgb(162, 162, 162)",
                                                      fontWeight: "bold",
                                                      marginLeft: 5,
                                                    }}
                                                  >
                                                    {blog?.views || 0}
                                                  </span>
                                                </Grid>
                                                <Grid item xs={2}>
                                                  <img src={hands} alt="hands" />
                                                  <span
                                                    style={{
                                                      color: "rgb(162, 162, 162)",
                                                      fontWeight: "bold",
                                                      marginLeft: 5,
                                                    }}
                                                  >
                                                    {blog && blog.likedby
                                                      ? blog?.likedby?.length
                                                      : 0}
                                                  </span>
                                                </Grid>
                                                <Grid item xs={2}>
                                                  <FaRegComment color="rgb(162, 162, 162)" />
                                                  <span
                                                    style={{
                                                      color: "rgb(162, 162, 162)",
                                                      fontWeight: "bold",
                                                      marginLeft: 5,
                                                    }}
                                                  >
                                                    {blog?.commentscount || 0}
                                                  </span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                  <span
                                                    style={{
                                                      color: "rgb(162, 162, 162)",
                                                      fontWeight: "bold",
                                                      marginLeft: 5,
                                                    }}
                                                  >
                                                    <TelegramIcon color="rgb(162, 162, 162)" />

                                                    {blog?.shared || 0}
                                                  </span>
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={3} lg={3}>
                                                  <Button
                                                    style={{
                                                      padding: "6px",
                                                      width: window.innerWidth < 668 ? "65vw" : "8vw",
                                                      borderRadius: "50px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      marginTop: '0.3rem',
                                                      backgroundColor: "rgb(255, 219, 0)",
                                                      color: "#000",
                                                    }}
                                                    variant="contained"
                                                    size="small"
                                                  >
                                                    Read More
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                        </Grid>
                                      </Box>
                                    </CardContent>
                                  </Grid>

                                  <Grid item sm={4} style={{ height: '35vh', overflow: "hidden", border: '1px solid lightgray', borderRadius: '20px', objectFit: 'cover' }}>
                                    <CardMedia
                                      component="img"
                                      image={Array.isArray(blog?.images) ? blog?.images[0] : blog?.images}
                                      alt="Live from space album cover"
                                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />{" "}
                                  </Grid>
                                </Card>
                              ))}
                            </Carousel>
                            : null
                        }
                      </Box>
                    </Grid>
                    {/* <Box>
                      <AppNavComp />
                    </Box> */}

                    <div>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        color="rgb(30, 58, 113)"
                        fontWeight="700"
                        marginTop="2rem"
                      >
                        Latest
                      </Typography>
                      {
                        postCount && postCount > 0 && showData ?
                          <ThemeProvider theme={theme}>
                            <Container
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "4rem",
                                padding: "10px 10px",
                              }}
                            >
                              <Posts wallOfFamePostsState={allContents} page={page} showPostCount={showPostCount} poems={poems} />
                            </Container>
                            <Stack spacing={2}>
                              <Pagination
                                count={postCount}
                                size="large"
                                page={page}
                                shape="rounded"
                                onChange={handlePageChange}
                              />
                            </Stack>

                          </ThemeProvider> :
                          <h5>Loading....</h5>
                      }

                    </div>
                  </Grid>
                </TabPanel>

                <TabPanel
                  value={"1"}
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="rgb(30, 58, 113)"
                      fontWeight="700"
                    >
                      Latest
                    </Typography>
                    {
                      globalPostCount && globalPostCount > 0 && showData && globalPosts && globalPosts.length > 0 ?
                        <ThemeProvider theme={theme}>
                          <Container
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1rem",
                              padding: "10px 10px",
                            }}
                          >
                            <Posts wallOfFamePostsState={globalPosts} page={page} showPostCount={showPostCount} poems={[]} />
                          </Container>
                          <Stack spacing={2}>
                            <Pagination
                              count={globalPostCount}
                              size="large"
                              page={globalPage}
                              shape="rounded"
                              onChange={handlelobalFeedPageChange}
                            />
                          </Stack>
                        </ThemeProvider> :
                        <h5>Loading....</h5>
                    }
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
            <Grid item xs={12} md={4} lg={2} className={classes.exploreStyle}>
              <Typography
                component="div"
                marginBottom="1.5rem"
                color="#545454"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
              >
                Explore topics
              </Typography>

              <LibraryButton
                setTopics={setTopics}
                filterByTopics={filterByTopics}
                categories={categories && categories.length > 0 ? categories : []}
              />
              <Card className={classes.addImges}>
                <img
                  src={add1}
                  alt="hi"
                  width="100%"
                  className={classes.hidenImg1}
                />
              </Card>
              <Card className={classes.addImges}>
                <img
                  src={add2}
                  alt="hi"
                  width="100%"
                  className={classes.hidenImg1}
                />
              </Card>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Main>

  );
}

export default Library;
