import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from '../store'

export const getComments = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let comments = [];
        await db
            .collection('comments').limit(5)
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    comments.push(doc.data());
                })
            }).catch((error) => {
                console.log("Error ---- ", error)
            });
        dispatch(
            triggerReducer(actionTypes.GET_COMMENTS, {
                payload: comments
            })
        );
        resolve(comments)
    });
};

export const setOpenedComment = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_COMMENT, { payload })
    );
};

export const setAddNewComment = (payload) => async (dispatch, getState) => {
    let payloadData = { ...payload, lastupdate: firebase.firestore.Timestamp.fromDate(new Date()) }
    try {
        const { firestore } = store
        await firestore
            .add({ collection: "comments" }, payloadData)
            .then(() => {
                dispatch(showSuccessSnackbar("Comment Added Successfully"));
                dispatch(
                    triggerReducer(actionTypes.SET_NEW_COMMENT, { payload: payloadData })
                );
            })
            .catch((error) => {

                dispatch(showErrorSnackbar("Error in saving comment"));
            });
    } catch (error) {

        dispatch(showErrorSnackbar("Error in saving Comment"));
    }
};

export const setListComments = (payload) => async (dispatch) => {
    try {
        await db
            .collection("comments")
            .where("postid", "==", payload.postid)
            .get()
            .then((docRef) => {
                payload.setListComments = docRef;
                dispatch(
                    triggerReducer(actionTypes.SET_LIST_COMMENT, { payload })
                );
            })
            .catch((error) => {

            });
    } catch (error) {

    }
    dispatch(
        triggerReducer(actionTypes.SET_LIST_COMMENT, { payload })
    );
};

export const setSavedComment = (payload) => async (dispatch, getState) => {
    try {
        const [docId] = Object.entries(payload)
        await db
            .collection("comments")
            .doc(docId[0])
            .update(docId[1])
            .then(() => {
                payload.savedComment = payload;
                dispatch(showSuccessSnackbar("Comment Updated Successfully"));
                dispatch(
                    triggerReducer(actionTypes.SET_SAVED_COMMENT, { payload })
                );
            })
            .catch((error) => {

                dispatch(showErrorSnackbar("Error in saving reply"));
            });
        // }
    } catch (error) {

        dispatch(showErrorSnackbar("Error in saving Comment"));
    }
    dispatch(
        triggerReducer(actionTypes.SET_SAVED_COMMENT, { payload })
    );
};

function getUserData(userId) {
    return new Promise(async (resolve, reject) => {
        try {
            let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', userId).get()
            let userData = null
            if (user && user.docs && user.docs.length > 0) {
                userData = user.docs[0].data()
                userData = {
                    ...userData,
                    cPassword: null,
                    newPassword: null,
                    password: null
                }
            }
            resolve(userData)
        } catch (error) {
            resolve(null)
        }

    });
}
export const getLatestComments = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState().firebase;
        let comments = [];
        await db
            .collection('comments')
            .limit(5)
            .orderBy('lastupdate', 'desc')
            .where('ownerId', '==', auth.uid)
            .get()
            .then(async (querySnapshot) => {
                let userIdsData = []
                let userIds = []
                querySnapshot.docs.forEach(async (item) => {
                    let comment = item.data()
                    comment = {
                        ...comment,
                        id: comment.id
                    }
                    comments.push(comment)
                })
                for (let i = 0; i < comments.length; i++) {
                    let comment = comments[i]
                    let { children, postid, userid } = comments[i]
                    let userIndex = userIds.findIndex((user) => user === userid)
                    let userData = null
                    let postData = null
                    if (userIndex > -1) {
                        userData = userIdsData[userIndex]
                    } else {
                        userData = await getUserData(userid)
                        if (userData) {
                            userIds.push(comment.userid)
                            userIdsData.push(userData)
                        }
                        // let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', comment.userid).get()
                        // if (user && user.docs && user.docs.length > 0) {
                        //     userData = user.docs[0].data()
                        //     userData = {
                        //         ...userData,
                        //         cPassword: null,
                        //         newPassword: null,
                        //         password: null
                        //     }
                        // }
                    }
                    if (postid) {
                        let post = await db.collection("posts").where(firebase.firestore.FieldPath.documentId(), '==', postid).get()
                        if (post && post.docs && post.docs.length > 0) {
                            postData = post.docs[0].data()
                            postData = {
                                ...postData,
                                postId: post.docs[0].id,
                                type: 'Blog'
                            }
                        }
                    }
                    if (children && children.length > 0) {
                        for (let j = 0; j < children.length; j++) {
                            let childComment = children[j]
                            let userIndex = userIds.findIndex((user) => user === childComment.userid)
                            let userData = null
                            if (userIndex > -1) {
                                userData = userIdsData[userIndex]
                            } else {
                                let userData = await getUserData(childComment.userid)
                                if (userData) {
                                    userIds.push(childComment.userid)
                                    userIdsData.push(userData)
                                }
                            }
                            childComment = {
                                ...childComment,
                                user: userData
                            }
                            children[j] = childComment
                        }
                    }
                    comment = {
                        ...comment,
                        user: userData,
                        children,
                        postData
                    }
                    comments[i] = comment
                }
            }).catch((error) => {
                console.log("Error ---- ", error)
            });
        dispatch(
            triggerReducer(actionTypes.GET_LATEST_COMMENTS, {
                payload: comments
            })
        );
        resolve(comments)
    });
};


export const getCommentsByPost = (payload) => (dispatch, getState) => {
    const { auth } = getState().firebase;
    db.collection('comments')
        .orderBy('lastupdate', 'desc')
        .where('poemid', '==', payload)
        .get()
        .then(async (querySnapshot) => {
            let commentsData = []
            querySnapshot.docs.forEach(async (item) => {
                let comment = item.data()
                comment = {
                    ...comment,
                    id: comment.id
                }
                commentsData.push(comment)
            })

            dispatch(
                triggerReducer(actionTypes.GET_COMMENTS_BY_POSTS, {
                    payload: commentsData
                })
            );
        }).catch((error) => {
            console.log("Error ---- ", error)
        });

}