import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { storage } from "../../config/fbConfig";
import { useHistory } from "react-router-dom";
import ladyImg from "../../assets/images/Blog/NBP/shutterstock_2029347044.png";
import PostcardEmailSelect from './PostcardSections/PostcardEmailSelect';
import {
  Grid, Container, Box, Stack, Typography,
  Modal, Button, Backdrop, MenuItem, AppBar,
  Toolbar, IconButton, Menu
} from "@mui/material";

import "firebase/auth";
import "firebase/firestore";
import MenuIcon from '@mui/icons-material/Menu';
import catimg from "../../assets/images/Blog/NBP/Cat.png";
import cloudImg from '../../assets/images/Artwork1.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import validateEmail from '../../helpers/validateEmail';
import store from "../../store/store";
import { setPostCardDetails, setOpenedPostCard, setPostCardImage, deleteDraftCard, setReceivedPost } from "../../store/actions/postCards";
import { showSuccessSnackbar, showErrorSnackbar } from '../../store/actions/snackbar'
import ReactHtmlParser from "react-html-parser";
import { EmailTemplate } from "../EmailTemplate/PostCard_Template"

//firebase
import firebase from 'firebase/compat/app'
import { addUserLogs } from "../../store/actions/rewards";

const style = {
  position: "absolute",
  top: "35%",
  left: "70%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "100%",
  minHeight: '25vw',
};

const styleSend = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "50%",
};


const pages = ['Save', 'Preview'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const PostCardHeader = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const { users: user } = useSelector(state => state.firestore.ordered)
  const postCardDetails = useSelector(state => state.postcards.postCard)
  const [data, setData] = useState({ content: "", name: "" })

  const { firestore } = store

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [error, setError] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [flag, setFlag] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null)

  useFirestoreConnect([{ collection: "users", doc: auth.uid }]);


  const handleClose = () => setOpen(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseSendModal = () => setOpenSend(false)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    let avatarUrl = null
    if (user && user.length > 0) {
      avatarUrl = user[0]?.avatarUrl
    }
    setAvatarUrl(avatarUrl)
  }, [user])

  useEffect(() => {
    setData(postCardDetails)
  }, [postCardDetails])

  const handleOpen = () => {
    if (props.plainFiles.length === 0 && !props.isMediaSelected && !props.hasTextStatus) {
      dispatch(showErrorSnackbar("Please, add image and some text!!!"));
      return
    }

    if (props.plainFiles.length === 0 && !props.isMediaSelected) {
      dispatch(showErrorSnackbar("Please, select image!!!"));
      return
    }

    if (!props.hasTextStatus) {
      dispatch(showErrorSnackbar("Please, add some text!!!"));
      return
    }
    setOpenSend(true)
  }

  const uploadFile = () => {

    setSendButtonDisabled(true)
    let image = props.plainFiles[0] //e.target.files[0];
    let userId = auth.uid;

    var date = new Date()
    var sample = [];
    if (users.length === 0) {
      dispatch(showErrorSnackbar('Please, enter user email!!!'))
      setSendButtonDisabled(false)
      return
    }
    users.map((item) => {

      let data =
      {
        email: item.email,
        userName: item.email.substring(0, item.email.lastIndexOf("@"))
      }
      sample.push(data)
    })

    let draft_id = localStorage.getItem('draft-card-id');
    if (draft_id) {
      dispatch(deleteDraftCard(draft_id));
    }
    if (props.isMediaSelected && props.mediaUrl) {
      dispatch(setPostCardImage(props.mediaUrl));
      const postCard = {
        content: props.editorStateAsHTML,
        name: auth.displayName ? auth.displayName : '',
        userId: auth.uid,
        from: auth.email,
        to: sample,
        image: props.mediaUrl,
        date: date.toString(),
        isPublished: true
      };
      dispatch(setPostCardDetails(postCard))

      onSend(props.mediaUrl, postCard)
    } else {
      storage
        .ref(`/users/${userId}/${image.name}`)
        .put(image)
        .on("state_changed", () => {
          storage
            .ref(`/users/${userId}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async (url) => {
              dispatch(setPostCardImage(url));
              const postCard = {
                content: props.editorStateAsHTML,
                name: auth.displayName ? auth.displayName : auth.email.substring(0, auth.email.lastIndexOf("@")),
                userId: auth.uid,
                from: auth.email,
                to: sample,
                image: url,
                date: date.toString(),
                isPublished: true
              };

              dispatch(setPostCardDetails(postCard));
              onSend(url, postCard)
            });
        });
    }
  };

  const onSend = async (urlImage, postCard) => {
    if (flag === false) {
      var sample = []

      if (users.length === 0) {
        dispatch(showErrorSnackbar("Please, select user!"));
        return
      }

      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        if (user && user.email && validateEmail(user.email)) {
          let data = {
            email: user.email,
            userName: user.email.substring(0, user.email.lastIndexOf("@"))
          }
          sample.push(data)
        }
      }

      if (sample.length > 0) {
        try {
          let EmailArray = []
          sample.forEach((e) => {
            EmailArray.push(e.email);
          })
          await firestore.add({ collection: "postcard" }, {
            content: postCard.content,
            from: postCard.from,
            userId: auth.uid,
            to: sample,
            image: urlImage,
            name: postCard.name ? postCard.name : auth.displayName,
            date: postCard.date,
            profileImage: avatarUrl ? avatarUrl : auth.photoURL == undefined ? '' : auth.photoURL,
            isPublished: true
          })
            .then((resp) => {
              let rewardPayload = {
                postcardId: resp.id,
                type: 'postcard',
                operation: 'sent',
                quinns: 250,
                milestone: 1,
                date: firebase.firestore.Timestamp.fromDate(new Date())
              }
              dispatch(addUserLogs(rewardPayload))
              let name = user.name ? user.name : auth.displayName
              const EmailTmpVariable = EmailTemplate(name, resp.id);
              fetch(
                "https://asia-south1-quillatwill-int-uat.cloudfunctions.net/sendEmail",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },

                  body: JSON.stringify({
                    // email: sample[0].email,
                    email: EmailArray,
                    name: postCard.name ? postCard.name : auth.displayName,
                    message: 'Post Card Sent Successfully',
                    template: EmailTmpVariable
                  }),
                }
              )
                .then((response) => {

                })
                .catch((error) => {
                  alert("error");
                  console.error(error);
                });
            })


          dispatch(setPostCardDetails(''))
          dispatch(setPostCardImage(''))
          let changeOpenedPostcard = {
            from: null,
            to: [],
            content: null,
            image: null,
          }
          dispatch(setOpenedPostCard(changeOpenedPostcard, false))
          setUsers([])
          props.setEmail([])
          props.clearFilePicker()
          props.clearEditor()
          dispatch(showSuccessSnackbar("Postcard sent successfully!!!"));
          setSendButtonDisabled(false)
          setOpenSend(false)
        } catch (error) {

          setSendButtonDisabled(false)
        }
      } else {
        dispatch(showErrorSnackbar("Not valid user/s email!!!"));
        setSendButtonDisabled(false)
      }
      setFlag(true);
    }
    else {
      dispatch(showErrorSnackbar("Postcard has been sent already..!"));
    }
  }

  const handleOnSaveClick = () => {
    if (props.plainFiles.length === 0 && !props.isMediaSelected && !props.hasTextStatus) {
      dispatch(showErrorSnackbar("Please, add image and some text!!!"));
      return
    }

    if (props.plainFiles.length === 0 && !props.isMediaSelected) {
      dispatch(showErrorSnackbar("Please, add image!!!"));
      return
    }

    if (!props.hasTextStatus) {
      dispatch(showErrorSnackbar("Please, add some text!!!"));
      return
    }

    props.onSave()
  }

  const getImageLink = () => {
    if (props.files && props.files.length > 0) {
      return props.files[0].content
    } else if (props.isMediaSelected && props.mediaUrl) {
      return props.mediaUrl
    } else {
      return ''
    }
  }
  return (
    <>
      <AppBar position="static" style={{
        position: 'fixed',
        marginTop: '4rem !important', 
        background: 'linear-gradient(.25turn,  rgb(67,20,163), 40%,rgb(73,200,211))',
        zIndex: 1000 
      }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Stack direction="row" spacing={2}
                onClick={() => history.push("/postcard")}

              >
                <Button style={{ color: '#fff', fontSize: '17px', fontFamily: "Museo700", }}>
                  <ArrowBackIosNewIcon style={{ color: '#fff', width: '28%', margin: '8px', }} /> Back
                </Button>
              </Stack>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu style={{ display: 'flex', justifyContent: 'end' }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}

              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" >{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              style={{ fontFamily: "Museo700" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              onClick={() => history.push("/postcard")}
            >
              Back
            </Typography>
            <Box style={{ borderLeft: '1px solid #FFFFFF', fontFamily: "Museo700", marginLeft: '18px', color: 'red', width: '2%', height: '25px' }}> </Box>
            {/* <Typography
            >
              Post Title Here
            </Typography>
            <HiOutlinePencil style={{ margin: '20px' }} /> */}

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>

              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => { handleOnSaveClick() }}
              >
                Save
              </Button>

              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={handleOpenModal}
              // onClick={() => { handleOnPreviewClick() }}
              >
                Preview
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip> */}
              <Button
                onClick={handleOpen}
                // className={classes.root1}
                sx={{
                  marginLeft: "10px",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#FFDB00",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                  '&: hover': {
                    backgroundColor: "yellow",
                  },
                }}
                variant="contained"
              // onClick={onSend}
              >
                Send
              </Button>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
          >
            <Stack>
              <Modal
                open={openSend}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleSend} minwidth="md">
                  <Box style={{ position: "relative" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={2} lg={2}>
                        <img
                          src={catimg}
                          alt="CatImg"
                          style={{
                            position: "absolute",
                            top: "-70px",
                            left: "-65px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={8}
                        lg={8}
                        style={{ textAlign: "center" }}
                      >
                        <h2 style={{ color: "#1f108e" }}>
                          Add Details
                        </h2>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        lg={2}
                        style={{ textAlign: "end" }}
                      >
                        <Button onClick={handleCloseSendModal}>Close</Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="form" noValidate autoComplete="off" marginTop="2rem">
                    <Grid container spacing={2} marginBottom="3rem">
                      <Grid item xs={6} md={6} lg={6} style={{ textAlign: 'left', margin: 'auto' }}>
                        <PostcardEmailSelect
                          setUsers={setUsers}
                          users={users}
                          error={error}
                          setError={setError}
                        />


                        {/* <label
                              htmlFor="blogtitle"
                              style={{ ...labelstyle }}
                            >
                              Email ID
                            </label>
                            <TextField style={{ margin: 'auto', textAlign: 'center' }}
                              fullWidth
                              size="small"
                              id="blogtitle"
                              label="Type here"
                            /> */}
                      </Grid>

                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      display='contents'
                    >
                      {/* <Grid item>
                                  <Button
                                    style={{
                                      padding: "5px 20px",
                                      borderRadius: "2rem",
                                      backgroundColor: "#adb5bd",
                                      border: "none",
                                      fontSize: "1.5rem",
                                      color: "grey",
                                    }}
                                  >
                                    Skip
                                  </Button>
                                </Grid> */}
                      <Grid item style={{ textAlign: 'end' }}>
                        <Button
                          style={{
                            padding: "5px 22px",
                            borderRadius: "2rem",
                            backgroundColor: "#ffc107",
                            border: "none",
                            fontSize: "0.9rem",
                            color: "#1f108e",
                            textAlign: 'end'
                          }}
                          onClick={uploadFile}
                          disabled={sendButtonDisabled}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Box style={{ position: "relative" }}>
                          <img
                            src={cloudImg}
                            alt=""
                            style={{
                              position: "absolute",
                              top: "-8px",
                              left: "-100px",
                              zIndex: "-1",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item textAlign="end">
                        <Box style={{ position: "relative" }}>
                          <img
                            src={ladyImg}
                            alt=""
                            style={{
                              position: "absolute",
                              bottom: "-294px",
                              right: "-255px",
                              height: "70vh",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </Backdrop>

          <Modal style={{ marginTop: '8rem', width: '70%', border: '1px solid red' }}
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box style={{ position: "relative" }}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <Grid
                    item
                    xs={8}
                    md={8}
                    lg={8}
                    style={{ textAlign: "left" }}
                  >
                    <h2 style={{ color: "#1f108e" }}>
                      Preview
                    </h2>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    lg={2}
                    style={{ textAlign: "end" }}
                  >
                    <Button onClick={handleClose}>Close</Button>
                  </Grid>
                </Grid>
              </Box>
              <Box component="form" noValidate autoComplete="off" style={{ padding: 20 }}>
                <Grid
                  container
                  spacing={2}
                  // justifyContent="space-between"
                  style={{
                    // marginTop: '1rem',
                    borderRadius: '10px',
                    // padding: '10px',
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Grid container item xs={12} md={6} lg={6} style={{
                    minHeight: '28vw', padding: '10px'
                  }}
                  >
                    <Grid item xs={12} md={12} lg={12} style={{
                      boxShadow: '0px 0px 1px 1px gray', borderRadius: '20px',
                    }}>
                      <p
                        style={{
                          fontSize: "small",
                          color: "grey",
                          textAlign: "left",
                          padding: "15px",
                          margin: 10,
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace: "nowrap !important",
                          overflow: "hidden !important",
                          textOverflow: "ellipsis !important",
                          // width: '100%'
                        }}
                      >
                        {
                          ReactHtmlParser(props.editorStateAsHTML)
                        }
                      </p>
                    </Grid>

                    {/* {cardContent} */}
                  </Grid>
                  <Grid container item xs={12} md={6} lg={6} style={{
                    padding: 10
                  }}>
                    <Grid item xs={12} md={12} lg={12} style={{
                      boxShadow: '0px 0px 1px 1px gray', borderRadius: '20px', marginBottom: '-10px',
                      overflow: 'hidden',
                    }}>
                      <img
                        src={getImageLink()}
                        alt=""
                        style={{
                          width: '25vw',
                          height: '25vw',
                          marginTop: "10px",
                          justifyContent: "center",
                          objectFit: "cover",
                          borderRadius: '15px',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
        </Container>

      </AppBar>
    </>
  );
};
export default PostCardHeader;
