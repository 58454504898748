import React from "react"
// Button from MUI
import Button from '@mui/material/Button';
import { textTransform } from "@mui/system";
import { useRef, useState, useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sha512 } from 'js-sha512';
import moment from 'moment';
// import PaymentForm from './PaymentForm'
const userInit = {
  email: "",
  phoneNumber: "",
  avatarUrl: "",
  displayName: "",
};
function PremiumButton({ customStyles, buttonText, handleClick=() => null }) {
  const auth = useSelector((state) => state.firebase.auth);
  useFirestoreConnect([{ collection: "users", doc: auth.uid }]);
  const history = useHistory();
  const { firestore } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { users } = firestore.ordered;
  const [user, setUser] = useState(userInit);
  const [txnid, setTxnid] = useState(null);
  const [hash, setHash] = useState(null)
  useEffect(() => {
    if (users) {
      const currentUser = users[0];
      setUser({ ...user, ...currentUser });
    }
  }, [users]);
  useEffect(() => {
    var now = moment().unix();
    let randomNumber = parseInt(Math.random() * 1000)
    let txnid = 'QaW-' + now + randomNumber
    setTxnid(txnid)
  }, [])
  useEffect(() => {
    if (hash)
      document.getElementById("submit").click()
  }, [hash])
  const handleOnClickSubscribe = async () => {
    console.log("auth", user)
      if (auth && auth.uid) {
        let udf1 = auth.uid
        let udf5 = "PayUBiz_NODE_JS_KIT"
        let key = process.env.REACT_APP_PAYMENT_KEY;
        let salt = process.env.REACT_APP_PAYMENT_SALT;
        let amount = '199'
        let productinfo = "Premium"
        let firstname = user?.displayName
        let email = user?.email
        let text = key + '|' + txnid + '|' + amount + '|' + productinfo + '|' + firstname + '|' + email + '|' + udf1 + '||||' + udf5 + '||||||' + salt;

        let hash = sha512.create();
        let newhash = hash.update(text)
        let hashHex = hash.hex(newhash)
        setHash(hashHex);
      } else {
        history.push('/login')
      }
  }
  const defaultStyles = {
    borderRadius: '1rem',
    backgroundColor: 'white',
    fontWeight: 700,
    color: '#001f58',
    textTransform:'capitalize',
  };

  return (
    <>
      <Button 
      // onClick={() => handleOnClickSubscribe()}
      onClick={() => handleClick()}
      style={{ ...defaultStyles, ...customStyles }} variant="contained">
        {buttonText}
      </Button>
       <form action="https://test.payu.in/_payment" id="payment_form" method="Post" style={{ display: 'none' }}>
        <input type="hidden" id="udf1" name="udf1" value={auth.uid} />
        <input type="hidden" id="udf5" name="udf5" value="PayUBiz_NODE_JS_KIT" />
        <input type="hidden" id="userId" name="userId" value={auth.uid} />
        <input type="hidden" id="surl" name="surl" value={process.env.REACT_APP_PAYMENT_SUCCESS_URL} />
        <input type="hidden" id="furl" name="furl" value={process.env.REACT_APP_PAYMENT_FAILED_URL} />
        <input type="hidden" id="curl" name="curl" value={process.env.REACT_APP_PAYMENT_CLOSE_URL} />
        <input type="hidden" id="key" name="key" value={process.env.REACT_APP_PAYMENT_KEY} />
        <input type="text" id="txnid" name="txnid" placeholder="Transaction ID" value={txnid} />
        <input type="text" id="amount" name="amount" placeholder="Amount" value="199" />
        <input type="text" id="productinfo" name="productinfo" placeholder="Product Info" value="Premium" />
        <input type="text" id="firstname" name="firstname" placeholder="First Name" value={user?.displayName} />
        <input type="text" id="Lastname" name="Lastname" placeholder="Last Name" value={user?.lastName} />
        <input type="text" id="hash" name="hash" placeholder="Hash" value={hash} />
        <input type="text" id="phone" name="phone" placeholder="Mobile/Cell Number" value={user?.phoneNumber} />
        <input type="text" id="email" name="email" placeholder="Email ID" value={user?.email}/>
        <input type="text" id="address1" name="address1" placeholder="Address1" value="Latur" />
        <input type="text" id="address2" name="address2" placeholder="Address2" value="Latur" />
        <input type="text" id="city" name="city" placeholder="City" value="Latur" />
        <input type="text" id="state" name="state" placeholder="State" value="Maharashtra" />
        <input type="text" id="country" name="country" placeholder="Country" value="India" />
        <input type="text" id="Zipcode" name="Zipcode" placeholder="Zip Code" value="413512" />
        {/* <input type="text" id="Pg" name="Pg" placeholder="PG" value="CC" /> */}
        <input type="submit" id="submit" value="Pay" />
      </form>
      {/* <PaymentForm /> */}
    </>
  );
}
export default React.memo(PremiumButton)