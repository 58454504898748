import { triggerReducer, actionTypes } from '.';
import { storage, firebase, db } from "../../config/fbConfig";
import store from '../store'
import moment from 'moment'

export const getOpenedGallery = (payload, updateGotImages) => async (dispatch, getState) => {
    if (payload.length > 0) dispatch(triggerReducer(actionTypes.SET_OPENED_GALLERY, { payload }));
    const imgFolder = (payload.foldername) ? payload.foldername : 'images';
    const listRef = storage.ref(imgFolder);
    const maxCount = payload.maxCount ? payload.maxCount : 100;
    const imgArray = [];
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {
                imgArray.push(url);
                updateGotImages(imgArray.length);
                dispatch(triggerReducer(actionTypes.SET_OPENED_GALLERY, { payload: imgArray }))
            })
        })
    })
};


export const getOpenedUsersImages = (payload, userId, updateGotImages) => async (dispatch, getState) => {

    // const imgFolder = (payload.foldername) ? payload.foldername : 'users/' + userId + '/thumbnail';
    const imgFolder = (payload.foldername) ? payload.foldername : 'users/' + userId;
    const listRef = storage.ref(imgFolder);
    const maxCount = payload.maxCount ? payload.maxCount : 100;
    const imgArray = [];
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {
                imgArray.push(url);
                if (updateGotImages) updateGotImages(imgArray.length);
                dispatch(triggerReducer(actionTypes.SET_OPENED_USERS_IMAGES, { payload: imgArray }))
            })
        })
    })
};

export const getLatestUsersImages = (payload, userId) => async (dispatch, getState) => {
    const imgFolder = (payload.foldername) ? payload.foldername : 'users/' + userId;
    const listRef = storage.ref(imgFolder)
    const maxCount = payload.maxCount ? payload.maxCount : 3;
    const imgArray = [];
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {

            imgRef.getDownloadURL().then(function (url) {

                imgArray.push(url);
                dispatch(triggerReducer(actionTypes.SET_LATEST_USERS_IMAGES, { payload: imgArray }))
            })
        })
    })
};

export const getProfileImages = () => async (dispatch, getState) => {
    const imgFolder = 'profile';
    const listRef = storage.ref(imgFolder)
    const imgArray = [];
    await listRef.list().then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {

                imgArray.push(url);
                dispatch(triggerReducer(actionTypes.SET_PROFILE_IMAGES, { payload: imgArray }))
            })
        })
    })
};

export const getPremiumImages = () => async (dispatch, getState) => {
    const imgFolder = 'premium';
    const listRef = storage.ref(imgFolder)
    const imgArray = [];
    const maxCount = 10;
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {
                imgArray.push(url);
                dispatch(triggerReducer(actionTypes.GET_PREMIUM_IMAGES, { payload: imgArray }))
            })
        })
    })
};

export const getStickers = () => async (dispatch, getState) => {
    const imgFolder = 'stickers';
    const listRef = storage.ref(imgFolder)
    const imgArray = [];
    const maxCount = 10;
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {
                imgArray.push(url);
                dispatch(triggerReducer(actionTypes.GET_STICKERS, { payload: imgArray }))
            })
        })
    })
};

/*
type-image, photo, video, audio, sticker
name,
category,
tags,
free/paid
*/
export const addImageMetadata = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const createdAt = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD')))

    payload = {
        ...payload,
        createdAt
    }
    try {
        firestore.add({ collection: "images" }, payload).then((docRef) => {
            console.log("docRef === ", docRef)
        }).catch((error) => {
            console.log("Error : ", error)
        });
    } catch (error) {
        console.log("Error : ", error)
    }
};

export const getImageMetadata = (payload) => async (dispatch, getState) => {
    const { firestore } = store;

    await db.collection("images").where("url", "==", payload.url).get()
        .then(async (images) => {
            let imagesData = []
            images.docs.forEach(async (item) => {
                let image = item.data()
                image = {
                    ...image,
                    id: item.id
                }
                imagesData.push(image)
            })
        })
        .catch((err) => {
            console.log("Error --- ", err)
        })
};

export const addUserImageMetadata = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;

    const createdAt = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD')))

    payload = {
        ...payload,
        createdAt,
        userId: auth.uid
    }
    try {
        firestore.add({ collection: "userImages" }, payload).then((docRef) => {
            console.log("docRef === ", docRef)
        }).catch((error) => {
            console.log("Error : ", error)
        });
    } catch (error) {
        console.log("Error : ", error)
    }
};