import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { showErrorSnackbar } from "../../../store/actions/snackbar";
import _ from "lodash";
import validateEmail from '../../../helpers/validateEmail';

export default function PostcardEmailSelect(props) {
  const [users, setUsers] = useState([]);
  // const [share_users, setShareUsers] = useState([]);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch()

  const handleShareInputChange = (event, newValue) => {
    let tempData = [];
    for (let i = 0; i < newValue.length; i++) {
      let n = newValue[i];
      if (validateEmail(n.inputValue)) {
        n = {
          ...n,
          email: n.inputValue.toLowerCase(),
        };
        tempData.push(n)
      } else {
        dispatch(showErrorSnackbar("Please enter valid email address!"));
      }
      // if (n.email.includes('Add "')) {
      //   n = {
      //     ...n,
      //     email: n.email.substring(5, n.email.length - 1).toLowerCase(),
      //   };
      //   tempData.push(n);
      // } else {
      //   tempData.push(n);
      // }
    }
    let filterData = _.uniqBy(tempData, function (e) {
      return e.email;
    });
    if (filterData.length < tempData.length) {
      // setMessage("No duplicate email allowed.");
      dispatch(showErrorSnackbar("Duplicate email not allowed!!!"));
    }

    // for (let i = 0; i < filterData.length; i++) {
    //   let user = filterData[i];
    //   if (user && user.length > 0) {
    //     let value =
    //       user && user.email.length > 0 ? user.email.toLowerCase() : user.email;
    //     let index = _.findIndex(
    //       share_users,
    //       (s) =>
    //         s.user.email === value ||
    //         s.user.username === value ||
    //         s.user.phone == value
    //     );
    //     if (index > -1) {
    //       filterData.splice(index, 1);
    //       i--;
    //     }
    //   }
    // }
    setValue(filterData);
    props.setUsers(filterData);
  };

  return (
    <>
      <Autocomplete
        value={value}
        multiple
        id="tags-outlined"
        options={users.length > 0 ? users : []}
        getOptionLabel={(option) =>
          option && option.email ? option.email : "no email"
        }
        filterSelectedOptions
        filterOptions={(options, params) => {
          // setIsAlert(false);
          let user = params.inputValue;
          if (params.inputValue !== "") {
            user = user.toLowerCase();
          }
          const filtered = _.filter(options, (option) =>
            JSON.stringify(option).includes(user)
          );
          // Suggest the creation of a new value
          // if (params.inputValue !== '' && isNaN(params.inputValue))
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              email: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        onChange={(event, newValue) => handleShareInputChange(event, newValue)}

        onKeyDown={(ev, newValue) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            let data = [...value]
            data.push({
              email: ev.target.value,
              inputValue: ev.target.value
            })
            handleShareInputChange(ev, data)
          }
        }}
        renderInput={(params) => (
          <TextField
            label="Email ID"
            style={{ maxHeight: "100px" }}
            {...params}
            variant="outlined"
            placeholder={
              value && value.length > 0
                ? ""
                : "Search people through email or user name"
            }
            error={props.error}
          />
        )}
      />
      <span style={{ color: "red", fontSize: "11px" }}>
        {props.error ? "Please enter valid email id" : ""}
      </span>
    </>
  );
}
