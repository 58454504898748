import React, { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import lodash, { property } from "lodash-es";
import deepdash from "deepdash-es";
import {
  Card, CardContent, CardHeader,
  Box, Grid, Button, TextField, Icon,
  Typography, Avatar
} from "@mui/material";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import Post3 from "../../assets/images/PostCardImages/Post3.png";
import {
  setSavedComment,
  setAddNewComment
} from "../../store/actions/comments";
import { showErrorSnackbar } from "../../store/actions/snackbar";
import moment from 'moment'
import emojis from './emojis'

const useStyles = makeStyles((theme) => ({
  commentItem: {
    position: `relative`,
    marginTop: 1
  },
  commentWrap: {
    position: `relative`,
    marginLeft: `55px`
  },
  commentCard: {
    boxShadow: `none`
  },
  commentContent: {
    padding: `0 15px !important`
  },
  avatarSm: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  avatarLg: {
    width: theme.spacing(9),
    height: theme.spacing(9)
  }
}));

const CommentsMultiLevelPoem = (props) => {
  const classes = useStyles();
  const _ = deepdash(lodash);
  const [newcomment, setNewComment] = useState({});
  const [repliedComment, setrepliedComment] = useState({});
  const [origComment, setorigComment] = useState([]);
  const [showReplyTo, setshowReplyTo] = useState();
  const [commentId, setCommentId] = useState(null);
  const [mainCommentId, setMainCommentId] = useState();
  const [level, setLevel] = useState(-1);
  const [poemComments, setPoemComments] = useState({})
  const history = useHistory();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);


  useFirestoreConnect([{ collection: "users", doc: auth.uid }]);

  useFirestoreConnect([
    { collection: "comments", where: ["poemid", "==", props.post.id] },
  ])

  const { comments } = useSelector((state) => state.firestore.data);
  const { users } = useSelector((state) => state.firestore.ordered)

  useEffect(() => {
    if (comments) {
      let keys = Object.keys(comments)
      // console.log("keys ---- ", keys, comments[keys[0]].poemid)
      if (props.post.id === comments[keys[0]].poemid)
        setPoemComments(comments)
    }
  }, [comments])

  useEffect(() => {
    if (newcomment && newcomment !== undefined) {
      setNewComment(newcomment);
    }
  }, [newcomment]);

  const textInput = useRef(null);

  const handleAddReply = async (event) => {
    event.preventDefault();
    var updatedComments = {},
      oComment = {},
      commentStack = [];
    if (!newcomment.comment) {
      dispatch(showErrorSnackbar("Please enter Comments/Replies"));
      return false;
    } else {
      if (!showReplyTo) {
        dispatch(setAddNewComment({ ...newcomment }));
      } else {
        var selectedComment = {};
        _.forEach(poemComments, function (value, key) {
          if (JSON.stringify(value).indexOf(commentId) != -1) {
            selectedComment[key] = value;
          }
        });

        if (origComment[0].children) {
          //If the node has children
          commentStack.push(repliedComment.children[0]);
          commentStack.push(...origComment[0].children);

          updatedComments = JSON.stringify(commentStack);
          oComment = JSON.stringify(origComment[0].children);
          updatedComments = JSON.stringify(selectedComment).replace(
            oComment,
            updatedComments
          );
        } else {
          commentStack.push(JSON.stringify(repliedComment));
          updatedComments = JSON.stringify(selectedComment).replace(
            JSON.stringify(origComment[0]),
            commentStack[0].toString()
          );
        }

        updatedComments = JSON.parse(updatedComments);
        await dispatch(setSavedComment({ ...updatedComments }));
      }

      await firestore
        .collection("poems")
        .doc(props.post.id)
        .update({
          commentscount: props.post.commentscount ? props.post.commentscount + 1 : 1,
        });
      while (origComment.length) {
        origComment.pop();
      }
      handleFormClear();
    }
  };
  const handleReplyClick = (parent, level, id) => {
    setCommentId(id);
    origComment.push({ ...parent });
    setorigComment(origComment);
    let replacedComment = {};
    replacedComment = newcomment.comment
      ? { ...repliedComment, ...parent, children: [{ ...newcomment }] }
      : { ...repliedComment, ...parent };
    setrepliedComment({ ...replacedComment });
    setLevel({ ...level });
    setshowReplyTo("Reply to: " + parent.username);
  };

  const handleChange = (data) => {
    let avatarURL = Post3

    if (users && users.length > 0 && users[0].avatarUrl) {
      avatarURL = users[0].avatarUrl
    } else if (auth.photoURL) {
      avatarURL = auth.photoURL
    }

    if (data.isReply) {
      setshowReplyTo(true)
    } else {
      setCommentId(null)
      setshowReplyTo(false)
    }

    if (data.text.length <= 0) {
      setNewComment('');
    }
    if (data.text) {
      const newComm = {
        id: uuid(),
        username: auth.displayName ? auth.displayName : "",
        userid: auth.uid,
        avatarURL,
        date: dayjs(new Date()).format("MMMM D YYYY, h:mm A"),
        comment: data.text,
        poemid: props.post?.id,
        order: poemComments ? Object.keys(poemComments).length + 1 : 1,
        ownerId: props.post?.userId
      };
      setNewComment(newComm);
      if (showReplyTo && data.isReply) {
        setrepliedComment({ ...repliedComment, children: [{ ...newComm }] });
      }
    }
  };
  const handleFormClear = () => {
    setNewComment({
      id: "",
      username: "",
      userid: "",
      avatarURL: "",
      lastupdate: "",
      comment: "",
    });
    setrepliedComment({});
    setorigComment([]);
    setshowReplyTo("");
    setCommentId(0);
    setLevel(-1);
    setMainCommentId(0);
  };

  const handleFormClick = (event, isReply) => {
    const textFieldObject = document.querySelector('#replytext')
    if (!isReply && !textFieldObject.value) {
      setCommentId(null)
      setshowReplyTo(false)
      setNewComment({ comment: '' })
    }

    if (
      event.target.value == "" ||
      !newcomment.comment
    ) {
      event.target.value = "";
    }
  };

  const CommentGroups = (comments) => {
    return (
      <Box className={classes.commentWrap}>
        {CommentTree(comments)}{" "}
      </Box>
    );
  };

  const CommentTree = (comments, isTop = false) => {
    const commentData = Object.entries(comments).sort(
      (firstItem, secondItem) => secondItem[1].order - firstItem[1].order
    );

    const items = commentData.map((comment, i) => {
      return (
        <Box className={classes.commentItem} style={{ marginTop: 10 }}>
          <Card className={classes.commentCard}>
            <CardHeader
              avatar={
                <Avatar as="a"
                  src={comment[1].avatarURL}
                  className={isTop ? classes.avatarLg : classes.avatarSm}
                />
              }
              title={comment[1].username}
              subheader={
                comment[1].lastupdate
                  ? moment
                    .unix(comment[1].lastupdate.seconds)
                    .format("MMMM D YYYY, h:mm A")
                  : dayjs(new Date(comment[1].date)).format("MMMM D YYYY, h:mm A")
              }
            />
            <CardContent className={classes.commentContent}>
              <Typography>{comment[1].comment}</Typography>
            </CardContent>
            {isTop && <Box ml={1}>
              <Button
                size="small"
                onClick={() => handleReplyClick(comment[1], i, comment[1].id)}>
                Reply
              </Button>
            </Box>
            }
          </Card>
          {comment[1].children && CommentGroups(comment[1].children)}
          {
            comment[1].id === commentId &&
            <Box className={classes.commentWrap} style={{
              display: 'flex', flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'end', marginTop: 10
            }}
            >
              <TextField
                onChange={(e) => handleChange({ text: e.target.value, isReply: true })}
                id="replytext"
                value={newcomment.comment}
                ref={textInput}
                label="Add Reply"
                onClick={(e) => handleFormClick(e, true)}
                // autoFocus
                multiline
                rows={2}
                fullWidth
                variant="outlined"
              />
              <Box style={{ dispaly: 'flex', justifyContent: 'right', marginTop: 5 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleAddReply}>
                  <AddCircleIcon>add</AddCircleIcon>Add Reply
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleFormClear}>
                  <ClearIcon>clear</ClearIcon>Clear Reply
                </Button>
              </Box>
            </Box>
          }
        </Box >
      );
    });
    return items;
  };

  return (
    <React.Fragment>
      <Box m={2} />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Comments
          </Typography>
          {
            auth && auth.uid &&
            <Box>
              <p>{showReplyTo}</p>
              <TextField
                onChange={(e) => handleChange({ text: e.target.value, isReply: false })}
                id="replytext"
                value={!showReplyTo ? newcomment.comment : ''}
                ref={textInput}
                label="Add Comments"
                onClick={(e) => handleFormClick(e, false)}
                // autoFocus
                multiline
                rows={4}
                fullWidth
                variant="outlined"
              />
              <Box mt={2} mb={2}>
                {/* <div>
                  {emojis && emojis.map((emoji, index) =>
                    index < 5 &&
                    <p onClick={() => setNewComment(prev => ({ ...prev, comment: prev.comment + emoji.emoji }))}>
                      {emoji.emoji}
                    </p>
                  )}
                </div> */}
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleAddReply}>
                  <AddCircleIcon>add</AddCircleIcon>Add Comment
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleFormClear}>
                  <ClearIcon>clear</ClearIcon>Clear Comment
                </Button>
              </Box>
            </Box>

          }

          {poemComments &&
            <Box mb={10}>
              {CommentTree(poemComments, true)}
            </Box>
          }

        </Grid>
      </Grid>
    </React.Fragment >
  );
};
export default CommentsMultiLevelPoem;
