import React from 'react'
import add1 from "../../assets/images/dashboard/1.png";
import add2 from "../../assets/images/dashboard/2.png";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import HubPagesStyles from "../pages/Hub Pages/HubPagesStyles";
import Footer from "../Footer";
import AppCreate from "../Blog/blogSections/AppCreate";
import { Carousel } from "react-responsive-carousel";
import EssayViewCard from "./EssayViewCard";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSearchQuery } from "../../store/actions/library";
import Navbar from "../Navbar";

// import Carousel from "react-elastic-carousel";


function EssayView() {
    const classes = HubPagesStyles();
    const dispatch = useDispatch()
    const { showEssays, showEssayIndex } = useSelector(state => state.essays)

    useEffect(() => {
        dispatch(setSearchQuery())
    }, [])

    return (
        <>

            <Box
                item
                sx={{
                    flexGrow: 1,
                    width: "100%",
                    display: "flex",
                    position: "relative",
                }}
            >
                <Navbar />


                <Container
                    spacing={2}
                    sx={{
                        display: "flex",
                        width: "100%",
                        marginTop: "4rem",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10} lg={10} style={{ marginTop: "3rem", marginBottom: '2rem' }}>
                            <Box
                                sx={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            >
                                <Grid xs={12} sm={12} md={12} lg={12}>
                                    <AppCreate />
                                </Grid>

                            </Box>

                            <Carousel selectedItem={showEssayIndex || 0}>
                                {
                                    showEssays && showEssays.length > 0 ?
                                        showEssays.map((essay) =>
                                            < EssayViewCard style={{ marginTop: '-2rem', }} essay={essay} />
                                        ) : null
                                }
                            </Carousel>
                        </Grid>
                        <Grid />
                        <Grid item xs={12} md={4} lg={2} className={classes.exploreStyle} style={{ marginTop: '3rem' }}>
                            <Card className={classes.addImges}>
                                <img
                                    src={add1}
                                    alt="hi"
                                    width="100%"
                                    className={classes.hidenImg1}
                                />
                            </Card>
                            <Card className={classes.addImges} style={{ marginTop: '1rem' }}>
                                <img
                                    src={add2}
                                    alt="hi"
                                    width="100%"
                                    className={classes.hidenImg1}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box >
            <Footer />
        </>
    );
}

export default EssayView;
