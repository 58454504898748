import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	openedEssay: {
		id: null,
		category: null,
		content: '',
		image: '',
		isPublished: false,
		isReviewComplete: false,
		isReviewer: false,
		isViewed: false,
		lastUpdated: null,
		likedBy: [],
		lovedBy: [],
		reviewerEmail: null,
		star: 0,
		status: '',
		userId: null,
		updatedBy: null,
		title: null,
		views: 0
	},
	savedEssay: {
		id: null,
		category: null,
		content: '',
		image: '',
		isPublished: false,
		isReviewComplete: false,
		isReviewer: false,
		isViewed: false,
		lastUpdated: null,
		likedBy: [],
		lovedBy: [],
		reviewerEmail: null,
		status: '',
		star: 0,
		userId: null,
		updatedBy: null,
		title: null,
		views: 0
	},
	essays: [],
	savedEssays: [],
	likedEssays: [],
	trendingEssays: [],
	wallOfFameEssays: [],
	userPublishedEssays: [],
	userDraftEssays: [],
	draftEssayEdit: false,
	showEssays: [],
	showEssayIndex: 0,
	counts: [],
};

export const essays = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_ESSAYS:
			return {
				...state,
				essays: payload
			}

		case actionTypes.SET_OPENED_ESSAY:
			return {
				...state,
				openedEssay: payload,
			};

		case actionTypes.SET_SAVED_ESSAY:
			return {
				...state,
				savedEssay: payload,
			};

		case actionTypes.GET_USER_LIKED_ESSAYS:
			return {
				...state,
				likedEssays: payload,
			};
		case actionTypes.GET_TRENDING_ESSAYS:
			return {
				...state,
				trendingEssays: payload,
			};
		case actionTypes.GET_WALL_OF_FAME_ESSAYS:
			return {
				...state,
				wallOfFameEssays: payload,
			};
		case actionTypes.GET_USER_PUBLISHED_ESSAYS:
			return {
				...state,
				userPublishedEssays: payload,
			};
		case actionTypes.GET_USER_DRAFT_ESSAYS:
			return {
				...state,
				userDraftEssays: payload,
			};
		case actionTypes.SET_USER_DRAFT_ESSAY_EDIT:
			return {
				...state,
				draftEssayEdit: payload,
			};

		case actionTypes.SET_SHOW_ESSAY:
			return {
				...state,
				showEssays: payload,
			};

		case actionTypes.SET_SHOW_ESSAY_INDEX:
			return {
				...state,
				showEssayIndex: payload,
			};
		case actionTypes.COUNTS:
			return {
				...state,
				counts: payload,
			};
		default:
			return state;
	}
};


export const userEssays = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_USER_ESSAYS:
			return {
				...state,
				userEssays: payload
			}

		default:
			return state;
	}
};
