import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	openedPoem: {
		id: null,
		body: null,
		category: null,
		content: '',
		image: '',
		isPublished: false,
		isReviewComplete: false,
		isReviewer: false,
		isViewed: false,
		lastUpdated: null,
		likedBy: [],
		lovedBy: [],
		reviewerEmail: null,
		status: '',
		star: 0,
		userId: null,
		updatedBy: null,
		title: null,
		views: 0
	},
	savedPoem: {
		id: null,
		body: null,
		category: null,
		content: '',
		image: '',
		isPublished: false,
		isReviewComplete: false,
		isReviewer: false,
		isViewed: false,
		lastUpdated: null,
		likedBy: [],
		lovedBy: [],
		reviewerEmail: null,
		status: '',
		star: 0,
		userId: null,
		updatedBy: null,
		title: null,
		views: 0
	},
	poems: [],
	savedPoems: [],
	likedPoems: [],
	trendingPoems: [],
	wallOfFamePoems: [],
	userPublishedPoems: [],
	userDraftPoems: [],
	draftPoemEdit: false,
	counts: [],
	showPoems: [],
	showPoemIndex: 0,
	globalPoems: []
};

export const poems = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_POEMS:
			return {
				...state,
				poems: payload
			}

		case actionTypes.SET_OPENED_POEM:
			return {
				...state,
				openedPoem: payload,
			};

		case actionTypes.SET_SAVED_POEM:
			return {
				...state,
				savedPoem: payload,
			};

		case actionTypes.GET_USER_LIKED_POEMS:
			return {
				...state,
				likedPoems: payload,
			};
		case actionTypes.GET_TRENDING_POEMS:
			return {
				...state,
				trendingPoems: payload,
			};
		case actionTypes.GET_WALL_OF_FAME_POEMS:
			return {
				...state,
				wallOfFamePoems: payload,
			};
		case actionTypes.GET_USER_PUBLISHED_POEMS:
			return {
				...state,
				userPublishedPoems: payload,
			};
		case actionTypes.GET_USER_DRAFT_POEMS:
			return {
				...state,
				userDraftPoems: payload,
			};
		case actionTypes.SET_USER_DRAFT_POEM_EDIT:
			return {
				...state,
				draftPoemEdit: payload,
			};
		case actionTypes.COUNTS:
			return {
				...state,
				counts: payload,
			};
		case actionTypes.SET_SHOW_POEM:
			return {
				...state,
				showPoems: payload,
			};

		case actionTypes.SET_SHOW_POEM_INDEX:
			return {
				...state,
				showPoemIndex: payload,
			};

		case actionTypes.GET_GLOBAL_POEMS:
			return {
				...state,
				globalPoems: payload,
			};
		
		default:
			return state;
	}
};