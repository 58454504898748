
import React from 'react'
import { firebase, db, storage, auth } from '../config/fbConfig'
const fileUploadService = async(file, path) => {
  console.log("file", file)
  let response;
    try {
      return new Promise((resolve, reject) => {
        storage.ref(path).put(file)
        .then(async (snapshot) => {
          const fileDownloadUrl = await storage.ref(path).getDownloadURL();
          console.log('Uploaded a base64 string!', fileDownloadUrl);
          resolve(fileDownloadUrl)
        })
        .catch(error => {
          console.log('Failed to upload', error);
          reject("")
        })
      });
    } catch(error) {
      console.log("Something went wrong while converting file to base64")
      response = ""
      return response
    }
}

export default fileUploadService