import { triggerReducer, actionTypes } from '.';

export const showSuccessSnackbar = payload => dispatch => {
    dispatch(triggerReducer(actionTypes.SNACKBAR_SUCCESS, { payload }));
};

export const showErrorSnackbar = payload => dispatch => {
    dispatch(triggerReducer(actionTypes.SNACKBAR_ERROR, { payload }));
};

export const showInfoSnackbar = payload => dispatch => {
    dispatch(triggerReducer(actionTypes.SNACKBAR_INFO, { payload }));
};

export const clearSnackbar = () => dispatch => {
    dispatch(triggerReducer(actionTypes.SNACKBAR_CLEAR, { payload: null }));
};

export const togglePageLoading = payload => dispatch => {
    dispatch(triggerReducer(actionTypes.IS_PAGE_LOADING, { payload }));
};
