import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import aboutl from "../../../assets/images/about/Group-775.png";
import article102 from '../../../assets/images/article/article102.jpg';
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function WhatIsJournal() {
    const classes = DiaryblogsStyles();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>What is a Journal / Journal Writing / Quill At Will
                </title>
                <meta
                    name="title"
                    content="What is a Journal / Journal Writing / Quill At Will"
                />
                <meta
                    name="description"
                    content="A journal is a place where you can record 
                    your ideas, feelings, and observations.
                     You can write in your journal every day 
                    or only when the impulse strikes."
                />
                <meta
                    name="keywords"
                    content="Journal Writing"
                />
            </Helmet>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px',
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        What is a Journal? <br />
                        All you need to know about it


                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    { /* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", }}
                    >
                        “Your Journal is like your best friend, You don't have to pretend with it, you can be honest and write exactly how you feel”
                        <br />
                    </Typography>
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontSize: "17px", fontFamily: "Museo 700", textAlign: "end" }} >
                        ― Bukola Ogunwale.
                </Typography> */}

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={article102} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        Writing a journal is a great way to destress and keep track of your life,
                                        but starting one can be overwhelming at first. Sometimes people mix up the concepts of a
                                        diary and a journal, but both are pretty different in terms of utility and writing formats.
                                        Let's understand what a journal is, how it differs from a diary, and the primary purpose of
                                        writing a journal.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>



                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                {/*<Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Benefits of writing online diaries
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The online diary is even more interesting and engaging!
                                    <br />
                                    It offers great features to help you write your diary more creatively and efficiently. Also, the best online diaries provide premium features to even publish your diary and help you become a published author.

                                    <br />
                        </Typography>*/}

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    What is a journal?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    One of the most effective, time-tested ways to keep a journal is to write about your day.
                                    It gives one a private space to put down their thoughts. You can write through unhappy thoughts,
                                    stifled feelings, or savor the memories of a pleasant time with friends. A journal records an
                                    individual's thoughts, observations, and experiences. You can write a journal when you feel the
                                    urge to write. You can be honest and write your truest and most profound thoughts without being
                                    judged.
                                    <br /><br />
                                    People may have reluctance when sharing their opinions and views with others. In such cases,
                                    it can be quite therapeutic to write in a journal and approach the writing process as though you
                                    were chatting with a close friend. Your secret or personal ideas will always be with you in this way.
                                    This not only helps build confidence but also helps you become the best version of yourself.
                                    <br /> <br />
                                    You can use a journal as a form of reflective writing to think about and react to something you have
                                    read or learned. It is a process of recording personal insights, reflections, and questions. It may
                                    include thoughts about daily experiences, reading, current events, and more. Journal writing is a good
                                    form of self-reflection because a journal is a perfect place to consider steps that will change your
                                    life for the better.
                                    <br /> <br />
                                    However, journal entries shouldn't just restate what you've read or be solely about your emotions.
                                    They should instead demonstrate your capacity to conduct a critical investigation. Critical inquiry
                                    is the process of gathering and evaluating ideas or data.
                                    <br /> <br />
                                    Because journal writing allows you to explore different ideas and strategies, it's also a form of
                                    exploratory writing. Remember, a class journal is not the same as a private journal; the writing may
                                    be a little bit more formal, although not as formal as an essay. A personal journal is written to
                                    express yourself irrespective of the pattern or format.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Purpose of Writing a Journal

                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Besides being a reflection of yourself, journal writing can be a great place to explore ideas and
                                    improve your writing skills, among other benefits. Journal writing can also provide you with ample advantages. These are:

                                    <br />
                                </Typography>
                                <ul>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Become more confident about writing.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Broaden your perspective on a wide range of topics.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Learn to gather material for later essays.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Spend more time on self-reflection.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Expressing without fear of criticism.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Expressing yourself with creativity.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        Self-reflection.
                                    </li>
                                    <li className={classes.headingTypo1} style={{ fontSize: "15px" }}>
                                        View a recap of  your day.
                                    </li>
                                </ul>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Additionally, journal writing can help alleviate the stress associated with writing,
                                    a task some people find daunting. When you're journaling, there's no need to edit your work or
                                    worry about language and spelling. Instead, you can jot down ideas and develop your thoughts in your
                                    journal. Regular writing also makes writing simpler, making it easier for you to approach professional
                                    documents, presentations, and polished articles.
                                    <br /><br />
                                    Express yourself creatively with journals. Whether you're a morning person or a night owl, you can write a
                                    journal at any hour of the day. It is one of the best ways to introspect and track yourself as an
                                    individual. Make your journal uniquely yours by decorating the pages with craft supplies.
                                    You can leave space to write entries or create expressive collages as your journaling experience.
                                    You can create thoughtful questions to ask yourself and can work on yourself through journaling.
                                </Typography>
                            </Box>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatIsJournal;