import { triggerReducer, actionTypes } from '.';
import { db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar'
import store from '../store'
import moment from 'moment'

///new code

export const setOpenedJournal = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_JOURNAL, {
            payload
        })
    );
};


export const clearJournal = () => (dispatch) => {
    let openedJournal = {
        journalId: null,
        page: null,
        events: null,
        gratefulfor: null,
        on: null,
        userid: null
    }

    dispatch(
        triggerReducer(actionTypes.SET_OPENED_JOURNAL, {
            payload: openedJournal
        })
    );
};

// export const addJournalPage = (payload) => async (dispatch, getState) => {
//     const { firestore } = store;
//     const { openedJournal } = getState().journal;
//     let id = payload.id
//     delete payload.id
//     let newPayload = {
//         ...payload,
//         on: new Date(payload.on.replaceAll('-', '/'))
//     }

//     if (openedJournal && openedJournal.id) {
//         // payload.on = new Date(payload.on.replaceAll('-', '/'));
//         db.collection("journal").doc(id).update(newPayload)
//             .then(() => {
//                 dispatch(
//                     triggerReducer(actionTypes.SET_SAVED_JOURNAL, { openedJournal })
//                 );
//                 // dispatch(showSuccessSnackbar("Journal updated successfully!!!"));
//             }).catch((error) => {
//                 console.log("Error == ", error)
//                 // dispatch(showErrorSnackbar("Error in updating Journal"));
//             });
//     } else {
//         await firestore.add({ collection: "journal" }, {
//             page: payload.page,
//             userid: payload.userid,
//             on: new Date(payload.on.replaceAll('-', '/')),
//             events: payload.events,
//             gratefulfor: payload.gratefulfor,
//         }).then((resp) => {
//             payload.id = resp.id

//             dispatch(
//                 triggerReducer(actionTypes.SET_ADD_JOURNAL_PAGE, {
//                     payload
//                 })
//             );
//             // dispatch(showSuccessSnackbar('Journal page added successfully!!!'))
//         }).catch((error) => {
//             console.log("Error == ", error)
//             // dispatch(showErrorSnackbar("Error in updating Journal"));
//         });
//     }
// };


export const addJournalPage = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { openedJournal } = getState().journal;
    const { tenantId } = getState().user;

    let docId = payload?.journalId
    let newPayload = {
        on: new Date(payload.on.replaceAll('-', '/')),
        grateful: payload.grateful,
        events: payload.events,
        userid: payload.userid,
        tenantId
    }

    if (payload && payload.page) {
        newPayload = {
            ...newPayload,
            page: payload.page
        }
    }

    if (docId) {
        db.collection("journal").doc(docId).update(newPayload)
            .then((resp) => {
                newPayload = {
                    ...newPayload,
                    journalId: docId
                }
                dispatch(
                    triggerReducer(actionTypes.SET_SAVED_JOURNAL, { payload: newPayload })
                );

            }).catch((error) => {
                console.log("Error update journal -- ", error)
            });
    } else {
        await firestore.add({ collection: "journal" }, newPayload).then((resp) => {
            newPayload.journalId = resp.id
            dispatch(
                triggerReducer(actionTypes.SET_JOURNAL_ID, { payload: resp.id })
            );
            dispatch(
                triggerReducer(actionTypes.SET_ADD_JOURNAL_PAGE, {
                    payload: newPayload
                })
            );
        });
    }
};

export const getJournalPage = (payload) => async (dispatch, getState) => {
    const { openedJournal } = getState().journal;
    const on = payload.on.replaceAll('-', '/');
    try {
        const journalPage = await db
            .collection('journal')
            .where('userid', '==', payload.userid)
            .where('on', '==', new Date(on))
            .limit(1)
            .get()

        if (journalPage && journalPage.docs && journalPage.docs.length > 0) {
            payload.journalId = journalPage.docs[0].id;
            payload.page = journalPage.docs[0].data().page;
            payload.events = journalPage.docs[0].data().events
            payload.gratefulfor = journalPage.docs[0].data().gratefulfor
        }
        else {        // payload.on = new Date(payload.on.replaceAll('-', '/'));

            openedJournal.page = '';
            payload.page = '';
            payload.journalId = ''
            payload.events = ''
            payload.gratefulfor = ''

        }
    } catch (error) {
        console.log("error --- ", error)
    }

    dispatch(
        triggerReducer(actionTypes.SET_GET_JOURNAL_PAGE, { payload })
    )
    dispatch(
        triggerReducer(actionTypes.SET_JOURNAL_ID, { payload: payload.journalId })
    );
};
// Service to get journal from db and store in redux
export const getJournal = (payload) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.GET_JOURNAL_LOADER,
        journalLoader : true,
    });
    db
    .collection('journal')
    .where('userid', '==', payload.userid)
    .get()
    .then((querySnapshot) => {
        // console.log("querySnapshot.docs.length", querySnapshot.docs.length)
        const diaryObject = {}
        if(querySnapshot.docs.length > 0) {
            querySnapshot.docs.forEach(documentSnapshot => {
                const docSnap = documentSnapshot.data()
                const docSnapDate = moment(new Date(docSnap.on.seconds*1000)).format('YYYY/MM/DD')
                diaryObject[docSnapDate] = {
                    journalId: documentSnapshot.id,
                    page: docSnap.page,
                    events: docSnap?.events??"",
                    gratefulfor: docSnap?.gratefulfor??'',
                    on: docSnap.on,
                }
                // console.log("querySnapshot.docs.length",diaryObject)
            })
            dispatch({
                type: actionTypes.GET_JOURNAL,
                myJournal : diaryObject,
                journalLoader : false,
            });
        } else {
            dispatch({
                type: actionTypes.GET_JOURNAL,
                myJournal : diaryObject,
                journalLoader : false,
            });
        }
   
    })
    .catch(error => {
        console.log("error", error);
        dispatch({
            type: actionTypes.GET_JOURNAL_LOADER,
            journalLoader : false,
        });
    })
};

// Service to save journal by date with updated data in redux storage
export const saveJournal = (payload) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.GET_JOURNAL_LOADER,
        journalLoader : true,
    });
    dispatch({
        type: actionTypes.GET_JOURNAL,
        myJournal : payload,
        journalLoader : false,
    });
};

// Service to save journal by date with updated data in firebase databases
export const saveJournalPageByDate = (payload, journalSaveParams) => async (dispatch, getState) => {
    const docId = payload?.journalId;
    if(docId) {
        db
        .collection("journal")
        .doc(docId)
        .update(payload)
        .then((response) => {
            console.log("saveJournalPageByDate->>>>>update_response::Saved successfully")
        })
        .catch(error => {
            console.log("saveJournalPageByDate->>>>>update_error", error)
        })
    } else {
        db
        .collection("journal")
        .add(payload)
        .then((response) => {
            console.log("saveJournalPageByDate->>>>>add_response:Updated successfully", response)
            payload.journalId = response.id
            journalSaveParams[moment(payload.on).format('YYYY/MM/DD')] = payload
            dispatch(saveJournal(journalSaveParams));
        })
        .catch(error => {
            console.log("saveJournalPageByDate->>>>>add_error", error)
        })
    }
};

export const saveJournalPage = (payload) => async (dispatch, getState) => {
    const { openedJournal } = getState().journal;
    payload.on = new Date(payload.on.replaceAll('-', '/'));
    db.collection("journal").doc(openedJournal.journalId).update(payload)
        .then(() => {
            dispatch(triggerReducer(actionTypes.SET_SAVED_JOURNAL, { openedJournal }));
            // dispatch(showSuccessSnackbar("Journal updated successfully!!!"));
        }).catch((error) => {
            console.log("Error === ", error)
            // dispatch(showErrorSnackbar("Error in updating Journal"));
        });
};