import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Typography, Card, CardMedia, CardContent, CardActions, Button } from "@mui/material";
import DiaryblogsStyles from "./DiaryblogsStyles";
import logo1 from '../../../assets/images/logo1.png';
import { useHistory, useParams } from "react-router-dom";
import PoetryImg from '../../../assets/images/poetryImg.jpg';
import blogdemystif from '../../../assets/images/article/blogdemystif.jpg';
import poemwomen from '../../../assets/images/article/poemwomen.jpeg'
import diary1 from '../../../assets/images/article/diary1.png';
import diary2 from '../../../assets/images/article/diary-2.png';
import diary4 from '../../../assets/images/article/diary-5.png';
import article101 from '../../../assets/images/article/article101.jpg';
import article102 from '../../../assets/images/article/article102.jpg'
import diaryImgs from '../../../assets/images/diaryImg.png';
import DiaryPersonalBlog from "./DiaryPersonalBlog";
import DiaryBenefitBlog from "./DiaryBenefitBlog";
import DiaryTypesBlog from "./DiaryTypesBlog";
import DiaryHistoryBlog from "./DiaryHistoryBlog";
import Poetry from "./Poetry";
import StartWritingBlog from "./StartWritingBlog";
import WhatIsJournal from "./WhatIsJournal";
import WritePoetry from "./WritePoetry";
import DemystifiedBlog from "./DemystifiedBlog";
const Article = (props) => {
    const classes = DiaryblogsStyles();
    const history = useHistory();
    const [value, setValue] = useState(null)
    const { article } = useParams()

    useEffect(() => {
        switch (article) {
            case 'online-personal-diary':
                onClickArticle(0);
                break;
            case 'benefits-of-writing-diary':
                onClickArticle(1);
                break;
            case 'types-of-diary':
                onClickArticle(2);
                break;
            case 'history-of-postcards':
                onClickArticle(3);
                break;
            case 'history-of-poetry':
                onClickArticle(4);
                break;
            case 'start-writing-diary':
                onClickArticle(5);
                break;
            case 'what-is-journal':
                onClickArticle(6);
                break;
            case 'benefits-of-blogging-for-students':
                onClickArticle(7);
                break;
            default:
                onClickArticle(null);
                break;
        }
    }, [])

    const handleShowArticle = () => {
        switch (value) {
            case 0: return (<DiaryPersonalBlog />); break;
            case 1: return <DiaryBenefitBlog />; break;
            case 2: return <DiaryTypesBlog />; break;
            case 3: return <DiaryHistoryBlog />; break;
            case 4: return <Poetry />; break;
            case 5: return <StartWritingBlog />; break;
            case 6: return <WhatIsJournal />; break;
            case 7: return <WritePoetry />; break;
            case 8: return <DemystifiedBlog />; break;



        }
    }
    useEffect(() => {
        if (props.showArticle && props.articleValue !== null) {
            setValue(props.articleValue)
        }
    }, [props.articleValue, props.showArticle])

    const onClickArticle = (value, url) => {
        if (value !== null) {
            props.setShowArticle(true)
            props.setArticleValue(value)
            history.push(url)
        } else {
            props.setShowArticle(false)
            props.setArticleValue(null)
            // history.push('/hub/articles')
        }
    }

    const handleOnClickBack = () => {
        props.setShowArticle(false);
        history.push('/hub/articles');
    }

    return (
        props.showArticle ?
            <>
                <Button variant="contained"
                    style={{
                        marginBottom: "1rem",
                        color: '#fff',
                        cursor: 'pointer',
                        fontFamily: 'Museo700'
                    }}
                    onClick={handleOnClickBack}
                >{`  < Go Back`}</Button>
                {handleShowArticle()}
            </>
            :
            <>
                <Box
                    style={{
                        background: "#fff",
                        // marginTop: '6rem',
                    }}
                >
                    <Typography style={{ textAlign: 'left', fontSize: '40px', padding: '20px', fontFamily: 'Museo700', color: "darkblue" }}>
                        Articles
                    </Typography>
                    <Container className={classes.container}>

                        <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} style={{ margin: 'auto', }}>
                            <Grid item xs={12} sm={12} md={4} lg={4} style={{ margin: 'auto' }}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={blogdemystif}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>

                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(8, "/hub/benefits-of-blogging-for-students")}
                                        //onClick={() => history.push("/hub/online-personal-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                The Blog- Demystified
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                Reasons to Blog & Benefits of Blogging for Students.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            // marginTop: '2rem',
                                            display: "flex",
                                            justifyContent: "space-between",

                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(8, "/hub/benefits-of-blogging-for-students")}
                                                // onClick={() => history.push("/hub/online-personal-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} style={{ margin: 'auto' }}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={poemwomen}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>

                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(7, "/hub/how-to-write-poetry")}
                                        //onClick={() => history.push("/hub/online-personal-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                How to write poetry : A guide
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                A 5 Step Guide to Writing Poems for Beginners with tips and tricks.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            // marginTop: '2rem',
                                            display: "flex",
                                            justifyContent: "space-between",

                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(7, "/hub/how-to-write-poetry")}
                                                // onClick={() => history.push("/hub/online-personal-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} style={{ margin: 'auto' }}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={article102}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>

                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(6, "/hub/what-is-journal")}
                                        //onClick={() => history.push("/hub/online-personal-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                Journal-All you need to know about it
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                Writing a journal is a great way to destress and keep track of your life, but starting one can be overwhelming at first.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            // marginTop: '2rem',
                                            display: "flex",
                                            justifyContent: "space-between",

                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(6, "/hub/what-is-journal")}
                                                // onClick={() => history.push("/hub/online-personal-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} style={{ margin: 'auto' }}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={article101}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>

                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(5, "/hub/start-writing-diary")}
                                        //onClick={() => history.push("/hub/online-personal-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                How do I Start Writing a Diary?
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                We all understand diaries and their importance in our lives.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            // marginTop: '2rem',
                                            display: "flex",
                                            justifyContent: "space-between",

                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(5, "/hub/start-writing-diary")}
                                                // onClick={() => history.push("/hub/online-personal-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} style={{ margin: 'auto' }}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={diary1}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>

                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(0, "/hub/online-personal-diary")}
                                        //onClick={() => history.push("/hub/online-personal-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                What is a Diary? Why opt for an Online Personal Diary?
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                A diary is a place where an individual's daily routine, thoughts.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            // marginTop: '2rem',
                                            display: "flex",
                                            justifyContent: "space-between",

                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(0, "/hub/online-personal-diary")}
                                                // onClick={() => history.push("/hub/online-personal-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={diary2}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>
                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(1, "/hub/benefits-of-writing-diary")}

                                        // onClick={() => history.push("/hub/benefits-of-writing-diary")}
                                        >
                                            <Typography style={{ fontSize: "16px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                Benefits of Diary Writing and Journaling
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                Do you know about intrapersonal communication and its importance in one's life?
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // marginTop: '2rem'
                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(1, "/hub/benefits-of-writing-diary")}

                                                // onClick={() => onClickArticle(1)}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={diaryImgs}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>
                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(2, "/hub/types-of-diary")}
                                        // onClick={() => history.push("/hub/types-of-diary")}
                                        >
                                            <Typography style={{ fontSize: "17px" }}
                                                gutterBottom

                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                Types of Diary, Journals and why it is important
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                Writing a secret diary allows you to focus on your writing without worrying about your audience.
                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>


                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // marginTop: '2rem'
                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(2, "/hub/types-of-diary")}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={diary4}
                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>
                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(3, "/hub/history-of-postcards")}

                                        // onClick={() => history.push("/hub/history-of-postcards")}
                                        >
                                            <Typography
                                                gutterBottom style={{ fontSize: "17px" }}
                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                HISTORY OF THE HUMBLE POSTCARD
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                If you are under 30, it’s quite likely you haven’t even seen a postcard!


                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>


                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // marginTop: '2rem'
                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}
                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(3, "/hub/history-of-postcards")}
                                                // onClick={() => onClickArticle(3)}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card
                                    sx={{
                                        background: '#2e64b4',
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "25px !important",
                                    }}
                                >
                                    <CardMedia style={{ width: "100%", height: '230px', objectFit: 'cover' }}
                                        component="img"
                                        alt="green iguana"
                                        src={PoetryImg}

                                    />


                                    <CardContent sx={{ flexGrow: 1, display: 'flex', height: '150px' }}>
                                        <Grid item xs={12} md={12} style={{ cursor: "pointer" }}
                                            onClick={() => onClickArticle(4, "/hub/history-of-poetry")}
                                        // onClick={() => history.push("/hub/history-of-poetry")}
                                        >
                                            <Typography
                                                gutterBottom style={{ fontSize: "17px" }}
                                                component="div"
                                                color="#ffffffcf"
                                                fontFamily="Museo700"
                                            >
                                                POETRY- INDIA AND THE WEST
                                                {/* {post?.title} */}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="#ffffffcf"
                                                fontFamily="Museo300"
                                            >
                                                A detailed comparative study of the origins of poetry in different parts of the world.



                                                {/* {ReactHtmlParser(post?.body?.substring(0, 50))}.... */}
                                            </Typography>
                                        </Grid>


                                    </CardContent>
                                    <CardActions>
                                        <Grid container md={12} lg={12} style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // marginTop: '2rem'
                                        }}>
                                            <Grid item md={5} lg={5} >
                                                <img
                                                    className="d-block w-100"
                                                    style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        // borderRadius: "50%",
                                                    }}
                                                    src={logo1}

                                                    // src={post?.user?.avatarUrl}
                                                    alt="First slide"
                                                />
                                            </Grid>
                                            <Grid item md={1} lg={1}>

                                            </Grid>

                                            <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', paddingRight: '10px' }}>
                                                <Button variant="contained" style={{
                                                    background: "rgb(255, 193, 7)",
                                                    color: "rgb(255, 255, 255)",
                                                    // padding: "6px 30px 6px 30px"
                                                }}
                                                    onClick={() => onClickArticle(4, "/hub/history-of-poetry")}
                                                // onClick={() => onClickArticle(4)}
                                                >
                                                    Read More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <br />
                    </Container>
                </Box>
                {/* <Footer /> */}
            </>
    )
}

export default Article;