import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import moment from 'moment'

let subscriptionData = [{
    name: 'Freemium',
    type: 'freemium',
    subText: 'Free Use',
    price: 0,
    features: ['10 Blogs per month', 'Publish 5 Poems', 'Send 5 postcards', 'Up-to 5 Essays', 'Earn Badges & Currency', 'Digital Pets'],
    blogs: 10,
    poems: 5,
    postcard: 5,
    essays: 5
}, {
    name: 'Premium',
    type: 'premium',
    subText: '₹199/- For 3 Months',
    price: 199,
    features: ['Unlimited Blogs, Poems, Essays and Books', 'Badges/Currency can be exchanged for Gift Vouchers', 'Premium Stable with signature Digital Pets'],
    blogs: 0,
    poems: 0,
    postcard: 0,
    essays: 0
}]


export const addSubscription = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    try {
        db.collection('subscriptions').get().then(snap => {
            let size = snap.size
            if (size == 0) {
                for (let i = 0; i < subscriptionData.length; i++) {
                    let payload = subscriptionData[i]
                    firestore.add({ collection: "subscriptions" }, payload).then((docRef) => {
                        console.log("docRef : ", docRef)
                    }).catch((error) => {
                        console.log("Error : ", error)
                    });
                }

            }
        });

    } catch (error) {
        console.log("Error : ", error)
    }
};

export const getSubscription = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    try {
        db.collection('subscriptions').get().then(snap => {
            let subscriptions = []
            snap.docs.forEach(s => {
                let subscription = s.data()
                subscription = {
                    ...subscription,
                    id: s.id
                }
                subscriptions.push(subscription)
            })
            dispatch(triggerReducer(actionTypes.GET_SUBSCRIPTION, { payload: subscriptions }));
        });

    } catch (error) {
        console.log("Error : ", error)
    }
};

export const addUserSubscription = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const validTill = firebase.firestore.Timestamp.fromDate(new Date(moment().add(90, 'days').format('YYYY-MM-DD')))
    const createdAt = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD')))

    let payloadData = {
        userId: auth.uid,
        validTill,
        createdAt,
        subscriptionId: 'df3ovneaAcgZkN92FJct',
        paymentId: 'df3ovneaAcgZkN92FJct'
    }
    try {
        firestore.add({ collection: "userSubscriptions" }, payloadData).then((docRef) => {
            let data = {
                ...payloadData,
                id: docRef?.id
            }

            dispatch(triggerReducer(actionTypes.ADD_USER_SUBSCRIPTION, { payload: data }));
        }).catch((error) => {
            console.log("Error : ", error)
        });
    } catch (error) {
        console.log("Error : ", error)
    }
};

export const getUserSubscription = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const today = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD')))

    try {
        db.collection('userSubscriptions')
            .where('userId', '==', auth.uid)
            .where('validTill', '>=', today)
            .orderBy('validTill', 'desc')
            .limit(1)
            .get().then(async snap => {
                if (snap && snap.docs && snap.docs.length > 0) {
                    let subscriptionData = snap.docs[0].data()
                    subscriptionData = {
                        ...subscriptionData,
                        id: snap.docs[0].id
                    }
                    let subscription = await db.collection("subscriptions").where(firebase.firestore.FieldPath.documentId(), '==', subscriptionData.subscriptionId).get()
                    if (subscription && subscription.docs && subscription.docs.length > 0) {
                        let data = subscription.docs[0].data()

                        subscriptionData = {
                            ...subscriptionData,
                            ...data
                        }
                    }
                    dispatch(triggerReducer(actionTypes.GET_USER_SUBSCRIPTION, { payload: subscriptionData }));
                    // dispatch(triggerReducer(actionTypes.SET_PREMIUM_USER_FLAG, { payload: true }));
                }
                // dispatch(triggerReducer(actionTypes.SET_PREMIUM_USER_STATUS_FLAG, { payload: true }));
            });

    } catch (error) {
        console.log("Error : ", error)
    }
};


export const getUserAllowedContent = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const today = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD')))

    try {

        const startDate = firebase.firestore.Timestamp.fromDate(new Date(moment().format("YYYY-MM-01")))
        let userContent = {
            blogs: 0,
            essay: 0,
            postcard: 0,
            poems: 0
        }
        await db.collection('posts')
            .where('userId', '==', auth.uid)
            .where('lastUpdate', '>=', startDate)
            .get()
            .then(snap => {
                userContent = {
                    ...userContent,
                    blogs: snap.size
                }
            })
        await db.collection('postcard')
            .where('userId', '==', auth.uid)
            .where('date', '>=', startDate)
            .get()
            .then(snap => {
                userContent = {
                    ...userContent,
                    postcard: snap.size
                }
            })
        await db.collection('poems')
            .where('userId', '==', auth.uid)
            .where('lastUpdated', '>=', startDate)
            .get()
            .then(snap => {
                userContent = {
                    ...userContent,
                    poems: snap.size
                }
            })
        await db.collection('essay')
            .where('userId', '==', auth.uid)
            .where('lastUpdated', '>=', startDate)
            .get()
            .then(snap => {
                userContent = {
                    ...userContent,
                    essay: snap.size
                }
            })
        dispatch(triggerReducer(actionTypes.SET_USER_ALLOWED_CONTENT, { payload: userContent }));

    } catch (error) {
        console.log("Error : ", error)
    }
};