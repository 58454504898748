import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
    habits :[],
    openedHabit: {
        id: null,
        habit: null,
        userId: null,
        date: [],
        status: null
    }
};

export const habit = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.GET_USER_HABITS:
            return {
                ...state,
                habits: payload,
            };
        case actionTypes.SET_USER_HABITS:
            return {
                ...state,
                habits: payload
            }
            case actionTypes.UPDATE_USER_HABITS:
                return {
                    ...state,
                    habits: payload
                }
                case actionTypes.DELETE_USER_HABITS:
            return {
                ...state,
                habits: payload
            }
        default:
            return state;
    }
};
