import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar'
import { updateUser } from "./user";
import { addQuinns } from "./dashboardActions";
import store from '../store'
import moment from 'moment'

export const setOpenedDiary = (payload) => (dispatch) => {
  dispatch(
    triggerReducer(actionTypes.SET_OPENED_DIARY, {
      payload
    })
  );
};


export const clearDiary = () => (dispatch) => {
  let openedDiary = {
    diaryId: null,
    page: null,
    on: null,
    userid: null,
    feelings: null
  }

  dispatch(
    triggerReducer(actionTypes.SET_OPENED_DIARY, {
      payload: openedDiary
    })
  );
};

export const addDiaryPage = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  let docId = payload?.diaryId
  let newPayload = {
    on: new Date(payload.on.replaceAll('-', '/')),
    color: payload?.color,
    feelings: payload?.feelings,
    userid: payload?.userid
  }
  if (payload && payload.diaryPage) {
    newPayload = {
      ...newPayload,
      page: payload.diaryPage,
    }
  }

  if (docId) {
    db.collection("diary").doc(docId).update(newPayload)
      .then((resp) => {
        newPayload = {
          diaryPage: payload.diaryPage
        }
        delete newPayload.page
        dispatch(
          triggerReducer(actionTypes.SET_SAVED_DIARY, { payload: newPayload })
        );
        // if (!payload.hasOwnProperty('feelingChange'))
        //     dispatch(showSuccessSnackbar("Diary/Journal updated successfully!!!"));
      }).catch((error) => {
        console.log("Error : ", error)
        // if (!payload.hasOwnProperty('feelingChange'))
        //     dispatch(showErrorSnackbar("Error in updating Diary/Journal"));
      });
  } else {
    await firestore.add({ collection: "diary" }, newPayload).then((resp) => {
      newPayload = {
        diaryId: resp.id,
        diaryPage: payload.diaryPage
      }
      delete newPayload.page

      dispatch(
        triggerReducer(actionTypes.SET_ADD_DIARY_PAGE, {
          payload: newPayload
        })
      );

      // if (!payload.hasOwnProperty('feelingChange'))
      //     dispatch(showSuccessSnackbar('Diary/Journal page added successfully!!!'))
    });
  }
};

export const saveDiaryPage = (payload) => async (dispatch, getState) => {
  const { openedDiary } = getState().diary;
  payload.on = new Date(payload.on.replaceAll('-', '/'));
  db.collection("diary").doc(openedDiary.diaryId).update(payload)
    .then(() => {
      dispatch(
        triggerReducer(actionTypes.SET_SAVED_DIARY, { openedDiary })
      );
      // dispatch(showSuccessSnackbar("Diary/Journal updated successfully!!!"));
    }).catch((error) => {
      console.log("error -- ", error)
      dispatch(showErrorSnackbar("Error in updating Diary/Journal"));
    });
};

export const getDiaryPage = (payload) => async (dispatch, getState) => {
  const { openedDiary } = getState().diary;
  const on = payload.on.replaceAll('-', '/');
  try {
    const diaryPageData = await db
      .collection('diary')
      .where('userid', '==', payload.userid)
      .where('on', '==', new Date(on))
      .limit(1)
      .get()

    if (diaryPageData && diaryPageData.docs && diaryPageData.docs.length > 0) {
      payload.diaryId = diaryPageData.docs[0].id;
      payload.diaryPage = diaryPageData.docs[0].data().page;
      payload.feelings = diaryPageData.docs[0].data().feelings
      payload.color = diaryPageData.docs[0].data().color ?? '#a093c7'
    }
    else {
      clearDiary()
    }
  } catch (error) {
    console.log("error --- ", error)
  }

  dispatch(
    triggerReducer(actionTypes.SET_GET_DIARY_PAGE, { payload })
  )
};

export const setDiaryDates = (payload) => async (dispatch, getState) => {
  const datesArray = [];
  // const on = payload.on.replaceAll('-', '/');
  const diaryPage = await db
    .collection("diary")
    .where('userid', '==', payload.userid)
    // .where('on','<',new Date(on)) //Todo: Fix this bug
    .limit(6)
    .get()
  diaryPage.forEach((doc) => {
    datesArray.push({ 'on': doc.data().on.toDate(), 'feelings': doc.data().feelings })
  });
  payload.dates = datesArray;

  dispatch(
    triggerReducer(actionTypes.SET_DIARY_DATES, { payload })
  )
};

export const getDiaryDates = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  // const today = moment()
  // const startOfYear = moment().startOf('year').format('YYYY/MM/DD')

  let startDate = new Date(moment(payload.startDate).format('YYYY/MM/DD'))
  let endDate = new Date(moment(payload.endDate).format('YYYY/MM/DD'))

  // const startDate = new Date(start.format)
  // const endDate = new Date(today.endOf('week').format('YYYY/MM/DD'))

  await db.collection("diary")
    .where('userid', '==', auth.uid)
    .where('on', '>=', startDate)
    .where('on', '<=', endDate)
    .get()
    .then((diaries) => {
      let diaryDates = []
      diaries.docs.forEach(async (item) => {
        let diary = item.data()
        diary = {
          ...diary,
          id: item.id
        }
        diaryDates.push(diary)
      })
      dispatch(
        triggerReducer(actionTypes.SET_DIARY_DATES, {
          payload: diaryDates
        })
      );
    })
    .catch((err) => {
      console.log("Error diary Dates", err)
    })
}

export const clearDiaryDates = () => (dispatch, getState) => {
  dispatch(
    triggerReducer(actionTypes.SET_DIARY_DATES, {
      payload: []
    })
  );
}
// Service to save diary by date with updated data in redux storage
export const saveDiary = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_DIARY_LOADER,
    diaryLoader: true,
  });
  dispatch({
    type: actionTypes.GET_DIARY,
    myDiary: payload,
    diaryLoader: false,
  });
};
// Service to check 5 day streak of diary and update quinns if streak is completed
export const handleCheckStreak = (payload) => async (dispatch, getState) => {
  const { user } = getState().user;
  const cloneUserData = { ...user };
  var currentDate = new Date(payload).getTime();
  let count = 0;
  let diary = {};
  // /*
  if ("diary" in cloneUserData) {
    const cloneUserDataDiary = { ...cloneUserData.diary };
    var latestDate = new Date(
      cloneUserDataDiary.latestDate.seconds * 1000
    ).getTime();
    if (latestDate < currentDate) {
      count = cloneUserDataDiary.count;
      count += 1;
      if (latestDate < currentDate) {
        if (latestDate + 86400000 * 2 < currentDate) {
          count = 0;
        }
      }
      if (count == 5) {
        const addQuinnsParams = {
          type: "DIARY",
          content: "",
        };
        dispatch(addQuinns(addQuinnsParams));
        count = 0;
      }
      diary = {
        latestDate: payload,
        streakEnds: new Date(currentDate + 86400000 * 2),
        count: count,
      };
    }
  } else {
    count += 1;
    diary = {
      latestDate: payload,
      streakEnds: new Date(currentDate + 86400000 * 2),
      count: count,
    };
  }
  dispatch(updateUser({ diary }));
  console.log("cloneUserData", cloneUserData);
  // */
};

// Service to save diary by date with updated data in firebase databases
export const saveDiaryPageByDate =
  (payload, diarySaveParams) => async (dispatch, getState) => {
    const docId = payload?.diaryId;
    if (docId) {
      db.collection("diary")
        .doc(docId)
        .update(payload)
        .then((response) => {
          console.log(
            "saveDiaryPageByDate->>>>>update_response::Saved successfully"
          );
        })
        .catch((error) => {
          console.log("saveDiaryPageByDate->>>>>update_error", error);
        });
    } else {
      db.collection("diary")
        .add(payload)
        .then((response) => {
          console.log(
            "saveDiaryPageByDate->>>>>add_response:Updated successfully",
            response
          );
          payload.diaryId = response.id;
          diarySaveParams[moment(payload.on).format("YYYY/MM/DD")] = payload;
          dispatch(saveDiary(diarySaveParams));
          dispatch(handleCheckStreak(payload.on));
        })
        .catch((error) => {
          console.log("saveDiaryPageByDate->>>>>add_error", error);
        });
    }
  };
// Service to get diary from db and store in redux
export const getDiary = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_DIARY_LOADER,
    diaryLoader: true,
  });
  db.collection("diary")
    .where("userid", "==", payload.userid)
    .get()
    .then((querySnapshot) => {
      // console.log("querySnapshot.docs.length", querySnapshot.docs.length)
      const diaryObject = {};
      if (querySnapshot.docs.length > 0) {
        querySnapshot.docs.forEach((documentSnapshot) => {
          const docSnap = documentSnapshot.data();
          const docSnapDate = moment(
            new Date(docSnap.on.seconds * 1000)
          ).format("YYYY/MM/DD");
          diaryObject[docSnapDate] = {
            ...documentSnapshot.data(),
            diaryId: documentSnapshot.id,
            page: docSnap.page,
            feelings: docSnap?.feelings ?? "",
            color: docSnap?.color ?? "#a093c7",
            on: docSnap.on,
          };
          // console.log("querySnapshot.docs.length",diaryObject)
        });
        dispatch({
          type: actionTypes.GET_DIARY,
          myDiary: diaryObject,
          diaryLoader: false,
        });
      } else {
        dispatch({
          type: actionTypes.GET_DIARY,
          myDiary: diaryObject,
          diaryLoader: false,
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({
        type: actionTypes.GET_DIARY_LOADER,
        diaryLoader: false,
      });
    });
};
export const getColorChange = (payload) => async (dispatch, getState) => {
  console.log(payload, 'dairyAx')
  dispatch({
    type: actionTypes.GET_DIARY_COLOR,
    diaryColor: payload,
  });
};