import React from "react";
import { Box, Grid, Container, Typography, Divider } from "@mui/material";
import aboutl from "../../../assets/images/about/Group-775.png";
import diaryImg1 from '../../../assets/images/article/diary-2.png';

import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function DiaryBenefitBlog() {
    const classes = DiaryblogsStyles();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Benefits of writing Diary and Journaling | Quill At Will</title>
                <meta
                    name="title"
                    content="Benefits of writing Diary and Journaling | Quill At Will"
                />
                <meta
                    name="description"
                    content="Know the benefits of daily writing an online diary or journal like good mental health, sparks your creativity & more | Diary vs. Journal: How are they different? | Quill at Will."
                />
                <meta
                    name="keywords"
                    content="Blog Writing Platform"
                />
            </Helmet>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        Benefits of Diary Writing and Journaling
                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", }}
                    >
                        Intrapersonal communication
                        <br />
                    </Typography>
                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={diaryImg1} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        Do you know about intrapersonal communication and its importance in one's life? Intrapersonal communication is communicating with oneself, which can be done through self-talk, imagination, visualisation, and even recall and memory, etc.
                                        <br />
                                        You got it right! Keeping a secret diary or an online diary and writing your thoughts, daily routines, and life stories are some ways of intrapersonal communication.

                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "30px" }}
                                    >
                                        Beneficial for Mental Health

                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        It is believed that diary writing helps you summarise your experiences and look back on specific events less stressfully.
                                        Apparently, writing your personal story seems to help with this, and it is crucial to concentrate on thoughts and feelings rather than simply feelings.
                                        <br />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Spark Your Creativity
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    A person can keep a variety of journals depending on their wants and requirements. Some use it to write creative works like poems, short novels, and songs, while others still use it to chronicle daily events and keep a dream journal. Alternatively, you could just keep a simple idea notebook, a book where you keep a list of all the weird and zany ideas that come to you or you could jot down a list of ideas that you can refer to whenever you want.  Though some ideas might not be useful right away, you never know when they might come in handy.
                                    <br />
                                    Furthermore, when you intentionally concentrate on creative ideas, your mind opens up to a wide range of ideas you never knew you possessed. Often your best ideas come to you when you are least expecting them.

                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Remember Events
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    This may be significant for several reasons. For instance, you frequently have to describe instances in which you have shown skill or performed something very well when you are looking for a job. By keeping a diary or journal, you may keep track of your accomplishments and ensure you have a ready supply of examples for job applications. It may also be a chance for you to consider your past and gain knowledge for the future.
                                    <br />
                                    Writing about happy memories and reflecting on them might also help you feel better.

                                </Typography>
                            </Box>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Expressing thoughts and feelings

                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    A diary is your secret friend and a space where you can be sincere with yourself. You can let out pent-up emotions and destructive thoughts that have been bothering you. There may be moments when you feel unable to be completely honest with someone. Your thoughts and secrets will stay with you forever if you keep a diary and approach it as though you were speaking to a close friend.
                                    <br />
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    “Journal writing, when it becomes a ritual for transformation, is not only life-changing but life-expanding.”
                                    <br />
                                    I have seen journaling transform my life in so many ways. It could transform yours too. It’s not only the romanticised sayings of famous authors advocating the enormous benefits of journaling for our general well-being, science also has a message to convey.
                                    <br />
                                    Let’s look over some scientific benefits of diary writing-

                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                Scientific Benefits of Writing a Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                <b style={{ fontSize: "20px", color: '#656e72' }}>Mental and emotional clarity:</b> Due to our sometimes erratic schedules, we occasionally need to take a few minutes to sit down and write the important things to do, keep a track of events, set a few goals to reconnect with ourselves. We may also want to reflect upon the day - our thoughts, behaviours and actions.

                                <br />
                                <b style={{ fontSize: "20px", color: '#656e72' }}> Stress reduction: </b> Journaling can help you better understand yourself and let go of daily stress and ambiguity. Keeping a journal is a great way to combat stress as it makes you feel more at ease and organised leading to strengthening of the immune system.

                                <br />
                                <b style={{ fontSize: "20px", color: '#656e72' }}> Ease of problem-solving:</b> What do you think—is it simpler to solve problems in your head or on paper? Different types of journal formats can help you with a variety of life issues.



                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                Diary vs. Journal: How are they different?

                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                The things you did and experienced on a certain day, along with your feelings toward them, are recorded in your diary. A diary is organised and specific since it is predated, laid down calendar-style, and often has one page per day.
                                <br />
                                On the contrary, journals can cover any topic of your choice. The typical Jan-Dec layout is not required when keeping a journal. You have a lot more freedom to discover new themes, create lists, make illustrations, and write about anything you like.
                                <br />
                                Be it writing in a diary or a journal, it is without doubt, a wonderful process of keeping track of all of your priceless memories and life's significant milestones.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default DiaryBenefitBlog;