import React from "react";
import {
    Grid, Paper, Typography, Button,
} from '@mui/material';

const StartSetup = (props) => {
    return (
        <Grid container style={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'center', maxHeight: '70%' }}>
            <Grid item sm={12} md={6} lg={4}>
                <Grid item sm={12} md={12} component={Paper} style={{ borderRadius: '10%', padding: 100, paddingTop: 150, paddingBottom: 80, textAlign: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Almost there!
                    </Typography>
                    <br />
                    <Typography
                        variant="p"
                        style={{ fontFamily: 'Museo300', color: '#1E3A71' }}
                    >
                        Start setting up institutre by onboarding the <br /> employees and students using tool provided
                    </Typography>
                    <br /><br /> <br /><br /><br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#1E3A71',
                                fontFamily: 'Museo700',
                                color: '#fff',
                                width: '80%',
                                padding: 15,
                                borderRadius: '50px'
                            }}
                                size="large"
                                onClick={() => props.setPageCount(prev => prev + 1)}
                            >
                                Start setup
                            </Button>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#fff',
                                fontFamily: 'Museo700',
                                color: '#000',
                                width: '80%',
                                padding: 15,
                                border: '2px solid #000',
                                borderRadius: '50px'
                            }}
                                size="large"
                            // onClick={() => props.setPageCount(prev => prev + 1)}
                            >
                                Tutorial
                            </Button>
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br /><br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', margin: 'auto' }}>
                            <Typography
                                variant="p"
                                style={{ fontFamily: 'Museo300', color: 'gray' }}
                            >
                                Not Sure? <a style={{ fontFamily: 'Museo700', color: '#1E3A71', marginLeft: 10 }} href="#get-help">Get Help</a>
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default StartSetup;
