import React, { useState } from "react";
import "../../App.css";
import Footer from "../Footer";
// import AboutInformationStyles from "../AboutInformationStyles";
import AboutInformation from "./AboutInformation";

function About() {
  const [show, setShow] = useState(false);
  return (
    <>
      <AboutInformation />
      <Footer />
    </>
  );
}

export default About;
