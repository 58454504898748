import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { firebase, db } from "../../config/fbConfig";

export const getNotifications = () => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  console.log("Auth --- ", auth.uid)
  let notifications = [];
  await db
    .collection('notifications')
    .where('userId', '==', auth.uid)
    .orderBy('createdAt', 'desc')
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        console.log("notifications --- ", doc.data())
        notifications.push({ ...doc.data(), id: doc.id });
      })
    }).catch((error) => {
      console.log("Error notifications --- ", error)
    });
  dispatch(
    triggerReducer(actionTypes.GET_NOTIFICATIONS, {
      payload: notifications
    })
  );
};

export const addNotification = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  let createdAt = firebase.firestore.Timestamp.fromDate(new Date())
  await firestore.add({ collection: "notifications" }, { ...payload, createdAt })
    .then((resp) => {
      console.log("Resp --- ", resp)
      // dispatch(showSuccessSnackbar("Goal added Successfully!"));
    })
};

export const setNotifications = (payload) => (dispatch, getState) => {
  dispatch(
    triggerReducer(actionTypes.SET_NOTIFICATIONS, {
      payload
    })
  );
}

export const deleteNotification = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  await firestore.collection('notifications')
    .doc(payload).delete().then(() => {
      dispatch(showSuccessSnackbar("Notification removed Successfully!"));
    }).catch((error) => {
      dispatch(showErrorSnackbar("Notification remove failed!!!"));
    });
  dispatch(getNotifications())
}