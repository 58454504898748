import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import poemwomen from "../../../assets/images/article/poemwomen.jpeg";
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "../SecretDiaryPage/SecretDiaryStyles";
import Footer from "../../Footer";

const WritePoetry = () => {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Box
                style={{
                    background: "#fff",
                }}
            >
                {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>Online Diary | Online Diary Writing | Quill at Will</title>
                    <meta
                        name="title"
                        content="Online Diary | Online Diary Writing | Quill at Will"
                    />
                    <meta
                        name="description"
                        content="Online Diary - Quill at Will is the secure place to record your day-to-day experience, feelings & thoughts. Let's Create your own free e-diary or journal and get started now!"
                    />
                    <meta
                        name="keywords"
                        content="Blog Writing Platform"
                    />
                </Helmet> */}

                <Grid container style={{
                    display: 'flex', justifyContent: "center", marginBottom: "3rem"
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{
                        borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                        padding: '20px'
                    }}>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            How to write poetry : A guide

                        </Typography>
                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "24px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            A 5 Step Guide to Writing Poems for Beginners with tips and tricks

                        </Typography>


                        <Grid container spacing={3} stype={{ marginTop: '1rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <img src={poemwomen} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                                <Grid container >
                                    <Box >

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontWeight: "bold", fontSize: "22px", fontFamily: "Museo 700", color: "#101070", }}
                                        >
                                            I never started a poem whose end I knew. Writing a poem is discovering.
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "17px", fontFamily: "Museo 700", textAlign: "end" }} >
                                            – Robert Frost
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px" }}
                                        >
                                            Poems can be described as songs without music. For literature enthusiasts, it is music for the soul. They are lyrical, emotional methods of self-expression swirling through words. If you have often wondered how to pen down your thoughts and emotions in the form of poems but could not, this guide is for you.
                                            <br />
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            What is poetry?

                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            When we say a poem, we usually think of rhyming words. But poems are not just words rhyming; they are the artistic use of words. They don’t need to fit into a format or have to be written using specific vocabulary. Instead, poetry uses words artistically, employing figurative language.
                                            <br />
                                            <br />

                                            To put it in simple words, a poem is an arrangement of words that expresses emotions, ideas, thoughts, or observations.
                                            Typically, a poem is written in verse instead of paragraphs and can have rhythms, rhymes, complete or incomplete sentences, etc. It can have all of these and nothing at all. The beauty of poetry lies in the thoughts of the writer and not in rhyming, as most people believe poetry to be.

                                        </Typography>

                                        <br />
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            How to write poetry?


                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            Before we begin with the technicalities of poetry, let’s understand what the purpose of writing a poem is.
                                            <br />
                                            <br />
                                            We have discussed that we can express emotions, ideas, and thoughts through poems, but there is more to poetry than this. Poetry tells us stories, teaches lessons, and sometimes even carries a hidden message. So, when you sit down to write, ask yourself why you are writing the poem. Is it for a performance at an open mic? Or is it to pen down the strong emotions you are feeling right now? Is it for your school or college assignment, or is it for giving your complex thoughts an outlet?
                                            When you know why you are writing, you find the purpose of that poem.


                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Tips for writing poems - A 5 Step Guide to Writing Poems for Beginners
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            <b style={{ color: "#000", fontWeight: "700" }}>1.  Choose a subject.</b>
                                            <br />
                                            After you have your purpose for writing the poem clear, the next step is to choose a subject to write the poem about. Choosing a subject allows you to focus your mind and thoughts on the subject. Some examples of the subject are:
                                            <br />
                                            <br />
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    A feeling like happiness,anger, acceptance, and rejection.

                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    An emotion like love

                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    A place, real or fake, somewhere you feel attached to.

                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    A person

                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    An object
                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    An animal
                                                    <br />
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    className={classes.headingTypo}
                                                    style={{ fontSize: "18px" }}>
                                                    Time, like a memory
                                                    <br />
                                                </Typography>
                                            </li>
                                        </ul>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            <b style={{ color: "#000", fontWeight: "700" }}>2.  Brainstorm</b>
                                            <br />
                                            Now, start by writing down all the words that come to your mind when you think about your subject. Brainstorm the ideas that come to your mind when you think of your subject. Take a walk, observe people and your surroundings to loosen up your mind and think of the subject more than just a subject. Think from other people’s perspective about the subject. This gives you ideas on how to write a poem. Write all the ideas that you get, this step is called brainstorming.
                                            <br />
                                            <br />
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            <b style={{ color: "#000", fontWeight: "700" }}>3.  Choosing a format
                                            </b>
                                            <br />
                                            In the learning process, don’t focus too much on perfecting a format; rather select one format of your liking, learn the rules and practice.
                                            Some of the easiest types of poetry formats for beginners are:
                                            <br />
                                            <br />
                                            <b style={{ color: "#000", fontWeight: "700" }}>Free Verse:  </b>No rule format, just write what you want in this type of poem.
                                            <br />
                                            <b style={{ color: "#000", fontWeight: "700" }}>Haiku: </b> This is a short poem format where you use only a specific number of syllables per line.
                                            <br />

                                            <b style={{ color: "#000", fontWeight: "700" }}>Acrostic: </b> The first letter of the first word of each line spells out a word in this format.
                                            <br />

                                            <b style={{ color: "#000", fontWeight: "700" }}>Narrative: </b> This format of poetry tells a story and includes epics and ballads..
                                            <br />

                                            <b style={{ color: "#000", fontWeight: "700" }}>I Am: </b> Writing poetry about yourself. There is no rule in this type of poem.
                                            <br />

                                            <b style={{ color: "#000", fontWeight: "700" }}>Rhyming couplets: </b> This is one of the most common formats of poetry, where the last word of the two consecutive lines rhymes.
                                            <br />
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            <b style={{ color: "#000", fontWeight: "700" }}>4.  Begin writing
                                            </b>
                                            <br />
                                            Now, let’s get down to the question, "How to start a poem?".
                                            <br />
                                            When you have all the above in place, it is time to write your poem. Write the first line that comes to your mind using the words and thoughts from your brainstorm session, the subject and the format. Take a look at it and see if it fits your idea. Now, write another line and see if that fits. Don’t look for perfection in the first attempt. Put forth your ideas and thoughts in the form of words and then try to correct them.

                                            <br />
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            <b style={{ color: "#000", fontWeight: "700" }}>5.  Editing
                                            </b>
                                            <br />
                                            Editing is very crucial for every writer, and the best way to do it is by sitting with your poem for a couple of days and coming back to read it with a fresh mind. You can also ask someone for feedback after you have finished editing it yourself. You can also read your poem aloud to see how it feels. This gives you a fair idea of the rhythm and rhyme of your poem.

                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box>
                                    <ul>
                                        <li>
                                            <Typography
                                                className={classes.headingTypo}
                                                style={{ fontSize: "18px" }}>
                                                If you want to read poetry, start by reading poetry and ease your way into writing poems in a natural flow.
                                                <br />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography
                                                className={classes.headingTypo}
                                                style={{ fontSize: "18px" }}>
                                                Try not to obsess about getting things perfect on the first go. Give yourself time and practice to get better with poetry. You will get better with more practice along the way and learn the hacks of writing poems in simple steps.
                                                <br />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography
                                                className={classes.headingTypo}
                                                style={{ fontSize: "18px" }}>
                                                Don’t focus on getting the grammar right. Poetry is all about expressing thoughts and feelings, even if that means breaking a few rules of grammar, it’s okay.
                                                <br />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography
                                                className={classes.headingTypo}
                                                style={{ fontSize: "18px" }}>
                                                Start small and try not to use heavy words to make the poem beautiful. Simple and appropriate words are more beautiful in poetry.
                                                <br />
                                            </Typography>
                                        </li>
                                    </ul>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                        Every art work demands a lot of practice and dedication. It is important that you are consistent with your efforts in writing. Bend the rules artistically and don’t pressure yourself.
                                        <br />
                                        <br />
                                        When you are satisfied with your poem, share it with others. You can also join an online poem writing platform, attend poetry workshops, or be a part of a social media community of poets to share your poetry. Through these platforms, you get to meet a community of like-minded people. Online poem writing will help you gain perspective and it will also help in polishing your skills.
                                        <br />
                                        <br />
                                        So get started with your creative journey of writing poetry, and become the best version of yourself!

                                    </Typography>
                                    <br />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default WritePoetry;