import { makeStyles } from "@mui/styles";

const BlogEditorStyles = makeStyles((theme) => ({
  toolbarClassName: {
    padding: "6px 10px 0",
    borderRadius: "30px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "flex-start",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px",
    userSelect: "none",
    height: "450px",
    maxWidth: "140px",
    margin: "auto",
  },
  //create App style code//
  mainContainerStyle: {
    paddingTop: "3rem !important",
    paddingLeft: "0.1rem !important",
    paddingRight: "0.1rem !important",
    paddingBottom: "3rem !important",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "6rem !important",
      paddingLeft: "1.6rem !important",
      paddingRight: "1.6rem !important",
    },
  },
  //Create App blog Style end code//

  responsiveCard: {
    height: "30vw",
    // width: '22vw',
    padding: "0",
    marginTop: "1rem",
    borderRadius: "2rem",
    overflow: "hidden",
    boxShadow: "black 0px 0px 5px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      height: "120vw",
      width: "75vw",
      padding: "0",
      marginTop: "1rem",
      borderRadius: "2rem",
      overflow: "hidden",
      boxShadow: "black 0px 0px 5px 0px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      //  width: 23vw;
      // height: 53vw;
      padding: "0",
      marginTop: "1rem",
      borderRadius: "2rem",
      overflow: "hidden",
      boxShadow: "black 0px 0px 5px 0px",
    },
  },
  fileicons: {
    height: "50px",
    width: "26px",
    margin: "auto",
    textAlign: "center !important",
    justifyContent: "center",
    display: "flex",
    padding: "1px",
  },

  boxiconBlog: {
    margin: "auto",
    height: "50px",
    color: "white",
    width: "50px",
    borderRadius: "26px",
    background: "#cbd0de",
    "&:hover": {
      background: "#1E3A71",
    },
  },

  mainBoxStyles: {

    [theme.breakpoints.down("sm")]: {
      height: "85vh", width: "85vw !important", overflow: "auto", padding: '30px',
    }
  },

  blogImgStyle: {
    position: "absolute",
    top: "-70px",
    left: "-65px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  blogImgStyle2: {
    position: "absolute",
    top: "-15px",
    left: "-100px",
    zIndex: "-1",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  blogImgStyle3: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  appPreStyle: {
    borderRadius: "1rem",
    backgroundColor: "#EDF0F2",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
      marginTop: "1rem !important",
    },
  },
  hidenImg: {
    width: "13vw",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  createFabStyles: {
    position: 'fixed', zIndex: '9999', top: '50%', right: '50px',
    '& .MuiFab-primary': {
      color: '#fff',
      backgroundColor: '#ffc107',
    }
  },

  AppCreateStyles: {
    maxWidth: '40%',
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    }
  },

  hidenImg1: {
    width: "13vw",
    borderRadius: "10px",
    width: "-webkit-fill-available",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddensection: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  deleteStyles: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#183756",
    marginTop: "1rem",
    marginLeft: "0.5rem",
    background: "#f0f0f0",
    width: "3vw !important",
    padding: "6px",
    border: "2px solid rgb(0 0 0 / 26%)",
    height: "6vh !important",
    marginLeft: "19px",
    borderRadius: "24px",
    position: "absolute",
    zIndex: "1",

    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
      fontSize: "30px",
      color: "rgb(24, 55, 86)",
      marginTop: "1rem",
      marginLeft: "19px",
      background: "rgb(240, 240, 240)",
      width: "11vw !important",
      padding: "7px",
      border: "2px solid rgba(0, 0, 0, 0.26)",
      height: "6vh !important",
      borderRadius: "24px",
      position: "absolute",
      zIndex: "1",
    },
  },

  deltDraftStyles: {
    cursor: "pointer",
    fontSize: "30px",
    position: "absolute",
    color: "#183756",
    marginTop: "1rem",
    marginLeft: "0.5rem",
    background: "#f0f0f0",
    width: "3vw !important",
    padding: "8px",
    border: ["48px", "2px solid rgb(0 0 0 / 26%)"],
    height: "6vh !important",
    borderRadius: "24px",
    zIndex: "1",

    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
      fontSize: "30px",
      position: "absolute",
      color: "#183756",
      marginTop: "1rem",
      marginLeft: "0.5rem",
      background: "#f0f0f0",
      width: "11vw !important",
      padding: "8px",
      border: ["48px", "2px solid rgb(0 0 0 / 26%)"],
      height: "6vh !important",
      borderRadius: "24px",
      zIndex: "1",
    },
  },


  editDraftStyles: {
    cursor: "pointer",
    fontSize: "30px",
    position: "absolute",
    color: "#183756",
    marginTop: "1rem",
    marginLeft: "5rem",
    background: "#f0f0f0",
    width: "3vw !important",
    padding: "8px",
    border: ["48px", "2px solid rgb(0 0 0 / 26%)"],
    height: "6vh !important",
    borderRadius: "24px",
    zIndex: "1",

    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
      fontSize: "30px",
      position: "absolute",
      color: "#183756",
      marginTop: "1rem",
      marginLeft: "5rem",
      background: "#f0f0f0",
      width: "11vw !important",
      padding: "8px",
      border: ["48px", "2px solid rgb(0 0 0 / 26%)"],
      height: "6vh !important",
      borderRadius: "24px",
      zIndex: "1",
    },
  },
  toolbarStyling: {
    marginTop: "5rem",
    background: "#fff",
    borderRadius: "100px",
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "50%",
    maxWidth: "50%",
    boxShadow: "box-shadow: 2px 3px 4px rgba(0,0,0,0.5)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexFlow: "column",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "20px",
      // paddingTop: "30px",
      // paddingBottom: "30px",
      // width: "50%",
      // marginTop: '4rem',
      minWidth: "100%",
      boxShadow: "box-shadow: 2px 3px 4px rgba(0,0,0,0.5)",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexFlow: "row",
      padding: "10px 0px 12px 12px",
    },

    [theme.breakpoints.down("md")]: {
      background: "#fff",
      borderRadius: "20px",
      // paddingTop: "30px",
      // paddingBottom: "30px",
      width: "100%",
      minWidth: "100%",
      boxShadow: "box-shadow: 2px 3px 4px rgba(0,0,0,0.5)",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexFlow: "row",
      // margin: "auto",
      padding: "10px 0px 12px 12px",
    },
  },

  underLineStyle: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  iconsBoxed: {
    // margin: "unset",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "auto !important",
    },
  },

  blogCardstyle: {
    border: "none",
    borderRadius: "1.5rem",
    boxShadow: "black 1px 1px 4px",
    padding: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "75vw",
      margin: "auto",
      borderRadius: "1.5rem",
    },
  },

  hkk2: {
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      cursor: "pointer !important",
      height: "8px",
      zIndex: "1",
    },
  },
  hkk: {
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      cursor: "pointer !important",
      zIndex: "1",
    },
  },

  "& .css-tj2cti-MuiInputBase-root-MuiOutlinedInput-root": {
    zIndex: "1",
  },
  headerblogStyle: {
    zIndex: '1000 !important',
    [theme.breakpoints.down("sm")]: {
      zIndex: '1000 !important',
      marginTop: "0rem",
    }
  },

  dailogstyle: {
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "1rem !important",
      height: "38vw",
      width: "38vw",
      marginTop: "8rem",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        margin: "auto",
        height: "100vh",
        marginRight: "-4.8rem",
      },
      [theme.breakpoints.down("md")]: {
        // width: "90vw",
        margin: "auto",
        // height: "60vw",
        marginRight: "-5rem",
      },
    },
  },

  carsl: {
    "& .gZZBOx": {
      display: "none",
    },
  },
}));

export default BlogEditorStyles;

// .editor {
//     box-sizing: border-box;
//     border: 1px solid #ddd;
//     cursor: text;
//     padding: 16px;
//     border-radius: 2px;
//     margin-bottom: 2em;
//     box-shadow: inset 0px 1px 8px -3px #ABABAB;
//     background: #fefefe;
//   }

//   .editor :global(.public-DraftEditor-content) {
//     min-height: 500px !important;
//   }

/* .toolbarClassName {
    min-height: 600px !important
  } */
