import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import moment from 'moment'
import MainStyles from '../MainStyles';
import { FormControl, Grid, MenuItem, Pagination, PaginationItem, Select, Switch } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00008B', //theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'left'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    textAlign: 'left'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    textAlign: 'left'
  },
}));

const UserTable = (props) => {
  const classes1 = MainStyles();
  const [localAllSelected, setLocalAllSelected] = useState(false)
  const [rows, setRows] = useState([])
  const [selectionCount,setselectionCount] = useState(0)

  useEffect(() => {
    let rows = []
    for (let i = (props.page-1)*props.limit; i < Math.min(props.userList.length,props.page*props.limit); i++) {
      let { userCode, name, access, coOrdinator, group, designation, grade, section, dateOfJoining,
        dateOfBirth, address, email, gender, phoneNumber,id,isChecked } = props.userList[i]
      let data = {
        userCode, name, access, coOrdinator, group, designation, grade, section, dateOfJoining,
        dateOfBirth, address, email, gender, phoneNumber,id,isChecked
      }
      rows.push(data)
    }
    let count = 0;
    props.userList.forEach(element => {
      if(element.isChecked)
        count++;
    });
    props.setAllSelected(count == props.userList.length)
    setselectionCount(count)
    setRows(rows)
  }, [props.userList,props.page,props.limit])
  
  const globalSelectAll =()=>{
    props.setUserList(prev=>{
      prev.forEach(element => {
        element.isChecked = !props.allSelected
      });
      return [...prev]
    })
  }
  
  useEffect(()=>{
    let count = 0
    for(let i = 0;i < rows.length;i++){
      if(rows[i].isChecked){
        count++;
      }
    }
    if(count === rows.length) setLocalAllSelected(true)
    else setLocalAllSelected(false)
  },[rows])

  const handleOnClickCheckbox = (id, value) => {
    props.setUserList((prev)=>{
      for(let i = 0;i < prev.length;i++){
        if(prev[i].id === id){
          prev[i].isChecked = !prev[i].isChecked
          break
        }
      }
      console.log(prev)
      return [...prev]
    })
  }

  const handleOnClickRadioButton = () => {
    props.setUserList((prev)=>{
      for(let i = 0;i < rows.length;i++){
        for(let j = 0;j < prev.length;j++){
          if(rows[i].id == prev[j].id){
            prev[j].isChecked = !localAllSelected
            break;
          }
        }
      }
      return [...prev]  
    })
  }


  const getGrade = (value) => {
    if (value) {
      switch (value) {
        case 'class_iii': return 'Class III';
        case 'class_iv': return 'Class IV';
        case 'class_v': return 'Class V';
        case 'class_vi': return 'Class VI';
        case 'class_vii': return 'Class VII';
        case 'class_viii': return 'Class VIII';
        case 'class_ix': return 'Class IX';
        case 'class_x': return 'Class X';
        case 'class_xi': return 'Class XI';
        case 'class_xii': return 'Class XII';
        default: return value;
      }
    } else {
      return 'Not Applicable';
    }
  }

  const getAccess = (value) => {
    if (value) {
      switch (value) {
        case 'super_admin': return 'Super Admin';
        case 'manager': return 'Manager';
        case 'co_ordinator': return 'Co-Ordinator';
        case 'reviewer': return 'Reviewer';
        case 'writer': return 'Writer';
        default: return value;
      }
    } else {
      return 'Not Applicable';
    }
  }

  const getGroup = (value) => {
    if (value) {
      switch (value) {
        case 'administration': return 'Administration';
        case 'teaching': return 'Teaching';
        case 'non_teaching': return 'Non-Teaching';
        case 'primary_school': return 'Primary School';
        case 'middle_school': return 'Middle School';
        case 'high_school': return 'High School';
        default: return value;
      }
    } else {
      return 'Not Applicable';
    }
  }
  const paginationStyles = makeStyles(() => ({
    ul: {
      "& .css-11vbsq9-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
        backgroundColor:"#4315A4",
        color:"white"
      }
    }
  }));
  const handlePageChange =(event,value)=>{
    props.setPage(value)
  }
  const handleLimitChange=(event)=>{
    props.setLimit(event.target.value)
    props.setPage(1)
  }

  return (
    <>
    <div style ={{alignItems:'center',display:'flex',justifyContent:'space-between' ,marginBottom:'15px',marginTop:'-25px'}}>
      <div>
        <FormControlLabel
          label="Select All" 
          control={
            <Switch
            checked={props.allSelected}
            onChange={(e)=>{globalSelectAll()}}
            color="primary"
            value="dynamic-class-name"
            />
          }
        />
        <span>{"Currently Selected(" + selectionCount + ")"}</span>
      </div>
      <div style={{display:'flex',justifyContent:'end',right:'0',alignItems:'center'}}>
        <Pagination size="large" classes={{ul:paginationStyles().ul}} count = {Math.ceil(props.userList.length/props.limit)} variant="outlined" page = {props.page}  onChange = {handlePageChange} renderItem={(item)=>(<PaginationItem  components={{previous: ArrowBackIcon, next: ArrowForwardIcon }}{...item}/>)}/>
          <FormControl width= {"30px"} >
            <Select style={{height:'40px',marginLeft:'20px', padding:'0px 20px', borderRadius:'20px',display:'flex',justifyContent:'center',alignItems:'center'}} value={props.limit} displayEmpty onChange={handleLimitChange} inputProps={{ 'aria-label': 'Without label' }} IconComponent={(props) => (<ExpandMoreOutlinedIcon  {...props}/>)}>
              <MenuItem value={5}>5 Per page</MenuItem>
              <MenuItem value={10}>10 Per page</MenuItem>
              <MenuItem value={15}>15 Per page</MenuItem>
            </Select>
          </FormControl>
      </div>
    </div>
      <TableContainer component={Paper} className={classes1.tblStyle}>
        <Table aria-label="customized table" style={{ width: '150vw', maxWidth: '200vw' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ maxHeight: '20px', marginTop: '-20px' }}
                >
                  <FormControlLabel value="all"
                    control={
                      <Radio
                        checked={localAllSelected}
                        onClick={handleOnClickRadioButton}
                        style={{ color: '#fff' }}
                      />
                    }
                  />
                </RadioGroup>
              </StyledTableCell>
              <StyledTableCell align="right">User Code</StyledTableCell>
              <StyledTableCell align="right">User Name</StyledTableCell>
              <StyledTableCell >Access</StyledTableCell>
              <StyledTableCell align="right">Group</StyledTableCell>
              <StyledTableCell align="right">Designation</StyledTableCell>
              <StyledTableCell align="right">Grade</StyledTableCell>
              <StyledTableCell align="right">Section</StyledTableCell>
              <StyledTableCell align="right">Date Of Joining</StyledTableCell>
              <StyledTableCell align="right">Location</StyledTableCell>
              <StyledTableCell align="right">Email ID</StyledTableCell>
              <StyledTableCell align="right">Gender</StyledTableCell>
              <StyledTableCell align="right">Date of Birth</StyledTableCell>
              <StyledTableCell align="right">Phone Number</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={row.isChecked}
                    inputProps={{
                      'aria-labelledby': `checkbox-${index}`,
                    }}
                    onChange={(e) => handleOnClickCheckbox(row.id, e.target.checked)}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">{row.userCode}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell> {getAccess(row.access)}</StyledTableCell>
                <StyledTableCell align="right">{getGroup(row.group)}</StyledTableCell>
                <StyledTableCell align="right">{row.designation || 'Not Applicable'}</StyledTableCell>
                <StyledTableCell align="right">{getGrade(row.grade)}</StyledTableCell>
                <StyledTableCell align="right">{row.section || 'Not Applicable'}</StyledTableCell>
                <StyledTableCell align="right">{moment.unix(row?.dateOfJoining?.seconds).format('DD MMM  	YYYY')}</StyledTableCell>
                <StyledTableCell align="right">{row.address || '-'}</StyledTableCell>
                <StyledTableCell align="right">{row.email || '-'}</StyledTableCell>
                <StyledTableCell align="right">{row.gender}</StyledTableCell>
                <StyledTableCell align="right">{moment.unix(row.dateOfBirth.seconds).format('DD MMM  	YYYY')}</StyledTableCell>
                <StyledTableCell align="right" style={{ textAlign: 'center' }}>{row.phoneNumber || '-'}</StyledTableCell>
                <StyledTableCell align="right" style={{ padding: 0 }}>
                  <BorderColorIcon onClick={() =>props.onClickUpdate(row.id)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{display:'flex',justifyContent:'end', alignItems:'center',marginTop:'15px'}}>
          <Pagination size="large" classes={{ul:paginationStyles().ul}} count = {Math.ceil(props.userList.length/props.limit)} variant="outlined" page = {props.page} onChange = {handlePageChange} renderItem={(item)=>(<PaginationItem  components={{previous: ArrowBackIcon, next: ArrowForwardIcon }}{...item}/>)}/>
          <FormControl width= {"30px"} >
            <Select style={{height:'40px',marginLeft:'20px', padding:'0px 20px', borderRadius:'20px',display:'flex',justifyContent:'center',alignItems:'center'}} value={props.limit} displayEmpty onChange={handleLimitChange} inputProps={{ 'aria-label': 'Without label' }} IconComponent={(props) => (<ExpandMoreOutlinedIcon  {...props}/>)}>
              <MenuItem value={5}>5 Per page</MenuItem>
              <MenuItem value={10}>10 Per page</MenuItem>
              <MenuItem value={15}>15 Per page</MenuItem>
            </Select>
          </FormControl>
      </div>
    </>
  );
}

export default UserTable