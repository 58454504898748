import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// component from library
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
// Custom component
import AssetComp from '../AssetComp';
// trophies image import
import Asset1ColoredSrc from '../../../assets/images/dashboard/Asset 15.png';
import badge2 from "../../../assets/images/Profile/badge2.svg";
import badge3 from "../../../assets/images/Profile/badge3.svg";
import badge4 from "../../../assets/images/Profile/badge4.svg";

import rookieUnlocked from "../../../assets/images/Profile/rookieUnlocked.png";
import nerdUnlocked from "../../../assets/images/Profile/nerdUnlocked.png";
import scholarUnlocked from "../../../assets/images/Profile/scholarUnlocked.png";

import { getUserLogs } from "../../../store/actions/rewards"

export default function AppEarnedBadges() {
  const [comingSoon, setComingSoon] = useState(false)
  const dispatch = useDispatch();
  const { userLogs } = useSelector(state => state.rewards)

  useEffect(() => {
    if (userLogs && userLogs.userId === null) {
      dispatch(getUserLogs())
    }
  }, [userLogs])

  return (
    <Stack
      style={{
        backgroundColor: '#CEBFFB',
        padding: '1rem',
        // marginTop: '1rem',
        borderRadius: '2rem'
      }}
    >
      <h2 style={{ color: '#001f58', textAlign: 'center', paddingBottom: '10px' }}>Earned Badges</h2>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <AssetComp
            isLocked="false"
            comingSoon={comingSoon}
            title="Newbie"
            IconSrc={Asset1ColoredSrc}
          />
        </Grid>

        <Grid item>
          <AssetComp
            // isLocked="true"
            isLocked={userLogs?.totalQuinns >= 20000 ? "false" : "true"}
            comingSoon={comingSoon}
            title="Geek"
            // IconSrc={Asset2ColoredSrc}
            IconSrc={userLogs?.totalQuinns >= 20000 ? rookieUnlocked : badge2}
          />
        </Grid>

        <Grid item>
          <AssetComp
            // isLocked="true"
            isLocked={userLogs?.totalQuinns >= 40000 ? "false" : "true"}
            comingSoon={comingSoon}
            title="Scholar"
            // IconSrc={Asset3UnColoredSrc}
            IconSrc={userLogs?.totalQuinns >= 40000 ? nerdUnlocked : badge3}

          />
        </Grid>
        <Grid item>
          <AssetComp
            // isLocked="true"
            isLocked={userLogs?.totalQuinns >= 60000 ? "false" : "true"}
            comingSoon={comingSoon}
            title="Pro"
            // IconSrc={Asset4UnColoredSrc}
            IconSrc={userLogs?.totalQuinns >= 60000 ? scholarUnlocked : badge4}
          />
        </Grid>
        {/* {
          comingSoon &&
          <CustomButton
          buttonText="Coming Soon"
          customStyles={{
            borderRadius: '5rem',
            fontSize: '1.2rem'
          }}
        />
          // <h1 style={{color:"rgb(0, 31, 88)", fontFamily:"Museo300"}}>Coming Soon...</h1>
        } */}
      </Grid>
    </Stack>
  );
}
