import { Dialog, FormControl, MenuItem, Select } from '@material-ui/core'
import { Button, Checkbox, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import JoditEditor from 'jodit-react';
import './userAction.css'
import { addAnnouncement} from '../../store/actions/message_announcement';
import { useDispatch } from 'react-redux';

export const MessageBox = ({totalUser,open,setOpen,userList}) => {
  const editor = useRef(null);

  const dispatch = useDispatch()

	const [content, setContent] = useState('');
  const [selectedUser,setSelectedUser]=useState(0);
  console.log(totalUser)
  useEffect(()=>{
    if(open){
      let count = 0;
      for(let i = 0;i < userList.length;i++){
        if(userList[i].isChecked){
          count++;
        }
      }
      setSelectedUser(count)
    }
  },[open])
  const handleClose = () => {
    setOpen(false);
  };
  // const config = {
  //   placeholder:"Compose your message here...",
  //   autoFocus:true,
  // }
  const handleClearAll =()=>{
    setContent("")
  }
  const handleSend = ()=>{   
    let payload = {}
    if(selectedUser === totalUser){
      payload ={
        announcement:content
      }
    }
    else{
      let selectedUsersId = []
      for(let i = 0;i < userList.length;i++){
        if(userList[i].isChecked){
          selectedUsersId.push(userList[i].id)
        }
      }
      payload = {
        message:content,
        messageFor:selectedUsersId
      }
    }
    dispatch(addAnnouncement(payload))
  }

  return (
    <Dialog open = {open} onClose = {handleClose} fullWidth='true'
    maxWidth='md' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ width: '100%' }}>
      <Grid padding='20px'>
        <Grid style ={{display:'flex', justifyContent:'space-between',marginBottom:'20px'}}  >
          <Grid style={{fontSize:"18px",fontWeight:'600',color:'#1E3A71',}}>
            Selected Users{'('+selectedUser+')'}
          </Grid>
          <CloseIcon onClick={handleClose} cursor='pointer'/> 
        </Grid>
          <JoditEditor ref = {editor} value = {content} onChange = {newContent=>setContent(newContent)} />
        <Grid style = {{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'22px'}}>
          <Button style={{cursor:'pointer'}} disabled = {!content.length} onClick={handleClearAll} >Clear All</Button>
          <Button style={{cursor:'pointer',marginLeft:'10px',width:"130px",height:'48px',background:'linear-gradient(180deg, #FFCD51 0%, #FEB231 100%)',color:'#FAFAFA',borderRadius:'30px',opacity:content.length?'100%':'50%'}} disabled = {!content.length} onClick ={handleSend}>Send</Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}