import React from "react";
import Footer from "./Footer";

import MainStyles from "./MainStyles";
const Main = (props) => {
    const classes = MainStyles();
    return (
        <>
            <div className={classes.root} style={{ ...props.styles }}>
                {props.children}
            </div>
            <Footer />
        </>

    );
}

export default Main;
