import { triggerReducer, actionTypes } from '.';
import { firebase, db, auth } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";

import 'firebase/compat/storage';
import store from '../store'
import {festivalsForDB} from './__resource__/festivals'
import moment from "moment";
import { digitalPetsResource } from "./__resource__";
import Cookies from 'js-cookie'
export const getMemeOfTheWeek = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const week = moment().format('w')%12;
        await db
        .collection("memes")
            .where("sequenceId", "==", week.toString())
            .get()
            .then((querySnapshot) => {
                if(querySnapshot.docs.length>0) {
                    const memes = []
                    querySnapshot.docs.forEach((documentSnapshot) => {
                        memes.push({
                            id:  documentSnapshot.id,
                            ...documentSnapshot.data()
                        })
                    })
                    dispatch(
                        triggerReducer(actionTypes.GET_MEMES, { 
                            memeOfTheWeek: memes,
                         })
                    );
                }
            })
            .catch((err) => {
                console.log("ddd", err)
                reject(err)
            })
    });
};
export const getFestival = () => (dispatch, getState) => {
    var tomorrow = new Date();
    tomorrow.setFullYear(2000);
    db
    .collection("festivals")
    .where("date", "<=", new Date(tomorrow))
    .get()
    .then((documentSnapshot) => {
      if (documentSnapshot && documentSnapshot.docs && documentSnapshot.docs.length !== 0) {
        dispatch(
            triggerReducer(actionTypes.GET_FESTIVAL, {
                festival: documentSnapshot.docs[documentSnapshot.docs.length-1].data(),
            })
        );
      }
    })
    .catch((error) => { });
  };
  
export const addFestival = async (festival, i) => {
    await db
      .collection("festivals")
      .add(festival)
      .then((resp) => {
        console.log("Added", i)
      });
  };
  
export const addFestivals = (payload) => async (dispatch, getState) => {
    festivalsForDB.forEach(async (festival, i) => {
      const dateString = festival.date;
      var date = new Date();
      var festDate = festival?.date?.split("/");
      date.setDate(festDate[0]);
      date.setMonth(festDate[1] - 1);
      date.setFullYear(2000);
      date.setHours(0, 0, 0, 0);
      const finalFest = { ...festival, date: date, dateString: dateString };
      await addFestival(finalFest, i);
    }
    )
    dispatch(showSuccessSnackbar("Festivals added Successfully!"));
  };
export const  countWords = (str) => {
  return str.trim().split(/\s+/).length;
}
export const addQuinns = (payload) => async (dispatch, getState) => {
  const { user } = getState().user;
  const prevQuinns = user?.quinns??0
  let quinns = 0;
  if(payload.type  == "POST") {
    const getWordsCount = countWords(payload.content)
    if(getWordsCount<100) {
      quinns= 500
    }else {
      quinns= 1000
    }
    quinns+=prevQuinns
    console.log("quinns", quinns)
  }
  if(payload.type == "POEM") {
    quinns= 500
    quinns+=prevQuinns
    console.log("quinns", quinns)
  }
  if(payload.type == "POSTCARD") {
    quinns= 100
    quinns+=prevQuinns
    console.log("quinns", quinns)
  }
  if(payload.type == "DIARY") {
    quinns= 200
    quinns+=prevQuinns
  }
  await db
  .collection("users")
  .doc(auth.currentUser.uid)
  .update({quinns})
  .then((resp) => {
    console.log("addQuinns_success", resp)
    dispatch(handleQuinnsRewardCard({
      showQuinnsRewardCard: true,
      showQuinnsRewardCardData: {
        quinns: quinns-prevQuinns,
        type:payload.type
      }
    }))
    const addDigitalPetsParams = {
      prevQuinns,
      newQuinns: quinns
    }
    dispatch(addDigitalPets(addDigitalPetsParams));
  })
  .catch((error) => {
    console.log("addQuinns_error", error)
  })
};
export const addDigitalPets = (payload) => async (dispatch, getState) => {
  console.log("aaddDigitalPets_called", )
  const { user } = getState().user;
  let digitalPets = [];
  if(user?.digitalPets) {
    digitalPets= [...user?.digitalPets]
  }
  let shouldAdd = false
  if(payload.newQuinns>=5000) {
      if(payload.newQuinns>=payload.prevQuinns - payload.prevQuinns%5000+5000) {
          shouldAdd = true
      }
  }
  console.log("shouldAdd",shouldAdd)
  if(shouldAdd) {
    var randomPet = digitalPetsResource[Math.floor(Math.random()*digitalPetsResource.length)];
    const findIndex = digitalPets.findIndex((data) => data.id == randomPet.id);
    if(findIndex>-1) {
      digitalPets[findIndex].quantity= digitalPets[findIndex].quantity+1
    }else {
      digitalPets.push(randomPet);
    }
    console.log("shouldAdd", digitalPets);
    await db
    .collection("users")
    .doc(auth.currentUser.uid)
    .update({digitalPets})
    .then((resp) => {
      console.log("addDigitalPets_success", resp);
      // triggerReducer(actionTypes.DIGITAL_PETS_SHOW, { 
      //     showDigitalPetCard: true,
      // });
      dispatch(handleDigitalPetCard({
        showDigitalPetCard: true,
        showDigitalPetCardData: randomPet
      }))
    })
    .catch((error) => {
      console.log("addDigitalPets_error", error)
    })
  }
};

export const handleDigitalPetCard = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DIGITAL_PETS_SHOW,
    ...payload,
  })
}

export const handleQuinnsRewardCard = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.QUINNS_REWARD_SHOW,
    ...payload,
  })
}

export const handlePremiumTrialCard = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.PREMIUM_TRIAL_CARD,
    ...payload,
  })
  if(!payload.showPremiumTrialCard) {
    Cookies.set('premium_trial_notified', true)
  }
}

export const handleNewBadgeCard = (payload) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.BADGE_CARD_SHOW,
    ...payload,
  })
}