import { makeStyles } from '@mui/styles';

const FooterStyles = makeStyles((theme) => ({

  footer: {
    flexGrow: 1,
    padding: '30px 100px',
    backgroundColor: '#f3f3f3',
    // position: 'fixed',
    // bottom: 0,
    width: '100%',
    borderTop: '1px solid grey',
    [theme.breakpoints.down("sm")]: {
      padding: '30px 52px',
    },
  },
  footerLogo: {
    height: '2rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  quicklinks: {
    Typography: {
      lineHeight: 2.0,
    },
  },
  footerHeading: {
    fontStyle: 'normal',
    fontWeight: '600',
    // fontSize: '28px',
    lineHeight: '29px',
    fontFamily: 'inherit',
    // color: '#121212',

    fontSize: "17px", color: "#d3ac35 !important", marginBottom: '16px' 
  },
  footerdiscription: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#121212',
  },

  footerContainer: {
    backgroundColor: "#1d396f",
    padding: "4rem 0 2rem 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center'
  },

  footerLinks: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "40px !important"
  },
  footerLinkWrapper: { display: "flex" },
  footerLinkItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "2rem",
    textAlign: "left",
    // width: "160px",
    boxSizing: "border-box"
  },

  a: {
    color: "#fff",
    textDecoration: "none",
    marginBottom: "0.5rem",
    '&:hover': {
      color: "#e9e9e9",
      transition: "0.3s ease-out"
    },
  },


  socialMedia:
  {
    maxWidth: "1060px",
    width: "100%",
    height: "35px",
    // [theme.breakpoints.down("sm")]:{
    //   maxWidth: "1260px",
    //   width: "100%",
    //   height: "35px",
    // },
    // [theme.breakpoints.down("md")]:{
    //   maxWidth: "1260px",
    //   width: "100%",
    //   height: "35px",
    // }
  },

  socialMediaWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    maxWidth: "1150px"
  },

  socialIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "12vw",
    [theme.breakpoints.down("sm")]: {
      width: "55vw",
    },
  },
  footerPara: { color: "white", fontStyle: "italic", justifyContent: 'center', float: 'left', paddingBottom: '0.8rem', paddingTop: '0.5rem' },

  linkStyle: {
    color: '#fff',
    textDecoration: 'none !important',
    // '& .makeStyles-linkStyle-11' : {
    //   textDecoration:'none !important'
    // }
    '&:hover': {
      color: '#fff !important',
      textDecoration: 'underline !important'
    }
  }

}));

export default FooterStyles;