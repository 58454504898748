// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ timestampInSnapshots: true, ignoreUndefinedProperties: true })

const db = firebase.firestore();

const storage = firebase.storage();

const auth = firebase.auth()

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

const servertimestamp = firebase.firestore.FieldValue.serverTimestamp;

export { auth, googleAuthProvider, db, firebase, servertimestamp, storage }
export default firebaseApp;