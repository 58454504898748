import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
    openedTodos: {
        id: null,
        todos: [],
        userid: null
    },
    savedTodos: {
        id: null,
        todos: [],
        userid: null
    },
    todos: []
};

export const todos = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_GET_TODOS:
            return {
                ...state,
                openedTodos: payload,
            };
        case actionTypes.SET_ADD_TODOS:
            return {
                ...state,
                openedTodos: payload,
            };
        case actionTypes.SET_TODOS_ACTIONS:
            return {
                ...state,
                savedTodosActions: payload,
            };
        case actionTypes.CLEAR_TODO_INPUT:
            return {
                ...state,
                clearTodo: Date.now()
            };
        case actionTypes.GET_TODOS:
            return {
                ...state,
                todos: payload
            }
        default:
            return state;
    }
};
