import React, { useEffect, useState } from "react";
import { Avatar, Container, Typography, Button, IconButton, Icon, Box, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "./Blog.css";
import { useDispatch, useSelector } from "react-redux";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import dayjs from "dayjs";
import { sortDateDesc } from "../../../helpers/Helpers";
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { RestoreOutlined } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import { showErrorSnackbar, showSuccessSnackbar, showInfoSnackbar } from "../../../store/actions/snackbar";
import moment from 'moment'
import { getWallOfFamePosts } from "../../../store/actions/posts"
import { addNotification } from "../../../store/actions/notifications";
import { FollowerTemplate } from "../../EmailTemplate/FollowerTemplate";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2),
    },
    authorImage: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        borderRadius: theme.spacing(2),
        border: `1px solid #fff`
    },
    authorImage2: {
        borderRadius: theme.spacing(2),
        border: `1px solid #fff`,
        display: `block`
    },
    authorText: {
        textAlign: "left",
        marginTop: theme.spacing(2)
    },
    authorName: {
        fontWeight: `bold`
    },
    notice: {
        background: `red`,
        color: `white`
    },
    authorIcons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(5)
    },
    blogListItem: {
        cursor: `pointer`
    }
}));

const getAuthor = async (userId, firestore) => {
    let authorDetails = "";
    const doc = await firestore.collection('users').doc(userId).get();
    if (!doc.exists) {

    } else {
        console.log("author id --- ", doc.id)
        authorDetails = {
            ...doc.data(),
            id: doc.id
        }
    }
    return authorDetails;
}

const BlogViewAuthor = ({ post }, bgcolor, color) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const firestore = useFirestore();
    const _ = deepdash(lodash);
    const auth = useSelector(state => state.firebase.auth);
    const { user } = useSelector((state) => state.user);
    // const user = useSelector((state) => state.firestore.data.users[auth?.uid]);
    const [author, setAuthor] = React.useState({});
    const history = useHistory();
    const [constPostArr, setConstPostArr] = useState([])
    const [latestPosts, setLatestPosts] = useState([])
    const [isSubscribed, setIsSubscribed] = useState(false)

    useEffect(() => {
        const authorId = post.userId || auth.uid;
        getAuthor(authorId, firestore).then((result) => setAuthor(result));
    }, []);

    const { blogs } = useSelector((state) => state.firestore.ordered);
    useFirestoreConnect([{ collection: "blogs", doc: post.blogId.value }]);

    const { wallOfFamePosts } = useSelector((state) => state.posts);



    var index = _.findIndex(constPostArr, { "body": post?.body });

    const NavigateToPost = (postid, title, event) => {
        if (postid) {
            history.push(`/blog/${postid}/${title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase()}`);
        }

    };
    const getName = (user) => {
        let name = ''
        if (user) {
            if (user.hasOwnProperty('displayName')) {
                name = user.displayName
            }
            if (user.hasOwnProperty('name')) {
                let temp = user.name
                user = temp.length > name.length ? temp : name
            }
            if (user.hasOwnProperty('firstName')) {
                let temp = user.firstName + ' ' + user?.lastName
                user = temp.length > name.length ? temp : name
            }
        }
        return name;
    }
    const subscribeUser = () => {
        if (user && user.id) {
            let displayName = getName(user)
            let subscribeData = {
                userId: user.id,
                displayName,
                email: user.email,
                avatarUrl: user?.avatarUrl || ''
            }
            firestore.collection("users")
                .doc(author?.id)
                .update({ 'follower': firestore.FieldValue.arrayUnion(subscribeData) })
                .then(() => {
                    setIsSubscribed(true)
                    let payload = {
                        title: 'New Follower',
                        message: `${displayName} followed you.`,
                        userId: author.id,
                        displayName,
                        email: user.email || '',
                        avatarUrl: user?.avatarUrl || '',
                        isMarkedRead: false,
                        from: user.id
                    }
                    dispatch(addNotification(payload))
                    dispatch(showSuccessSnackbar("You've subscribed to " + getName(author)))
                    let name = user.name ? user.name : user.displayName
                    const EmailTmpVariable = FollowerTemplate(name);
                    let EmailArray = [author.email]
                    fetch(
                        "https://asia-south1-quillatwill-int-uat.cloudfunctions.net/sendEmail",
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                            },

                            body: JSON.stringify({
                                email: EmailArray,
                                name: user.displayName,
                                message: 'New User Follow',
                                template: EmailTmpVariable
                            }),
                        }
                    )
                        .then((response) => {

                        })
                        .catch((error) => {
                            alert("error");
                            console.error(error);
                        });
                })
                .catch((err) => console.log("error --", err))

            firestore.collection("blogs")
                .doc(post.blogId.value)
                .update({ 'subscribedby': firestore.FieldValue.arrayUnion(subscribeData) })
                .then(() => {
                    setIsSubscribed(true)
                    // dispatch(showSuccessSnackbar("You've latestPosts subscribed to " + post.blogId.label))
                })
                .catch((err) => console.log("error --", err))
        } else {
            dispatch(showInfoSnackbar("Please, login to subscribe"))
        }
    };

    const getIndex = (data) => {
        return data.findIndex((sub) => sub.userId === user.id)
    }

    const removeUser = (data) => {
        return data.filter((sub) => sub.userId !== auth.uid)
    }

    useEffect(() => {
        if (author && author?.follower && author.follower.length > 0) {
            let index = getIndex(author.follower)
            if (index > -1)
                setIsSubscribed(true)
        }
    }, [author])

    const unsubscribeUser = () => {
        if (auth && auth.uid) {
            let subscribedby = removeUser(author?.follower)
            firestore.collection("users")
                .doc(author.id)
                .update({ 'follower': subscribedby })
                .then(() => {
                    setIsSubscribed(false)
                    dispatch(showSuccessSnackbar("You've unsubscribed to " + getName(author)))
                })
                .catch((err) => console.log("error --", err))

            let subscribedbyData = removeUser(blogs[0]?.subscribedby)
            firestore.collection("blogs")
                .doc(post.blogId.value)
                .update({ 'subscribedby': subscribedbyData })
                .then(() => {
                    setIsSubscribed(false)
                    // dispatch(showSuccessSnackbar("You've unsubscribed to " + post.blogId.label))
                })
                .catch((err) => console.log("error --", err))
        }
    };

    if (index !== -1) {
        constPostArr.splice(index, 1);
    }

    useEffect(() => {
        if (!wallOfFamePosts || wallOfFamePosts.length == 0)
            dispatch(getWallOfFamePosts());
    }, [])
    useEffect(() => {
        const constPostArr = wallOfFamePosts && wallOfFamePosts.length > 0 ?
            Object.entries(wallOfFamePosts).filter(
                ([key, val]) => val.userId === post.userId && val.isPublished === true && key != post.id)
            : []
        setConstPostArr(constPostArr)
        const latestPosts = sortDateDesc(constPostArr).slice(0, 5);
        setLatestPosts(latestPosts)
    }, [wallOfFamePosts, wallOfFamePosts?.length])

    return (
        <React.Fragment>
            <Container className={classes.content}>
                <Avatar variant="square"
                    className={classes.authorImage}
                    alt={author.displayName}
                    src={author.avatarUrl} />

                <Box className={classes.authorText}>
                    <Typography className={classes.authorName} variant="body1">{getName(author)}</Typography>
                    <Typography variant="body2">{author ? author?.tagLine : ''}</Typography>
                </Box>
                <Box mb={10} className={classes.authorIcons}>
                    {
                        isSubscribed ?
                            <Button variant="contained" color="primary" size="small"
                                onClick={unsubscribeUser}>
                                Unsubscribe
                            </Button>
                            :
                            <Button variant="contained" color="primary" size="small"
                                onClick={subscribeUser}>
                                Subscribe
                            </Button>

                    }
                    <IconButton className={classes.notice} size="small">
                        <CircleNotificationsIcon style={{ color: '#fff', background: 'red', borderRadius: '20px' }} />
                        {/* <Icon color="white">notifications</Icon> */}
                    </IconButton>
                </Box>
                {/* {!isPreview && ( */}
                <React.Fragment>
                    <Divider light />
                    <Box>
                        <List>
                            {latestPosts.map(itm =>
                                <ListItem
                                    disableGutters={true}
                                    className={classes.blogListItem}
                                    onClick={(event) => NavigateToPost(itm[1].id, itm[1].title, event)}
                                >
                                    <ListItemText
                                        primary={itm[1].title}
                                        secondary={`at ${itm[1].lastUpdate ? moment.unix(itm[1].lastUpdate.seconds).format("MMMM D YYYY, h:mm A") : ''}`}
                                        primaryTypographyProps={{
                                            color: `textPrimary`
                                        }}
                                        secondaryTypographyProps={{
                                            color: `textSecondary`,
                                            component: `i`
                                        }}
                                    ></ListItemText>
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </React.Fragment>
                {/* )} */}
            </Container>
        </React.Fragment>
    );
};

export default BlogViewAuthor;
