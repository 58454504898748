import { makeStyles } from "@mui/styles";
import { height } from "@mui/system";


const DiaryPremiumBannerStyles = makeStyles((theme) => ({
    pauseButton: {
        backgroundColor:"#FFF !important",
    },
    finishButton: {
        backgroundColor:"#FFF !important",
        borderRadius:"20px !important",
        color:"#242424 !important",
        textTransform:"capitalize !important"
    },
    saveButton: {
        background:"linear-gradient(180deg, #FFCD51 0%, #FEB231 100%)",
        borderRadius:"20px !important",
        color:"#FFF !important",
        textTransform:"capitalize !important",
        width:"134px !important"
    },
    audioWaveCard: {
        display: 'flex',
        // width: '30vw',
       
        justifyContent:"center",
        alignItems:"center",
        height: 'unset',       
        minHeight: '244px',
        border: '0 !important',
    },
    audioPlayer: {
        width: '100%',
    },
    videoCard: {
        // width: '30vw',
        height: '20vw',
    },
    videoPreviewPlayer: {
        width: '100%',
        height: '20vw',
        position: "inherit",
        objectFit: 'cover'
    },
    videoPlayer: {
        width: '100%',
        height: '8vw',
        position: "inherit",
        objectFit: 'cover'
    },
    headTex :{
        fontFamily: 'Raleway',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '24px',
    },
   popupBox :{       
    // height: 'unset',    
    // minHeight: '450px',
    // width:" 500px",
    // backgroundColor: "#fff",
    // position: "absolute",
    // top: "50%",
    // left: "0",
    // right:"0",
    // transform: "translate(-50%, -50%)",
    // borderRadius: "1rem",
    // boxShadow:" 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
    // padding: "16px",
    // margin:"0 auto",
    // display:"block",

    // [theme.breakpoints.down("md")]: {
    //   width:"90%",
    //   height:" 400px",
    // }
    },
    closebtn:{
        float: 'right',
        position: 'relative',
        right: '-15px',
        top: '-15px;',
    
    },
    actionBtn:{
        backgroundColor:"white !important",
        padding: '3px 20px',
        borderRadius: '20px !important',
        width: '140px',
        color: 'black !important',
        margin:"5px !important"
    
    },

}));

export default DiaryPremiumBannerStyles;