import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { setActivePage } from '../store/actions/user';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

const pages = ['User Organization', 'Roles & Permissions', 'Site Insights','Complaints Portal'];
const links = ['/user-organization','/roles-and-permissions', '/site-insights','/complaints-portal']

const TopBar = ({ page }) => {
  const [selectedPage, setSelectedPage] = useState(0)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedPage(page)
  }, [page])

  useEffect(() => {
    dispatch(setActivePage(2))
  }, [])

  return (
    <AppBar position="static" style={{ background:'linear-gradient(90deg, #4314A3 2.6%, #4658B6 34.79%, #4ACAD5 98.81%)', position: "fixed", marginTop: "-1.6rem", zIndex: "100", padding: "7px" }} elevation={1}>
      <Container maxWidth="xl" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} md={8}>
          <Toolbar disableGutters style={{ height: "50px" }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', }}>
              {pages.map((page, index) => (
                <Box key={index}
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center', padding: 0, margin: 'auto',
                  }}
                >
                  <Button
                    key={page}
                    sx={{
                      my: 2, color: 'white',
                      fontSize: '15px', display: 'block',
                      fontFamily: `${selectedPage === index ? 'Museo700' : 'Museo300'}`,
                      // marginTop: '28px',
                    }}
                    onClick={() => history.push(links[index])}
                  >
                    {page}
                  </Button>
                  <ArrowDropUpIcon style={{
                    color: '#fff', fontSize: '20px',
                    margin: 'auto', paddingBottom: 0,
                    marginTop: '-30px',
                    display: `${selectedPage === index ? 'block' : 'none'}`,
                  }}
                  />
                </Box>
              ))}
            </Box>
          </Toolbar>
        </Grid>

      </Container>
    </AppBar>
  );
};
export default TopBar;
