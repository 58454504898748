import React, { Component } from 'react';
import CanvasJSReact from '../PieChartPage/canvas.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class ActivityBar extends Component {
    constructor() {
        super();
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
        this.addSymbols = this.addSymbols.bind(this);
    }
    addSymbols(e) {
        var suffixes = ["", "K", "M", "B"];
        var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
        if (order > suffixes.length - 1)
            order = suffixes.length - 1;
        var suffix = suffixes[order];
        return CanvasJS.formatNumber(e.value / Math.pow(1000, order));
    }
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        }
        else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }
    render() {
        const options = {
            animationEnabled: true,
            colorSet: "colorSet3",
            exportEnabled: true,
            title: {
                text: "Site Activity"
            },
            axisX: {
                valueFormatString: "MMM"
            },
            axisY: {
                prefix: "",
                labelFormatter: this.addSymbols
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
                verticalAlign: "top"
            },
            data: [{
                type: "column",
                name: "",
                showInLegend: false,
                xValueFormatString: "MMMM YYYY",
                yValueFormatString: "#,##0 Posts",
                dataPoints: [
                    { x: new Date(2022, 1), y: 0 },
                    { x: new Date(2022, 2), y: 2 },
                    { x: new Date(2022, 3), y: 5 },
                    { x: new Date(2022, 4), y: 10 },
                    { x: new Date(2022, 5), y: 11 },
                    { x: new Date(2022, 6), y: 9 },
                    { x: new Date(2022, 7), y: 5 },
                    { x: new Date(2022, 8), y: 8 },
                    { x: new Date(2022, 9), y: 16 },
                    { x: new Date(2022, 10), y: 4 }, //    , indexLabel: "High Renewals" },
                    { x: new Date(2022, 11), y: 8 },
                    { x: new Date(2023, 0), y: 10 }

                ]
            }, {
                type: "line",
                name: "",
                showInLegend: false,
                yValueFormatString: "#,##0 Students",
                dataPoints: [
                    { x: new Date(2022, 1), y: 1 },
                    { x: new Date(2022, 2), y: 1 },
                    { x: new Date(2022, 3), y: 1 },
                    { x: new Date(2022, 4), y: 2 },
                    { x: new Date(2022, 5), y: 2 },
                    { x: new Date(2022, 6), y: 3 },
                    { x: new Date(2022, 7), y: 4 },
                    { x: new Date(2022, 8), y: 5 },
                    { x: new Date(2022, 9), y: 5 },
                    { x: new Date(2022, 10), y: 6 },
                    { x: new Date(2022, 11), y: 6 },
                    { x: new Date(2023, 0), y: 6 },
                ]
            }
                // , {
                //     type: "area",
                //     name: "Profit",
                //     markerBorderColor: "white",
                //     markerBorderThickness: 2,
                //     showInLegend: false,
                //     yValueFormatString: "#,##0",
                //     dataPoints: [
                //         { x: new Date(2022, 0), y: 11500 },
                //         { x: new Date(2022, 1), y: 10500 },
                //         { x: new Date(2022, 2), y: 9000 },
                //         { x: new Date(2022, 3), y: 13500 },
                //         { x: new Date(2022, 4), y: 13890 },
                //         { x: new Date(2022, 5), y: 18500 },
                //         { x: new Date(2022, 6), y: 16000 },
                //         { x: new Date(2022, 7), y: 14500 },
                //         { x: new Date(2022, 8), y: 15880 },
                //         { x: new Date(2022, 9), y: 24000 },
                //         { x: new Date(2022, 10), y: 31000 },
                //         { x: new Date(2022, 11), y: 19000 }
                //     ]
                // }
            ]
        }
        return (
            <div>
                <CanvasJSChart options={options}
                    onRef={ref => this.chart = ref}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}
export default ActivityBar;
