import React from "react"
// component from library
import Stack from '@mui/material/Stack';

export default function AssetComp({ isLocked, title, IconSrc, comingSoon }) {
  let isLockedBool = false;
  if (isLocked === 'true') {
    isLockedBool = true;
  }
  const h3Style = {
    color: 'rgb(0, 31, 88)',
    fontWeight: 300
  };
  if (!isLockedBool) {
    // h3Style.color = 'white';
    h3Style.fontWeight = 700
  }
  return (
    <Stack
      width="fit-content"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <h3 style={h3Style}>{title}</h3>
      {
        !comingSoon &&
        <>

          <img src={IconSrc} alt="trophie" style={{ width: 'auto', height: '3.9rem' }} />
          <h5 style={{ color: 'rgb(0, 31, 88)', fontWeight: isLockedBool ? 500 : 700 }}>
            {isLockedBool ? 'Locked' : 'Unlocked'}
          </h5>
        </>
      }


    </Stack>
  );
}
