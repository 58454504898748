import * as React from 'react';
import { Card, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import memes from '../../../assets/images/MemesImages/image7.png';


const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    // padding: theme.spacing(4, 0),
    color: theme.palette.info.darker,
    backgroundColor: '#77D4E3',
    marginTop: '1.2rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: "25px",
    height: '45vh',

}));

function MemesBox() {
    return (
        <>
            <RootStyle style={{ border: '1px solid lightgray' }}>
                <Typography style={{ fontWeight: "600", fontSize: "25px" }}>Meme of the Week</Typography>
                <Grid item xs={12} style={{
                    backgroundImage: `url(${memes})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: '40vh',
                    marginTop: "0.2rem",

                }}>
                    {/* <img src={memes} alt="trophie" style={{ width: '100%', height: 'auto', objectFit: "fill" }} /> */}
                </Grid>
            </RootStyle>
        </>
    );
}
export default MemesBox;
