import { makeStyles } from '@mui/styles';

const MainStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#1d396f",
    // backgroundImage: 'linear-gradient(darkblue, aqua)',
    margin: 0,
    padding: 0,
    minHeight: '90vh',
    height: 'auto'
  },
  allUserStylebox: {
    maxHeight: '70px !important',
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center !important',
    [theme.breakpoints.down("sm")]: {
      maxHeight: '70px !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      marginTop: "-1rem !important"
    }
  },

  searchboxStyle: {
    height: '50px !important', borderRadius: '25px !important', width: '40ch',
    [theme.breakpoints.down("sm")]: {
      height: '50px', borderRadius: '25px', width: 'auto',

    }
  },
  btnStyle: {
    color: '#000 !important', fontFamily: 'Museo300 !important', backgroundColor: '#FFDB00 !important', height: '50px !important', borderRadius: "30px !important", fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      color: '#000', fontFamily: 'Museo300', backgroundColor: '#FFDB00', height: '40px', borderRadius: "30px", fontSize: "10px !important"
    }
  },

  textStyles: {
    fontFamily: 'Museo300 !important',
    fontWeight: "600 !important",
    fontSize: "38px !important",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center !important',
      margin: "auto",
      fontSize: "30px !important",
    }
  },

  mainContainerboxStyle: {
    display: 'flex !important',
    flexDirection: 'row !important',
    justifyContent: 'center !important',
    marginTop: '45px !important',
    [theme.breakpoints.down("sm")]: {
      padding: "20px !important"
    }
  },
  modalStyles: {
    marginLeft: '30% !important',
    padding: '0 !important',
    width: '40vw !important',
    align: 'right !important',
    left: '30% !important',
    top: '10% !important',
    [theme.breakpoints.down("sm")]: {
      margin: 'auto !important',
      padding: '0 !important',
      width: '100% !important',
      align: 'center !important',
      left: '0% !important',
      top: '10% !important',
    }
  },

  outlineBoxStyles: {
    display: 'flex !important', flexDirection: 'row !important', justifyContent: 'end !important',
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center !important',

    }
  },

  mobileStyle: {
    marginTop: "20px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px !important",
    }
  },

  searchfirstGridStyle: {
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem !important",
    }
  },
  tblStyle: {
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem"
    }
  }

}));

export default MainStyles;