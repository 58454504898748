import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import OnlineDiaryImg from "../../../assets/images/DiaryImages/OnlineDiary.jpeg";
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "./SecretDiaryStyles";
import Footer from "../../Footer";

function OnlineDiary() {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Box
                style={{
                    background: "#fff",
                }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Online Diary | Online Diary Writing | Quill at Will</title>
                    <meta
                        name="title"
                        content="Online Diary | Online Diary Writing | Quill at Will"
                    />
                    <meta
                        name="description"
                        content="Online Diary - Quill at Will is the secure place to record your day-to-day experience, feelings & thoughts. Let's Create your own free e-diary or journal and get started now!"
                    />
                    <meta
                        name="keywords"
                        content="Blog Writing Platform"
                    />
                </Helmet>

                <Grid container style={{
                    display: 'flex', justifyContent: "center", marginTop: '6rem', marginBottom: "3rem"
                }}>
                    <Grid item xs={12} sm={12} md={10} lg={10} style={{
                        borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                        padding: '20px'
                    }}>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            What’s on your mind– Write it in your private online diary
                        </Typography>


                        <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <img src={OnlineDiaryImg} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                                <Grid container >
                                    <Box >
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "15px" }}
                                        >
                                            A diary can act like a healing tool, bringing in much needed mindfulness in our lives.  Carrying a physical diary everywhere is not only tedious but also risky, as anyone can read it without your permission.
                                            <br />That’s precisely why we have this modern-day online diary for you, which will travel with you wherever you go, with no burden, and will be in your safekeeping.
                                            <br />
                                            <br />
                                            Your online diary is part of the quillatwill platform and your account is safeguarded with a password.
                                            <br />
                                            Treat this as your go-to place to record your emotions, aspirations; to track your activities; to reflect on your thoughts or just write to practise the habit of journaling.
                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Why do you need this online locked diary ?
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "15px" }}>
                                            There can be many reasons for you to write a diary – like having a travel journal, recording your progress for achieving your goals, setting and tracking habits or just keeping a record of your daily life and observations. You might even want to start your writing career by maintaining a writing habit, practising your literary skills or working on a manuscript. You might want to plan your day, write down your day-to-day tasks or just express gratitude at the end of the day.
                                            <br />
                                            This online diary with password protection at Quill At Will, will be there with you every time and every day.
                                            <br />
                                            <br />
                                            Writing a diary helps us organise our thoughts and build our creative confidence. The online diary at QuillatWill is designed to wake up the artist inside you. We believe that everyone has creativity hidden inside and online diary writing is the best way to get that creative expression discovered.
                                            <br />
                                            This is the best place for you to start exploring your potential while also nurturing your mind with positivity. So let your imagination run wild into the world where anything is possible, where anything you say will be safe.
                                            <br />
                                            We have everything that you need to focus on. Start your online diary writing with Quill At Will today!
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "30px" }}
                                    >
                                        Features
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}>
                                        <b style={{ color: "gray" }}>Privacy: </b> Only you can access your password-protected account.
                                        <br />
                                        <br />
                                        <b style={{ color: "gray" }}>Anywhere-Anytime diary: </b>No need to carry a physical diary and a pen to write your thoughts down. Just carry your personal online diary on your phone.
                                        <br />
                                        <br />
                                        <b style={{ color: "gray" }}>Customisable: </b> Add colours, fonts, and styles that reflect your personality. Use the QAW media images or stickers or upload photos of your day or add media like a picture or audio from your device into your personal diary.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default OnlineDiary;