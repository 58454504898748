import { DesktopDatePicker, LocalizationProvider, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MainStyles from '../MainStyles';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@mui/styles';
import ReactHtmlParser from 'react-html-parser';
import { AttachFile } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { multiUserUpload, signupSingleUser, updateUser } from '../../store/actions/user';
import validateEmail from '../../helpers/validateEmail';
import { showErrorSnackbar } from '../../store/actions/snackbar';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';

const TopBarStyles = makeStyles((theme) => ({
    mainGrid: {
        '& .css-1cndkqf-MuiToolbar-root': {
            minHeight: '40px',
            height: '70px'
        }
    },
}));
export const AddUpdateUser = ({open,setOpen,updateId,setUpdateId, userList,coOrdinators}) => {
    const classes1 = MainStyles();
    const classes = TopBarStyles();
    const dispatch = useDispatch();

    const [updateFlag, setUpdateFlag] = useState(false)
    const [userCode, setUserCode] = useState('')
    const [name, setName] = useState('')
    const [gender, setGender] = useState('male')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState(new Date())
    const [isStudent, setIsStudent] = useState('')
    const [access, setAccess] = useState('')
    const [coOrdinator, setCoOrdinator] = useState({ id: '', name: '', email: '' })
    const [group, setGroup] = useState('')
    const [designation, setDesignation] = useState('')
    const [grade, setGrade] = useState('')
    const [section, setSection] = useState('')
    const [dateOfJoining, setDateOfJoining] = useState(new Date())
    const [address, setAddress] = useState('')
    const [uploadUserListFlag, setUploadUserListFlag] = useState(false)
    const [updateUserId, setUpdateUserId] = useState(null)

    const [fileObjects, setFileObjects] = useState([]);
    const [uploadData, setUploadData] = useState([])
    
    const handleClose = () => {
        setUpdateId(null)
        setOpen(false);
        resetValues()
        setUpdateFlag(false)
    };
    const resetValues = () => {
        setUserCode(null)
        setName(null)
        setGender('male')
        setEmail(null)
        setPhoneNumber(null)
        setDateOfBirth(new Date())
        setDateOfJoining(new Date())
        setAddress(null)
        setAccess(null)
        setCoOrdinator(null)
        setGrade(null)
        setGroup(null)
        setDesignation(null)
        setSection(null)
        setUpdateFlag(false)
        setIsStudent('')
    }
    const onChangeCoOrdinator = (value) => {
        let data = coOrdinators.filter((c) => c.id === value)
        if (data && data.length > 0) {
            const { name, email, designation } = data[0]
            setCoOrdinator({
                id: value,
                name,
                email,
                designation
            })
        }
    }
    const onClickDownloadSample = () => {
        let fileUrl = "https://firebasestorage.googleapis.com/v0/b/quillatwill-int-uat.appspot.com/o/QaWUpload.xlsx?alt=media&token=154ade04-6f0d-48f1-958f-7f558a46a540"

        // download(fileUrl, 'QaWUserSample.xlsx', 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const element = document.createElement("a");
        element.href = fileUrl;
        element.download = "100ideas-" + Date.now() + ".txt";// simulate link click
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        // fetch(fileUrl, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //     },
        // })
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //         console.log("test -- ", blob)
        //         // Create blob link to download
        //         const url = window.URL.createObjectURL(
        //             new Blob([blob]),
        //         );
        //         download(url, 'QaWUserSampleFetch.xlsx', 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        //     });
    }
    const dropzoneText = () => {

        return `<div style="color:gray;">
            <h6>Drop the CSV file here</h6>
            <p>or use the "Browse" buton. </p>
            <br/>
            <button style="padding:10px 30px; 
                background-color:#383838; 
                color:#fff; font-weight:bold;
                border-radius:20px; cursor:pointer;"
            >
                Browse
            </button>
        </div>`
    }
    const handleOnClickUpdate = () => {
        let userCodeLower = userCode ? userCode.toLowerCase() : ''
        let data = {
            userCode: userCodeLower, name, gender, phoneNumber, dateOfBirth, dateOfJoining, isStudent,
            address, access, coOrdinator, group, designation, grade, section, id: updateUserId
        }
        dispatch(updateUser(data))
    }
    const handleOnClick = () => {
        if (uploadUserListFlag) {
            if (uploadData && uploadData.length > 0)
                dispatch(multiUserUpload(uploadData))
        } else {
            if (updateFlag) {
                handleOnClickUpdate()
            } else {
                let data = {
                    userCode, name, gender, email, phoneNumber, dateOfBirth, dateOfJoining,
                    address, access, coOrdinator, group, designation, grade, section, isStudent
                }
                if (validateEmail(email))
                    dispatch(signupSingleUser(data))
                else
                    dispatch(showErrorSnackbar(`Email address is not valid!!`))
            }
        }
        resetValues()
        setOpen(false);
    }
    const getIcon = () => {
        return <AttachFile style={{ dispaly: 'none', color: '#fff' }} className={classes.dropzoneHideIcon} />
    }
    const labelstyle = {
        fontSize: "1.5rem",
        color: "#1f108e",
    };
    useEffect(() => {
        async function checkData() {
            if (fileObjects && fileObjects.length > 0) {
                let payload = []
                await readXlsxFile(fileObjects[0]).then((rows) => {
                    for (let i = 1; i < rows.length; i++) {
                        let row = rows[i]
                        let userCode = row[1] ? row[1].trim() : ''
                        let name = row[2] ? row[2].trim() : ''
                        let email = row[3] ? row[3].trim() : ''
                        let phoneNumber = row[4] ? row[4] : ''
                        let gender = row[5] ? row[5].trim() : ''
                        let student = row[6] ? row[6].trim() : ''
                        let access = row[7] ? row[7].trim() : ''
                        let group = row[8] ? row[8].trim() : ''
                        let grade = row[9] ? row[9].trim() : ''
                        let designation = row[10] ? row[10].trim() : ''
                        let address = row[11] ? row[11].trim() : ''
                        let dateOfBirth = row[12] ? row[12] : ''
                        let dateOfJoining = row[13] ? row[13] : ''
                        let section = row[14] ? row[14].trim() : ''
                        let flag = true
                        if (validateEmail(email)) {
                            email = email.toLowerCase()
                        } else {
                            alert(`Not valid email address at Sr. No. ${row[0]}
Please, fix the errors. Retry again!
                            `)
                            return;
                        }
                        if (student) {
                            flag = student.toLowerCase().includes('n') ? false : true
                        }

                        payload.push({
                            userCode, name, email, phoneNumber, address, gender, isStudent: flag,
                            access, group, grade, designation, dateOfBirth, dateOfJoining, section
                        })
                    }
                    console.log("after alert Payload ---", payload)
                    setUploadData(payload)
                })
            }
        }
        checkData()
    }, [fileObjects])
    useEffect(()=>{
        if(updateId){
            onClickUpdate(updateId)
        }
    },[updateId])
    const onClickUpdate = (uid) => {
        setUpdateFlag(true)
        let index = 0;
        for(let i = 0;i < userList.length;i++){
            if(userList[i].id == uid){
                index = i;
                break;
            }
        }
        let { userCode, name, gender, email, phoneNumber, dateOfBirth, dateOfJoining,
            address, access, coOrdinator, group, designation, grade, section, id } = userList[index]
        setUpdateUserId(id)
        setUserCode(userCode)
        setName(name)
        setGender(gender)
        setEmail(email)
        setPhoneNumber(phoneNumber)
        setDateOfBirth(moment.unix(dateOfBirth.seconds).format('DD/MM/YYYY'))
        setDateOfJoining(moment.unix(dateOfJoining.seconds).format('DD/MM/YYYY'))
        setAddress(address)
        setAccess(access)
        setCoOrdinator(coOrdinator)
        setGrade(grade)
        setGroup(group)
        setDesignation(designation)
        setSection(section)
        setOpen(true);
        setIsStudent(isStudent)
    }
  return (
    <Dialog className={classes1.modalStyles} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ width: '100%' }}>
        <DialogContent style={{ padding: 0, width: '450px' }}>
            <Grid container spacing={2} marginBottom="1rem" style={{ display: "flex", justifyContent: 'space-between' }}>
                <Grid item xs={6} md={6} lg={6}
                    style={{
                        height: '8vh',
                        background: uploadUserListFlag ? 'gray' : 'aqua',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => setUploadUserListFlag(false)}
                >
                    <Typography variant="h6">
                        Create User
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}
                    style={{
                        height: '8vh',
                        background: uploadUserListFlag ? 'aqua' : 'gray',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => setUploadUserListFlag(true)}
                >
                    <Typography variant="h6">
                        Upload Doc
                    </Typography>
                </Grid>

                {/* <Grid item xs={6} md={6} lg={6} style={{ height: '8vh', background: 'gray', display: 'flex', justifyContent: "center", alignItems: 'center', margin: 'auto' }}>
                    <Typography variant="h6">
                        Upload Doc
                    </Typography>
                </Grid> */}
            </Grid>
            {
                !uploadUserListFlag ?
                    <>
                        <Grid container spacing={2} style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    User Code*
                                </label>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="blogtitle"
                                    label="Enter User Code"
                                    style={{ width: "100%", cursor: "pointer !important" }}
                                    size="small"
                                    value={userCode}
                                    onChange={(e) => setUserCode(e.target.value)}
                                />
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    User Name*
                                </label>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="blogtitle"
                                    label="Enter User Name"
                                    style={{ width: "100%", cursor: "pointer !important" }}
                                    size="small"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => setGender(e.target.value)}
                                        value={gender}
                                    >
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />

                                    </RadioGroup>

                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Mobile Number
                                </label>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="blogtitle"
                                    label="Enter Mobile Number"
                                    style={{ width: "100%", cursor: "pointer !important" }}
                                    size="small"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Email
                                </label>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="blogtitle"
                                    label="Enter User Email"
                                    style={{ width: "100%", cursor: "pointer !important" }}
                                    size="small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={updateFlag}
                                />
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Date of Birth
                                </label><br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={dateOfBirth}
                                        onChange={(date) => setDateOfBirth(date)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={null}
                                            size="small"
                                            style={{ color: '#000', border: '1px solid #000', borderRadius: '10px', width: '100%' }}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Date of Joining
                                </label><br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={dateOfJoining}
                                        onChange={(date) => setDateOfJoining(date)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={null}
                                            size="small"
                                            style={{ color: '#000', border: '1px solid #000', borderRadius: '10px', width: '100%' }}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    City
                                </label>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="blogtitle"
                                    label="Enter User City"
                                    style={{ width: "100%", cursor: "pointer !important" }}
                                    size="small"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Type Of User
                                </label>
                                <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                    <InputLabel style={{ marginTop: '-5px' }} id="demo-simple-select-helper-label">Select Is Student</InputLabel>
                                    <Select
                                        value={isStudent}
                                        onChange={(e) => setIsStudent(e.target.value)}
                                        displayEmpty
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                        style={{ width: "100%" }}
                                        size="small"
                                        // label="Select Grade"
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                    >
                                        <MenuItem value={true}>Student</MenuItem>
                                        <MenuItem value={false}>Employee</MenuItem>
                                    </Select>
                                </FormControl>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Access*
                                </label>
                                <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                    <InputLabel style={{ marginTop: '-5px' }} id="demo-simple-select-helper-label">Select Access</InputLabel>
                                    <Select
                                        value={access}
                                        onChange={(e) => setAccess(e.target.value)}
                                        displayEmpty
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                        style={{ width: "100%" }}
                                        size="small"
                                        // label="Select Grade"
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                    >
                                        <MenuItem value={'super_admin'}>Super Admin</MenuItem>
                                        <MenuItem value={'manager'}>Manager</MenuItem>
                                        <MenuItem value={'co_ordinator'}>Co-Ordinator</MenuItem>

                                        <MenuItem value={'reviewer'}>Reviewer</MenuItem>
                                        <MenuItem value={'writer'}>Writer</MenuItem>
                                    </Select>
                                </FormControl>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Group*
                                </label>
                                <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                    <InputLabel style={{ marginTop: '-5px' }} id="demo-simple-select-helper-label">Select Group</InputLabel>
                                    <Select
                                        value={group}
                                        onChange={(e) => setGroup(e.target.value)}
                                        displayEmpty
                                        style={{ width: "100%", cursor: "pointer !important" }}
                                        size="small"
                                        label="Select Grade"
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                    >
                                        <MenuItem value={'administration'}>Administration</MenuItem>
                                        <MenuItem value={'teaching'}>Teaching</MenuItem>
                                        <MenuItem value={'non_teaching'}>Non-Teaching</MenuItem>

                                        <MenuItem value={'primary_school'}>Primary School</MenuItem>
                                        <MenuItem value={'middle_school'}>Middle School</MenuItem>
                                        <MenuItem value={'high_school'}>High School</MenuItem>
                                    </Select>
                                </FormControl>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                    Grade
                                </label><br />
                                <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                    <InputLabel style={{ marginTop: '-5px' }} id="demo-simple-select-helper-label">Select Grade</InputLabel>
                                    <Select
                                        value={grade}
                                        onChange={(e) => setGrade(e.target.value)}
                                        displayEmpty
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                        style={{ width: "100%", cursor: "pointer !important" }}
                                        size="small"
                                        label="Select Grade"
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                    >
                                        <MenuItem value={'class_xii'}>Class XII</MenuItem>
                                        <MenuItem value={'class_xi'}>Class XI</MenuItem>
                                        <MenuItem value={'class_x'}>Class X</MenuItem>

                                        <MenuItem value={'class_ix'}>Class IX</MenuItem>
                                        <MenuItem value={'class_viii'}>Class VIII</MenuItem>
                                        <MenuItem value={'class_vii'}>Class VII</MenuItem>

                                        <MenuItem value={'class_vi'}>Class VI</MenuItem>
                                        <MenuItem value={'class_v'}>Class V</MenuItem>
                                        <MenuItem value={'class_iv'}>Class IV</MenuItem>

                                        <MenuItem value={'class_iii'}>Class III</MenuItem>
                                    </Select>
                                </FormControl>
                                <span style={{ color: "red", fontSize: "11px" }}>
                                </span>
                            </Grid>
                        </Grid>

                        {
                            isStudent && coOrdinators && coOrdinators.length > 0 &&
                            <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-2rem' }}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                        Co-Ordinator
                                    </label>
                                    <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                        <InputLabel style={{ marginTop: '-5px' }} id="demo-simple-select-helper-label">Select Co-Ordinator</InputLabel>
                                        <Select
                                            value={coOrdinator.id}
                                            onChange={(e) => onChangeCoOrdinator(e.target.value)}
                                            displayEmpty
                                            // inputProps={{ 'aria-label': 'Without label' }}
                                            style={{ width: "100%", cursor: "pointer !important" }}
                                            size="small"
                                            label="Select Grade"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                        >
                                            {
                                                coOrdinators.map((c, index) => <MenuItem value={c.id}>{c.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    {/* <TextField
                            fullWidth
                            type="text"
                            id="blogtitle"
                            label="Enter Co-Ordinator"
                            style={{ width: "100%", cursor: "pointer !important" }}
                            size="small"
                            value={coOrdinator}
                            onChange={(e) => setCoOrdinator(e.target.value)}
                        />
                        <span style={{ color: "red", fontSize: "11px" }}>
                        </span> */}
                                </Grid>
                            </Grid>
                        }

                        {
                            access !== 'writer' &&
                            <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                                        Designation
                                    </label>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="blogtitle"
                                        label="Enter User Designation"
                                        style={{ width: "100%", cursor: "pointer !important" }}
                                        size="small"
                                        value={designation}
                                        onChange={(e) => setDesignation(e.target.value)}
                                    />
                                    <span style={{ color: "red", fontSize: "11px" }}>
                                    </span>
                                </Grid>
                            </Grid>

                        }



                        {/* <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <label htmlFor="blogtitle" style={{ ...labelstyle }}>
                            Section
                        </label>
                        <TextField
                            fullWidth
                            type="text"
                            id="blogtitle"
                            label="Enter User Section"
                            style={{ width: "100%", cursor: "pointer !important" }}
                            size="small"
                            value={section}
                            onChange={(e) => setSection(e.target.value)}
                        />
                        <span style={{ color: "red", fontSize: "11px" }}>
                        </span>
                    </Grid>
                </Grid> */}
                    </> :
                    <>
                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <br />
                                <Typography
                                    variant="h6"
                                    style={{ width: '100%', fontFamily: 'Museo700', color: '#000', textAlign: 'center' }}
                                >
                                    To create multiple users at once
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControl variant="standard" style={{ width: '100%', justifyContent: 'left', textAlign: 'left' }}>
                                    <Timeline position="right" style={{ paddingLeft: 0, marginLeft: '-200px' }}>
                                        <TimelineItem>
                                            <TimelineSeparator className={classes.timelineConnector}>
                                                <TimelineDot style={{ padding: '5px 13px' }}>
                                                    {/* <FastfoodIcon /> */}
                                                    1
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                <Grid container item xs={12}>
                                                    <Typography variant="p" component="span">
                                                        Download sample format sheet
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12} style={{ marginTop: 30 }}>
                                                    <Button style={{
                                                        backgroundColor: '#383838',
                                                        fontFamily: 'Museo700',
                                                        color: '#fff',
                                                        width: '80%',
                                                        padding: '10px 15px',
                                                        fontSize: '13px'
                                                    }}
                                                        size="large"
                                                        onClick={() => onClickDownloadSample()}
                                                    >
                                                        <SimCardDownloadIcon style={{ marginRight: 10 }} />
                                                        {"  Sample Document"}
                                                    </Button>
                                                </Grid>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot style={{ padding: '5px 13px' }}> 2 </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                <Typography variant="p" component="span">
                                                    Fill user details accordingly
                                                </Typography>
                                                {/* <Typography>Because it&apos;s awesome!</Typography> */}
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot style={{ padding: '5px 13px' }}> 3 </TimelineDot>
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                <Typography variant="p" component="span">
                                                    Upload file in .csv format
                                                </Typography>
                                                {/* <Typography>Because you need rest</Typography> */}
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom="1rem" style={{ padding: '20px', marginTop: '-3rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <DropzoneArea
                                        // dropzoneClass="dropzoneHeight"
                                        fileObjects={fileObjects}
                                        Icon={getIcon}
                                        dropzoneText={ReactHtmlParser(dropzoneText())}
                                        onChange={(files) => setFileObjects(files)}
                                        filesLimit={1}
                                        showFileNamesInPreview={true}
                                        useChipsForPreview={true}
                                        previewText={ReactHtmlParser('<br/>')}
                                        acceptedFiles={[".xlsx", ".xlsm", ".xls", ".csv"]}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </>
            }


        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="contained" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="contained" onClick={handleOnClick} autoFocus>
                {
                    uploadUserListFlag ?
                        'Upload'
                        :
                        updateFlag ? 'Update' : 'Create'
                }
            </Button>
        </DialogActions>
    </Dialog>
  )
}
