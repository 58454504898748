import { triggerReducer, actionTypes } from '.';
import { db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from '../store'

export const getRoles = () => (dispatch, getState) => {
   const { tenantId } = getState().user;

   if (tenantId) {
      db.collection("instituteRoles")
         .where('tenantId', '==', tenantId)
         .get()
         .then(async (res) => {
            const rolesData = []
            res.docs.forEach(async (item) => {
               let role = item.data()
               role = {
                  ...role,
                  id: item.id,
               }
               rolesData.push(role)
            })
            console.log("rolesData --- ", rolesData)
            dispatch(
               triggerReducer(actionTypes.GET_ROLES, {
                  payload: rolesData
               })
            );
         })
         .catch((err) => {
            console.log("Error fetching ----", err)
         })
   }

}

export const addRole = (payload) => (dispatch, getState) => {
   const { firestore } = store;
   const { tenantId } = getState().user;

   firestore.add({ collection: "instituteRoles" }, { ...payload, tenantId }).then((resp) => {
      payload.id = resp.id;
      dispatch(getRoles())
   });
}

export const deleteRole = (id) => async (dispatch, getState) => {
   await db.collection('instituteRoles')
      .doc(id).delete()
      .then((res) => {
         console.log("deleted --- ", res)
         dispatch(getRoles())
      })
      .catch((err) => console.log("Error -- ", err))
   dispatch(showSuccessSnackbar("Role deleted Successfully!"));
}


export const updateRole = (payload) => async (dispatch, getState) => {
   const { firestore } = store;

   let id = payload.id
   delete payload.id
   await firestore
      .collection('instituteRoles')
      .doc(id)
      .update(payload)
      .then(res => {
         dispatch(getRoles())
         dispatch(showSuccessSnackbar("Role Updated Successfully"));
      }).catch((error) => {
         console.log("Error : ", error)
      });
}


//testing

export const addDefaultRoles = () => async (dispatch, getState) => {
   let arrayName = ['SuperAdmin', 'Admin', 'Manager', 'Co-Ordinator', 'Reviewer', 'Writer']
   let arraRoleCodes = ['super_admin', 'admin', 'manager', 'co_ordinator', 'reviewer', 'writer']
   const { firestore } = store;

   for (let i = 0; i < arrayName.length; i++) {
      let data = {
         role_code: arraRoleCodes[i],
         role_name: arrayName[i],
         short_name: arrayName[i],
         role_id: i + 1,
         role_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo incididunt ut labore et dolore magna aliqua.'
      }
      await firestore.add({ collection: "roles" }, { ...data }).then((resp) => {
         data.id = resp.id;
      });
   }
}

//end