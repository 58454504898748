import React, { useState } from "react";
import { Grid, Paper, Typography, InputBase, InputLabel, FormControl, Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { setInstituteInfo } from "../../store/actions/user";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        color: '#000'
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '15px 12px',
        color: '#000',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
    formField: {
        width: '100%'
    }
}));

const InstituteInfo = (props) => {
    const dispatch = useDispatch()
    const [instituteName, setInstituteName] = useState('')
    const [instituteEmail, setInstituteEmail] = useState('')
    const [noOfEmployees, setNoOfEmployees] = useState('')
    const [noOfStudents, setNoOfStudents] = useState('')

    const handleOnClickNext = () => {
        let data = {
            instituteName, instituteEmail, noOfEmployees, noOfStudents
        }
        dispatch(setInstituteInfo(data))
        props.setSubPageCount(prev => prev + 1)
    }

    return (
        <Grid container style={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'center', maxHeight: '70%' }}>
            <Grid item sm={12} md={6} lg={4}>
                <Grid item sm={12} md={12} component={Paper} style={{ borderRadius: '10%', padding: 100, paddingTop: 70, paddingBottom: 50, textAlign: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Tell us about your Institute
                    </Typography>
                    <br /><br />
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                            Institute Name*
                        </InputLabel>
                        <BootstrapInput id="bootstrap-input" type="text" placeholder="Institute Name" value={instituteName} onChange={(e) => setInstituteName(e.target.value)} />
                    </FormControl>

                    <br /><br />
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                            Institute Website*
                        </InputLabel>
                        <BootstrapInput id="bootstrap-input" type="email" placeholder="institute@email.com" value={instituteEmail} onChange={(e) => setInstituteEmail(e.target.value)} />
                    </FormControl>
                    <br /><br />

                    <Grid container item sm={12} md={12} spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'spacae-between' }}>
                        <Grid item sm={12} style={{ marginBottom: '-10px' }}>
                            <FormControl variant="standard" style={{ width: '100%' }}>
                                <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                                    Number of Employees*
                                </InputLabel>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfEmployees === "0<20" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfEmployees('0<20')}
                            >
                                {'0<20'}
                            </Button>
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfEmployees === "20<50" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfEmployees('20<50')}
                            >
                                {'20<50'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfEmployees('20<50')}>{'20<50'}</Button> */}
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfEmployees === "50<100" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfEmployees('50<100')}
                            >
                                {'50<100'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfEmployees('50<100')}>{'50<100'}</Button> */}
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfEmployees === "100+" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfEmployees('100+')}
                            >
                                {'100+'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfEmployees('100+')}>{'100+'}</Button> */}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container item sm={12} md={12} spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'spacae-between' }}>
                        <Grid item sm={12} style={{ marginBottom: '-10px' }}>
                            <FormControl variant="standard" style={{ width: '100%' }}>
                                <InputLabel shrink htmlFor="bootstrap-input" style={{ color: '#000', fontWeight: 'bold' }}>
                                    Number of Students*
                                </InputLabel>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfStudents === "0<50" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfStudents('0<50')}
                            >
                                {'0<50'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfStudents('0<50')}>{'0<50'}</Button> */}
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfStudents === "50<200" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfStudents('50<200')}
                            >
                                {'50<200'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfStudents('50<200')}>{'50<200'}</Button> */}
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfStudents === "200<500" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfStudents('200<500')}
                            >
                                {'200<500'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfStudents('200<500')}>{'200<500'}</Button> */}
                        </Grid>
                        <Grid item sm={6} md={3} style={{ width: '90%' }}>
                            <Button
                                variant={noOfStudents === "500+" ? "contained" : "outlined"}
                                size="large"
                                onClick={(e) => setNoOfStudents('500+')}
                            >
                                {'500+'}
                            </Button>
                            {/* <Button variant="outlined" size="large" onClick={(e) => setNoOfStudents('500+')}>{'500+'}</Button> */}
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', margin: 'auto' }}>
                            <Button style={{
                                backgroundColor: '#fff',
                                fontFamily: 'Museo700',
                                color: 'gray',
                                width: '60%',
                                padding: 15,
                                border: '1px solid gray'
                            }}
                                size="large"
                                onClick={() => props.setPageCount(prev => prev - 1)}
                            >
                                BACK
                            </Button>

                        </Grid>

                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#1E3A71',
                                fontFamily: 'Museo700',
                                color: '#fff',
                                width: '60%',
                                padding: 15
                            }}
                                size="large"
                                onClick={handleOnClickNext}
                            >
                                NEXT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default InstituteInfo;
