import React from "react";
import { Box, Grid, Container, Typography, Divider } from "@mui/material";
import aboutl from "../../assets/images/about/Group-775.png";
import quotes from "../../assets/images/about/Group-708.png";
import yugandhar from "../../assets/images/about/FBG.png";
import mansi from "../../assets/images/about/FBG1.png";
import tina from "../../assets/images/about/FBG2.png";
import badisoch from "../../assets/images/about/Group-679.png";
import AboutInformationStyles from "./AboutInformationStyles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { Helmet } from "react-helmet";

export const AboutInformation = (props) => {
  const classes = AboutInformationStyles();
  return (
    <>
      <Box
        style={{
          background: "#fff",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | Blog Writing Platform | Quill At Will</title>
          <meta
            name="title"
            content="About Us | Blog Writing Platform | Quill At Will"
          />
          <meta
            name="description"
            content="Quill at Will is a online blog, diary & journal writing platform where we encourage writers to incript their imagination & provides support to get better at creative writing."
          />
          <meta
            name="keywords"
            content="Blog Writing Platform"
          />
        </Helmet>
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={6}>
              <Box style={{ paddingTop: "7rem" }}>
                <Typography
                  className={classes.headingTypo}
                  style={{ fontSize: "30px" }}
                >
                  About US
                </Typography>
                {/* <h3 style={{ marginLeft: "0.5rem", color: "skyblue" }}>
                  Lorem ipsum dolor sit amet.
                </h3> */}
                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  Badi Soch is a B2C product company founded in Oct'2020 by Yugandhar Penubolu, Mansi Deo and
                  Tina Dilipkumar. We focus on enhancing the creative confidence and expression of users.
                  <br /><br />
                  Our first product, Quillatwill.com is designed to help users create blogs, stories, essays,
                  poems, newsletters, etc and publish it on product platform.

                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={6} style={{}}>
              <Grid container style={{ paddingTop: "10px" }}>
                <img src={badisoch} alt="" style={{
                  width: "100%",
                }} />
              </Grid>
            </Grid>
          </Grid>
          <Box
            style={{
              border: "none",
              borderRadius: "3rem",
              backgroundColor: "rgb(178 178 178 / 15%)",
              padding: "1.4rem",
              textAlign: "center",
              fontSize: "1.4rem",
              color: "#1e3b71",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              position: "relative",
            }}
          >
            <img
              src={quotes}
              alt=""
              style={{ position: "absolute", left: "95px", top: "-35px" }}
            />
            <Box style={{ width: "70%", }}>
              We help transform your writing skills. At QuillatWill, you can explore a range of articles in our
              well stocked library, publish your writings, and acquire admiring fans.
            </Box>
          </Box>
          <Grid container spacing={3} style={{ position: "relative" }}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container style={{ paddingTop: "10px" }}>
                <Grid item xs={12} md={8} lg={6} style={{ textAlign: "left" }}>
                  <img
                    className={classes.visionImage}
                    src={aboutl}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className={classes.visionBox}>
                <Typography
                  className={classes.headingTypo}
                  style={{ fontSize: "30px" }}
                >
                  Vision and Mission
                </Typography>
                {/* <h3 style={{ marginLeft: "0.5rem", color: "skyblue" }}>
                  Lorem ipsum dolor sit amet.
                </h3> */}
                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  Our platform provides a platform for aspiring writers to hone their writing skills.
                  Users can seek, learn, and understand the craft of writing, and then challenge themselves
                  by completing writing exercises.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box
          style={{
            backgroundColor: "#fbcf51",
            paddingLeft: "5rem",
            paddingRight: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <Typography className={classes.leadershipHead} variant="h4"
          >
            Leadership
          </Typography>
          <Grid container spacing={2} >
            <Grid item xs={12} lg={4} className={classes.leadershipCard}>
              <Card style={{
                borderRadius: "25px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}>
                <CardActionArea className={classes.leadershipCard}>
                  <CardMedia style={{
                    backgroundColor: "#1E3B72",
                    objectFit: "contain",
                    objectPosition: "left",
                  }}
                    component="img"
                    height="140"
                    image={yugandhar}
                    alt="yugandhar penubolu"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{
                      color: "#1E3A71",
                    }}>
                      Yugandhar Penubolu
                    </Typography>
                    <Typography variant="p" component="div" style={{
                      textAlign: "left",
                      color: "#1E3A71"
                    }}>
                      Director
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{
                      paddingTop: "0.5rem",
                    }}>
                      Yugandhar is the author of “The Transformational Lens”, a people and performance management book. With over 20 years of experience working with varied corporates and involved in Leadership and Strategic assignments as well as initiatives, He hones his passion for Organizational Behavior and User Creativity.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.leadershipCard}>
              <Card style={{
                borderRadius: "25px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}>
                <CardActionArea>
                  <CardMedia style={{
                    backgroundColor: "#1E3B72",
                    objectFit: "contain",
                    objectPosition: "left",
                  }}
                    component="img"
                    height="140"
                    image={mansi}
                    alt="mansi deo"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{
                      color: "#1E3A71",
                    }}>
                      Mansi Deo
                    </Typography>
                    <Typography variant="p" component="div" style={{
                      textAlign: "left",
                      color: "#1E3A71",
                    }}>
                      Director
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{
                      paddingTop: "0.5rem",
                    }}>
                      With over 12 years of experience in the education field, Mansi is passionate about improving the creating skills of young writers.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.leadershipCard}>
              <Card style={{
                borderRadius: "25px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}>
                <CardActionArea>
                  <CardMedia style={{
                    backgroundColor: "#1E3B72",
                    objectFit: "contain",
                    objectPosition: "left",
                  }}
                    component="img"
                    height="140"
                    image={tina}
                    alt="tina dilipkumar"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{
                      color: "#1E3A71",
                    }}>
                      Tina Dilipkumar
                    </Typography>
                    <Typography variant="p" component="div" style={{
                      textAlign: "left",
                      color: "#1E3A71",
                    }}>
                      Director
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{
                      paddingTop: "0.5rem",
                    }}>
                      With over 10 years of experience in user engagement and relationship management, Tina is keen to make a mark with robust feature filled products that are high on user connect and engagement.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AboutInformation;
