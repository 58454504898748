import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
   userLogs: {
      userId: null,
      docId: null,
      logs: [],
      totalQuinns: 0,
      totalBlogQuinns: 0,
      totalPoemQuinns: 0,
      totalEssayQuinns: 0,
      totalPostcardQuinns: 0,
      lastUpdatedDate: null,
   },
   userLog: [],
   quinns: 0,
};

/*
logsArray = [{
         postId: null,
         poemId: null,
         essayId: null,
         postcardId: null,
         blogId: null,
         diaryId: null,
         type: null, //[diary, blog,poem, essay, post, postcard, likes, loves, shares, views],
         operation: null, //[create, delete, update]
         milestone: null,
         totalMilestone: null,
         quinns: 0,
         date: null,
      }]
*/
export const rewards = (state = INITIAL_STATE, action) => {
   const { type, payload } = action;
   switch (type) {
      case actionTypes.GET_USER_QUINNS:
         return {
            ...state,
            quinns: payload,
         };
      case actionTypes.SET_USER_QUINNS:
         return {
            ...state,
            quinns: payload,
         };
      case actionTypes.SET_USER_LOGS:
         return {
            ...state,
            userLogs: payload
         }
      case actionTypes.ADD_USER_LOGS:
         return {
            ...state,
            userLog: payload
         }
      default:
         return state;
   }
};
