import { actionTypes } from '../actions';

const INITIAL_STATE = {
	comments: [],

	openedComment: {
		userid: null,
		blogid: null,
		comment: null,
		lastupdate: null,
		username: null,
		avatarURL: null,
		parentid: null,
		ownerId: null
	},

	savedComment: {
		userid: null,
		blogid: null,
		comment: null,
		lastupdate: null,
		username: null,
		avatarURL: null,
		parentid: null,
		ownerId: null
	},
	latestComments: [],
	commentsByPoem: []
};

export const comments = (state = INITIAL_STATE, action) => {
	const { type, payload, error } = action;

	switch (type) {

		case actionTypes.GET_COMMENTS:
			return {
				...state,
				comments: payload,
			};

		case actionTypes.SET_OPENED_COMMENT:
			return {
				...state,
				openedComment: payload,
			};

		case actionTypes.SET_SAVED_COMMENT:
			return {
				...state,
				savedComment: payload.openedComment,
			};

		case actionTypes.SET_LIST_COMMENT:
			return {
				...state,
				setListComments: payload.setListComments,
			};

		case actionTypes.GET_LATEST_COMMENTS:
			return {
				...state,
				latestComments: payload,
			};
		case actionTypes.GET_COMMENTS_BY_POSTS:
			return {
				...state,
				commentsByPoem: payload,
			};
		default:
			return state;
	}
};