import React, { useState, useEffect } from 'react';
import { Grid, Box, Stack, TextField, ListItem, ListItemText, } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GoalsNew from './GoalsNew';
import DiaryStyles from '../../Diary/DiaryStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector, useDispatch } from "react-redux";
import { firebase, auth } from '../../../config/fbConfig';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';

import {
    addAspirations,
    getAspiration,
    saveAspirations,
    updateAspirations
} from '../../../store/actions/aspirations';
import {
  addEvent,
  updateEvent,
  deleteEvent,
  fetchEvent,
  refreshEvent
} from '../../../store/actions/userEventActions';

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { showErrorSnackbar } from '../../../store/actions/snackbar';


function NewAppEventsComp() {
    const classes = DiaryStyles();
    const dispatch = useDispatch();
    const { goals, openedGoal } = useSelector((state) => state.goals);
    const { savedAspirations } = useSelector((state) => state.aspirations)
    const { 
      getUserEventLoading,
      getUserEventStatus,
      getUserEventData,
      getUserEventPageInfo,
     } = useSelector((state) => state.userEventsReducer)
    const [input, setInput] = useState('');
    const [aspirations, setAspirations] = useState([]);

    useEffect(() => {
      dispatch(fetchEvent())
    
      // return () => {
        
      // }
    }, [])
    
    const handleAddNewUserEvent = (e) => {
        if (input && input.length > 0 && input.trim().length > 0) {
            let payload = {
              title: input.trim(),
              description:"",
              createdAt: new Date(),
              lastUpdatedAt: new Date(),
              eventAt: new Date(),
              showReminder: true,
              thumbnailUrl:"",
              status: true,
              userId: auth.currentUser.uid,
            }
            dispatch(addEvent(payload))
        }
        setInput('')
    };
    // const handleAddNewUserEvent = (e) => {
    //     if (input && input.length > 0 && input.trim().length > 0) {
    //         let date = new Date()
    //         // e.preventDefault();
    //         let payload = {
    //             goal: input.trim(),
    //             target: date,
    //             added: date,
    //             status: 0
    //         }
    //         if (savedAspirations.id) {
    //             dispatch(saveAspirations(payload))
    //         } else { dispatch(addAspirations(payload)) }
    //     }
    //     setInput('')
    // };

    useEffect(() => {
        dispatch(getAspiration())
    }, [])

    useEffect(() => {
        const { aspirations } = savedAspirations
        if (aspirations && aspirations.length > 0) {
            setAspirations(aspirations)
        }
    }, [savedAspirations])

    const deleteEventDate = (id) => {
        const payload = {
          id:id,
        }
        dispatch(deleteEvent(payload))
    }

    const updateEventDate = (value, id) => {
      const payload = {
        id,
        lastUpdatedAt: new Date(),
        eventAt: value,
      }
      console.log("payload", payload)
      dispatch(updateEvent(payload))
    }

    const handelInput = event => {
        const { name, value } = event.target;
        if (value.length > 20) {
            dispatch(showErrorSnackbar("Limit is 20 characters !!!"));
            return
        }
        else {
            setInput(value);
        }
    };
console.log("getUserEventData", getUserEventData)
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{
          borderRadius: "1rem",
          // backgroundColor: '#EDF0F2',
          padding: "1rem",
          marginTop: "1rem",
          display: "block",
        }}
      >
        <Grid>
          <Box>
            <form
              style={{
                marginTop: "-0.5rem",
                background: "rgba(255, 255, 255, 0.7)",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              {/* <TextField variant="standard" placeholder="Write about yourself.." multiline maxRows={3} InputProps={{ disableUnderline: true, }} sx={{ input: { textAlign: "center", } }} type="textarea" name="tagLine" 

               /> */}
              <TextField
                type="text"
                label="Add New Events"
                variant="outlined"
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                size="big"
                value={input}
                // onChange={e => setInput(e.target.value)}
                onChange={(e) => handelInput(e)}
                // onBlur={handleAddNewUserEvent}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    handleAddNewUserEvent();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <AddCircleIcon
                        style={{ cursor: "pointer", color: "#103d7e" }}
                        onClick={handleAddNewUserEvent}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            {/* {goals.map(item => <GoalsNew key={item.id} arr={item} date={item.date} />)} */}
            {
              getUserEventData?.map((item, index) => {
                return (
                  <ListItem
                className={classes.deleteStyle}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                key={index}
                secondaryAction={
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      opacity: 0.7,
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      deleteEventDate(item.id);
                    }}
                  />
                }
              >
                <Grid
                  container
                  spacing={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={12} sm={8} md={5}>
                    <ListItemText
                      style={{
                        fontSize: "15px",
                        marginTop: "0.8rem",
                        //  whiteSpace: 'nowrap !important',
                        // overflow: 'hidden !important',
                        // textOverflow: 'ellipsis !important', width: '8vw', height: '4vh', overflow: "hidden"
                      }}
                      id={index}
                      primaryTypographyProps={{
                        fontFamily: "Raleway",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "19px",
                      }}
                      primary={item.title}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={7} mt={1}>
                    {/* <Stack
                                            width="100%"
                                            direction="row"
                                            justifyContent="space-between"
                                        > */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        // className={classes.datePickerStyle}
                        value={item.eventAt.toDate().toDateString()}
                        onChange={(newValue) => {
                          updateEventDate(newValue, item.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              input: { color: "rgba(117, 5, 40, 1)" },
                            }}
                            className={classes.datePickerStyle}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {/* </Stack> */}
                  </Grid>
                </Grid>
              </ListItem>
                )
              })
            }
          </Box>
        </Grid>
      </Stack>
    );
}

export default NewAppEventsComp;