import { makeStyles } from '@mui/styles';

const OnboardingStyles = makeStyles((theme) => ({
    root: {
        '& .css-ha3bif-MuiTimelineItem-root::before': {
            content: 'none',
            marginLeft: '100px',
            paddingLeft: '100px'
        },
        '& .css-ha3bif-MuiTimelineItem-root': {
            content: 'none',
            paddingLeft: '100px'
        },

    },
    timelineConnector: {
        '& .css-1ami7lv-MuiTimelineConnector-root': {
            height: '70px'
        }
    },
    dropzoneHideIcon: {
        '& .MuiSvgIcon-root': {
            dispaly: 'none !important'
        },
        '& .MuiDropzoneArea-icon': {
            dispaly: 'none'
        },
    },
    dropzoneHeight: {
        height: '100px',
        minHeight: '100px',
        maxHeight: '100px'
    }

}));

export default OnboardingStyles;