import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import logoImages from "../../assets/images/logo1.png";
import hands from "../../assets/images/Blog/BD/Group 63.png";

import {
  Grid,
  Box
} from "@mui/material";


import { FaRegComment } from "react-icons/fa";
import Stack from "@mui/material/Stack";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import LibraryStyles from "./LibraryStyles";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { useFirestoreConnect } from "react-redux-firebase";
import { setShowPoemIndex } from "../../store/actions/poems";

export default function Post({ wallOfFamePostsState, page, showPostCount, poems }) {
  const classes = LibraryStyles();
  const history = useHistory();
  const dispatch = useDispatch()

  const { firestore } = useSelector((state) => state);
  // const auth = useSelector(state => state.firebase.auth);
  const [posts, setPosts] = useState([])
  // const { users } = firestore.ordered;
  // const [user, setUser] = useState([]);
  const [change, setChange] = useState(wallOfFamePostsState.length)

  // useFirestoreConnect([{ collection: "users", doc: auth.uid }]);
  // useFirestoreConnect([{ collection: "users" }]);

  // useEffect(() => {
  //   if (users) {
  //     const currentUser = users[0];
  //     setUser({ ...user, ...currentUser });
  //   }
  // }, [users]);

  useEffect(() => {
    let startIndex = (page - 1) * showPostCount
    let endIndex = startIndex + showPostCount
    let showPosts = []
    for (let i = startIndex; i < endIndex; i++) {
      if (wallOfFamePostsState.length > i)
        showPosts.push(wallOfFamePostsState[i])
      else
        break;
    }
    setPosts(showPosts)
  }, [showPostCount, page, wallOfFamePostsState, change])

  const handlePoemClick = (poemId) => {
    let index = poems.findIndex((poem) => poem.id === poemId)
    dispatch(setShowPoemIndex(index > 0 ? index : 0))
    //history.push("/poem/" + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())
    history.push('/poem-view')
  }

  const getUsername = (post) => {
    let name = ''
    let { user } = post
    if (user) {
      if (user.hasOwnProperty('displayName')) {
        name = user.displayName
      }
      if (user.hasOwnProperty('name')) {
        let temp = user.name
        user = temp.length > name.length ? temp : name
      }
      if (user.hasOwnProperty('firstName')) {
        let temp = user.firstName + ' ' + user?.lastName
        user = temp.length > name.length ? temp : name
      }
    }
    return name;
  }

  return (
    <Grid container spacing={3}>
      {posts && posts.length > 0
        ? posts.map((post, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            key={post.id}
            onClick={() => post?.type == 'poem' ?
              handlePoemClick(post.id)
              :
              history.push("/blog/" + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())
            }
            style={{ cursor: "pointer" }}
          >
            <Stack
              className={classes.responsiveCard}
              style={{
                borderRadius: "25px",
                overflow: "hidden",
                boxShadow: "black 0px 0px 5px 0px",
              }}
            >
              <Grid
                container
                spacing={1}
                direction="column"
              >
                <Grid item>
                  <Box style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "200px",
                        backgroundColor: "skyblue",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{
                        color: '#000',
                        position: 'absolute',
                        Zindex: 1000,
                        fontSize: 16,
                        top: 10,
                        right: 10,
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        fontFamily: 'Museo700',
                        // fontWeight: 700,
                        borderRadius: 10
                      }}
                      >
                        {
                          post?.type === 'blog' ? 'Blog' : 'Poem'
                        }
                      </span>
                      <div style={{
                        minHeight: '200px', backgroundColor: 'skyblue', display: 'flex',
                        justifyContent: 'center', alignItems: 'center'
                      }}>
                        {
                          post.images && post.images.length > 0 ?
                            <img
                              src={post.images[0]}
                              //  src={bgImageSrc2}
                              alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover' }} />
                            :
                            <img
                              src={logoImages}
                              //  src={bgImageSrc2}
                              alt="bg" style={{ width: "50%", height: 'auto', margin: 'auto' }} />

                        }
                      </div>
                    </div>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0",
                        // margin: "10px",
                        padding: "10px",
                        width: "100%",
                        background: 'linear-gradient(rgba(255,0,0,0), #000000)',

                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{ color: "#fff" }}
                      >
                        <Grid item>
                          {/* <img src={eye} alt="" /> */}
                          <RemoveRedEyeOutlinedIcon />
                          <span>
                            {post?.views || 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <img src={hands} alt="" />
                          <span>
                            {post?.likedby?.length || 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <FaRegComment />
                          <span>
                            {post?.commentscount || 0}
                          </span>
                        </Grid>
                        <Grid item>
                          <TelegramIcon />
                          <span>
                            {post?.shared || 0}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item
                //  xs={12}
                //   sm={12}
                //   md={12}
                //   lg={12}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <div
                      style={{
                        height: "100px",
                        overflow: "hidden",
                      }}
                    >
                      <h3
                        style={{
                          color: "grey",
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(
                            "/blog/" + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase()
                          )
                        }
                      >
                        {/* Lorem ipsum dolor */}
                        {post?.title}
                      </h3>

                      <div
                        style={{
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace:
                            "nowrap !important",
                          overflow: "hidden !important",
                          textOverflow:
                            "ellipsis !important",
                          width: "100%",
                        }}
                      >
                        {post && post.body
                          ? ReactHtmlParser(
                            post.body.substring(0, 50)
                          )
                          : ""}
                      </div>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      marginBottom="0"
                    >
                      <Grid
                        item
                        xs={2}
                        sm={6}
                        md={6}
                        lg={2}
                      >
                        <img
                          // src={blog?.image}
                          style={{
                            width: "34px",
                            height: "34px",
                            borderRadius: "50%",
                          }}
                          src={post?.user?.avatarUrl}
                        // alt=""
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={5}
                        lg={6}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                        >
                          <b
                            style={{
                              fontWeight: "500",
                              color: "rgb(0, 31, 88)",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {/* {post?.user?.displayName} |{" "} */}
                            {
                              getUsername(post)
                            }
                          </b>
                          {/* <span style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis', }}>{post?.blogId?.label}</span> */}
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        <b
                          style={{
                            fontWeight: "500",
                            color: "rgb(0, 31, 88)",
                            textAlign: "right",
                          }}
                        >
                          {post.lastUpdate
                            ? moment
                              .unix(
                                post?.lastUpdate
                                  ?.seconds
                              )
                              .format("DD MMM YY")
                            : null}
                        </b>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        ))
        : null}
    </Grid>
  )
}