import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import ComplaintDetail from './ComplaintDetail'

export const ComplaintBox = ({complaint}) => {
    const [complaintDetailOpen,setComplaintDetailOpen]=useState(false)
    const getDay = (createdAt)=>{
        let now = new Date()
        now = Math.floor(now.getTime()/1000);
        return Math.floor((now-createdAt)/(86400))
    }
    const noOfDays = getDay(complaint.createdAt.seconds)
  return (
    <Grid style = {{marginTop:'30px',width:'65vw'}}>
        <Grid display={'flex'} justifyContent={'space-between'} alignItems="center" marginBottom={'5px'}>
            <Grid style={{color:'#707070'}}>Complaint ID : {complaint.id}</Grid>
            <Grid fontSize='12px' display={'flex'} alignItems="center">
                <Grid style ={{borderRadius:'10px', padding:"10px 15px",color:'#1E3A71',border:'1px solid #707070'}}>{complaint.complaintCategory}</Grid>
                <Grid style = {{backgroundColor:'#FFDCDC',padding:"10px 15px", borderRadius:'10px',color:'#1E3A71',marginLeft:'20px'}}>{complaint.status}</Grid>
            </Grid>
        </Grid>
        <Grid style={{color:'#707070',fontWeight:'700',fontSize:'20px',marginBottom:'5px'}}>{complaint.title}</Grid>
        <Grid style ={{color:'#707070',marginBottom:'20px'}}>{complaint.discription?(complaint.discription.length<=100?complaint.discription:complaint.discription.slice(0,100)+'....'):'No Discription Available'}</Grid>
        <Grid display={'flex'} justifyContent={'space-between'} alignItems="center">
            <Grid display={'flex'} alignItems="center">
                <Grid display={"flex"} alignItems='center'>
                    <Grid>
                        <img style ={{height:'36px',borderRadius:'50%'}} src={'fakeDp.png'} alt={'User'}/>
                    </Grid>
                    <Grid style ={{fontSize:'12px'}}>
                        <Grid Style ={{fontWeight:'700',color:'#305067'}}>{complaint.displayName}</Grid>
                        <Grid Style ={{fontWeight:'400',color:'#4D8A93'}}>{complaint.email}</Grid>
                    </Grid>
                </Grid>
                <Grid style ={{color:'#1E3A71',fontSize:'12px',marginLeft:'10px'}}>{noOfDays} {noOfDays == 1?"Day":"Days"} ago</Grid>
            </Grid>
            <Grid><Button onClick={()=>{setComplaintDetailOpen(true)}} style ={{backgroundColor:'#4315A4',color:'white',borderRadius:'5px',padding:'10px 20px'}}>Action</Button></Grid>
        </Grid>
        <ComplaintDetail open = {complaintDetailOpen} setOpen = {setComplaintDetailOpen} noOfDays = {noOfDays} complaint = {complaint}/>
    </Grid>
  )
}
