import { actionTypes, triggerReducer } from '../actions';
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { db } from "../../config/fbConfig"
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from 'moment'

export const clearAspirations = () => (dispatch) => {
   dispatch(triggerReducer(actionTypes.CLEAR_TODO_INPUT));
};

export const getAspiration = () => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { firestore } = store;
   var data = []
   try {
      firestore.collection('aspirations').where('userid', '==', auth.uid).get().then((querySnapshot) => {
         if (querySnapshot && querySnapshot.docs && querySnapshot.docs.length !== 0) {
            querySnapshot.forEach(snapshot => {
               let data = {
                  id: snapshot.id,
                  aspirations: snapshot.data().aspirations.sort((a, b) => new Date(moment(b.target._seconds * 1000)) - new Date(moment(a.target._seconds * 1000)))
               }
               dispatch(triggerReducer(actionTypes.SET_GET_ASPIRATIONS, { payload: data }))
            })
         } else {
            let payload = {
               id: null,
               userid: auth.uid,
               aspirations: []
            }
            dispatch(triggerReducer(actionTypes.SET_GET_ASPIRATIONS, { payload }))
         }
      })
   } catch (error) {
      console.log("test aspita=r ", error)
   }
};

export const addAspirations = (payload) => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { firestore } = store;
   await firestore.add({ collection: "aspirations" }, {
      aspirations: [payload],
      userid: auth.uid
   }).then((resp) => {
      payload.id = resp.id;
      dispatch(triggerReducer(actionTypes.SET_ADD_ASPIRATIONS, { payload }));
      dispatch(getAspiration());
      dispatch(showSuccessSnackbar("Goals Added Successfully!"));
   });
};

export const saveAspirations = (payload) => async (dispatch, getState) => {
   const { savedAspirations } = getState().aspirations;
   const docId = savedAspirations ? savedAspirations.id : null;
   const goalsRef = doc(db, "aspirations", docId);
   updateDoc(goalsRef, {
      aspirations: arrayUnion(payload)
   })
      .then(() => {
         dispatch(showSuccessSnackbar("Goals Set Successfully!"));
         dispatch(getAspiration());
      }).catch((error) => {
         console.log(error, "Error in updating Aspiration");
         dispatch(showErrorSnackbar("Error in updating Aspiration"));
      });
};

export const updateAspirations = (payload, doc) => async (dispatch, getState) => {
   const { firestore } = store;
   firestore.collection("aspirations").doc(doc.id).update({ 'aspirations': payload })
      .then(() => {
         getAspiration()
      }).catch((error) => {
         console.log(error, "Error in updating Goal");
      });
};

export const deleteAspirations = (payload) => (getState) => {
   const { firestore } = store;
   try {
      firestore.collection('aspirations').doc(payload.id).update({ 'aspirations': (payload.content) })
         .then((res) => {
            console.log('action update', res)
         })
   } catch (error) {
      console.log(error)
   }
}