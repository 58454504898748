import React from "react";
import { Grid, Paper, Typography } from '@mui/material';

const Sidebar = (props) => {
    return (
        <Grid container component={Paper} style={{
            backgroundColor: '#edf0f2',
            height: 'auto',
            width: '100%',
            borderRadius: '10px',
        }}
        >
            <Grid container item xs={12} md={12}
                style={{
                    backgroundColor: props.menuIndex === 0 ? '#e6fbfb' : '#edf0f2',
                    padding: '15px 20px', borderRadius: '10px 10px 0px 0px', cursor: 'pointer'
                }}
                onClick={() => props.setMenuIndex(0)}
            >
                <Typography variant="h6" style={{ fontFamily: props.menuIndex === 0 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                    Manage Roles
                </Typography>
            </Grid>
            <Grid container item xs={12} md={12}
                style={{
                    backgroundColor: props.menuIndex === 1 ? '#e6fbfb' : '#edf0f2',
                    padding: '15px 20px', cursor: 'pointer'
                }}
                onClick={() => props.setMenuIndex(1)}
            >
                <Typography variant="h6" style={{ fontFamily: props.menuIndex === 1 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                    Assign Roles
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}
                style={{
                    backgroundColor: props.menuIndex === 2 ? '#e6fbfb' : '#edf0f2',
                    padding: '15px 20px', borderRadius: '0px 0px 10px 10px', cursor: 'pointer'
                }}
                onClick={() => props.setMenuIndex(2)}
            >
                <Typography variant="h6" style={{ fontFamily: props.menuIndex === 2 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                    Access & Permissions
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Sidebar;
