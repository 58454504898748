import React from "react";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useHistory } from "react-router-dom";
import DashboardStyles from "./DashboardStyles";
import ReactHTMLParser from 'react-html-parser'
import IconCircle from "./IconCircle";
import libraryiconhover from "../../assets/images/dashboard/libraryiconhover.svg";
import blogiconhover from "../../assets/images/dashboard/blogiconhover.svg";
import cardiconhover from "../../assets/images/dashboard/cardiconhover.svg";
import essayIconHover from "../../assets/images/dashboard/essayiconhover.svg";
import poemIconHover from "../../assets/images/dashboard/poemiconhover.svg";

import DiaryIconSrc from "../../assets/images/dashboard/diary.png";
import BlogIconSrc from "../../assets/images/dashboard/blogicon.png";
import CardIconSrc from "../../assets/images/dashboard/card.png";

export default function CreateFabButton() {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const classes = DashboardStyles();

  const actions = [
    {
      icon: <IconCircle style={{ marginTop: '10px' }}
        HoverCompSrc={essayIconHover}
        IconCompSrc={essayIconHover}
        onClick={() => history.push('/essay')}
      />,
      name: "<span style='font-size:20px;margin-top:100px;padding-top:30px;height:40px;'>Story</span>",
      path: '/essay'
    },
    {
      icon: <IconCircle style={{ marginTop: '10px' }}
        HoverCompSrc={poemIconHover}
        IconCompSrc={poemIconHover}
        onClick={() => history.push('/poem')}
      />,
      name: "<span style='font-size:20px;margin-top:100px;padding-top:30px;height:40px;'>Poem</span>",
      path: '/poem'
    },
    {
      icon: <IconCircle style={{ marginTop: '10px' }}
        HoverCompSrc={cardiconhover}
        IconCompSrc={cardiconhover}
        onClick={() => history.push('/postcard')}
      />,
      name: "<span style='font-size:20px;margin-top:100px;padding-top:30px;height:40px;'>PostCard</span>",
      path: '/postcard'
    },
    {
      icon:
        <IconCircle
          HoverCompSrc={libraryiconhover}
          IconCompSrc={libraryiconhover}
          onClick={() => history.push('/diary')}
        />,
      name: "<span style='font-size:20px;margin-top:100px;padding-top:30px;height:40px;'>Diary</span>",
      path: '/diary'
    },
    {
      icon: <IconCircle
        HoverCompSrc={blogiconhover}
        IconCompSrc={blogiconhover}
        onClick={() => history.push('/blog')}
      />,
      name: "<span style='font-size:20px;margin-top:100px;padding-top:30px;height:40px;'>Blog</span>",
      path: '/blog'
    }

  ];

  const handleClose = (path) => {
    history.push(path)
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="up"
    >
      {actions.map((action) => (
        <SpeedDialAction
          className={classes.febBTN}
          key={action.name}
          icon={action.icon}
          tooltipTitle={ReactHTMLParser(action.name)}
          onClick={() => handleClose(action.path)}
        />
      ))}
    </SpeedDial>
  );
}
