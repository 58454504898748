import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import HistoryImg from "../../../assets/images/DiaryImages/HistoryImg .jpg";
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "./DiaryblogsStyles";

function DiaryHistoryBlog() {
  const classes = DiaryblogsStyles();

  return (
    <>
      <Box
        style={{
          background: "#fff",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>HISTORY OF THE HUMBLE POSTCARD | Quill at Will</title>
          <meta
            name="title"
            content="HISTORY OF THE HUMBLE POSTCARD | Quill at Will"
          />
          <meta
            name="description"
            content="The First postcard was conceptualized in 1869. Get to Know about the history of the humble postcard through our latest blog and know the origin of postcards."
          />
          <meta
            name="keywords"
            content="History of postcards"
          />
        </Helmet>

        <Grid container style={{
          display: 'flex', justifyContent: "center",
        }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{
            borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
            padding: '20px'
          }}>

            <Typography
              className={classes.headingTypo}
              style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
            >
              HISTORY OF THE HUMBLE POSTCARD
            </Typography>
            <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

              <Grid item xs={12} md={12} lg={12}>
                <Grid container>
                  <img src={HistoryImg} alt="" style={{
                    width: "100%",
                    height: "60vh",
                  }} />
                </Grid>

              </Grid>
              <Grid item xs={12} md={12} lg={12} style={{}}>
                <Grid container >

                  <Box >

                    <Typography
                      className={classes.headingTypo1}
                      style={{ fontSize: "15px" }}
                    >
                      If you are under 30, it’s quite likely you haven’t even seen a postcard!
                      For many many years, a postcard was the most exciting thing to receive from a
                      friend or a family member, holidaying in some exciting city or studying in a far-off
                      country.
                      <br />
                      The postcard is usually a card with two sides- front and back. The picture
                      on the front side of the postcard is in some manner connected with the country
                      or the land the postcard is sent from.
                      <br />
                      <br />
                      <b>You see, that’s the best part of the postcard: </b> Looking at the picture of a beach, a
                      forest, a street, a monument or a famous person and trying to imagine what the
                      rest of the country, city or town must be like! There are people who actually
                      collect postcards. It’s like a collector’s item.
                      <br />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box>
                  <Typography
                    className={classes.headingTypo1}
                    style={{ fontSize: "15px" }}
                  >
                    Believe it or not, a postcard collecting club was founded in 1897 in Nuremberg.
                    They would send postcards to each other with the greeting Gutferngruß,
                    requesting a return card to be mailed back, thus collecting postcards from all over
                    the world. At their peak, until the First World War, there were about 15000
                    members of the club in Germany alone. Quite a committed bunch of postcard
                    collectors, right?
                    <br />
                    <br />
                    Although no one really uses postcards anymore, since we have Instagram,
                    snapchat and WhatsApp, the history of postcards might interest you.
                    <br />

                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Box >
                  <Typography
                    className={classes.headingTypo1}
                    style={{ fontSize: "15px" }}
                  >
                    In Austria-Hungary (yes, they were one country at the time), a certain Dr.
                    Emanuel Herrmann (a professor of Economics from Vienna) pointed out in an
                    article in the Neue Freie Presse how letter writing was a wasteful exercise in
                    terms of time and effort.
                    <br />
                    It was out of proportion to the size of the message being
                    sent, he claimed.
                    <br />
                    He felt that a more practical and cheaper method should be
                    implemented for shorter, more efficient communications.
                    <br />

                  </Typography>
                </Box>
              </Grid>


              <Grid item xs={12} md={12} lg={12}>
                <Box >
                  <Typography
                    className={classes.headingTypo1}
                    style={{ fontSize: "15px" }}
                  >
                    This seemed to have inspired a German postal official called Dr. Heinrich von
                    Stephan who conceptualized the first postcard in 1869. It took about ten years for
                    the postcard to make its debut in India- in 1879, apparently. The first postcard to
                    reach Indian shores was a straw colored ‘East India Postcard’ with the picture of,
                    you guessed it right, the then Queen, Victoria on the upper right-hand corner.
                    <br />
                    A Heligoland card, introduced in 1889, is believed to be the first-ever postcard to
                    be printed in multiple colours. What’s Heligoland? It’s an archipelago in the North
                    Sea. What’s an archipelago?
                    <br />
                    See! You are asking questions and that’s how learning begins. In a world without
                    Google, a postcard was an exciting way to learn about new things for sure.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  In the initial years, in the absence of photography, the images used on postcards
                  were mainly lithograph prints, woodcuts and broadsides. As printing and
                  photography became more and more popular and available, experimentation
                  with different pictures began. During the days of the British, postcards became a
                  very common medium of exchange between residents and their families ‘back
                  home’ in England.
                  <br />
                  It is reported that between 1900 and 1910 about 6 billion postcards were
                  couriered through the British postal system. In fact, Indian themes and subjects
                  became quite prevalent in England and Europe too. A classic example was that of
                  a ‘Tiger hunt of Elephant back’ issued by Dess Company.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  In 1893, at the World &#39;s Columbian Exposition in Chicago, a world fair visited by
                  over 26 million people, Publisher Charles W Goldsmith had a genius idea. He
                  produced a new set of official postcards, presenting the pavilions and other
                  interesting sections of the exhibition in color. These were the first commercially
                  produced pictorial postcards in the United States, and they immediately became a
                  sensational hit.
                  <br />

                </Typography>

                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  India wasn’t behind. Imagine the sheer volume of postcards that must have been
                  circulated in the country in those early years. It is believed that close to 26 million
                  postcards were sold by 1883 in India. How lovely would it be to have a book that
                  contained reproductions of these postcards from that era! It would be quite an
                  entertaining lesson in history through postcards, wouldn’t it?
                  <br />
                </Typography>

                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  ‘Picturesque India: A Journey in Early Picture Postcards (1896 - 1947)’ by Sangeeta
                  and Ratnesh Mathur does just that. It describes the humble postcard’s evolution,
                  postal delivery and transportation systems in Indian cities in the early 20th
                  century.
                </Typography>


                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  It would interest you to know that of late, despite social media and other easy
                  means of communication, there has been an unusual rise in the sale of postcards.
                  <br />
                </Typography>

                <Typography
                  className={classes.headingTypo1}
                  style={{ fontSize: "15px" }}
                >
                  The reason for this sudden rise in the use of postcards may surprise you. Jitendra
                  Singh Gurjar, a private security guard at Surat school has been sending postcards
                  to the families of martyred soldiers and paramilitary jawans across the country for
                  over 20 years! He has sent about 5,500 cards so far since the Kargil war in 1999!
                  There are still many such individuals who use the postcard to express themselves.
                  Paras Tyagi, a resident of Delhi raises awareness about various civic issues in the
                  area that he lives in by sending postcards to the responsible authorities.
                  <br />
                  There are many who continue to communicate with their loved ones near and far through the postcard as it is cheap, personalized and can be cherished as a keepsake. You can too.

                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default DiaryHistoryBlog;