import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	openedPost: {
		id: null,
		title: null,
		comments: [],
		layout: null,
		category: null,
		tags: [],
		body: null,
		images: [],
		blogId: null,
		isPublished: false
	},
	savedPost: {
		id: null,
		title: null,
		comments: [],
		layout: null,
		category: null,
		tags: [],
		body: null,
		images: [],
		blogId: null,
		isPublished: false
	},
	posts: [],
	savedPosts: [],
	likedPosts: [],
	trendingPosts: [],
	wallOfFamePosts: [],
	userPublishedPosts: [],
	userDraftPosts: [],
	draftPostEdit: false,
	counts: [],
	blogOfTheWeek: {},
	featured: [],
	userBlogTitles: [],
	globalPosts: [],
};

export const posts = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_POSTS:
			return {
				...state,
				posts: payload
			}

		case actionTypes.SET_OPENED_POST:
			return {
				...state,
				openedPost: payload,
			};

		case actionTypes.SET_SAVED_POST:
			return {
				...state,
				savedPost: payload,
			};

		case actionTypes.GET_USER_LIKED_POSTS:
			return {
				...state,
				likedPosts: payload,
			};
		case actionTypes.GET_TRENDING_POSTS:
			return {
				...state,
				trendingPosts: payload,
			};
		case actionTypes.GET_WALL_OF_FAME_POSTS:
			return {
				...state,
				wallOfFamePosts: payload,
			};
		case actionTypes.GET_USER_PUBLISHED_POSTS:
			return {
				...state,
				userPublishedPosts: payload,
			};
		case actionTypes.GET_USER_DRAFT_POSTS:
			return {
				...state,
				userDraftPosts: payload,
			};
		case actionTypes.SET_USER_DRAFT_POST_EDIT:
			return {
				...state,
				draftPostEdit: payload,
			};
		case actionTypes.COUNTS:
			return {
				...state,
				counts: payload,
			};
		case actionTypes.GET_BLOG_OF_THE_WEEK:
			return {
				...state,
				blogOfTheWeek: payload
			}
		case actionTypes.GET_FEATURED_POSTS:
			return {
				...state,
				featured: payload
			}
		case actionTypes.GET_USER_BLOG_TITLES:
			return {
				...state,
				userBlogTitles: payload
			}
		case actionTypes.GET_GLOBAL_POSTS:
			return {
				...state,
				globalPosts: payload
			}

		default:
			return state;
	}
};


export const userPosts = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.GET_USER_POSTS:
			return {
				...state,
				userPosts: payload
			}

		default:
			return state;
	}
};
