import { makeStyles } from "@mui/styles";

const LibraryStyles = makeStyles((theme) => ({

  featureBox: {
    position: "relative",
    zIndex: 100,
    padding: "3px",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "2rem",
      // float: "left",
    },
    [theme.breakpoints.down("sm")]: {
      // marginTop: "2rem",
      // float: "left",
    },
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },

  cardTitle: {
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#231f20",
    // "&:hover": {
    //   backgroundColor: "#ffffff00",
    // },
    padding: "0px !important",
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  buttonLibrary: {
    "&:hover": {
      // background: "transparent",
      color: "rgb(30, 58, 113) !important",
      background: "#FFDB00 !important",
    },
  },

  redBNT: {
    padding: "5px",
    width: "8vw !important",
    // margin: "10px",
    borderRadius: "50px !important",
    fontSize: "10px !important",
    fontWeight: "bold !important",
    backgroundColor: "rgb(255, 219, 0) !important",
    color: "#000 !important",
    [theme.breakpoints.down("sm")]: {
      width: "20vw !important",
    },

  },
  cars: {
    [theme.breakpoints.down("sm")]: {
      '& .eLPooy': {
        width: "100vw !important"
      },
    },

  },

  cardResp: {
    // borderRadius: "14px",
    width: "100%",
    backgroundColor: "#fff",
    // margin: " auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      "& .rec & .rec-arrow": {
        display: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      "& .rec & .rec-arrow": {
        display: "none",
      },
    },

    "& .rec & .rec-arrow": {
      display: "none",
    },
  },

  exploreStyle: {
    marginTop: "3rem !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0 !important",
    },
  },

  addImges: {
    marginTop: "1rem",
    borderRadius: "1.5rem !important",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tabBTN: {
    '& .css-1wf8b0h-MuiTabs-flexContainer': {
      display: "flex",
      justifyContent: "center"

    },
  },
}));

export default LibraryStyles;
