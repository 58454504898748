import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import { INITIAL_STATE as essayInitialState } from "../reducers/essay";
import { addUserLogs } from './rewards';

export const getEssays = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await db.collection("essay").limit(5).get()
            .then(async (essays) => {
                let essaysData = []
                essays.docs.forEach(async (item) => {
                    let essay = item.data()
                    essay = {
                        ...essay,
                        id: item.id
                    }
                    essaysData.push(essay)
                })
                for (let i = 0; i < essaysData.length; i++) {
                    let essay = essaysData[i]
                    let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', essay.userId).get()
                    if (user && user.docs && user.docs.length > 0) {
                        let userData = user.docs[0].data()
                        userData = {
                            ...userData,
                            cPassword: null,
                            newPassword: null,
                            password: null
                        }
                        essay = {
                            ...essay,
                            user: userData
                        }
                        essaysData[i] = essay
                    } else {
                        essaysData.splice(i, 1)
                        i--
                    }

                }

                dispatch(
                    triggerReducer(actionTypes.GET_ESSAYS, {
                        payload: essaysData
                    })
                );
                resolve(essaysData)
            })
            .catch((err) => {
                console.log("Error ---- ", err)
                reject(err)
            })
    });
};

export const getEssaysByUser = (user_id) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await db.collection("essay").where('userid', '==', user_id).get()
            .then(async (essays) => {
                let essaysData = []
                essays.docs.forEach(async (item) => {
                    let essay = item.data()
                    essay = {
                        ...essay,
                        id: item.id
                    }
                    essaysData.push(essay)
                })
                dispatch(
                    triggerReducer(actionTypes.GET_USER_ESSAYS, {
                        userEssays: essaysData
                    })
                );
                resolve(essaysData)
            })
            .catch((err) => {
                console.log("Error ---- ", err)
                reject(err)
            })
    });
};


export const setOpenedEssay = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_ESSAY, { payload }));
};

export const setUserDraftEssayEdit = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER_DRAFT_ESSAY_EDIT, { payload }));
}

export const clearEssay = () => (dispatch) => {
    const payload = essayInitialState.openedEssay;
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_ESSAY, { payload }));
};

// export const setSavedEssay = (isPublished = false) => async (dispatch, getState) => {
//     const { auth } = getState().firebase;
//     const { openedEssay } = getState().essays;
//     const { firestore } = store;
//     const payload = {
//         userId: auth.uid,
//         updatedBy: auth.uid,
//         layout: '',
//         lastUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
//         ...openedEssay,
//     };

//     if (payload.blogId && payload.blogId.label) {
//         payload.blogId = payload.blogId;
//     } else {
//         payload.blogId = ''
//     }

//     if (payload.category && payload.category.label) {
//         payload.category = payload.category;
//     } else {
//         payload.category = ''
//     }
//     if (openedEssay && openedEssay.id) {
//         payload.id = openedEssay.id
//     }
//     payload.isPublished = isPublished;

//     if (openedEssay?.blogId?.value || payload.blogId == '') {
//         dispatch(saveEssay(payload));
//     } else if (payload.blogId && payload.blogId.label && payload?.blogId?.value.length == 0) {
//         await firestore.add({ collection: "blogs" }, {
//             title: openedEssay.blogId.label,
//             userId: auth.uid,
//             category: payload.category,
//         }).then((resp) => {
//             payload.blogId = {
//                 value: resp.id,
//                 label: openedEssay.blogId.label,
//             };
//             dispatch(saveEssay(payload));
//         });
//     }
// };

export const saveUserEssay = () => async (dispatch, getState) => {
    const { firestore } = store;
    const { openedEssay } = getState().essays;
    const { tenantId } = getState().user;
    let lastUpdate = firebase.firestore.Timestamp.fromDate(new Date())
    try {
        const docRefId = openedEssay.id;
        delete openedEssay.id
        if (!docRefId) {
            dispatch(togglePageLoading(true));
            firestore.add({ collection: "essay" }, { ...openedEssay, tenantId, lastUpdate }).then((docRef) => {
                // payload.id = docRef.id;

                dispatch(triggerReducer(actionTypes.SET_SAVED_ESSAY, { ...openedEssay, id: docRef.id }));
                dispatch(triggerReducer(actionTypes.SET_OPENED_ESSAY, { ...openedEssay, id: docRef.id }));

                if (openedEssay.isPublished) {
                    let rewardPayload = {
                        essayId: docRef.id,
                        type: 'essay',
                        operation: 'create',
                        quinns: 1000,
                        milestone: 1,
                        date: firebase.firestore.Timestamp.fromDate(new Date())
                    }
                    dispatch(addUserLogs(rewardPayload))
                }

                dispatch(showSuccessSnackbar("Essay Saved Successfully"));
                dispatch(togglePageLoading(false));
            }).catch((error) => {
                console.log("error --- ", error)
                dispatch(showErrorSnackbar("Error in saving Essay"));
                dispatch(togglePageLoading(false));
            });
        } else {
            dispatch(togglePageLoading(true));
            firestore.collection("essay").doc(docRefId).update(openedEssay)
                .then(() => {
                    dispatch(triggerReducer(actionTypes.SET_SAVED_ESSAY, { openedEssay }));
                    dispatch(triggerReducer(actionTypes.SET_OPENED_ESSAY, { openedEssay }));

                    dispatch(showSuccessSnackbar("Essay Updated Successfully"));
                    dispatch(togglePageLoading(false));

                }).catch((error) => {
                    console.log("error --- ", error)
                    dispatch(showErrorSnackbar("Error in updating Essay"));
                    dispatch(togglePageLoading(false));
                });
        }
    } catch (error) {
        console.log("Error ---- ", error)
        dispatch(showErrorSnackbar("Error in saving Essay"));
        dispatch(togglePageLoading(false));
    }
};

export const getUserEssays = (isPublished = false) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    await db.collection("essay")
        .orderBy('lastUpdate', 'desc')
        .where('userId', '==', auth.uid)
        .where('isPublished', '==', isPublished)
        .get()
        .then(async (essays) => {
            let essaysData = []
            await essays.docs.forEach(async (item) => {
                let essay = item.data()
                essay = {
                    ...essay,
                    id: item.id
                }
                essaysData.push(essay)
            })
            if (isPublished) {
                console.log("essaysData---", essaysData)
                dispatch(
                    triggerReducer(actionTypes.GET_USER_PUBLISHED_ESSAYS, {
                        payload: essaysData,
                    })
                );
            } else {
                dispatch(
                    triggerReducer(actionTypes.GET_USER_DRAFT_ESSAYS, {
                        payload: essaysData
                    })
                );
            }

        })
        .catch((err) => {
            console.log("Error ---- ", err)
        })
};

export const deleteEssay = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    await firestore.collection("essay")
        .doc(payload).delete().then(() => {
            firestore.collection("comments")
                .where('essayid', '==', payload)
                .get()
                .then((comments) => {
                    if (comments && comments.docs.length > 0) {
                        comments.docs.forEach(async (item) => {
                            item.ref.delete()
                        })
                    }
                })
                .catch((error) => {
                    console.log("Errorr deleting comment", error)
                })
            dispatch(showSuccessSnackbar("essay deleted Successfully!"));
        }).catch((error) => {
            console.log("Eroorrrrrrr === ", error)
            dispatch(showErrorSnackbar("essay delete failed!!!"));
        });
}


export const setShowEssays = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_SHOW_ESSAY, { payload }));
};

export const setShowEssayIndex = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_SHOW_ESSAY_INDEX, { payload }));
};
