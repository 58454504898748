const FilterData = [
   [
      {
         filterType: 'Access',
         value: 'super_admin',
         name: 'SUPER ADMIN',
         status: false
      },
      {
         filterType: 'Access',
         value: 'admin',
         name: 'ADMIN',
         status: false
      },
      {
         filterType: 'Access',
         value: 'manager',
         name: 'MANAGER',
         status: false
      },
      {
         filterType: 'Access',
         value: 'co_ordinator',
         name: 'Co-Ordinator',
         status: false
      },
      {
         filterType: 'Access',
         value: 'reviewer',
         name: 'Reviewer',
         status: false
      },
      {
         filterType: 'Access',
         value: 'writer',
         name: 'Writer',
         status: false
      },
   ],
   [
      {
         filterType: 'User Status',
         value: 'archive',
         name: 'Archived',
         status: false
      },
      {
         filterType: 'User Status',
         value: 'active',
         name: 'Active',
         status: true
      }
   ],
   [
      {
         filterType: 'Group',
         value: 'administration',
         name: 'Administration',
         status: false
      },
      {
         filterType: 'Group',
         value: 'teaching',
         name: 'Teaching',
         status: false
      },
      {
         filterType: 'Group',
         value: 'non_teaching',
         name: 'Non-Teaching',
         status: false
      },
      {
         filterType: 'Group',
         value: 'primary_school',
         name: 'Primary School',
         status: false
      },
      {
         filterType: 'Group',
         value: 'middle_school',
         name: 'Middle School',
         status: false
      },
      {
         filterType: 'Group',
         value: 'high_school',
         name: 'High School',
         status: false
      }
   ],
   [
      {
         filterType: 'Grade',
         value: 'class_xii',
         name: 'Class XII',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_xi',
         name: 'Class XI',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_x',
         name: 'Class X',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_ix',
         name: 'Class IX',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_viii',
         name: 'Class VIII',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_vii',
         name: 'Class VII',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_vi',
         name: 'Class VI',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_v',
         name: 'Class V',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_iv',
         name: 'Class IV',
         status: false
      },
      {
         filterType: 'Grade',
         value: 'class_iii',
         name: 'Class III',
         status: false
      }
   ],
   [
      {
         filterType: 'Section',
         value: 'A',
         name: 'Section A',
         status: false
      },
      {
         filterType: 'Section',
         value: 'B',
         name: 'Section B',
         status: false
      },
      {
         filterType: 'Section',
         value: 'C',
         name: 'Section C',
         status: false
      },

   ],
   [
      {
         filterType: 'Location',
         value: 'Latur',
         name: 'Latur',
         status: false
      },
      {
         filterType: 'Location',
         value: 'Sangli',
         name: 'Sangli',
         status: false
      },
      {
         filterType: 'Location',
         value: 'Mumbai',
         name: 'Mumbai',
         status: false
      },
      {
         filterType: 'Location',
         value: 'Pune',
         name: 'Pune',
         status: false
      },
   ],
   [
      {
         filterType: 'Gender',
         value: 'male',
         name: 'Male',
         status: false
      },
      {
         filterType: 'Gender',
         value: 'female',
         name: 'Female',
         status: false
      },
      {
         filterType: 'Gender',
         value: 'other',
         name: 'Other',
         status: false
      }
   ],
   [
      {
         filterType: 'Date Of Joining',
         value: 'peter_parker',
         name: 'Enter A Date',
         status: false
      }
   ],
   [
      {
         filterType:"Date Range",
         value:'',
         name:'',
         status:false
      }
   ],
   [
      {
         filterType:'Search',
         value:'',
         name:'',
         status:false
      }
   ]
]

export default FilterData;