import React from "react";
import { Box, Grid, Container, Typography, } from "@mui/material";
import aboutl from "../../../assets/images/about/Group-775.png";
import article101 from '../../../assets/images/article/article101.jpg';
import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";

function StartWritingBlog() {
    const classes = DiaryblogsStyles();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>How do start Diary Writing / Online Diary / Quill At Will
                </title>
                <meta
                    name="title"
                    content="How do start Diary Writing / Online Diary / Quill At Will"
                />
                <meta
                    name="description"
                    content=" Start diary writing with
                    Quill At Will, Anywhere, at any time, you
                    can preserve a diary of all the beautiful 
                   experiences you wish to preserve as a 
                   memory in your life."
                />
                <meta
                    name="keywords"
                    content="Diary Writing"
                />
            </Helmet>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px',
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        How do I start writing a diary?

                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    { /* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", fontSize: "25px", fontFamily: "Museo 700", color: "#101070", }}
                    >
                        “Your Journal is like your best friend, You don't have to pretend with it, you can be honest and write exactly how you feel”
                        <br />
                    </Typography>
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontSize: "17px", fontFamily: "Museo 700", textAlign: "end" }} >
                        ― Bukola Ogunwale.
                </Typography> */}

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={article101} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "15px" }}
                                    >
                                        We all understand diaries and their importance in our lives. A diary is a best friend to most people.
                                        It is a place where we write our thoughts and feelings. It is to record what happened during
                                        the whole day and what the plan and actions are for the coming days. One can be creative and
                                        thoughtful while writing a diary. However, writing a diary regularly with consistency helps improve the
                                        thought process, build confidence and provides a scope for personal development.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                {/*<Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    The following are some uses of diaries:
                                </Typography>

                                <ul >
                                    <li style={{ color: '#000' }} >
                                        A place to store private thoughts.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A chronology of events
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        A just telling an intriguing tale.
                                    </li>
                            </ul>*/}
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Online diary writing can completely change the way you write a diary offline.
                                    You get advanced tools, various fonts, and emojis to make your diary more
                                    creative and beautiful. You need not carry the dairy everywhere or hide it from
                                    anyone when you can log in anytime from anywhere in your online diary with your
                                    login id and password.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                {/*<Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Benefits of writing online diaries
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The online diary is even more interesting and engaging!
                                    <br />
                                    It offers great features to help you write your diary more creatively and efficiently. Also, the best online diaries provide premium features to even publish your diary and help you become a published author.

                                    <br />
                        </Typography>*/}

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    How to Start Writing?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Starting is key, from deciding to write to retaining consistency in writing.
                                    Here are a few tips which can help you maintain your diary.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    1. DECIDE TO WRITE

                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    First and foremost, decide to start writing a diary.
                                    Once you dedicate time to maintaining a diary, starting one will be easy.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    2. DECIDE WHAT TO WRITE
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Writing a diary is the hardest part, but it is probably the most important.
                                    If you want your diary to be specific, decide on a topic you would like to write about
                                    during your daily entries. Although diaries are typically used to record confidential ideas,
                                    they can also be an excellent way to record personal musings.
                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                3. SCHEDULE YOUR WRITING TIME
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Starting a diary requires consistency, but it is up to you to decide how often you want to write in it.
                                Whether it is once a day or once a week, create a schedule you can adhere to.
                                Adding diary writing to your routine will make it a more comprehensive and useful practice.
                                <br />
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                4. SET A LIMIT
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                It is easy to get carried away and write excessively when writing a diary.
                                Setting a time limit for your work will help you stay focused and be concise.
                                Decide how much time you want to spend writing your diary.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                5. DATE YOUR ENTRIES
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                A diary is valuable because it allows you to reflect on it and see your development through time.
                                Every entry you make must be dated.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                6. CREATE AN INTRODUCTORY ENTRY

                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Try to write an introduction to the topic of your diary in the first entry.
                                Introduce yourself, the topics that interest you, the things you believe are
                                essential, and what you hope your diary will be about. Be honest and genuine.
                                <br />
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                7. WRITE LIKE YOU ARE TALKING TO YOUR FRIEND

                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Wondering how to draft a diary entry? The best way to write a diary is to write like you're
                                talking to your friend. You should feel comfortable writing a diary as though you were
                                talking to a trusted companion. Starting from addressing your diary as "Dear Diary",
                                like it were your friend, can lead you in the right direction for the tone you should be
                                writing in. The goal of a diary is to discuss things candidly as if you were talking them
                                through with a best friend.
                                <br />
                            </Typography>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                8. ENJOY IT!
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Jotting down your diary should never be a chore or a burden, so remember to enjoy the process
                                of writing. It can be a place of solace, creativity, and self-reflection where your thoughts
                                can roam free. When starting a diary, ensure you write about things you care and are passionate
                                about.
                                <br />
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box >
                                {/*<Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                Benefits of writing online diaries
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                The online diary is even more interesting and engaging!
                                <br />
                                It offers great features to help you write your diary more creatively and efficiently. Also, the best online diaries provide premium features to even publish your diary and help you become a published author.

                                <br />
                    </Typography>*/}

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    What to write in a diary?
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Now we have got the idea of how to start writing a diary.
                                    Let’s find out more about what you can include in your diary.
                                    Here are some of the topics you can choose from, which can be included in your diary.
                                    <br />
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    1. WRITE ABOUT THE EVENTS OF THE DAY

                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    The first thing you can start writing about is your day.
                                    When you can tell the whole story to your friends, why not to yourself and
                                    your diary? Consider everything that occurred that day,
                                    then write down any memorable moments. Even if your day seems quite regular,
                                    writing down the specifics of your day may surprise you with deeper feelings.
                                    and thoughts. Feel free to veer off any topic you want as you write about the
                                    day's events.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    2. Write your short and long term goals
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Think about your future objectives and how you plan to attain them.
                                    List both your immediate and long-term objectives. Then, go through each
                                    item on the list and outline how you intend to reach your goal. It is possible
                                    to make your goals feel more manageable by breaking them down into manageable,
                                    smaller tasks.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    3. NOTE YOUR CURRENT MOODS OR FEELINGS
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Don't worry about providing any context for your emotions;
                                    focus on accurately describing what they are. Because nobody is watching
                                    you write or nobody will read your text. It will be kept by your side forever.
                                    Then, you can embrace those emotions and ideas as inspiration for writing in-depth
                                    entries in your diary. Focus on and thoroughly examine one thought or feeling at a time.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    4. WRITE DOWN SOME INSPIRATIONAL THOUGHTS
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Inspirational quotes can come from a famous person, your
                                    favourite book or movie, or even a friend or family member.
                                    Any selection you find inspiring is a great starting point. Record the
                                    section in your diary and note where it came from. Then, explain what it means to
                                    you in your own words.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    5.  EXPLORE YOURSELF A LITTLE DEEPER
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    Make a list of topics that you love or your favourite hobbies.You can be a
                                    lover of fashion, art, sports, food, or movies. Anything goes as long as it
                                    inspires and interests you as a subject. After that, pick one article from the list
                                    and write a diary post about it.
                                    <br />
                                </Typography>

                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "15px" }}
                                >
                                    So, what are you waiting for? Start writing your diary now and explore the features of
                                    online diary writing platforms online for writing more effective diaries.
                                    <br />
                                </Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                11. Work Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                In order to keep track of your daily work, examine career options, set milestones, and accomplish your goals, write about your professional experiences. Your observations on your professional life are written down in a work journal.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                12. Electronic Diary or Digital Diary
                            </Typography>
                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Whatever your motivations for journaling are, you will undoubtedly value the convenience and flexibility of an electronic diary. Digital diaries are online diaries that users keep and maintain.
                                <br />
                                There are numerous websites where you can maintain a digital journal and store your thoughts. Quillatwill.com offers you a digital diary and journal along with many exciting features like habit trackers, goal setting, and mood board.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                13. Creative Writing Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                A creative writing diary chronicles any poetry, stories, songs, or other artistic ideas that spring to mind.
                                <br />
                                Oftentimes, people design and decorate creative writing diaries where you can list your interests and hobbies. This kind of diary can also be used to compose creative inspirational pieces and write stories.
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                14. Motivation Diaries
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Nowadays, most people enjoy keeping a motivational journal or diary. To inspire yourself, you can write about your strengths and make a list of life goals to achieve.
                                <br />
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                15. Audio Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                Keeping an audio diary is convenient because all you need to do is upload your voice recordings to the platform you want to use for maintaining your audio diary.
                                <br />
                            </Typography>

                            <Typography
                                className={classes.headingTypo}
                                style={{ fontSize: "30px" }}
                            >
                                16. Fitness Diary
                            </Typography>

                            <Typography
                                className={classes.headingTypo1}
                                style={{ fontSize: "15px" }}
                            >
                                One of the most popular diaries among fitness aficionados is a fitness diary. A fitness diary, also known as a workout or exercise diary, tracks your accomplishments and also your daily dietary intake.
                                <br />
                                These diaries are used by people to record their favourite sports and their playing experiences.
                                <br />
                                Typically, athletes use it to store their individual records, accomplishments, trophies, and photos.
                            </Typography>
                    </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default StartWritingBlog;