import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
   reviewBooks: [],
   reviewPoems: [],
   reviewEssays: [],
   reviewRequest: [],
   reviewer: {
      contentId: '',
      userId: '',
      reviewerId: '',
      type: '',
      status: '',
      sednDate: '',
      lastUpdated: '',
      isDeleted: false
   }
};

export const reviewer = (state = INITIAL_STATE, action) => {
   const { type, payload } = action;
   switch (type) {
      case actionTypes.GET_POEMS_FOR_REVIEW:
         return {
            ...state,
            reviewPoems: payload,
         };
      case actionTypes.GET_ESSAYS_FOR_REVIEW:
         return {
            ...state,
            reviewEssays: payload
         }
      case actionTypes.GET_BOOKS_FOR_REVIEW:
         return {
            ...state,
            reviewBooks: payload
         }
      case actionTypes.SET_REVIEWER:
         return {
            ...state,
            reviewer: payload
         }
      case actionTypes.GET_REVIEW_REQUEST:
         return {
            ...state,
            reviewRequest: payload
         }
      default:
         return state;
   }
};
