import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Box, Stack, Typography, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CloseIcon from "@mui/icons-material/Close";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";
import InfoIcon from '@mui/icons-material/Info';
import AppCreate from "./blogSections/AppCreate";
import AppDrafts from "./blogSections/AppDrafts";
import AppPublished from "./blogSections/AppPublished";
import AppComments from "./blogSections/AppComments";
import Footer from "../Footer";
import plusicon from "../../assets/images/Blog/BD/Icon feather plus.png";
import catimg from "../../assets/images/Blog/NBP/Cat.png";
import categoryImg from "../../assets/images/Blog/NBP/Icon1.png";
import tagImg from "../../assets/images/Blog/NBP/Icon feather-tag.png";
import calenderImg from "../../assets/images/Blog/NBP/Icon feather-calendar.png";
import commentImg from "../../assets/images/Blog/NBP/Icon.png";
import ladyImg from "../../assets/images/Blog/NBP/shutterstock_2029347044.png";
import cloudImg from "../../assets/images/Artwork1.png";

import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  setOpenedPost,
  getUserPosts,
  setUserDraftPostEdit,
  getUserBlogTitles
} from "../../store/actions/posts";

import AppPremiumBadge from "../Dashboard/sections/AppPremiumBadge";
import BlogEditorStyles from "./BlogEditorStyles";
import BlogMultiSelect from "./blogSections/BlogMultiSelect";
import CreateFabButton from "../Dashboard/CreateFabButton";
import moment from "moment";
import BlogSelectBlogTitle from "./blogSections/BlogSelectBlogTitle";

import Navbar from "../Navbar";
import Main from "../Main";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "70%",
};
const labelstyle = {
  fontSize: "1.5rem",
  color: "#1f108e",
};

const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
}

function Blog(props) {
  const classes = BlogEditorStyles();

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [blogTitle, setBlogTitle] = useState("");
  const [blogTitleId, setBlogTitleId] = useState(null);
  const [postTitle, setPostTitle] = useState("");
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(new Date());
  const [tag, setTag] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipBlogOpen, setTooltipBlogOpen] = useState(false)

  useFirestoreConnect([{ collection: "categories" }]);

  const categories = useSelector((state) => state.firestore.ordered.categories);

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const changeDate = (newValue) => {
    // setDate(newValue);
    setDate(moment().format('LLLL'));

  };

  useEffect(() => {
    dispatch(setUserDraftPostEdit(false));
    dispatch(getUserPosts());
    dispatch(getUserPosts(true));
    dispatch(getUserBlogTitles())
  }, []);

  const handleCategoryChange = (e) => {
    let tags = categories.filter((cat) => cat.title === e.target.value);
    if (tags && tags.length > 0) {
      setCategoryId(tags[0].id);
      tags = tags[0].tags;
      setTags(tags);
    } else {
      setTags([]);
    }
    setCategory(e.target.value);
  };
  const handleTagChange = (e) => {
    setTag([e.target.value]);
  };

  const handleBlogChange = (event) => {
    setBlogTitle(event.target.value);
  };

  const handleOnClickNext = () => {
    let errors = new Array(6);
    let errorStatus = false;
    if (postTitle === "" || postTitle === null || postTitle === undefined) {
      errors[0] = "Please enter valid Post title";
      errorStatus = true;
    }
    if (blogTitle === "" || blogTitle === null || blogTitle === undefined) {
      errors[1] = "Please enter valid Blog title";
      errorStatus = true;
    }
    if (category === "" || category === null || category === undefined) {
      errors[2] = "Please select valid category";
      errorStatus = true;
    }
    if (tag.length < 1) {
      errors[3] = "Please select valid tag";
      errorStatus = true;
    }
    if (date === "" || date === null || date === undefined) {
      errors[4] = "Please enter valid date and time";
      errorStatus = true;
    }
    if (comment === null || comment === undefined) {
      errors[5] = "Please select yes and no comment";
      errorStatus = true;
    }
    setErrors(errors);
    if (!errorStatus) {
      //redux code  pending//
      let post = {
        title: postTitle,
        category: {
          label: category,
          value: categoryId ? categoryId : "",
        },
        blogId: {
          label: blogTitle,
          value: blogTitleId,
        },
        tags: tag,
        date,
        commentable: comment,
        views: 0,
        likedby: [],
        lovedby: [],
        shared: 0,
      };
      dispatch(setOpenedPost(post));
      props.history.push("/blog-editor");
    }
  };
  const resetTitle = () => {
    dispatch(setOpenedPost(""));
    history.push("/blog-editor");
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (tooltipOpen || tooltipBlogOpen) {
      setTimeout(() => setTooltipOpen(false), 3000)
      setTimeout(() => setTooltipBlogOpen(false), 3000)
    }
  }, [tooltipOpen, tooltipBlogOpen])


  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const handleTooltipBlogOpen = () => {
    setTooltipBlogOpen(true);
  };
  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Container maxWidth="xl">
          <Grid container spacing={2} className={classes.mainContainerStyle}>
            <Grid item xs={12} md={10} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={12}>
                  <AppCreate></AppCreate>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Stack>
                    <div>
                      <Button
                        onClick={handleOpen}
                        style={{
                          width: "100%",
                          padding: "1rem",
                          borderRadius: "1rem",
                          backgroundColor: "#ffc107",
                          border: "none",
                          color: "white",
                          fontSize: '18px',
                          fontFamily: "Museo700",
                        }}
                      >
                        Create New <span> </span>
                        <img
                          src={plusicon}
                          alt=""
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                      // onClick={handleClose}
                      >
                        <Stack>
                          <Modal
                            open={open}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} className={classes.mainBoxStyles}
                            // style={{ height: "85vh", width: "85vw", overflow: "auto", padding: '30px' }}
                            >
                              <Box style={{ position: "relative" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={2} md={2} lg={2}>
                                    <img className={classes.blogImgStyle}
                                      src={catimg}
                                      alt="CatImg"
                                    // style={{
                                    //   position: "absolute",
                                    //   top: "-70px",
                                    //   left: "-65px",
                                    // }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    md={8}
                                    lg={8}
                                    style={{ textAlign: "center" }}
                                  >
                                    <h2 style={{ color: "#1f108e" }}>
                                      Add Details
                                    </h2>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    md={2}
                                    lg={2}
                                    style={{ textAlign: "end" }}
                                  >
                                    <CloseIcon onClick={handleClose} />
                                    {/* <Button onClick={handleClose}>Close</Button> */}
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2} marginBottom="1rem">
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="blogtitle"
                                      style={{ ...labelstyle }}
                                    >
                                      Post Title
                                    </label>
                                    {
                                      window.innerWidth < 680 ?
                                        <Tooltip title="It is the heading of the article being posted under a blog."
                                          PopperProps={{
                                            disablePortal: true,
                                          }}
                                          onClose={handleTooltipClose}
                                          open={tooltipOpen}
                                          disableFocusListener
                                          disableHoverListener
                                          disableTouchListener
                                        >
                                          <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}
                                            onClick={handleTooltipOpen}>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip> :
                                        <Tooltip title="It is the heading of the article being posted under a blog.">
                                          <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                    }
                                    {/* <Tooltip title="It is the heading of the article being posted under a blog."
                                    // enterTouchDelay={0}
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    // open={tooltipOpen}
                                    // disableFocusListener
                                    // disableHoverListener
                                    disableTouchListener
                                  >
                                    <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}
                                      onClick={handleTooltipOpen}>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                    <TextField
                                      className={classes.hkk}
                                      fullWidth
                                      type="text"
                                      id="posttitle"
                                      label="Type here"
                                      style={{
                                        width: "100%",
                                        cursor: "pointer !important",
                                      }}
                                      size="small"
                                      marginBottom="2px"
                                      onChange={(e) =>
                                        setPostTitle(e.target.value)
                                      }
                                    />
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[0] ? errors[0] : ""}
                                    </span>
                                  </Grid>

                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="blogtitle"
                                      style={{ ...labelstyle }}
                                    >
                                      Blog Title
                                    </label>
                                    {
                                      window.innerWidth < 680 ?
                                        <Tooltip title="The blog title indicates a collection of one or more posts under a single blog.
                                      For example:
                                      Blog title - My travels 
                                      Post title - My trip to Kashmir"
                                          PopperProps={{
                                            disablePortal: true,
                                          }}
                                          onClose={handleTooltipClose}
                                          open={tooltipBlogOpen}
                                          disableFocusListener
                                          disableHoverListener
                                          disableTouchListener
                                        >
                                          <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}
                                            onClick={handleTooltipBlogOpen}

                                          >
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip> :
                                        <Tooltip title="The blog title indicates a collection of one or more posts under a single blog.
                                     For example:
                                     Blog title - My travels 
                                     Post title - My trip to Kashmir

                                             ">
                                          <IconButton style={{ color: '#23348f', marginTop: '-0.8rem' }}
                                          >
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                    }
                                    <BlogSelectBlogTitle setBlogTitle={setBlogTitle} setBlogTitleId={setBlogTitleId} />
                                    {/* <TextField
                                    className={classes.hkk}
                                    type="text"
                                    fullWidth
                                    size="small"
                                    id="blogtitle"
                                    label="Type here"
                                    onChange={(e) =>
                                      setBlogTitle(e.target.value)
                                    } 
                                  /> */}
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[1] ? errors[1] : ""}
                                    </span>
                                  </Grid>

                                  {/* <Grid item xs={6} md={6} lg={6}>
                                  <label
                                    htmlFor="Blog Title"
                                    style={{ ...labelstyle }}
                                  >
                                    Blog Title
                                  </label>
                                  <TextField style={{ zIndex: "1" }}
                                    fullWidth
                                    id="Blog"
                                    select
                                    size="small"
                                    label="Type Here"
                                    value={postTitle}
                                    onChange={handleBlogChange}
                                   
                                  >
                                    <MenuItem
                                      key={"1"}
                                      value={1}
                                    >
                                      books
                                    </MenuItem>
                                    <MenuItem
                                      key={"0"}
                                      value={0}
                                    >
                                      music
                                    </MenuItem>
                                   
                                  </TextField>
                                  <span style={{ color: 'red', fontSize: '11px' }}>{errors[5] ? errors[5] : ''}</span>

                                </Grid> */}
                                </Grid>
                                <Grid container spacing={2} marginBottom="1rem">
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="category"
                                      style={{ ...labelstyle }}
                                    >
                                      <img src={categoryImg} alt="Category" />{" "}
                                      Select Category
                                    </label>
                                    <TextField
                                      fullWidth
                                      id="category"
                                      select
                                      size="small"
                                      label="Select"
                                      // value={category}
                                      onChange={handleCategoryChange}
                                    // helperText="Please select your category"
                                    >
                                      {categories &&
                                        categories.length > 0 &&
                                        categories.map((option) => (
                                          <MenuItem
                                            key={option.title}
                                            value={option.title}
                                          >
                                            {option.title}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[2] ? errors[2] : ""}
                                    </span>
                                  </Grid>
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="tags"
                                      style={{ ...labelstyle }}
                                    >
                                      <img src={tagImg} alt="tags" />
                                      Add Tags
                                    </label>
                                    <BlogMultiSelect tags={tags} setTag={setTag} />
                                    {/* <TextField
                                    className={classes.hkk}
                                    fullWidth
                                    id="tags"
                                    select
                                    size="small"
                                    label="Select"
                                    value={tag}
                                    onChange={handleTagChange}
                                    style={{ zIndex: 1 }}
                                    // helperText="Please select your tags"
                                  >
                                    <MenuItem disabled key={"0"} value={"0"}>
                                      Please Select Categories
                                    </MenuItem>
                                    {tags &&
                                      tags.length > 0 &&
                                      tags.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                  </TextField> */}
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[3] ? errors[3] : ""}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} marginBottom="1rem">
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="category"
                                      style={{ ...labelstyle }}
                                    >
                                      <img src={calenderImg} alt="Category" />
                                      Date & Time
                                    </label>
                                    {/* <Typography style={{}}>
                                      {moment().format('LLLL')}
                                    </Typography> */}
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Stack spacing={3}>
                                        <Typography style={{ border: '1px solid gray', padding: '7px', borderRadius: '5px' }}>
                                          {moment().format('LLLL')}
                                        </Typography>
                                        {/* <TextField
                                            className={classes.hkk2}
                                            // {...params}
                                          /> */}
                                        {/* <DateTimePicker
                                        label="select date"
                                        size="small"
                                        value={date}
                                        onChange={changeDate}
                                        renderInput={(params) => (
                                          <TextField
                                            className={classes.hkk2}
                                            {...params}
                                          />
                                        )}
                                      /> */}
                                      </Stack>
                                    </LocalizationProvider>
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[4] ? errors[4] : ""}
                                    </span>
                                  </Grid>
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <label
                                      htmlFor="comments"
                                      style={{ ...labelstyle }}
                                    >
                                      <img src={commentImg} alt="comments" />
                                      Comment (Yes/No)
                                    </label>
                                    <TextField
                                      style={{ zIndex: "1" }}
                                      fullWidth
                                      id="comments"
                                      select
                                      size="small"
                                      label="Select"
                                      value={comment}
                                      onChange={handleChange}
                                    // helperText="Please select your comments"
                                    >
                                      <MenuItem key={"1"} value={1}>
                                        Yes
                                      </MenuItem>
                                      <MenuItem key={"0"} value={0}>
                                        No
                                      </MenuItem>
                                      {/* {categories && categories.length > 0 && categories.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))} */}
                                    </TextField>
                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {errors[5] ? errors[5] : ""}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Button
                                      style={{
                                        padding: "5px 20px",
                                        borderRadius: "2rem",
                                        backgroundColor: "#adb5bd",
                                        border: "none",
                                        fontSize: "1.5rem",
                                        color: "grey",
                                      }}
                                      // onClick={handleClose}
                                      onClick={() => resetTitle()}
                                    >
                                      Skip
                                    </Button>
                                  </Grid>
                                  <Grid item style={{ cursor: "pointer" }}>
                                    <Button
                                      style={{
                                        padding: "5px 20px",
                                        borderRadius: "2rem",
                                        backgroundColor: "#ffc107",
                                        border: "none",
                                        fontSize: "1.5rem",
                                        color: "#1f108e",
                                        cursor: "pointer !important",
                                        zIndex: "1",
                                      }}
                                      onClick={handleOnClickNext}
                                    >
                                      Next
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Box style={{ position: "relative" }}>
                                      <img
                                        src={cloudImg}
                                        alt=""
                                        className={classes.blogImgStyle2}
                                      // style={{
                                      //   position: "absolute",
                                      //   top: "-15px",
                                      //   left: "-100px",
                                      //   zIndex: "-1",
                                      // }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item textAlign="end">
                                    <Box
                                      className={classes.blogImgStyle3}
                                    // style={{ position: "relative" }}
                                    >
                                      <img
                                        src={ladyImg}
                                        alt=""
                                        style={{
                                          position: "absolute",
                                          bottom: "-170px",
                                          right: "-175px",
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Modal>
                        </Stack>
                      </Backdrop>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppDrafts></AppDrafts>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppPublished></AppPublished>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <AppComments></AppComments>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AppPremiumBadge />
                  {/* <AppProgress></AppProgress> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.hiddensection}
                >
                  <Box className={classes.hidenImg}>
                    <img
                      src={add2}
                      alt="hi"
                      width="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img
                      src={add1}
                      alt="hi"
                      width="100%"
                      className={classes.hidenImg1}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Box className={classes.createFabStyles} >
              <CreateFabButton />
            </Box>

            {/* <img  style={{
                      position: "fixed",
                      zIndex:'999',
                      top: "50%",
                      left: "100px",
                      cursor: "pointer",
                    }}
                    src={plus}
                    alt=""
                    onClick={() => history.push("/blog-editor")}
                  /> */}
          </Grid>
        </Container>
      </Grid>
    </Main>


  );
}

export default Blog;
