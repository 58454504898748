import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import blogdemystif from '../../../assets/images/article/blogdemystif.jpg';
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "../SecretDiaryPage/SecretDiaryStyles";

const DemystifiedBlog = () => {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Box
                style={{
                    background: "#fff",
                }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>The Blog- Demystified</title>
                    <meta
                        name="title"
                        content="The Blog- Demystified"
                    />
                    <meta
                        name="description"
                        content="Reasons to Blog & Benefits of Blogging for Students!"
                    />
                    <meta
                        name="keywords"
                        content="Blog Writing Platform"
                    />
                </Helmet>

                <Grid container style={{
                    display: 'flex', justifyContent: "center", marginBottom: "3rem"
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{
                        borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                        padding: '20px'
                    }}>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "33px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            The Blog- Demystified
                        </Typography>



                        <Grid container spacing={3} stype={{ marginTop: '1rem' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <img src={blogdemystif}
                                        alt="" style={{
                                            width: "70%",
                                            height: "70%",
                                            margin: 'auto'
                                        }} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                                <Grid container >
                                    <Box >
                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontWeight: "bold", color: "#101070", fontSize: "26px", fontFamily: "Museo 700", textAlign: "left" }}
                                        >
                                            Reasons to Blog & Benefits of Blogging for Students
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px" }}
                                        >
                                            Interestingly enough, a "blog"’ is short for ‘weblog’ - as in, a log on the web. It is really meant to be a kind of online journal or more suitably, a website which displays information in reverse chronology, with the most recent posts appearing first and so on. A blog is a platform for a writer or a group of writers to share their views and their expertise in a selected category of knowledge- say history, literature, visual art etc.
                                            <br />
                                            <br />
                                            Since it’s fairly easy to start writing a blog, it's no surprise that there are more than 570 million blogs on the web and several  blog sites for writers !

                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Why Blog?
                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            There are personal reasons for writing a blog of course. If you are knowledgeable about something and you want to either air your views on a matter of significance or express your opinion on an issue and share it with the vast and limitless world wide web, then blogging is for you.
                                            <br />
                                            <br />
                                            You may also write business blogs to boost your business. Blogging for business, projects, or anything else that involves the prospect of money making has one focused goal- to ensure that the website you are running your business with has high visibility.
                                            <br />
                                            <br />
                                            It is pretty obvious that the primary objective with business blogging is to lead prospective and regular customers to your site and get them interested in your products and services. In the confusing maze of information out on the web, it is important to stay ahead of the competition and feature your blogs on best websites to read blogs.

                                            <br />
                                            <br />
                                            There is a certain accepted structure for a blog that has remained fairly unchanged over the years. It usually has a header with the menu or a navigation bar. The main content area has the latest blogs. The sidebar lists the other content- previous posts- or call to action. The footer has relevant links like a disclaimer, privacy policy, contact page, etc.

                                        </Typography>

                                        <br />
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "25px" }}
                                        >
                                            Why should students blog?



                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo}
                                            style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                            A student may find it difficult to get hold of a willing publisher to publish his or her writings. However, there are many young minds out there who are passionate about writing blogs online. They are eager to put words to their thoughts, reflections, ideas and views on contemporary issues and learn how to write blog online. A blog is their playground. It allows them to do exactly that without the censorious intervention of a publisher. It is a versatile and enabling medium. The range of subjects that can be covered under a blog is mind-blogging, oops, boggling! You can blog on travel, pets, sports, history, science, fashion, books, and a host of other subjects.
                                            <br />
                                            <br />
                                            The best part about blogging is that it invites feedback. Imagine you have written a book. How do you ensure it has reached its target audience? Blogging on the other hand, creates its own audience organically and, what’s more, it lets you receive feedback and comments from readers and followers. This is a tremendous boost to one’s morale. The commitment to authenticity and quality strengthens automatically as there is now a moral obligation to fulfil the expectations of the followers who read your blogs with interest. This instills a sense of responsibility in the young writer. Blogging doesn’t mean that one can get away with writing anything. A commitment to quality is absolutely important.
                                            <br />
                                            <br />
                                            Blogs are also a way by which students and young learners can engage in civil discourse. Ordinarily, students don’t find opportunities to voice their opinions on public affairs, current issues, and controversies. This is perceived to be the privilege of adults. Blogging is empowering in that sense because a student can rationalise and construct his/her arguments on an issue which otherwise no one may pay much heed to.
                                            <br />
                                            <br />
                                            The followers then engage with the blogger, adding comments, counter-arguments and an online discourse is built through a growing community. Blogging also instils in the young writer a sense of decorum on how to engage in a civil manner with others in public digital spaces. It is easy these days to be caught up in toxic unpleasant exchanges on various social media platforms on controversial matters but blogging by its very nature discourages that and makes polite engagement on such issues possible.

                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box>
                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "25px" }}
                                    >
                                        Benefits of Blogging
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "18px", textAlign: 'left', fontFamily: 'Museo300' }}>
                                        Blogging can be a very productive and beneficial activity. It can help you hone and refine your skills as a writer because the more you blog, the keener your sense develops as to what works and what doesn’t, what the readers are receptive to and what they aren’t.
                                        <br />
                                        <br />
                                        Blogging facilitates creative expression when they develop original content and layout of their blogs. Students have the freedom to use their imagination when customizing the themes, choosing pertinent images or videos, and planning the layout. Students can express themselves visually using this medium of writing. Writing blogs online can make learning enjoyable.
                                        <br />
                                        <br />
                                        Quill at will is one example of  blog writing website being a multi-feature creative writing platform. It is one of the best blog platforms for writers and is highly recommended for students who want to learn, build their writing skills and become professional bloggers while also having fun with the riddles and memes the page has to offer.
                                        <br />
                                        <br />
                                        The most celebrated writers have advised consistent practice of writing a certain number of words on a daily basis. Blogging regularly will help you adapt to that discipline. Eventually, you will acquire your own style and your own voice. If you want to be a writer to be reckoned with, start blogging now!

                                    </Typography>

                                    <br />
                                    <br />
                                    <br />

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default DemystifiedBlog;