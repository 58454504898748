import React, { useState } from "react";
import Main from "../Main";
import { Grid } from '@mui/material';


import Navbar from "../Navbar";
import TopBar from "../TopBar";
import Sidebar from "./Sidebar";
import AssignRoles from "./AssignRoles";
import ManageRoles from './ManageRoles'
import AccessAndPermissions from "./AccessAndPermissions";
import { makeStyles } from "@mui/styles";
import MainStyles from "../MainStyles";

const TopBarStyles = makeStyles((theme) => ({
    mainGrid: {
        '& .css-1cndkqf-MuiToolbar-root': {
            minHeight: '40px',
            height: '70px'
        }
    },
}));
const RolesAndPermissions = (props) => {
    const classes = TopBarStyles();
    const classes1 = MainStyles();
    const [menuIndex, setMenuIndex] = useState(0)

    const styles = {
        backgroundColor: '#fff',
        backgroundImage: 'none',
    }

    const returnMenuComponent = () => {
        switch (menuIndex) {
            case 0: return <ManageRoles />
            case 1: return <AssignRoles />
            case 2: return <AccessAndPermissions />
            default: return <ManageRoles />
        }
    }
    return (
        <Main styles={styles}>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                    <Navbar />
                </Grid>
                <Grid container item xs={12} md={12} style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} md={12} style={{
                        backgroundColor: "#1d396f",
                        backgroundImage: 'linear-gradient(to right, darkblue, aqua)'
                    }}
                        className={classes.mainGrid}>
                        <TopBar page={1} />
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={12} className={classes1.mainContainerboxStyle}
                // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '50px' }}
                >
                    <Grid container item xs={12} md={10} lg={10} style={{ marginTop: 20 }}>
                        <Grid item xs={12} md={2}>
                            <Sidebar setMenuIndex={setMenuIndex} menuIndex={menuIndex} />
                        </Grid>
                        <Grid container item xs={12} md={10} style={{ paddingLeft: '20px', marginBottom: '50px' }}>
                            {
                                returnMenuComponent()
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Main>
    );
}

export default RolesAndPermissions;
