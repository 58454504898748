import React from "react";
import { Grid, Stack, Box, Button } from "@mui/material";
import { storeUserDetails } from "../../../store/actions/user";
import { useState, useEffect } from "react";
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from "react-redux";

const userInit = {
  preferences: []
};

const interestsListArray = [
  "Science",
  "Technology",
  "Pets",
  "Craft",
  "Psychology",
  "Travel",
  "Books",
  "Pop",
  "Painting",
  "Sports",
  "Fiction",
  "IT",
  "Dance",
  "Poems",
  "Comics",
  "Art",
  "Scaling",
  "Animals",
];


export default function AppBottomGrid() {
  const [interests, setPreferences] = useState([]);
  const dispatch = useDispatch();

  const handleInterests = (interest) => {
    let interestsList = [...interests];
    let index = interestsList.findIndex((i) => i === interest);

    if (index > -1) {
      interestsList.splice(index, 1);
    } else {
      interestsList.push(interest);
    }

    setPreferences(interestsList);
  };

  const handleUserInfo = () => {
    try {
      dispatch(storeUserDetails({ preferences: interests }));
    } catch (e) {

    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        border: "none",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        padding: "1.5rem",
        paddingBottom: "8px",
        marginTop: "1rem",
        boxShadow: "black 1px 1px 4px",

      }}
    >
      <Box >
        {interestsListArray.map((interest, index) => (
          <Button
            key={index}
            style={{
              // marginRight: "10px",
              // marginBottom: "10px",
              borderRadius: "50px",
              borderColor: "gray",
              color: interests.includes(interest) ? "#fff" : "#000",
              margin: 3,
              //   padding: 4,
              fontSize: "10px",
              // marginBottom: 10,
            }}
            variant={interests.includes(interest) ? "contained" : "outlined"}
            size="small"
            onClick={() => handleInterests(interest)}
          >
            {interest}
          </Button>
        ))}
        {interests.length > 0 && (
          <Grid item xs={12} md={6} lg={6} style={{ marginRight: "auto", marginLeft: "auto", display: "block", }}>
            <Button
              style={{
                marginBottom: "10px",
                borderRadius: "50px",
                borderColor: "#000",
                color: "#fff",
                width: "30%",
                background: "#49AECD",
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              variant={"contained"}
              size="small"
              onClick={handleUserInfo}
            >
              Save
            </Button>
          </Grid>
        )}
      </Box>
    </Stack>
  );
}
