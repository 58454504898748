import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Grid, Menu, IconButton, AppBar, TextField,
  Toolbar, Container, Box, Stack, Typography,
  Popper, Card, Button, MenuItem, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { saveUserPoem, setOpenedPoem, getUserPoems } from "../../store/actions/poems";
import PoemEditorStyles from "./PoemEditorStyles";


const pages = ['Save', 'Review'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const PoemHeader = (props) => {
  const history = useHistory();
  const classes = PoemEditorStyles();
  const dispatch = useDispatch();
  const { openedPoem, draftPoemEdit } = useSelector((state) => state.poems);

  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  useEffect(() => {
    if (draftPoemEdit) {
      const { isReviewer, reviewerEmail } = openedPoem
      if (isReviewer)
        setEmail(reviewerEmail)
    }
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickPage = (e, page) => {

    switch (page) {
      case 'Save': props.savePoem(); break;
      case 'Review': setOpen(true); break;
      case 'Preview': break;
      default: console.log('Test')
    }
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const onClickSubmit = () => {
    dispatch(setOpenedPoem({ ...openedPoem, isReviewer: true, reviewerEmail: email }));
    setOpen(false)
  }

  return (
    <AppBar position="static" className={classes.poemHeaderSty} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Stack direction="row" spacing={2} onClick={() => history.push("/poem")}>
              <Button style={{ color: '#fff', fontSize: '17px', fontFamily: "Museo700", }}>
                <ArrowBackIosNewIcon style={{ color: '#fff', width: '28%', margin: '8px', }} /> Back
              </Button>
            </Stack>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu style={{ display: 'flex', justifyContent: 'end' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" >{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            style={{ fontFamily: "Museo700" }}
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            onClick={() => history.push("/poem")}
          >
            Back
          </Typography>
          <Box style={{ borderLeft: '1px solid #FFFFFF', fontFamily: "Museo700", marginLeft: '18px', color: 'red', width: '2%', height: '25px' }}> </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Museo700' }}
                onClick={(e) => handleClickPage(e, page)}
              >
                {page}
              </Button>
            ))}


            <Dialog onClose={() => setOpen(false)} open={open} style={{ padding: "20px !important", borderRadius: "30px !important" }}>
              <DialogContent style={{ padding: "32px" }}>
                <TextField id="outlined-basic" label="Email" variant="outlined" style={{ width: "25vw" }} />
                {/* <TextField style={{ width: "25vw" }}
                    autoFocus
                    margin="dense"
                    // value={email}
                    onChange={handleChange}
                    id="email"
                    label="Type name or Email Id"
                    type="email"
                    fullWidth
                    variant="standard"
                  /> */}
                <DialogContentText style={{ color: "#000" }}>
                  Note: 2/2 Review
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ paddingBottom: "20px", }}>
                <Button sx={{
                  marginLeft: "10px",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#FFDB00",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                  '&: hover': {
                    backgroundColor: "#FFDB00",
                  },
                }}
                  variant="contained"
                  onClick={() => onClickSubmit()} >Submit</Button>
              </DialogActions>
            </Dialog>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              // onClick={handleOpen}
              // className={classes.root1}
              sx={{
                marginLeft: "10px",
                borderRadius: "1.5rem",
                textTransform: "capitalize",
                padding: "2px 32px",
                backgroundColor: "#FFDB00",
                color: "#0B2F64",
                fontFamily: "Museo700",
                '&: hover': {
                  backgroundColor: "yellow",
                },
              }}
              variant="contained"
              onClick={() => props.publishPoem()}
            >
              Publish
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default PoemHeader;
