import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from '../components/PrivateRoute'

import Register from "../components/Register";
import Login from "../components/Login";

import BulkUserActions from "../components/BulkUserActions";
import RolesAndPermissions from "../components/RolesAndPermissions";
import SiteInsights from "../components/SiteInsights";
import { ComplaintsPortal } from "../components/complaintsPortal";



import About from "../components/pages/About"
import Contact from "../components/pages/Contact"
import TermsAndConditions from "../components/pages/TermsAndConditions/TermsAndConditions";
import CookiesPolicy from "../components/pages/CookiesPolicy/CookiesPolicy";
import PrivacyPolicy from "../components/pages/PrivacyPolicy/PrivacyPolicy";
import SecretDiary from "../components/pages/SecretDiaryPage/SecretDiary";
import DiaryBenefitBlog from "../components/pages/BlogsPages/DiaryBenefitBlog";
import DiaryTypesBlog from "../components/pages/BlogsPages/DiaryTypesBlog";
import Article from "../components/pages/BlogsPages/Article";
import DiaryHistoryBlog from "../components/pages/BlogsPages/DiaryHistoryBlog";
import DiaryPersonalBlog from "../components/pages/BlogsPages/DiaryPersonalBlog";
import OnlineDiary from "../components/pages/SecretDiaryPage/OnlineDiary";
import OnlineJournal from "../components/pages/SecretDiaryPage/OnlineJournal";
import HubPages from "../components/pages/Hub Pages/HubPages";
import Poetry from "../components/pages/BlogsPages/Poetry";
import TypesOfPoem from "../components/pages/SecretDiaryPage/TypesOfPoem";
import GuideSubpages from "../components/pages/BlogsPages/GuideSubpages";
import WritePoetry from "../components/pages/BlogsPages/WritePoetry";
import DemystifiedBlog from "../components/pages/BlogsPages/DemystifiedBlog";
import Dashboard from "../components/Dashboard";
import Profile from '../components/Profile'
import Library from "../components/Library";
import Blog from "../components/Blog";
import BlogEditor from '../components/Blog/BlogEditor'
import Diary from "../components/Diary";
import DiaryEditor from "../components/Diary/DiaryEditor";
import Poem from "../components/Poem";
import PoemEditor from '../components/Poem/PoemEditor'
import PoemViewPage from '../components/Poem/PoemViewPage'
import Essay from '../components/Essay'
import EssayEditor from '../components/Essay/EssayEditor'
import PostCard from "../components/PostCard";
import PostCardReceived from "../components/PostCard/PostCardReceived";
import PostCardEditor from '../components/PostCard/PostCardEditor'
import EssayView from "../components/Essay/EssayView";
import BlogView from '../components/Blog/blogSections/BlogView'

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/register" component={Register} />
      <PrivateRoute path="/user-organization" exact component={BulkUserActions} />
      <PrivateRoute path="/complaints-portal" exact component={ComplaintsPortal} />
      <PrivateRoute path="/roles-and-permissions" exact component={RolesAndPermissions} />
      <PrivateRoute path="/site-insights" exact component={SiteInsights} />

      {/* B2C Routes */}

      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/CookiesPolicy" component={CookiesPolicy} />
      <Route exact path="/terms-conditions" component={TermsAndConditions} />
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/secret-diary" component={SecretDiary} />
      <Route exact path="/article/benefits-of-writing-diary" component={DiaryBenefitBlog} />
      <Route exact path="/article/types-of-diary" component={DiaryTypesBlog} />
      <Route exact path="/article/history-of-postcards" component={DiaryHistoryBlog} />
      <Route exact path="/article/online-personal-diary" component={DiaryPersonalBlog} />
      <Route exact path="/online-journal" component={OnlineJournal} />
      <Route exact path="/poetry" component={Poetry} />
      <Route exact path="/poem-view" component={PoemViewPage} />
      <Route exact path="/essay-view" component={EssayView} />

      <Route exact path="/online-diary" component={OnlineDiary} />
      <Route exact path="/types-of-poem" component={TypesOfPoem} />
      <Route exact path="/hub/articles" component={HubPages} />
      <Route exact path="/article" component={Article} />
      <Route exact path="/poem-women" component={WritePoetry} />
      <Route exact path="/guide-poem" component={GuideSubpages} />
      <Route exact path="/benefits-of-blogging-for-students" componet={DemystifiedBlog} />
      <Route exact path="/hub/:article" component={HubPages} />
      <Route exact path="/hub/:article/:type" component={HubPages} />
      <Route exact path="/blog/:postId/:name" component={BlogView} />


      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute path="/library" exact component={Library} />

      <PrivateRoute exact path="/blog" component={Blog} />
      <PrivateRoute exact path="/blog-editor" component={BlogEditor} />
      <PrivateRoute exact path="/diary" component={Diary} />
      <PrivateRoute exact path="/diary-editor" component={DiaryEditor} />
      <PrivateRoute exact path="/poem" component={Poem} />
      <PrivateRoute exact path="/poem-editor" component={PoemEditor} />
      <PrivateRoute exact path="/essay" component={Essay} />
      <PrivateRoute exact path="/essay-editor" component={EssayEditor} />
      <PrivateRoute exact path="/postcard" component={PostCard} />
      <PrivateRoute exact path="/postcard-editor" component={PostCardEditor} />
      <PrivateRoute exact path="/postcard-received" component={PostCardReceived} />
    </Switch>
  );
}

export default Routes;
