import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'

export const setOpendRiddle = (payload) => (dispatch) => {
  dispatch(
    triggerReducer(actionTypes.SET_OPENED_RIDDLE, { payload })
  );
};

export const getRiddle = () => async (dispatch, getState) => {
  let riddle = [];
  await db
    .collection('riddles')
    .where('isactive', '==', true)
    .orderBy("start", "desc")
    .get()
    .then(
      querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          riddle.push(doc.data());
        })
      }).catch((error) => {
        console.log(error, "Error in getting Riddles");
      });
  dispatch(
    triggerReducer(actionTypes.SET_OPENED_RIDDLE, { payload: riddle })
  );
};


