import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import _ from 'lodash'

/*
GET_USER_COMPLAINTS:'GET_USER_COMPLAINTS',
GET_COMPLAINTS:'GET_COMPLAINTS',
*/

export const getAnnouncementsByInstitute = () => async (dispatch, getState) => {
   const { tenantId } = getState().user;
   await db.collection("message_announcement")
      .where('tenantId', '==', tenantId)
      .get()
      .then(async (announcements) => {
         let announcementsData = []
         announcements.docs.forEach(async (item) => {
            let announcement = item.data()
            announcement = {
               ...announcement,
               id: item.id
            }
            announcementsData.push(announcement)
         })
         dispatch(
            triggerReducer(actionTypes.GET_ANNOUNCEMENTS, {
               payload: announcementsData
            })
         );
      })
      .catch((err) => {
         console.log("Err get complaints --- ", err)
      })
};

export const addAnnouncement = (payload) => async (dispatch, getState) => {
   const { auth } = getState().firebase;
   const { firestore } = store;
   const date = firebase.firestore.Timestamp.fromDate(new Date())
   const what = payload.message?'Message':'Announcement'
   console.log(payload)
   await firestore.add({ collection: "message_announcement" }, {createdAt:date, ...payload, userId: auth.uid, tenantId: auth.uid })
      .then((resp) => {
         dispatch(showSuccessSnackbar(what + " send successfully!"));
      })
      .catch((err) => {
         console.log("Error --- ", err)
         dispatch(showErrorSnackbar("Failed to send "+ what));
      })
};



//testing announcements

// export const addAnnouncements = () => (dispatch, getState) => {

//    const { auth } = getState().firebase;
//    const { firestore } = store;

//    const arrayList = [
//       'The Autumn Challenge Begins!',
//       'Checkout The New Q@W 3.0 Features.',
//       'Students Need To Update Their Information.',
//       'Checkout The New Updated Manual.',
//       'The Poem competition will be held on next Wednesday.'
//    ]

//    for (let i = 0; i < arrayList.length; i++) {
//       let testDate = new Date('2022-10-20')
//       testDate.setHours(13 + i)
//       let date = firebase.firestore.Timestamp.fromDate(testDate)
//       firestore.add({ collection: "message_announcement" }, { title: arrayList[i], userId: auth.uid, tenantId: auth.uid, createdAt: date })
//          .then((resp) => {
//             console.log("annocuenfvnvn -- ", resp)
//             dispatch(showSuccessSnackbar("Announcement added successfully!"));
//          })
//          .catch((err) => {
//             console.log("Error --- ", err)
//          })
//    }

// }