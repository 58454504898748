import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { Grid, Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
// import PoemBackground from '../../assets/images/poem/background.jpeg'
import PoemBackground from '../../assets/images/poem/2.jpg'
//import PoemBackground from '../../assets/images/poem/3.jpg'

import ReactHtmlParser from "react-html-parser";
import CommentsMultiLevelPoem from '../Shared/CommentsMultiLevelPoem';
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsByPost } from '../../store/actions/comments';
import { convertKMBT } from "../../helpers/Helpers";
import eye from "../../assets/images/Blog/BD/a;hi.png";
import comment from "../../assets/images/Blog/BD/agrg.png";
import share from "../../assets/images/Blog/BD/agr.png";
import hands from "../../assets/images/Blog/BD/Group 63.png";
import { showInfoSnackbar } from "../../store/actions/snackbar";
import PoemViewCardStyles from './PoemViewCardStyles';

const useStyles = makeStyles((theme) => ({
    content: {
        display: `flex`,
        padding: theme.spacing(0),
    },
    mainContent: {
        flexGrow: 1,
        width: `100%`,
        overflowY: `scroll`,
        overflowX: `hidden`,
        paddingRight: theme.spacing(0),
        "&::-webkit-scrollbar": {
            width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
        marginLeft: theme.spacing(3),
    },
    adSection: {
        // width: '285px',
        // overflow: `hidden`,
        "&.preview": {
            height: `calc(100vh - 100px)`,
        },
    },
    blogAuthor: {
        width: `220px`,
        textAlign: `center`,
        paddingTop: theme.spacing(10),
        overflow: `hidden`,
        "&.preview": {
            height: `calc(100vh - 100px)`,
        },
    },
    imageGrid: {
        flexGrow: 1,
        overflow: `hidden`,
    },
    imageGridItem: {
        padding: 0,
    },
    image: {
        height: `auto`,
        objectFit: `cover`,
        maxWidth: `100%`,
    },
    tags: {
        display: `flex`,
        color: theme.palette.secondary.alt,
        justifyContent: `flex-end`,
        fontStyle: `italic`,
    },
    actionIcons: {
        display: `flex`,
        justifyContent: `flex-end`,
    },
    viewIcon: {
        color: `lightgreen`,
    },
    favIcon: {
        color: `red`,
    },
    shareIcon: {
        color: `green`,
    },
    commentIcon: {
        color: theme.palette.primary.main,
    },
    likeIcon: {
        color: theme.palette.primary.main,
    },
    blogIcon: {
        display: `flex`,
        flexDirection: `column`,
        textAlign: `center`,
        marginRight: 5,
    },
}));

const PoemViewCard = (props) => {
    const classes = useStyles();
    const classes1 = PoemViewCardStyles();
    const dispatch = useDispatch()
    const firestore = useFirestore();
    const auth = useSelector((state) => state.firebase.auth);

    const [poem, setPoem] = useState(null)
    const [isCommentOpen, setCommentOpen] = useState(false);
    const [likedButtonStatus, setLikedButtonStatus] = useState(false);
    const [shareButtonStatus, setShareButtonStatus] = useState(false);
    const [commentButtonStatus, setCommentButtonStatus] = useState(false);

    useEffect(() => {
        if (auth && auth.uid) {
            let viewscount = props.poem.views ? props.poem.views + 1 : 1;
            firestore.collection("poems").doc(props.poem.id).update({
                views: viewscount,
            });
        }
    }, []);

    useEffect(() => {
        dispatch(getCommentsByPost(props.poem.id))
    }, [])

    useEffect(() => {
        setPoem(props.poem)
    }, [props.poem])

    const convertPostDataToHtml = () => {
        const state = convertFromRaw(JSON.parse(poem?.body))
        let editorStateData = EditorState.createWithContent(state);
        return <Editor
            editorState={editorStateData}
            readOnly={true}
        />
    }

    const handleLikedClick = () => {
        let likedArray = poem?.likedby?.length > 0 ? [...poem.likedby] : [];
        if (likedArray.indexOf(auth.uid) === -1) {
            likedArray.push(auth.uid);
            firestore.collection("poems").doc(poem.id).update({
                likedby: likedArray,
            });
            let poemData = {
                ...poem,
                likedby: likedArray
            }
            setPoem(poemData)
            setLikedButtonStatus(true);
        } else {
            dispatch(showInfoSnackbar("You had already liked this post!"));
        }
    };

    const handleSharedClick = () => {
        let sharecount = poem.shared ? poem.shared + 1 : 1;
        const el = document.createElement("input");
        el.value = process.env.REACT_APP_DOMAIN_URL + window.location.pathname;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        firestore.collection("poems").doc(poem.id).update({
            shared: sharecount,
        });
        dispatch(showInfoSnackbar("This post is copied to share!"));
    };
    const showActions = () => (
        <React.Fragment style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Box className={classes.blogIcon}>
                <IconButton style={{ background: "lightgreen" }}>
                    <img src={eye} alt="" style={{ width: "16px", height: "16px" }} />
                    {/* <VisibilityIcon style={{ color: 'lightgreen' }}>visibility</VisibilityIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(poem.views ? poem.views : 0)}
                </Typography>
            </Box>
            <Box className={classes.blogIcon} onClick={handleLikedClick}>
                <IconButton
                    style={{ background: likedButtonStatus ? "blue" : "#b8b3b3" }}
                >
                    <img
                        src={hands}
                        alt=""
                        style={{ width: "16px", height: "16px", textAlign: "center" }}
                    />
                    {/* <ThumbUpOffAltIcon style={{ color: 'theme.palette.primary.main' }}>thumb_up_off_alt</ThumbUpOffAltIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(poem.likedby ? poem.likedby.length : 0)}
                </Typography>
            </Box>
            {/* <Box className={classes.blogIcon} onClick={handleLovedClick}>
            <IconButton className={classes.favIcon}>
              <FavoriteIcon style={{ color: 'red' }}>favorite</FavoriteIcon>
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              {convertKMBT(poem.lovedby ? poem.lovedby.length : "[]")}
            </Typography>
          </Box> */}
            <Box className={classes.blogIcon} onClick={handleSharedClick}>
                <IconButton
                    style={{ background: shareButtonStatus ? "green" : "#b8b3b3" }}
                >
                    <img src={share} alt="" style={{ width: "16px", height: "16px" }} />
                    {/* <ShareIcon style={{ color: 'green' }}>share</ShareIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(poem.shared ? poem.shared : 0)}
                </Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <IconButton
                    style={{ background: commentButtonStatus ? "blue" : "#b8b3b3" }}
                >
                    <img src={comment} alt="" style={{ width: "16px", height: "16px" }} />
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(poem.commentscount ? poem.commentscount : 0)}
                </Typography>
            </Box>
        </React.Fragment>
    );

    return (
        poem &&
        <>

            <Grid container
                style={{
                    display: 'flex', justifyContent: "center", marginTop: '1rem',
                    borderRadius: '5px', boxShadow: "1px 1px 1px 1px lightgray", border: '1px solid lightgray',
                }}>
                <Grid container item xs={12} sm={12} md={12} lg={12} style={{
                    marginTop: '-3rem', paddingTop: '80px', display: 'flex', justifyContent: 'center'
                }}>
                    <Grid container itex xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={4} md={2} lg={2} sm={2}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: red[600], height: '80px', width: '80px' }} aria-label="recipe">
                                            <img src={poem?.user?.avatarUrl} alt="User" style={{ height: "80px", width: '80px' }} />
                                        </Avatar>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={10} lg={10} sm={10}>
                                <Box style={{ textAlign: 'left', marginTop: '0.7rem' }}>
                                    <Typography variant="h5" style={{ fontFamily: 'Museo700' }}>{poem?.user?.displayName}</Typography>
                                    <Typography variant="h6" style={{ fontFamily: 'Museo300' }}>{`Poem: ${poem?.category}`}</Typography>
                                    <Typography variant="h6" style={{ fontFamily: 'Museo300' }}>
                                        {
                                            poem?.lastUpdated
                                                ? moment
                                                    .unix(poem?.lastUpdated?.seconds)
                                                    .format("MMMM D, YYYY")
                                                : null
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ fontFamily: 'Museo700' }}>{poem?.title}</Typography>
                    <Grid
                        container item xs={12}
                        style={{
                            height: 'auto',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <Grid
                            container item xs={12}
                            style={{
                                opacity: 1, position: 'absolute',
                                display: 'flex',
                                textAlign: 'center',
                                margin: 'auto',
                                justifyContent: 'center',
                                paddingTop: '2rem',
                            }}
                        >

                            <Typography className={classes1.overlappingTestStyle}>
                                {
                                    poem && poem.body ?
                                        convertPostDataToHtml()
                                        :
                                        poem && poem?.content ? ReactHtmlParser(poem?.content) : ""
                                }
                            </Typography>
                        </Grid>
                        <img style={{ opacity: '0.3', width: '100%', height: 'auto' }} src={PoemBackground} alt={"poem_image"} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                {
                    showActions()
                }
            </Grid>

            <Grid container style={{ display: 'flex', justifyContent: 'left', marginTop: 10 }}>
                <CommentsMultiLevelPoem post={poem} />
            </Grid>

        </>
    );
}
export default PoemViewCard;
