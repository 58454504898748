import React, { useState, useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  Grid,
  Box,
  Dialog,
  Paper,
  Button,
  DialogContent,
  DialogTitle,
  InputBase,
  IconButton,
  FormControl,
  NativeSelect,
  TextField
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Masonry } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import BlockStyleToolbar, { getBlockStyle } from "./BlockStyleToolbar";
import "./EssayEditorCSS.css";
import Typography from "@mui/material/Typography";
import { GoFileMedia } from "react-icons/go";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import EssayHeader from "./EssayHeader";
import { saveUserEssay, setOpenedEssay, getUserEssays } from "../../store/actions/essay";
import { showErrorSnackbar } from "../../store/actions/snackbar";
import { storage } from "../../config/fbConfig";
import { useFilePicker } from "use-file-picker";
import { ImCross } from "react-icons/im";
import SearchIcon from "@mui/icons-material/Search";
import EssayEditorStyles from "./EssayEditorStyles";
import {
  getOpenedUsersImages,
  getOpenedGallery, getPremiumImages, getStickers
} from "../../store/actions/images";

import '@draft-js-plugins/alignment/lib/plugin.css';
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import createImagePlugin from "@draft-js-plugins/image";
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import { convertToHTML } from "draft-convert";
import mockUpload from '../Blog/mockUpload';
import { getSubscription, getUserSubscription, addUserSubscription } from "../../store/actions/subscriptions";
import { CgMenuGridR } from "react-icons/cg";
import EditIcon from '@mui/icons-material/Edit';

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: mockUpload,
  addImage: imagePlugin.addImage,
});

const plugins = [imagePlugin, resizeablePlugin, alignmentPlugin, blockDndPlugin, focusPlugin];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "70%",
};
const labelstyle = {
  fontSize: "1.5rem",
  color: "#1f108e",
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#fff',
    },
  },
}));

const EssayEditor = (props) => {
  const classes = EssayEditorStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const { openedEssay, draftEssayEdit } = useSelector((state) => state.essays);
  const { gallery, usersImages, premiumImages, stickers } = useSelector(state => state.images);
  const [images, SetImages] = useState([]);
  const [gotImages, updateGotImages] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateAsHTML, setEditorStateAsHTML] = useState("");
  const [hasTextStatus, setHasTextStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [openElements, setOpenElements] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const [category, setCategory] = useState(10);
  const [categoryId, setCategoryId] = useState("");
  const { isPremiumUser, userStatusUpdated } = useSelector((state) => state.subscriptions);
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState(false)

  console.log("OpenedEssay --- ", openedEssay)
  const [openFileSelector, { filesContent, plainFiles, clear }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL",
      accept: "image/*",
    });

  useFirestoreConnect([{ collection: "essay_categories" }]);

  const essay_categories = useSelector((state) => state.firestore.ordered.essay_categories);

  useEffect(() => {
    dispatch(setOpenedEssay({ ...openedEssay, title, userId: auth?.uid, updatedBy: auth?.id }));
  }, [title])

  const handleChange = (event) => {
    console.log("event --- ", event.target)
    let id = event.target.value
    setCategoryId(id)
    let category = essay_categories[event.target.selectedIndex - 1]?.title || ''
    console.log("category -- ", category, essay_categories)
    setCategory(category)
    dispatch(setOpenedEssay({ ...openedEssay, category }));
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setOpenElements(false);
  };

  useEffect(() => {
    if (!userStatusUpdated)
      dispatch(getUserSubscription())

    try {
      let uid = auth?.uid;
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages));
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages));
      dispatch(getStickers())
      dispatch(getPremiumImages())
    } catch (e) {
      console.log("Error : ", e);
    }
  }, []);

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
    let dataStatus = editorState.getCurrentContent();
    let htmlData = convertToHTML(dataStatus);
    setEditorStateAsHTML(htmlData);
    let status = dataStatus.hasText();
    setHasTextStatus(status);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = JSON.stringify(rawContentState)
    dispatch(setOpenedEssay({ ...openedEssay, body: markup, content: htmlData }));
    clear()
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  useEffect(() => {
    var imagesArray = [];
    if (usersImages.length > 0) {
      imagesArray = imagesArray.concat(usersImages);
    }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery);
    }
    SetImages(imagesArray);
  }, [gotImages, usersImages, props.images]);

  const handleClose = () => {
    setOpen(false);
  };

  const searchContent = (e) => {
    if (e && e.target.value && e.target.value.length > 0) {
      let imagesArray = images.filter((img) =>
        img.toLowerCase().includes(e.target.value.toLowerCase())
      );
      SetImages(imagesArray);
    } else {
      let imagesArray = [];
      if (usersImages.length > 0) {
        imagesArray = imagesArray.concat(usersImages);
      }
      if (gallery.length > 0) {
        imagesArray = imagesArray.concat(gallery);
      }
      SetImages(imagesArray);
    }
  };

  const handleMediaClick = (url) => {
    setMediaUrl(url);
    setEditorState(insertImage(url));
    setOpen(false);
  };

  /////Template model open
  const handleClickOpenTemplate = (scrollType) => () => {
    setOpenTemplate(true);
    setScroll(scrollType);
  };

  /////Elements model open
  const handleClickOpenElements = (scrollType) => () => {
    setOpenElements(true);
    setScroll(scrollType);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onUnderlineClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onBoldClick = (event) => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const saveEssay = () => {
    if (hasTextStatus) dispatch(saveUserEssay());
    else {
      dispatch(showErrorSnackbar("Please, enter text for your essay"));
    }
  };

  const publishEssay = () => {
    if (hasTextStatus) {
      console.log("openedEssay---", openedEssay)
      dispatch(setOpenedEssay({ ...openedEssay, isPublished: true }))
      const { category } = openedEssay;
      if (
        category === "" ||
        category === null ||
        category === undefined
      ) {
        dispatch(showErrorSnackbar("Please, select category"));
        // handleOpenModal();
      }
      else {
        dispatch(saveUserEssay(true));
        dispatch(getUserEssays());
        dispatch(getUserEssays(true));
        props.history.push("/essay");
      }
    } else {
      dispatch(showErrorSnackbar("Please, enter text for your blog"));
    }
  };

  const { userPublishedPosts } = useSelector((state) => state.posts)
  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      let image = plainFiles[0];
      let userId = auth.uid;
      storage
        .ref(`/users/${userId}/${image.name}`)
        .put(image)
        .on("state_changed", console.log, console.error, () => {
          storage
            .ref(`/users/${userId}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async (url) => {
              let images = openedEssay.images ? openedEssay.images : [];
              images.push(url);
              dispatch(setOpenedEssay({ ...openedEssay, images }));
              onChange(insertImage(filesContent[0].content));
            });
        });
    }

  }, [plainFiles, plainFiles.length]);

  useEffect(() => {
    if (mediaUrl) {
      let imagesArray = openedEssay && Array.isArray(openedEssay.images) ? openedEssay.images : []
      imagesArray.push(mediaUrl)
      dispatch(setOpenedEssay({ ...openedEssay, images: imagesArray }));
    }
  }, [mediaUrl])

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  useEffect(() => {
    if (draftEssayEdit) {
      const { image, content, body } = openedEssay;
      let editorStateData = EditorState.createEmpty()

      if (body) {
        try {
          const state = convertFromRaw(JSON.parse(content))
          editorStateData = EditorState.createWithContent(state);
          onChange(editorStateData)
        } catch (e) {
          console.log("Error --- ", e)
        }
      } else {
        if (content) {
          const blocksFromHTML = htmlToDraft(body);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          editorStateData = EditorState.createWithContent(state);
          setEditorState(editorStateData);
        }

        if (image) {
          const contentState = editorStateData.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "IMMUTABLE",
            { src: image }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorStateData, {
            currentContent: contentStateWithEntity,
          });
          editorStateData = AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            " "
          );
          setEditorState(editorStateData);
        }
        let dataStatus = editorStateData.getCurrentContent();
        let htmlData = convertToHTML(dataStatus);
        setEditorStateAsHTML(htmlData);
        let status = dataStatus.hasText();
        setHasTextStatus(status);
      }

    }
  }, [draftEssayEdit]);

  const handleCloseElements = () => {
    setOpenElements(false);
    setOpenTemplate(false);
  };

  const renderBuyPremiumModal = () => {
    return (
      <Dialog style={{ padding: "10px" }}
        open={openElements || openTemplate}
        onClose={handleCloseElements}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"To unclock these images buy premium"}
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions style={{ paddingBottom: '20px' }}>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements}>Buy</Button>
          <Button style={{
            textAlign: "center", background: "rgb(0, 31, 88)", color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            padding: "6px 35px 6px 35px"
          }} onClick={handleCloseElements} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className="pageContainer" style={{ backgroundColor: "#f2f2f2" }}>
      <EssayHeader
        style={{ margin: "auto", display: "flex" }}
        saveEssay={saveEssay}
        publishEssay={publishEssay}
      />
      <Grid container spacing={2} className="editorContainer">
        <Grid item xs={12} sm={12} md={1} className="toolbars">
          <Box className={classes.toolbarStyling}>
            <Box>
              <BlockStyleToolbar
                editorState={editorState}
                onToggle={toggleBlockType}
              />
              <Box className={classes.underLineStyle}>
                <button className="styleButton" onClick={onUnderlineClick}>
                  U
                </button>
                <button className="styleButton" onClick={onBoldClick}>
                  <b>B</b>
                </button>
                <button className="styleButton" onClick={onItalicClick}>
                  <em>I</em>
                </button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={9} sm={12} xs={12} style={{ marginTop: "6rem" }}>
          <Grid item xs={12} style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '10px 0px' }}>
            <Grid item xs={6}>
              {
                status ?
                  <TextField id='title' variant="standard" value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                      borderColor: '#000',
                      margin: 'auto',
                      marginLeft: '10px',
                      fontSize: 40,
                      marginTop: '20px',
                      fontWeight: 'bold'
                    }}
                    style={{ fontSize: '40px', fontWeight: 'bold' }}
                    onBlur={() => setStatus(false)}
                    autoFocus={true}
                  />
                  :
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography
                      variant="h4"
                      style={{ fontFamily: "Museo700", padding: '10px 20px' }}
                    >
                      {title ? title : 'Story Title Here'}
                    </Typography>
                    <EditIcon style={{ margin: 'auto', marginLeft: '5px' }} onClick={() => setStatus(true)} />
                  </div>
              }



            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} variant="standard" style={{ float: 'right', marginRight: '20px', width: '70%' }}>
                {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
                <NativeSelect
                  id="demo-customized-select-native"
                  value={categoryId}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                  placeholder="Select or Type category"
                  style={{ width: '100%' }}
                >
                  <option aria-label="None" value="" />
                  {
                    essay_categories && essay_categories.length > 0 ?
                      essay_categories.map((cat) => <option value={cat.id}>{cat.title}</option>) : null
                  }

                  {/* <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option> */}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            className="editors"
            style={{
              marginTop: '1rem',
              overflow: "auto",
            }}
          >
            <Editor
              blockStyleFn={getBlockStyle}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onChange={onChange}
              plugins={plugins}
            />
            <AlignmentTool />
          </Grid>
        </Grid>

        <Grid item xs={2} md={2} sm={12} className={classes.iconsBoxed}>
          <Grid
            item
            sm={12}
            md={2}
            xs={12}
            style={{
              marginTop: '6rem',
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            <Box className="boxicon">
              <GoFileMedia
                style={{ color: "#fff" }}
                className="fileicons"
                onClick={handleClickOpen("paper")}
              />
            </Box>
            <Typography className="iconname">Media</Typography>
            <Box className="boxicon">
              <FiUpload
                className="fileicons"
                onClick={openFileSelector}
                modifier={imagePlugin.addImage}
              />
            </Box>
            <Typography className="iconname">Upload</Typography>


            <Box className="boxicon">
              <WorkspacePremiumIcon
                style={{ display: "flex", justifyContent: "center", height: "45px" }}
                className="fileicons"
                onClick={handleClickOpenElements("paper")}
              />
            </Box>
            <Typography className="iconname">Premium<br /> Images</Typography>


            <Box className="boxicon">
              <CgMenuGridR
                style={{ color: "#fff" }}
                className="fileicons"
                onClick={handleClickOpenTemplate("paper")}
              />
            </Box>
            <Typography className="iconname">Premium<br /> Stickers</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xl={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ borderRadius: "2rem !important" }}
      >
        <Dialog
          className={classes.dailogstyle}
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            borderRadius: "1rem !important",
            display: "flex",
            justifyContent: "right",
            paddingRight: "6rem",
          }}
        >
          <ImCross
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 0,
              margin: "10px",
              color: "gray",
            }}
          />

          <DialogTitle
            id="scroll-dialog-title"
            sx={{ borderRadius: "2rem !important" }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "1rem",
                borderRadius: "2rem",
              }}
            >
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by type"
                inputProps={{ "aria-label": "Search by type" }}
                id="searchType"
                onChange={searchContent}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                  }
                }}
              />
            </Paper>
          </DialogTitle>
          <DialogContent dividers={scroll === "div"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                fontFamily="Museo700"
                sx={{ color: "rgb(30, 58, 113)" }}
              >
                Photos
              </Typography>
              {/* <Typography>See all</Typography> */}
            </Box>
            <Box sx={{ width: '100%', minHeight: 829 }}>
              <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                {images && images.length > 0 ? images.map((img, index) =>
                  <div key={index}>
                    {/* <Label>{index + 1}</Label> */}
                    <img
                      src={img}
                      // src={`${img}?w=162&auto=format`}
                      // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                      alt='demo'
                      loading="lazy"
                      style={{
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                        display: 'block',
                        width: '100%',
                      }}
                      onClick={() => handleMediaClick(img)}
                    />

                  </div>
                ) : null
                }
              </Masonry>
            </Box>

          </DialogContent>
        </Dialog>
      </Grid>
      <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
        {
          isPremiumUser ?
            <Dialog
              className={classes.dailogstyle}
              open={openTemplate}
              onClose={handleCloseTemplate}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              borderRadius="2rem"
              sx={{
                // width: "70%",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
                // p: "2px 4px",
              }}
            >
              <ImCross
                onClick={handleCloseTemplate}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle id="scroll-dialog-title">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by template"
                    inputProps={{ "aria-label": "Search by type" }}
                    // onChange={(e) => }
                    onKeyPress={(ev) => {

                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                      }
                    }}
                  />

                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                </Paper>
              </DialogTitle>

              <DialogContent dividers={scroll === "div"}>
                <Grid spacing={2} xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    // textAlign="start"
                    component="div"
                    fontFamily="Museo700"
                    color="#1E3A71"
                  >
                    Sticker Images
                  </Typography>
                  <Box sx={{ width: '100%', minHeight: 829 }}>
                    <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                      {stickers && stickers.length > 0 ? stickers.map((img, index) =>
                        <div key={index}>
                          {/* <Label>{index + 1}</Label> */}
                          <img
                            src={img}
                            // src={`${img}?w=162&auto=format`}
                            // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                            alt='demo'
                            loading="lazy"
                            style={{
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius: 4,
                              display: 'block',
                              width: '100%',
                            }}
                            onClick={() => handleMediaClick(img)}
                          />

                        </div>
                      ) : null
                      }
                    </Masonry>
                  </Box>
                  {/* <Grid container spacing={3}>

                    {
                      stickers && stickers.length > 0 ? stickers.map((img, index) =>
                        <Grid item sm={4} sx={12}>
                          <img style={{ width: "100%", height: '100%', cursor: "pointer" }}
                            key={index}
                            component="img"
                            src={img}
                            alt="green iguana"
                            onClick={() => handleMediaClick(img)}
                          />
                        </Grid>
                      ) : null
                    }
                  </Grid> */}
                </Grid>
              </DialogContent>
            </Dialog>
            :
            renderBuyPremiumModal()
        }

      </Grid>
      {/* Elements dialog */}
      <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
        {
          isPremiumUser ?
            <Dialog
              className={classes.dailogstyle}
              open={openElements}
              onClose={handleCloseElements}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              borderRadius="2rem"
              sx={{
                // width: "70%",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
                // p: "2px 4px",
              }}
            >
              <ImCross
                onClick={handleCloseElements}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle id="scroll-dialog-title">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}

                    onKeyPress={(ev) => {

                      if (ev.key === 'Enter') {
                        // Do code here
                        ev.preventDefault();
                      }
                    }}
                  />

                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                </Paper>
              </DialogTitle>

              <DialogContent dividers={scroll === "div"}>
                <Grid spacing={3} xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    // textAlign="start"
                    component="div"
                    fontFamily="Museo700"
                    color="#1E3A71"
                  >
                    Premium Images
                  </Typography>
                  <Box sx={{ width: '100%', minHeight: 829 }}>
                    <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>

                      {premiumImages && premiumImages.length > 0 ?
                        premiumImages.map((image, index) =>
                          <div key={index}>
                            {/* <Label>{index + 1}</Label> */}
                            <img
                              src={image}
                              // src={`${img}?w=162&auto=format`}
                              // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                              alt='demo'
                              loading="lazy"
                              style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                display: 'block',
                                width: '100%',
                              }}
                              onClick={() => handleMediaClick(image)}

                            />

                          </div>
                        ) : null
                      }
                    </Masonry>
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog> :
            renderBuyPremiumModal()
        }

      </Grid>
    </div>
  );
};

export default EssayEditor;
