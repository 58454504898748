import { makeStyles } from "@mui/styles";

const PostCardStyles = makeStyles((theme) => ({
  featureBox: {
    // marginTop: "-75px",
    zIndex: 100,
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      //marginBottom: "4rem",
    },
  },

  ContainerStyle: {
    paddingTop: "3rem !important",
    paddingLeft: "0.1rem !important",
    paddingRight: "0.1rem !important",
    paddingBottom: "3rem !important",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "6rem !important",
      paddingLeft: "1.6rem !important",
      paddingRight: "1.6rem !important",
    },
  },

  postImg: {
    height: "38vh !important",
  },

  postImg1: {
    height: "38vh !important",
  },
  profileImg: {
    borderRadius: "40%",
    height: "30px",
    width: "30px",
  },
  createFabStyles1: {
    position: 'fixed', zIndex: '9999', top: '50%', right: '60px',
    '& .MuiFab-primary': {
      color: '#fff',
      backgroundColor: '#ffc107',
    }
  },

  postcardReciev: {
    display: "flex",
    position: "relative",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "3rem",
    // backgroundImage: url(''),
    backgroundColor: "#f6f6f9",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    fontSize: "100px"

  },
  marginTypography: {
    marginLeft: "15px !important",
    color: '#1E3A71',
    fontFamily: "Museo700",
  },
  marginTypography1: {
    marginLeft: "15px !important",
    color: 'gray',
    fontFamily: "Museo300",
  },

  createbtn: {
    width: "100%",
    // marginTop: '15px',
    padding: "0px",
    background: "#EDF0F2",
    // boxShadow: "0px 0px 20px #39393926",
    height: "155px",
    borderRadius: "20px",
    // marginTop:'1rem',
    cursor: 'pointer',
  },

  '&.css-1aquho2-MuiTabs-indicator': {
    position: "absolute",
    height: "4px",
    bottom: "0",
    // width: "100%",
    // WebkitTransition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#073868"
  },

  addbtn: {
    textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    marginLeft: "6.2rem",
    color: "#1E3A71",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "cneter",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      marginTop: "3rem",
      marginLeft: "8.5rem",
      color: "#1E3A71",
      padding: "0px",
    },
  },

  card: {
    display: "flex",
    flexDirection: "column",
    // padding: "30px",
    // background: "#ffffff",
    // boxShadow: "0px 0px 20px #39393926",
    borderRadius: "10px",
    border: "none",
    zIndex: 100,
    // minHeight: "320px",
    margin: "0px !important",
    padding: "0px !important",
    // "&:hover": {
    //   boxShadow: "0px 10px 20px #d3e0ff",
    //   cursor: "pointer",
    // },
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  cardTitle: {
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#231f20",
    // "&:hover": {
    //   backgroundColor: "#ffffff00",
    // },
    padding: "0px !important",
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  cardText: {
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "left",
    marginTop: "8px",
    color: "#767a84",
    padding: "4px 12px",
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  cardImg: {
    height: '190px',
    width: '1980px',
    alignItems: "left",
    justifyContent: "left",
    margin: "0px !important",
    padding: "0px !important",
    // maxHeight: '40vh !important',
    maxWidth: '100% !important',
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },
  cardImgs: {
    height: '190px',
    width: '190px',
    alignItems: "left",
    justifyContent: "left",
    margin: "0px !important",
    padding: "0px !important",
    // maxHeight: '40vh !important',
    maxWidth: '100% !important',
    [theme.breakpoints.down("sm")]: {
      // height: "100%",
    },
  },

  ReceivedBox: {
    width: "100%",
    height: '34vw',
    // overflowY:'scroll',
    borderRadius: '20px',
    background: "#EDF0F2", margin: '5px',
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: '77vw',
      // overflowY:'scroll',
      borderRadius: '20px',
      margin: 'auto'
    }
  },
  scrollBox: {
    overflowY: 'scroll', height: '28vw',
    [theme.breakpoints.down("sm")]: {
      overflowY: 'scroll', height: '67vw'
    }
  },

  sendBox: {
    width: "100%",
    borderRadius: '20px',
    height: '34vw',
    // overflowY:'scroll',
    background: "#EDF0F2",
    margin: '10px',
    padding: "20px",

    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      borderRadius: "20px",
      height: "87vw",
      // overflowY: "scroll",
      background: "#EDF0F2",
      padding: "20px",
      margin: "auto",
    }
  },

  cardImg1: {
    // backgroundSize:'',
    // height: '190px',
    // width: '190px',
    // alignItems: "left",
    // justifyContent: "left",
    margin: "0px",
    padding: "0px !important",
    width: "100%",
    height: '13vw',
    borderRadius: '2vw',
    [theme.breakpoints.down("sm")]: {
      height: "60vw",
      width: '76vw',
      borderRadius: '10vw',
    },
  },

  addTextStyle: {
    display: 'flex', justifyContent: 'space-between', width: '70%', marginTop: '2rem',
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    }
  },
  cardImg2: {
    // backgroundSize:'',
    // height: '190px',
    // width: '190px',
    alignItems: "left",
    justifyContent: "left",
    margin: "0px !important",
    padding: "0px !important",
    width: "100%",
    height: '19vw',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: '55vw',
    },
  },

  //advertigin imges styling code//
  hidenImgStyle: {
    width: '13vw', borderRadius: '10px',
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    }
  },

  hidenImgStyle1: {
    width: '13vw', borderRadius: '10px',
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },

  cardImgReceived: {
    // backgroundSize:'',
    height: '190px',
    width: '195px',
    alignItems: "left",
    justifyContent: "left",
    margin: "0px !important",
    padding: "0px !important",
    borderRadius: "20px",
    //  width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "33vw",
      height: "30vw",
      margin: "auto",

    },
  },

  draftStyle: {
    overflow: 'auto',
    // height: '44vw',
    [theme.breakpoints.down("sm")]: {
      // height: '122vw',
      overflow: 'auto',
    }
  },

  // myrooomStyle:{
  //   borderRadius: '1rem',
  //     backgroundColor: '#dddddd',
  //     padding: '1rem',
  //     [theme.breakpoints.down("sm")]:{
  //       borderRadius: "1rem",
  //       padding: "1rem",
  //       width: "80vw !important",
  //       margin: "auto !important"
  //     }
  // },
  //Editor Styling code//

  boxicon: {
    margin: 'auto',
    height: '50px',
    color: 'white',
    width: '50px',
    borderRadius: '26px',
    background: '#cbd0de',
    '&:hover': {
      background: '#1E3A71'
    },
    // padding: '13px',
  },

  fileicons: {
    height: '50px',
    width: '26px',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '1px',
  },
  // Textimg: {
  //   height: '30px',
  //   width: '20px',
  //   margin: 'auto',
  //   textAlign: 'center',
  //   justifyContent: 'center',
  //   display: 'flex',
  //   padding:'1px',
  // },
  iconname: {
    color: '#1E3B72',
    fontFamily: "Museo300",
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    textallign: 'center',
    margin: 'auto',
    padding: '5px',
    paddingBottom: '22px',
  },

  App: {
    fontfamily: 'sans-serif',
    textAlign: 'center',
  },

  mw400: {
    maxWidth: '400px',
  },

  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  toolbarClassName: {
    padding: '6px 10px 0',
    borderRadius: '30px',
    border: '1px solid #F1F1F1',
    display: 'flex',
    justifyContent: 'flex-start',
    background: 'white',
    flexWrap: 'wrap',
    fontSize: '15px',
    marginBottom: '5px',
    userSelect: 'none',
    height: '450px',
    maxWidth: '140px',
    margin: 'auto',
    float: 'left',
    marginRight: '20px',
    [theme.breakpoints.down("sm")]: {
      Float: "left",
      border: "1px solid #F1F1F1",
      height: "60vw",
      margin: "auto",
      display: "flex",
      padding: "13px 12px 2px 16px",
      flexWrap: "wrap",
      fontSize: "15px",
      maxWidth: "100vw",
      background: "white",
      userSelect: "none",
      borderRadius: "30px",
      marginBottom: "5px",
      justifyContent: "flex-start"
    }
  },
  delteStyle: {
    paddingBottom: '30px', marginRight: '-14rem',
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      // cursor: "pointer",
      marginTop: "11rem",
      marginRight: '0rem',
      // paddingBottom: "30px"
    }
  },

  cardEditorStyle: {
    display: 'flex',
    flexDirection: 'column', justifyContent: 'center', padding: '30px',
    marginTop: '-30px',
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      flexDirection: 'column', justifyContent: 'center', padding: '12px',
      marginTop: '-30px',
    }
  },

  toolbarHiddenClassName: {
    display: 'none !important',
    visibility: 'hidden !important'
  },



  editorClassName: {
    width: '33vw',
    height: '35vw',
    padding: 10,
    boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
    overflow: 'hidden',
    backgroundColor: '#fff',
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
      height: "70vw",
      marginTop: "12rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  },

  secondCardStyle: {
    display: "flex",
    flexWrap: "wrap",

    m: 1,
    width: '35vw',
    height: '35vw',
    // boxShadow: "0px 10px 20px #d3e0ff",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      m: 1,
      width: '80vw',
      height: '80vw',

    }
  },




  wrapperClassName: {
    // '& .rdw-embedded-wrapper': {
    //   display: 'none',
    // },
    // '& .rdw-image-wrapper': {
    //   display: 'none',
    // },
    // '& .rdw-remove-wrapper': {
    //   display: "none",
    // },
    // '& .rdw-emoji-wrapper': {
    //   display: 'none',
    // },
    // '& .rdw-link-wrapper': {
    //   display: 'none'
    // },
    '& .rdw-inline-wrapper': {
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
      flexWrap: "wrap",
      marginTop: "2rem !important"
    },

  },



  carsl: {

    '& .gZZBOx': {
      display: 'none'
    }
  },


  //dailog style//
  dailogstyle: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      borderRadius: '1rem !important',
      height: '38vw',
      width: '38vw',
      marginTop: '8rem',
    }
  },


}));

export default PostCardStyles;
