import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Stack, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDraftPostCards, setOpenedPostCard } from "../../../store/actions/postCards";
import PostCardStyles from '../PostCardStyles';

function Postcardsend(props) {
  const classes = PostCardStyles();
  const history = useHistory()
  const dispatch = useDispatch();
  const { draftPostCards } = useSelector(state => state.postcards);
  const draftEdit = useSelector((state) => state.postcards.draftEdit);


  useEffect(() => {
    dispatch(getDraftPostCards())
  }, [])

  const handleOnClickDraft = (card) => {
    dispatch(setOpenedPostCard(card, true));
    localStorage.setItem('draft-card-id',card.id);
    history.push('/postcard-editor')
  }

  useEffect(() => {
    
  }, [draftEdit])
  

  return (
    <Grid container spacing={2} className={classes.draftStyle}>
      {
        draftPostCards && draftPostCards.length > 0 ?
          draftPostCards.map((card) =>
            <Grid item xs={12} sm={4} md={4} lg={4} onClick={() => handleOnClickDraft(card)}>
              <Stack
                style={{
                  padding: "0",
                  marginTop: "1rem",
                  borderRadius: "2rem",
                  overflow: "hidden",
                  boxShadow: "white 0px 0px 1px 0px",
                }}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item>

                    <Box className={classes.featureBox}>
                      <Box style={{ padding: 0, overflow:'hidden' }}>
                        <img
                          component="img"
                          // height="140"
                          src={card.image}
                          alt="green iguana"
                          className={classes.cardImg2}
                        />
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Stack>
            </Grid>
          ) : null
      }


      {/* <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
            <Box className={classes.featureBox}>
                <Box style={{ padding: 0 }}>
                <img
                    component="img"
                    // height="140"
                    src={Post3}
                    alt="green iguana"
                    className={classes.cardImg1}
                />
            
                </Box>
            </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
            <Box className={classes.featureBox}>
                <Box style={{ padding: 0 }}>
                <img
                    component="img"
                    // height="250"
                    src={Post3}
                    alt="green iguana"
                    className={classes.cardImg1}
                />
                
                </Box>
            </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
            <Box className={classes.featureBox}>
                <Box style={{ padding: 0 }}>
                <img
                    component="img"
                    // height="140"
                    src={Post3}
                    alt="green iguana"
                    className={classes.cardImg1}
                />
                
                </Box>
            </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
            <Box className={classes.featureBox}>
                <Box style={{ padding: 0 }}>
                <img
                    component="img"
                    // height="140"
                    src={Post4}
                    alt="green iguana"
                    className={classes.cardImg1}
                />
                </Box>
            </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "white 0px 0px 1px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
            <Box className={classes.featureBox}>
                <Box style={{ padding: 0 }}>
                <img
                    component="img"
                    // height="140"
                    src={Post4}
                    alt="green iguana"
                    className={classes.cardImg1}
                />
                </Box>
            </Box>
            </Grid>
          </Grid>
        </Stack>
      </Grid> */}

    </Grid>
  );
}
export default Postcardsend;