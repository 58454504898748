import { triggerReducer, actionTypes } from '.';
import firebaseApp, { auth, googleAuthProvider, firebase, db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from '../store'
import _ from 'lodash'



export const getPermissionsByTenantId = () => (dispatch, getState) => {
   const { tenantId } = getState().user

   db.collection("institutePermissions")
      .where('tenantId', '==', tenantId)
      .get()
      .then(async (res) => {
         const permissionsData = []
         res.docs.forEach(async (item) => {
            let user = item.data()
            user = {
               ...user,
               id: item.id,
            }
            permissionsData.push(user)
         })

         dispatch(
            triggerReducer(actionTypes.GET_INSTITUTE_PERMISSIONS, {
               payload: permissionsData
            })
         );
      })
      .catch((err) => {
         console.log("Error fetching ----", err)
      })
}


//testing b2b 

var data = [
   {
      permission_name: 'Assign roles',
      permission_short: 'assign_roles',
   },
   {
      permission_name: 'Override Permissions',
      permission_short: 'override_permissions',
   },
   {
      permission_name: 'User Actions',
      permission_short: 'user_actions',
   },
   {
      permission_name: 'Complaint Portal',
      permission_short: 'complaint_portal',
   },
   {
      permission_name: 'Announcements',
      permission_short: 'announcements',
   },
   {
      permission_name: 'Reviewer Access',
      permission_short: 'reviewer_access',
   },
   {
      permission_name: 'Help Center Administration)',
      permission_short: 'help_center_admin',
   },
   {
      permission_name: 'Help Center (USER)',
      permission_short: 'help_center_user',
   },
   {
      permission_name: 'User Insights',
      permission_short: 'user_insights',
   },
   {
      permission_name: 'Reviewer Insights',
      permission_short: 'reviewer_insights',
   },
   {
      permission_name: 'Post Activity Insights',
      permission_short: 'post_activity_insights',
   },
   {
      permission_name: 'User Leaderboard',
      permission_short: 'user_leaderboard',
   }
]
var access = [
   [true, true, true, true, true, true, true, true, true, true, true, true],
   [false, false, true, true, true, true, false, true, true, true, true, true],
   [false, false, false, true, false, true, false, true, false, false, true, true],
   [false, false, false, true, false, true, false, true, true, false, false, true],
   [true, true, true, true, true, true, true, true, true, true, true, true],
]

var roles = ['admin', 'co_ordinator', 'reviewer', 'writer', 'manager']

export const setDefaultPemissionRoles = (payload) => (dispatch, getState) => {
   const { firestore } = store;
   for (let i = 0; i < roles.length; i++) {
      let accessData = access[i]
      let permissions = []
      for (let j = 0; j < accessData.length; j++) {
         let tempData = data[j]
         let pemission = {
            ...tempData,
            status: accessData[j],
         }
         permissions.push(pemission)
      }
      let newData = {
         role: roles[i],
         permissions
      }
      firestore.add({ collection: "permissions" }, { ...newData }).then((resp) => {
         console.log("Added successfully roles for ", roles[i])
      });
   }

}


export const setDefaultPemissionList = (payload) => (dispatch, getState) => {
   const { firestore } = store;
   for (let i = 0; i < data.length; i++) {
      let tempData = data[i]
      firestore.add({ collection: "permissionsList" }, { ...tempData, status: false }).then((resp) => {
         console.log("Added successfully roles for ", tempData)
      });
   }

}

export const setSuperAdminPermissions = (role_code) => (dispatch, getState) => {
   let permissions = {
      complaint_portal: true,
      assign_roles: true,
      override_permissions: true,
      reviewer_access: true,
      user_leaderboard: true,
      help_center_user: true,
      help_center_admin: true,
      reviewer_insights: true,
      user_insights: true,
      announcements: true,
      post_activity_insights: true,
      user_actions: true
   }
   dispatch(
      triggerReducer(actionTypes.SET_USER_PERMISSIONS, {
         payload: permissions
      })
   )
}

export const setUserPermissions = (role_code) => (dispatch, getState) => {
   const { tenantId } = getState().user
   console.log("tenantId---", tenantId, role_code)
   if (tenantId && role_code) {
      db.collection('instituteRoles')
         .where('tenantId', '==', tenantId)
         .where('role_code', '==', role_code)
         .get()
         .then((res) => {
            console.log("res ===>", res?.docs[0]?.data())
            let data = res?.docs[0]?.data()
            let roles = {

            }
            if (data && data.permissions.length > 0) {
               for (let i = 0; i < data.permissions.length; i++) {
                  let temp = data.permissions[i]
                  roles = {
                     ...roles,
                     [temp.permission_short]: temp.status
                  }
               }
               dispatch(
                  triggerReducer(actionTypes.SET_USER_PERMISSIONS, {
                     payload: roles
                  })
               )
            }
         })
         .catch((err) => {
            console.log("error ---", err)
         })
   }
}

export const addInstitutePermissions = () => async (dispatch, getState) => {
   let permissionData = []
   let rolesData = []
   await db.collection('permissions').get()
      .then((permissons) => {
         permissons.docs.forEach((permission) => {
            let data = permission.data()
            permissionData.push(data)
         })
      })
   await db.collection('instituteRoles').get()
      .then((roles) => {
         roles.docs.forEach((role) => {
            let data =
            {
               ...role.data(),
               id: role.id
            }
            rolesData.push(data)
         })
         // console.log("rolesData --- ", rolesData)
         for (let i = 0; i < rolesData.length; i++) {
            let roleData = rolesData[i]
            let index = permissionData.findIndex((permission) => (permission.role === roleData.role_code) || (roleData.role_code === 'super_admin' && permission.role === 'admin'))
            if (index > -1) {
               console.log("Pemissiondata ", permissionData[index], roleData.role_code, roleData.id)
               let tempData = permissionData[index]
               db
                  .collection('instituteRoles')
                  .doc(roleData.id)
                  .update({ permissions: tempData.permissions })
                  .then(res => {
                     console.log("Respos --- ", res)
                  }).catch((error) => {
                     console.log("Error : ", error)
                  });
            }
         }
      })

}
//testing end


