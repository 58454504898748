import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { db } from "../../config/fbConfig"


export const openedHabit = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER_HABITS, {
            payload
        })
    );
};

export const clearOpenedHabits = () => (dispatch, getState) => {
    let habits = {
        id: null,
        habit: null,
        userId: null,
        date: [],
        status: null
    }
    dispatch(
        triggerReducer(actionTypes.SET_USER_HABITS, {
            openedhabit: [],
            habits : []
        })
    );
};

export const getHabits = () => (dispatch, getState) => {

    return new Promise(async (resolve, reject) => {
        const { auth } = getState().firebase;
        let habits = [];
        await db
            .collection('habits')
            .where('userId', '==', auth.uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    habits.push({ ...doc.data(), id: doc.id });
                })
            }).catch((error) => {
                console.log(error)
            });
        dispatch(
            triggerReducer(actionTypes.GET_USER_HABITS, {
                payload: habits
            })
        );
        // console.log("habits",habits);        
        resolve(habits)
    });
};

export const saveHabits = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    if((payload.habit).length <=0)
    {
        dispatch(showErrorSnackbar("Habit must not be empty"))
    }
    else
    {
        const { firestore } = store;
    await firestore.add({ collection: "habits" },
       payload
    ).then((resp) => {
        dispatch(getHabits())
        dispatch(showSuccessSnackbar("Habit Added!"));
    })
    .catch((err)=>{
        console.log("Error : ", err)
    })
    }
};

export const updateHabits = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { firestore } = store;
    let docId = payload.id
    delete payload.id
    await firestore
        .collection("habits")
        .doc(docId)
        .update(payload)
        .then((resp) => {
            dispatch(getHabits());
            dispatch(showSuccessSnackbar('habit Updated'));
        })
};

export const deleteHabit = (id) => async (dispatch, getState) => {
console.log("id===>",id);
    const { firestore } = store;
    await firestore.collection('habits')
            .doc(id).delete().then(() => {
                dispatch(showSuccessSnackbar("habit Deleted"));
            }).catch((error) => {
                dispatch(showErrorSnackbar("Failed to delete"));
            });
    dispatch(getHabits())
}