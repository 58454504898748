import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import { Grid, Paper, Typography, FormControl, Button } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';

import OnboardingStyles from './OnboardingStyles'

const Onboarding = (props) => {
    const classes = OnboardingStyles()
    const [fileObjects, setFileObjects] = useState([]);


    // const handlePreviewIcon = (fileObject, classes) => {
    //     const { type } = fileObject.file

    //     return <AttachFile />
    //     // const iconProps = {
    //     //     className: classes.image,
    //     // }

    //     // if (type.startsWith("video/")) return <Theaters {...iconProps} />
    //     // if (type.startsWith("audio/")) return <AudioTrack {...iconProps} />

    //     // switch (type) {
    //     //     case "application/msword":
    //     //     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //     //         return <Description {...iconProps} />
    //     //     case "application/pdf":
    //     //         return <PictureAsPdf {...iconProps} />
    //     //     default:
    //     //         return <AttachFile {...iconProps} />
    //     // }
    // }

    const dropzoneText = () => {

        return `<div style="color:gray;">
            <h6>Drop the CSV file here</h6>
            <p>or use the "Browse" buton. </p>
            <br/>
            <button style="padding:10px 30px; 
                background-color:#383838; 
                color:#fff; font-weight:bold;
                border-radius:20px; cursor:pointer;"
            >
                Browse
            </button>
        </div>`
    }

    const getIcon = () => {
        return <AttachFile style={{ dispaly: 'none', color: '#fff' }} className={classes.dropzoneHideIcon} />
    }

    // const selectDropzoneFiles = (files) => {
    //     if (files === 0) {
    //     } else {
    //         setFileObjects(files);
    //     }
    // };

    return (
        <Grid container style={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'center', maxHeight: '70%' }}>
            <Grid item sm={12} md={6} lg={4}>
                <Grid item sm={12} md={12} component={Paper} style={{ borderRadius: '10%', padding: 100, paddingTop: 50, paddingBottom: 20, textAlign: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{ fontFamily: 'Museo700', color: '#1E3A71', height: 'auto' }}
                    >
                        Onboarding
                    </Typography>
                    <br />
                    <Typography
                        variant="h6"
                        style={{ fontFamily: 'Museo700', color: '#000' }}
                    >
                        To create multiple users at once
                    </Typography>

                    <FormControl variant="standard" style={{ width: '100%', justifyContent: 'left', textAlign: 'left' }}>
                        <Timeline position="right" style={{ paddingLeft: 0, marginLeft: 0 }} className={classes.root}>
                            <TimelineItem>
                                <TimelineSeparator className={classes.timelineConnector}>
                                    <TimelineDot style={{ padding: '5px 13px' }}>
                                        {/* <FastfoodIcon /> */}
                                        1
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Grid container item xs={12}>
                                        <Typography variant="p" component="span">
                                            Download sample format sheet
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 30 }}>
                                        <Button style={{
                                            backgroundColor: '#383838',
                                            fontFamily: 'Museo700',
                                            color: '#fff',
                                            width: '80%',
                                            padding: '10px 15px',
                                            fontSize: '13px'
                                        }}
                                            size="large"
                                            onClick={() => props.setPageCount(prev => prev + 1)}
                                        >
                                            <SimCardDownloadIcon style={{ marginRight: 10 }} />
                                            {"  Sample Document"}
                                        </Button>
                                    </Grid>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot style={{ padding: '5px 13px' }}> 2 </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="p" component="span">
                                        Fill user details accordingly
                                    </Typography>
                                    {/* <Typography>Because it&apos;s awesome!</Typography> */}
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot style={{ padding: '5px 13px' }}> 3 </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="p" component="span">
                                        Upload file in .csv format
                                    </Typography>
                                    {/* <Typography>Because you need rest</Typography> */}
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </FormControl>

                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <DropzoneArea
                            // dropzoneClass="dropzoneHeight"
                            fileObjects={fileObjects}
                            Icon={getIcon}
                            dropzoneText={ReactHtmlParser(dropzoneText())}
                            onChange={(files) => setFileObjects(files)}
                            filesLimit={1}
                            showFileNamesInPreview={true}
                            useChipsForPreview={true}
                            previewText={ReactHtmlParser('<br/>')}
                            acceptedFiles={[".xlsx", ".xlsm", ".xls", ".csv"]}
                        />
                    </FormControl>

                    <br /><br />
                    <Grid container style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', margin: 'auto' }}>
                            <Button style={{
                                backgroundColor: '#fff',
                                fontFamily: 'Museo700',
                                color: 'gray',
                                width: '60%',
                                padding: 15,
                                border: '1px solid gray'
                            }}
                                size="large"
                                onClick={() => props.setPageCount(prev => prev - 1)}
                            >
                                BACK
                            </Button>

                        </Grid>

                        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', maxWidth: '17vw' }}>
                            <Button style={{
                                backgroundColor: '#1E3A71',
                                fontFamily: 'Museo700',
                                color: '#fff',
                                width: '60%',
                                padding: 15
                            }}
                                size="large"
                                disabled
                            // onClick={() => props.setPageCount(prev => prev + 1)}
                            >
                                NEXT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default Onboarding;
