import { actionTypes } from "../actions/index";

export const INITIAL_STATE = {
  club: "",
  clubs: [],
  posts: []
};

export const clubs = (state = INITIAL_STATE, action) => {
  
  const { type, payload } = action;
  switch (type) {
    // case actionTypes.GET_PLAGIARISM_REPORT:
    //     return {
    //         ...state,
    //         plagiarismReport: payload
    //     }
    case actionTypes.GET_ALL_CLUBS:
      return {
        ...state,
        clubs:payload // Update clubs array with payload value
      };
    case actionTypes.GET_ALL_POSTS:
      return {
        ...state,
        posts: payload
      };
    default:
      return state;
  }
};
