import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import eye from "../../../assets/images/Blog/BD/a;hi.png";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import share from "../../../assets/images/Blog/BD/agr.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";
import blankimg from "../../../assets/images/Blog/blankimg.png";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import BlogEditorStyles from "../EssayEditorStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getUserPosts, deletePost } from "../../../store/actions/posts";
import { useFirestoreConnect } from "react-redux-firebase";
import { deleteEssay, getUserEssays, setShowEssayIndex, setShowEssays } from "../../../store/actions/essay";



export default function AppDraftData(props) {
  const classes = BlogEditorStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { firestore } = useSelector((state) => state);
  const { userPublishedEssays } = useSelector((state) => state.essays)
  const { user } = useSelector((state) => state.user)
  const auth = useSelector(state => state.firebase.auth);
  const [id, setId] = useState([]);
  // const { users } = firestore.ordered;
  // const [user, setUser] = useState([]);

  // useFirestoreConnect([{ collection: "users", doc: auth.uid }]);

  useEffect(() => {
    console.log('userPublishedEssays----', userPublishedEssays)
  }, [userPublishedEssays]);

  useEffect(() => {
    try {
      dispatch(getUserEssays(true))
    } catch (error) {
      console.log("error --- ", error)
    }
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (e, post) => {
    e.stopPropagation();
    setId(post.id)
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(deleteEssay(id));
    dispatch(getUserEssays(true))
    setOpen(false);
  }

  useEffect(() => {
    let userEssays = userPublishedEssays
    for (let i = 0; i < userEssays.length; i++) {
      userEssays[i] = {
        ...userEssays[i],
        user
      }
    }
    dispatch(setShowEssays(userEssays))
  }, [userPublishedEssays])

  const onClickNo = () => {
    setOpen(false);
  }

  const handleEssayClick = (index) => {
    dispatch(setShowEssayIndex(index))
    history.push('/essay-view')
  }

  return (
    <Grid container spacing={2}>


      <Dialog
        open={open}
        keepMounted
        onClose={onClickNo}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Are you sure you want to Delete ?'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            lorem ipsum dolor sit amet, consectetur
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button style={{ background: 'rgb(0, 31, 88)', color: '#fff' }} onClick={handleClose}>Yes</Button>
          <Button style={{ background: 'rgb(0, 31, 88)', color: "#fff" }} onClick={onClickNo}>No</Button>
        </DialogActions>
      </Dialog>

      {userPublishedEssays && userPublishedEssays.length > 0
        ? userPublishedEssays.map((post, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4}
            // onClick={() => history.push('/blog/' + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())}
            onClick={() => handleEssayClick(index)}
            style={{ cursor: 'pointer' }}
          >
            <Stack className={classes.responsiveCard}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box style={{ position: "relative" }}>

                    <Box>
                      <DeleteIcon onClick={(e) => handleClickOpen(e, post)}
                        className={classes.deleteStyles}
                      />
                    </Box>
                    <div style={{ minHeight: '200px', backgroundColor: 'skyblue' }}>
                      <img
                        src={post.images && post.images.length > 0 ? post.images : blankimg}
                        //  src={bgImageSrc2}
                        alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover' }} />
                    </div>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0",
                        // margin: "10px",
                        padding: '1rem',
                        width: '78vw',
                        background: 'linear-gradient(rgba(255,0,0,0), #000000)',

                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{ color: "#fff" }}
                      >
                        <Grid item>
                          <img src={eye} alt="" />
                          <span> {post?.views || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={hands} alt="" />
                          <span> {post && post.likedby
                            ? post.likedby.length
                            : 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={comment} alt="" />
                          <span> {post?.comments || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={share} alt="" />
                          <span> {post?.shared || 0} </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    spacing={2}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <div style={{ height: '100px', width: '17vw', overflow: 'hidden', paddingRight: '10px' }}>
                      <h3 style={{ color: "grey", marginTop: "0px", }}>
                        {post?.title}
                      </h3>

                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                        }}
                      // dangerouslySetInnerHTML={{ __html: post?.body }}
                      >
                        {
                          post && post.content ? ReactHtmlParser(post?.content) : null
                        }
                        {/* {
                        post.body ?
                          post.body.length < 100 ?
                            ReactHtmlParser(post?.body) :
                            ReactHtmlParser(post?.body.substring(0, 50)) + '....' : ''
                      } */}
                      </p>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      marginBottom="0"
                    >
                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <img
                          style={{
                            width: "34px",
                            height: "34px",
                            borderRadius: "50%",
                          }}
                          src={user.avatarUrl || auth.photoURL}
                          alt="pc" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={7}>
                        <Stack direction="row" spacing={2}>
                          <b
                            style={{
                              fontWeight: "500",
                              color: "rgb(0, 31, 88)",
                              whiteSpace: 'nowrap !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important',
                            }}
                          >
                            {/* Mitali Singh |{" "} */}
                            {user?.displayName}
                          </b>
                          {/* <span style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}> {post?.title}</span> */}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <b
                          style={{
                            fontWeight: "500",
                            color: "rgb(0, 31, 88)",
                            textAlign: "right",
                          }}
                        >
                          {post.lastUpdate
                            ? moment
                              .unix(post.lastUpdate.seconds)
                              .format("DD MMM YY")
                            : null}
                        </b>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid >
        )) : null
      }
      {/* <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "black 0px 0px 5px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box style={{ position: "relative" }}>
                <img src={bgImageSrc2} alt="bg" style={{ width: "100%" }} />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "0",
                    margin: "10px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    spacing={4}
                    style={{ color: "#fff" }}
                  >
                    <Grid item>
                      <img src={eye} alt="" />
                      <span>7500</span>
                    </Grid>
                    <Grid item>
                      <img src={hands} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={comment} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={share} alt="" />
                      <span>100</span>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Stack
                direction="column"
                spacing={2}
                style={{
                  padding: "1rem",
                }}
              >
                <h3 style={{ color: "grey", marginTop: "0px" }}>
                  Lorem ipsum dolor
                </h3>

                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "small",
                    color: "grey",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit.
                </p>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  marginBottom="0"
                >
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <img src={profilePicSrc1} alt="pc" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Stack direction="row" spacing={2}>
                      <b
                        style={{
                          fontWeight: "500",
                          color: "rgb(0, 31, 88)",
                        }}
                      >
                        Mitali Singh
                      </b>
                      <span>Blog</span>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <b
                      style={{
                        fontWeight: "500",
                        color: "rgb(0, 31, 88)",
                        textAlign: "right",
                      }}
                    >
                      Feb 17
                    </b>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Stack
          style={{
            padding: "0",
            marginTop: "1rem",
            borderRadius: "2rem",
            overflow: "hidden",
            boxShadow: "black 0px 0px 5px 0px",
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box style={{ position: "relative" }}>
                <img src={bgImageSrc2} alt="bg" style={{ width: "100%" }} />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "0",
                    margin: "10px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    spacing={4}
                    style={{ color: "#fff" }}
                  >
                    <Grid item>
                      <img src={eye} alt="" />
                      <span>7500</span>
                    </Grid>
                    <Grid item>
                      <img src={hands} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={comment} alt="" />
                      <span>300</span>
                    </Grid>
                    <Grid item>
                      <img src={share} alt="" />
                      <span>100</span>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Stack
                direction="column"
                spacing={2}
                style={{
                  padding: "1rem",
                }}
              >
                <h3 style={{ color: "grey", marginTop: "0px" }}>
                  Lorem ipsum dolor
                </h3>

                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "small",
                    color: "grey",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit.
                </p>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  marginBottom="0"
                >
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <img src={profilePicSrc1} alt="pc" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Stack direction="row" spacing={2}>
                      <b
                        style={{
                          fontWeight: "500",
                          color: "rgb(0, 31, 88)",
                        }}
                      >
                        Mitali Singh
                      </b>
                      <span>Blog</span>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <b
                      style={{
                        fontWeight: "500",
                        color: "rgb(0, 31, 88)",
                        textAlign: "right",
                      }}
                    >
                      Feb 17
                    </b>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid> */}
    </Grid >
  );
}
