import { Checkbox, Dialog, FormControlLabel, Grid, Radio} from '@material-ui/core'
import React, { useEffect, useState } from 'react'


const ComplaintFilter = ({open,setOpen,data,setData}) => {
    const [menuIndex,setMenuIndex] = useState('time') 
    
    const handleFilterChange = (type,index)=>{
        if(type =='category'){
            setData((prev)=>{
                prev[type][index].status = !prev[type][index].status
                return {...prev}
            })
        }
        else{
            setData((prev)=>{
                prev[type].forEach((element,i) => {
                    if(i == index){
                        element.status = !element.status
                    }
                    else{
                        element.status = false
                    }
                });
                return {...prev}
            })
        }
    }
  return (
    <Dialog style={{padding: '0',width: '100vw',align: 'left',display: 'flex',justifyContent: 'left',alignItems: 'flex-start',}} open = {open} onClose={()=>setOpen(false)}>
        <Grid style ={{display:'flex'}} >
            <Grid style ={{borderRight:'1px solid #cbcbcb',height:'50vh'}}>
                <Grid onClick={()=>setMenuIndex('time')} style={{width:'200px',backgroundColor:menuIndex=='time'?'#E6FBFB':'white',color:'#1E3A71',fontWeight:menuIndex=='time'?'700':'400',cursor:'pointer',padding:'15px 25px',borderBottom:'1px solid #cbcbcb' }}>Time</Grid>
                <Grid onClick={()=>setMenuIndex('category')} style={{width:'200px',backgroundColor:menuIndex=='category'?'#E6FBFB':'white',color:'#1E3A71',fontWeight:menuIndex=='category'?'700':'400',cursor:'pointer',padding:'15px 25px',borderBottom:'1px solid #cbcbcb' }}>Category</Grid>
                <Grid onClick={()=>setMenuIndex('sortby')} style={{width:'200px',backgroundColor:menuIndex=='sortby'?'#E6FBFB':'white',color:'#1E3A71',fontWeight:menuIndex=='sortby'?'700':'400',cursor:'pointer',padding:'15px 25px',borderBottom:'1px solid #cbcbcb' }}>Sort By</Grid>
            </Grid>
            <Grid style ={{width:'300px'}}>
                {
                    menuIndex == 'time'?
                        data[menuIndex].map((item,index)=>{
                            return <Grid style ={{padding:'10px'}} key={index}>
                                <FormControlLabel
                                control={
                                    <Radio onChange={()=>{handleFilterChange(menuIndex,index)}} checked={item.status}  name="gilad" style={{ marginLeft: '30px',position:'50' }} />
                                }
                                label={item.name}
                                labelPlacement="end"
                            />
                                </Grid>
                        }):menuIndex == 'category'?data[menuIndex].map((item,index)=>{
                            return <Grid key={index} style ={{padding:'10px'}}>
                                <FormControlLabel
                                control={
                                    <Checkbox onChange={()=>{handleFilterChange(menuIndex,index)}} checked={item.status}  name="gilad" style={{ marginLeft: '30px',position:'50' }} />
                                }
                                label={item.name}
                                labelPlacement="end"
                            />
                                </Grid>
                        }):data[menuIndex].map((item,index)=>{
                            return <Grid style ={{padding:'10px'}} key={index}>
                                <FormControlLabel
                                control={
                                    <Radio onChange={()=>{handleFilterChange(menuIndex,index)}} checked={item.status}  name="gilad" style={{ marginLeft: '30px',position:'50' }} />
                                }
                                label={item.name}
                                labelPlacement="end"
                            />
                                </Grid>
                        })
                }
            </Grid>
        </Grid>
    </Dialog>
  )
}

export default ComplaintFilter