import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Grid, Container, Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import store from "../../store/store";

const style = {
  position: "absolute",
  top: "37%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "50%",
};

const labelstyle = {
  fontSize: "1.5rem",
  color: "#1f108e",
};


const pages = ['Save', 'Share', 'Preview'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const EssayHeader = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const postCardDetails = useSelector(state => state.postcards.postCard)
  const [data, setData] = useState({ content: "", namPostCardHeadere: "" })
  const openedPost = useSelector(state => state.posts.openedPost)
  const { firestore } = store

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    setData(postCardDetails)
  }, [postCardDetails])

  return (
    <>
      <AppBar position="static" style={{ marginTop: '4rem !important', position: 'fixed', marginTop: '1rem', zIndex: 1000 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Stack direction="row" spacing={2} onClick={() => history.push("/essay")}>
                <Button style={{ color: '#fff', fontSize: '17px', fontFamily: "Museo700", }}>
                  <ArrowBackIosNewIcon style={{ color: '#fff', width: '28%', margin: '8px', }} /> Back
                </Button>
              </Stack>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu style={{ display: 'flex', justifyContent: 'end' }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}

              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" >{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              style={{ fontFamily: "Museo700" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              onClick={() => history.push("/poem")}
            >
              Back
            </Typography>
            <Box style={{ borderLeft: '1px solid #FFFFFF', fontFamily: "Museo700", marginLeft: '18px', color: 'red', width: '2%', height: '25px' }}> </Box>
            <Typography
            >
              {
                openedPost && openedPost.title ? openedPost.title : ''
              }
              {/* Post Title Here */}
            </Typography>
            {/* <HiOutlinePencil style={{ margin: '20px' }} /> */}

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  onClick={() => { page == 'Save' ? props.saveEssay() : console.log('not save'); }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Button
                // onClick={handleOpen}
                // className={classes.root1}
                sx={{
                  marginLeft: "10px",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#FFDB00",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                  '&: hover': {
                    backgroundColor: "yellow",
                  },
                }}
                variant="contained"
                onClick={() => props.publishEssay()}
              >
                Publish
              </Button>

              {/* <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <Stack>
                  <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} minwidth="md">
                      <Box style={{ position: "relative" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={2} md={2} lg={2}>
                            <img
                              src={catimg}
                              alt="CatImg"
                              style={{
                                position: "absolute",
                                top: "-70px",
                                left: "-65px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            md={8}
                            lg={8}
                            style={{ textAlign: "center" }}
                          >
                            <h2 style={{ color: "#1f108e" }}>
                              Add Details
                            </h2>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            lg={2}
                            style={{ textAlign: "end" }}
                          >
                            <Button onClick={handleClose}>Close</Button>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box component="form" noValidate autoComplete="off" marginTop="2rem">
                        <Grid container spacing={2} marginBottom="3rem">
                          <Grid item xs={6} md={6} lg={6} style={{ textAlign: 'left', margin: 'auto' }}>
                            <PostcardEmailSelect
                              setUsers={setUsers}
                              users={users}
                              error={error}
                              setError={setError}
                            />
                          </Grid>

                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                          display='contents'
                        >
                          <Grid item style={{ textAlign: 'end' }}>
                            <Button
                              style={{
                                padding: "5px 22px",
                                borderRadius: "2rem",
                                backgroundColor: "#ffc107",
                                border: "none",
                                fontSize: "0.9rem",
                                color: "#1f108e",
                                textAlign: 'end'
                              }}
                              onClick={uploadFile}
                            >
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Box style={{ position: "relative" }}>
                              <img
                                src={cloudImg}
                                alt=""
                                style={{
                                  position: "absolute",
                                  top: "-8px",
                                  left: "-100px",
                                  zIndex: "-1",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item textAlign="end">
                            <Box style={{ position: "relative" }}>
                              <img
                                src={ladyImg}
                                alt=""
                                style={{
                                  position: "absolute",
                                  bottom: "-294px",
                                  right: "-255px",
                                  height: "70vh",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Modal>
                </Stack>
              </Backdrop> */}


              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default EssayHeader;
