import { triggerReducer, actionTypes } from '.';
import firebaseApp, { auth, googleAuthProvider, firebase, db } from '../../config/fbConfig'
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from '../store'
import _ from 'lodash'
import moment from 'moment'

export const setUser = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER, {
            payload
        })
    );
}

export const setInstitute = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_INSTITUTE, {
            payload
        })
    );
}

export const resetPassword = email => dispatch => {
    auth.sendPasswordResetEmail(email).then(function (res) {
        console.log("Res ---", res)
        dispatch(showSuccessSnackbar("Password reset link has been sent to your email."));
    }).catch(function (error) {
        dispatch(showErrorSnackbar("An Error Occured. Please check email address"));
    });
}

export const storeUserDetails = payload => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { uid } = auth
    await db
        .collection("users")
        .doc(uid)
        .update(payload)
        .then((docRef) => {

            dispatch(showSuccessSnackbar("User Saved Successfully"));
        })
        .catch((error) => {

            dispatch(showErrorSnackbar("Error in saving user"));
        });
}

async function addInstituteRoles(tenantId) {
    return new Promise((resolve, reject) => {
        const { firestore } = store
        db.collection('roles').get()
            .then(async (roles) => {
                let rolesData = []
                roles.docs.forEach((role) => {
                    let roleData = role.data()
                    rolesData.push(roleData)
                })
                rolesData = _.orderBy(rolesData, ['role_id'], ['asc'])
                for (let i = 0; i < rolesData.length; i++) {
                    firestore.add({ collection: "instituteRoles" }, { ...rolesData[i], tenantId })
                        .then((resp) => {
                            console.log("response --- ", resp)
                        })
                        .catch((err) => {
                            console.log("error --", err)
                        })
                }
                resolve(true)
            })
            .catch((err) => {
                console.log("Errorr --- ", err)
                resolve(false)
            })
    })

}
async function addInstituteInfo(institute, uid) {
    return new Promise((resolve, reject) => {
        const { firestore } = store
        let createdAt = firebase.firestore.Timestamp.fromDate(new Date())
        let createdBy = uid
        firestore.add({ collection: "institutes" }, { ...institute, createdAt, createdBy })
            .then((resp) => {
                let data = {
                    ...institute, createdAt, createdBy,
                    id: resp.id
                }
                if (resp && resp.id) {
                    resolve(data)
                }

                resolve(false)
            })
            .catch((err) => {
                console.log("error --", err)
                resolve(false)
            })
    })
}

async function addUserInfo(institute, tenantId, uid) {
    return new Promise(async (resolve, reject) => {
        let { name, email, password } = institute

        let createdAt = firebase.firestore.Timestamp.fromDate(new Date())
        let data = {
            userCode: '001',
            name,
            displayName: name,
            email,
            password,
            access: 'super_admin',
            tenantId,
            createdAt,
            address: '',
            avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/quillatwill-int-uat.appspot.com/o/profile%2F2.jpg?alt=media&token=eb87e6b9-e1a7-4f77-b737-c376af41f15c',
            designation: '',
        }
        await db.collection('users').doc(uid).set({ ...data, isSuperAdmin: true, isStudent: false, })
            .then((resp) => {
                if (resp && resp.id) {
                    let payload = {
                        ...data, isSuperAdmin: true, isStudent: false
                    }
                    resolve(payload)
                }
                resolve(false)

            })
            .catch((err) => {
                console.log("error --", err)
                resolve(false)
            })
    })
}
export const instituteSignup = () => (dispatch, getState) => {
    const { institute } = getState().user;
    const { firestore } = store;
    try {
        auth.createUserWithEmailAndPassword(institute.email, institute.password).then(async (res) => {
            if (res.code) {
                throw res.message;
            } else {
                const { auth } = getState().firebase;
                const { uid } = auth
                let instituteId = await addInstituteInfo(institute, uid)
                if (instituteId && instituteId.id) {
                    await addInstituteRoles(instituteId.id)
                    await dispatch(setTenantId(instituteId.id))
                    await dispatch(
                        triggerReducer(actionTypes.SET_INSTITUTE, {
                            payload: instituteId
                        })
                    );
                }
                instituteId = instituteId || ''
                if (uid) {
                    let data = await addUserInfo(institute, instituteId.id, uid)
                    if (data)
                        dispatch(setUser(data))
                }
            }
            dispatch(showSuccessSnackbar("Registered Successfully"));
        }).catch(e => {
            console.log("e -- ", e)
            dispatch(showErrorSnackbar("Something went wrong!!!"));
        });
    }
    catch (e) {
        dispatch(showErrorSnackbar("Unable to Register."));
    }
};

export const setInstituteInfo = payload => (dispatch, getState) => {
    const { institute } = getState().user;

    let data = null
    if (institute) {
        data = {
            ...institute,
            ...payload
        }
    } else {
        data = payload
    }

    dispatch(
        triggerReducer(actionTypes.SET_INSTITUTE, {
            payload: data
        })
    );
}

export const getInstitute = (payload) => async (dispatch, getState) => {
    let institute = await db.collection("institutes")
        .where(firebase.firestore.FieldPath.documentId(), '==', payload)
        .get()
    if (institute && institute.docs && institute.docs.length > 0) {
        let instituteData = institute.docs[0].data()
        instituteData = {
            ...instituteData,
            cPassword: null,
            newPassword: null,
            password: null
        }
        dispatch(
            triggerReducer(actionTypes.SET_INSTITUTE, {
                payload: instituteData
            })
        );
    }
}


async function getUser(email, password) {
    return new Promise((resolve, reject) => {
        db.collection('users')
            .where('email', '==', email)
            .where('password', '==', password)
            .get()
            .then((users) => {
                if (users && users.docs.length > 0) {
                    let userData = {
                        ...users.docs[0].data(),
                        id: users.docs[0].id
                    }
                    resolve(userData)
                }
                resolve(false)
            })
            .catch(err => resolve(false))
    })
}

async function registerUser(userData) {
    return new Promise((resolve, reject) => {
        let user = userData
        try {
            auth.createUserWithEmailAndPassword(user.email, user.password)
                .then((res) => {
                    console.log("Create with emaill password working=--", res)
                    resolve(true)
                }).catch(e => {
                    console.log("Create with emaill password error=---", e)
                    resolve(false)
                });
        }
        catch (e) {
            resolve(false)
        }
    })

}

export const instituteLogin = (payload) => (dispatch, getState) => {
    try {
        firebaseApp.auth()
            .signInWithEmailAndPassword(payload.email, payload.password).then(async res => {
                if (res.code) {
                    throw res.message;
                }
                else {
                    dispatch(showSuccessSnackbar("Logged In Successfully"));
                }
            }).catch(async error => {
                if (error && error.code === 'auth/user-not-found') {
                    let user = await getUser(payload.email, payload.password)
                    if (user) {
                        let userData = user
                        let id = userData.id
                        delete userData.id
                        let status = await registerUser(user)
                        if (status) {
                            const { auth } = getState().firebase;
                            const { uid } = auth
                            try {
                                if (uid) {
                                    await db.collection('users').doc(uid).set({ ...userData, isRegistered: true, id: uid })
                                    await dispatch(deleteUsers([id]))
                                }
                            } catch (error) {
                                console.log("Error----", error)
                            }
                        } else {
                            dispatch(showErrorSnackbar("Something Went Wrong. Please, try again"))
                        }
                    }
                    else
                        dispatch(showErrorSnackbar("Email is not registred. Please, Register!"));
                }
                else if (error.code === 'auth/wrong-password')
                    dispatch(showErrorSnackbar("Invalid Credentials"));
                else
                    dispatch(showErrorSnackbar("Something Went Wrong. Please, try again"))
            });
    }
    catch (e) {

        dispatch(showErrorSnackbar("Something Went Wrong. Please, try again"));
    }
};

export const getUsers = () => (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { tenantId } = getState().user

    if (auth && auth.uid) {
        db.collection("users")
            .where('tenantId', '==', tenantId)
            .orderBy('createdAt', 'desc')
            .get()
            .then(async (res) => {
                const usersData = []
                res.docs.forEach(async (item) => {
                    let user = item.data()
                    user = {
                        ...user,
                        id: item.id,
                    }
                    usersData.push(user)
                })
                console.log("Userdata --- ", usersData)
                dispatch(
                    triggerReducer(actionTypes.GET_USERS, {
                        payload: usersData
                    })
                );
            })
            .catch((err) => {
                console.log("Error fetching ----", err)
            })
    }

}

export const getCoOrdinators = () => (dispatch, getState) => {
    const { auth } = getState().firebase;
    if (auth && auth.uid) {
        const { uid } = auth
        db.collection("users")
            .where('tenantId', '==', uid)
            .where('isStudent', '==', false)
            .get()
            .then(async (res) => {
                const usersData = []
                res.docs.forEach(async (item) => {
                    let user = item.data()
                    user = {
                        ...user,
                        id: item.id,
                    }
                    usersData.push(user)
                })
                dispatch(
                    triggerReducer(actionTypes.GET_CO_ORDINATORS, {
                        payload: usersData
                    })
                );
            })
            .catch((err) => {
                console.log("Error fetching ----", err)
            })
    }

}

export const signupSingleUser = (payload) => (dispatch, getState) => {
    const { firestore } = store;
    const { tenantId } = getState().user;
    const { name } = payload
    let createdAt = firebase.firestore.Timestamp.fromDate(new Date())
    firestore.add({ collection: "users" }, { ...payload, displayName: name, tenantId, createdAt, isRegistered: false, isActive: 'active' })
        .then((resp) => {
            payload.id = resp.id;
            dispatch(getUsers())
        });
}

export const multiUserUpload = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { tenantId } = getState().user;

    if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
            let { name, dateOfBirth, dateOfJoining, email, password, access } = payload[i]
            let createdAt = firebase.firestore.Timestamp.fromDate(new Date())
            password = password || "Quill@Will2023"

            if (email)
                email = email.toLowerCase()

            if (dateOfBirth)
                dateOfBirth = firebase.firestore.Timestamp.fromDate(dateOfBirth)

            if (dateOfJoining)
                dateOfJoining = firebase.firestore.Timestamp.fromDate(dateOfJoining)
            if (access) {
                access = access.toLowerCase()
                if (access.includes('ordinator')) {
                    access = 'co_ordinator'
                }
            }
            await firestore.add({ collection: "users" },
                {
                    ...payload[i], access, displayName: name, tenantId, password,
                    createdAt, dateOfBirth, dateOfJoining, isRegistered: false, isActive: 'active'
                })
                .then((resp) => {
                    console.log("Respos === ", resp)
                    payload.id = resp.id;
                });
        }
    }
    await dispatch(getUsers())
}

export const logout = () => (dispatch, getState) => {
    let userPermissions = {
        complaint_portal: false,
        assign_roles: false,
        override_permissions: false,
        reviewer_access: false,
        user_leaderboard: false,
        help_center_user: false,
        help_center_admin: false,
        reviewer_insights: false,
        user_insights: false,
        announcements: false,
        post_activity_insights: false,
        user_actions: false,
    }

    firebaseApp.auth().signOut();
    dispatch(
        triggerReducer(actionTypes.SET_USER, {
            payload: null
        })
    );

    dispatch(
        triggerReducer(actionTypes.SET_INSTITUTE, {
            payload: null
        })
    );

    dispatch(
        triggerReducer(actionTypes.SET_TENANT_ID, {
            payload: null
        })
    );

    dispatch(
        triggerReducer(actionTypes.SET_USER_PERMISSIONS, {
            payload: userPermissions
        })
    )
}


export const deleteUsers = (payload) => async (dispatch, getState) => {
    for (let i = 0; i < payload.length; i++) {
        let id = payload[i]
        await db.collection('users')
            .doc(id).delete()
            .then((res) => console.log("deleted --- ", res))
            .catch((err) => console.log("Error -- ", err))
    }
    dispatch(getUsers())
    dispatch(showSuccessSnackbar("User deleted Successfully!"));
}


export const archiveUsers = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const { uid } = auth
    for (let i = 0; i < payload.length; i++) {
        let id = payload[i]
        await db.collection('users')
            .doc(id).update({ isActive: 'archive' })
            .then((res) => console.log("archived --- ", res))
            .catch((err) => console.log("Error -- ", err))
    }
    dispatch(getUsers())
    dispatch(showSuccessSnackbar("User archived Successfully!"));
}

export const activeUsers = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const { uid } = auth
    for (let i = 0; i < payload.length; i++) {
        let id = payload[i]
        await db.collection('users')
            .doc(id).update({ isActive: 'active' })
            .then((res) => console.log("active --- ", res))
            .catch((err) => console.log("Error -- ", err))
    }
    dispatch(getUsers())
    dispatch(showSuccessSnackbar("Users activated Successfully!"));
}

export const updateUser = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const { uid } = auth
    let id = payload.id
    delete payload.id
    await firestore
        .collection('users')
        .doc(id)
        .update(payload)
        .then(res => {
            dispatch(getUsers())
            dispatch(showSuccessSnackbar("User Updated Successfully"));
        }).catch((error) => {
            console.log("Error : ", error)
        });
}

export const updateUserRole = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    const { auth } = getState().firebase;
    const { uid } = auth
    for (let i = 0; i < payload.userIds.length; i++) {
        let id = payload.userIds[i]
        await db.collection('users')
            .doc(id).update({ access: payload.role })
            .then((res) => console.log("deleted --- ", res))
            .catch((err) => console.log("Error -- ", err))
    }
    dispatch(showSuccessSnackbar("User/s role updated Successfully!"));
}

export const setTenantId = (tenantId) => (dispatch, getState) => {
    const { auth } = getState().firebase;
    let isInstitute = auth?.uid === tenantId
    dispatch(
        triggerReducer(actionTypes.SET_IS_INSTITUTE, {
            payload: isInstitute
        })
    )

    dispatch(
        triggerReducer(actionTypes.SET_TENANT_ID, {
            payload: tenantId
        })
    )
}

export const setUserAvatar = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER_AVATAR, {
            payload
        })
    );
}

export const setActivePage = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_ACTIVE_PAGE_INDEX, {
            payload
        })
    );
}

export const changeUserPassword = (password) => (dispatch, getState) => {
    var user = firebase.auth().currentUser
    user.updatePassword(password).then((response) => {
        console.log("Response --- ", response)
    }).catch((error) => {
        console.log("Error --- ", error)
    });
}

