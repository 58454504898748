import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import eye from "../../../assets/images/Blog/BD/a;hi.png";
import comment from "../../../assets/images/Blog/BD/agrg.png";
import share from "../../../assets/images/Blog/BD/agr.png";
import hands from "../../../assets/images/Blog/BD/Group 63.png";
import logoImages from "../../../assets/images/logo1.png";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PoemEditorStyles from "../PoemEditorStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFirestoreConnect } from "react-redux-firebase";
import { deletePoem, getUserPoems, setShowPoemIndex, setShowPoems } from "../../../store/actions/poems";



export default function AppPublishData(props) {
  const classes = PoemEditorStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { firestore } = useSelector((state) => state);
  const { userPublishedPoems } = useSelector((state) => state.poems)
  const { user } = useSelector((state) => state.user)
  const auth = useSelector(state => state.firebase.auth);
  const [id, setId] = useState([]);

  useEffect(() => {
    try {
      dispatch(getUserPoems(true))
    } catch (error) {
      console.log("error --- ", error)
    }
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (e, post) => {
    e.stopPropagation();
    setId(post.id)
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(deletePoem(id));
    dispatch(getUserPoems(true))
    setOpen(false);
  }
  const onClickNo = () => {
    setOpen(false);
  }

  useEffect(() => {
    let userPoems = userPublishedPoems
    for (let i = 0; i < userPoems.length; i++) {
      userPoems[i] = {
        ...userPoems[i],
        user
      }
    }
    dispatch(setShowPoems(userPoems))
  }, [userPublishedPoems])

  const handlePoemClick = (index) => {
    dispatch(setShowPoemIndex(index))
    history.push('/poem-view')
  }
  return (
    <Grid container spacing={2}>


      <Dialog
        open={open}
        keepMounted
        onClose={onClickNo}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Are you sure you want to Delete ?'}</DialogTitle>
        <DialogActions>
          <Button style={{ background: 'rgb(0, 31, 88)', color: '#fff' }} onClick={handleClose}>Yes</Button>
          <Button style={{ background: 'rgb(0, 31, 88)', color: "#fff" }} onClick={onClickNo}>No</Button>
        </DialogActions>
      </Dialog>

      {userPublishedPoems && userPublishedPoems.length > 0
        ? userPublishedPoems.map((post, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4}
            // onClick={() => history.push('/poem/' + post.id + '/' + post.title.trim().replaceAll(' ', '-').replaceAll('---', '-').replaceAll('--', '-').toLowerCase())}
            onClick={() => handlePoemClick(index)}
            style={{ cursor: 'pointer' }}
          >
            <Stack className={classes.responsiveCard}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box style={{ position: "relative" }}>

                    <Box>
                      <DeleteIcon onClick={(e) => handleClickOpen(e, post)}
                        className={classes.deleteStyles}
                      />
                    </Box>
                    <div style={{
                      minHeight: '200px', backgroundColor: 'skyblue', display: 'flex',
                      justifyContent: 'center', alignItems: 'center'
                    }}>
                      {
                        post.images && post.images.length > 0 ?
                          <img
                            src={post.images[0]}
                            //  src={bgImageSrc2}
                            alt="bg" style={{ width: "100%", height: '200px', objectFit: 'cover' }} />
                          :
                          <img
                            src={logoImages}
                            //  src={bgImageSrc2}
                            alt="bg" style={{ width: "50%", height: 'auto', margin: 'auto' }} />

                      }
                    </div>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0",
                        padding: '1rem',
                        width: '78vw',
                        background: 'linear-gradient(rgba(255,0,0,0), #000000)',

                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{ color: "#fff" }}
                      >
                        <Grid item>
                          <img src={eye} alt="" />
                          <span> {post?.views || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={hands} alt="" />
                          <span> {post && post.likedby
                            ? post.likedby.length
                            : 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={comment} alt="" />
                          <span> {post?.comments || 0} </span>
                        </Grid>
                        <Grid item>
                          <img src={share} alt="" />
                          <span> {post?.shared || 0} </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    spacing={2}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <div style={{ height: '100px', width: '17vw', overflow: 'hidden', paddingRight: '10px' }}>
                      <h3 style={{ color: "grey", marginTop: "0px", }}>
                        {post?.title}
                      </h3>

                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "small",
                          color: "grey",
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                        }}
                      // dangerouslySetInnerHTML={{ __html: post?.body }}
                      >
                        {
                          post && post.content ? ReactHtmlParser(post?.content) : null
                        }
                        {/* {
                        post.body ?
                          post.body.length < 100 ?
                            ReactHtmlParser(post?.body) :
                            ReactHtmlParser(post?.body.substring(0, 50)) + '....' : ''
                      } */}
                      </p>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      marginBottom="0"
                    >
                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <img
                          style={{
                            width: "34px",
                            height: "34px",
                            borderRadius: "50%",
                          }}
                          src={user.avatarUrl || auth.photoURL}
                          alt="pc" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={7}>
                        <Stack direction="row" spacing={2}>
                          <b
                            style={{
                              fontWeight: "500",
                              color: "rgb(0, 31, 88)",
                              whiteSpace: 'nowrap !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important',
                            }}
                          >
                            {/* Mitali Singh |{" "} */}
                            {user?.displayName || user?.name} |{" "}
                          </b>
                          {/* <span style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}> {post?.title}</span> */}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <b
                          style={{
                            fontWeight: "500",
                            color: "rgb(0, 31, 88)",
                            textAlign: "right",
                          }}
                        >
                          {post.lastUpdated
                            ? moment
                              .unix(post.lastUpdated.seconds)
                              .format("DD MMM YY")
                            : null}
                        </b>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid >
        )) : null
      }
    </Grid >
  );
}
