import { actionTypes } from '../actions';

export const INITIAL_STATE = {
    searchQuery: "",
    filter: ['All']
};

export const library = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.SET_USER_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: payload,
            };
        case actionTypes.SET_FILTER:
            return {
                ...state,
                filter: payload,
            };
        default:
            return state;
    }
};
