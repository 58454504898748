import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { Grid, Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { EditorState, convertFromRaw } from "draft-js";
import ReactHtmlParser from "react-html-parser";
import CommentsMultiLevelPoem from '../Shared/CommentsMultiLevelPoem'
// '../Shared/CommentsMultiLevelPoem';
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsByPost } from '../../store/actions/comments';
import { convertKMBT } from "../../helpers/Helpers";
import eye from "../../assets/images/Blog/BD/a;hi.png";
import comment from "../../assets/images/Blog/BD/agrg.png";
import share from "../../assets/images/Blog/BD/agr.png";
import hands from "../../assets/images/Blog/BD/Group 63.png";
import { showInfoSnackbar } from "../../store/actions/snackbar";
import EssayViewCardStyles from './EssayViewCardStyles';
import avatarImg from "../../assets/images/Profile/3.jpg";

import Editor, { composeDecorators } from "@draft-js-plugins/editor";

import createImagePlugin from "@draft-js-plugins/image";
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const plugins = [imagePlugin, resizeablePlugin, alignmentPlugin, blockDndPlugin, focusPlugin];

const useStyles = makeStyles((theme) => ({
    content: {
        display: `flex`,
        padding: theme.spacing(0),
    },
    mainContent: {
        flexGrow: 1,
        width: `100%`,
        overflowY: `scroll`,
        overflowX: `hidden`,
        paddingRight: theme.spacing(0),
        "&::-webkit-scrollbar": {
            width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
        marginLeft: theme.spacing(3),
    },
    adSection: {
        // width: '285px',
        // overflow: `hidden`,
        "&.preview": {
            height: `calc(100vh - 100px)`,
        },
    },
    blogAuthor: {
        width: `220px`,
        textAlign: `center`,
        paddingTop: theme.spacing(10),
        overflow: `hidden`,
        "&.preview": {
            height: `calc(100vh - 100px)`,
        },
    },
    imageGrid: {
        flexGrow: 1,
        overflow: `hidden`,
    },
    imageGridItem: {
        padding: 0,
    },
    image: {
        height: `auto`,
        objectFit: `cover`,
        maxWidth: `100%`,
    },
    tags: {
        display: `flex`,
        color: theme.palette.secondary.alt,
        justifyContent: `flex-end`,
        fontStyle: `italic`,
    },
    actionIcons: {
        display: `flex`,
        justifyContent: `flex-end`,
    },
    viewIcon: {
        color: `lightgreen`,
    },
    favIcon: {
        color: `red`,
    },
    shareIcon: {
        color: `green`,
    },
    commentIcon: {
        color: theme.palette.primary.main,
    },
    likeIcon: {
        color: theme.palette.primary.main,
    },
    blogIcon: {
        display: `flex`,
        flexDirection: `column`,
        textAlign: `center`,
        marginRight: 5,
    },
}));

const EssayViewCard = (props) => {
    const classes = useStyles();
    const classes1 = EssayViewCardStyles();
    const dispatch = useDispatch()
    const firestore = useFirestore();
    const auth = useSelector((state) => state.firebase.auth);

    const [essay, setEssay] = useState(null)
    const [isCommentOpen, setCommentOpen] = useState(false);
    const [likedButtonStatus, setLikedButtonStatus] = useState(false);
    const [shareButtonStatus, setShareButtonStatus] = useState(false);
    const [commentButtonStatus, setCommentButtonStatus] = useState(false);

    useEffect(() => {
        if (auth && auth.uid) {
            let viewscount = props?.essay?.views ? props.essay.views + 1 : 1;
            if (props?.essay?.id)
                firestore.collection("essay").doc(props.essay.id).update({
                    views: viewscount,
                });
        }
    }, []);

    useEffect(() => {
        if (props?.essay?.id)
            dispatch(getCommentsByPost(props.essay.id))
    }, [])

    useEffect(() => {
        setEssay(props.essay)
    }, [props.essay])

    const convertPostDataToHtml = () => {
        const state = convertFromRaw(JSON.parse(essay.body))
        let editorStateData = EditorState.createWithContent(state);
        return <Editor
            editorState={editorStateData}
            plugins={plugins}
            readOnly={true}
        />
    }

    const handleLikedClick = () => {
        let likedArray = essay?.likedby?.length > 0 ? [...essay.likedby] : [];
        if (likedArray.indexOf(auth.uid) === -1) {
            likedArray.push(auth.uid);
            firestore.collection("essays").doc(essay.id).update({
                likedby: likedArray,
            });
            let essayData = {
                ...essay,
                likedby: likedArray
            }
            setEssay(essayData)
            setLikedButtonStatus(true);
        } else {
            dispatch(showInfoSnackbar("You had already liked this post!"));
        }
    };

    const handleSharedClick = () => {
        let sharecount = essay?.shared ? essay?.shared + 1 : 1;
        const el = document.createElement("input");
        el.value = process.env.REACT_APP_DOMAIN_URL + window.location.pathname;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        firestore.collection("essays").doc(essay.id).update({
            shared: sharecount,
        });
        dispatch(showInfoSnackbar("This post is copied to share!"));
    };
    const showActions = () => (
        <React.Fragment style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Box className={classes.blogIcon}>
                <IconButton style={{ background: "lightgreen" }}>
                    <img src={eye} alt="" style={{ width: "16px", height: "16px" }} />
                    {/* <VisibilityIcon style={{ color: 'lightgreen' }}>visibility</VisibilityIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(essay?.views || 0)}
                </Typography>
            </Box>
            <Box className={classes.blogIcon} onClick={handleLikedClick}>
                <IconButton
                    style={{ background: likedButtonStatus ? "blue" : "#b8b3b3" }}
                >
                    <img
                        src={hands}
                        alt=""
                        style={{ width: "16px", height: "16px", textAlign: "center" }}
                    />
                    {/* <ThumbUpOffAltIcon style={{ color: 'theme.palette.primary.main' }}>thumb_up_off_alt</ThumbUpOffAltIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(essay?.likedby ? essay?.likedby?.length : 0)}
                </Typography>
            </Box>
            {/* <Box className={classes.blogIcon} onClick={handleLovedClick}>
            <IconButton className={classes.favIcon}>
              <FavoriteIcon style={{ color: 'red' }}>favorite</FavoriteIcon>
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              {convertKMBT(poem.lovedby ? poem.lovedby.length : "[]")}
            </Typography>
          </Box> */}
            <Box className={classes.blogIcon} onClick={handleSharedClick}>
                <IconButton
                    style={{ background: shareButtonStatus ? "green" : "#b8b3b3" }}>
                    <img src={share} alt="" style={{ width: "16px", height: "16px" }} />
                    {/* <ShareIcon style={{ color: 'green' }}>share</ShareIcon> */}
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(essay?.shared ? essay?.shared : 0)}
                </Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <IconButton
                    style={{ background: commentButtonStatus ? "blue" : "#b8b3b3" }}
                >
                    <img src={comment} alt="" style={{ width: "16px", height: "16px" }} />
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                    {convertKMBT(essay?.commentscount ? essay?.commentscount : 0)}
                </Typography>
            </Box>
        </React.Fragment>
    );


    const getName = (user) => {
        let name = ''
        if (user) {
            if (user.hasOwnProperty('displayName')) {
                name = user.displayName
            }
            if (user.hasOwnProperty('name')) {
                let temp = user.name
                user = temp.length > name.length ? temp : name
            }
            if (user.hasOwnProperty('firstName')) {
                let temp = user.firstName + ' ' + user?.lastName
                user = temp.length > name.length ? temp : name
            }
        }
        return name;
    }

    return (
        // essay &&
        <>

            <Grid container
                style={{
                    display: 'flex', justifyContent: "center", marginTop: '1rem',
                    borderRadius: '5px', boxShadow: "1px 1px 1px 1px lightgray", border: '1px solid lightgray',
                }}>
                <Grid container item xs={12} sm={12} md={12} lg={12} style={{
                    marginTop: '-3rem', paddingTop: '80px', display: 'flex', justifyContent: 'center'
                }}>
                    <Grid container itex xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={4} md={2} lg={2} sm={2}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: red[600], height: '80px', width: '80px' }} aria-label="recipe">
                                            {/* <img src={avatarImg} alt="User" style={{ height: "80px", width: '80px' }} /> */}
                                            <img src={essay?.user?.avatarUrl} alt="User" style={{ height: "80px", width: '80px' }} />
                                        </Avatar>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={10} lg={10} sm={10}>
                                <Box style={{ textAlign: 'left', marginTop: '0.7rem' }}>
                                    <Typography variant="h5" style={{ fontFamily: 'Museo700' }}>{getName(essay?.user)}</Typography>
                                    <Typography variant="h6" style={{ fontFamily: 'Museo300' }}>{`Poem: ${essay?.category}`}</Typography>
                                    <Typography variant="h6" style={{ fontFamily: 'Museo300' }}>
                                        {
                                            essay?.lastUpdated
                                                ? moment
                                                    .unix(essay?.lastUpdated?.seconds)
                                                    .format("MMMM D, YYYY")
                                                : null
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ fontFamily: 'Museo700' }}>{essay?.title}</Typography>
                    <Grid
                        container item xs={12}
                        style={{
                            height: 'auto',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <Grid
                            container item xs={12}
                            style={{
                                opacity: 1,
                                display: 'flex',
                                textAlign: 'center',
                                margin: 'auto',
                                justifyContent: 'center',
                                paddingTop: '1rem',
                            }}
                        >

                            <Typography className={classes1.overlappingTestStyle} style={{ overflowY: "auto", maxHeight: '90vh' }}>
                                {/* hello gaddhe
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.v
                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.


                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                <br />
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                What is Lorem Ipsum?
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                */}
                                {

                                    essay && essay.body ?
                                        convertPostDataToHtml()
                                        :
                                        essay && essay?.content ? ReactHtmlParser(essay?.content) : ""
                                }
                            </Typography>
                        </Grid>
                        {/* <img style={{ opacity: '0.3', width: '100%', height: 'auto' }} src={PoemBackground} alt={"poem_image"} /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                {
                    showActions()
                }
            </Grid>

            {/* <Grid container style={{ display: 'flex', justifyContent: 'left', marginTop: 10 }}>
                <CommentsMultiLevelPoem post={poem} />
            </Grid> */}

        </>
    );
}
export default EssayViewCard;
