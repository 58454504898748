import { Box, Button, Grid, Typography } from "@mui/material";
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getWeek,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import boyImg from "../../assets/images/DiaryImages/shutterstock_2121447629.png";
import DiaryMoodSection from "./DiaryMoodSection";
import DiaryStyles from "./DiaryStyles";
import { useSelector } from "react-redux";

function DiaryDate(props) {
  const classes = DiaryStyles();
  const history = useHistory();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  // current day and dates
  const dateFormat = "dd";
  const MonthFormat = "MMMM";
  const WeekName = "cccc";
  const feellingColor = useSelector((state) => state.diary.diaryColor);
  const [opacityValue, setOpacityValue] = useState(0.7);
  const [color, setColor] = useState("");
  console.log(color, "DairyDate");
  // console.log(feellingColor,'shakiri')
  return (
    <>
      {/* <div className={classes.mainCardUp}> */}
      <Grid container>
        <div className={classes.mainCardUp}>
          <Grid item>
            <DiaryMoodSection
              setCalendarColor={props.setCalendarColor}
              setUpdateEmojiImg={props.setUpdateEmojiImg}
              setMoodValue={props.setMoodValue}
              moodValue={props.moodValue}
              setFeelings={props.setFeelings}
              setColor={setColor}
            />
          </Grid>
          {/* shakir */}
          <Box
            className={classes.cardRes}
            sx={{
              position: "relative",

              backgroundColor: `${color || "#ECECED"}${Math.round(
                opacityValue * 255
              ).toString(16)}`,
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="#1E3A71"
              sx={{
                width: "fit-content",
                height: "20px",
                fontSize: "16px",
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "19px",
              }}
            >
              Today | {format(new Date(), WeekName)}
            </Typography>
            <Typography
              sx={{
                width: "50px",
                height: "65px",
                fontSize: "52px",
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "65px",
                textAlign: "center",
                color: "#1E3A71",
                marginTop: "-0.5rem",
              }}
            >
              {/* {format(addDays(startDate), )} */}
              {format(new Date(), dateFormat)}
            </Typography>
            <Typography
              sx={{
                width: "82px",
                height: "20px",
                fontSize: "16px",
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "19px",
                textAlign: "center",
                color: "#1E3A71",
                marginTop: "-0.6rem",
                textAlign: "first",
                paddingLeft: "4px",
              }}
            >
              {format(currentMonth, MonthFormat)}
              {/* February */}
            </Typography>
            <button
              className={classes.cardResButton}
              style={{
                // width: "192px",
                // height: "48px",
                backgroundColor: "#FFD814",
                borderRadius: "30px",
                textAlign: "center",
                border: "none",
                marginTop: "2rem",
              }}
              onClick={() => history.push("/diary-editor")}
            >
              <Typography
                variant="p"
                component="div"
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "#242424",
                }}
              >
                Start writing
              </Typography>
            </button>
            <Grid container sx={{ position: "relative" }}>
              <img className={classes.cardResImg} src={boyImg} alt="img" />
            </Grid>
          </Box>
        </div>
      </Grid>
    </>
  );
}

export default DiaryDate;