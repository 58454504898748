import { Typography, Grid, Box, table } from "@mui/material";
import React, { } from "react";
import CookiesPolicyStyles from "./CookiesPolicyStyles.js";
import '../../../App.css';

const CookiesPolicy = props => {
  const classes = CookiesPolicyStyles();


  return (
    <div className={classes.bg} style={{ backgroundColor: '#fff' }}>
      <div className={classes.root} >
        <Grid container spacing={2}>
          <Box>
            <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Cookies Policy
            </Typography>
            <br />
            <Typography component="p" >
              This page explains everything you need to know about "Cookies" and how Cookies are used on Quill at will. On this page, “you” or “your” refers to the user and "we" or "us" refers to Quill at Will.
              What are Cookies?
              <br />
              All websites use Cookies. Cookies are small files put on your computer that contain certain information about you. They do not contain any sensitive information about you, like your name or address.
              But it can allow us to distinguish you from other users on the site. Cookies cannot harm your computer in any way.


            </Typography>
            <br /><br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              What do Cookies do?
            </Typography>

            <Typography component="p">
              {' '}
              <a href="https://quillatwill.com/"> Quillatwill.com </a>
              {' '} uses Cookies to improve its website's features and services, so you can enjoy the site more. One way we use Cookies is to remember useful stuff about your member's profile, such as to obtain certain badges. We use different types of Cookies on our site. These are described below.

            </Typography>
            <br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Types of Cookies

            </Typography>
            <br />
            <Typography component="p">
              <b>"Analytical" Cookies: </b> These help us to count the number of visitors on our site and how they move around the website. And which pages or actions are used the most.
            </Typography>
            <br />
            <Typography component="p">
              <b>"Session" Cookies:  </b> These Cookies help us to see when you are logged into your account and what activities you are completing on the website.

            </Typography>
            <br />
            <Typography component="p">
              <b>"Persistent" Cookies: </b>These Cookies are stored between your visits to our site. These allow us to remember your preferences or actions when you log back into the site.

            </Typography>
            <br />
            <Typography component="p">
              <b>“Targeting” Cookies: </b>These cookies are used to deliver adverts more relevant to your and your interests. They collect information about your browsing habits and the websites you visit.

            </Typography>
            <br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              How to block Cookies?
            </Typography>
            <Typography component="p">
              If you want to block Cookies, you can check the settings in your browser. And this will let you block either all or certain types of Cookies. It is very important to note, that if you do block Cookies this will mean that you might not be able to access all areas of our site and all features.
            </Typography>
            <br />

            <Typography component="p">
              For registration and such other purposes, we may collect information from the user. Use such information for providing User a better user experience and services as per their preferences. In order to improve the responsiveness of the website, electronic tools such as cookies, etc. may be used and user IP Address may be tracked.
            </Typography>
            <br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Sharing your personal information
            </Typography>

            <Typography component="p">
              User agrees that his/her information may be used by <a href="https://quillatwill.com/"> Quillatwill.com </a> for providing services to the user. Users always have the option of not providing the information which is not mandatory.VOTECHAIN PRIVATE LIMITED may contact the user by using his/her information to provide information regarding the product and services of user interest. By accepting this Privacy Policy, User expressly agrees to receive this information. If the user does not wish to receive these communications, he/she may opt-out by unsubscribing. You may make changes to your profile at any time.
            </Typography>
            <br />

            <Typography component="p">
              <ul>
                <li>
                  <b>Internet Explorer</b>
                </li>
                <Typography>
                  {' '}
                  <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-Cookies" > https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-Cookies </a>
                  {' '}
                </Typography>

                <li>
                  <b>Google Chrome	</b>
                </li>
                <Typography>
                  {' '}
                  <a href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647&p=cpn_Cookies" > https://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647&p=cpn_Cookies </a>
                  {' '}
                </Typography>
                <li>
                  <b>Mozilla Firefox </b>
                </li>
                <Typography>
                  {' '}
                  <a href="http://support.mozilla.org/en-US/kb/Blocking%20Cookies" > http://support.mozilla.org/en-US/kb/Blocking%20Cookies </a>
                  {' '}
                </Typography>
                <li>
                  <b>Apple Safari</b>
                </li>
                <Typography>
                  {' '}
                  <a href="https://support.apple.com/kb/ph21411?locale=en_US" > https://support.apple.com/kb/ph21411?locale=en_US </a>
                  {' '}
                </Typography>
                <li>
                  <b>Apple handheld devices</b>
                </li>
                <Typography>
                  {' '}
                  <a href="https://support.apple.com/en-us/HT201265" > https://support.apple.com/en-us/HT201265 </a>
                  {' '}
                </Typography>
              </ul>
            </Typography>
            <br />
            {/* <table style="width:100%">
                <tr>
                  <th>Firstname</th>
                  <th>Lastname</th>
                </tr>
                <tr>
                  <td>Jill</td>
                  <td>Smith</td>
                </tr>
              </table> */}
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Cookies used on <a href="https://quillatwill.com/"> quillatwill.com </a>
            </Typography>
            <Typography component="p">
              Below is a list of Cookies we use:
            </Typography>
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Strictly Necessary Cookies
            </Typography>
            <Typography component="p">
              These Cookies are essential in order to enable you to move around the website and use its features.
            </Typography>
            <br />

            <table className="table">
              <tr className="th">
                <th className="th">Cookie Name</th>
                <th className="th">Cookie Overview</th>
              </tr>
              <tr className="td">
                <td className="td">martys_vault XSRF-TOKEN</td>
                <td className="td">These are unique security tokens, used to prevent and block unknown user requests to the site, also used to validate the current user once logged in.

                </td>

              </tr>
              <tr className="td">
                <td className="td">marty_seen</td>
                <td className="td">This cookie is used to track if a member has logged into the site.</td>

              </tr>
              <tr className="td">
                <td className="td">cookie_policy_new_feature_popup cookie_policy_sigup_popup</td>
                <td className="td">This cookie is used to track if the user has seen the popup and has hidden it.</td>

              </tr>
              <tr className="td">
                <td className="td">cookie_policy cookie_policy_setting</td>
                <td className="td">These cookies are used to track if a user has agreed with our cookie policy and stores the user's cookie settings.</td>

              </tr>
              <tr className="td">
                <td className="td">remember_web_*</td>
                <td className="td">This cookie is used to keep the member logged in.</td>

              </tr>
              <tr className="td">
                <td className="td">tz</td>
                <td className="td">This cookie stores your local timezone.</td>

              </tr>
            </table>

            <br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Performance Cookies
            </Typography>
            <Typography component="p">
              These Cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular, see how visitors move around the site, and provide a better user experience in the future. All information these Cookies aggregated
              and therefore anonymous. If you do not allow these Cookies we will not know when you have visited our site, and will not be able to monitor its performance.

            </Typography>
            <br />

            <table className="table">
              <tr className="th">
                <th className="th">Cookie Name</th>
                <th className="th">Cookie Overview</th>
              </tr>
              <tr className="td">
                <td className="td">_ga _gid</td>
                <td className="td">Google Analytics cookie is used to distinguish users when collecting information about page visits. This cookie helps us to identify website areas to be improved and these cookies do not allow Quill at Will to identify a user personally as no personal information or data is tracked.

                </td>

              </tr>
              <tr className="td">
                <td className="td">_gat</td>
                <td className="td">Google Analytics cookie is used by Google to monitor request rate toward their servers.</td>

              </tr>
              <tr className="td">
                <td className="td">__gads</td>
                <td className="td">This cookie is set by Google and stored under the name doubleclick.net and under QuillatWill.com. This cookie is used to track how many times users see a particular advert which helps in measuring the success of the campaign and calculate the revenue generated by the campaign. These cookies can only be read from the domain that it is set on so it will not track any data while browsing through other sites.
                </td>

              </tr>
              <tr className="td">
                <td className="td">fr<br />
                  sb <br />
                  datr <br />
                  reg_fb_ref <br />
                  reg_ext_ref <br />
                  reg_fb_gate
                </td>
                <td className="td">This Cookie is placed by Facebook. It enables us to measure, optimise and build audiences for advertising campaigns served on Facebook.
                </td>

              </tr>


            </table>

            <br />
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              User Engagement Cookies
            </Typography>
            <br />
            <Typography component="p">

              These Cookies are used to engage users with third party services like AddThis and Disqus.

            </Typography>
            <br />
            <table className="table">
              <tr className="th">
                <th className="th">Cookie Name</th>
                <th className="th">Cookie Overview</th>
              </tr>
              <tr className="td">
                <td className="td">__atuvc <br />
                  __atuvs <br />
                  uid <br />
                  uvc <br />
                  mus <br />
                  cw_id
                </td>
                <td className="td">These Cookies are created and used by AddThis social sharing addon,
                  in order to make sure users see the updated count if the user shares a page.


                </td>

              </tr>
              <tr className="td">
                <td className="td">disqus_unique <br />
                  __jid <br />
                  __utmb <br />
                  __utmc <br />
                  __utma <br />
                  __utmz <br />
                  __utmt
                </td>
                <td className="td">These Cookies are created and used by Disqus social commenting system.
                </td>

              </tr>
            </table>

            <br />
            <br />
          </Box>
        </Grid>

      </div>
    </div>
  );
};

export default CookiesPolicy;
