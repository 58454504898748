import { Button, Card, Chip, FormControl, Grid, ListItem, OutlinedInput } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Main from '../Main'
import Navbar from '../Navbar'
import TopBar from '../TopBar'
import { makeStyles } from "@mui/styles";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MainStyles from '../MainStyles'
import SearchIcon from '@mui/icons-material/Search';
import { ComplaintBox } from './ComplaintBox'
import { useDispatch, useSelector } from 'react-redux'
import { getComplaintsByInstitute } from '../../store/actions/complaints'
import { getUsers } from '../../store/actions/user'
import ComplaintFilter from './ComplaintFilter'
import Data from './constant'
import _ from 'lodash'

export const ComplaintsPortal = () => {
    const classes1 = MainStyles()
    const dispatch = useDispatch()
    const {users,tenantId } = useSelector(state => state.user)
    const {complaints} = useSelector(state=>state.complaints)
    const [complaintList,setComplaintList] = useState([])
    const [search,setSearch]=useState("")
    const [openFilterBox,setOpenFilterBox] = useState(false)
    const [data,setData] = useState(Data)
 
    const statusFilterStyle = {padding:'10px 15px',cursor:'pointer',height:'50px'}
    const statusFilterActiveStyle = {fontWeight:'700',backgroundColor:'#E6FBFB',...statusFilterStyle}
    const TopBarStyles = makeStyles((theme) => ({
        mainGrid: {
            '& .css-1cndkqf-MuiToolbar-root': {
                minHeight: '40px',
                height: '70px'
            }
        },
    }));
    const classes = TopBarStyles()
    const styles = {
        backgroundColor: '#fff',
        backgroundImage: 'none',
        paddingBottom: 50
    }

    const getDay = (createdAt)=>{
        let now = new Date()
        now = Math.floor(now.getTime()/1000);
        return Math.floor((now-createdAt)/(86400))
    }
    useEffect(() => {
        if (tenantId)
        dispatch(getUsers())
        dispatch(getComplaintsByInstitute())
    }, [tenantId])
    useEffect(() => {
        if(complaints.length && users.length){
            for(let i = 0;i < complaints.length;i++){
                for(let j = 0;j < users.length;j++){
                    if(users[j].id == complaints[i].userId){
                        complaints[i].displayName = users[j].displayName
                        complaints[i].email = users[j].email
                    }
                }
            }
            setComplaintList([...complaints])
        }
    }, [complaints,users])
    useEffect(()=>{
        mainFilter()
    },[data,complaints])
    // console.log(complaintList)
    const sort_by_time = (filtering,by)=>{
        if(by === 1){
            for(let i = 0;i < filtering.length;i++){
                let smallest = i;
                for(let j = i;j < filtering.length;j++){
                    if(filtering[smallest].createdAt.seconds > filtering[j].createdAt.seconds){
                        smallest = j;
                    }
                }
                let temp = filtering[smallest];
                filtering[smallest] = filtering[i]
                filtering[i] = temp; 
            }
        }
        else if(by === 0){
            for(let i = 0;i < filtering.length;i++){
                let largest = i;
                for(let j = i;j < filtering.length;j++){
                    if(filtering[largest].createdAt.seconds < filtering[j].createdAt.seconds){
                        largest = j;
                    }
                }
                let temp = filtering[largest];
                filtering[largest] = filtering[i]
                filtering[i] = temp; 
            }
        }
        return filtering
    }
    const sort_by_type=(filtering,type)=>{
        let s = 0
        let e = filtering.length-1
        while(s < e){
            while(filtering[s].complaintCategory == type && s < e){
                s++;
            }
            while(filtering[e].complaintCategory != type && s < e){
                e--;
            }
            if(s < e){
                let temp = filtering[s]
                filtering[s] = filtering[e]
                filtering[e] = temp
                s++;
                e--;
            }
        }
        return filtering
    }
    const mainFilter =()=>{
        let statusFlag = false
        for(let i = 0; i < Object.values(data).length && !statusFlag; i++){
            for(let j = 0;j < Object.values(data)[i].length;j++){
                if(Object.values(data)[i][j].status){
                    statusFlag = true
                    break
                }
            }
        }
        if(statusFlag){
            let filtering = complaints
            let sortByIndex = -1;
            for (let i = 0; i < Object.values(data).length; i++) {
                let tempans = []
                let ans = []
                let isExist = false
                for(let j = 0;j < Object.values(data)[i].length;j++){
                    if(Object.values(data)[i][j].status){
                        isExist = true
                        switch (i) {
                            case 0:
                                ans = filtering.filter(c=>getDay(c.createdAt.seconds) <= Object.values(data)[i][j].value)
                                break
                            case 1:
                                if(!j){
                                    ans = filtering
                                }
                                else if(j === 3){
                                    ans = filtering.filter(c=>(c.status === 'Closed' && c.complaintCategory === 'Post Report'))
                                }
                                else{
                                    ans = filtering.filter(c=>c.status == Object.values(data)[i][j].value)
                                }
                                break
                            case 2:
                                ans = filtering.filter(c=>c.complaintCategory == Object.values(data)[i][j].value)
                                break
                            case 3:
                                ans = filtering
                                sortByIndex = j
                                break
                            case 4:
                                ans = filtering.filter(c=>c.complaintCategory?.toLowerCase().includes(search.toLowerCase()) || c.status?.toLowerCase().includes(search.toLowerCase()) ||c.title?.toLowerCase().includes(search.toLowerCase()) || c.discription?.toLowerCase().includes(search.toLowerCase()) || c.displayName.toLowerCase().includes(search.toLowerCase()) ||c.email.toLowerCase().includes(search.toLowerCase()))
                                break
                        }
                    }
                    if(ans.length){
                        tempans = [...tempans,...ans]
                    }
                }
                if(isExist){
                    filtering = _.uniqBy(tempans,'id')
                }
            }
            if(sortByIndex != -1){
                switch (sortByIndex) {
                    case 0:
                        filtering = sort_by_time(filtering,0)
                        break;
                    case 1:
                        filtering = sort_by_time(filtering,1)
                        break
                    default:
                        filtering = sort_by_type(filtering,Object.values(data)[3][sortByIndex].value)
                        break;
                }
            }   
            setComplaintList([...filtering])
        }
        else{
            setComplaintList([...complaints])
        }
    }
    const handleSearch =()=>{
        setData(prev=>{
            prev['search'][0].status=search.length?true:false
            prev['search'][0].value = search
            return {...prev}
        })
    }
    const removeChip = (item,index)=>{
        console.log(item,index)
        setData(prev=>{
            prev[item][index].status=false
            return{...prev}
        })
    }
    const setStatusFilter =(v)=>{
        setData(prev=>{
            for(let i = 0;i < prev['status'].length;i++){
                prev['status'][i].status = (i == v);
            }
            return {...prev}
        })
    } 
  return (
    <Main styles = {styles}>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                <Navbar />
            </Grid>
            <Grid container item xs={12} md={12} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={12} style={{
                    backgroundColor: "#1d396f",
                    backgroundImage: 'linear-gradient(to right, darkblue, aqua)'
                }}
                    className={classes.mainGrid}
                >
                    <TopBar page={3} />
                </Grid>
            </Grid>
            <Grid display={'flex'} marginTop='40px'>
                <Grid width={'250px'} display='flex' flexDirection='column' marginLeft='150px' marginRight='90px'>
                    <Grid>
                        <Button onClick={()=>{setOpenFilterBox(true)}} style = {{border:'1px solid #cbcbcb', width:'94px',borderRadius:'20px',color:'#4315A4'}}>
                            <FilterAltIcon /><span>Filter</span>
                        </Button>
                    </Grid>
                    <Grid marginTop={'20px'}>
                        <Card style={{ backgroundColor: '#f5f5f5', height: '30vh', textAlign: 'center', overflowY: 'auto'}}>
                            {
                                Object.keys(data).map((item)=> data[item].map((element,index)=>element.status &&
                                    <ListItem 
                                    key={item.name}
                                    style={{ textAlign: 'center', margin: 'auto', float: 'center' }}>
                                        <Chip
                                            sx={{ borderRadius: 0 }}
                                            label={item =='search'?item.charAt(0).toUpperCase()+item.slice(1)+' : '+element.value:item.charAt(0).toUpperCase()+item.slice(1)+' : '+element.name}
                                            onDelete = {()=>removeChip(item,index)}
                                        />
                                    </ListItem>
                                    )
                                )
                            }
                        </Card>
                    </Grid>
                    <Grid style = {{backgroundColor:'#EDF0F2',color:'#1E3A71',fontSize:'15px',marginTop:'20px',borderRadius:'10px'}}>
                        <Grid onClick = {()=>{setStatusFilter(0)}} style ={data["status"][0].status?statusFilterActiveStyle:statusFilterStyle}>All</Grid>
                        <Grid onClick = {()=>{setStatusFilter(1)}} style ={data["status"][1].status?statusFilterActiveStyle:statusFilterStyle}>Open</Grid>
                        <Grid onClick = {()=>{setStatusFilter(2)}} style ={data["status"][2].status?statusFilterActiveStyle:statusFilterStyle}>Closed</Grid>
                        <Grid onClick = {()=>{setStatusFilter(3)}} style ={data["status"][3].status?statusFilterActiveStyle:statusFilterStyle}>Archived</Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid display='flex' alignItems='center' item>
                        <Grid>
                            <FormControl variant="outlined" style={{ marginRight: 20 }}>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    startAdornment={<SearchIcon />}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    placeholder="Search Users"
                                    size="medium"
                                    className={classes1.searchboxStyle}
                                    value ={search}
                                    onChange={(e)=>{setSearch(e.target.value)}}
                                    />

                            </FormControl>
                        </Grid>
                        <Grid>
                            <Button style ={{backgroundColor:'white',borderRadius:'20px',border:'1px solid #cbcbcb',color:'#4315A4',}}variant="contained" onClick={handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        complaintList.length? 
                        complaintList.map((element)=>{
                            return <ComplaintBox key = {element.id} complaint = {element}/>
                        }):<Grid>No Report/Complaints Till Now</Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
        <ComplaintFilter complaintList = {complaintList} setComplaintList ={setComplaintList} complaints= {complaints} open={openFilterBox} setOpen={setOpenFilterBox} data = {data} setData = {setData}/>
    </Main>
  )
}
