import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	openedJournal: {
		journalId: null,
		page: null,
		events: null,
		gratefulfor: null,
		on: null,
		userid: null
	},
	savedJournal: {
		journalId: null,
		page: null,
		events: null,
		gratefulfor: null,
		on: null,
		userid: null
	},
	journalId: null
};

export const journal = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.SET_OPENED_JOURNAL:
			return {
				...state,
				openedJournal: payload,
			};
		case actionTypes.SET_GET_JOURNAL_PAGE:
			return {
				...state,
				openedJournal: payload,
			};
		case actionTypes.SET_ADD_JOURNAL_PAGE:
			return {
				...state,
				openedJournal: payload,
			};
		case actionTypes.SET_SAVED_JOURNAL:
			return {
				...state,
				savedJournal: payload,
			};
			case actionTypes.SET_JOURNAL_ID:
				return {
					...state,
					journalId: payload,
				};
		default:
			return state;
	}
};
