import React, { useEffect, useState } from "react";
// component from library
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
// custom component
// material
import { Grid } from "@mui/material";
import publish from "../../../assets/images/dashboard/published.png";
import draft from "../../../assets/images/dashboard/Group 516.png";
import lock from "../../../assets/images/dashboard/Group 513.png";
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import store from "../../../store/store";
import { getUserPostcardCount } from "../../../store/actions/postCards";

const AppMyRoom = (props) => {
  const [allPosts, setAllPosts] = useState([]);

  const { firestore } = store;
  const dispatch = useDispatch();

  const { sentCount, receivedCount, draftCount } = useSelector(state => state.postcards);

  const auth = useSelector((state) => state.firebase.auth);
  useFirestoreConnect([{ collection: "posts" }, { collection: "essay" }]);

  const { posts } = useSelector((state) => state.firestore.ordered);

  useEffect(() => {
    if (posts && posts.length > 0 && posts !== undefined) {
      setAllPosts(posts);
    }
  }, [posts]);

  const publishedBlogs = allPosts.filter(
    (post) => post.isPublished && post.userId === auth.uid
  ).length;
  const inProgressBlogs = allPosts.filter(
    (post) => !post.isPublished && post.userId === auth.uid
  ).length;

  useEffect(() => {
    dispatch(getUserPostcardCount())
  }, [])

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      style={{
        borderRadius: "1rem",
        backgroundColor: "#EDF0F2",
        padding: "1rem",
        marginTop: "1rem",
      }}
    >
      <h3 style={{ color: "#001f58" }}>My Room</h3>
      <Grid container direction='column'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 style={{ color: "grey" }}>Blogs</h3>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={publish} alt='publish' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Published</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691'
                }}
              >
                {publishedBlogs}
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt='publish' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Drafts</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691',
                  marginBottom: "1rem",
                }}
              >
                {inProgressBlogs}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          variant='middle'
          style={{ fontSize: "1rem", marginTop: "1rem", marginBottom: "1rem" }}
        />
        {/* <Grid container direction="column"> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 style={{ color: 'grey' }}>Postcard</h3>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={publish} alt="publish" />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              {/* <p style={{ color: '#f5819e', fontFamily: 'Museo700' }}>Sent</p> */}
              <p>Sent</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p style={{ fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691' }}>{sentCount ? sentCount : 0}</p>
            </Grid>
          </Grid>

          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt="publish" style={{ color: 'red' }} />
            </Grid>


            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Received</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691',
                  marginBottom: '1rem'
                }}
              >
                {receivedCount ? receivedCount : 0}
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt="publish" />
            </Grid>


            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Drafts</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontFamily: 'Museo700', fontSize: '1rem', color: '#4d3691',
                  marginBottom: '1rem'
                }}
              >
                {draftCount ? draftCount : 0}

              </p>
            </Grid>
          </Grid>
        </Grid>
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 style={{ color: "grey" }}>Diary</h3>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={lock} alt='publish' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Locked</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p style={{ fontWeight: "500", fontSize: "1rem", color: "red" }}>
                12
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={lock} alt='publish' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Open</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "1rem",
                  color: "red",
                  marginBottom: "1rem",
                }}
              >
                2
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          variant='middle'
          style={{ fontSize: "1rem", marginTop: "1rem", marginBottom: "1rem" }}
        />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 style={{ color: "grey" }}>Book</h3>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={publish} alt='publish' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Publish</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p style={{ fontWeight: "500", fontSize: "1rem", color: "red" }}>
                12
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img src={draft} alt='drafts' />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <p>Drafts</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "1rem",
                  color: "red",
                  marginBottom: "1rem",
                }}
              >
                2
              </p>
            </Grid>
          </Grid>
          <Stack direction='column' justifyContent='center' alignItems='center'>
            <CustomButton buttonText='Show all' />
          </Stack>
        </Grid> */}
      </Grid>
    </Stack>
  );
};

export default AppMyRoom;
