import React, { useEffect, useState } from 'react';
import { Grid, Stack, Box, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Logout from "../../../assets/images/Profile/_icons.png";
import badge1 from "../../../assets/images/Profile/badge1.svg";
import badge2 from "../../../assets/images/Profile/badge2.svg";
import badge3 from "../../../assets/images/Profile/badge3.svg";
import badge4 from "../../../assets/images/Profile/badge4.svg";
import { useFirestoreConnect } from 'react-redux-firebase';
import rookieUnlocked from "../../../assets/images/Profile/rookieUnlocked.png";
import nerdUnlocked from "../../../assets/images/Profile/nerdUnlocked.png";
import scholarUnlocked from "../../../assets/images/Profile/scholarUnlocked.png";

import store from "../../../store/store";
import { logout } from "../../../store/actions/user";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../store/actions/snackbar";
import { getUserLogs } from "../../../store/actions/rewards"
const userAvatarUrlInit = {
  avatarUrl: ''
};
const userDisplayNameInit = {
  displayName: 'Display Name',
};
const userTagLineInit = {
  tagLine: 'About'
};
const AppLeftGrid = (props) => {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({})
  const [userdisplayname, setUserDisplayName] = useState(userDisplayNameInit);
  const [usertagline, setUserTagLine] = useState(userTagLineInit);
  const [useravatarurl, setUserAvatarUrl] = useState(userAvatarUrlInit);
  const { firestore } = useSelector(state => state);
  const { userAvatar, tenantId } = useSelector((state) => state.user)
  const auth = useSelector(state => state.firebase.auth);
  const { userLogs } = useSelector(state => state.rewards)
  const { users, institutes } = firestore.ordered;

  useFirestoreConnect([
    { collection: 'users', doc: auth.uid }
  ]);

  useFirestoreConnect([
    { collection: 'institutes', doc: auth.uid }
  ]);

  useEffect(() => {
    if (userLogs && userLogs.userId === null) {
      dispatch(getUserLogs())
    }
  }, [])

  const onDisplayNameChange = event => {
    const { name, value } = event.target;
    setUserDisplayName({ ...userdisplayname, [name]: value });
  };
  const onTagLineChange = event => {
    const { name, value } = event.target;
    if (value.length > 50) {
      dispatch(showErrorSnackbar("Limit is 50 characters !!!"));
      return
    }
    else {
      setUserTagLine({ ...usertagline, [name]: value });
    }
  };

  const setInstitutesValues = (currentUser) => {
    setUserDisplayName({ ...userdisplayname, ...currentUser, });
    setUserAvatarUrl({ ...useravatarurl, ...currentUser, });
    setUserTagLine({ ...usertagline, ...currentUser, });
  }

  const setUserValues = (currentUser) => {
    setUserDisplayName({ ...userdisplayname, ...currentUser, });
    setUserAvatarUrl({ ...useravatarurl, ...currentUser, });
    setUserTagLine({ ...usertagline, ...currentUser, });
  }

  useEffect(() => {
  }, [useravatarurl])
  useEffect(() => {
    if (institutes && institutes.length > 0) {
      setInstitutesValues(institutes[0])
    } else if (users && users.length > 0) {
      setUserValues(users[0])
    }
  }, [users, institutes]);

  const onLogout = () => {
    dispatch(logout());
  };

  const onDisplayNameSubmit = event => {
    const userObject = {
      ...userdisplayname
    };
    if (institutes && institutes.length > 0) {
      store.firestore.collection('institutes').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
        dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
      });
    } else {
      store.firestore.collection('users').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
        dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
      });
    }
  };

  const onTagLineSubmit = event => {
    const userObject = {
      ...usertagline

    };
    
    if (institutes && institutes.length > 0) {
      store.firestore.collection('institutes').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
        dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
      });
    } else {
      store.firestore.collection('users').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
        dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
      });
    }
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}
      style={{
        height: '75vh',
        border: "none",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        padding: "1rem",
        boxShadow: "black 1px 1px 4px",
        paddingTop: '0px'
      }}
    >
      <Grid container style={{ justifyContent: "center", }}>
        <Grid item xs={6} md={6} lg={6} style={{ position: "relative" }}>
          <div style={{
            height: "100px", width: "100px", borderRadius: "50px",
            backgroundImage: `url(${userAvatar ? userAvatar : useravatarurl.avatarUrl})`,
            position: 'relative', backgroundSize: "cover", backgroundRepeat: "no-repeat",
            backgroundPosition: "center", display: "block", marginLeft: "auto",
            marginRight: "auto", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField variant="standard" InputProps={{ disableUnderline: true, }} sx={{ input: { color: '#1f108e', fontSize: "20px", textAlign: "center" } }} type="text " name="displayName" value={userdisplayname.displayName.toString()} onChange={onDisplayNameChange} onBlur={onDisplayNameSubmit} />
          <Box style={{ textAlign: "center" }}>
            <p style={{ color: "grey", padding: "5px" }}>
              <TextField variant="standard" placeholder="Write about yourself.." multiline maxRows={3} InputProps={{ disableUnderline: true, }} sx={{ input: { textAlign: "center", } }} type="textarea" name="tagLine" value={usertagline.tagLine.toString()} onChange={onTagLineChange} onBlur={onTagLineSubmit} />
            </p>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <h4 style={{ color: "#1f108e", marginTop: "1rem" }}>Badges Found</h4>
          <Grid container style={{ paddingLeft: "0rem", paddingRight: "0rem" }}>
            <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: "#1f108e" }}>Newbie</span>
              <img src={badge1} alt="" style={{ width: "50%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

              <span style={{ fontSize: "10px" }}>Unlocked</span>
            </Grid>
            <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: userLogs?.totalQuinns >= 20000 ? "#1f108e" : "grey" }}>Geek</span>
              <img
                src={userLogs?.totalQuinns >= 20000 ? rookieUnlocked : badge2}
                alt=""
                style={{ width: userLogs?.totalQuinns >= 20000 ? "70%" : "50%", display: "block", marginLeft: "auto", marginRight: "auto" }}
              />
              <span style={{ fontSize: "10px" }}>
                {
                  userLogs?.totalQuinns >= 20000 ? "Unlocked" : "locked"
                }
              </span>
            </Grid>
            <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: userLogs?.totalQuinns >= 40000 ? "#1f108e" : "grey" }}>Scholar</span>
              <img
                src={userLogs?.totalQuinns >= 40000 ? nerdUnlocked : badge3}
                alt=""
                style={{ width: userLogs?.totalQuinns >= 40000 ? "70%" : "50%", display: "block", marginLeft: "auto", marginRight: "auto" }}
              />
              <span style={{ fontSize: "10px" }}>
                {
                  userLogs?.totalQuinns >= 40000 ? "Unlocked" : "locked"
                }
              </span>
            </Grid>
            <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: userLogs?.totalQuinns >= 60000 ? "#1f108e" : "grey" }}>Pro</span>
              {/* <img src={badge4} alt="" style={{ width: "50%", display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
              <img
                src={userLogs?.totalQuinns >= 60000 ? scholarUnlocked : badge4}
                alt=""
                style={{ width: userLogs?.totalQuinns >= 60000 ? "70%" : "50%", display: "block", marginLeft: "auto", marginRight: "auto" }}
              />
              <span style={{ fontSize: "10px" }}>
                {
                  userLogs?.totalQuinns >= 60000 ? "Unlocked" : "locked"
                }
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} md={10} lg={10} style={{ marginTop: "2rem", textAlign: "center" }}>
          <img src={Logout} alt="" />
          <Button variant="text" onClick={onLogout}>Logout</Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AppLeftGrid;
