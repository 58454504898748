import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Stack, TextField, OutlinedInput, InputLabel, FormControl, IconButton } from "@mui/material";
import { ListItem, ListItemText } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';
import DashboardStyles from '../DashboardStyles';
//Icon
import DeleteIcon from "@mui/icons-material/Delete";

//Redux
import {
  getTodos,
  saveTodos,
  updateTodo,
  deleteTodo,
} from "../../../store/actions/todos";
import { useSelector, useDispatch } from "react-redux";


//Main Function
function NewAppToDo(props) {
  //Dispatch
  const dispatch = useDispatch();
  const classes = DashboardStyles();
  const getTodoList = useSelector((state) => state.todos.todos);
  const [input, setInput] = useState("");

  const styles ={
    labelRoot:{
      fontSize:10,
    },
    labelFocused:{}
  }

  useEffect(() => {
    dispatch(getTodos());
  }, []);

  //Add -Update Todo
  const addTodo = (e) => {
    // console.log("e", e);
    if (input == "") {
      return null
    }
    else {
      let todoList = {
        name: input,
      };

      if (getTodoList.todos?.length == undefined) {
        dispatch(saveTodos(todoList));
        setInput("");
        dispatch(getTodos());
      }
      else {
        dispatch(updateTodo(todoList));
        //dispatch(saveTodos(todoList));
        setInput("");
        dispatch(getTodos());

      }
      // if (getTodoList.todos?.length !== 0 ) {
      //  console.log("----update todo ", todoList);
      //   dispatch(updateTodo(todoList));
      //  //dispatch(saveTodos(todoList));
      //   setInput("");
      //   dispatch(getTodos());
      // } else {
      //   console.log("----addd todo ", todoList);
      //   dispatch(saveTodos(todoList));

      //   setInput("");
      //   dispatch(getTodos());
      // }
    }

  };

  const removeIndex = (index) => {
    var values = getTodoList.todos.filter((item) => {
      return item.name !== index;
    });
    dispatch(deleteTodo(values));
    dispatch(getTodos());
  };

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        borderRadius: "1rem",
        backgroundColor: "#EDF0F2",
        padding: "1rem",
        display: "block",
       maxHeight:'72vh',
       minHeight:'72vh',
        overflow: "auto",
      }}
    >
      <Grid>
        <Typography
          gutterBottom
          variant="h5"
          textAlign="center"
          fontFamily="Museo700"
          color="#0B2F64"
        >
          To Do
          {/* <RiDeleteBinLine
                        style={{
                            marginLeft: "20px",
                        }}
                    // onClick={handleDeleteTodo}
                    /> */}
        </Typography>
        <Box>
          <form
          // onSubmit={handleSubmit}
          >
             <FormControl  variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Add New ToDo</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            label="Add New ToDo"
            variant="outlined"
            style={{ margin: "2px 3px" }}
            size="small"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            // onBlur={addTodo}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                addTodo()
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={addTodo}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                <AddCircleIcon  style={{cursor:'pointer',color:'#103d7e'}} />

                  {/* {values.showPassword ? <VisibilityOff /> : <Visibility />} */}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

            {/* <TextField
              type="text"
              id="outlined-basic"
              label="Add New ToDo"
              variant="outlined"
              style={{ margin: "2px 3px" }}
              size="small"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              // onBlur={addTodo}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  addTodo()
                }
              }}
              InputLabelProps={{classes:{root:styles.labelRoot,
              focused:styles.labelFocused
              }}}

              InputProps={{
                endAdornment: <InputAdornment position="end" style={{marginRight:'-11px!important'}}>
                <AddCircleIcon  style={{cursor:'pointer',color:'#103d7e'}} onClick={addTodo}/>
                  
              </InputAdornment>,
              }}
            /> */}
          </form>
          {getTodoList.todos?.map((item) => (
            <ListItem
              key={item}
              secondaryAction={
                <DeleteIcon
                  fontSize="small"
                  style={{ opacity: 0.7,cursor:'pointer' }}
                  onClick={() => {
                    //  deleteDoc(doc(db, "todos", arr.id));
                    removeIndex(item.name);
                  }}
                />
              }
            >
              <ListItemText
                style={{ fontSize: "15px", paddingRight: "10px" }}
                id={item.name}
                primary={item.name}
              />
            </ListItem>
          ))}
        </Box>
      </Grid>
    </Stack>
  );
}

export default NewAppToDo;
