import React from "react";
import Navbar from "../Navbar";
import Main from "../Main";
import {
  Grid,
  Container,
} from "@mui/material";
import AppLeftGrid from "./profileSections/AppLeftGrid";
import AppRightGrid from "./profileSections/AppRightGrid";
import AppCenterGrid from "./profileSections/AppCentergrid";
import AppBottomGrid from "./profileSections/AppBottomGrid";
const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
}
const Profile = (props) => {
  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid container item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Container
            maxWidth="xl"
            style={{
              // paddingTop: "5.3rem",
              paddingLeft: "2.3rem",
              paddingRight: "2.3rem",
              paddingBottom: '3rem',
              backgroundColor: "rgb(221 221 221 / 18%)",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={1}>

              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={3}>
                <AppLeftGrid />
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={7}>
                <AppCenterGrid />
              </Grid>
              {/* <Grid item sm={12} xs={12} md={6} lg={2}>
                <AppRightGrid />
              </Grid> */}
              {/* <Grid item sm={12} xs={12} md={12} lg={12}>
                <h3 style={{ color: "#1f108e" }}>Interest</h3>
                <AppBottomGrid />
              </Grid> */}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Main>

  );
}

export default Profile;