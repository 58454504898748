import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
  Grid
} from "@mui/material";
import DrawerComp from "./DrawerComp";
import HeaderStyle from "./HeaderStyle";
import logo from "../../assets/images/logo1.png";

const linkArray = ["/sign-up", "/login"];
const Header = (props) => {
  const history = useHistory();
  const classes = HeaderStyle();

  const [value, setValue] = useState("0");

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [showMenus, setShowMenus] = useState(false);

  const changePage = (event, value) => {
    setValue(value);
    switch (parseInt(value)) {
      case 0:
        history.push("/");
        break;
      case 1:
        history.push("/about");
        break;
      case 2:
        history.push("/contact");
        break;
      case 3:
        history.push("/hub/articles");
        break;
      default:
        history.push("/");
        break;
    }
  };

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    if (linkArray.includes(pathname)) {
      setShowMenus(false);
    } else {
      setShowMenus(true);
    }
  }, [history]);

  return (
    <React.Fragment>
      <AppBar
        className={classes.navbar}
        sx={{
          boxShadow: "none",
          fontFamily: "Museo300",
        }}
      >
        <Toolbar>
          <img
            src={logo}
            alt="logo"
            sx={{ transform: "scale(2)" }}
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          />
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              {showMenus && (
                <Grid container item md={4}
                  sx={{ marginLeft: "2rem", fontFamily: "Museo700" }}
                  value={value}
                  onChange={(e, value) => changePage(e, value)}
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <NavLink style={{
                    color: "#fff", fontFamily: "Museo300", margin: 'auto'
                  }}
                    to="/"
                    activeClassName={value === 0 ? "navbar__link--active" : null}
                    className="navbar__link"
                  >
                    Home
                  </NavLink>
                  <NavLink style={{
                    color: "#fff", fontFamily: "Museo300", margin: 'auto'
                  }}
                    to="/about"
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                  >
                    About Us
                  </NavLink>
                  <NavLink style={{
                    color: "#fff", fontFamily: "Museo300", margin: 'auto'
                  }}
                    to="/contact"
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                  >
                    Contact Us
                  </NavLink>
                  <NavLink style={{
                    color: "#fff", fontFamily: "Museo300", margin: 'auto'
                  }}
                    to="/hub/articles"
                    activeClassName="navbar__link--active"
                    className="navbar__link"

                  >
                    Hub
                  </NavLink>
                </Grid>
              )}
              <div>
              </div>
              <Button
                className={classes.root}
                sx={{
                  marginLeft: "auto",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#ffff",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                }}
                variant="contained"
                onClick={() => history.push("/")}
              >
                Login
              </Button>
              <Button
                className={classes.root1}
                sx={{
                  marginLeft: "10px",
                  borderRadius: "1.5rem",
                  textTransform: "capitalize",
                  padding: "2px 32px",
                  backgroundColor: "#FFDB00",
                  color: "#0B2F64",
                  fontFamily: "Museo700",
                }}
                variant="contained"
                onClick={() => history.push("/register")}
              >
                SignUp
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
