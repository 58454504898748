import React, { useState, useEffect } from 'react';
import { Grid, Box, Stack, TextField, ListItem, ListItemText, } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GoalsNew from './GoalsNew';
import DiaryStyles from '../../Diary/DiaryStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector, useDispatch } from "react-redux";
import { firebase } from '../../../config/fbConfig';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';

import {
    addAspirations,
    getAspiration,
    saveAspirations,
    updateAspirations
} from '../../../store/actions/aspirations'

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { showErrorSnackbar } from '../../../store/actions/snackbar';


function NewAppGoalComp() {
    const classes = DiaryStyles();
    const dispatch = useDispatch();
    const { goals, openedGoal } = useSelector((state) => state.goals);
    const auth = useSelector((state) => state.firebase.auth);
    const { savedAspirations } = useSelector((state) => state.aspirations)
    const [input, setInput] = useState('');
    const [aspirations, setAspirations] = useState([])


    let date = new Date()
    const addGoal = (e) => {
        if (input && input.length > 0 && input.trim().length > 0) {
            let date = new Date()
            // e.preventDefault();
            let payload = {
                goal: input.trim(),
                target: date,
                added: date,
                status: 0
            }
            if (savedAspirations.id) {
                dispatch(saveAspirations(payload))
            } else { dispatch(addAspirations(payload)) }
        }
        setInput('')
    };

    useEffect(() => {
        dispatch(getAspiration())
    }, [])

    useEffect(() => {
        const { aspirations } = savedAspirations
        if (aspirations && aspirations.length > 0) {
            setAspirations(aspirations)
        }
    }, [savedAspirations])

    const deleteItem = (index) => {
        const asData = [...aspirations]
        asData.splice(index, 1)
        setAspirations(asData.reverse())
        dispatch(updateAspirations(asData, savedAspirations))
    }

    const updateDate = (value, index) => {
        let asData = [...aspirations]
        asData[index].target = firebase.firestore.Timestamp.fromDate(new Date(moment(value).format('YYYY-MM-DD')))
        setAspirations(asData.reverse())
        dispatch(updateAspirations(asData, savedAspirations))
    }

    const handelInput = event => {
        const { name, value } = event.target;
        if (value.length > 20) {
            dispatch(showErrorSnackbar("Limit is 20 characters !!!"));
            return
        }
        else {
            setInput(value);
        }
    };

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            style={{
                borderRadius: '1rem',
                // backgroundColor: '#EDF0F2',
                padding: '1rem',
                marginTop: '1rem',
                display: 'block'
            }}
        >
            <Grid >
                <Box>
                    <form style={{ marginTop: '-2rem' }}>
                        {/* <TextField variant="standard" placeholder="Write about yourself.." multiline maxRows={3} InputProps={{ disableUnderline: true, }} sx={{ input: { textAlign: "center", } }} type="textarea" name="tagLine" 

               /> */}
                        <TextField
                            type="text" label="Add New Goal"
                            variant="outlined"
                            style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}
                            size="small" value={input}
                            // onChange={e => setInput(e.target.value)}
                            onChange={e => handelInput(e)}
                            // onBlur={addGoal}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    addGoal()
                                }
                            }}

                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    <AddCircleIcon style={{ cursor: 'pointer', color: '#103d7e' }} onClick={addGoal} />

                                </InputAdornment>,
                            }}
                        />
                    </form>
                    {/* {goals.map(item => <GoalsNew key={item.id} arr={item} date={item.date} />)} */}

                    {
                        aspirations.map((a, index) =>
                            <ListItem className={classes.deleteStyle}
                                key={index}
                                secondaryAction={
                                    <DeleteIcon fontSize="small" style={{ opacity: 0.7, fontSize: "24px", cursor: 'pointer' }} onClick={() => { deleteItem(index) }} />
                                }
                            >
                                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={12} sm={8} md={5} >
                                        <ListItemText style={{
                                            fontSize: "15px", marginTop: "0.8rem"
                                            //  whiteSpace: 'nowrap !important',
                                            // overflow: 'hidden !important',
                                            // textOverflow: 'ellipsis !important', width: '8vw', height: '4vh', overflow: "hidden"
                                        }} id={index} primary={a.goal} />

                                    </Grid>
                                    <Grid item xs={12} sm={4} md={7} >
                                        {/* <Stack
                                            width="100%"
                                            direction="row"
                                            justifyContent="space-between"
                                        > */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                inputFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                // className={classes.datePickerStyle}
                                                value={a.target.toDate().toDateString()}
                                                onChange={(newValue) => {
                                                    updateDate(newValue, index)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField   {...params}
                                                        className={classes.datePickerStyle}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {/* </Stack> */}
                                    </Grid>
                                </Grid>

                            </ListItem>
                        )
                    }
                </Box>
            </Grid>
        </Stack>
    );
}

export default NewAppGoalComp;
