import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, TextField } from "@mui/material";

import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function BlogMultiSelect(props) {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [open, setOpen] = useState(false);
  // const [users, setUsers] = useState([{ email: 'pradeep.bande01@gmail.com', phone: 7620069935, username: 'Pradeep' }]);
  const [users, setUsers] = useState([]);
  const [all_users, setAllUsers] = useState([]);
  const [share_users, setShareUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permission_id, setPermissionId] = useState(2);
  const [selectState, setSelectState] = useState({});
  const [change_users, setChangeUsers] = useState(true);
  const [document_name, setDocumentName] = useState("");
  const [value, setValue] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [severity, setSeverity] = useState("success");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleShareInputChange = (event, newValue) => {
    let tempData = [];
    for (let i = 0; i < newValue.length; i++) {
      let n = newValue[i];
      if (n.includes('Add "')) {
        n = n.substring(5, n.length - 1).toLowerCase()
        tempData.push(n);
      } else {
        tempData.push(n);
      }
    }
    let filterData = _.uniqBy(tempData, function (e) {
      return e;
    });
    if (filterData.length < tempData.length) {
      setMessage("No duplicate email allowed.");
      setIsAlert(true);
    }
    for (let i = 0; i < filterData.length; i++) {
      let user = filterData[i];
      if (user && user.length > 0) {
        let value =
          user && user.length > 0 ? user.toLowerCase() : user;
        let index = _.findIndex(share_users, (s) => s.user === value);
        if (index > -1) {
          filterData.splice(index, 1);
          i--;
          setSnackbarOpen(true);
        }
      }
    }
    setValue(filterData);
    props.setTag(filterData);
  };

  useEffect(() => {
    setUsers(props.tags)
  }, [props.tags])

  return (
    <>
      <Autocomplete
        size="small"
        value={value}
        multiple
        id="tags-outlined"
        options={users.length > 0 ? users : []}
        getOptionLabel={(option) =>
          option ? option : "no email"
        }
        filterSelectedOptions
        filterOptions={(options, params) => {
          setIsAlert(false);
          let user = params.inputValue;
          if (params.inputValue !== "") {
            user = user.toLowerCase();
          }
          const filtered = _.filter(options, (option) =>
            JSON.stringify(option).includes(user)
          );
          // Suggest the creation of a new value
          // if (params.inputValue !== '' && isNaN(params.inputValue))
          if (params.inputValue !== "") {
            filtered.push(`Add "${params.inputValue}"`);
          }
          return filtered;
        }}
        onChange={(event, newValue) => handleShareInputChange(event, newValue)}
        renderInput={(params) => (
          <TextField
            size="small"
            label="Add Tags"
            style={{ maxHeight: "100px" }}
            {...params}
            variant="outlined"
            placeholder={
              value && value.length > 0
                ? ""
                : "enter tags "
            }
            error={props.error}
          />
        )}
      />
      <span style={{ color: "red", fontSize: "11px" }}>
        {props.error ? "Please enter any tag" : ""}
      </span>
    </>
  );
}
