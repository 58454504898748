import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { db } from "../../config/fbConfig";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

export const getTodos = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const { auth } = getState().firebase;
    // console.log("auth.uid",auth.uid);
    await db
      .collection("todos")
      .doc(auth.uid)
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const TempTodoData =
            documentSnapshot.data().todos == undefined
              ? "empty"
              : documentSnapshot.data().todos.reverse();
          //  console.log("documentSnapshot.data()",documentSnapshot.data().todos);
          let data = {
            id: documentSnapshot.data().userid,
            todos: documentSnapshot.data().todos.reverse(),
          };
          //   console.log("data=====>",data)
          dispatch(
            triggerReducer(actionTypes.GET_TODOS, {
              payload: data,
            })
          );
          resolve(data);
        }
      })
      .catch((error) => {});
  });
};

export const saveTodos = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  await db
    .collection("todos")
    .doc(auth.uid)
    .set({
      todos: [payload],
      userid: auth.uid,
    })
    .then((resp) => {
      // getTodos()
      dispatch(showSuccessSnackbar("ToDo added Successfully!"));
      // getTodos()
    });
};

export const updateTodo = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  console.log("payload", payload);
  const todosRef = doc(db, "todos", auth.uid);
  await updateDoc(todosRef, {
    todos: arrayUnion(payload),
  });
};

export const deleteTodo = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  console.log("payload=====>", payload);
  await db
    .collection("todos")
    .doc(auth.uid)
    .update({ todos: payload, userid: auth.uid })
    .then(() => {
      dispatch(showSuccessSnackbar("Todo deleted Successfully!"));
    })
    .catch((error) => {
      dispatch(showErrorSnackbar("Todo delete failed!!!"));
    });
};
