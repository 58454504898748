import { makeStyles } from "@mui/styles";
import { GoNoNewline } from "react-icons/go";

const PoemViewCardStyles = makeStyles((theme) => ({
  imageOpacity: {
    '& img': {
      opacity: 0.1
    }
  },
  mainGridPoem: {
    position: 'absolute',
    opacity: 1
  },

  poemviewSty: {
    display: 'flex  !important', justifyContent: "center !important",
    borderRadius: '2px  !important', boxShadow: "1px 1px 1px 1px lightgray  !important", border: '1px solid lightgray !important',
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem  !important",
      display: 'flex  !important', justifyContent: "center  !important",
      borderRadius: '2px  !important', boxShadow: "1px 1px 1px 1px lightgray  !important", border: '1px solid lightgray !important',
    }
  },

  overlappingTestStyle: {
    width: '100%',
    overflowX: 'auto !important', maxHeight: '1050px', padding: "20px !important",
    [theme.breakpoints.down("md")]: {
      overflowX: 'auto !important', maxHeight: '600px !important', padding: "20px !important",
    },
    [theme.breakpoints.down("sm")]: {
      overflowX: 'auto !important', maxHeight: '300px !important', padding: "20px !important",
    }
  },
}));

export default PoemViewCardStyles;
