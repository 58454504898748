import React from "react";
import { Box, Grid, Container, Typography, Divider } from "@mui/material";
import SecretDiaryImg from '../../../assets/images/DiaryImages/SecretDiary.jpeg';

import { Helmet } from "react-helmet";
import DiaryblogsStyles from "../BlogsPages/DiaryblogsStyles";
import Footer from "../../Footer";

function SecretDiary() {
    const classes = DiaryblogsStyles();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Secret Diary | Quill at Will | Get Started For Free</title>
                <meta
                    name="title"
                    content="Secret Diary | Quill at Will | Get Started For Free"
                />
                <meta
                    name="description"
                    content="Write your deepest feeling, thoughts & daily experience in your secure online secret diary, access it from anywhere & anytime and Get lots of additional features & benefits."
                />
                <meta
                    name="keywords"
                    content="Blog Writing Platform"
                />
            </Helmet>
            <Grid container style={{
                display: 'flex', justifyContent: "center", marginTop: '6rem', marginBottom: "3rem"
            }}>
                <Grid item xs={12} sm={12} md={10} lg={10} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                    >
                        Secret Diary
                    </Typography>
                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <img src={SecretDiaryImg} alt="" style={{
                                    width: "100%",
                                    height: "60vh",
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        Our experiences are varied and most exciting as the world is a colorful canvas etched with booming life. There are days which are more exciting than others and also times which are gloomy. We share our experiences with friends or family but we all have things that we wish to keep to ourselves. This should not be a burden to us. There are thoughts that sprout often and also those emotions that trouble us. A “Secret Diary Online” is a diary hidden from everyone. Where all our secrets lay in rest, where we can say anything without being judged, where we can share our thoughts that can be recalled..
                                        <br />
                                        <br />
                                        Since a secret diary online is private and contains our deepest secrets, it needs to be protected. Physical diaries are some of the most cherished possessions of people who love writing but it lacks security. This is why QuillAtWill has a password that keeps all your secrets safe in the online secret diary.

                                    </Typography>
                                    <Typography
                                        className={classes.headingTypo}
                                        style={{ fontSize: "30px" }}
                                    >
                                        QuillAtWill - Your secret Diary Writing best friend
                                    </Typography>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        With Quill at Will’s digital secret diary online, you get to safe-keep all your secrets. Protected by a password, this diary becomes your best friend to journal your aspirations, goals and also the things you want to do in the week ahead. The Diary with a record of things to do and goals also functions as a reminder for you. Capturing memories and reliving them has become possible with a few clicks. Writing about the special events, fun times or even things you are grateful for is a great way to relive and enjoy the good times. Retrieving the diary logs and other info is very easy with the calendar feature on it. Writing about worries and hard times also lightens up your burden. This diary also supports uploading a picture from your device apart from adding images from the in-house gallery. The e-diary stays with you wherever you go without adding to the weight of baggage.
                                        <br />
                                        <br />
                                        Operable through our secret diary app on mobile and desktops, diary writing online will be the stress buster that allows you to let your emotions out in a safe space.
                                        <br />
                                        <br />
                                        This gets even better with the app’s Habit tracker! With this feature, you can track any habit that you wish to inculcate or persist with. For example, you could track your water intake or eating habits or even count down to a goal of resisting chocolate for a month.

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    Your aid to mindfulness - Secret Diary Online
                                </Typography>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    Secret diary writing is said to improve your mental state as you pen your thoughts. When you get negative thoughts, writing them down eases your mind and lets those thoughts out. When you write down positive thoughts, it manifests in your life, in your day and in your behaviour. This is why experts suggest journaling as a way to mindfulness. With QuillAtWill’s secret diary online, you bring harmony to your mind and soul. You can also record your mood on the mood board everyday and track your mood pattern.
                                    <br />
                                    <br />
                                    This secret diary writing will be your inspiration and guide to a healthy mind. So start diary writing online from today!

                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
};

export default SecretDiary;