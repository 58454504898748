import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#00008B', //theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: 'center'
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: 'center'
   },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      textAlign: 'center'
   },
   // hide last border
   '&:last-child td, &:last-child th': {
      border: 0,
      textAlign: 'center'
   },
}));

function createData(features, admin, manager, coOrdinator, reviewer, writer) {
   return {
      features, admin, manager, coOrdinator, reviewer, writer
   }
}

const rows = [
   createData('Assign Roles', true, true, false, false, false),
   createData('Override Permissions', true, true, false, false, false),
   createData('User Actions', true, true, true, false, false),
   createData('Complaint Portal', true, true, true, true, true),
   createData('Announcements', true, true, true, false, false),
   createData('Reviewer Access', true, true, true, true, true),
   createData('Help Center Administration', true, true, false, false, false),
   createData('Help Center (User)', true, true, true, true, true),
   createData('User Insights', true, true, true, false, true),
   createData('Reviewer Insights', true, true, true, false, false),
   createData('Post Activity Insights', true, true, true, true, false),
   createData('User Leaderboard', true, true, true, true, true),
]

const AccessAndPermissions = (props) => {
   const [index, setIndex] = useState(null)

   const getGrade = (value) => {
      if (value) {
         switch (value) {
            case 'class_iii': return 'Class III';
            case 'class_iv': return 'Class IV';
            case 'class_v': return 'Class V';
            case 'class_vi': return 'Class VI';
            case 'class_vii': return 'Class VII';
            case 'class_viii': return 'Class VIII';
            case 'class_ix': return 'Class IX';
            case 'class_x': return 'Class X';
            case 'class_xi': return 'Class XI';
            case 'class_xii': return 'Class XII';
            default: return value;
         }
      } else {
         return 'Not Applicable';
      }
   }

   const getAccess = (value) => {
      if (value) {
         switch (value) {
            case 'super_admin': return 'Super Admin';
            case 'manager': return 'Manager';
            case 'co_ordinator': return 'Co-Ordinator';
            case 'reviewer': return 'Reviewer';
            case 'writer': return 'Writer';
            default: return value;
         }
      } else {
         return 'Not Applicable';
      }
   }

   const getGroup = (value) => {
      if (value) {
         switch (value) {
            case 'administration': return 'Administration';
            case 'teaching': return 'Teaching';
            case 'non_teaching': return 'Non-Teaching';
            case 'primary_school': return 'Primary School';
            case 'middle_school': return 'Middle School';
            case 'high_school': return 'High School';
            default: return value;
         }
      } else {
         return 'Not Applicable';
      }
   }

   return (
      <TableContainer component={Paper} style={{ borderRadius: '0px 15px 15px 0px' }}>
         <Table aria-label="customized table" style={{ maxWidth: '100vw' }}>
            <TableHead style={{ backgroundColor: '#273be2' }}>
               <TableRow style={{ backgroundColor: '#273be2', textAlign: 'left' }}>
                  <StyledTableCell align="left" style={{ textAlign: 'left' }}>Features</StyledTableCell>
                  <StyledTableCell align="center">Admin</StyledTableCell>
                  {/* <StyledTableCell align="center">Manager</StyledTableCell> */}
                  <StyledTableCell align="center">Co-Ordinator</StyledTableCell>
                  <StyledTableCell align="center">Reviewer</StyledTableCell>
                  <StyledTableCell align="center">Writer</StyledTableCell>

               </TableRow>
            </TableHead>
            <TableBody style={{ overflowX: 'auto' }}>
               {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                     <StyledTableCell align="left" style={{ textAlign: 'left' }}> {row.features}</StyledTableCell>
                     <StyledTableCell align="right">{row.admin ? <CheckIcon style={{ color: 'green' }} /> : null}</StyledTableCell>
                     {/* <StyledTableCell align="right">{row.manager}</StyledTableCell> */}
                     <StyledTableCell align="right">{row.coOrdinator ? <CheckIcon style={{ color: 'green' }} /> : null}</StyledTableCell>
                     <StyledTableCell align="right">{row.reviewer ? <CheckIcon style={{ color: 'green' }} /> : null}</StyledTableCell>
                     <StyledTableCell align="right">{row.writer ? <CheckIcon style={{ color: 'green' }} /> : null}</StyledTableCell>
                  </StyledTableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
}

export default AccessAndPermissions