import { actionTypes } from '../actions';

const INITIAL_STATE = {
	// openedBlog: {
	// 	id: '',
	// 	post_title: '',
	// 	blog_title: '',
	// 	category: '',
	// 	tags: [],
	// 	date: '',
	// 	commentable: '',
	// 	content: '',
	// 	userId: '',
	// 	views: [],
	// 	likedby: [],
	// 	lovedby: [],
	// 	shared: [],
	// 	comments: []
	// },
	// savedBlog: {
	// 	id: '',
	// 	post_title: '',
	// 	blog_title: '',
	// 	category: '',
	// 	tags: [],
	// 	date: '',
	// 	commentable: '',
	// 	content: '',
	// 	userId: '',
	// 	views: [],
	// 	likedby: [],
	// 	lovedby: [],
	// 	shared: [],
	// 	comments: []
	// },
	openedBlog: {
		id: null,
		title: null,
		posts: []
	},
	savedBlog: {
		id: null,
		title: null,
		posts: []
	},
	blogs: [],
	draftBlogs: [],
	savedBlogStatus: false
};

export const blogs = (state = INITIAL_STATE, action) => {
	const { type, payload, error } = action;

	switch (type) {
		case actionTypes.GET_BLOGS:
			return {
				...state,
				blogs: payload
			};

		case actionTypes.GET_DRAFT_BLOGS:
			return {
				...state,
				draftBlogs: payload
			};

		case actionTypes.SET_OPENED_BLOG:
			return {
				...state,
				openedBlog: payload
			};
		case actionTypes.SET_SAVED_BLOG:
			return {
				...state,
				savedBlog: payload.openedBlog
			};
		case actionTypes.SET_LIST_BLOGS:
			return {
				...state,
				setListBlogs: payload.setListBlogs
			};
		case actionTypes.SET_SAVED_BLOG_STATUS:
			return {
				...state,
				savedBlogStatus: payload
			};
		default:
			return state;
	}
};