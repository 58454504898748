import { triggerReducer, actionTypes } from '.';
import { firebase, db } from '../../config/fbConfig'
import store from '../store'
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from './snackbar'
import { INITIAL_STATE as poemInitialState } from "../reducers/poems";
import moment from 'moment'
import { addUserLogs } from './rewards';
import { saveReviewerDetails } from './reviewer'

// export const getPoems = () => (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//         await db.collection("poems").limit(5).get()
//             .then(async (poems) => {
//                 let poemsData = []
//                 poems.docs.forEach(async (item) => {
//                     let poem = item.data()
//                     poem = {
//                         ...poem,
//                         id: item.id
//                     }
//                     poemsData.push(poem)
//                 })
//                 for (let i = 0; i < poemsData.length; i++) {
//                     let poem = poemsData[i]
//                     let user = await db.collection("users").where(firebase.firestore.FieldPath.documentId(), '==', poem.userId).get()
//                     if (user && user.docs && user.docs.length > 0) {
//                         let userData = user.docs[0].data()
//                         userData = {
//                             ...userData,
//                             cPassword: null,
//                             newPassword: null,
//                             password: null
//                         }
//                         poem = {
//                             ...poem,
//                             user: userData
//                         }
//                         poemsData[i] = poem
//                     } else {
//                         poemsData.splice(i, 1)
//                         i--
//                     }

//                 }

//                 dispatch(
//                     triggerReducer(actionTypes.GET_POEMS, {
//                         payload: poemsData
//                     })
//                 );
//                 resolve(poemsData)
//             })
//             .catch((err) => {
//                 reject(err)
//             })
//     });
// };

export const getPoemsByUser = (user_id) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await db.collection("poems").where('userid', '==', user_id).get()
            .then(async (poems) => {
                let poemsData = []
                poems.docs.forEach(async (item) => {
                    let poem = item.data()
                    poem = {
                        ...poem,
                        id: item.id
                    }
                    poemsData.push(poem)
                })
                dispatch(
                    triggerReducer(actionTypes.GET_USER_POEMS, {
                        userPoems: poemsData
                    })
                );
                resolve(poemsData)
            })
            .catch((err) => {
                console.log("Error -- ", err)
                reject(err)
            })
    });
};


export const setOpenedPoem = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_POEM, { payload }));
};

export const setUserDraftPoemEdit = (payload) => (dispatch) => {
    dispatch(triggerReducer(actionTypes.SET_USER_DRAFT_POEM_EDIT, { payload }));
}

export const clearPoem = () => (dispatch) => {
    const payload = poemInitialState.savedPoem;
    dispatch(triggerReducer(actionTypes.SET_OPENED_POEM, { payload }));
    dispatch(triggerReducer(actionTypes.SET_USER_DRAFT_POEM_EDIT, { payload }));
};

// export const setSavedPoem = (isPublished = false) => async (dispatch, getState) => {
//     const { auth } = getState().firebase;
//     const { openedPoem } = getState().poems;
//     const { firestore } = store;


//     if (payload.blogId && payload.blogId.label) {
//         payload.blogId = payload.blogId;
//     } else {
//         payload.blogId = ''
//     }

//     if (payload.category && payload.category.label) {
//         payload.category = payload.category;
//     } else {
//         payload.category = ''
//     }
//     if (openedPoem && openedPoem.id) {
//         payload.id = openedPoem.id
//     }
//     payload.isPublished = isPublished;

//     if (openedPoem?.blogId?.value || payload.blogId == '') {
//         dispatch(savePoem(payload));
//     } else if (payload.blogId && payload.blogId.label && payload?.blogId?.value.length == 0) {
//         await firestore.add({ collection: "blogs" }, {
//             title: openedPoem.blogId.label,
//             userId: auth.uid,
//             category: payload.category,
//         }).then((resp) => {
//             payload.blogId = {
//                 value: resp.id,
//                 label: openedPoem.blogId.label,
//             };
//             dispatch(savePoem(payload));
//         });
//     }
// };

export const saveUserPoem = (isPublished = false) => async (dispatch, getState) => {
    const { firestore } = store;
    const { openedPoem } = getState().poems;
    const { auth } = getState().firebase;
    const { tenantId } = getState().user
    try {
        const docRefId = openedPoem.id;
        let payload = {
            ...openedPoem,
            userId: auth.uid,
            updatedBy: auth.uid,
            tenantId,
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        };
        delete payload.id;
        payload.isPublished = isPublished;

        if (!docRefId) {
            firestore.add({ collection: "poems" }, { ...payload, tenantId }).then((docRef) => {
                payload.id = docRef.id;

                dispatch(triggerReducer(actionTypes.SET_OPENED_POEM, { payload }));

                if (payload.isPublished) {
                    let rewardPayload = {
                        poemId: docRef.id,
                        type: 'poem',
                        operation: 'create',
                        quinns: 1000,
                        milestone: 1,
                        date: firebase.firestore.Timestamp.fromDate(new Date()),
                    }
                    dispatch(addUserLogs(rewardPayload))
                    if (payload.isReviewer) {
                        const reviewer = {
                            contentId: docRef.id,
                            userId: auth.uid,
                            reviewerEmail: payload.reviewerEmail,
                            type: 'Poem',
                            title: payload.title,
                            status: 'Unread',
                            sendDate: payload.lastUpdated,
                            lastUpdated: payload.lastUpdated,
                            isDeleted: false
                        }
                        dispatch(saveReviewerDetails(reviewer))
                    }
                }
                dispatch(showSuccessSnackbar("Poem Saved Successfully"));
            }).catch((error) => {
                dispatch(showErrorSnackbar("Error in saving Poem"));
            });
        } else {
            dispatch(togglePageLoading(true));
            firestore.collection("poems").doc(docRefId).update(payload)
                .then(async () => {
                    payload.id = docRefId

                    dispatch(triggerReducer(actionTypes.SET_OPENED_POEM, { payload }));
                    if (payload.isReviewer) {
                        const reviewer = {
                            contentId: docRefId,
                            userId: auth.uid,
                            reviewerEmail: payload.reviewerEmail,
                            type: 'Poem',
                            title: payload.title,
                            status: 'Unread',
                            sendDate: payload.lastUpdated,
                            lastUpdated: payload.lastUpdated,
                            isDeleted: false,
                        }
                        await firestore
                            .collection('poems')
                            .where('reviewerEmail', '==', auth.email)
                            .get()
                            .then(async (poems) => {
                                if (poems.size) {
                                    dispatch(saveReviewerDetails(reviewer))
                                }
                            })
                            .catch((err) => {
                                console.log("error -- ", err)
                            })
                    }
                    dispatch(showSuccessSnackbar("Poem Updated Successfully"));
                    dispatch(togglePageLoading(false));

                }).catch((error) => {
                    dispatch(showErrorSnackbar("Error in updating Poem"));
                    dispatch(togglePageLoading(false));
                });
        }
    } catch (error) {
        console.log("Error -- ", error)
        dispatch(showErrorSnackbar("Error in saving Poem"));
        dispatch(togglePageLoading(false));
    }
};

export const getUserPoems = (isPublished = false) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    await db.collection("poems")
        .orderBy('lastUpdated', 'desc')
        .where('userId', '==', auth.uid)
        .where('isPublished', '==', isPublished)
        // .where('isReviewer', '==', false)
        .get()
        .then(async (poems) => {
            let poemsData = []
            poems.docs.forEach(async (item) => {
                let poem = item.data()
                poem = {
                    ...poem,
                    id: item.id
                }
                poemsData.push(poem)
            })
            if (isPublished) {
                // let likedByCount = 0;
                // let lovedByCount = 0;
                // let sharedByCount = 0;
                // let viewsCount = 0;

                // for (let i = 0; i < poemsData.length; i++) {
                //     if (poemsData[i].likedby) {
                //         likedByCount += poemsData[i].likedby.length;
                //     }
                //     else {
                //         likedByCount += 0;
                //     }
                //     if (poemsData[i].lovedby) {
                //         lovedByCount += poemsData[i].lovedby.length;
                //     }
                //     else {
                //         lovedByCount += 0;
                //     }

                //     if (poemsData[i].shared) {
                //         sharedByCount += poemsData[i].shared;
                //     }
                //     else {
                //         sharedByCount += 0;
                //     }

                //     if (poemsData[i].views) {
                //         viewsCount += poemsData[i].views;
                //     }
                //     else {
                //         viewsCount += 0;
                //     }

                // }
                // dispatch(
                //     triggerReducer(actionTypes.COUNTS, {
                //         payload: { likedByCount, lovedByCount, sharedByCount, viewsCount },
                //     })
                // );

                dispatch(
                    triggerReducer(actionTypes.GET_USER_PUBLISHED_POEMS, {
                        payload: poemsData,
                    })
                );
            } else {
                dispatch(
                    triggerReducer(actionTypes.GET_USER_DRAFT_POEMS, {
                        payload: poemsData
                    })
                );
            }

        })
        .catch((err) => {
            console.log("Err -- ", err)
        })
};

export const deletePoem = (payload) => async (dispatch, getState) => {
    const { firestore } = store;
    await firestore.collection('poems')
        .doc(payload).delete().then(() => {
            firestore.collection("comments")
                .where('poemid', '==', payload)
                .get()
                .then((comments) => {
                    if (comments && comments.docs.length > 0) {
                        comments.docs.forEach(async (item) => {
                            item.ref.delete()
                        })
                    }
                })
                .catch((error) => {
                    console.log("Errorr deleting comment", error)
                })
            dispatch(showSuccessSnackbar("poem deleted Successfully!"));
        }).catch((error) => {
            console.log("Eroorrrrrrr === ", error)
            dispatch(showErrorSnackbar("poem delete failed!!!"));
        });
}

export const getPoems = () => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { tenantId } = getState().user;

    await db.collection("poems")
        .orderBy('lastUpdated', 'desc')
        .where('isPublished', '==', true)
        .where('tenantId', '==', tenantId)
        .get()
        .then(async (poems) => {
            let poemsData = []
            poems.docs.forEach(async (item) => {
                let poem = item.data()
                poem = {
                    ...poem,
                    id: item.id
                }
                poemsData.push(poem)
            })

            var usersArrayData = []

            for (let i = 0; i < poemsData.length; i++) {
                let post = poemsData[i]
                let userData = {}
                let index = usersArrayData.findIndex((u) => u.id === post.userId)
                if (index > -1) {
                    userData = usersArrayData[index]
                } else {
                    let user = await db.collection("users")
                        .where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                    if (user && user.docs && user.docs.length > 0) {
                        userData = user.docs[0].data()
                        userData = {
                            ...userData,
                            cPassword: null,
                            newPassword: null,
                            password: null
                        }
                    }
                }
                post = {
                    ...post,
                    user: userData
                }
                poemsData[i] = post
            }
            dispatch(
                triggerReducer(actionTypes.GET_POEMS, {
                    payload: poemsData,
                })
            );
        })
        .catch((err) => {
            console.log("Err -- ", err)
        })
};


export const getGlobalPoems = () => async (dispatch, getState) => {
    const { tenantId } = getState().user;

    await db.collection("poems")
        .orderBy('lastUpdated', 'desc')
        .where('isPublished', '==', true)
        .where('isGlobalView', '==', true)
        .where('tenantId', '!=', tenantId)
        .get()
        .then(async (poems) => {
            let poemsData = []
            poems.docs.forEach(async (item) => {
                let poem = item.data()
                poem = {
                    ...poem,
                    id: item.id
                }
                poemsData.push(poem)
            })

            var usersArrayData = []

            for (let i = 0; i < poemsData.length; i++) {
                let post = poemsData[i]
                let userData = {}
                let index = usersArrayData.findIndex((u) => u.id === post.userId)
                if (index > -1) {
                    userData = usersArrayData[index]
                } else {
                    let user = await db.collection("users")
                        .where(firebase.firestore.FieldPath.documentId(), '==', post.userId).get()
                    if (user && user.docs && user.docs.length > 0) {
                        userData = user.docs[0].data()
                        userData = {
                            ...userData,
                            cPassword: null,
                            newPassword: null,
                            password: null
                        }
                    }
                }
                post = {
                    ...post,
                    user: userData
                }
                poemsData[i] = post
            }
            dispatch(
                triggerReducer(actionTypes.GET_GLOBAL_POEMS, {
                    payload: poemsData,
                })
            );
        })
        .catch((err) => {
            console.log("Err -- ", err)
        })
};

export const setShowPoems = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_SHOW_POEM, { payload }));
};

export const setShowPoemIndex = (payload) => (dispatch) => {
    dispatch(
        triggerReducer(actionTypes.SET_SHOW_POEM_INDEX, { payload }));
};





//testing area start


export const addTenantIdManuallyToPoem = (tenantId) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        if (tenantId) {
            await db.collection("poems").get()
                .then(async (posts) => {
                    let postsData = []
                    posts.docs.forEach(async (item) => {
                        let post = item.data()
                        post = {
                            ...post,
                            id: item.id
                        }
                        postsData.push(post)
                    })

                    let count = 0;
                    for (let i = 0; i < postsData.length; i++) {
                        let post = postsData[i]
                        if (post.hasOwnProperty('tenantId') && post.tenantId === tenantId) {
                            resolve(true);
                        }
                    }

                    for (let i = 0; i < postsData.length; i++) {
                        let post = postsData[i]
                        if (count == 10)
                            break;
                        if ((!post.hasOwnProperty('tenantId') || post?.tenantId.includes('@')) && count < 10) {
                            await db
                                .collection('poems')
                                .doc(post.id)
                                .update({ tenantId })
                                .then(res => {
                                    console.log("updated successfully---", res)
                                }).catch((error) => {
                                    console.log("Error : ", error)
                                });
                            count++;
                        }

                    }
                    resolve(postsData)
                })
                .catch((err) => {
                    reject(err)
                })
        }
        resolve(false)
    });
};



export const addGlobalViewToPoem = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { tenantId } = getState().user;

        await db.collection("poems")
            .where('tenantId', '!=', tenantId)
            .get()
            .then(async (posts) => {
                let postsData = []
                posts.docs.forEach(async (item) => {
                    let post = item.data()
                    post = {
                        ...post,
                        id: item.id
                    }
                    postsData.push(post)
                })

                for (let i = 0; i < 10; i++) {
                    let post = postsData[i]
                    await db
                        .collection('poems')
                        .doc(post.id)
                        .update({ isGlobalView: true })
                        .then(res => {
                            console.log("Global added successfully---", res)
                        }).catch((error) => {
                            console.log("Error : ", error)
                        });
                }
                resolve(postsData)
            })
            .catch((err) => {
                reject(err)
            })
    });
};
//testing area end


