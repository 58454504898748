import React, { useState, useEffect } from "react";
import { Grid, Container, Box, Stack, Paper } from "@mui/material";
import EssayEditorStyles from "../EssayEditorStyles";

import blogiconhover from "../../../assets/images/dashboard/blogiconhover.svg";
import libraryiconhover from "../../../assets/images/dashboard/libraryiconhover.svg";
import cardiconhover from "../../../assets/images/dashboard/cardiconhover.svg";
import bookiconhover from "../../../assets/images/dashboard/bookiconhover.svg";
import poemiconhover from "../../../assets/images/dashboard/poemiconhover.svg";
import essayiconhover from "../../../assets/images/dashboard/essayiconhover.svg";
import comiciconhover from "../../../assets/images/dashboard/comiciconhover.svg";
import scrapbookiconhover from "../../../assets/images/dashboard/scrapbookiconhover.svg";

import BlogIconSrc from "../../../assets/images/dashboard/blogicon.png";
import DiaryIconSrc from "../../../assets/images/dashboard/diary.png";
import CardIconSrc from "../../../assets/images/dashboard/card.png";
import BookIconSrc from "../../../assets/images/dashboard/book.png";
import PoemIconSrc from "../../../assets/images/dashboard/poem.png";
import EssayIconSrc from "../../../assets/images/dashboard/essay.png";
import ComicIconSrc from "../../../assets/images/dashboard/comic.png";
import SBookIconSrc from "../../../assets/images/dashboard/scrapbook.png";

import { useHistory } from "react-router-dom";

// components from local src/components
import IconCircle from "../../Dashboard/IconCircle";
export default function AppCreate() {
  const classes = EssayEditorStyles();
  const history = useHistory();

  const redirectTo = (path) => {
    history.push(path);
  };

  const getImg = (curr) => {
    if (history.location.pathname === curr) {
      return blogiconhover;
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      maxWidth="100%"
      style={{
        color: "white",
        background: "linear-gradient(0deg, #6acfff, #2d00b7)",
        borderRadius: "1rem",
        padding: "1rem",
      }}
    >
      <h3 style={{ color: "white", fontWeight: 700, fontSize: "2rem" }}>
        Create
      </h3>
      <Grid className={classes.AppCreateStyles}
        container
        spacing={0}
        justifyContent="space-evenly"
        alignItems="center"
        padding={2}
      >
        <Grid
          item
          minWidth={0.125}
          style={{ cursor: "pointer" }}
          onClick={() => redirectTo("/blog")}
        >
          <IconCircle
            HoverCompSrc={blogiconhover}
            IconCompSrc={getImg("/blog") ? blogiconhover : BlogIconSrc}
            DispTitle="Blog"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/diary")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            HoverCompSrc={libraryiconhover}
            IconCompSrc={getImg("/diary") ? libraryiconhover : DiaryIconSrc}
            DispTitle="Diary"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/postcard")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            HoverCompSrc={cardiconhover}
            IconCompSrc={getImg("/postcard") ? cardiconhover : CardIconSrc}
            DispTitle="Card"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/poem")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            // IconCompSrc={CardIconSrc}
            HoverCompSrc={poemiconhover}
            IconCompSrc={getImg("/poem") ? poemiconhover : PoemIconSrc}
            DispTitle="Poem"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/essay")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            HoverCompSrc={essayiconhover}
            IconCompSrc={getImg("/essay") ? essayiconhover : EssayIconSrc}
            DispTitle="Story"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
