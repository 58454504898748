import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Box } from "@mui/system";
import CampaignIcon from '@mui/icons-material/Campaign';
import AddIcon from '@mui/icons-material/Add';
import ActivityBar from "../ActivityBar";
import PieChartPage from "../PieChartPage";
import { useDispatch, useSelector } from "react-redux";
import { getCounts } from "../../store/actions/siteInsights";
import AnnouncementsTable from "./Table/AnnouncementsTable";
import TextField from '@mui/material/TextField';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Rating from '@mui/material/Rating';

const UserActivity = (props) => {
   const { studentsCount, reviewReceived,
      reviewEvaluated, totalBlogsCount, totalPoemsCount,
      totalStoryCount, totalBooksCount } = useSelector((state) => state.siteInsights)

   const [postDistributionDataPoints, setPostDistributionDataPoints] = useState([])
   const [value, setValue] = useState(dayjs());

   useEffect(() => {
      setPostDistributionDataPoints([
         { y: totalBlogsCount, label: "Blogs" },
         { y: totalPoemsCount, label: "Poems" },
         { y: totalStoryCount, label: "Story" },
         { y: totalBooksCount, label: "Books" },
      ])
   }, [])
   
   return (
      <Grid container item xs={11} md={11} lg={11} style={{ margin: "auto" }}>
         <Grid container spacing={2} style={{ padding: "30px", paddingTop: "1rem" }}>
            <Grid container style={{ padding: "10px 15px", display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: "0.8rem", background: "#c5eed3", borderRadius: "10px" }}>
               <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography style={{ fontSize: "20px", fontWeight: "600", color: "#1e3a71" }}>
                     Performance of the high school
                  </Typography>
               </Grid>
               <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "end" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                     <DatePicker style={{ background: "#fff !important", }}
                        views={['year', 'month']}
                        // label="Year and Month"
                        minDate={dayjs('2012-03-01')}
                        // maxDate={dayjs('2023-06-01')}
                        value={value}
                        size="small"
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" {...params} helperText={null} style={{ background: "#fff", borderRadius: "6px", boxShadow: "0px 1px 1px 0px #9e9e9e" }} />}
                     />
                  </LocalizationProvider>

                  <Button variant="contained" size="small" style={{ borderRadius: "10px", background: "#fff", color: "#1e3a71", fontWeight: "600", marginLeft: "1rem" }}>
                     Create New <SaveAltIcon />
                  </Button>
               </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
               <Grid container spacing={2}>
                  <Grid item spacing={1} xs={12} md={6}  >
                     <Box style={{ background: "lightblue", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {studentsCount}
                        </Typography>
                        <Typography>
                           Enrolled
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Students
                        </Typography>
                     </Box>

                  </Grid>
                  <Grid item xs={12} md={6} >
                     <Box style={{ background: "#ec572840", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {totalBlogsCount}
                        </Typography>
                        <Typography>
                           Posts
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Published
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={6} >
                     <Box style={{ background: "#ffc1078c", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {reviewReceived}
                        </Typography>
                        <Typography>
                           Review Requests
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Received
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <Box style={{ background: "#8bc34a9c", borderRadius: '20px', textAlign: "center", padding: '45px' }}>
                        <Typography style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}>
                           {reviewEvaluated}
                        </Typography>
                        <Typography>
                           Review Requests
                        </Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                           Evaluated
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={12} md={2} >
               <PieChartPage title="Post Distribution" dataPoints={postDistributionDataPoints} />
            </Grid>

            <Grid item xs={12} md={5} lg={5}>
               <ActivityBar />
            </Grid>

            <Grid item xs={12} md={2} >
               <Typography style={{ fontSize: "25px", fontFamily: 'Museo700' }}>
                  Avg. Group Rating
               </Typography>
               <Typography style={{ fontSize: "25px", fontFamily: 'Museo300', }}>
                  Overall
               </Typography>
               <br />
               <Box style={{ background: "#8bc34a9c", borderRadius: '20px', textAlign: "center", justifyContent: 'center', padding: '40px 20px' }}>
                  <Typography style={{ fontSize: "45px", textAlign: "center", color: '#fff', fontFamily: 'Museo300', fontWeight: '600' }}>
                     4.35<span style={{ fontSize: '25px' }}>/5</span>
                  </Typography><br />
                  <Rating sx={{ color: '#fff', fontSize: '40px' }} name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />
                  <br /><br />
                  <Typography style={{ fontSize: '30px', fontFamily: 'Museo300', color: '#fff', fontWeight: '600' }}>
                     273 Reviews
                  </Typography>
               </Box>
            </Grid>
         </Grid>

         <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12} md={12}>
               <Grid container spacing={2}>
                  <Grid item spacing={1} xs={12} md={6}  >
                     <Grid container style={{ background: "lightgray", borderRadius: '10px', textAlign: "center", padding: '20px' }} >
                        <Grid container style={{ padding: "5px", display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                           <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                              <CampaignIcon style={{ marginRight: "0.5rem", fontSize: "30px" }} />
                              <Typography style={{ fontSize: "20px", fontWeight: "600", color: "#1e3a71" }}>
                                 Announcements
                              </Typography>
                           </Grid>
                           <Grid item md={6} lg={6} style={{ display: "flex", justifyContent: "end" }}>
                              <Button variant="contained" startIcon={<AddIcon />} style={{ borderRadius: "20px", background: "#ffc107", color: "#1e3a71", fontWeight: "600" }}>
                                 Create New
                              </Button>
                           </Grid>
                        </Grid>

                        <Grid item md={12} lg={12}>
                           <AnnouncementsTable />
                        </Grid>

                     </Grid>

                  </Grid>
                  <Grid item spacing={1} xs={12} md={6}  >
                     <Grid container style={{ background: "lightgray", borderRadius: '10px', textAlign: "center", padding: '20px' }} >
                        <Grid container style={{ padding: "5px", display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                           <Grid item md={5} lg={5} style={{ display: "flex", justifyContent: "flex-start" }}>
                              <CampaignIcon style={{ marginRight: "0.5rem" }} />
                              <Typography style={{ fontWeight: "600", color: "#1e3a71" }}>
                                 User Complaints
                              </Typography>
                           </Grid>
                           <Grid item md={7} lg={7} style={{ display: "flex", justifyContent: "space-around", }}>
                              <Typography style={{ fontWeight: "600", color: "#1e3a71", marginTop: "0.4rem" }}>
                                 Sort By
                              </Typography>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 Open
                              </Button>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 In-Progress
                              </Button>
                              <Button variant="contained" style={{ fontSize: "12px", borderRadius: "10px", background: "#1e3a71", color: "#fff", fontWeight: "600" }}>
                                 Closed
                              </Button>
                           </Grid>
                        </Grid>

                        <Grid item md={12} lg={12}>
                           <AnnouncementsTable />
                        </Grid>

                     </Grid>

                  </Grid>

               </Grid>
            </Grid>
         </Grid>
      </Grid>


   );
}

export default UserActivity;
