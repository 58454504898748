import { Button, Dialog, FormControl, OutlinedInput } from '@material-ui/core'
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import MainStyles from '../MainStyles';
import { addUpdateComment, updateComplaintStatus } from '../../store/actions/complaints';
import CampaignIcon from '@mui/icons-material/Campaign';
import CommentIcon from '@mui/icons-material/Comment';
import { db } from '../../config/fbConfig'
import { showSuccessSnackbar } from '../../store/actions/snackbar';

const ComplaintDetail = ({open,setOpen,complaint,noOfDays}) => {
    const classes1 = MainStyles();
    const dispatch = useDispatch()
    const [comment,setComment] = useState("")
    const [post,setPost] = useState(null)
    const [postAuthor,setPostAuthor] = useState('Rishabh')
    
    useEffect(()=>{
        setComment("")
    },[complaint])
    const handleClose = ()=>{
        setOpen(false)
    }
    const addComment =()=>{
        const payload = {
            id:complaint.id,
            comment:comment
        }
        dispatch(addUpdateComment(payload))
    }
    useEffect(()=>{
        if(complaint.postId)    
            getPostByPostId(complaint.postId)
    },[])
    const getUserById = async(userId)=>{
        await db.collection("users")
           .doc(userId)
           .get()
           .then(users => {
                let user = users.data()
                user = {
                ...user,
                id: users.id
                }
                setPostAuthor(user.displayName)
            })
           .catch((err) => {
              console.log("Err get post --- ", err)
           })
    }
    const getPostByPostId = async(payload)=> {
        await db.collection("posts")
           .doc(payload)
           .get()
           .then(posts => {
                let post = posts.data()
                post = {
                ...post,
                id: posts.id
                }
                getUserById(post.userId)
                setPost(post)
            })
           .catch((err) => {
              console.log("Err get post --- ", err)
           })
    };
    const archivePost = async(id)=>{
        await db.collection("posts")
        .doc(id)
        .update({isPublished:false})
        .then(res=>{
            dispatch(showSuccessSnackbar('Post has been set to archived'))
            dispatch(updateComplaintStatus(complaint.id))
           })
           .catch((err) => {
              console.log("Err get post --- ", err)
           })
    }
  return (
    <Dialog PaperProps={{
        style: { borderRadius: 10 }
      }} style ={{width:'60vw'}} className={classes1.modalStyles} open={open} onClose={handleClose}>
        <Grid style ={{backgroundColor:'#B0E8EC',color:'#1E3A71',fontWeight:'600',fontSize:'20px',justifyContent:'center',display:'flex',alignItems:'center',height:'40px'}}> 
            Details
        </Grid>
        <Grid style ={{borderBottom:'1px solid #DDDDDD',margin:'20px'}}>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems="center" marginBottom={'5px'}>
                <Grid style={{color:'#707070'}}>Complaint ID : {complaint.id}</Grid>
                <Grid display={'flex'} fontSize='12px' alignItems="center">
                    <Grid style ={{borderRadius:'10px', padding:"10px 15px",color:'#1E3A71',border:'1px solid #707070'}}>{complaint.complaintCategory}</Grid>
                    <Grid style = {{backgroundColor:'#FFDCDC',padding:"10px 15px", borderRadius:'10px',color:'#1E3A71',marginLeft:'20px'}}>{complaint.status}</Grid>
                </Grid>
            </Grid>
            <Grid display={'flex'} alignItems="center">
                <Grid display={"flex"} alignItems='center'>
                    <Grid>
                        <img style ={{height:'36px',borderRadius:'50%'}} src={'fakeDp.png'} alt={'User'}/>
                    </Grid>
                    <Grid style ={{fontSize:'12px'}}>
                        <Grid Style ={{fontWeight:'700',color:'#305067'}}>{complaint.displayName}</Grid>
                        <Grid Style ={{fontWeight:'400',color:'#4D8A93'}}>{complaint.email}</Grid>
                    </Grid>
                </Grid>
                <Grid style ={{color:'#1E3A71',fontSize:'12px',marginLeft:'10px'}}>{noOfDays} {noOfDays == 1?"Day":"Days"} ago</Grid>
            </Grid>
            <Grid style={{color:'#707070',fontWeight:'700',fontSize:'20px',marginBottom:'5px'}}>{complaint.title}</Grid>
            <Grid style ={{color:'#707070',marginBottom:'20px'}}>{complaint.discription?complaint.discription:'No Discription Available'}</Grid>
        </Grid>
        {
            (complaint.complaintCategory == 'Post Report' && post) &&
            <Grid  margin ='0px 20px'>
                <Grid fontWeight='700' display='flex' justifyContent='space-between' >
                    <Grid color='#707070' fontSize='20px'> Blog: {post.title}</Grid>
                    {/* {complaint.status == 'Open' && 
                        <Grid color='#E42525' onClick={()=>{archivePost(post.id)}} style ={{cursor:'pointer'}} >Archive Post</Grid>
                    } */}
                </Grid>
                <Grid color='#707070' fontWeight='700'>
                    Author: {postAuthor}
                </Grid>

                <Grid dangerouslySetInnerHTML = {{__html:post.body}} />
            </Grid>
        }
        {
            complaint.status == 'Open'&&
            <Grid style ={{margin:'20px'}}>
                <FormControl variant="outlined" style={{ marginRight: 20 }}>
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        placeholder="Add Comment..."
                        size="medium"
                        className={classes1.searchboxStyle}
                        value ={comment}
                        onChange={(e)=>{setComment(e.target.value)}}
                        />
                </FormControl>
                <Button variant="contained"
                    style = {{borderRadius:'10px'}}
                    className={classes1.btnStyle}
                    onClick={addComment}
                > Send
                </Button>
            </Grid>
        }
        <Grid style = {{padding:'0px 20px',height:'200px',overflow:'auto'}}>
            {complaint.status == 'Closed' &&
                <Grid>
                    <Grid style ={{fontSize:'12px',color:'#909090'}}>
                        {new Date(complaint.closedAt.seconds*1000).toString().slice(0,21)}
                    </Grid>
                    <Grid display='flex' alignItems='center'>
                        <CampaignIcon style = {{color:'#8F8F8F'}}/> <span>The problem has been resolved</span> 
                    </Grid>
                </Grid>
            }
            {
                complaint.comment && 
                complaint.comment.slice(0).reverse().map(element=>{
                    return <Grid marginTop='10px'>
                    <Grid style ={{fontSize:'12px',color:'#909090'}}>
                        {new Date(element.date.seconds*1000).toString().slice(0,21)}
                    </Grid>
                    <Grid display='flex' alignItems='center'>
                        <CommentIcon style = {{color:'#8F8F8F'}}/> <span>{element.comment}</span> 
                    </Grid>
                </Grid>
                })
            }
            <Grid style = {{marginTop:'16px'}}>
                <Grid style ={{fontSize:'12px',color:'#909090'}}>
                    {new Date(complaint.createdAt.seconds*1000).toString().slice(0,21)}
                </Grid>
                <Grid>
                    Complaint Registered By {complaint.displayName}
                </Grid>
            </Grid>
        </Grid>
        {
            complaint.status=='Open'&&
            <Grid style={{borderTop:'2px solid #dddddd',height:'65px',display:'flex',justifyContent:complaint.status=='Open'?'space-between':'flex-end',alignItems:'center'}}>
                <Button onClick={()=>{dispatch(updateComplaintStatus(complaint.id))}} style ={{backgroundColor:'#294378',color:'white',marginLeft:'20px'}}>{complaint.complaintCategory == 'Post Report'?'Dismiss Report':'Close Issue'}</Button>
                <Button onClick={complaint.complaintCategory == 'Post Report'?()=>{archivePost(post.id)}:()=>{dispatch(updateComplaintStatus(complaint.id))}} style ={{backgroundColor:'#294378',color:'white',marginRight:'20px'}}>{complaint.complaintCategory == 'Post Report'?'Archive Post':'Resolved'}</Button>
            </Grid>
        }
    </Dialog>
  )
}

export default ComplaintDetail