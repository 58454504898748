import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import DiaryblogsStyles from './DiaryblogsStyles';
import guide from '../../../assets/images/PostCardImages/guide.jpg';

function NewDiary() {
    const classes = DiaryblogsStyles();
    return (
        <>
            <Grid container style={{
                display: 'flex', justifyContent: "center",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{
                    borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                    padding: '20px'
                }}>

                    <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "center" }}
                    >
                        POSTCARDS
                    </Typography>
                    {/* <Typography
                        className={classes.headingTypo}
                        style={{ fontWeight: "bold", color: "#101070", fontSize: "25px", fontFamily: "Museo 700", textAlign: "left" }}

                    >
                        Dream.Explore.Create
                        <br />
                    </Typography> */}
                    <Typography
                        className={classes.headingTypo}
                        style={{ fontSize: "25px", fontFamily: "Museo300", color: "#101070", }}
                    >
                        Have you ever received a postcard? Those rectangular cards with beautiful pictures on them, bringing wishes from friends or family?
                        <br />
                    </Typography>

                    <Grid container spacing={3} stype={{ marginTop: '1rem' }}>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                            <Grid container >

                                <Box >

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}
                                    >
                                        First used in Austria, a postcard is a simple yet warm option to send greetings to your loved ones. Rectangular cards that come in a standard size, postcards feature beautiful pictures or photographs – featuring people, places, monuments, illustrations of important events, etc.
                                        <br />

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                                <Typography
                                    className={classes.headingTypo}
                                    style={{ fontSize: "30px" }}
                                >
                                    You may send a postcard, among other reasons, for the following:
                                </Typography>

                                <ul >
                                    <li style={{ color: '#000' }} >
                                        To convey greetings or wishes to friends or family
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        To Invite your loved ones to an event
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        To convey the whereabouts of a trip you make.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        To describe an event
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        To inquire about one’s well being
                                    </li>
                                </ul>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    With the advent of e-mail and messaging apps, the use of postcards has declined. But the happiness of receiving a postcard from a cousin or friend during the holidays cannot be expressed in words. Wouldn’t it be more fun to send specially crafted postcards to our friends?
                                    QuillatWill is here to help you.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} sm={12} style={{ textAlign: "left" }}>
                                    <img src={guide} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box >
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }}
                                >
                                    With QuillatWill, you can design your postcard to your taste. The app provides a template and a huge stock of images and stickers to design your customised postcard. Follow the simple steps and get it done!
                                </Typography>
                                <ol style={{ color: '#000' }}>
                                    <li style={{ color: '#000' }}>
                                        Sign up on www.quillatwill.com or Login to your Quill at will account.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        In the Dashboard, go to Create banner and click on Card.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Click on the + / Create new icon.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Select from the range of attractive postcard templates in Media.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        You can also customise the postcard with an image of your choice by clicking on Upload and adding the image from your device.
                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Craft your message in short and simple sentences on the left-hand side of the postcard. You can make it more fun by using different text colour and highlight options.

                                    </li>
                                    <li style={{ color: '#000' }}>
                                        Click on the Send button on the top right and address it to the recipient in the space provided by typing their e-mail ID
                                        Hurray
                                    </li>
                                </ol>
                                <Typography
                                    className={classes.headingTypo1}
                                    style={{ fontSize: "16px" }} >
                                    A postcard carries more than just messages. They are reminders of your love and concern. Create beautiful postcards on QuillatWill to surprise your loved ones.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default NewDiary