import { triggerReducer, actionTypes } from '.';

export const setSearchQuery = (searchQuery = '') => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_USER_SEARCH_QUERY, {
            payload: searchQuery
        })
    );
}

export const setFilter = (filter) => (dispatch, getState) => {
    filter = filter && filter.length > 0 ? filter : ['all']
    dispatch(
        triggerReducer(actionTypes.SET_FILTER, {
            payload: filter
        })
    );
}