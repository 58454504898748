import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Grid } from '@mui/material';
// Icon import from/src/images
import ShoutIconSrc from '../../../assets/images/dashboard/shoutout.png';
import rookieUnlocked from "../../../assets/images/Profile/rookieUnlocked.png";
import nerdUnlocked from "../../../assets/images/Profile/nerdUnlocked.png";
import scholarUnlocked from "../../../assets/images/Profile/scholarUnlocked.png";


// ----------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(4, 0),
  color: theme.palette.info.darker,
  backgroundColor: '#77D4E3',
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: "25px",
  height: '40vh',

}));

// ------------------------------------------------
const imageArray = [rookieUnlocked, nerdUnlocked, scholarUnlocked]

export default function AppShoutOut() {
  const { quinns } = useSelector(state => state.rewards)
  const [imageIndex, setImageIndex] = useState(0)
  const [earningQuinns, setEarningQuinns] = useState(0)

  useEffect(() => {
    if ((20000 - quinns) > 0) {
      setImageIndex(0)
      setEarningQuinns(20000 - quinns)
    } else if ((40000 - quinns) > 0) {
      setImageIndex(1)
      setEarningQuinns(40000 - quinns)
    } else if ((60000 - quinns) > 0) {
      setImageIndex(2)
      setEarningQuinns(60000 - quinns)
    }
  }, [quinns])

  return (
    <RootStyle>
      <img
        style={{

          marginLeft: 'auto',
          marginRight: 'auto',
          height: '70px',

        }}
        src={ShoutIconSrc}
        alt="Shout_Icon"
      />
      <Typography variant="h6">Shout Out!!</Typography>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold', fontSize: "20px" }}>
          Earned Quinns {quinns}
        </Typography>
        <br />

        <Typography style={{ fontWeight: 'bold', fontSize: '18', color: "#f56335" }}>
          {earningQuinns} quinns to the next badge
        </Typography>
        <img src={imageArray[imageIndex]} alt="trophie" style={{ width: 'auto', height: '3rem' }} />
        <br />
        {/* <p style={{ color: '#fff', fontWeight: 'bold', marginTop: '10px' }}>Earn more {earningQuinns} quinns to get.</p> */}
      </Grid>
    </RootStyle>
  );
}
