import React, { useEffect, useRef, useState } from "react";
import Main from "../Main";
import { Grid, Typography, Button, Card, ListItem, Chip, Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import './userAction.css'

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormGroup from '@mui/material/FormGroup';
import AddIcon from '@mui/icons-material/Add';

import Navbar from "../Navbar";
import TopBar from "../TopBar";
import UserTable from "./UserTable";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from "@mui/styles";
import FilterData from "./constants";
import { activeUsers, archiveUsers, deleteUsers, getUsers, manualSignUp, setUser } from "../../store/actions/user";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import _ from 'lodash'
import { showErrorSnackbar } from "../../store/actions/snackbar";
import MainStyles from "../MainStyles";
import { AddUpdateUser } from "./AddUpdateUser";
import { MessageBox } from "./MessageBox";
import { CSVLink } from 'react-csv';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import moment from "moment";


const TopBarStyles = makeStyles((theme) => ({
    mainGrid: {
        '& .css-1cndkqf-MuiToolbar-root': {
            minHeight: '40px',
            height: '70px'
        }
    },
}));

const BulkUserActions = (props) => {

    const classes = TopBarStyles();
    const classes1 = MainStyles();
    const dispatch = useDispatch()
    const { users, tenantId } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false);
    const [menuIndex, setMenuIndex] = useState(0)
    const [state, setState] = useState(FilterData);
    const [userList, setUserList] = useState([])
    const [actionToDo, setActionToDo] = useState(1)
    const [addUserOpen, setAddUserOpen] = useState(false)
    const [coOrdinators, setCoOrdinators] = useState([])
    const [updateId, setUpdateId] = useState(null)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [openMsgBox, setOpenMsgBox] = useState(false)
    const [downloadData, setDownloadData] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true)
    const [search, setSearch] = useState('')
    const [isADate, setIsADate] = useState(false)
    const [isDateRange, setIsDateRange] = useState(false)
    const [aDateValue, setADateValue] = useState(null)
    const [dateRangeFrom, setDateRangeFrom] = useState(null);
    const [dateRangeTo, setDateRangeTo] = useState(null);
    const [allSelected, setAllSelected] = useState(false)

    const csvLink = useRef()
    // console.log(users)
    useEffect(() => {
        if (users && users.length > 0) {
            let arr = []
            for (let i = 0; i < users.length; i++) {
                let t = users[i]
                t.isChecked = false
                arr.push(t)
            }
            setUserList([...arr])
            addCityFilterList()
            filterData()
        }
    }, [users])

    function convertDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("/");
    }

    useEffect(() => {
        let filterList = state
        if (dateRangeTo && dateRangeFrom) {
            filterList[8][0].status = true;
            filterList[8][0].name = convertDate(dateRangeFrom['$d']) + ' To ' + convertDate(dateRangeTo['$d'])
        }
        else {
            filterList[8][0].status = false;
        }
        if (aDateValue) {
            filterList[7][0].status = true;
            filterList[7][0].name = convertDate(aDateValue['$d'])
        }
        else {
            filterList[7][0].status = false;
        }
        setState([...filterList])
        filterData()
    }, [aDateValue, dateRangeTo, dateRangeFrom])

    useEffect(() => {
        if (!state[8][0].status) {
            setIsDateRange(false)
            setDateRangeFrom(null)
            setDateRangeTo(null)
        }
        if (!state[7][0].status) {
            setIsADate(false)
            setADateValue(null)
        }
    }, [state[8][0].status || state[7][0].status])

    useEffect(() => {
        if (tenantId)
            dispatch(getUsers())
    }, [tenantId])

    const handleDelete = (mainIndex, index) => () => {
        let filterList = state
        filterList[mainIndex][index].status = false
        setState([...filterList]);
        filterData()
    };

    const styles = {
        backgroundColor: '#fff',
        backgroundImage: 'none',
        paddingBottom: 50
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addCityFilterList = () => {
        let cityData = []
        let stateData = state
        for (let i = 0; i < users.length; i++) {
            let cData = users[i]
            if (cData.address) {
                let index = cityData.findIndex((city) => city.value === cData?.address?.toLowerCase())
                if (index < 0)
                    cityData.push({
                        filterType: 'Location',
                        value: cData?.address?.toLowerCase(),
                        name: cData?.address?.toUpperCase(),
                        status: false
                    })
            }
        }

        stateData.splice(5, 1, cityData)
        setState(stateData)
    }

    const isWithin = (dateFrom, dateCheck, dateTo) => {
        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");
        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);  // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
        return (check >= from && check <= to)
    }
    const filterData = () => {
        let statusFlag = false
        for (let i = 0; i < state.length; i++) {
            let newState = state[i]
            for (let j = 0; j < newState.length; j++) {
                if (newState[j].status === true) {
                    statusFlag = true
                    break
                }
            }
        }
        if (statusFlag) {
            let filteredData = users
            for (let i = 0; i < state.length; i++) {
                let usersData = []
                let isAny = false
                let newState = state[i]
                let arrayData = []
                for (let j = 0; j < newState.length; j++) {
                    if (newState[j].status) {
                        isAny = true
                        if (i == 0)
                            arrayData = filteredData.filter((u) => u?.access?.toLowerCase() === newState[j].value)
                        else if (i == 1)
                            arrayData = filteredData.filter((u) => u.isActive === newState[j].value)
                        else if (i == 2)
                            arrayData = filteredData.filter((u) => u.group === newState[j].value)
                        else if (i == 3)
                            arrayData = filteredData.filter((u) => u.grade === newState[j].value)
                        else if (i == 5)
                            arrayData = filteredData.filter((u) => u.address?.toLowerCase() === newState[j].value.toLowerCase())
                        else if (i == 6)
                            arrayData = filteredData.filter((u) => u.gender.toLowerCase() === newState[j].value.toLowerCase())
                        else if (i == 7)
                            arrayData = filteredData.filter((u) => {
                                // console.log(moment.utc(u.dateOfJoining.seconds*1000).format('DD/MM/YYYY'))
                                if (!u.dateOfJoining) return true
                                if (moment.unix(u.dateOfJoining.seconds).format('DD/MM/YYYY') == convertDate(aDateValue['$d'])) {
                                    return true
                                }
                            })
                        else if (i == 8)
                            arrayData = filteredData.filter((u) => {
                                if (!u.dateOfJoining) return true
                                return isWithin(convertDate(dateRangeFrom['$d']), moment.unix(u.dateOfJoining.seconds).format('DD/MM/YYYY'), convertDate(dateRangeTo['$d']))
                            })
                        else if (i == 9) {
                            arrayData = filteredData.filter(u => u.name.toLowerCase().includes(newState[0].value.toLowerCase()))
                        }
                    }
                    if (arrayData.length > 0)
                        usersData.push(arrayData)
                }
                if (isAny) {
                    let tp = [].concat.apply([], usersData);
                    filteredData = _.uniqBy(tp, 'id')
                    console.log(i, " = ", filteredData)
                }
            }
            setUserList(filteredData)
        } else {
            setUserList(users)
        }
        setPage(1)
    }

    const handleChange = (event, menuIndex, index) => {
        let filterList = state

        filterList[menuIndex][index].status = event.target.checked

        setState([...filterList]);
        filterData()
    };

    const handleSearchChange = (e) => {
        let filterList = state
        filterList[9][0].status = e.target.value.length && true
        filterList[9][0].value = e.target.value
        filterList[9][0].name = e.target.value
        setState([...filterList])
        filterData()
        setSearch(e.target.value)
    }
    useEffect(() => {
        if (!search.length) {
            setState(prev => {
                prev[9][0].status = false
                return [...prev]
            })
        }
    }, [search.length == 0])

    const onClickArchive = () => {
        let payload = []
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].isChecked) {
                payload.push(userList[i].id)
            }
        }
        if (payload.length) {
            dispatch(archiveUsers(payload))
        }
        else
            dispatch(showErrorSnackbar("Please, select user/s for archive."))
    }

    const handleAddUser = () => {
        setAddUserOpen(true)
    }

    const onClickUpdate = (id) => {
        setUpdateId(id)
        setState([...FilterData])
    }

    const dropdownIsActive = {
        backgroundColor: "#e6fbfb",
        color: "#1e3a71",
        fontWeight: '700'
    }

    const generateCSV = () => {
        let data = []
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].isChecked) {
                const temp = {
                    "User Code": userList[i].userCode,
                    "User Name": userList[i].displayName,
                    Access: userList[i].access,
                    Group: userList[i].group,
                    Designation: userList[i].designation,
                    Grade: userList[i].grade,
                    Section: userList[i].section,
                    "Date Of Joining": userList[i].dateOfJoining,
                    Location: userList[i].address,
                    "Email ID": userList[i].email,
                    Gender: userList[i].gender,
                    "Date Of Birth": userList[i].dateOfBirth,
                    "Phone Number": userList[i].phoneNumber
                }
                data.push(temp)
            }
        }
        setDownloadData([...data])
    };

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false)
        }
        else {
            if (downloadData.length)
                csvLink.current.link.click()
            else
                dispatch(showErrorSnackbar("Please, select user/s To Downlaod."))
        }
    }, [downloadData])

    const onClickActive = () => {
        let payload = []
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].isChecked) {
                payload.push(userList[i].id)
            }
        }
        if (payload.length) {
            dispatch(activeUsers(payload))
        }
        else
            dispatch(showErrorSnackbar("Please, select user/s for active."))
    }
    // console.log(userList)
    const handleDropDown = () => {
        let count = 0;
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].isChecked) {
                count++
            }
        }
        if (count) {
            switch (actionToDo) {
                case 1:
                    break;
                case 10:
                    setOpenMsgBox(true)
                    break
                case 20:
                    generateCSV()
                    break
                case 30:
                    console.log(30)
                    break
                case 40:
                    onClickArchive();
                    break
                case 50:
                    onClickActive();
                    break;
                default:
                    break;
            }
        }
        else {
            dispatch(showErrorSnackbar("Please, Select User/s first"))
        }
    }
    const revertFilter = () => {
        let filterList = state;
        for (let i = 0; i < filterList.length; i++) {
            for (let j = 0; j < filterList[i].length; j++) {
                filterList[i][j].status = false
            }
        }
        setState([...filterList])
        setUserList(users)
        setSearch('')
    }

    return (
        <Main styles={styles}>
            <CSVLink
                data={downloadData}
                filename='data.csv'
                className='hidden'
                ref={csvLink}
                target='_blank'
            />
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                    <Navbar />
                </Grid>
                <Grid container item xs={12} md={12} style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} md={12} style={{
                        backgroundColor: "#1d396f",
                        backgroundImage: 'linear-gradient(to right, darkblue, aqua)'
                    }}
                        className={classes.mainGrid}
                    >
                        <TopBar page={0} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={12} className={classes1.mainContainerboxStyle}
                // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '50px' }}
                >
                    <Grid container item xs={12} md={10} lg={10} style={{ maxHeight: '70px', display: 'flex', justifyContent: 'left' }}>
                        <Grid item xs={12} md={2} style={{ paddingRight: 20, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <Button
                                variant="outlined"
                                style={{
                                    marginRight: '15px',
                                    fontFamily: 'Museo300',
                                    backgroundColor: '#fff',
                                    height: '50px',
                                    size: '14px',
                                    fontWeight: '300',
                                    border: '1px solid gray',
                                    borderRadius: '35px',
                                    color: '#4315A4'
                                }}
                                onClick={handleClickOpen}
                            >
                                <FilterAltIcon sx={{ color: '#00008B' }} />&nbsp;&nbsp; Use List Filter
                            </Button>
                            <span className='revert' onClick={revertFilter}>Revert</span>

                        </Grid>
                        <Grid container item xs={12} sm={12} md={10} lg={10}
                            className={classes1.allUserStylebox}
                        >
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <Typography className={classes1.textStyles} >

                                    All Users ({userList.length})
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} className={classes1.outlineBoxStyles}
                                style={{}}>
                                <FormControl sx={{ width: 'auto' }} variant="outlined" style={{ marginRight: 20 }}>
                                    <OutlinedInput
                                        id="outlined-adornment-weight"
                                        startAdornment={<SearchIcon />}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        placeholder="Search Users"
                                        size="medium"
                                        className={classes1.searchboxStyle}
                                        value={search}
                                        // style={{ height: '50px', borderRadius: '25px', width: '40ch' }}
                                        onChange={handleSearchChange}
                                    />

                                </FormControl>
                                <Button variant="contained"
                                    className={classes1.btnStyle}

                                    onClick={handleAddUser}
                                >
                                    <AddIcon /> &nbsp;&nbsp; Add User
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br />
                    <Grid container item xs={12} md={10} lg={10} className={classes1.mobileStyle} >
                        <Grid item xs={12} md={2} style={{ paddingRight: 20 }}>
                            <Card style={{
                                backgroundColor: '#f5f5f5', height: '30vh', textAlign: 'center', overflowY: 'auto'
                            }}>
                                {
                                    state.map((data, mainIndex) =>
                                        data.map((item, index) =>
                                            item.status === true &&
                                            <ListItem key={item.name} style={{ textAlign: 'center', margin: 'auto', float: 'center' }}>
                                                <Chip
                                                    sx={{ borderRadius: 0 }}
                                                    label={item.filterType + ' : ' + item.name}
                                                    onDelete={handleDelete(mainIndex, index)}
                                                />
                                            </ListItem>
                                        )
                                    )
                                }
                            </Card>
                            <br /><br />
                            <Grid container item xs={12}>
                                <Grid item xs={8} md={11}>
                                    <FormControl style={{ display: 'inline' }}>
                                        <Select
                                            style={{ width: '183px', borderRadius: '20px' }}
                                            value={actionToDo}
                                            onChange={(e) => setActionToDo(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem style={actionToDo === 1 ? dropdownIsActive : { color: '#1e3a71' }} value={1}> Choose...</MenuItem>
                                            <MenuItem style={actionToDo === 10 ? dropdownIsActive : { color: '#1e3a71' }} value={10}>Send a Message</MenuItem>
                                            <MenuItem style={actionToDo === 20 ? dropdownIsActive : { color: '#1e3a71' }} value={20}>Download</MenuItem>
                                            {/* <MenuItem style = {actionToDo === 30?dropdownIsActive:{color:'#1e3a71'}} value={30}>Force Password Change</MenuItem> */}
                                            <MenuItem style={actionToDo === 40 ? dropdownIsActive : { color: '#1e3a71' }} value={40}>Archive</MenuItem>
                                            <MenuItem style={actionToDo === 50 ? dropdownIsActive : { color: '#1e3a71' }} value={50}>Active</MenuItem>
                                        </Select>
                                        <button
                                            style={{
                                                opacity: actionToDo === 1 ? "50%" : "100%",
                                                color: 'white',
                                                borderRadius: '50%',
                                                marginLeft: '5px',
                                                width: '34px',
                                                height: '34px',
                                                backgroundColor: "#4315A4",
                                                cursor: actionToDo === 1 ? 'default' : 'pointer',
                                                outline: 'none',
                                                border: 'none',
                                            }}
                                            disabled={actionToDo === 1}
                                            onClick={handleDropDown}
                                        >
                                            Go
                                        </button>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={10}>
                            {
                                userList.length ?
                                    <UserTable userList={userList} setUserList={setUserList} onClickUpdate={onClickUpdate} limit={limit} page={page} setPage={setPage} setLimit={setLimit} allSelected={allSelected} setAllSelected={setAllSelected} /> :
                                    <Grid>No data available....</Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog style={{
                    padding: '0',
                    width: '100vw',
                    align: 'left',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'flex-start',
                }}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogContent style={{ padding: 0 }}>
                        <Grid container spacing={2} style={{ borderRadius: '10px', display: "flex", justifyContent: 'space-between' }}>
                            <Grid container item xs={4} md={4} lg={4}
                                style={{
                                    // background: 'aqua',
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 0 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(0)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 0 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Access
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 1 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(1)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 1 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        User Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 2 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(2)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 2 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Group
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 3 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(3)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 3 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Grade
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 4 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(4)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 4 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Section
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 5 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(5)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 5 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Location
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 6 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(6)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 6 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Gender
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} md={12}
                                    style={{
                                        backgroundColor: menuIndex === 7 ? '#e6fbfb' : 'white',
                                        padding: '15px 20px', cursor: 'pointer', borderRight: '1px solid #E8E8E8', borderBottom: '1px solid #e8e8e8'
                                    }}
                                    onClick={() => setMenuIndex(7)}
                                >
                                    <Typography variant="h6" style={{ fontFamily: menuIndex === 7 ? 'Museo700' : 'Museo300', fontSize: '1.2rem', color: '#1e3a71' }}>
                                        Date Of Joining
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={8} md={8} lg={8} style={{ display: 'flex', justifyContent: "left", alignItems: 'left', overflowY: 'auto' }}>
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormGroup>
                                        {
                                            (menuIndex == 7) ?
                                                (
                                                    <>
                                                        <Grid>
                                                            <CalendarMonthIcon style={{ display: 'inline' }} /><FormControlLabel
                                                                control={
                                                                    <Checkbox checked={isADate} onChange={() => {
                                                                        setIsADate(!isADate)
                                                                        setADateValue(null)
                                                                    }} name="gilad" style={{ marginLeft: '50px' }} />
                                                                }
                                                                label='Enter A date'
                                                                labelPlacement="start" />
                                                            {
                                                                isADate && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={3}>
                                                                        <DesktopDatePicker
                                                                            label="Select A Date"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            value={aDateValue}
                                                                            onChange={(newValue) => { setADateValue(newValue) }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </Stack>
                                                                </LocalizationProvider>

                                                            }
                                                        </Grid>
                                                        <Grid>
                                                            <CalendarMonthIcon /><FormControlLabel
                                                                control={
                                                                    <Checkbox checked={isDateRange} onChange={() => {
                                                                        setIsDateRange(!isDateRange)
                                                                        setDateRangeFrom(null)
                                                                        setDateRangeTo(null)
                                                                    }} name="gilad" style={{ marginLeft: '50px' }} />
                                                                }
                                                                label='Select A Date Range'
                                                                labelPlacement="start" />
                                                            {
                                                                isDateRange && <Grid>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <Stack spacing={3} >
                                                                            <DesktopDatePicker
                                                                                style={{ marginBottom: '10px' }}
                                                                                label="Start Date"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                value={dateRangeFrom}
                                                                                onChange={(newValue) => { setDateRangeFrom(newValue) }}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                            />
                                                                        </Stack>
                                                                    </LocalizationProvider>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <Stack spacing={3}>
                                                                            <DesktopDatePicker
                                                                                label="End Date"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                value={dateRangeTo}
                                                                                onChange={(newValue) => { setDateRangeTo(newValue) }}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                            />
                                                                        </Stack>
                                                                    </LocalizationProvider>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </>
                                                )
                                                :
                                                state[menuIndex].map((item, index) => {
                                                    return <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox checked={item.status} onChange={(e) => handleChange(e, menuIndex, index)} name="gilad" style={{ marginLeft: '50px' }} />
                                                        }
                                                        label={item.name}
                                                        labelPlacement="start"
                                                    />
                                                }
                                                )
                                        }


                                    </FormGroup>

                                    {/* {
                                        menuIndex === 0 &&

                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={gilad} onChange={handleChange} name="gilad" style={{ marginLeft: '50px' }} />
                                                }
                                                label="SUPER ADMIN"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={jason} onChange={handleChange} name="jason" style={{ marginLeft: '50px' }} />
                                                }
                                                label="MANAGER"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={antoine} onChange={handleChange} name="antoine" style={{ marginLeft: '50px' }} />
                                                }
                                                label="Co-ordinator"
                                                labelPlacement="start"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={antoine} onChange={handleChange} name="antoine" style={{ marginLeft: '50px' }} />
                                                }
                                                label="Reviewer"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={antoine} onChange={handleChange} name="antoine" style={{ marginLeft: '50px' }} />
                                                }
                                                label="Writer"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    } */}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <AddUpdateUser open={addUserOpen} setOpen={setAddUserOpen} userList={userList} coOrdinators={coOrdinators} updateId={updateId} setUpdateId={setUpdateId} />
                <Grid>
                    <MessageBox totalUser={users.length} userList={userList} open={openMsgBox} setOpen={setOpenMsgBox} />
                </Grid>
            </Grid>

        </Main>
    );
}

export default BulkUserActions;
