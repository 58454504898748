import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getOpenedGallery, getOpenedUsersImages } from '../../../store/actions/images'
import { getPostCard, getReceivedPostCards, getSentPostCards, setReceivedPost } from "../../../store/actions/postCards";
import { useHistory } from "react-router-dom";
import PostCardStyles from '../PostCardStyles';


function PostcardDetails(props) {
  const classes = PostCardStyles();
  const history = useHistory();
  const [value, setValue] = useState("1");
  const dispatch = useDispatch();


  const auth = useSelector(state => state.firebase.auth);
  const { gallery, usersImages } = useSelector(state => state.images);
  const [images, SetImages] = useState([])
  const [gotImages, updateGotImages] = useState(0)

  useEffect(() => {
    try {
      let uid = auth?.uid
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages))
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages))
    } catch (e) {

    }
  }, []);

  useEffect(() => {
    var imagesArray = []
    if (usersImages.length > 0) {
      imagesArray = imagesArray.concat(usersImages)

    }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery)
    }
    SetImages(imagesArray);
  }, [gotImages, usersImages, props.images]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { received, sent } = useSelector(state => state.postcards);
  //  

  useEffect(() => {
    if (auth && auth.uid) {
      dispatch(getReceivedPostCards(auth))
      dispatch(getSentPostCards(auth))
    }

  }, [auth])
  // Start Received Preview code//
  const previewCard = (index, card, flag) => {
    let payload
    if (flag.sent === true) {
      payload = {
        content: card.content,
        from: [...card.to],
        image: card.image,
        name: card.name,
        date: card.date,
        sent: true,
        profileImage:card?.profileImage
      }
    } else {
      payload = {
        content: card.content,
        from: card.from,
        image: card.image,
        name: card.name,
        date: card.date,
        rec: true,
        profileImage:card?.profileImage
      }
    }
    dispatch(setReceivedPost(payload));
    history.push('/postcard-received');
  }

  // End Received Preview code//

  return (

    <Grid container >
      <Grid container spacing={1}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div >
            <Box className={classes.ReceivedBox} >
              <Box
                style={{
                  display: "flex",
                  // position: "absolute",
                  width: "260px",
                  textAlign: "center",
                  justifyContent: "space-between",
                  //   margin: "auto",
                  //   marginRight: "20px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  textAlign="center"
                  component="div"
                  fontFamily="Museo700"
                  color="#1E3A71"
                >
                  Received
                </Typography>
              </Box>

              <Grid item xs={12} sm={12} md={6}
                className={classes.scrollBox}
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, }} >
                {
                  received && received.length > 0 ?
                    received.map((card, index) =>
                      <Grid item xs={6} style={{ marginBottom: 10 }}>
                        <Box style={{ padding: 0 }}>
                          <img
                            component="img"
                            // height="140"
                            src={card.image}
                            alt="green iguana"
                            className={classes.cardImgReceived}
                            onClick={() => previewCard(index, card, { rec: true })} />
                        </Box>
                      </Grid>

                    ) : null
                }
              </Grid>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div className={classes.mainContentDownRight} >
            <Box className={classes.sendBox}

            >
              <Box
                style={{
                  display: "flex",
                  // position: "absolute",
                  width: "220px",
                  textAlign: "center",
                  justifyContent: "space-between",
                  //   margin: "auto",
                  textAlign: 'left'
                  // marginRight: "20px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  textAlign="center"
                  component="div"
                  fontFamily="Museo700"
                  color="#1E3A71"
                >
                  Sent
                </Typography>
              </Box>

              <Grid item xs={12} sm={12} md={6} className={classes.scrollBox}
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, }}
              >
                {
                  sent && sent.length > 0 ?
                    sent.map((card, index) =>
                      <Grid item xs={6} style={{ marginBottom: 10 }}>
                        <Box style={{ padding: 0 }}>
                          <img
                            component="img"
                            // height="140"
                            src={card.image}
                            alt="green iguana"
                            className={classes.cardImgReceived}
                            onClick={() => previewCard(index, card, { sent: true })}
                          />

                        </Box>
                      </Grid>

                    ) : null
                }

                {/* <Grid item xs={6}>
                                <Box style={{ padding: 0 }}>
                                    <img
                                        component="img"
                                        // height="140"
                                        src={Post3}
                                        alt="green iguana"
                                        className={classes.cardImg1}
                                    />
                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                <Box style={{ padding: 0 }}>
                                    <img
                                        component="img"
                                        // height="140"
                                        src={Post3}
                                        alt="green iguana"
                                        className={classes.cardImg1}
                                    />
                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                <Box style={{ padding: 0 }}>
                                    <img
                                        component="img"
                                        // height="140"
                                        src={Post3}
                                        alt="green iguana"
                                        className={classes.cardImg1}
                                    />
                                    
                                    </Box>
                                </Grid> */}
              </Grid>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PostcardDetails;