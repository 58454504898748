import React, { useEffect } from "react";
import DiaryStyles from "./DiaryStyles";
import Happy from "../../assets/images/Happy.png";
import Neutral from "../../assets/images/Neutral.png";
import Sad from "../../assets/images/Sad.png";
import VeryHappy from "../../assets/images/VeryHappy.png";
import { Grid, Typography, Card, Slider, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { getColorChange } from "../../store/actions/diary";
import { useState } from "react";
function DiaryMoodSection(props) {
  const dispatch = useDispatch();
  dispatch(getColorChange());
  const classes = DiaryStyles();
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }
  const [colorValue, setColoValue] = useState("#DBDBDB");
  const [opacityValue, setOpacityValue] = useState(0.5);
  const handleMoodChange = (value, index) => {
    console.log(value, 'mmood')
    setColoValue(value);
    props.setMoodValue(index);
    props.setColor(value);

  };
  // useEffect(() => {
  //   dispatch(getColorChange(colorValue));
  // }, [colorValue]);
  return (
    <Box
      sx={{
        width: {
          xs: '60px',
          sm: '96px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        height: '228px',
        backgroundColor: `${colorValue || '#ECECED'}${Math.round(opacityValue * 255).toString(16)}`,
        fontFamily: 'Museo700',
        borderRadius: '5px 0px 0px 5px',
      }}
    >
      <Grid container direction="column" className={classes.lightColor}>
        <Box className={classes.moodIconContainer}>
          <img
            src={VeryHappy}
            className={classes.moodStyling}
            position="absolute"
            backgroundPosition="center"
            backgroundSize="cover"
            onClick={() => handleMoodChange("#73D1F3", 4)}
            // style={{ marginTop: "0.2rem", marginBottom: "-15px" }}
            alt=""
          />
        </Box>
        <Box className={classes.moodIconContainer}>
          <img
            src={Happy}
            className={classes.moodStyling}
            position="absolute"
            backgroundPosition="center"
            backgroundSize="cover"
            onClick={() => handleMoodChange("#8FF57E", 3)}
            // style={{ marginBottom: "-15px" }}
            alt=""
          />
        </Box>
        <Box className={classes.moodIconContainer}>
          <img
            src={Neutral}
            className={classes.moodStyling}
            position="absolute"
            backgroundPosition="center"
            backgroundSize="cover"
            onClick={() => handleMoodChange("#F1D896", 2)}
            // style={{ marginBottom: "-15px" }}
            alt=""
          />
        </Box>
        <Box className={classes.moodIconContainer}>
          <img
            src={Sad}
            className={classes.moodStyling}
            position="absolute"
            backgroundPosition="center"
            backgroundSize="cover"
            onClick={() => handleMoodChange("#F76262", 1)}
            // style={{width:'50%',height:'50%'}}
            alt=""
          />
        </Box>
      </Grid>
    </Box>
  );
}

export default DiaryMoodSection;