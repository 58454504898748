import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import './App.css';
import theme from "./AppTheme";
import { AuthProvider } from './Auth'
import SnackbarMain from "./components/SnackbarMain";
import Routes from "./Routes";
import "./assets/fonts/Museo300-Regular.otf";
import "./assets/fonts/Museo700-Regular.otf";
import { addUserWithDoc, getInstitute, instituteLogin, setInstitute, setTenantId, setUser, testAddData, testSignup } from "./store/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { isLoaded, isEmpty } from "react-redux-firebase";

import Header from "./components/Header/Header";
import { setSuperAdminPermissions, setUserPermissions } from "./store/actions/permissions";
import { getCounts } from "./store/actions/siteInsights";

const getUser = async (userId, firestore) => {
  let authorDetails = "";
  const doc = await firestore.collection('users').doc(userId).get();
  if (!doc.exists) {

  } else {
    authorDetails = {
      ...doc.data(),
      id: doc.id
    }
  }
  return authorDetails;
}
function App() {
  const dispatch = useDispatch()
  const { user, institute } = useSelector(state => state.user)
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);
  const isAuthenticated = isLoaded(auth) && !isEmpty(auth);

  useEffect(() => {
    async function getData() {
      let user = await getUser(auth.uid, firestore)
      dispatch(setUser(user))
    }
    if (isAuthenticated)
      getData()
  }, [isAuthenticated])

  useEffect(() => {
    if (user && user.tenantId) {
      dispatch(setTenantId(user.tenantId))

      if (!institute)
        dispatch(getInstitute(user.tenantId))

      if (user.access === 'super_admin')
        dispatch(setSuperAdminPermissions())
      else
        dispatch(setUserPermissions(user.access))

      dispatch(getCounts())
    }
  }, [user])

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarMain />
        <Router>
          {!isAuthenticated && <Header />}
          <Routes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

