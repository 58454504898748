import { Typography, Grid, Box, table } from "@mui/material";
import React, { } from "react";
import PrivacyPolicyStyles from "./PrivacyPolicyStyles.js";
import Footer from "../../Footer";

const PrivacyPolicy = props => {
    const classes = PrivacyPolicyStyles();
    return (
        <>
         <div className={classes.bg} style={{ backgroundColor: '#fff' }}>
            <div className={classes.root} >
                <Grid container spacing={2}>
                    <Box>
                        <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            Privacy Policy
                        </Typography>
                        <br />
                        <Typography component="p" >
                            We are thrilled that you are interested in using  {' '}
                            <a href="https://quillatwill.com/">quillatwill.com</a>
                            {' '}, a wonderful site for inspiring writers to find inspiration and write. This policy explains the privacy rights of  <a href="https://quillatwill.com/">quillatwill.com</a> ("us", "we", "our" and terms of similar meaning) members and explains what we do with the information you give us.
                            <br />
                            <br />
                            By accepting the Privacy Policy during registration or by visiting and using the Site, you expressly consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy.
                            In this Privacy Policy, we use the term "Member" to describe a registered user of the Site. Together with members, people who browse our site without becoming Members are called "Users".

                        </Typography>
                        <br /><br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            What Information do we collect and how do we collect it:
                        </Typography>

                        <Typography component="p">
                            When any user creates an account on  <a href="https://quillatwill.com/">quillatwill.com</a>, we ask them to select a username, password, and provide an email address. Once they have created their account, they can start using  <a href="https://quillatwill.com/">quillatwill.com</a> straight away with no additional information needed. Completing their profile involves adding a profile picture and a few details about the user.
                            <br />
                            <br />
                            We collect information relating to demographics and interests of the user to provide them with a more personalised experience and develop website features that the user is likely to enjoy. Furthermore, we collect information on the types of interests that the members may create on the  <a href="https://quillatwill.com/">quillatwill.com</a> website. This is very important because we want to avoid spam and make sure no fake member accounts are created using the same IP address.


                        </Typography>
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Google Analytics
                        </Typography>
                        <br />
                        <Typography component="p">
                            Our website uses Google Analytics. This tool helps us to record information on how many users we get in a month, weekly, and so on. It also allows us to determine the most popular pages on our website, how long a user spends on our site, and where most of our users are based in the world.
                            <br /> This information is generated by a “Cookie” (more details in the next section) and also collects a user's "IP address" (this is not visible to anyone apart from Google). Google may share this data with a third-party where required by law. Further information about Google's privacy policy may be obtained from https://policies.google.com/privacy.

                        </Typography>
                        <br />

                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Advertisements
                        </Typography>
                        <Typography component="p">
                            <a href="https://quillatwill.com/">quillatwill.com</a> uses advertisements to support the costs of operating the website, such as hosting, servers and domain renewal.
                            Throughout the site, you may notice third-party advertisements or promotional banners. We may use Google AdSense, which is a tool that allows website publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google uses cookies and tracking technology to deliver ads personalised for a website user/visitor.
                            <br />
                            These technologies gather personal information from users and their devices, including information about IP addresses and user location, as well as behavioural information such as websites visited, products viewed or purchased, and other personal preferences.
                            <br />
                            Please note that third-party vendors, including Google, use cookies to serve ads based on a user's previous visits to your website or other websites. This means that cookies can be placed on the user's device from outside the  <a href="https://quillatwill.com/">quillatwill.com</a> website, and when they visit another website after  <a href="https://quillatwill.com/">quillatwill.com</a> that cookie will continue to be tracked. Please see the privacy policies for each website you visit for details on how they process cookies, and user data.

                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            About Cookies
                        </Typography>

                        <Typography component="p">
                            Cookies are small files delivered to your computer that assist us in providing our services. Cookies will be used on the Site to allow you to enter your password less frequently during a session, to help us with data collection on the Site to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety, to offer certain features that are only available through the use of a cookie, and to allow us to provide information that is targeted to your interests. Cookies are very commonly used on the internet, and if you have cookies disabled in your browser, you may not be able to use the Site properly.
                            You can also refer to our Cookies policy.

                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            What does  <a href="https://quillatwill.com/">quillatwill.com</a> do with the information?
                        </Typography>

                        <Typography component="p">
                            Cookies are small files delivered to your computer that assist us in providing our services. Cookies will be used on the Site to allow you to enter your password less frequently during a session, to help us with data collection on the Site to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety, to offer certain features that are only available through the use of a cookie, and to allow us to provide information that is targeted to your interests. Cookies are very commonly used on the internet, and if you have cookies disabled in your browser, you may not be able to use the Site properly.
                            You can also refer to our Cookies policy.Our primary purpose in collecting personal information from you is to provide functionality and features that improve your experience, and make our service safer and easier to use. We do our best to only collect personal information about you that we consider necessary.
                            <br /><br />

                            <a href="https://quillatwill.com/">quillatwill.com</a> uses the email address provided at registration to notify members that their account on  <a href="https://quillatwill.com/">quillatwill.com</a> has been created and later on to notify members of any activity they are missing out on. This includes “we miss you” emails if the member has been inactive for 30 days, and by default, notification emails weekly with a summary of missed activity on their account. We also ask users if they would be interested in receiving our newsletters. If they select yes, then we add their email and other details provided to our monthly newsletter mailing list on our email system.
                            <br/>
                            <br />
                            All members registered on  <a href="https://quillatwill.com/">quillatwill.com</a> will be added to the email system by default when they complete registration. We do this so we can send any important emails regarding  <a href="https://quillatwill.com/">quillatwill.com</a> . For example, any changes to our privacy policy or terms and conditions in the near future.
                            <br />
                            Upon activation of an account, the information submitted by the registered user will be used by  <a href="https://quillatwill.com/">quillatwill.com</a> to allow the registered user to participate in the  <a href="https://quillatwill.com/">quillatwill.com</a> website, such as writing and sharing blogs, books, essays, comics, postcards, and also adding friends to their friends list. Throughout the site, the user is identified by their registered username, accompanied by a picture of their avatar. Both the username and avatar picture will be displayed to other users.
                            We also use the information that you give us (including your contact information such as email address) to make our services the best possible experience for you. For instance, we may use your information to:
                        </Typography>
                        <br />

                        <Typography component="p">
                            <ul>
                                <li>Improve our service or website.</li>
                                <li>Manage your account and your queries.</li>
                                <li>Carry out market research and quality assurance.</li>
                                <li>Contact you with important service announcements.</li>
                                <li>Customise the information you receive from us.</li>
                            </ul>

                            If you do not want your data to be used in any of the ways mentioned above, please email us (support@ <a href="https://quillatwill.com/">quillatwill.com</a>) your request and we will process it within one month of receiving the request.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            How long will the data be stored ?
                        </Typography>

                        <Typography component="p">
                            Any data collected on  <a href="https://quillatwill.com/">quillatwill.com</a> members will be kept in our database for 12 months. If the member does not engage with  <a href="https://quillatwill.com/">quillatwill.com</a> in this time, we will permanently delete all their data after 12 months from all our databases. By "engage", we mean opening any email, clicking on links in emails and logging into the site.
                            <br />
                            Before deleting their data, we will send out a couple of emails to warn the member that they will be removed from our database if they do not act now. After the member has been removed from our databases, they are free to sign-up to  <a href="https://quillatwill.com/">quillatwill.com</a> again, as a new member.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Links to third party websites:
                        </Typography>

                        <Typography component="p">
                            The Site may include links to other websites or resources over which  <a href="https://quillatwill.com/">quillatwill.com</a> does not have control ("External Web Sites"). Even though we do not intend to include any external website links, there may be some links included in the blog section of our website. Such links do not establish an endorsement by  <a href="https://quillatwill.com/">quillatwill.com</a> of those external web sites. You acknowledge that  <a href="https://quillatwill.com/">quillatwill.com</a> is providing these links to you only as a convenience, and further agree that  <a href="https://quillatwill.com/">quillatwill.com</a> is not responsible for the content of such External Web Sites. Your use of External Web Sites is subject to the terms of use and privacy policies located on the External Web Sites.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Information sharing and disclosure:
                        </Typography>

                        <Typography component="p">
                            <a href="https://quillatwill.com/">quillatwill.com</a> does not share, rent, trade or sell personally identifiable information about our members, with third parties for their promotional purposes.  <a href="https://quillatwill.com/">quillatwill.com</a> makes every reasonable effort to disallow and discourage members from sharing personally identifying information through website features and the friend system. The only information that can be openly shared is usernames and the member's avatar. The content of the Diary shall not be shared with other users. However, comments made on the  <a href="https://quillatwill.com/">quillatwill.com</a> blog will be public to all users and visitors.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Use of third-party plugins and apps:

                        </Typography>

                        <Typography component="p">
                            <a href="https://quillatwill.com/">quillatwill.com</a> aims to avoid using unnecessary third-party plugins and apps, unless for security purposes or to offer certain features to both members and users.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Blog disclosure:
                        </Typography>

                        <Typography component="p">
                            If you use the blog on this website, you should be aware that any personally identifiable information you submit there can be read, collected, or used by other users of this blog, and could be used to send you unsolicited messages. Though we moderate the blog comments, we are not responsible for the personally identifiable information you choose to submit on the  <a href="https://quillatwill.com/">quillatwill.com</a>  blog.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Social Media:
                        </Typography>

                        <Typography component="p">
                            Communication, engagement and actions taken through external social media platforms that this website and its owners participate on are custom to the terms and conditions as well as the privacy policies held with each social media platform respectively.<br />
                            Users are advised to use social media platforms wisely and communicate/ engage upon them with due care and caution with regard to their own privacy and personal details. This website nor its owners will ever ask for personal or sensitive information through social media platforms and encourage users wishing to discuss sensitive details to contact them through email.
                            <br />
                            We sometimes will share member stories from our library with their username on social media with a direct link to the story for everyone to read. We will never share anything else relating to the members.

                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Guest bloggers:
                        </Typography>

                        <Typography component="p">
                            The  <a href="https://quillatwill.com/">quillatwill.com</a> blog is open to guest post submissions all year round. Any data provided, such as full name, email address, bio, website link, and social media profiles, will be posted on our blog to credit the guest author who wrote the guest post. If your guest post is not successful or not posted live on our blog, we will not keep any data provided unless your guest post is successful.
                            <br />
                            This data will also be kept in our email provider's database for a period of 12 months and used to contact guest bloggers about any guest blogging opportunities regularly.

                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Opting out of our communications:
                        </Typography>

                        <Typography component="p">
                            If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by sending an email to support@ <a href="https://quillatwill.com/">quillatwill.com</a>, with the word 'UNSUBSCRIBE' in the subject line to opt out of all  <a href="quillatwill.com">quillatwill.com</a> email communications.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Confidentiality and security:
                        </Typography>

                        <Typography component="p">
                            Information a member submits to  <a href="https://quillatwill.com/">quillatwill.com</a> that relates to his or her account is password-protected. No employee of  <a href="https://quillatwill.com/">quillatwill.com</a> nor any automated system will contact a member by email asking for a member's login details, password or any other personal information related or unrelated to  <a href="https://quillatwill.com/">quillatwill.com</a>. If any message claiming or appearing to come from  <a href="https://quillatwill.com/">quillatwill.com</a> is sent to you asking for such information, please report it immediately to support@quillatwill.com.
                            <br />
                            If there is a personal data breach,  <a href="https://quillatwill.com/">quillatwill.com</a> will notify you within 72 hours of the breach occurring. A personal breach refers to the accidental, destruction, loss, alteration, unauthorised sharing of, or access to, personal data. If in case the security breach has a high impact on the user's or member's rights or freedom we will let the individual/s involved know as soon as possible. In all cases, we will keep a record of all data breaches identified. If you have any questions about security on our website, you can email us at <a href="support@quillatwill.com">support@quillatwill.com</a>.

                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Requests for information:
                        </Typography>

                        <Typography component="p">
                            At any time, members of the site can request a copy of all the data we have on them. Any requests for information will be responded to within a month. If we need more time to respond to your request, we will let you know within a month of the request’s being made and the reason why we need more time. All requests for information will be free. However, if the request for information is complex and excessive, we may refuse to accept the request. We would be grateful if you would contact us first if you do have a complaint so that we can try to resolve it for you.
                            <br />
                            If you would like to make a request, please either email us at: <a href="support@quillatwill.com"> support@quillatwill.com </a>  or <a href="connect@quillatwill.com">connect@quillatwill.com </a> .
                            If you feel the data we have is inaccurate or requires updating, please send us an email to  <a href="support@quillatwill.com">support@quillatwill.com </a>  and we will update your records within one month of receiving the email. If you keep on requesting changes to your data or if your request is excessive, we have the right to refuse your request and will send you an email within a month explaining why it has been refused.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Requests to delete data:
                        </Typography>

                        <Typography component="p">
                            Members of  <a href="https://quillatwill.com/">quillatwill.com</a> can delete all the data we have on them by sending us an email, requesting to be deleted from  <a href="https://quillatwill.com/">quillatwill.com</a>. Once your account has been deleted, you will receive one final "Goodbye" email from us to confirm that all the data we have on you will be deleted. Once this request is made, we will delete all the data within one month, and you will no longer be able to login into your account. At any time, you can sign-up and become a site member again.
                            <br />
                            For deleting your account by email, please send your requests to: <a href="support@quillatwill.com">support@quillatwill.com</a>.

                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Changes to this Privacy Policy:
                        </Typography>

                        <Typography component="p">
                            As  <a href="https://quillatwill.com/"> quillatwill.com!</a> is a fairly new business, we plan to add many features that will keep our members stimulated and interested in the website. These changes may alter the practises of  <a href="https://quillatwill.com/">quillatwill.com</a> from time to time. If we decide to change our privacy policy, we will post those changes to this privacy statement, send you an email with a link to the updated policy, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Complaints:
                        </Typography>

                        <Typography component="p">
                            If you have any complaints about the way we use your data or the communications you are receiving from  <a href="https://quillatwill.com/">quillatwill.com</a>.
                            <br />
                            Please email us using the subject line “COMPLAINT” to  <a href="support@quillatwill.com">support@quillatwill.com</a>.

                        </Typography>
                        <br />

                        <br />
                        <Typography variant="h5" component="h1" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            Questions and Suggestions:
                        </Typography>

                        <Typography component="p">
                            If you have questions or suggestions, please email us at support@ <a href="https://quillatwill.com/">quillatwill.com</a>
                            <br />
                            This Privacy Policy is effective as of 24th Dec 2021.

                        </Typography>
                        <br />
                        <br />
                        <br />
                    </Box>
                </Grid>

            </div>
        </div>
        <Footer />
        </>
       
    );
};

export default PrivacyPolicy;
