import { actionTypes } from '../actions';

export const INITIAL_STATE = {
	complaints: [],
	userComplaints: []
};

export const complaints = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.GET_COMPLAINTS:
			return {
				...state,
				complaints: payload
			}
		case actionTypes.GET_USER_COMPLAINTS:
			return {
				...state,
				userComplaints: payload
			}

		default:
			return state;
	}
};


