import { makeStyles } from "@mui/styles";

const HeaderStyle = makeStyles((theme) => ({
  navbar: {
     background: '#442AA9 !important', 
    //  background:'linear-gradient(0deg, #1d396f, #1d396f)',
    // backgroundColor: "transparent",
    // height: "80px",
    display: "flex",
    // alignItems: "center",
    fontFamily: "Museo300",
    fontSize: "1.2rem",
    position: "fixed",
    zIndex: 999,
    padding: "0 6rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  root: {
    "&:hover": {
      // background: "transparent",
      background: "#fff !important",
    },
  },

  root1: {
    "&:hover": {
      // background: "transparent",
      background: "#FFDB00 !important",
    },
  },
}));

export default HeaderStyle;
