import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Paper, Select, MenuItem, TextField } from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, updateUserRole } from "../../store/actions/user";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { showErrorSnackbar } from "../../store/actions/snackbar";

const TopBarStyles = makeStyles((theme) => ({
    mainGrid: {
        '& .css-1cndkqf-MuiToolbar-root': {
            minHeight: '40px',
            height: '70px'
        }
    },
}));


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
    marginTop: 10
}));


const AssignRoles = (props) => {
    const classes = TopBarStyles()
    const [role, setRole] = useState('')
    const { users, tenantId } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [chipUserData, setChipUserData] = useState([]);

    const handleDelete = (userToDelete) => () => {
        setChipUserData((users) => users.filter((user) => user.id !== userToDelete.id));
    };

    useEffect(() => {
        if (tenantId)
            dispatch(getUsers())
    }, [tenantId])

    const handleChange = (event) => {
        setRole(event.target.value);
    };

    const handleOnSelectUser = (value) => {
        let userArray = [...chipUserData]
        let index = users.findIndex((u) => u.name === value)

        if (index > -1) {
            userArray.push(users[index])
        }
        setChipUserData(userArray)
    }

    const updateUserRoles = () => {
        let payload = {}

        if (role) {
            if (chipUserData && chipUserData.length > 0) {
                let userIds = []
                for (let i = 0; i < chipUserData.length; i++) {
                    let { id } = chipUserData[i]
                    userIds.push(id)
                }
                payload = {
                    userIds,
                    role
                }
                dispatch(updateUserRole(payload))
                setRole(null)
                setChipUserData([])
            } else {
                alert("Please, user/s")
                dispatch(showErrorSnackbar('Please, user/s'))
            }
        } else {
            alert("Please, select role")
            dispatch(showErrorSnackbar('Please, select role'))
        }

    }

    return (
        <Grid container>
            <Grid container item xs={12} md={12}>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth variant="outlined" style={{ marginRight: 20 }}>
                        <Select
                            id="demo-simple-select"
                            value={role}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={role !== null ? undefined : () => "Choose A Role"}
                            IconComponent={() => <KeyboardArrowDownIcon style={{ marginRight: '20px' }} />}
                            style={{ borderRadius: '25px', width: '70%', paddingLeft: '20px', fontWeight: 'bolder', color: '#747474' }}
                        >
                            <MenuItem value={'super_admin'}>Super Admin</MenuItem>
                            <MenuItem value={'manager'}>Manager</MenuItem>
                            <MenuItem value={'co_ordinator'}>Co-Ordinator</MenuItem>
                            <MenuItem value={'reviewer'}>Reviewer</MenuItem>
                            <MenuItem value={'writer'}>Writer/User</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={9} lg={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <FormControl sx={{ width: 'auto', borderRadius: '50px' }} variant="outlined" style={{ marginRight: 20 }}>
                        <Autocomplete
                            clearOnBlur={true}
                            freeSolo
                            id="free-solo-2-demo"
                            // disableClearable
                            options={users.map((user) => user.name)}
                            style={{ width: '100%', borderRadius: '50px' }}
                            onChange={(e, newValue) => handleOnSelectUser(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search user"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    style={{ width: '350px', borderRadius: '50px' }}
                                />
                            )}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        style={{
                            color: '#000', fontSize: '20px',
                            fontFamily: 'Museo700', backgroundColor: '#f5f5f5',
                            height: '50px', borderRadius: '10px'
                        }}
                        onClick={() => updateUserRoles()}
                    >
                        {/* <AddIcon /> &nbsp;&nbsp; */}
                        Update User
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={12} style={{ marginTop: '2vh' }}>
                <Box style={{
                    height: '60vh',
                    width: '100%', border: '1px solid #747474',
                    backgroundColor: '#f5f5f5', borderRadius: '10px'
                }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}
                    component="ul"
                >
                    {chipUserData.map((data) =>
                        <ListItem key={data.id}>
                            <Chip
                                label={data.name}
                                onDelete={handleDelete(data)}
                            />
                        </ListItem>
                    )}
                </Box>
            </Grid>
        </Grid>

    );
}

export default AssignRoles;
