import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import PoetryImg from "../../../assets/images/poetryImg.jpg";
import { Helmet } from "react-helmet";
import SecretDiaryStyles from "../SecretDiaryPage/SecretDiaryStyles";
import Footer from "../../Footer";

function Poetry() {
    const classes = SecretDiaryStyles();

    return (
        <>
            <Box
                style={{
                    background: "#fff",
                }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Online Diary | Online Diary Writing | Quill at Will</title>
                    <meta
                        name="title"
                        content="Online Diary | Online Diary Writing | Quill at Will"
                    />
                    <meta
                        name="description"
                        content="Online Diary - Quill at Will is the secure place to record your day-to-day experience, feelings & thoughts. Let's Create your own free e-diary or journal and get started now!"
                    />
                    <meta
                        name="keywords"
                        content="Blog Writing Platform"
                    />
                </Helmet>

                <Grid container style={{
                    display: 'flex', justifyContent: "center", marginBottom: "3rem"
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{
                        borderRadius: '20px', boxShadow: "0px 0px 1px 1px",
                        padding: '20px'
                    }}>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontWeight: "bold", color: "#101070", fontSize: "30px", fontFamily: "Museo 700", textAlign: "left" }}
                        >
                            POETRY- INDIA AND THE WEST
                        </Typography>

                        <Typography
                            className={classes.headingTypo}
                            style={{ fontSize: "20px", }}
                        >
                            A detailed comparative study of the origins of poetry in different parts of the world.  <br />
                        </Typography>
                        <Grid container spacing={3} stype={{ marginTop: '1rem' }}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <img src={PoetryImg} alt="" style={{
                                        width: "100%",
                                        height: "60vh",
                                    }} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                                <Grid container >
                                    <Box >
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "16px" }}
                                        >
                                            Poetry is one of the most ancient modes of creative expression that there is in this world. It’s so fascinating that despite the dazzling variety of poems that exist across cultures, the motivations, sentiments, emotions, and feelings remain the same. Every culture has its own principles, modes, and formats of poetry writing that have evolved over the ages. India perhaps has the oldest poems in the world- the Vedas! No one has yet arrived at an accurate date as to when they were composed, but they are definitely more than 5000 years old. The Rig Veda is considered to be the oldest of the four collections. Rig comes from the word "rig", which actually means a verse with a metre.
                                            <br />
                                            <br />
                                            English poetry, of course, is very popular, mainly because of the colonial presence of the English in so many countries in the last few centuries. The oldest English poetry can be dated back to the period between 600 to 1000 A.D. The language is almost unrecognisable! Beowolf, an epic saga, considered to be one of the most important poems of the English language can be dated to this period.

                                            <br />
                                            Middle English period is approximately between the 11th century until Renaissance, the beginning of which saw the Norman conquest that eventually had an impact on the language and culture of the people too. The most famous poem of this period is the Canterbury Tales by Chaucer, which was written after the fashion of chivalric romances popular at the time. It is a narrative poem of about 24 stories, covering over 17,000 lines!

                                        </Typography>
                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "16px" }}
                                        >
                                            The Middle English period was followed by the Renaissance period between 16th to the 18th centuries which saw the emergence of the weighty Elizabethan poets like Shakespeare, Edmund Spenser alongside religious poets like John Milton and Metaphysical poets like John Donne. This was the period that saw the birth of the sonnet.

                                        </Typography>

                                        <Typography
                                            className={classes.headingTypo1}
                                            style={{ fontSize: "16px" }}>
                                            The 18th century had on the one hand, great satirists like Alexander Pope and Jonathan Swift, on the other it saw the emergence of one of the most popular literary movements of the time called the Romantic Period or the Romantic poets. Surely, you all would have heard of William Wordsworth, Lord Byron, John Keats. Percy Bysshe Shelley, Samuel Coleridge and others. The themes that dominated the poet’s panoramic vision were mainly nature and the authentic emotional response to it. This period saw a collective moving away from the traditional rules of rhyme and meter to experimentation with form and style.
                                            <br />
                                            <br />
                                            The Victorian period of the 19th and early 20th century saw a blend of romantic themes with symbolism that was evident in the works of Lord Alfred Tennyson, Robert Browning, Mathew Arnold and others. The rise of the industrial revolution also brought up its attendant philosophical questions about life and religion. Modern poetry took root after the grim conclusion of the first world war, the one definitive event in history that changed the way people of the era viewed life, forever. Poets like Ezra Pound and T. S. Eliot dominated the landscape in this period, with the mood and style of poetry writing changing dramatically to suit the times.
                                            <br />
                                            <br />
                                            The history of poetry in the Indian subcontinent is just as vast, deep and diverse as the land itself because of the bewildering number of languages and dialects etc. No wonder then, that the history of poetry in Indian languages cannot be discussed in one article alone. Sanskrit poetry as mentioned earlier, dates back to early vedic poetry but as ancient as the language is, just as vast and deep is its prevalence in the world of Indian literature. In fact, Valmiki’s Ramayana is considered to be the world’s first ‘loka-poetry’ or world poetry, and is believed to have greatly impacted the latter day poets. The first epic poem of its kind, it is believed to have been composed in 500 BC, but the exact date is contested by many scholars and historians. The second such epic is of course, the Mahabharata by Krishanadwaipayana Vyasa.

                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box>

                                    <Typography
                                        className={classes.headingTypo1}
                                        style={{ fontSize: "16px" }}>
                                        The contribution of Sanskrit poetry, along with other languages of the period like Prakrit, is immense and incalculable. Poets like Kalidasa, Ashvaghosa contributed considerably during the Gupta period. Bharavi, Bhatti, Kumardasa, and Magha were renowned writers of Mahakavyas. Harishena and Vatsabhatti were also prominent writers. Other prominent classical writers were Kalhana and Bilhana, who wrote historical kavyas. Bhartrihari, Amaruka, Bilhana, Jayadeva, Somadeva, etc. were famous lyric poets. The Brihatkatha, romantic and didactic fables, champu kavyas, works on poetics and anthologies, gnomic and didactic poetry etc. are all the many genres that have been covered by Sanskrit literature through time.

                                        <br />
                                        <br />
                                        In the rest of the country, there was phenomenal work being produced in other languages too as they evolved over centuries- Tamil, Telugu, Bengali, Marathi, Hindi etc. The Bhakti period spanned several centuries and saw the likes of spiritual evocative but simple poetic works by saint composers like Tulsidas, Surdas, Mirabai, Ramdas, etc. By the 20th century, the landscape of Indian poetry was enriched further by the addition of a long line of distinguished Indian poets writing in English like Sarojini Naidu, Toru Dutt, Kamala Das, Aurobindo Ghose etc.
                                        <br />

                                        <br />
                                        The West however has been greatly influenced by Greek and Roman writers and poets. Many English poets like Shakespeare too, were fond of turning to what they called the ‘classical poets’ for inspiration. Some of the most influential Greek poets are widely acknowledged to be Homer whose Iliad and Odyssey are considered to be iconic epic poems much in the manner of Valmiki’s Ramayana. In fact, the two epic poems are often compared for their similarities.
                                        <br />
                                        <br />
                                        It is obvious isn’t it, that poetry is the oldest form of creative expression in the world? There have been so many great minds that have contributed to the poetic wealth of the world through the ages and continue to do so. You can be a part of this time-honoured world too, all you need is a gift of words, original vision and a unique voice to express yourself.

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Poetry;