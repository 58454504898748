import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useSelector(state => state.firebase.auth);
    const isAuthenticated = isLoaded(auth) && !isEmpty(auth);

    return (
        <Route
            {...rest}
            render={(props) => {

                if (isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{ pathname: "/", state: { from: props.location } }}
                        />
                    );
                }
            }}
        />
    )
}


export default PrivateRoute;
