import React, { useState, useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../config/fbConfig";
import "draft-js/dist/Draft.css";
import "./DiaryStyles";
import {
  addDiaryPage,
  getDiaryPage,
  saveDiary,
  saveDiaryPageByDate,
  getDiary,
} from "../../store/actions/diary";
import {
  addJournalPage,
  getJournalPage,
  getJournal,
  saveJournal,
  saveJournalPageByDate,
} from "../../store/actions/journal";

import {
  Box,
  Grid,
  Paper,
  TextField,
  Card,
  Button,
  DialogContentText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Diaryheader from "./DiaryHeader";
import { useFilePicker } from "use-file-picker";
import Diarypremium from "./DiaryPremiumBanner";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  AtomicBlockUtils,
  convertToRaw,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { FiUpload } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import DiaryStyles from "./DiaryStyles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import {
  getOpenedUsersImages,
  getOpenedGallery,
  getPremiumImages,
  getStickers,
} from "../../store/actions/images";

import { GoFileMedia } from "react-icons/go";
import { CgMenuGridR } from "react-icons/cg";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import draftToHtml from "draftjs-to-html";
import { getUserSubscription } from "../../store/actions/subscriptions";
import PropTypes from "prop-types";
import { BlockPicker } from "react-color";
import EditIcon from "@mui/icons-material/Edit";
import { Masonry } from "@mui/lab";
// import { Autosave, useAutosave } from 'react-autosave';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "../Loader/index";
import PaymentForm from "../PaymentForm";
import Navbar from "../Navbar";
import Main from "../Main";

const styles = {
  backgroundColor: "#fff",
  backgroundImage: "none",
  paddingBottom: 50,
};

class ColorPic extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  onChange = (color) => {
    const { onChange } = this.props;
    onChange("color", color.hex);
  };

  renderModal = () => {
    const { color } = this.props.currentState;
    return (
      <div onClick={this.stopPropagation} style={{ marginLeft: "-80px" }}>
        <BlockPicker color={color} onChangeComplete={this.onChange} />
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;
    return (
      <div>
        <div
          aria-haspopup="true"
          aria-expanded={expanded}
          aria-label="rdw-color-picker"
        >
          <div onClick={onExpandEvent}>
            <EditIcon sx={{ color: "#431ba5", fontSize: "2rem" }} />
          </div>
          {expanded ? this.renderModal() : null}
        </div>
      </div>
    );
  }
}

const DiaryEditor = (props) => {
  const classes = DiaryStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { gallery, usersImages, premiumImages, stickers } = useSelector(
    (state) => state.images
  );
  const [images, SetImages] = useState([]);
  const [gotImages, updateGotImages] = useState(0);
  const openedDiary = useSelector((state) => state.diary.openedDiary);
  const diaryLoader = useSelector((state) => state.diary.diaryLoader);
  const myDiary = useSelector((state) => state.diary.myDiary);
  const myJournal = useSelector((state) => state.journal.myJournal);
  const openedJournal = useSelector((state) => state.journal.openedJournal);
  const auth = useSelector((state) => state.firebase.auth);
  const { isPremiumUser, userStatusUpdated } = useSelector(
    (state) => state.subscriptions
  );
  const formRef = React.useRef(null);
  const [
    openFileSelector,
    { filesContent, loading, errors, plainFiles, clear },
  ] = useFilePicker({
    multiple: true,
    readAs: "DataURL",
    accept: "image/*",
  });
  const [defaultEditorState, setDefaultEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateSecond, setEditorStateSecond] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateAsHTML, setEditorStateAsHTML] = useState(null);
  const [editorStateSecondAsHTML, setEditorStateSecondAsHTML] = useState(null);
  //dialog media

  const [open, setOpen] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openElements, setOpenElements] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openAddImages, setAddImages] = useState(false);
  const [openAddImageDialog, setOpenAddImageDialog] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [date, setDate] = useState(new Date());
  const [hideToolbar, setHideToolbar] = useState(false);
  const [autoSaveTimer, setAutoSaveTimer] = useState(null);
  const [autoSaveFlag, setAutoSaveFlag] = useState(false);
  const [initializeDiary, setInitializeDiary] = useState(false);
  const [initializeJournal, setInitializeJournal] = useState(false);
  useEffect(() => {
    let payload = {
      userid: auth.uid,
      on: moment(date).format("YYYY/MM/DD"),
    };
    dispatch(getDiary(payload));
    dispatch(getJournal(payload));
  }, []);
  //Effect to trace diary and update editor accordingly
  useEffect(() => {
    const getDiaryPageByDate = myDiary[moment(date).format("YYYY/MM/DD")];
    console.log("getDiaryPageByDate", getDiaryPageByDate);
    if (getDiaryPageByDate) {
      if (!initializeDiary && getDiaryPageByDate?.page) {
        let blocksFromHTML = htmlToDraft(getDiaryPageByDate.page);
        let state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        let editorStateData = EditorState.createWithContent(state);
        setEditorState(editorStateData);
        setInitializeDiary(true);
      } else {
        setInitializeDiary(true);
      }
    } else {
      // setInitializeDiary(true)
      // setEditorState(() => EditorState.createEmpty())
    }
  }, [myDiary]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (auth && auth.uid) {
        setEditorState(() => EditorState.createEmpty());
        setEditorStateSecond(EditorState.createEmpty());

        let payload = {
          userid: auth.uid,
          on: moment(date).format("YYYY/MM/DD"),
        };
        dispatch(getDiaryPage(payload));
        dispatch(getJournalPage(payload));
        setAutoSaveFlag(true);
        setAutoSaveTimer(10);
      }
    }

    fetchData();
  }, [date]);

  useEffect(() => {
    async function save() {
      await onSave();
      setAutoSaveTimer(10);
    }
    if (autoSaveFlag) {
      if (autoSaveTimer > 0) {
        setTimeout(() => setAutoSaveTimer(autoSaveTimer - 1), 1000);
      } else {
        save();
      }
    }
  }, [autoSaveTimer]);

  /////Media model open
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  /////Template model open
  const handleClickOpenSticker = (scrollType) => () => {
    setOpenTemplate(true);
    setScroll(scrollType);
  };

  //upload images modal close//
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  /////Elements model open
  const handleClickOpenPremium = (scrollType) => () => {
    setOpenElements(true);
    setScroll(scrollType);
  };

  /////uploadImages model open
  const handleAddImages = () => {
    setOpenAddImageDialog(true);
  };

  //upload modal
  const handleClickOpenUpload = (scrollType) => () => {
    setOpenUpload(true);
    setScroll(scrollType);
  };

  /////Media model close
  const handleClose = () => {
    setOpen(false);
  };

  /////template model close
  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setOpenElements(false);
  };

  /////Elements model close
  const handleCloseElements = () => {
    setOpenElements(false);
    setOpenTemplate(false);
  };

  const descriptionElementRef = React.useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //Service to update state of the diary editor
  const onEditorStateChange = (editorCurrentState) => {
    if (hideToolbar) {
      setHideToolbar(false);
    }
    setEditorState(editorCurrentState);
    const rawContentState = convertToRaw(
      editorCurrentState.getCurrentContent()
    );

    const markup = draftToHtml(rawContentState);
    setEditorStateAsHTML(markup);
  };

  //Service to update state of the journal editor
  const onEditorStateChangeSecond = (editorCurrentState) => {
    if (!hideToolbar) {
      setHideToolbar(true);
    }
    setEditorStateSecond(editorCurrentState);
    const rawContentState = convertToRaw(
      editorCurrentState.getCurrentContent()
    );

    const markup = draftToHtml(rawContentState);
    setEditorStateSecondAsHTML(markup);
  };
  //end editor

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      uploadFile();
    }
  }, [plainFiles]);

  const selectEditor = (editorState) => {
    if (hideToolbar) {
      onEditorStateChangeSecond(editorState);
    } else {
      onEditorStateChange(editorState);
    }
  };
  const uploadFile = () => {
    let image = plainFiles[0]; //e.target.files[0];
    let userId = auth.uid;
    storage
      .ref(`/users/${userId}/${image.name}`)
      .put(image)
      .on("state_changed", () => {
        storage
          .ref(`/users/${userId}/`)
          .child(image.name)
          .getDownloadURL()
          .then(async (url) => {
            selectEditor(insertImage(url));
          });
      });
  };

  // const onSave = async () => {
  //   let on = moment(date).format('YYYY/MM/DD')
  //   let userid = auth.uid
  //   const diary = {
  //     ...openedDiary,
  //     diaryPage: editorStateAsHTML,
  //     userid,
  //     on
  //   };
  //   dispatch(addDiaryPage(diary));
  //   const journal = {
  //     ...openedJournal,
  //     page: editorStateSecondAsHTML,
  //     userid,
  //     on,
  //     events: '',
  //     gratefulfor: '',
  //   };
  //   dispatch(addJournalPage(journal));
  // }
  const onSave = async () => {
    //Auto save functionality was already working with 2 seconds enterval don't know what to do with this function
  };

  useEffect(() => {
    if (!userStatusUpdated) dispatch(getUserSubscription());

    let url =
      "https://firebasestorage.googleapis.com/v0/b/quillatwill-int-uat.appspot.com/o/images%2FBirthday_Superhero.jpg?alt=media&token=d21a988b-51d5-4aab-b528-2500b29e6066";
    let name = url.split("%2F");
    name = name[1].split("?");
    name = name[0];

    let payload = {
      url,
      category: "Birthday",
      tags: ["Happy", "Birthday", "Wishes"],
      type: "image",
      name,
      isPaid: "free",
    };
    dispatch(getPremiumImages());
    dispatch(getStickers());

    try {
      let uid = auth?.uid;
      dispatch(getOpenedUsersImages({ ...usersImages }, uid, updateGotImages));
      dispatch(getOpenedGallery({ ...gallery }, updateGotImages));
      dispatch(getStickers());
      dispatch(getPremiumImages());
    } catch (e) {
      console.log("Auth error : ", e);
    }
  }, []);

  const handleMediaClick = (url) => {
    if (hideToolbar) setEditorStateSecond(insertImage(url));
    else setEditorState(insertImage(url));
    setOpen(false);
  };

  useEffect(() => {
    var imagesArray = [];
    if (usersImages.length > 0) {
      imagesArray = imagesArray.concat(usersImages);
    }
    if (gallery.length > 0) {
      imagesArray = imagesArray.concat(gallery);
    }
    SetImages(imagesArray);
  }, [gotImages, usersImages, props.images]);

  const clearFilePicker = () => {
    clear();
  };

  const insertImage = (url) => {
    clearFilePicker();
    const editorStateFocused = hideToolbar ? editorStateSecond : editorState;
    const contentState = editorStateFocused.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorStateFocused, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  useEffect(() => {
    if (openedDiary && openedDiary.diaryId) {
      let { diaryPage } = openedDiary;
      if (diaryPage && diaryPage.length > 0) {
        let blocksFromHTML = htmlToDraft(diaryPage);

        let state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        let editorStateData = EditorState.createWithContent(state);
        setEditorState(editorStateData);
      }
    }
  }, [openedDiary]);

  useEffect(() => {
    if (openedJournal && openedJournal.journalId) {
      let { page, journalId } = openedJournal;
      if (page && page.length > 0) {
        let blocksFromHTML = htmlToDraft(page);

        let state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        let editorStateData = EditorState.createWithContent(state);

        setEditorStateSecond(editorStateData);
      }
    }
  }, [openedJournal]);

  // function for handling date change
  const handleDateSelect = (dataParam) => {
    setDate(dataParam);
    const getDiaryPageByDate = myDiary[moment(dataParam).format("YYYY/MM/DD")];
    const getJournalPageByDate =
      myJournal[moment(dataParam).format("YYYY/MM/DD")];
    if (getDiaryPageByDate && getDiaryPageByDate?.page) {
      const blocksFromHTML = htmlToDraft(getDiaryPageByDate.page);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorStateData = EditorState.createWithContent(state);
      setEditorState(editorStateData);
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
    if (getJournalPageByDate && getJournalPageByDate?.page) {
      const blocksFromHTML = htmlToDraft(getJournalPageByDate.page);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorStateData = EditorState.createWithContent(state);
      setEditorStateSecond(editorStateData);
    } else {
      setEditorStateSecond(() => EditorState.createEmpty());
    }
  };
  const renderBuyPremiumModal = () => {
    return (
      <Dialog
        style={{ padding: "10px" }}
        open={openElements || openTemplate}
        onClose={handleCloseElements}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"To unclock these images buy premium"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions style={{ paddingBottom: "20px" }}>
          <Button
            style={{
              textAlign: "center",
              background: "rgb(0, 31, 88)",
              color: "#fff",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
              padding: "6px 35px 6px 35px",
            }}
            onClick={() => {
              handleCloseElements();
              formRef.current.click();
            }}
          >
            Buy
          </Button>

          <Button
            style={{
              textAlign: "center",
              background: "rgb(0, 31, 88)",
              color: "#fff",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
              padding: "6px 35px 6px 35px",
            }}
            onClick={handleCloseElements}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Service to save diary with updated data in local and firebase databases
  const handleSaveAudioDiary = (file) => {
    const diarySaveParams = { ...myDiary };
    let payload = {};
    const getDiaryPageByDate = myDiary[moment(date).format("YYYY/MM/DD")];
    if (getDiaryPageByDate) {
      payload = {
        ...getDiaryPageByDate,
        audio: file,
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    } else {
      payload = {
        page: editorStateAsHTML,
        on: new Date(date),
        userid: auth.uid,
        feelings: "neutral",
        color: "#FFA07A",
        audio: file,
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    }
    dispatch(saveDiary(diarySaveParams));
    dispatch(saveDiaryPageByDate(payload, diarySaveParams));
  };
  // Service to save diary with updated data in local and firebase databases
  const handleSaveDiary = () => {
    const diarySaveParams = { ...myDiary };
    let payload = {};
    const getDiaryPageByDate = myDiary[moment(date).format("YYYY/MM/DD")];
    if (getDiaryPageByDate) {
      payload = {
        ...getDiaryPageByDate,
        page: editorStateAsHTML,
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    } else {
      payload = {
        page: editorStateAsHTML,
        on: new Date(date),
        userid: auth.uid,
        feelings: "neutral",
        color: "#FFA07A",
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    }
    dispatch(saveDiary(diarySaveParams));
    dispatch(saveDiaryPageByDate(payload, diarySaveParams));
  };
  // Service to save diary with updated data in local and firebase databases
  const handleSaveVideoDiary = (file) => {
    console.log("file", file);
    const diarySaveParams = { ...myDiary };
    let payload = {};
    const getDiaryPageByDate = myDiary[moment(date).format("YYYY/MM/DD")];
    if (getDiaryPageByDate) {
      payload = {
        ...getDiaryPageByDate,
        video: file,
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    } else {
      payload = {
        page: editorStateAsHTML,
        on: new Date(date),
        userid: auth.uid,
        feelings: "neutral",
        color: "#FFA07A",
        video: file,
      };
      diarySaveParams[moment(date).format("YYYY/MM/DD")] = payload;
    }
    dispatch(saveDiary(diarySaveParams));
    dispatch(saveDiaryPageByDate(payload, diarySaveParams));
  };
  return (
    <Main styles={styles}>
      <Grid container style={{ display: "flex", flexDirection: "column" }}>
        <Grid item xs={12} md={12} style={{ minHeight: "80px" }}>
          <Navbar />
        </Grid>
        <>
          <Box
            style={{ height: "64px", width: "100%", background: "#fff" }}
          ></Box>
          <PaymentForm setRef={formRef} />
          <Diaryheader
            style={{ margin: "auto", display: "flex" }}
            onSave={onSave}
          />
          {/* <CircularProgress /> */}
          <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "2rem" }}>
            <Grid
              container
              className={classes.mainGridStyle}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid
                container
                item
                xs={12}
                md={12}
                style={{
                  height: "35px",
                  maxWidth: "80%",
                  marginLeft: "10%",
                  marginBottom: "20px",
                }}
              >
                {/* <Grid item xs={4} md={4} style={{ dispaly: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
              <UndoIcon style={{ cursor: 'pointer', fontSize: 40 }} />
              <RedoIcon style={{ marginLeft: '2vw', cursor: 'pointer', fontSize: 40 }} />
            </Grid> */}
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={6}
                  style={{
                    Display: "flex",
                    justifyContent: "center",
                    textAlign: "left",
                    margin: "",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={date}
                      onChange={(dataParam) => {
                        handleDateSelect(dataParam);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={null}
                          size="small"
                          style={{
                            color: "#000",
                            border: "1px solid #000",
                            borderRadius: "10px",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display={{ xs: "block", sm: "block", md: "none", lg: "none" }}
                >
                  <Button
                    style={{
                      color: "#fff",
                      background: "#1976d2",
                      padding: "0.6rem",
                      width: "39vw",
                      borderRadius: "6px",
                      marginLeft: "1rem",
                    }}
                    onClick={handleAddImages}
                  >
                    Add Images
                  </Button>

                  <Dialog
                    open={openAddImageDialog}
                    onClose={() => setOpenAddImageDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{
                        textAlign: "center",
                        color: "#033463",
                        fontSize: "30px",
                      }}
                    >
                      {"Add Images"}
                    </DialogTitle>
                    <DialogContent>
                      <Grid
                        item
                        sm={12}
                        md={12}
                        style={{ margin: "auto", display: "flex" }}
                      >
                        <Grid
                          item
                          sm={12}
                          md={12}
                          xs={12}
                          style={{ display: "flex" }}
                        >
                          <Grid item sm={4} md={12} xs={4}>
                            <Box className={classes.boxicon}>
                              <GoFileMedia
                                onClick={handleClickOpen("paper")}
                                style={{ color: "#fff" }}
                                className={classes.fileicons}
                              />
                            </Box>
                            <Typography className={classes.iconname}>
                              Media
                            </Typography>
                          </Grid>
                          <Grid item sm={4} md={12} xs={4}>
                            <Box className={classes.boxicon}>
                              <FiUpload
                                className={classes.fileicons}
                                onClick={openFileSelector}
                              />
                            </Box>
                            <Typography className={classes.iconname}>
                              Upload
                            </Typography>
                          </Grid>
                          <Grid item sm={4} md={12} xs={4}>
                            <Box className={classes.boxicon}>
                              <WorkspacePremiumIcon
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "45px",
                                }}
                                onClick={handleClickOpenPremium("paper")}
                                className={classes.fileicons}
                              />
                            </Box>
                            <Typography className={classes.iconname}>
                              Premium <br />
                              Images
                            </Typography>
                          </Grid>
                          <Grid item sm={4} md={12} xs={4}>
                            <Box className={classes.boxicon}>
                              <CgMenuGridR
                                onClick={handleClickOpenSticker("paper")}
                                className={classes.fileicons}
                              />
                            </Box>
                            <Typography className={classes.iconname}>
                              Premium <br />
                              Stickers
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{
                          background: "#1976d2",
                          color: "#fff",
                          width: "44vw",
                          margin: "auto",
                        }}
                        onClick={() => setOpenAddImageDialog(false)}
                        autoFocus
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "20px" }}>
              <Grid
                item
                sm={12}
                md={1}
                style={{ margin: "auto" }}
                display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
              >
                <Grid item sm={12} md={12} xs={12}>
                  <Grid item sm={3} md={12} xs={4}>
                    <Box className={classes.boxicon}>
                      <GoFileMedia
                        onClick={handleClickOpen("paper")}
                        style={{ color: "#fff" }}
                        className={classes.fileicons}
                      />
                    </Box>
                    <Typography className={classes.iconname}>Media</Typography>
                  </Grid>
                  <Grid item sm={4} md={12} xs={4}>
                    <Box className={classes.boxicon}>
                      <FiUpload
                        className={classes.fileicons}
                        onClick={handleClickOpenUpload("paper")}
                      />
                    </Box>
                    <Typography className={classes.iconname}>Upload</Typography>
                  </Grid>
                  <Grid item sm={4} md={12} xs={4}>
                    <Box className={classes.boxicon}>
                      <WorkspacePremiumIcon
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "45px",
                        }}
                        onClick={handleClickOpenPremium("paper")}
                        className={classes.fileicons}
                      />
                    </Box>
                    <Typography className={classes.iconname}>
                      Premium <br />
                      Images
                    </Typography>
                  </Grid>
                  <Grid item sm={4} md={12} xs={4}>
                    <Box className={classes.boxicon}>
                      <CgMenuGridR
                        onClick={handleClickOpenSticker("paper")}
                        className={classes.fileicons}
                      />
                    </Box>
                    <Typography className={classes.iconname}>
                      Premium <br />
                      Stickers
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                md={4.5}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 10,
                  marginTop: "-30px",
                }}
              >
                <h4 className={classes.headTex}>Diary</h4>
                <Editor
                  style={{ fontFamily: "none" }}
                  defaultEditorState={defaultEditorState}
                  editorState={editorState}
                  toolbarClassName={
                    hideToolbar
                      ? classes.toolbarHidden
                      : window.innerWidth < 668
                      ? "toolbarClassName"
                      : classes.toolbarClassName
                  }
                  wrapperClassName={
                    window.innerWidth < 668
                      ? classes.wrapperClassNameMobile
                      : classes.wrapperClassName
                  }
                  editorClassName={
                    hideToolbar
                      ? classes.editorClassNameOnHide
                      : classes.editorClassName
                  }
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "blockType",
                      "inline",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "embedded",
                      "emoji",
                      "image",
                      "remove",
                      "history",
                    ],
                    // colorPicker: { component: ColorPic, className: 'colorPickerDiv' },
                    fontFamily: {
                      options: [
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                        "Museo300",
                        "Museo700",
                        "cursive",
                        "sans-serif",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={4.5}
                xs={12}
                style={{
                  display: "flex",
                  marginTop: "-30px",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 10,
                  zIndex: "100",
                }}
              >
                <h4 className={classes.headText1}>Journal</h4>
                <Editor
                  style={{ fontFamily: "none" }}
                  editorState={editorStateSecond}
                  toolbarClassName={
                    hideToolbar
                      ? window.innerWidth < 668
                        ? "toolbarClassTwo"
                        : classes.toolbarClassNameTwo
                      : classes.toolbarHidden
                  }
                  wrapperClassName={classes.wrapperClassName}
                  editorClassName={classes.editorClassNameTwo}
                  onEditorStateChange={onEditorStateChangeSecond}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "blockType",
                      "inline",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "embedded",
                      "emoji",
                      "image",
                      "remove",
                      "history",
                    ],
                    // colorPicker: { component: ColorPic, className: 'colorPickerDiv' },
                    fontFamily: {
                      options: [
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                        "Museo300",
                        "Museo700",
                        "cursive",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={12} md={2} xs={12} style={{}}>
                <Diarypremium
                  onSaveAudio={(mediaFile) => handleSaveAudioDiary(mediaFile)}
                  onSaveVideo={(mediaFile) => handleSaveVideoDiary(mediaFile)}
                  diaryData={myDiary[moment(date).format("YYYY/MM/DD")]}
                  isPremiumUser={isPremiumUser}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ borderRadius: "2rem !important" }}
          >
            <Dialog
              className={classes.dailogstyle}
              open={openUpload}
              onClose={handleCloseUpload}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                paddingRight: "6rem",
              }}
            >
              <ImCross
                onClick={handleCloseUpload}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                  cursor: "pointer",
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                    id="searchType"
                    // onChange={searchContent}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                </Paper>
                <Button
                  variant="contained"
                  style={{
                    width: "41vw",
                    padding: "10px",
                    marginTop: "0.5rem",
                    borderRadius: "20px",
                  }}
                  onClick={openFileSelector}
                  // modifier={imagePlugin.addImage}
                >
                  Upload Media
                </Button>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Images
                  </Typography>
                  {/* <Typography>See all</Typography> */}
                </Box>
                <Box sx={{ width: "100%", minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>
                    {usersImages && usersImages.length > 0
                      ? usersImages.map((img, index) => (
                          <div key={index}>
                            {/* <Label>{index + 1}</Label> */}
                            <img
                              src={img}
                              alt="demo"
                              loading="lazy"
                              style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                display: "block",
                                width: "100%",
                              }}
                              onClick={() => handleMediaClick(img)}
                            />
                          </div>
                        ))
                      : null}
                  </Masonry>
                </Box>
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid
            container
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ borderRadius: "2rem !important" }}
          >
            <Dialog
              className={classes.dailogstyle}
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              sx={{
                borderRadius: "1rem !important",
                display: "flex",
                justifyContent: "right",
                // paddingRight: "3rem",
              }}
            >
              <ImCross
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: "10px",
                  color: "gray",
                }}
              />

              <DialogTitle
                id="scroll-dialog-title"
                sx={{ borderRadius: "2rem !important" }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: "2rem",
                  }}
                >
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by type"
                    inputProps={{ "aria-label": "Search by type" }}
                  />
                </Paper>
              </DialogTitle>
              <DialogContent dividers={scroll === "div"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontFamily="Museo700"
                    sx={{ color: "rgb(30, 58, 113)" }}
                  >
                    Photos
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", minHeight: 829 }}>
                  <Masonry columns={{ xs: 1, sm: 4, md: 3, lg: 3 }} spacing={1}>
                    {images && images.length > 0
                      ? images.map((img, index) => (
                          <div key={index}>
                            {/* <Label>{index + 1}</Label> */}
                            <img
                              src={img}
                              // src={`${img}?w=162&auto=format`}
                              // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                              alt="demo"
                              loading="lazy"
                              style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                display: "block",
                                width: "100%",
                              }}
                              onClick={() => handleMediaClick(img)}
                            />
                          </div>
                        ))
                      : null}
                  </Masonry>
                </Box>
                {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography
                variant="h6"
                fontFamily="Museo700"
                sx={{ color: "rgb(30, 58, 113)" }}
              >
                Audio
              </Typography>
              <Typography>See all</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Card
                sx={{
                  width: "100px",
                  height: "60px",
                  marginRight: "1rem",
                  backgroundColor: "red",
                  borderRadius: "10px",
                }}
                elevation={0}
              >
                <img
                  src="https://images.unsplash.com/photo-1489710437720-ebb67ec84dd2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  width="100%"
                  height="100%"
                />
              </Card>

              <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" fontFamily="Museo700" color="gray">
                  The happy Kid Song
                </Typography>
                <Typography variant="p" color="gray">
                  Instrumental | happy |Bright
                </Typography>
                <Typography color="gray">2:12</Typography>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Card
                sx={{
                  width: "100px",
                  height: "60px",
                  marginRight: "1rem",
                  backgroundColor: "red",
                  borderRadius: "10px",
                }}
                elevation={0}
              >
                <img
                  src="https://images.unsplash.com/photo-1489710437720-ebb67ec84dd2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  width="100%"
                  height="100%"
                />
              </Card>

              <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" fontFamily="Museo700" color="gray">
                  Baby shark do do
                </Typography>
                <Typography variant="p" color="gray">
                  Instrumental | happy |Bright
                </Typography>
                <Typography color="gray">2:12</Typography>
              </Grid>
            </Box> */}
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {user?.isPremiumUser ? (
              <Dialog
                className={classes.dailogstyle}
                open={openTemplate}
                onClose={handleCloseTemplate}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                borderRadius="2rem"
                sx={{
                  // width: "70%",
                  display: "flex",
                  justifyContent: "right",
                  paddingRight: "6rem",
                  // p: "2px 4px",
                }}
              >
                <ImCross
                  onClick={handleCloseTemplate}
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "10px",
                    color: "gray",
                  }}
                />

                <DialogTitle id="scroll-dialog-title">
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                      borderRadius: "2rem",
                    }}
                  >
                    <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by type"
                      inputProps={{ "aria-label": "Search by type" }}
                    />
                  </Paper>
                </DialogTitle>

                <DialogContent dividers={scroll === "div"}>
                  <Grid spacing={2} xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      // textAlign="start"
                      component="div"
                      fontFamily="Museo700"
                      color="#1E3A71"
                    >
                      Stickers Images
                    </Typography>
                    <Grid container spacing={3}>
                      {stickers && stickers.length > 0
                        ? stickers.map((img, index) => (
                            <Grid item sm={4} sx={12}>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                }}
                                key={index}
                                component="img"
                                src={img}
                                alt="green iguana"
                                onClick={() => handleMediaClick(img)}
                              />
                            </Grid>
                          ))
                        : null}
                    </Grid>
                    {/* <Box
                    spacing={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {
                      stickers && stickers.length > 0 ?
                        stickers.map(sticker =>
                          <Grid item lg={6} sx={{ p: 2 }}>
                            <img
                              component="img"
                              height="160"
                              src={sticker}
                              alt="green iguana"
                              onClick={() => handleMediaClick(sticker)}
                            />
                          </Grid>
                        ) : null
                    }
                  </Box> */}
                  </Grid>
                </DialogContent>
              </Dialog>
            ) : (
              renderBuyPremiumModal()
            )}
          </Grid>
          <Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
            {user?.isPremiumUser ? (
              <Dialog
                className={classes.dailogstyle}
                open={openElements}
                onClose={handleCloseElements}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                borderRadius="2rem"
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  paddingRight: "6rem",
                }}
              >
                <ImCross
                  onClick={handleCloseTemplate}
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "10px",
                    color: "gray",
                  }}
                />

                <DialogTitle id="scroll-dialog-title">
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                      borderRadius: "2rem",
                    }}
                  >
                    <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by type"
                      inputProps={{ "aria-label": "Search by type" }}
                    />
                  </Paper>
                </DialogTitle>

                <DialogContent dividers={scroll === "div"}>
                  <Grid spacing={2} xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      // textAlign="start"
                      component="div"
                      fontFamily="Museo700"
                      color="#1E3A71"
                    >
                      Premium Images
                    </Typography>
                    <Box sx={{ width: "100%", minHeight: 829 }}>
                      <Masonry
                        columns={{ xs: 1, sm: 4, md: 3, lg: 3 }}
                        spacing={1}
                      >
                        {premiumImages && premiumImages.length > 0
                          ? premiumImages.map((image, index) => {
                              return (
                                <div key={index}>
                                  {/* <Label>{index + 1}</Label> */}
                                  <img
                                    src={image}
                                    // src={`${img}?w=162&auto=format`}
                                    // srcSet={`${img}?w=162&auto=format&dpr=2 2x`}
                                    alt="demo"
                                    loading="lazy"
                                    style={{
                                      borderBottomLeftRadius: 4,
                                      borderBottomRightRadius: 4,
                                      display: "block",
                                      width: "100%",
                                    }}
                                    onClick={() => handleMediaClick(image)}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </Masonry>
                    </Box>
                    {/* 
                  <Box
                    spacing={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {
                      premiumImages && premiumImages.length > 0 ?
                        premiumImages.map(image =>
                          <Grid item lg={6} sx={{ p: 2 }}>
                            <img
                              component="img"
                              height="160"
                              src={image}
                              alt="green iguana"
                              onClick={() => handleMediaClick(image)}
                            />
                          </Grid>
                        ) : null
                    }
                  </Box> */}
                  </Grid>
                </DialogContent>
              </Dialog>
            ) : (
              renderBuyPremiumModal()
            )}
          </Grid>
          {diaryLoader && <Loader />}
        </>
      </Grid>
    </Main>
  );
};

export default DiaryEditor;