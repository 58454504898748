import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppRecentalyAddedData from "./AppRecentalyAddedData";

export default function AppRecentlyAddedComp() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 style={{ color: "#1f108e" }}>Recently Added</h3>
      </AccordionSummary>
      <AccordionDetails>
        <AppRecentalyAddedData />
      </AccordionDetails>
    </Accordion>
  );
}
