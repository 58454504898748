import { actionTypes } from "../actions";

export const INITIAL_STATE = {
  openedPostCard: {
    from: null,
    to: [],
    content: null,
    image: null,
  },
  postCard: {},
  sent: [],
  received: [],
  postCardImage: "",
  savedPostCard: {},
  draftPostCards: [],
  sentCount: 0,
  receivedCount: 0,
  draftCount: 0,
  draftEdit: false,
  displayPostcard: [],
};

export const postcards = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_OPENED_POSTCARD:
      return {
        ...state,
        openedPostCard: payload,
      };
    case actionTypes.GET_POSTCARD:
      return {
        ...state,
        openedPostCard: payload,
      };
    case actionTypes.SET_SAVED_POSTCARD:
      return {
        ...state,
        savedPostCard: payload,
      };
    case actionTypes.SET_POSTCARD_DETAILS:
      return {
        ...state,
        postCard: payload,
      };
    case actionTypes.GET_SENT_POSTCARDS:
      return {
        ...state,
        sent: payload,
      };
    case actionTypes.GET_RECEIVED_POSTCARDS:
      return {
        ...state,
        received: payload,
      };
    case actionTypes.SET_POSTCARD_IMAGE:
      return {
        ...state,
        postCardImage: payload,
      };
    case actionTypes.GET_DRAFT_POSTCARDS:
      return {
        ...state,
        draftPostCards: payload,
      };
    case actionTypes.SAVE_DRAFT_POSTCARDS:
      return {
        ...state,
        draftPostCards: payload,
      };
    case actionTypes.GET_SENT_POSTCARDS_COUNT:
      return {
        ...state,
        sentCount: payload,
      };
    case actionTypes.GET_RECEIVED_POSTCARDS_COUNT:
      return {
        ...state,
        receivedCount: payload,
      };
    case actionTypes.GET_DRAFT_POSTCARDS_COUNT:
      return {
        ...state,
        draftCount: payload,
      };
    case actionTypes.SET_DRAFT_EDIT_FLAG:
      return {
        ...state,
        draftEdit: payload,
      };
    case actionTypes.RECEIVED_POSTCARD:
      return {
        ...state,
        displayPostcard: payload,
      };
    default:
      return state;
  }
};
