
import React from 'react'
import { firebase, db, storage, auth } from '../config/fbConfig'
const fileConvertToBase64 = async(file) => {
    console.log("called", file);
    const reader = new FileReader();
    let response= "";
    let audioString= "";
    try {
      response =  await fetch(file)
      const responseBlob = await response.blob();
      return new Promise((resolve, reject) => {
        reader.readAsDataURL(responseBlob)
        reader.onloadend = () => {
          console.log("responses", reader.result?.toString())
          resolve(reader.result?.toString())
        };
        reader.onerror = () => {
          console.log("Something went wrong while reading file ")
          reject("")
        }
      });
    } catch(error) {
      console.log("Something went wrong while converting file to base64")
      response = ""
      return response
    }
}

export default fileConvertToBase64