import { makeStyles } from "@mui/styles";

const CookiesPolicyStyles = makeStyles((theme) => ({

bg: {
  position: 'fixed',
  overflowX: 'hidden',
  width: '100%',
  background: '#1171FF',
  marginTop: '4rem',
  padding: '7rem',
  height: '100vh',
  paddingTop: '54px',
  backgroundPosition: 'left top',
},

root: {
  textAlign:'left',
 marginTop:"1px",
},

}));

export default CookiesPolicyStyles;


