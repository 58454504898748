import { actionTypes, triggerReducer } from "./index";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbar";
import store from "../store";
import { db } from "../../config/fbConfig"


export const openedGoal = (payload) => (dispatch, getState) => {
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_GOALS, {
            payload
        })
    );
};

export const clearOpenedGoal = () => (dispatch, getState) => {
    let goal = {
        id: null,
        goal: null,
        userId: null,
        date: null,
        status: null
    }
    dispatch(
        triggerReducer(actionTypes.SET_OPENED_GOALS, {
            payload: goal
        })
    );
};

export const getGoals = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState().firebase;
        let goals = [];
        await db
            .collection('goals')
            .where('userId', '==', auth.uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    goals.push({ ...doc.data(), id: doc.id });
                })
            }).catch((error) => {
                
            });
        dispatch(
            triggerReducer(actionTypes.GET_GOALS, {
                payload: goals
            })
        );
        
        resolve(goals)
    });
};

export const saveGoals = (payload) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    if((payload.goal).length <=0)
    {
        dispatch(showErrorSnackbar("Goal must not be empty"))
    }
    else
    {
        const { firestore } = store;
    await firestore.add({ collection: "goals" }, {
        goal: payload.goal,
        userId: auth.uid,
        status: 0,
        date: payload.date,
    }).then((resp) => {
        dispatch(getGoals())
        dispatch(showSuccessSnackbar("Goal added Successfully!"));
    })
    }
};

export const updateGoals = (payload, goalId) => async (dispatch, getState) => {
    const { auth } = getState().firebase;
    const { firestore } = store;
    await firestore
        .collection("goals")
        .doc(goalId)
        .update(payload)
        .then((resp) => {
            dispatch(getGoals())
            dispatch(showSuccessSnackbar("Goal updated Successfully!"));
        })
};

export const deleteGoal = (payload) => async (dispatch, getState) => {

    const { firestore } = store;
    
    // for (let i = 0; i < payload.length; i++) {
    //     await firestore.collection('goals')
    //         .doc(payload).delete().then(() => {
    //             dispatch(showSuccessSnackbar("Goal deleted Successfully!"));
    //         }).catch((error) => {
    //             dispatch(showErrorSnackbar("Goal delete failed!!!"));
    //         });
    // }
    await firestore.collection('goals')
            .doc(payload.id).delete().then(() => {
                dispatch(showSuccessSnackbar("Goal deleted Successfully!"));
            }).catch((error) => {
                dispatch(showErrorSnackbar("Goal delete failed!!!"));
            });
    dispatch(getGoals())
}